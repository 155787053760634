import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { LocationId, LocationSelection, Nullable } from 'Consts/types';

import ListItem from 'UI/Components/Lists/List standard';
import Menu, { MenuItemProps } from 'UI/Components/Menu';

import { IconNames } from 'UI/Elements/Icon';

import { getConfigurationFromDomain } from 'subDomainConfiguration';
import sharedStyles from '../../shared.module.css';
import styles from './style.module.css';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

const BELL = 'bell';

type LocationsSelectorProps = {
  currentLocation: Nullable<LocationSelection>;
  locations: Nullable<LocationSelection[]>;
  onSwitchToLocation: (locationId: LocationId) => void;
};

const LocationsSelector: FunctionComponent<LocationsSelectorProps> = ({
  currentLocation,
  locations,
  onSwitchToLocation,
}) => {
  const { handleTriggerEvent } = useTrapFocus();
  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const parentRef = useRef<Element | null>(null);

  const handleCloseMenu = useCallback(() => setIsMenuOpen(false), []);
  const handleOpenMenu = useCallback(
    (ev: React.MouseEvent) => {
      if (!locations?.length || locations.length === 1) {
        return;
      }

      handleTriggerEvent(ev);

      parentRef.current = ev.currentTarget;

      setIsMenuOpen(true);
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    },
    [locations?.length]
  );

  const menuItems = (locations?.map((location) => ({
    label: location.name,
    iconName: location.id === currentLocation?.id ? IconNames.Check : undefined,
    onClick: () => onSwitchToLocation(location.id),
  })) || []) as MenuItemProps[];

  if (!currentLocation || !locations) {
    return null;
  }

  const borderClass = isBell ? styles.still400Border : styles.still200Border;

  return (
    <div
      className={cn(styles.locationWrapper, borderClass)}
      aria-label="location-navigation"
      role="navigation"
    >
      <ListItem
        className={cn(sharedStyles.navLink, styles.locationButton)}
        L1Props={{ iconProps: { name: IconNames.MapPin } }}
        L2Props={{ label: currentLocation.name }}
        onClick={handleOpenMenu}
        ariaLabel={currentLocation.name}
      />

      <Menu
        isOpen={isMenuOpen}
        parent={parentRef.current}
        items={menuItems}
        onClose={handleCloseMenu}
        maxHeight={window.innerHeight - 100}
        openTriggerEventType={menuOpenTrigger}
      />
    </div>
  );
};

export default LocationsSelector;
