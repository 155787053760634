import cn from 'classnames';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'Consts/routes';
import { Nullable } from 'Consts/types';

import { V2Card } from 'UI/Elements/Card';
import Icon, { IconName, IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import V2CardHeader from 'UI/Molecules/CardHeader';

import { SecurityEvent } from 'Consts/types';

import useNavigateToSettings from 'Utils/hooks/useNavigateToSettings';

import BarGraph from 'UI/Components/Graphs/BarGraph';
import { filterData } from './utils';

import EventTypeFilterCard from './Components/FilterCard';

import { FilterName, FilterNamePlusAll, eventsToGraphData } from './utils';

import { ShieldGraphConfig } from 'Consts/defintions';

import styles from './style.module.css';
import useScrollToViewOnFocus from 'Utils/hooks/useScrollToViewOnFocus';

type GraphConfig = {
  key: FilterNamePlusAll;
  i18labelKey: string;
  iconName: IconName;
  color: string;
};

const graphConfig: GraphConfig[] = [
  {
    key: 'all',
    i18labelKey: 'common.allEvents',
    iconName: IconNames.Graph,
    color: ShieldGraphConfig.all,
  },
  {
    key: 'onlineProtection',
    i18labelKey: 'common.onlineProtection',
    iconName: IconNames.Shield,
    color: ShieldGraphConfig.onlineProtection,
  },
  {
    key: 'contentAccess',
    i18labelKey: 'common.contentAccess',
    iconName: IconNames.CircleX,
    color: ShieldGraphConfig.contentAccess,
  },
  {
    key: 'adBlocking',
    i18labelKey: 'common.adBlocking',
    iconName: IconNames.HandSecurity,
    color: ShieldGraphConfig.adBlocking,
  },
  {
    key: 'remoteAccessProtection',
    i18labelKey: 'common.remoteAccessProtection',
    iconName: IconNames.CircleInternet,
    color: ShieldGraphConfig.remoteAccessProtection,
  },
  {
    key: 'advancedIotProtection',
    i18labelKey: 'common.advancedIotProtection',
    iconName: IconNames.SecurityMask,
    color: ShieldGraphConfig.advancedIotProtection,
  },
];

const graphColors = graphConfig.reduce(
  (acc, { key, color }) => ({ ...acc, ...{ [key]: color } }),
  {}
);

type ShieldGraphCardProps = {
  columnSpan?: 1 | 2 | 3;
  position?: 1 | 2 | 3;
  maxItems?: number;
  title?: Nullable<string>;
  contextLabel?: string;
  periodLabel?: string;
  isLoading?: boolean;
  errorMessage?: string;
  eventsData: SecurityEvent[];
  activeTypeFilter: FilterNamePlusAll;
  onTypeFilterClick: (name: FilterNamePlusAll) => void;
  onViewAllClick?: () => void;
};

const ShieldGraphCard: FunctionComponent<ShieldGraphCardProps> = ({
  columnSpan,
  position,
  contextLabel,
  periodLabel,
  isLoading,
  errorMessage,
  eventsData = [],
  activeTypeFilter,
  onTypeFilterClick,
  onViewAllClick,
}) => {
  const navigateToSettings = useNavigateToSettings();
  const { setContainerRef } = useScrollToViewOnFocus();
  const { t } = useTranslation();

  const handleSettingsClick = useCallback(() => {
    navigateToSettings(ROUTES.settings.shield);
    localStorage.setItem('settingsExpanded', true.toString());
  }, [navigateToSettings]);

  const graphData = eventsData
    ? eventsToGraphData(eventsData)
    : eventsToGraphData(eventsData);

  const filteredGraphData = useMemo(
    () => filterData(graphData, activeTypeFilter),
    [activeTypeFilter, graphData]
  );

  const eventCounts = graphData.reduce(
    (acc, { values }) => {
      Object.entries(values).forEach(([key, value]) => {
        acc[key as FilterName] = acc[key as FilterName] + value;
      });

      return acc;
    },
    {
      onlineProtection: 0,
      contentAccess: 0,
      adBlocking: 0,
      remoteAccessProtection: 0,
      advancedIotProtection: 0,
    } as Record<FilterName, number>
  );

  const totalCount = Object.values(eventCounts).reduce(
    (acc, value) => acc + value,
    0
  );

  return (
    <>
      <V2Card
        isLoading={isLoading}
        errorMessage={errorMessage}
        span={columnSpan}
        position={position}
        header={
          <V2CardHeader
            title={contextLabel || t('shield.events')}
            titleModifier={periodLabel || t('homepage.last24Hours')}
            right={
              <Icon
                name={IconNames.Settings}
                shape={IconShape.square}
                tooltipLabel={''}
                onClick={handleSettingsClick}
                ariaLabel={t('common.settings')}
              />
            }
          />
        }
        footer={
          onViewAllClick
            ? {
                label: t('common.viewMore'),
                onClick: onViewAllClick,
                ariaLabel: t('shield.viewMoreAriaLabel'),
              }
            : undefined
        }
        noBottomPadding={!!onViewAllClick}
      >
        <>
          <BarGraph
            data={filteredGraphData}
            graphColors={graphColors}
            noYAxisDecimals={true}
            interval={3}
            ariaLabel={t('shield.chartAriaLabel')}
          />

          <div
            role="tablist"
            aria-orientation="horizontal"
            ref={setContainerRef}
            className={styles.filterRow}
          >
            {graphConfig.map(({ key, i18labelKey, iconName, color }) => {
              let count = 0;

              if (key === 'all') {
                count = totalCount;
              } else {
                count = eventCounts[key];
              }

              return (
                <EventTypeFilterCard
                  key={key + count}
                  className={cn({
                    [styles.all]: key === 'all' && activeTypeFilter === 'all',
                  })}
                  label={t(i18labelKey)}
                  count={count}
                  iconName={iconName}
                  isActive={activeTypeFilter === key}
                  onClick={() => onTypeFilterClick(key as FilterNamePlusAll)}
                  color={color}
                />
              );
            })}
          </div>
        </>
      </V2Card>
    </>
  );
};

export default ShieldGraphCard;
