import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CenterListItem from 'UI/Components/Lists/List center';

import Icon, { IconNames } from 'UI/Elements/Icon';
import PillLarge from 'UI/Elements/Pill large';
import ProgressBar from 'UI/Elements/Progress bar';
import { Body2 } from 'UI/Elements/Typography';

import WlanSubheading from '../Heading';

import colorStyles from 'Styles/colors.module.css';

import SpeedTestCompleteAlertModal from 'UI/Reusable/SpeedTestCompleteAlertModal/SpeedTestCompleteAlertModal';
import styles from './style.module.css';

export type SpeedTestStates = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const SPEED_TEST_STATES: Record<string, SpeedTestStates> = {
  loading: 0,
  firstCheck: 1,
  secondCheck: 2,
  thirdCheck: 3,
  fourthCheck: 4,
  results: 5,
  error: 6,
};

type SpeedTestProgressProps = {
  speedTestState: number;
  download: number | null;
  upload: number | null;
};

export type InternetSpeedProps = SpeedTestProgressProps & {
  lastChecked: string;
};

const Checking = ({ label, percent }: { label: string; percent: number }) => {
  return (
    <>
      <CenterListItem className={styles.checking} smallLabel={label} />
      <ProgressBar
        className={styles.progressBar}
        percent={percent}
        color1="#7D7AFF"
        color2="#905DCF"
      />
    </>
  );
};

export const SpeedTestProgress = ({
  speedTestState,
  download,
  upload,
}: SpeedTestProgressProps) => {
  const { t } = useTranslation();

  const [percentAdded, setPercentAdded] = useState(0);
  useEffect(() => {
    let timeoutID: NodeJS.Timeout | null = null;

    const cancelTimeout = () => {
      if (!timeoutID) {
        return;
      }

      clearInterval(timeoutID);
      setPercentAdded(0);
    };

    if (
      speedTestState !== SPEED_TEST_STATES.firstCheck &&
      speedTestState !== SPEED_TEST_STATES.secondCheck &&
      speedTestState !== SPEED_TEST_STATES.thirdCheck &&
      speedTestState !== SPEED_TEST_STATES.fourthCheck
    ) {
      cancelTimeout();

      return;
    }

    timeoutID = setInterval(() => {
      setPercentAdded((oldPercent) => oldPercent + 1);
    }, 1000);

    return cancelTimeout;
  }, [speedTestState]);

  switch (speedTestState) {
    case SPEED_TEST_STATES.firstCheck:
      return (
        <Checking label={t('wlan.preparingTest')} percent={0 + percentAdded} />
      );

    case SPEED_TEST_STATES.secondCheck:
      return (
        <Checking
          label={t('wlan.checkingDownloadSpeed')}
          percent={5 + percentAdded}
        />
      );

    case SPEED_TEST_STATES.thirdCheck:
      return (
        <Checking
          label={t('wlan.checkingUploadSpeed')}
          percent={20 + percentAdded}
        />
      );

    case SPEED_TEST_STATES.fourthCheck:
      return (
        <Checking
          label={t('wlan.stillCalculating')}
          percent={35 + percentAdded}
        />
      );

    case SPEED_TEST_STATES.results:
      return (
        <>
          <div className={styles.download}>
            <div className={cn(styles.downloadItem, styles.downloadLabel)}>
              <div className={styles.itemHeading}>
                <Icon name={IconNames.ArrowDownRight} />
                <Body2 className={colorStyles.still400}>
                  {t('common.download')}
                </Body2>
              </div>

              <Body2 className={colorStyles.still800}>
                {download ? download + t('common.mbps') : '--'}
              </Body2>
            </div>

            <div className={styles.downloadItem}>
              <div className={styles.itemHeading}>
                <Icon name={IconNames.ArrowUpRight} />
                <Body2 className={colorStyles.still400}>
                  {t('common.upload')}
                </Body2>
              </div>
              <Body2 className={colorStyles.still800}>
                {upload ? upload + t('common.mbps') : '--'}
              </Body2>
            </div>
          </div>
        </>
      );

    case SPEED_TEST_STATES.error:
      return (
        <div className={styles.errorWrapper}>
          <PillLarge
            className={styles.error}
            leftIconName={IconNames.X}
            label={t('wlan.speedTestFailed')}
          />
        </div>
      );

    default:
      return null;
  }
};

const InternetSpeed = ({
  speedTestState,
  lastChecked,
  download,
  upload,
}: InternetSpeedProps) => {
  const { t } = useTranslation();
  const [speedTestCompleteModalOpen, setSpeedTestCompleteModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (speedTestState === SPEED_TEST_STATES.fourthCheck) {
      setSpeedTestCompleteModalOpen(true);
    }
  }, [speedTestState]);

  return (
    <>
      <SpeedTestCompleteAlertModal
        open={speedTestCompleteModalOpen}
        download={download || 0}
        upload={upload || 0}
      />
      <div>
        <WlanSubheading
          label={t('wlan.internetSpeed')}
          paragraph={lastChecked}
        />

        <SpeedTestProgress
          speedTestState={speedTestState}
          download={download}
          upload={upload}
        />
      </div>
    </>
  );
};

export default InternetSpeed;
