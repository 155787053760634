import { environments } from 'Consts/environments';
import { CloudBackend } from 'Consts/types';

export const getMetasaurusNodeIconUrl = async (
  cloud: CloudBackend,
  modelName: string
) => {
  const defaultModelName = 'Plume Pod v1.0';
  let url =
    environments[cloud]?.metasaurusUrl +
    '/v2/en-us/nodes/' +
    modelName +
    '/light.svg';

  try {
    const urlResponse = await fetch(url);

    if (urlResponse.status !== 200) {
      return (
        environments[cloud]?.metasaurusUrl +
        '/v2/en-us/nodes/' +
        defaultModelName +
        '/light.svg'
      );
    }

    return url;
  } catch (err) {
    throw err;
  }
};

export const getMetasaurusDeviceIconUrl = async (
  cloud: CloudBackend,
  modelName: string,
  size: string
) => {
  const deviceIconUrl = `${environments[cloud]?.metasaurusUrl}/v2/assets/devices/icons/${modelName}/icon-${size}.svg`;
  try {
    const urlResponse = await fetch(deviceIconUrl);

    if (urlResponse.status !== 200) {
      return `${environments[cloud]?.metasaurusUrl}/v2/assets/devices/icons/unknown/icon-${size}.svg`;
    }

    return deviceIconUrl;
  } catch (error) {
    return `${environments[cloud]?.metasaurusUrl}/v2/assets/devices/icons/unknown/icon-${size}.svg`;
  }
};
