import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FilterNamePlusAll } from '../../UI/Reusable/Shield graph/utils';

import { IPV4_REGEX, IPV6_REGEX } from 'Consts/defintions';
import {
  Blacklist,
  Events,
  SecurityEventHandling,
  WebsiteType,
  Whitelist,
  ZoneDevice,
} from 'Consts/types';

import { METHODS } from 'Api/index';

import * as actions from 'State/actions';
import useDevices from 'State/hooks/useDevices';
import usePersonSecurityPolicy from 'State/hooks/usePersonSecurityPolicy';
import useSecurityEvents from 'State/hooks/useSecurityEvents';
import useSecurityPolicy from 'State/hooks/useSecurityPolicy';

import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';

import Background from 'UI/Layout/Background';
import Page from 'UI/Layout/Page';
import PageHeader from 'UI/Layout/Page header';

import Alert from 'UI/Components/Alert';
import Grid, { GridSectionGap } from 'UI/Layout/Grid';

import { AutoCompleteResultsItem } from '../../UI/Components/AutoComplete/AutoCompleteResults';
import ShieldAutoComplete, {
  AutoCompleteResultTypes,
} from '../../UI/Reusable/Shield graph/Components/ShieldAutoComplete/ShieldAutoComplete';
import { ShieldEvents } from './Components/ShieldEvents';

import StandardListItem from 'UI/Components/Lists/List standard';
import Menu, { MenuItemProps } from 'UI/Components/Menu';

import { BUTTON_THEMES } from 'UI/Elements/Button';
import Card from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';
import AlertModal from 'UI/Elements/Modals/Alerts';
import { Body2Medium } from 'UI/Elements/Typography';

import ShieldGraphCard from 'UI/Reusable/Shield graph';
import useLayoutColumns from 'Utils/hooks/useLayout';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';

import styles from './style.module.css';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

type TabConfig = {
  key: SecurityEventHandling;
  i18labelKey: string;
};

const tabsConfig: TabConfig[] = [
  {
    key: Events.Protected,
    i18labelKey: 'shield.eventHandling.protected',
  },
  {
    key: Events.Approved,
    i18labelKey: 'shield.eventHandling.approved',
  },
  {
    key: Events.Blocked,
    i18labelKey: 'shield.eventHandling.blocked',
  },
];

type TabRowProps = {
  className?: string;
  tabs: TabConfig[];
  activeTab: SecurityEventHandling;
  onTabSelect: (selected: SecurityEventHandling) => void;
};

const TabRow: FunctionComponent<TabRowProps> = ({
  className,
  tabs,
  activeTab = Events.Protected,
  onTabSelect,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(styles.tabRow, className)}
      role="tablist"
      aria-label={t('shield.shieldTabAriaLabel')}
    >
      {tabs.map((tab, i) => (
        <button
          key={i}
          className={cn(styles.tab, { [styles.active]: tab.key === activeTab })}
          onClick={() => {
            onTabSelect(tab.key);
          }}
          role="tab"
          aria-label={t(tab.i18labelKey)}
          aria-selected={activeTab === tab.key}
          tabIndex={0}
        >
          <Body2Medium>{t(tab.i18labelKey)}</Body2Medium>
        </button>
      ))}
    </div>
  );
};

const Shield: FunctionComponent = () => {
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();
  const { allDevices } = useDevices();
  const trackEvent = useTrackEvent();
  const [columns, ref] = useLayoutColumns();

  const [selectedFilter, setSelectedFilter] =
    useState<AutoCompleteResultsItem | null>(null);

  const { data: securityEvents, isLoading, errorMessage } = useSecurityEvents();

  const [activeFilter, setActiveFilter] = useState<FilterNamePlusAll>('all');
  const [activeTab, setActiveTab] = useState<SecurityEventHandling>(
    Events.Protected
  );
  const [openMenu, setOpenMenu] = useState<
    'approve' | 'approved' | 'block' | 'blocked' | null
  >(null);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const [menuPolicy, setMenuPolicy] = useState<Whitelist | Blacklist | null>(
    null
  );
  const [openAlert, setOpenAlert] = useState<
    | 'approveIpAddress'
    | 'approveWebsite'
    | 'blockIpAddress'
    | 'blockWebsite'
    | null
  >(null);
  const [validationErrorString, setValidationErrorString] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { securityPolicy: securityPolicyData } = useSecurityPolicy();

  const { personSecurityPolicy } = usePersonSecurityPolicy(selectedFilter?.id!);
  const approveAlerts = ['approveIpAddress', 'approveWebsite'];
  const blockAlerts = ['blockIpAddress', 'blockWebsite'];

  const dispatch = useDispatch<AppDispatch>();

  const menuParentRef = useRef<Element | null>(null);

  const handleSelectTab = useCallback((selectedTab: SecurityEventHandling) => {
    setActiveTab(selectedTab);
  }, []);

  const employeeIdOfDevice = (mac: string) => {
    if (!allDevices) return;
    return (
      allDevices.data?.find((device) => device?.mac === mac)?.personId ||
      undefined
    );
  };

  const autocompleteFilteredEvents = useMemo(() => {
    if (selectedFilter && securityEvents?.length) {
      if (selectedFilter.type === AutoCompleteResultTypes.Employee) {
        return securityEvents.filter(
          (event) =>
            selectedFilter.id === employeeIdOfDevice(event?.eventData?.mac)
        );
      } else {
        return securityEvents.filter(
          (event) => selectedFilter.id === event?.eventData?.mac
        );
      }
    } else {
      return securityEvents;
    }
  }, [selectedFilter, securityEvents]);

  const handleSelectFilter = useCallback(
    (name: FilterNamePlusAll) => {
      setActiveFilter((prev) => {
        return prev === name ? 'all' : name;
      });
    },
    [setActiveFilter]
  );

  const onApproveMenuOpen = useCallback((ev: React.MouseEvent) => {
    handleTriggerEvent(ev);
    menuParentRef.current = ev.currentTarget;
    setOpenMenu('approve');
    setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    setInputValue('');
  }, []);

  const onBlockMenuOpen = useCallback((ev: React.MouseEvent) => {
    handleTriggerEvent(ev);
    menuParentRef.current = ev.currentTarget;
    setOpenMenu('block');
    setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    setInputValue('');
  }, []);

  const onApproveMenuClose = useCallback(() => {
    setOpenMenu(null);
  }, []);

  const onBlockMenuClose = useCallback(() => {
    setOpenMenu(null);
  }, []);

  const onCloseAlert = useCallback(() => {
    setValidationErrorString('');
    setOpenAlert(null);
  }, []);

  const openApproveWebsiteAlert = useCallback((ev: React.MouseEvent) => {
    setOpenAlert('approveWebsite');
  }, []);

  const openBlockWebsiteAlert = useCallback((ev: React.MouseEvent) => {
    setOpenAlert('blockWebsite');
  }, []);

  const openApproveIpAddressAlert = useCallback((ev: React.MouseEvent) => {
    setOpenAlert('approveIpAddress');
  }, []);

  const openBlockIpAddressAlert = useCallback((ev: React.MouseEvent) => {
    setOpenAlert('blockIpAddress');
  }, []);

  const getInvalidLengthErrorString = useCallback((value: string) => {
    if (!value.length) {
      switch (openAlert) {
        case 'approveIpAddress':
        case 'blockIpAddress':
          return t('shield.forms.errors.ipAddressIsRequired');
        case 'approveWebsite':
        case 'blockWebsite':
          return t('shield.forms.errors.websiteAddressIsRequired');
        default:
          return t('shield.forms.errors.fieldIsRequired');
      }
    } else {
      return '';
    }
  }, []);

  const AlertToWebsiteTypeMap: Record<string, WebsiteType> = {
    approveIpAddress: 'ipv4',
    approveWebsite: 'fqdn',
    blockIpAddress: 'ipv4',
    blockWebsite: 'fqdn',
  };

  const AlertToAlreadyExistingErrorStringMap: Record<string, string> = {
    approveIpAddress: 'shield.forms.errors.alreadyApprovedIpAddress',
    approveWebsite: 'shield.forms.errors.alreadyApprovedWebsite',
    blockIpAddress: 'shield.forms.errors.alreadyBlockedIpAddress',
    blockWebsite: 'shield.forms.errors.alreadyBlockedWebsite',
  };

  const getAlreadyListedErrorString = useCallback(
    (value: string) => {
      if (openAlert === null) {
        return '';
      }
      switch (openAlert) {
        case 'approveIpAddress':
        case 'approveWebsite':
          const alreadyApprovedWebsites =
            securityPolicyData?.data?.websites.whitelisted
              .filter(
                (website) => website.type === AlertToWebsiteTypeMap[openAlert]
              )
              .map((website) => website.value) || [];
          return alreadyApprovedWebsites.includes(value)
            ? t(AlertToAlreadyExistingErrorStringMap[openAlert])
            : '';
        case 'blockIpAddress':
        case 'blockWebsite':
          const alreadyBlockedWebsites =
            securityPolicyData?.data?.websites.blacklisted
              .filter(
                (website) => website.type === AlertToWebsiteTypeMap[openAlert]
              )
              .map((website) => website.value) || [];
          return alreadyBlockedWebsites.includes(value)
            ? t(AlertToAlreadyExistingErrorStringMap[openAlert])
            : '';
        default:
          return '';
      }
    },
    [securityPolicyData]
  );

  const getInvalidIpAddressErrorString = useCallback(
    (value: string) => {
      if (openAlert === 'approveIpAddress' || openAlert === 'blockIpAddress') {
        if (!IPV4_REGEX.test(value) && !IPV6_REGEX.test(value)) {
          return t('shield.forms.errors.ipAddressIsInvalid');
        }
      }
      return '';
    },
    [openAlert]
  );

  const doValidation = useCallback(
    (userInput: string) => {
      setValidationErrorString(
        getInvalidLengthErrorString(userInput) ||
          getAlreadyListedErrorString(userInput) ||
          getInvalidIpAddressErrorString(userInput) ||
          ''
      );
    },
    [
      getInvalidLengthErrorString,
      getAlreadyListedErrorString,
      getInvalidIpAddressErrorString,
    ]
  );

  const onInputChange = (userInput: string) => {
    doValidation(userInput.trim());
    setInputValue(userInput.trim());
  };

  const approveMenuItems: MenuItemProps[] = [
    {
      label: t('shield.eventHandling.approveWebsite'),
      onClick: openApproveWebsiteAlert,
    },
    {
      label: t('shield.eventHandling.approveIpAddress'),
      onClick: openApproveIpAddressAlert,
    },
  ];

  const blockMenuItems: MenuItemProps[] = [
    {
      label: t('shield.eventHandling.blockWebsite'),
      onClick: openBlockWebsiteAlert,
    },
    {
      label: t('shield.eventHandling.blockIpAddress'),
      onClick: openBlockIpAddressAlert,
    },
  ];

  const handleSubmitWebsiteOrIpAddress = useCallback(() => {
    if (inputValue && !validationErrorString) {
      if (openAlert === 'approveIpAddress' || openAlert === 'approveWebsite') {
        handleApprove();
      }
      if (openAlert === 'blockIpAddress' || openAlert === 'blockWebsite') {
        handleBlock();
      }
    }
  }, [inputValue, openAlert, validationErrorString]);

  const handleApprove = useCallback(() => {
    if (selectedFilter?.type === 'employee') {
      dispatch(
        actions.personSecurityPolicy.updatePersonPolicyWhitelist(
          openAlert === 'approveIpAddress' ? 'ipv4' : 'fqdn',
          inputValue,
          METHODS.POST,
          selectedFilter.id
        )
      );
    } else {
      dispatch(
        actions.settings.securityPolicy.updateLocationPolicyWhitelist(
          openAlert === 'approveIpAddress' ? 'ipv4' : 'fqdn',
          inputValue,
          METHODS.POST
        )
      );
    }

    setOpenAlert(null);
  }, [dispatch, inputValue]);

  const handleBlock = useCallback(() => {
    if (selectedFilter?.type === 'employee') {
      dispatch(
        actions.personSecurityPolicy.updatePersonPolicyBlacklist(
          openAlert === 'blockIpAddress' ? 'ipv4' : 'fqdn',
          inputValue,
          METHODS.POST,
          selectedFilter.id
        )
      );
    } else {
      dispatch(
        actions.settings.securityPolicy.updateLocationPolicyBlacklist(
          openAlert === 'blockIpAddress' ? 'ipv4' : 'fqdn',
          inputValue,
          METHODS.POST
        )
      );
    }

    setOpenAlert(null);
  }, [dispatch, inputValue]);

  const onApprovedMenuOpen = useCallback(
    (ev: React.MouseEvent, policy: Whitelist) => {
      handleTriggerEvent(ev);

      menuParentRef.current = ev.currentTarget;
      setMenuPolicy(policy);
      setOpenMenu('approved');
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    },
    []
  );

  const onBlockedMenuOpen = useCallback(
    (ev: React.MouseEvent, policy: Blacklist) => {
      handleTriggerEvent(ev);

      menuParentRef.current = ev.currentTarget;
      setMenuPolicy(policy);
      setOpenMenu('blocked');
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    },
    []
  );

  const onApprovedMenuClose = useCallback(() => {
    setOpenMenu(null);
  }, []);

  const onBlockedMenuClose = useCallback(() => {
    setOpenMenu(null);
  }, []);

  const removeApproval = useCallback(() => {
    if (menuPolicy) {
      if (selectedFilter?.type === 'employee') {
        dispatch(
          actions.personSecurityPolicy.updatePersonPolicyWhitelist(
            null,
            menuPolicy.value,
            METHODS.DELETE,
            selectedFilter.id
          )
        );
      } else {
        dispatch(
          actions.settings.securityPolicy.updateLocationPolicyWhitelist(
            null,
            menuPolicy.value,
            METHODS.DELETE
          )
        );
      }
    }
  }, [dispatch, menuPolicy]);

  const removeBlock = useCallback(() => {
    if (menuPolicy) {
      if (selectedFilter?.type === 'employee') {
        dispatch(
          actions.personSecurityPolicy.updatePersonPolicyBlacklist(
            null,
            menuPolicy.value,
            METHODS.DELETE,
            selectedFilter.id
          )
        );
      } else {
        dispatch(
          actions.settings.securityPolicy.updateLocationPolicyBlacklist(
            null,
            menuPolicy.value,
            METHODS.DELETE
          )
        );
      }
    }
  }, [dispatch, menuPolicy]);

  const viewWebsite = useCallback(() => {
    if (menuPolicy) {
      window.open('https://' + menuPolicy.value, '_blank');
    }
  }, [menuPolicy]);

  const approvedMenuItems: MenuItemProps[] =
    menuPolicy?.type === 'fqdn'
      ? [
          {
            label: t('shield.eventHandling.view'),
            iconName: IconNames.OpenBrowser,
            onClick: viewWebsite,
          },
          {
            label: t('shield.eventHandling.removeApproval'),
            iconName: IconNames.Trash,
            onClick: removeApproval,
          },
        ]
      : [
          {
            label: t('shield.eventHandling.removeApproval'),
            iconName: IconNames.Trash,
            onClick: removeApproval,
          },
        ];

  const blockedMenuItems: MenuItemProps[] =
    menuPolicy?.type === 'fqdn'
      ? [
          {
            label: t('shield.eventHandling.view'),
            iconName: IconNames.OpenBrowser,
            onClick: viewWebsite,
          },
          {
            label: t('shield.eventHandling.removeBlock'),
            iconName: IconNames.Trash,
            onClick: removeBlock,
          },
        ]
      : [
          {
            label: t('shield.eventHandling.removeBlock'),
            iconName: IconNames.Trash,
            onClick: removeBlock,
          },
        ];

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Shield,
      },
    });
  }, []);

  return (
    <Page>
      <Background ref={ref}>
        <PageHeader label={t('common.shield')} />

        <TabRow
          tabs={tabsConfig}
          activeTab={activeTab}
          onTabSelect={handleSelectTab}
        />

        <ShieldAutoComplete
          onSelectItem={setSelectedFilter!}
          activeTab={activeTab}
        />

        {activeTab === Events.Protected && (
          <>
            <Grid columnCount={columns}>
              <ShieldGraphCard
                columnSpan={columns}
                eventsData={autocompleteFilteredEvents || []}
                isLoading={isLoading}
                errorMessage={errorMessage}
                activeTypeFilter={activeFilter}
                onTypeFilterClick={handleSelectFilter}
              />
            </Grid>
            <GridSectionGap />

            <ShieldEvents
              columnSpan={columns}
              events={autocompleteFilteredEvents || []}
              devices={allDevices.data || ([] as ZoneDevice[])}
              isLoading={isLoading}
              errorMessage={errorMessage}
              activeTypeFilter={activeFilter}
            />
          </>
        )}

        {activeTab === Events.Approved && (
          <Grid columnCount={1}>
            <Card noBottomPadding>
              <StandardListItem
                className={styles.hot500}
                L2Props={{
                  label:
                    `${t('shield.eventHandling.approve')}...` || 'Approve...',
                }}
                onClick={onApproveMenuOpen}
                ariaLabel={t('shield.eventHandling.approve')}
              />
            </Card>

            <Menu
              isOpen={openMenu === 'approved'}
              parent={menuParentRef.current}
              onClose={onApprovedMenuClose}
              items={approvedMenuItems}
              openTriggerEventType={menuOpenTrigger}
            />

            <Menu
              isOpen={openMenu === 'approve'}
              parent={menuParentRef.current}
              onClose={onApproveMenuClose}
              items={approveMenuItems}
              openTriggerEventType={menuOpenTrigger}
            />

            <AlertModal
              isOpen={approveAlerts.includes(openAlert || '')}
              onClose={onCloseAlert}
            >
              <Alert
                topProps={{
                  label:
                    openAlert === 'approveIpAddress'
                      ? t('shield.eventHandling.approveIpAddress')
                      : t('shield.eventHandling.approveWebsite'),
                }}
                middleProps={{
                  listInputProps: {
                    value: inputValue,
                    onChange: onInputChange,
                    placeholder:
                      openAlert === 'approveIpAddress'
                        ? t('shield.forms.placeholders.ipAddress')
                        : t('shield.forms.placeholders.website'),
                    paragraph: validationErrorString,
                    hasError: Boolean(validationErrorString),
                    onSubmit: handleSubmitWebsiteOrIpAddress,
                  },
                }}
                bottomProps={{
                  button1Props: {
                    label:
                      selectedFilter?.id && selectedFilter?.type === 'employee'
                        ? t('shield.eventHandling.approvedFor', {
                            employee: selectedFilter?.label,
                          })
                        : t('shield.eventHandling.approve'),
                    onClick: handleSubmitWebsiteOrIpAddress,
                    disabled:
                      Boolean(!inputValue) || Boolean(validationErrorString),
                  },
                  button2Props: {
                    label: t('common.cancel'),
                    onClick: onCloseAlert,
                    theme: BUTTON_THEMES.white,
                  },
                }}
              />
            </AlertModal>

            {!selectedFilter?.id &&
              !securityPolicyData?.isLoading &&
              !!securityPolicyData?.data?.websites.whitelisted.length && (
                <Card noBottomPadding>
                  {securityPolicyData.data.websites.whitelisted.map(
                    (website, i) => (
                      <StandardListItem
                        key={i}
                        L2Props={{
                          label: website.value,
                        }}
                        RProps={{
                          icon1Props: {
                            name: IconNames.OverflowVertical,
                            tooltipLabel: t('common.moreActions'),
                            ariaLabel: t('common.moreActions'),
                            onClick: (ev) => {
                              onApprovedMenuOpen(ev, website);
                            },
                          },
                        }}
                        ariaLabel={website.value}
                      />
                    )
                  )}
                </Card>
              )}

            {selectedFilter?.type === 'employee' &&
              personSecurityPolicy?.data && (
                <Card>
                  {personSecurityPolicy.data.websites.whitelisted.map(
                    (website, i) => (
                      <StandardListItem
                        key={i}
                        L2Props={{
                          label: website.value,
                        }}
                        RProps={{
                          icon1Props: {
                            name: IconNames.OverflowVertical,
                            tooltipLabel: t('common.moreActions'),
                            ariaLabel: t('common.moreActions'),
                            onClick: (ev) => onApprovedMenuOpen(ev, website),
                          },
                        }}
                        ariaLabel={website.value}
                      />
                    )
                  )}
                </Card>
              )}
          </Grid>
        )}

        {activeTab === Events.Blocked && (
          <>
            <Grid columnCount={1}>
              <Card noBottomPadding>
                <StandardListItem
                  className={styles.hot500}
                  L2Props={{
                    label:
                      `${t('shield.eventHandling.block')}...` || 'Approve...',
                  }}
                  onClick={onBlockMenuOpen}
                  ariaLabel={t('shield.eventHandling.block')}
                />
              </Card>

              <Menu
                isOpen={openMenu === 'blocked'}
                parent={menuParentRef.current}
                onClose={onBlockedMenuClose}
                items={blockedMenuItems}
                openTriggerEventType={menuOpenTrigger}
              />

              <Menu
                isOpen={openMenu === 'block'}
                parent={menuParentRef.current}
                onClose={onBlockMenuClose}
                items={blockMenuItems}
                openTriggerEventType={menuOpenTrigger}
              />

              <AlertModal
                isOpen={blockAlerts.includes(openAlert || '')}
                onClose={onCloseAlert}
              >
                <Alert
                  topProps={{
                    label:
                      openAlert === 'blockIpAddress'
                        ? t('shield.eventHandling.blockIpAddress')
                        : t('shield.eventHandling.blockWebsite'),
                  }}
                  middleProps={{
                    listInputProps: {
                      value: inputValue,
                      onChange: onInputChange,
                      placeholder:
                        openAlert === 'blockIpAddress'
                          ? t('shield.forms.placeholders.ipAddress')
                          : t('shield.forms.placeholders.website'),
                      paragraph: validationErrorString,
                      hasError: Boolean(validationErrorString),
                      onSubmit: handleSubmitWebsiteOrIpAddress,
                    },
                  }}
                  bottomProps={{
                    button1Props: {
                      label:
                        selectedFilter?.id &&
                        selectedFilter?.type === 'employee'
                          ? t('shield.eventHandling.blockedFor', {
                              employee: selectedFilter?.label,
                            })
                          : t('shield.eventHandling.block'),
                      onClick: handleSubmitWebsiteOrIpAddress,
                      disabled:
                        Boolean(!inputValue) || Boolean(validationErrorString),
                    },
                    button2Props: {
                      label: t('common.cancel'),
                      onClick: onCloseAlert,
                      theme: BUTTON_THEMES.white,
                    },
                  }}
                />
              </AlertModal>

              {!selectedFilter?.id &&
                !securityPolicyData?.isLoading &&
                !!securityPolicyData?.data?.websites.blacklisted.length && (
                  <Card noBottomPadding>
                    {securityPolicyData.data.websites.blacklisted.map(
                      (website, i) => (
                        <StandardListItem
                          key={i}
                          L2Props={{
                            label: website.value,
                          }}
                          RProps={{
                            icon1Props: {
                              name: IconNames.OverflowVertical,
                              tooltipLabel: t('common.moreActions'),
                              ariaLabel: t('common.moreActions'),
                              onClick: (ev) => onBlockedMenuOpen(ev, website),
                            },
                          }}
                          ariaLabel={website.value}
                        />
                      )
                    )}
                  </Card>
                )}
              {selectedFilter?.type === 'employee' &&
                personSecurityPolicy?.data && (
                  <Card noBottomPadding>
                    {personSecurityPolicy.data.websites.blacklisted.map(
                      (website, i) => (
                        <StandardListItem
                          key={i}
                          L2Props={{
                            label: website.value,
                          }}
                          RProps={{
                            icon1Props: {
                              name: IconNames.OverflowVertical,
                              tooltipLabel: t('common.moreActions'),
                              ariaLabel: t('common.moreActions'),
                              onClick: (ev) => onBlockedMenuOpen(ev, website),
                            },
                          }}
                          ariaLabel={website.value}
                        />
                      )
                    )}
                  </Card>
                )}
            </Grid>
          </>
        )}
      </Background>
    </Page>
  );
};

export default Shield;
