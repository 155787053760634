import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'Consts/routes';
import { Mac } from 'Consts/types';

export default function useNavigateToDevice() {
  const navigate = useNavigate();

  return (mac: Mac) => navigate(`${ROUTES.device}/${encodeURI(mac)}`);
}
