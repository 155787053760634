import cn from 'classnames';
import React from 'react';

import { Body1Medium, Body3, Heading4Medium } from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';

type WlanHeadingProps = {
  label: string;
  paragraph: string;
  id?: string;
};

export const WlanHeading = ({ label, paragraph, id }: WlanHeadingProps) => {
  return (
    <div className={styles.wlanHeading}>
      <Heading4Medium id={id} className={styles.headingLeft}>
        {label}
      </Heading4Medium>
      <Body3 className={cn(colorStyles.still400, styles.headingRight)}>
        {paragraph}
      </Body3>
    </div>
  );
};

type WlanSubheadingProps = {
  label: string;
  paragraph: string;
  id?: string;
};

const WlanSubheading = ({ label, paragraph, id }: WlanSubheadingProps) => {
  return (
    <div className={styles.wlanSubheading} id={id}>
      <Body1Medium className={styles.headingLeft}>{label}</Body1Medium>
      <Body3 className={cn(colorStyles.still400, styles.headingRight)}>
        {paragraph}
      </Body3>
    </div>
  );
};

export default WlanSubheading;
