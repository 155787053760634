import useGuestEmailCollectionInfo from 'State/hooks/useGuestEmailCollectionInfo';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { singletonHook } from 'react-singleton-hook';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';

export const useGuestDownloadStateMachine = singletonHook(
  {
    checkForDownload: () => undefined,
    handleDownloadGuests: () => undefined,
    handleSetEmailCapture: () => undefined,
    isDownloadAlertOpen: false,
    isEmailCaptureAlertOpen: false,
    isNoEmailsToDownloadAlertOpen: false,
    closeDownloadAlert: () => undefined,
    closeEmailCaptureAlert: () => undefined,
    closeNoEmailsToDownloadAlert: () => undefined,
  },
  () => {
    const dispatch = useDispatch<AppDispatch>();
    const trackEvent = useTrackEvent();
    const [isDownloadAlertOpen, setIsDownloadAlertOpen] = useState(false);
    const [isEmailCaptureAlertOpen, setIsEmailCaptureAlertOpen] =
      useState(false);
    const [isNoEmailsToDownloadAlertOpen, setIsNoEmailsToDownloadAlertOpen] =
      useState(false);
    const {
      data: guestEmailCollectionInfo,
      isLoading: guestEmailCollectionInfoIsLoading,
    } = useGuestEmailCollectionInfo();
    const closeDownloadAlert = useCallback(() => {
      setIsDownloadAlertOpen(false);
    }, []);

    const closeEmailCaptureAlert = useCallback(() => {
      setIsEmailCaptureAlertOpen(false);
    }, []);

    const closeNoEmailsToDownloadAlert = useCallback(() => {
      setIsNoEmailsToDownloadAlertOpen(false);
    }, []);

    const checkForDownload = useCallback(() => {
      if (
        guestEmailCollectionInfo?.downloadAvailable ||
        guestEmailCollectionInfo?.emailCollectionEnabled
      ) {
        // if the emailCount attribute is not available assume earlier code version so use '1'
        if (guestEmailCollectionInfo?.emailCount ?? 1 > 0) {
          trackEvent({
            eventName: MixPanelEvents.GUEST_DOWNLOAD_CSV_MESSAGE,
          });
          setIsDownloadAlertOpen(true);
        } else {
          trackEvent({
            eventName: MixPanelEvents.GUEST_DOWNLOAD_NO_EMAILS_MESSAGE,
          });
          setIsNoEmailsToDownloadAlertOpen(true);
        }
      } else {
        trackEvent({
          eventName: MixPanelEvents.GUEST_DOWNLOAD_TURN_ON_MESSAGE,
        });
        setIsEmailCaptureAlertOpen(true);
      }
    }, [guestEmailCollectionInfo, guestEmailCollectionInfoIsLoading]);

    const handleDownloadGuests = useCallback(() => {
      dispatch(actions.guests.downloadGuestDetails());
      closeDownloadAlert();
    }, [dispatch, closeDownloadAlert]);

    const handleSetEmailCapture = useCallback(() => {
      dispatch(actions.guestEmailCollection.enableEmailCapture());
      closeEmailCaptureAlert();
    }, [dispatch, closeEmailCaptureAlert]);

    return {
      checkForDownload: checkForDownload,
      handleDownloadGuests,
      handleSetEmailCapture,
      isDownloadAlertOpen,
      isEmailCaptureAlertOpen,
      isNoEmailsToDownloadAlertOpen,
      closeDownloadAlert,
      closeEmailCaptureAlert,
      closeNoEmailsToDownloadAlert,
    };
  }
);
