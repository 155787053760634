import React, { FunctionComponent, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './style.module.css';

const Columns: FunctionComponent<
  PropsWithChildren<{
    columnCount?: number;
    topMargin?: number;
  }>
> = ({ children, columnCount = 1, topMargin = 0 }) => {
  const classes = cx(styles.columns, {
    [styles.oneColumn]: columnCount === 1,
    [styles.twoColumns]: columnCount === 2,
    [styles.threeColumns]: columnCount === 3,
  });
  return (
    <div
      className={classes}
      style={{
        marginTop: `${topMargin}px`,
      }}
    >
      {children}
    </div>
  );
};

export default Columns;
