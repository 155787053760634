import React, {
  FunctionComponent,
  useEffect,
  useState,
  useReducer,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import Card from 'UI/Elements/Card';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from 'trackingAnalytics/types';
import InputListItem from 'UI/Components/Lists/List input';
import {
  getBusinessTypeList,
  getSellOnlineOptionList,
  mapLabelToField,
  mapDropdownValueToField,
} from './businessInfoConfiguration.helper';
import V2ListItem from '../../../../../UI/Molecules/ListItem';
import { Body1 } from 'UI/Elements/Typography';
import Icon, { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import { BusinessInfoState, BusinessInfoAction, INPUT_FIELDS } from './types';
import Menu, { MenuItemProps } from 'UI/Components/Menu';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';
import ListSearchDropdown from 'UI/Components/Lists/List search dropdown/ListSearchDropdown';
import { businessCategories } from './businessCategories';
import { ListSearchDropdownResultsItemType } from 'UI/Components/Lists/List search dropdown/ListSearchDropdownResults';
import Button from 'UI/Elements/Button';
import cn from 'classnames';
import { isSettingsRoute } from 'Consts/routes';
import { useLocation } from 'react-router-dom';
import { SidepanelKeys, SIDEPANELS } from '../../../SettingsContent';
import * as actions from 'State/actions';
import { OpenTriggerEventType } from 'Consts/types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

const businessInfoInitialState: BusinessInfoState = {
  businessName: '',
  businessCategory: '',
  businessType: '',
  businessSellOnline: '',
  businessAddress: '',
  businessEmail: '',
  businessPhoneNumber: '',
};

function businessInfoReducer(
  state: BusinessInfoState,
  action: BusinessInfoAction
) {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
}

type BusinessInfoProps = {
  openSidepanel: (key: SidepanelKeys) => void;
};

const BusinessInfo: FunctionComponent<BusinessInfoProps> = ({
  openSidepanel,
}) => {
  const isInitialMount = useRef(true);
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();
  const trackEvent = useTrackEvent();
  const [businessInfoData, dispatch] = useReducer(
    businessInfoReducer,
    businessInfoInitialState
  );
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const [businessTypeMenuOpen, setBusinessTypeMenuOpen] = useState(false);
  const [businessSellOnlineMenuOpen, setBusinessSellOnlineMenuOpen] =
    useState(false);
  const parentRef = useRef<Element | null>(null);
  const [selectedBusinessCategory, setSelectedBusinessCategory] = useState<
    ListSearchDropdownResultsItemType | undefined
  >();
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const routerLocation = useLocation();
  const currentRoute = routerLocation.pathname;
  const dispatchReduxAction = useDispatch<AppDispatch>();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.SettingsBusinessInfo,
      },
    });
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setShowSaveBtn(true);
    }
  }, [businessInfoData]);

  const handleChange = (name: string, value: string) => {
    const field = mapLabelToField(name);
    dispatch({ type: 'UPDATE_FIELD', field, value: value as string });
  };

  const handleDropdownItemSelect = (event: React.MouseEvent<Element>) => {
    const value = (event.target as HTMLElement).innerText;
    const field = mapDropdownValueToField(value);
    dispatch({ type: 'UPDATE_FIELD', field, value });
  };

  const handleSearchDropdownItemSelect = (
    searchDropdownItem: ListSearchDropdownResultsItemType
  ) => {
    const value = searchDropdownItem.id;
    const field = mapDropdownValueToField(value);
    dispatch({ type: 'UPDATE_FIELD', field, value });
  };

  const handleDropdownClick = (
    ev: React.MouseEvent,
    dropdownType:
      | INPUT_FIELDS.businessType
      | INPUT_FIELDS.businessCategory
      | INPUT_FIELDS.businessSellOnline
  ) => {
    handleTriggerEvent(ev);
    setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    parentRef.current = ev.currentTarget.querySelector(
      '[data-testid="dropdownIcon"]'
    );

    switch (dropdownType) {
      case INPUT_FIELDS.businessType:
        return setBusinessTypeMenuOpen(true);
      case INPUT_FIELDS.businessSellOnline:
        return setBusinessSellOnlineMenuOpen(true);
      default:
        break;
    }
  };

  const onBusinessCategorySearch = (search: string) => {
    const filteredCategories = businessCategories.filter((category) => {
      return category.id.toLowerCase().includes(search.toLowerCase());
    });

    return Promise.resolve(filteredCategories);
  };

  const handleBusinessHoursSidepanel = (ev: React.MouseEvent) => {
    handleTriggerEvent(ev);

    dispatchReduxAction(
      actions.ui.page.setSidepanelOpenTriggerType(
        ev.type as OpenTriggerEventType
      )
    );
    openSidepanel(SIDEPANELS.businessInfoHours);
  };

  return (
    <div
      className={styles.main}
      role="region"
      aria-labelledby={t('settings.businessInfo.title')}
    >
      <Card
        noBottomPadding
        header={{ L2Props: { label: t('common.general') } }}
      >
        <div className={styles.form}>
          <InputListItem
            smallLabel={t('settings.businessInfo.businessName')}
            value={businessInfoData.businessName}
            onChange={(e) =>
              handleChange(t('settings.businessInfo.businessName'), e)
            }
            noIcon
            disableAutofill
          />
          <ListSearchDropdown
            fullList={businessCategories}
            onSearch={onBusinessCategorySearch}
            openOnClick
            showSelection
            configuration={{ displayFilters: false }}
            smallLabel={t('settings.businessInfo.businessCategory')}
            selectedItem={selectedBusinessCategory}
            setSelectedItem={setSelectedBusinessCategory}
            onItemClick={(e) => handleSearchDropdownItemSelect(e)}
          />
          <InputListItem
            smallLabel={t('settings.businessInfo.businessType')}
            value={businessInfoData.businessType}
            onDropdownClick={(ev) =>
              handleDropdownClick(ev, INPUT_FIELDS.businessType)
            }
            noIcon
            inputType="dropdown"
            dropdownMenuExpanded={businessTypeMenuOpen}
          />
          <Menu
            isOpen={businessTypeMenuOpen}
            parent={parentRef.current}
            onClose={() => setBusinessTypeMenuOpen(false)}
            items={
              getBusinessTypeList(handleDropdownItemSelect) as MenuItemProps[]
            }
            openTriggerEventType={menuOpenTrigger}
          />
          <InputListItem
            smallLabel={t('settings.businessInfo.businessSellOnline')}
            value={businessInfoData.businessSellOnline}
            onDropdownClick={(ev) =>
              handleDropdownClick(ev, INPUT_FIELDS.businessSellOnline)
            }
            noIcon
            inputType="dropdown"
            dropdownMenuExpanded={businessSellOnlineMenuOpen}
          />
          <Menu
            isOpen={businessSellOnlineMenuOpen}
            parent={parentRef.current}
            onClose={() => setBusinessSellOnlineMenuOpen(false)}
            items={
              getSellOnlineOptionList(
                handleDropdownItemSelect
              ) as MenuItemProps[]
            }
            openTriggerEventType={menuOpenTrigger}
          />
        </div>
      </Card>
      <Card
        noBottomPadding
        header={{ L2Props: { label: t('settings.businessInfo.contact') } }}
      >
        <div className={styles.form}>
          <InputListItem
            smallLabel={t('settings.businessInfo.businessAddress')}
            value={businessInfoData.businessAddress}
            onChange={(e) =>
              handleChange(t('settings.businessInfo.businessAddress'), e)
            }
            noIcon
            disableAutofill
          />
          <InputListItem
            smallLabel={t('settings.businessInfo.businessEmail')}
            value={businessInfoData.businessEmail}
            onChange={(e) =>
              handleChange(t('settings.businessInfo.businessEmail'), e)
            }
            noIcon
            disableAutofill
          />
          <InputListItem
            smallLabel={t('settings.businessInfo.businessPhoneNumber')}
            value={businessInfoData.businessPhoneNumber}
            onChange={(e) =>
              handleChange(t('settings.businessInfo.businessPhoneNumber'), e)
            }
            noIcon
            disableAutofill
          />
          <V2ListItem
            main={<Body1>{t('settings.businessInfo.businessHours')}</Body1>}
            ariaLabel={t('settings.businessInfo.businessHours')}
            right={
              <Icon name={IconNames.ChevronRight} shape={IconShape.square} />
            }
            noHorizontalPadding
            onClick={handleBusinessHoursSidepanel}
          />
        </div>
      </Card>

      <div className={styles.saveBtnContainer}>
        {showSaveBtn && (
          <Button
            className={cn(styles.saveBtn, {
              [styles.settingsNavBarButton]: isSettingsRoute(currentRoute),
            })}
            label={t('common.save')}
            onClick={() => console.log('Save')}
            theme="black"
          />
        )}
      </div>
    </div>
  );
};

export default BusinessInfo;
