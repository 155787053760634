import Account from 'Pages/Settings/SettingsContent/Components/Account';
import Pods from 'Pages/Settings/SettingsContent/Components/Pods';
import Support from 'Pages/Settings/SettingsContent/Components/Support';
import ScreenHeader from 'UI/Components/Headers/Header screen web';
import Background from 'UI/Layout/Background';
import Columns from 'UI/Layout/Columns';
import RestrictedViewPage from 'UI/Layout/RestrictedViewPage/RestrictedViewPage';
import useLayoutColumns from 'Utils/hooks/useLayout';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../SsidConfiguration/SsidConfigurationUI/style.module.css';
import SsidSetup from './SsidConfigurationUI/SsidSetup';
import SsidConfigurationInfo from './SsidConfigurationUI/SSIDConfigurationInfo';
import { useSelector } from 'react-redux';
import * as selectors from 'State/selectors';
import useLocations from 'State/hooks/useLocations';
import { CloudBackend, WifiNetwork } from 'Consts/types';
import * as api from 'Api/endpoints';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';

export enum SSID_CONFIGURATION_LIST {
  ssidConfiguration = 'ssidConfiguration',
  pods = 'pods',
  support = 'support',
  account = 'account',
  secure = 'secure',
  employee = 'employee',
  guest = 'guest',
}

export type SsidConfigurationProps = {
  active?: SSID_CONFIGURATION_LIST;
};

const SsidConfiguration: FunctionComponent<SsidConfigurationProps> = ({
  active,
}) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const [columns, ref] = useLayoutColumns();
  const [secureSsidConfig, setSecureSsidConfig] = useState<
    WifiNetwork | undefined
  >();
  const [employeeSsidConfig, setEmployeeSsidConfig] = useState<
    { ssid: string; encryptionKey: string } | undefined
  >();
  const [guestSsidConfig, setGuestSsidConfig] = useState<
    { ssid: string } | undefined
  >();
  const customerId = useSelector(selectors.locations.activeLocation)?.data
    ?.customerId;
  const { activeLocation } = useLocations();
  const { data: token } = useSelector(selectors.auth.locationToken);
  const cloud = useSelector(selectors.auth.cloud);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SSID_CONFIGURATION,
      additionalContent: {
        SCREEN: AvailableScreens.SsidConfiguration,
      },
    });
  }, []);

  const fetchExistingConfigurations = async (
    customerId: string,
    locationId: string,
    token: string,
    cloud: CloudBackend
  ) => {
    Promise.all([
      api.getDefaultWifiNetwork({
        customerId,
        locationId: locationId,
        token,
        cloud,
      }),
      api.getEmployeeWifiNetworks({
        customerId,
        locationId: locationId,
        token,
        cloud,
      }),
      api.getGuestWifiNetworks({
        customerId,
        locationId: locationId,
        token,
        cloud,
      }),
    ])
      .then(([secure, employee, guest]) => {
        const { data: secureNetworkData, error: secureNetworkError } = secure;
        const { data: employeeNetworkData, error: employeeNetworkError } =
          employee;
        const { data: guestNetworkData, error: guestNetworkError } = guest;

        if (secureNetworkError || employeeNetworkError || guestNetworkError) {
          console.error('error fetching data');
        }

        setSecureSsidConfig(secureNetworkData?.wifiNetwork);
        setEmployeeSsidConfig(
          employeeNetworkData?.[0]
            ? {
                ssid: employeeNetworkData[0].ssid,
                encryptionKey: employeeNetworkData[0].encryptionKey,
              }
            : undefined
        );
        setGuestSsidConfig(
          guestNetworkData?.[0]
            ? {
                ssid: guestNetworkData[0].ssid,
              }
            : undefined
        );
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (!customerId || !activeLocation?.data?.id || !token || !cloud) {
      return;
    }

    fetchExistingConfigurations(
      customerId,
      activeLocation?.data?.id,
      token,
      cloud
    );
  }, [customerId, activeLocation?.data?.id, token, cloud]);

  const renderContent = () => {
    switch (active) {
      case SSID_CONFIGURATION_LIST.ssidConfiguration:
        return <SsidConfigurationInfo />;
      case SSID_CONFIGURATION_LIST.pods:
        return (
          <>
            <ScreenHeader LProps={{ label: t('settings.pods') }} />
            <Pods />
          </>
        );
      case SSID_CONFIGURATION_LIST.support:
        return (
          <>
            <ScreenHeader LProps={{ label: t('settings.support') }} />
            <Support />
          </>
        );
      case SSID_CONFIGURATION_LIST.account:
        return (
          <>
            <ScreenHeader LProps={{ label: t('settings.account') }} />
            <Account />
          </>
        );
      case SSID_CONFIGURATION_LIST.secure:
        return (
          <SsidSetup
            ssidType="secure"
            existingConfiguration={secureSsidConfig}
            locationName={activeLocation?.data?.name}
          />
        );
      case SSID_CONFIGURATION_LIST.employee:
        return (
          <SsidSetup
            ssidType="employee"
            existingConfiguration={employeeSsidConfig}
            locationName={activeLocation?.data?.name}
          />
        );
      case SSID_CONFIGURATION_LIST.guest:
        return (
          <SsidSetup
            ssidType="guest"
            existingConfiguration={guestSsidConfig}
            locationName={activeLocation?.data?.name}
          />
        );
      default:
        return <SsidConfigurationInfo />;
    }
  };

  return (
    <RestrictedViewPage>
      <Background ref={ref} contentClassName={styles.content}>
        <Columns columnCount={columns}>{renderContent()}</Columns>
      </Background>
    </RestrictedViewPage>
  );
};

export default SsidConfiguration;
