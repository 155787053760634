import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useGuests = () =>
  useUpdatedData({
    selector: selectors.guests,
    action: actions.guests.fetchData(),
  });

export default useGuests;
