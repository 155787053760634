import React, { FunctionComponent, useEffect } from 'react';
import styles from './style.module.css';
import ScreenHeader from 'UI/Components/Headers/Header screen web';
import { useTranslation } from 'react-i18next';
import { IconNames } from 'UI/Elements/Icon';
import { useNavigate } from 'react-router-dom';
import { IconShape } from 'UI/Elements/Icon/icons';
import EmployeeLoginMobilePreview from './EmployeeLoginMobilePreview';
import { SIDEPANELS, SidepanelKeys } from 'Pages/Settings/SettingsContent';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from 'State/selectors';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from 'trackingAnalytics/types';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import { OpenTriggerEventType } from 'Consts/types';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

export type EmployeeLoginProps = {
  openSidepanel: (key: SidepanelKeys) => void;
};

const EmployeeLogin: FunctionComponent<EmployeeLoginProps> = ({
  openSidepanel,
}) => {
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();
  const navigate = useNavigate();
  const { closeSidepanel } = useSidepanel();
  const sidepanelOpen = useSelector(selectors.ui.page.sidepanelOpen);
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch<AppDispatch>();
  const databaseCompanyName = useSelector(selectors.settings.employeeLogin).data
    ?.databaseData.companyName;

  const localCompanyName = useSelector(selectors.settings.employeeLogin).data
    ?.localData.companyName;
  const localRef = React.useRef<string>();
  localRef.current = localCompanyName;
  const dbRef = React.useRef<string>();
  dbRef.current = databaseCompanyName;

  useEffect(() => {
    return () => {
      if (localRef !== dbRef) {
        dispatch(
          actions.settings.employeeLogin.updateInput({
            updatedCompanyName: dbRef.current || '',
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.EMPLOYEE_LOGIN,
      additionalContent: {
        SCREEN: AvailableScreens.EmployeeLogin,
      },
    });
  }, []);

  useEffect(() => {
    openSidepanel(SIDEPANELS.editEmployeeLogin);
  }, []);

  const handleBackClick = () => {
    closeSidepanel();
    navigate(-1);
  };

  return (
    <div className={styles.employeeLogin}>
      <ScreenHeader
        className={styles.handleBandwidthLimitUpdateeaderScreen}
        LProps={{
          label: t('settings.employeeLogin'),
          iconProps: {
            name: IconNames.ChevronLeft,
            onClick: handleBackClick,
            tooltipLabel: t('common.goBack'),
            shape: IconShape.square,
          },
        }}
        RProps={
          !sidepanelOpen
            ? {
                webButtonPillProps: {
                  label: t('common.edit'),
                  className: `${styles.editBtn}`,
                  onClick: (ev: React.MouseEvent) => {
                    handleTriggerEvent(ev);

                    dispatch(
                      actions.ui.page.setSidepanelOpenTriggerType(
                        ev.type as OpenTriggerEventType
                      )
                    );
                    openSidepanel(SIDEPANELS.editEmployeeLogin);
                  },
                },
              }
            : {}
        }
      />

      <EmployeeLoginMobilePreview />
    </div>
  );
};

export default EmployeeLogin;
