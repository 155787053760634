import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { TooltipProps } from 'recharts';
import { useTranslation } from 'react-i18next';

import type {
  InternetSpeed,
  Nullable,
  Period,
  SpeedDimension,
} from 'Consts/types';

import BarGraph from 'UI/Components/Graphs/BarGraph';

import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { Card } from 'UI/Elements/Card';
import { Body2 } from 'UI/Elements/Typography';
import Tooltip from 'UI/Elements/Tooltip';

import { chartToData } from './utils';

import colorStyles from 'Styles/colors.module.css';
import styles from './style.module.css';
import * as selectors from 'State/selectors';
import { useSelector } from 'react-redux';

const SpeedTestGraphTooltip = ({
  label,
  payload,
}: TooltipProps<string, string>) => {
  return (
    <Tooltip>
      <Body2>{payload?.[0]?.value ?? 0} Mbps</Body2>
      <Body2 className={colorStyles.still400}>{label}</Body2>
    </Tooltip>
  );
};

type SpeedTestGraphCardProps = {
  isLoading?: boolean;
  errorMessage?: string;
  secondLabel?: string;
};

type SpeedTestGraphProps = {
  isLoading?: boolean;
  errorMessage?: string;
  chart: Nullable<InternetSpeed[]>;
  period: Period;
  speedDimension: SpeedDimension;
  onCheckSpeedClick: React.MouseEventHandler;
};

export const SpeedTestGraphCard: FunctionComponent<
  PropsWithChildren<SpeedTestGraphCardProps>
> = ({ isLoading, errorMessage, secondLabel, children }) => {
  const { t } = useTranslation();

  return (
    <Card
      isLoading={isLoading}
      errorMessage={errorMessage}
      className={styles.speedTestGraphCard}
      header={{
        L2Props: {
          label: t('wlan.speedTest'),
          secondLabel: isLoading ? '' : secondLabel,
        },
      }}
    >
      {children}
    </Card>
  );
};

const SpeedTestGraph: FunctionComponent<SpeedTestGraphProps> = ({
  isLoading,
  errorMessage,
  chart,
  period,
  speedDimension,
  onCheckSpeedClick,
}) => {
  const { t } = useTranslation();

  const { data, minSpeed, maxSpeed } = useMemo(() => {
    return chartToData(chart, period, speedDimension);
  }, [chart, period, speedDimension]);

  const activeLocation = useSelector(selectors.locations.activeLocation);
  const networkStatus = activeLocation.data?.summary?.gatewayConnectionState;

  const disableButton = useMemo(() => {
    if (networkStatus === 'disconnected') return true;
    return false;
  }, [networkStatus]);

  const secondLabel =
    minSpeed && maxSpeed
      ? `${minSpeed?.toFixed(0)}-${maxSpeed?.toFixed(0)} Mbps`
      : t('common.noData');

  return (
    <SpeedTestGraphCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      secondLabel={secondLabel}
    >
      <BarGraph
        data={data}
        graphGradient={{ color1: '#6560FF', color2: '#803FCE' }}
        customTooltip={SpeedTestGraphTooltip}
        interval={3}
        ariaLabel={t('wlan.speedTestChartAriaLabel')}
      />

      <div className={styles.btn}>
        <Button
          label={t('wlan.checkSpeedNow')}
          theme={BUTTON_THEMES.white}
          onClick={onCheckSpeedClick}
          fullWidth
          disabled={disableButton}
        />
      </div>
    </SpeedTestGraphCard>
  );
};

export default SpeedTestGraph;
