import { ROUTES } from 'Consts/routes';

import useEmployeeMonthlyAppsOnlineTime from 'State/hooks/useEmployeeMonthlyAppsOnlineTime';
import usePersonMonthlyAppsOnlineTime from 'State/hooks/usePersonMonthlyAppsOnlineTime';

import { AppTimeProps, AppTimeUI } from 'UI/Reusable/AppTime';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

export const AppTimeEmployeeZone: FunctionComponent<AppTimeProps> = ({
  className,
  maxItems,
}) => {
  const employeeAppsTime = useEmployeeMonthlyAppsOnlineTime();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <AppTimeUI
      className={className}
      maxItems={maxItems}
      isLoading={employeeAppsTime.isLoading}
      errorMessage={employeeAppsTime.errorMessage}
      applications={employeeAppsTime.data?.dailyStats?.[0]?.apps ?? []}
      title={t('common.appTime') as string}
      viewAllHandler={() =>
        navigate(
          generatePath(ROUTES.appTimeInsightsByNetworkId, {
            network: 'employee',
          })
        )
      }
    />
  );
};

export const AppTimeSpecificEmployee: FunctionComponent<AppTimeProps> = ({
  className,
  maxItems,
  personId,
}) => {
  const employeeAppsTime = usePersonMonthlyAppsOnlineTime(personId || '');
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <AppTimeUI
      className={className}
      maxItems={maxItems}
      isLoading={employeeAppsTime.isLoading}
      errorMessage={employeeAppsTime.errorMessage}
      applications={employeeAppsTime.data?.dailyStats?.[0]?.apps ?? []}
      title={t('common.appTime') as string}
      viewAllHandler={() =>
        navigate(
          generatePath(ROUTES.appTimeInsightsByEmployeeId, {
            personId,
          })
        )
      }
    />
  );
};
