import cn from 'classnames';
import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './style.module.css';

type BackgroundProps = {
  contentClassName?: string;
  columns?: number;
} & JSX.IntrinsicElements['div'];

const Background = forwardRef<
  HTMLDivElement,
  PropsWithChildren<BackgroundProps>
>(({ className, contentClassName, columns, children }, ref) => (
  <div ref={ref} className={cn(styles.background, className)}>
    <div className={cn(styles.content, contentClassName)}>{children}</div>
  </div>
));

export default Background;
