import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Page from 'UI/Layout/Page';
import Background from 'UI/Layout/Background';
import PageHeader from 'UI/Layout/Page header';

import Guests from 'UI/Reusable/Guests';

import useGuestNetworkUsage from 'State/hooks/useGuestNetworkUsage';

import useLayoutColumns from 'Utils/hooks/useLayout';

import GuestDataUsage from '../Components/Guest graph';

import GuestsByHour from './Components/Guests by hour';
import { Analytics } from './Components/Analytics';
import PopularDevices from './Components/Popular devices';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import Columns from '../../../UI/Layout/Columns';
import AppTimeGuestZone from 'UI/Reusable/AppTime/AppTimeGuestZone';

export const GuestZone: FunctionComponent = () => {
  const [columns, ref] = useLayoutColumns();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();

  const { data } = useGuestNetworkUsage();

  const guestByHour = <GuestsByHour data={data} />;
  const analytics = (
    <Analytics headerLabel={t('guestZone.analytics')} data={data} />
  );
  const guests = <Guests maxItems={8} />;
  const popularDevices = <PopularDevices />;
  const guestDataUsage = <GuestDataUsage />;
  const appTime = <AppTimeGuestZone maxItems={6} />;

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.GuestZone,
      },
    });
  }, []);

  return (
    <Page>
      <Background ref={ref}>
        <PageHeader label={t('common.guestZone')} />

        <Columns columnCount={columns} topMargin={16}>
          {guestByHour}
          {analytics}
          {guests}
          {popularDevices}
          {appTime}
          {guestDataUsage}
        </Columns>
      </Background>
    </Page>
  );
};
