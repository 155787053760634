import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'Consts/routes';

export default function useNavigateToEmployeeAssignedDevices() {
  const navigate = useNavigate();

  return (id: string) =>
    navigate(`${ROUTES.employee}/${encodeURI(id)}/assigned-devices`);
}
