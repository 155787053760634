import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDeviceMonthlyDataUsage = (mac: string) =>
  useUpdatedData({
    selector: selectors.dataUsage.device.monthly,
    action: actions.dataUsage.device.monthly.fetchData(mac),
    dataDependencyKey: mac,
  });

export default useDeviceMonthlyDataUsage;
