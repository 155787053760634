import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ZoneDevice } from 'Consts/types';
import { ROUTES } from 'Consts/routes';

import useDevices from 'State/hooks/useDevices';
import useDeviceFilters from 'State/hooks/useDeviceFilters';
import { sortDevices } from '../../../Device management view all/utils';

import DevicesCard from 'Pages/Zones/Components/Devices/Devices card';
import { ITEM_LIMIT_PER_CARD_COLUMN } from 'Consts/config';

type PopularDevicesUIProps = {
  className?: string;
  isLoading?: boolean;
  errorMessage?: string;
  maxItems?: number;
  devices: ZoneDevice[];
};

type PopularDevicesProps = {
  className?: string;
  maxItems?: number;
};

export const PopularDevicesUI: FunctionComponent<PopularDevicesUIProps> = ({
  className,
  isLoading,
  errorMessage,
  maxItems = ITEM_LIMIT_PER_CARD_COLUMN,
  devices,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleViewAllClick = useCallback(() => {
    navigate(ROUTES.zones.guest.popularDevices);
  }, [navigate]);

  return (
    <DevicesCard
      className={className}
      isLoading={isLoading}
      errorMessage={errorMessage}
      title={t('devices.devices')}
      columnSpan={1}
      devices={devices || []}
      headerMenuItems={[]}
      itemLimitForOneColumn={maxItems}
      onViewAllClick={handleViewAllClick}
      infoTooltipDisabled
    />
  );
};

const PopularDevices: FunctionComponent<PopularDevicesProps> = ({
  className,
  maxItems,
}) => {
  const {
    allDevices: { isLoading, errorMessage },
    guestDevices,
  } = useDevices();
  const { sortingType } = useDeviceFilters();

  const _devices = [
    ...(sortDevices(guestDevices?.auto || [], sortingType) as ZoneDevice[]),
    ...(sortDevices(guestDevices?.approved || [], sortingType) as ZoneDevice[]),
    ...(sortDevices(guestDevices?.blocked || [], sortingType) as ZoneDevice[]),
  ];

  return (
    <PopularDevicesUI
      className={className}
      maxItems={maxItems}
      isLoading={isLoading}
      errorMessage={errorMessage}
      devices={_devices}
    />
  );
};

export default PopularDevices;
