import { useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

import useConditionalUpdate from './utils/useConditionalUpdate';

const useLocations = () => {
  const ownedLocations = useSelector(selectors.locations.ownedLocations);
  const activeLocation = useSelector(selectors.locations.activeLocation);
  const coAdminLocations = useSelector(selectors.locations.coAdminLocations);
  useConditionalUpdate({
    data: activeLocation,
    dependencies: [
      !!ownedLocations.data?.length || !!coAdminLocations?.data?.length,
    ],
    action: actions.locations.fetchData(),
  });

  return {
    activeLocation,
    locations: ownedLocations,
    coAdminLocations,
    availableLocationCount:
      (ownedLocations.data?.length || 0) +
      (coAdminLocations?.data?.length || 0),
  };
};

export default useLocations;
