import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useGuestMonthlyDataUsage = () =>
  useUpdatedData({
    selector: selectors.dataUsage.guest.monthly,
    action: actions.dataUsage.guest.monthly.fetchData(),
  });

export default useGuestMonthlyDataUsage;
