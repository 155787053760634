import {
  addHours,
  eachHourOfInterval,
  Interval,
  isAfter,
  isBefore,
  isEqual,
  subHours,
} from 'date-fns';

import { SecurityEvent, SecurityEventType } from 'Consts/types';
import { PERIODS } from 'Consts/defintions';

import { formatGraphDateLabel } from 'Utils/formatters/date';
import { BarGraphData } from '../../Components/Graphs/types';

export const FilterNames = [
  'onlineProtection',
  'contentAccess',
  'adBlocking',
  'remoteAccessProtection',
  'advancedIotProtection',
] as const;
export type FilterName = (typeof FilterNames)[number];

export const FilterNamesPlusAll = [...FilterNames, 'all'] as const;
export type FilterNamePlusAll = (typeof FilterNamesPlusAll)[number];

export type ShieldBarGraphData = BarGraphData<FilterName>;

export const filterData = (
  data: ShieldBarGraphData[],
  filter: FilterNamePlusAll
) => {
  if (filter === 'all') {
    return data;
  }

  return data.map(({ name, values }) => ({
    name,
    values: { [filter]: values[filter] },
  }));
};

export const filterEvents = (
  shieldEvents: SecurityEvent[],
  filter: FilterNamePlusAll
) => {
  if (filter === 'all') {
    return shieldEvents;
  }

  return shieldEvents.filter(
    (event) => SECURITY_EVENT_MAP[event.eventType] === filter
  );
};

export const SECURITY_EVENT_MAP: Record<SecurityEventType, FilterName> = {
  kids: 'contentAccess',
  teenagers: 'contentAccess',
  adultAndSensitive: 'contentAccess',
  workAppropriate: 'contentAccess',
  adBlocking: 'adBlocking',
  'IoTProtect.blacklistedAnomaly': 'advancedIotProtection',
  'IoTProtect.rolledbackAnomaly': 'advancedIotProtection',
  'IoTProtect.whitelistedAnomaly': 'advancedIotProtection',
  'IoTProtect.blacklistedAnomalyRollback': 'advancedIotProtection',
  secureAndProtect: 'onlineProtection',
  'SecureAndProtect.outboundIpBlocked': 'onlineProtection',
  'SecureAndProtect.inboundIpBlocked': 'onlineProtection',
  ipThreat: 'remoteAccessProtection',
  anomaly: 'advancedIotProtection',
};

export const countEvents = (events: SecurityEvent[]) => {
  const countedEvents: Record<FilterName, number> = {
    onlineProtection: 0,
    contentAccess: 0,
    adBlocking: 0,
    remoteAccessProtection: 0,
    advancedIotProtection: 0,
  };

  events.forEach((event) => {
    if (
      SECURITY_EVENT_MAP[event.eventType] &&
      countedEvents[SECURITY_EVENT_MAP[event.eventType]] !== undefined
    ) {
      countedEvents[SECURITY_EVENT_MAP[event.eventType]] += 1;
    }
  });

  return countedEvents;
};

export const eventsToGraphData = (events: SecurityEvent[] | null) => {
  const now = new Date();

  const interval: Interval = {
    start: subHours(now, 24),
    end: now,
  };

  return eachHourOfInterval(interval).map((hour) => {
    const hourEvents =
      events?.filter(({ createdAt }) => {
        const createdAtDate = new Date(createdAt);

        return (
          (isAfter(createdAtDate, hour) || isEqual(createdAtDate, hour)) &&
          isBefore(createdAtDate, addHours(hour, 1))
        );
      }) || [];

    return {
      name: formatGraphDateLabel(hour, PERIODS.day),
      values: countEvents(hourEvents),
    };
  });
};
