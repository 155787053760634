import React, { FunctionComponent, useMemo } from 'react';

import { Card } from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';

import useEmployees from 'State/hooks/useEmployees';

import useNavigateToEmployee from 'Utils/hooks/useNavigateToEmployee';

import { groupEmployeeList } from '../../utils';

import EmployeeCard from './Components/Employee card';
import { Person } from 'Consts/types';
import { AppDispatch } from 'State/store';
import { useDispatch } from 'react-redux';
import * as actions from 'State/actions';

type EmployeeListProps = {
  warnAboutManualCreation?: boolean;
};

const EmployeeList: FunctionComponent<EmployeeListProps> = ({
  warnAboutManualCreation,
}) => {
  const navigateToEmployee = useNavigateToEmployee();
  const { data, isLoading, errorMessage } = useEmployees();
  const dispatch = useDispatch<AppDispatch>();

  const groupedPersons = useMemo(() => groupEmployeeList(data), [data]);

  const homePersons = useMemo(
    () =>
      groupedPersons.home.sort((a, b) => {
        if (a.connectionStateChangeAt && b.connectionStateChangeAt) {
          return (
            new Date(a.connectionStateChangeAt).getTime() -
            new Date(b.connectionStateChangeAt).getTime()
          );
        }

        return -1;
      }),
    [groupedPersons]
  );

  const awayPersons = useMemo(
    () =>
      groupedPersons.away.sort((a, b) => {
        if (a.connectionStateChangeAt && b.connectionStateChangeAt) {
          return (
            new Date(a.connectionStateChangeAt).getTime() -
            new Date(b.connectionStateChangeAt).getTime()
          );
        }

        return -1;
      }),
    [groupedPersons]
  );

  const noDevicePersons = useMemo(
    () =>
      groupedPersons.noDevice.sort((a, b) => {
        return a.nickname.localeCompare(b.nickname);
      }),
    [groupedPersons]
  );

  const restPersons = useMemo(
    () =>
      groupedPersons.rest.sort((a, b) => {
        return a.nickname.localeCompare(b.nickname);
      }),
    [groupedPersons]
  );

  if (isLoading || errorMessage) {
    return (
      <Card
        isLoading={isLoading}
        errorMessage={errorMessage}
        header={{
          L1Props: { iconProps: { name: IconNames.Profile56Generic04 } },
          L2Props: { label: errorMessage || 'Loading' },
        }}
      />
    );
  }

  if (!data) {
    return null;
  }

  const allPersons = [
    ...homePersons,
    ...awayPersons,
    ...noDevicePersons,
    ...restPersons,
  ];
  const handleEmployeeClick = (person: Person) => {
    dispatch(actions.ui.devicesAndGroups.selectEmployee([person]));
    navigateToEmployee(person.id);
  };

  return (
    <>
      {allPersons.map((person) => (
        <EmployeeCard
          person={person}
          key={person.id}
          warnAboutManualCreation={warnAboutManualCreation}
          onClick={() => handleEmployeeClick(person)}
        />
      ))}
    </>
  );
};

export default EmployeeList;
