import { useEffect, useState } from 'react';

// According to caniuse, the native scrollIntoView is supposed to be supported by Safari in basically all versions.
// However, Safari is the new Internet Explorer and it does NOT work without this polyfill.
import { scrollIntoView } from 'seamless-scroll-polyfill';

/**
Hook for automatically scrolling focused elements into view within a scrollable container.
Good for accessibility when using the keyboard to navigate a list of items.
**/
export default function useScrollToViewOnFocus() {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      if (containerRef && event.target) {
        if (containerRef.contains(event.target as Node)) {
          const item = event.target as HTMLElement;
          scrollIntoView(
            item,
            {
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            },
            { duration: 200 }
          );
        }
      }
    };

    containerRef?.addEventListener('focusin', handleFocus);
    return () => {
      containerRef?.removeEventListener('focusin', handleFocus);
    };
  }, [containerRef]);

  return { setContainerRef };
}
