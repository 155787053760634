import React, { FunctionComponent } from 'react';

import styles from './style.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export type Props = {
  onChange: React.ChangeEventHandler<HTMLElement>;
  disabled?: boolean;
  checked?: boolean;
  ariaLabel?: string;
};

const Toggle: FunctionComponent<Props> = ({
  onChange,
  disabled,
  checked = false,
  ariaLabel,
}) => {
  return (
    <label
      className={styles.formSwitch}
      role="switch"
      aria-checked={checked}
      tabIndex={0}
      aria-label={ariaLabel}
      onKeyDown={(e) => onEnterKeydown(e, onChange)}
    >
      <input
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        aria-label={ariaLabel}
      />
      <i></i>
    </label>
  );
};

export default Toggle;
