import { useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const usePersonSecurityPolicy = (personId: string) => {
  const personShieldSettings = useSelector(
    selectors.personSecurityPolicy.personShieldSettings
  );

  const personSecurityPolicyData = useUpdatedData({
    selector: selectors.personSecurityPolicy.personSecurityPolicy,
    action: actions.personSecurityPolicy.fetchData(personId),
    dataDependencyKey: personId,
  });

  return {
    personSecurityPolicy: personSecurityPolicyData,
    personShieldSettings,
  };
};

export default usePersonSecurityPolicy;
