import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useWifiSettings = () =>
  useUpdatedData({
    selector: selectors.settings.wifi.all,
    action: actions.settings.wifi.fetchData(),
  });

export default useWifiSettings;
