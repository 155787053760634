import { Categories } from 'Consts/types';
import cn from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import { Body2, Body3, Body3Medium } from 'UI/Elements/Typography';

import { formatSecondsToHoursAndMinutes } from 'Utils/formatters/date';
import { sentenceCase } from 'Utils/formatters/string';
import { displayBytes } from 'Utils/mbMath';

import colorStyles from 'Styles/colors';

import { ActivityTypes } from 'UI/Components/Graphs/types';

import bellColors from 'Styles/bellColors';
import standardColors from 'Styles/colors';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import styles from './style.module.css';

const BELL = 'bell';

type CategoriesProps = {
  items: {
    name: Categories;
    value: number;
  }[];
  colors: Record<Categories, string>;
  activityType?: ActivityTypes;
  onIconClick: React.MouseEventHandler;
};

const CategoryList: FunctionComponent<CategoriesProps> = ({
  items,
  colors,
  activityType = 'usage',
  onIconClick,
}) => {
  const { t } = useTranslation();
  const environment = getConfigurationFromDomain();

  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);

  const colorFile = isBell ? bellColors : standardColors;

  const onlineActivityColors = [
    colorFile.cold700,
    colorFile.warm700,
    colorFile.hot500,
  ];
  const warmColor = isBell ? bellColors.warm500 : standardColors.warmX500;
  const dataUsageColors = [
    warmColor,
    standardColors.warm700,
    standardColors.good600,
  ];

  return (
    <div className={styles.categoryRow}>
      {items.length ? (
        <div className={styles.list}>
          {items.map((item) => (
            <div className={styles.item} key={item.name}>
              <Body3Medium style={{ color: colors[item.name] }}>
                {t(`categories.${item.name}`) || sentenceCase(item.name)}
              </Body3Medium>

              <Body3 style={{ color: colorStyles.still400 }}>
                {activityType === 'online'
                  ? formatSecondsToHoursAndMinutes(item.value)
                  : displayBytes(item.value)}
              </Body3>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.emptyList}>
          {!items.length &&
            activityType === 'online' &&
            onlineActivityColors.map((color) => (
              <div className={styles.emptyItem} key={color}>
                <Body2 style={{ color: color }}>--</Body2>
              </div>
            ))}

          {!items.length &&
            activityType === 'usage' &&
            dataUsageColors.map((color) => (
              <div className={styles.emptyItem} key={color}>
                <Body2 style={{ color: color }}>--</Body2>
              </div>
            ))}
        </div>
      )}

      <Icon
        className={cn(styles.icon, {
          [styles.disabledIcon]: !items.length,
        })}
        shape={IconShape.square}
        name={IconNames.OverflowVertical}
        onClick={items.length ? onIconClick : undefined}
        tooltipLabel={t('common.moreActions')}
        ariaLabel={t('common.moreActions')}
        ariaHidden={!items.length}
      />
    </div>
  );
};

export default CategoryList;
