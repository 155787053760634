import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'Consts/routes';

import Alert from 'UI/Components/Alert';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import Icon, { IconNames } from 'UI/Elements/Icon';
import AlertModal from 'UI/Elements/Modals/Alerts';

import * as api from 'Api/endpoints';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';
import PartnerLogo from 'UI/Elements/Partner Logo';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConfigurationFromDomain,
  isDevDomain,
} from 'subDomainConfiguration';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import styles from './style.module.css';

const IntroPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch<AppDispatch>();
  const authError = useSelector(selectors.auth.authError);
  const namespace = useCspTranslationNamespace();
  const [openAlert, setOpenAlert] = useState<AlertType>(
    localStorage.getItem('onboardingAlert') === 'noWorkPass'
      ? 'noWorkPass'
      : authError
      ? 'authError'
      : null
  );
  const environment = getConfigurationFromDomain();
  type AlertError = { label: string; paragraph: string; buttonLabel: string };
  type AlertType = 'noWorkPass' | 'noPartnerSsoLogin' | 'authError' | null;

  const handleCloseAlert = useCallback(() => {
    setOpenAlert(null);
    localStorage.removeItem('onboardingAlert');
    dispatch(actions.auth.clear());
  }, []);

  const handlePartnerLogin = useCallback(async () => {
    const partnerId = environment?.partnerId;
    const appId = environment?.ssoAppId;
    const cloud = isDevDomain()
      ? environment?.cloudDev
      : environment?.cloudProduction;

    if (!partnerId || !cloud) return;

    dispatch(actions.auth.setCloud(cloud));

    const response = await api.loginWithSSO({
      cloud,
      partnerId,
      appId,
    });

    if (response.data) {
      window.open(response.data.ssoUri, '_self');
    } else {
      setOpenAlert('noPartnerSsoLogin');
    }
  }, []);

  const getAlertMessage = useMemo((): AlertError => {
    switch (openAlert) {
      case 'noWorkPass':
        return {
          label: t('onboarding.alertModal.noWorkPass.heading', {
            ns: namespace,
          }),
          paragraph: t('onboarding.alertModal.noWorkPass.subheading', {
            ns: namespace,
          }),
          buttonLabel: t('onboarding.alertModal.noWorkPass.ackButton'),
        };
      case 'noPartnerSsoLogin':
        return {
          label: t('onboarding.alertModal.noPartnerSsoLogin.heading'),
          paragraph: t('onboarding.alertModal.noPartnerSsoLogin.subheading'),
          buttonLabel: t('onboarding.alertModal.noPartnerSsoLogin.ackButton'),
        };
      case 'authError':
        return {
          label:
            t('onboarding.alertModal.externalAuthenticationError.heading') ||
            '',
          paragraph: authError,
          buttonLabel:
            t('onboarding.alertModal.externalAuthenticationError.ackButton') ||
            'Okay',
        };
      default:
        return { label: '', paragraph: '', buttonLabel: '' };
    }
  }, [openAlert]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.IntroPage,
      },
    });
  }, []);

  return (
    <>
      {environment.id === 'bell' ? (
        <>
          <div className={styles.bellbg} />
        </>
      ) : (
        <>
          <div className={styles.bg} />
          <div className={styles.gradient} />
        </>
      )}

      <div className={styles.intro} role="main">
        <h1 aria-label="intro-logo">
          {!environment?.customLandingLogo && (
            <Icon name={IconNames.LogoStacked} />
          )}
          {environment?.customLandingLogo && environment.id !== 'bell' && (
            <PartnerLogo
              partnerId={environment.partnerId}
              height={76}
              className={styles.svgWhite}
            />
          )}
          {environment.id === 'bell' && (
            <Icon
              name={IconNames.BellCompanyLogo}
              className={styles.bellLogo}
            />
          )}
        </h1>
        {environment?.authenticationsMethodsPermitted.includes(
          'globalAuth'
        ) && (
          <Button
            className={styles.button}
            theme={BUTTON_THEMES.white}
            label={t('onboarding.signIn')}
            onClick={() => navigate(ROUTES.onboarding.globalAuth)}
          />
        )}
        {environment?.authenticationsMethodsPermitted.includes(
          'magicLinkAndPassword'
        ) && (
          <Button
            className={styles.button}
            theme={BUTTON_THEMES.white}
            label={t('onboarding.signIn')}
            onClick={() => navigate(ROUTES.onboarding.magicLink)}
          />
        )}

        {environment?.authenticationsMethodsPermitted.includes(
          'partnerSso'
        ) && (
          <Button
            className={styles.button}
            theme={BUTTON_THEMES.white}
            label={t('onboarding.signInPartner')}
            onClick={handlePartnerLogin}
          />
        )}

        {environment?.authenticationsMethodsPermitted.includes('bellSso') && (
          <Button
            className={styles.button}
            theme={BUTTON_THEMES.white}
            label={t('onboarding.signInBell', {
              ns: namespace,
            })}
            onClick={() => navigate(ROUTES.onboarding.bellLogin)}
          />
        )}

        <AlertModal isOpen={Boolean(openAlert)} onClose={handleCloseAlert}>
          {Boolean(getAlertMessage.label) && (
            <Alert
              topProps={{
                label: getAlertMessage.label,
                paragraph: getAlertMessage.paragraph,
                className: styles.modalBody,
              }}
              bottomProps={{
                button1Props: {
                  label: getAlertMessage.buttonLabel,
                  onClick: handleCloseAlert,
                },
              }}
            />
          )}
        </AlertModal>
      </div>
    </>
  );
};

export default IntroPage;
