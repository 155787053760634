import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Categories,
  OpenTriggerEventType,
  Period,
  TimeDailyToday,
  TimeLast30Days,
} from 'Consts/types';

import { PERIODS } from 'Consts/defintions';

import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';

import CategoryDataUsageGraph, {
  CategoryDataUsageGraphBase,
} from 'UI/Reusable/Data usage graph/Category';

import DataTile from 'UI/Components/Tiles/Data tile';

import { Card } from 'UI/Elements/Card';

import { formatSecondsToHoursAndMinutes } from 'Utils/formatters/date';
import { sentenceCase } from 'Utils/formatters/string';
import { useSidepanel } from 'Utils/hooks/useSidepanel';

import {
  formatDailyStats,
  formatHourlyStats,
  generateCategoryUsageList,
  getDailyTimeGraph,
  getHourlyTimeGraph,
  getTimeAverageLabel,
  mostUsedCategories,
} from '../../../../utils';

import OnlineActivityPlaceholderImg from 'Assets/Images/onlineActivityPlaceholder.svg';
import { OnlineTimeColors } from 'Consts/defintions';
import { useDispatch } from 'react-redux';
import * as actions from 'State/actions';
import useSecurityPolicy from 'State/hooks/useSecurityPolicy';
import { AppDispatch } from 'State/store';
import ChartPlaceholder from 'UI/Components/ChartPlaceholder';
import onlineActivityGraphTooltip from '../../../../../../UI/Reusable/Data usage graph/Components/Online activity graph tooltip';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

type OnlineTimeGraphProps = {
  daily: TimeDailyToday | undefined;
  monthly: TimeLast30Days | undefined;
  placeInCard?: boolean;
};

type GraphDataFields = Partial<Record<Categories, number>>;

type GraphColors = Record<string, string>;

type OnlineTimeSidePanelProps = {
  statistics: [Categories, number][];
};

const OnlineTimeSidePanel: FunctionComponent<OnlineTimeSidePanelProps> = ({
  statistics,
}) => {
  const { t } = useTranslation();

  const total = statistics.reduce((acc, [, number]) => acc + number, 0);

  return (
    <SimpleSidePanel title={t('common.onlineActivity')}>
      <SidePanelContentWrapper>
        {statistics.map(([category, count], index) => {
          const color = OnlineTimeColors[index] || OnlineTimeColors[2];

          if (count === 0) return null;

          return (
            <Card key={category}>
              <DataTile
                L2TopProps={{
                  label1: t(`categories.${category}`) || sentenceCase(category),
                }}
                RightProps={{
                  smallLabel1: formatSecondsToHoursAndMinutes(count),
                }}
                ProgressBarProps={{
                  color1: color,
                  color2: color,
                  percent: (count / total) * 100,
                }}
              />
            </Card>
          );
        })}
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

const OnlineTimeGraph: FunctionComponent<OnlineTimeGraphProps> = ({
  daily,
  monthly,
  placeInCard,
}) => {
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();
  const { shieldSettings, locationSecurityAppliesToAllDevices } =
    useSecurityPolicy();

  const { content, setContent } = useSidepanel();

  const [period, setPeriod] = useState<Period>(PERIODS.day);
  const [displaySidePanel, setDisplaySidePanel] = useState(false);

  const prevPeriod = useRef<Period>(period);

  const { hourlyStats, overallStats } = daily ?? {};
  const { dailyStats, monthlyStats } = monthly ?? {};
  const dispatch = useDispatch<AppDispatch>();

  const statsByHour = useMemo(
    () => formatHourlyStats(hourlyStats),
    [hourlyStats]
  );
  const statsByDay = useMemo(
    () => formatDailyStats(dailyStats, period),
    [dailyStats, period]
  );

  const sortedCategoryData = useMemo(() => {
    return generateCategoryUsageList(
      period === PERIODS.day ? statsByHour : statsByDay
    );
  }, [period, statsByDay, statsByHour]);

  const mostUsed = useMemo(
    () => mostUsedCategories(sortedCategoryData),
    [sortedCategoryData]
  );

  const totalData = mostUsed.reduce((acc, most) => acc + most.value, 0);

  const secondLabel = totalData
    ? formatSecondsToHoursAndMinutes(totalData)
    : t('common.noData');
  const itemsToShow = useMemo(() => {
    return mostUsed.reduce<GraphDataFields>((acc, cur) => {
      return { ...acc, [cur.name]: 0 };
    }, {});
  }, [mostUsed]);

  const colors = useMemo(() => {
    return mostUsed.reduce<GraphColors>((acc, cur, index) => {
      acc[cur.name] = OnlineTimeColors[index];
      return acc;
    }, {});
  }, [mostUsed]);

  const graphData = useMemo(() => {
    if (period === PERIODS.day) {
      return getHourlyTimeGraph(statsByHour, itemsToShow);
    }

    return getDailyTimeGraph(statsByDay, itemsToShow, period);
  }, [itemsToShow, period, statsByDay, statsByHour]);

  const handleUsageSidebar = useCallback(
    (ev?: React.MouseEvent) => {
      ev && ev.stopPropagation();
      ev &&
        dispatch(
          actions.ui.page.setSidepanelOpenTriggerType(
            ev.type as OpenTriggerEventType
          )
        );

      setContent(<OnlineTimeSidePanel statistics={sortedCategoryData} />);
    },
    [sortedCategoryData, setContent]
  );

  const handleDisplaySidePanel = (ev: React.MouseEvent) => {
    handleTriggerEvent(ev);
    setDisplaySidePanel(true);
    handleUsageSidebar(ev);
  };

  useEffect(() => {
    if (!content && displaySidePanel) {
      setDisplaySidePanel(false);
    }
    if (displaySidePanel && content && period !== prevPeriod.current) {
      handleUsageSidebar();

      prevPeriod.current = period;
    }
  }, [content, sortedCategoryData, handleUsageSidebar, period, setContent]);

  const { i18key, value } = getTimeAverageLabel({
    period,
    dailyStats: statsByDay,
    dailyAverage: overallStats?.hourlyAvgOnlineMinutes,
    monthlyAverage: monthlyStats?.dailyAvgOnlineMinutes,
  });

  return (
    <>
      {shieldSettings.data.appTime ||
      (!shieldSettings.data.appTime &&
        !locationSecurityAppliesToAllDevices.data.appTime) ? (
        placeInCard ? (
          <CategoryDataUsageGraphBase
            data={graphData ?? []}
            colors={colors}
            filters={mostUsed}
            period={period}
            customTooltip={onlineActivityGraphTooltip()}
            setPeriod={setPeriod}
            averageLabel={t(i18key, { value })}
            activityType="online"
            onCategoryIconClick={handleDisplaySidePanel}
          />
        ) : (
          <CategoryDataUsageGraph
            label={t('common.onlineActivity')}
            secondLabel={secondLabel}
            activityType="online"
            graphProps={{
              ariaLabel: t('common.onlineActivityAriaLabel'),
              data: graphData ?? [],
              customTooltip: onlineActivityGraphTooltip(),
              colors,
              filters: mostUsed,
              period,
              setPeriod,
              averageLabel: t(i18key, { value }),
              onCategoryIconClick: handleDisplaySidePanel,
            }}
          />
        )
      ) : (
        <ChartPlaceholder
          backgroundImageUrl={OnlineActivityPlaceholderImg}
          height="319px"
          placeholderTitle={t('common.onlineActivity')}
          placeholderSubtitle="5hr 23min"
        />
      )}
    </>
  );
};

export default OnlineTimeGraph;
