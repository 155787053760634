import {
  addHours,
  eachDayOfInterval,
  eachHourOfInterval,
  endOfDay,
  startOfDay,
  subDays,
} from 'date-fns';

import type {
  DataDailyStats,
  DataHourlyStats,
  Period,
  SpeedDimension,
} from 'Consts/types';
import { PERIODS, SPEED_DIMENSIONS } from 'Consts/defintions';

import type { GradientColors } from 'UI/Components/Graphs/types';

import { formatGraphDateLabel } from 'Utils/formatters/date';

import type { DataUsageBarGraphData } from './';

export type ZoneFilterNames = 'all' | 'secure' | 'employee' | 'guest';

export const ZONES: { key: ZoneFilterNames; i18labelKey: string }[] = [
  {
    key: 'all',
    i18labelKey: 'wlan.all',
  },
  {
    key: 'secure',
    i18labelKey: 'common.secureZone',
  },
  {
    key: 'employee',
    i18labelKey: 'common.employeeZone',
  },
  {
    key: 'guest',
    i18labelKey: 'common.guestZone',
  },
];

export const GRADIENT_COLORS: GradientColors = {
  color1: '#1FDC90',
  color2: '#6269FF',
};

export const totalHourlyUsage = (
  hourlyStats: DataHourlyStats[],
  speedDimension: SpeedDimension,
  period: Period
): DataUsageBarGraphData[] => {
  const now = new Date();

  const interval: Interval = {
    start: startOfDay(now),
    end: addHours(endOfDay(now), 1),
  };

  return eachHourOfInterval(interval).map((hour) => {
    const stats = hourlyStats.find(({ localStartTime }) => {
      const dataDate = new Date(localStartTime);

      return (
        dataDate.getHours() === hour.getHours() &&
        dataDate.getDate() === hour.getDate()
      );
    });

    if (!stats) {
      return {
        name: formatGraphDateLabel(new Date(hour), period),
        values: { usage: 0 },
      };
    }

    const total = stats.categories.reduce((total, categoryData) => {
      const type =
        speedDimension === SPEED_DIMENSIONS.download ? 'rxBytes' : 'txBytes';

      return total + categoryData[type];
    }, 0);

    return {
      name: formatGraphDateLabel(new Date(hour), period),
      values: { usage: total },
    };
  });
};

export const totalDailyUsage = (
  dailyStats: DataDailyStats[],
  speedDimension: SpeedDimension,
  period: Period
): DataUsageBarGraphData[] => {
  const now = new Date();

  let start = subDays(now, 6);

  if (period === PERIODS.month) {
    start = subDays(now, 30);
  }

  const interval: Interval = {
    start,
    end: now,
  };

  return eachDayOfInterval(interval).map((day) => {
    const stats = dailyStats.find(({ localStartDate }) => {
      return (
        new Date(localStartDate).setHours(0, 0, 0, 0) ===
        day.setHours(0, 0, 0, 0)
      );
    });

    if (!stats) {
      return {
        name: formatGraphDateLabel(new Date(day), period),
        values: { usage: 0 },
      };
    }

    const total = stats.categories.reduce((total, categoryData) => {
      const type =
        speedDimension === SPEED_DIMENSIONS.download ? 'rxBytes' : 'txBytes';

      return total + categoryData[type];
    }, 0);

    return {
      name: formatGraphDateLabel(new Date(day), period),
      values: { usage: total },
    };
  });
};

export const addTotals = (
  secure: DataUsageBarGraphData[],
  employee: DataUsageBarGraphData[],
  guest: DataUsageBarGraphData[]
): DataUsageBarGraphData[] => {
  const length = Math.max(secure.length, employee.length, guest.length);

  const total = [];

  for (let i = 0; i < length; i++) {
    total.push({
      name: secure[i]?.name || employee[i]?.name || guest[i]?.name,
      values: {
        usage:
          (secure[i]?.values.usage || 0) +
          (employee[i]?.values.usage || 0) +
          (guest[i]?.values.usage || 0),
      },
    });
  }

  return total;
};
