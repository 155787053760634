import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useEmployeeMonthlyAppsOnlineTime = () =>
  useUpdatedData({
    selector: selectors.appsOnlineTime.employee.monthly,
    action: actions.appsOnlineTime.employee.monthly.fetchData(),
  });

export default useEmployeeMonthlyAppsOnlineTime;
