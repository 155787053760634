import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'State/store';
import { useTranslation } from 'react-i18next';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';

import Modal from '../';
import Alert from 'UI/Components/Alert';
import styles from './style.module.css';
import { useNavigate } from 'react-router-dom';

type AlertModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AlertModal: FunctionComponent<PropsWithChildren<AlertModalProps>> = ({
  isOpen,
  onClose,
  children,
}) => (
  <Modal isOpen={isOpen ?? true} onClose={onClose} dark>
    {children}
  </Modal>
);

export const ErrorAlertModal: FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const errorState = useSelector(selectors.ui.errorAlert.errorState);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onAcknowledge = () => {
    if (errorState.redirectRoute) {
      navigate(errorState.redirectRoute);
    }
    dispatch(actions.ui.errorAlert.set({ errorState: { message: '' } }));
  };

  const onDismiss = () => {
    onAcknowledge();
  };

  if (!errorState.message.length) {
    return null;
  }

  return (
    <AlertModal isOpen={Boolean(errorState.message)} onClose={onDismiss}>
      <Alert
        className={styles.errorAlert}
        topProps={{
          label: t('errors.somethingWentWrong'),
          paragraph: errorState.message,
        }}
        bottomProps={{
          button1Props: {
            label: t('common.ok'),
            onClick: onAcknowledge,
          },
        }}
      />
    </AlertModal>
  );
};

export default AlertModal;
