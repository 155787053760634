import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useGuestNetworkUsage = () =>
  useUpdatedData({
    selector: selectors.guestNetworkUsage,
    action: actions.guestNetworkUsage.fetchData(),
  });

export default useGuestNetworkUsage;
