import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon, { Props as IconProps } from 'UI/Elements/Icon';
import { Body2 } from 'UI/Elements/Typography';

import { onEnterKeydown } from 'Utils/keyboardEvents';
import { Nullable } from '../../../Consts/types';
import styles from './style.module.css';

export type Props = {
  isSelected?: boolean;
  isDisabled?: boolean;
  label?: Nullable<string>;
  iconProps?: IconProps;
  className?: string;
  onClick?: React.MouseEventHandler;
};

const PillWeb: FunctionComponent<Props> = ({
  isSelected,
  isDisabled,
  label,
  iconProps,
  className,
  onClick,
}) => {
  const componentClasses = cn(
    styles.pillWeb,
    {
      [styles.interactive]: !!onClick,
      [styles.selected]: isSelected,
      [styles.disabled]: isDisabled,
    },
    className
  );

  if (!label && !iconProps) {
    return null;
  }

  return (
    <div
      className={componentClasses}
      onClick={onClick}
      role={onClick ? 'button' : ''}
      tabIndex={onClick ? 0 : undefined}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
    >
      {!!label && <Body2 className={styles.label}>{label}</Body2>}
      {!!iconProps && <Icon {...iconProps} />}
    </div>
  );
};

export default PillWeb;
