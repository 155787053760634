import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDeviceDailyOnlineTime = (mac: string) =>
  useUpdatedData({
    selector: selectors.onlineTime.device.daily,
    action: actions.onlineTime.device.daily.fetchData(mac),
    dataDependencyKey: mac,
  });

export default useDeviceDailyOnlineTime;
