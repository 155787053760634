import React, { FunctionComponent } from 'react';
import DailyDataUsageGraphUI from 'Pages/Zones/Components/Data usage';
import usePersonDailyDataUsage from 'State/hooks/usePersonDailyDataUsage';
import usePersonMonthlyDataUsage from 'State/hooks/usePersonMonthlyDataUsage';

type EmployeeDataUsageGraphProps = {
  personId: string;
};

const EmployeeDataUsageGraph: FunctionComponent<
  EmployeeDataUsageGraphProps
> = ({ personId }) => {
  const dailyDataUsage = usePersonDailyDataUsage(personId);
  const monthlyDataUsage = usePersonMonthlyDataUsage(personId);
  const personDailyDataUsage = dailyDataUsage.data?.[personId] || null;
  const personMonthlyDataUsage = monthlyDataUsage.data?.[personId] || null;

  return (
    <DailyDataUsageGraphUI
      daily={personDailyDataUsage}
      monthly={personMonthlyDataUsage}
    />
  );
};

export default EmployeeDataUsageGraph;
