import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDefaultDailyDataUsage = () =>
  useUpdatedData({
    selector: selectors.dataUsage.default.daily,
    action: actions.dataUsage.default.daily.fetchData(),
  });

export default useDefaultDailyDataUsage;
