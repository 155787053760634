import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import styles from './style.module.css';

export type Props = {
  className?: string;
  percent: number;
  color1?: string;
  color2?: string;
  height?: number;
};

const ProgressBar: FunctionComponent<Props> = ({
  className,
  percent,
  color1,
  color2,
  height = 4,
}) => {
  return (
    <div className={cn(styles.progressBar, className)}>
      <div
        className={styles.filler}
        data-testid={'progressBarFiller'}
        style={{
          width: `${Math.min(Math.max(percent, 0), 100)}%`,
          height: `${height}px`,
          background: `linear-gradient(90deg, ${color1} 0%, ${color2} 100%)`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
