import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useGuestEmailCollectionInfo = () =>
  useUpdatedData({
    selector: selectors.guestEmailCollection,
    action: actions.guestEmailCollection.fetchData(),
  });

export default useGuestEmailCollectionInfo;
