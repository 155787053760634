import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SPEED_DIMENSIONS, smallProfilePhoto } from 'Consts/defintions';
import type {
  BandwidthConsumption,
  Maybe,
  Nullable,
  SpeedDimension,
  SpeedTestDevice,
} from 'Consts/types';
import DeviceListItem from 'UI/Components/Lists/List device';
import styles from './style.module.css';

import { IconNames } from 'UI/Elements/Icon';

import useNavigateToDevice from 'Utils/hooks/useNavigateToDevice';
import { displayBytes, normalizeToBytes } from 'Utils/mbMath';

import WlanSubheading, { WlanHeading } from '../Heading';

import useDevices from 'State/hooks/useDevices';
import useEmployees from 'State/hooks/useEmployees';
import CenterListItem from 'UI/Components/Lists/List center';
import { IconShape } from 'UI/Elements/Icon/icons';
import { Heading4Medium } from 'UI/Elements/Typography';
import useNavigateToEmployee from 'Utils/hooks/useNavigateToEmployee';

export type DeviceListProps = {
  totalConsumption: BandwidthConsumption | null;
  devices: SpeedTestDevice[] | null;
  devicesToShow?: number;
  speedDimension?: SpeedDimension;
  onClick?: React.MouseEventHandler;
  viewMoreView?: boolean;
};

const sortDevices = (
  devices: SpeedTestDevice[],
  speedDimension: SpeedDimension
) => {
  const unit: 'downloadUnits' | 'uploadUnits' = `${speedDimension}Units`;

  return [...devices].sort((d1, d2) => {
    return (
      normalizeToBytes(d2[speedDimension], d2[unit]) -
      normalizeToBytes(d1[speedDimension], d1[unit])
    );
  });
};

const DeviceList = ({
  totalConsumption,
  devices,
  onClick,
  devicesToShow,
  speedDimension = SPEED_DIMENSIONS.download,
  viewMoreView = false,
}: DeviceListProps) => {
  const navigateToDevice = useNavigateToDevice();
  const navigateToEmployee = useNavigateToEmployee();
  const { t } = useTranslation();
  const devicesData = useDevices();
  const employees = useEmployees();
  const navigateToPerson = useCallback((personId: string | undefined) => {
    if (!personId) {
      return;
    }
    navigateToEmployee(personId);
  }, []);

  const sortedDevices = useMemo(
    () => (devices ? sortDevices(devices, speedDimension) : []),
    [devices, speedDimension]
  );

  const getPersonId = useCallback(
    (deviceMac: string) => {
      if (!devicesData.allDevices.data) {
        return;
      }
      return devicesData.allDevices.data.find(
        (device) => deviceMac === device.mac
      )?.personId;
    },
    [devicesData.allDevices.data]
  );

  const unit: 'downloadUnits' | 'uploadUnits' = `${speedDimension}Units`;

  const totalBandwidth = totalConsumption
    ? normalizeToBytes(totalConsumption[speedDimension], totalConsumption[unit])
    : 0;

  const total =
    devices?.length && totalConsumption
      ? t('wlan.total', {
          device: devices.length,
          usage: displayBytes(
            totalConsumption[speedDimension],
            totalConsumption[unit]
          ),
        })
      : '';

  const getImageId = (personId: Nullable<Maybe<string>>) => {
    if (!personId) {
      return;
    }
    return employees?.data?.filter((e) => e.id === personId)?.[0]?.imageId;
  };

  return (
    <section aria-labelledby="most-active-devices-heading">
      {viewMoreView ? (
        total?.length === 0 ? (
          <Heading4Medium
            id="most-active-devices-heading"
            className={styles.wlanHeading}
          >
            {t('wlan.mostActiveDevice')}
          </Heading4Medium>
        ) : (
          <WlanHeading
            id="most-active-devices-heading"
            paragraph={total}
            label={t('wlan.mostActiveDevice')}
          />
        )
      ) : (
        <WlanSubheading
          id="most-active-devices-heading"
          paragraph={total}
          label={t('wlan.mostActiveDevice')}
        />
      )}

      {sortedDevices?.length === 0 && (
        <CenterListItem
          paragraph={t('wlan.noData')}
          className={styles.noData}
        />
      )}

      {sortedDevices?.map((device, i) => {
        if (devicesToShow && i + 1 > devicesToShow) {
          return null;
        }

        const imageId = getImageId(getPersonId(device.mac));

        const deviceBandwidth = normalizeToBytes(
          device[speedDimension],
          device[unit]
        );

        const percent = (deviceBandwidth / totalBandwidth) * 100 || 0;

        const bandwidthLabel = displayBytes(
          device[speedDimension],
          device[unit]
        );

        return (
          <DeviceListItem
            key={device.mac}
            L1Props={{
              iconProps: imageId
                ? {
                    name:
                      smallProfilePhoto[imageId] ||
                      IconNames.Profile24Generic04,
                    shape: IconShape.smallCircle,
                    onClick: (ev: React.MouseEvent) => {
                      ev.stopPropagation();
                      navigateToPerson(getPersonId(device.mac) || undefined);
                    },
                  }
                : undefined,
              mediumDeviceIconProps: {
                fileName: device.iconV3 || 'unknown',
              },
            }}
            L2Props={{
              label: device.name,
              progressBarProps: {
                percent,
                color1: '#6269FF',
                color2: '#1FDC90',
              },
              paragraphList: [
                { label: `${bandwidthLabel}, ${percent.toFixed(0)}%` },
              ],
            }}
            onClick={() => navigateToDevice(device.mac)}
          />
        );
      })}
    </section>
  );
};

export default DeviceList;
