import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Heading4Medium } from 'UI/Elements/Typography';

import styles from './style.module.css';

export type SectionHeaderProps = {
  className?: string | undefined;
  label: string;
};

const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
  className,
  label,
}) => {
  return label ? (
    <Heading4Medium className={cn(styles.sectionHeader, className)}>
      {label}
    </Heading4Medium>
  ) : null;
};

export default SectionHeader;
