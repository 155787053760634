import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useBlockedEmployees = () =>
  useUpdatedData({
    selector: selectors.settings.blockedEmployees,
    action: actions.settings.blockedEmployees.fetchData(),
  });

export default useBlockedEmployees;
