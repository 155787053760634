import React, { FunctionComponent } from 'react';
import Icon, { IconName } from 'UI/Elements/Icon';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import styles from './style.module.css';
import { Body1, Heading4Medium } from 'UI/Elements/Typography';
import colorStyles from 'Styles/colors.module.css';
import cn from 'classnames';

export type FeatureAnnouncementProps = {
  title: string;
  paragraph: string;
  additionalInfo?: string;
  iconName: string;
  callToActionLabel: string;
  cancelActionLabel: string;
  additionalInfoBottomPadding?: boolean;
  handleCancelAction: () => void;
  handleCallToAction: () => void;
};

const FeatureAnnouncement: FunctionComponent<FeatureAnnouncementProps> = ({
  title,
  paragraph,
  additionalInfo,
  iconName,
  callToActionLabel,
  cancelActionLabel,
  additionalInfoBottomPadding,
  handleCancelAction,
  handleCallToAction,
}) => {
  return (
    <div className={styles.featureAnnouncement}>
      <div className={styles.featureAnnouncementTop}>
        <Heading4Medium>{title}</Heading4Medium>
        <Body1 className={styles.featureAnnouncementParagraph}>
          {paragraph}
        </Body1>
      </div>
      <Icon name={iconName as IconName} />
      {additionalInfo && (
        <Body1
          className={cn(colorStyles.still400, {
            [styles.additionalInfoBottomPadding]:
              additionalInfo && additionalInfoBottomPadding,
          })}
        >
          {additionalInfo}
        </Body1>
      )}
      <div className={styles.featureAnnouncementBottom}>
        <Button
          className={styles.featureAnnouncementCta}
          label={callToActionLabel}
          onClick={handleCallToAction}
        />
        <Button
          className={styles.featureAnnouncementCta}
          label={cancelActionLabel}
          onClick={handleCancelAction}
          theme={BUTTON_THEMES.white}
        />
      </div>
    </div>
  );
};

export default FeatureAnnouncement;
