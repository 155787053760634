import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AutoRun } from 'Consts/types';

import StandardListItem from 'UI/Components/Lists/List standard';

import { IconNames } from 'UI/Elements/Icon';
import Card from 'UI/Elements/Card';

import { AppDispatch } from 'State/store';
import useIspSpeedTest from 'State/hooks/useIspSpeedTest';
import * as actions from 'State/actions';

import styles from './style.module.css';

const AutoCheckUI = ({
  autoRun,
  isLoading,
  errorMessage,
}: {
  autoRun: AutoRun | null;
  isLoading?: boolean;
  errorMessage?: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [checked, setChecked] = useState(autoRun?.enable ?? false);

  const onSpeedTestUpdate = useCallback(
    (data: Partial<AutoRun>) => {
      dispatch(actions.speedTest.updateSpeedTest(data));
    },
    [dispatch]
  );

  const onChange = useCallback(() => {
    onSpeedTestUpdate({ enable: !checked });
    setChecked(!checked);
  }, [checked, onSpeedTestUpdate]);

  useEffect(() => {
    setChecked(!!autoRun?.enable);
  }, [autoRun]);

  if ((isLoading && autoRun === null) || errorMessage) {
    return <Card isLoading={isLoading} errorMessage={errorMessage} />;
  }

  return (
    <Card>
      <StandardListItem
        className={styles.list}
        L1Props={{
          iconProps: { name: IconNames.SpeedTest },
        }}
        L2Props={{
          label: t('wlan.autocheck'),
          paragraph:
            t('wlan.autocheckText') ||
            'Speed tests consume data. They are only run during low network activity periods to keep your online experience uninterrupted.',
        }}
        RProps={{
          toggleProps: {
            checked,
            onChange: onChange,
            ariaLabel: t('wlan.autocheck'),
          },
        }}
        ariaLabel={t('wlan.autocheck')}
      />
    </Card>
  );
};

const AutoCheck = () => {
  const { data: ispTestResult, isLoading, errorMessage } = useIspSpeedTest();

  return (
    <AutoCheckUI
      isLoading={isLoading}
      errorMessage={errorMessage}
      autoRun={ispTestResult?.dashboard.autoRun ?? null}
    />
  );
};

export default AutoCheck;
