import { FunctionComponent } from 'react';
import React from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import { FinePrint1 } from 'UI/Elements/Typography';
import { TimeItem } from '../AtWorkDataTile/AtWorkDataTile';
import {
  formatHoursMinutes,
  formatSecondsToHoursAndMinutes,
} from 'Utils/formatters/date';
import { useTranslation } from 'react-i18next';
type AtWorkCardTimeLineBarProps = {
  variant: 'present' | 'past';
  time?: TimeItem[];
};

const AtWorkCardTimeLineBar: FunctionComponent<AtWorkCardTimeLineBarProps> = ({
  variant,
  time,
}) => {
  let timeIn;
  let timeOut;
  let timeInlabelMarginLeft;
  let timeOutlabelMarginLeft;
  const { t } = useTranslation();
  const timeSections: Array<{
    marginLeft: string;
    width: string;
  }> = [];

  // Calculate the width percentage based on the time elapsed from midnight (12:00 AM) to startTime
  const calculateWidthFromMidnightToStartTime = (start: Date, end: Date) => {
    const midnight = new Date(start);
    midnight.setHours(0, 0, 0, 0);

    const totalMilliseconds = start.getTime() - midnight.getTime();
    const widthPercentage = (totalMilliseconds / (24 * 60 * 60 * 1000)) * 100;

    return Math.min(100, Math.max(0, widthPercentage));
  };

  // Calculate the width percentage based on the time duration between startTime and endTime
  const calculateWidthFromStartTimeToEndTime = (start: Date, end: Date) => {
    const totalMilliseconds = end.getTime() - start.getTime();
    return Math.max(0, (totalMilliseconds / (24 * 60 * 60 * 1000)) * 100);
  };

  if (time) {
    for (let index = 0; index < time.length; index++) {
      const t = time[index];
      const start = t.startTime ? new Date(t.startTime) : new Date();
      const end = t.endTime ? new Date(t.endTime) : new Date();
      const marginLeft = `${calculateWidthFromMidnightToStartTime(
        start,
        end
      )}%`;
      const width = `${calculateWidthFromStartTimeToEndTime(start, end)}%`;
      const timeSection = {
        marginLeft,
        width,
      };
      timeSections.push(timeSection);

      if (index > 0) {
        // Calculate margin left for entries after the first one
        const prevEnd = time[index - 1].endTime
          ? new Date(time[index - 1].endTime)
          : new Date();
        const nextStart = t.startTime ? new Date(t.startTime) : new Date();
        const entryMarginLeft = `${calculateWidthFromStartTimeToEndTime(
          prevEnd,
          nextStart
        )}%`;
        timeSections[index].marginLeft = entryMarginLeft;
      }
    }

    timeIn = time[0]?.startTime
      ? formatHoursMinutes(new Date(time[0].startTime)).toLowerCase()
      : null;

    if (variant === 'past') {
      timeOut =
        time && time.length > 0 && time[time.length - 1].endTime
          ? formatHoursMinutes(new Date(time[time.length - 1].endTime))
            ? formatHoursMinutes(
                new Date(time[time.length - 1].endTime)
              ).toLowerCase()
            : formatHoursMinutes(new Date()).toLowerCase()
          : null;
    } else if (variant === 'present') {
      const startTime =
        time && time.length > 0
          ? time[time.length - 1].startTime == null
            ? null
            : new Date(time[time.length - 1].startTime)
          : null;
      const endTime =
        time && time.length > 0
          ? time[time.length - 1].endTime == null
            ? null
            : new Date(time[time.length - 1].endTime)
          : null;
      timeOut =
        startTime && endTime
          ? formatSecondsToHoursAndMinutes(
              (endTime.getTime() - startTime.getTime()) / 1000
            )
          : '';
    }

    timeInlabelMarginLeft =
      time && time.length > 0 && time[0].startTime
        ? `${calculateWidthFromMidnightToStartTime(
            new Date(time[0]?.startTime),
            new Date(time[0].endTime)
          )}%`
        : 0;
    timeOutlabelMarginLeft =
      time && time.length
        ? calculateWidthFromMidnightToStartTime(
            new Date(time[time.length - 1]?.startTime),
            new Date(time[time.length - 1].endTime)
          ) +
          calculateWidthFromStartTimeToEndTime(
            new Date(time[time.length - 1]?.startTime),
            new Date(time[time.length - 1].endTime)
          )
        : 0;
  }

  const timeInStyle = {
    marginLeft: timeInlabelMarginLeft,
  };

  const timeOutStyle = {
    marginLeft:
      timeOutlabelMarginLeft && timeOutlabelMarginLeft >= 86
        ? '0'
        : `${timeOutlabelMarginLeft}%`,
  };

  return (
    <div className={styles.atWorkTimeLineBarCard}>
      <div style={timeInStyle}>
        <FinePrint1
          className={cn({
            [styles.label]: variant === 'present',
          })}
        >
          {timeIn}
        </FinePrint1>
      </div>
      <div
        className={cn(styles.timeLineBarContainer, {
          [styles.timeLineBarContainerPresent]: variant === 'present',
        })}
      >
        {timeSections.map((section, index) => {
          const progressBarStyle = {
            marginLeft: section.marginLeft,
            width: section.width,
            height: '4px',
            background: variant === 'present' ? '#575BCF' : '#111111',
          };

          return (
            <div
              className={styles.timeLineBarProgress}
              style={progressBarStyle}
              key={index}
            ></div>
          );
        })}
      </div>
      <div
        style={{
          ...timeOutStyle,
          textAlign:
            timeOutlabelMarginLeft && timeOutlabelMarginLeft >= 86
              ? 'right'
              : 'left',
        }}
      >
        {variant !== 'present' ? (
          <FinePrint1 className={styles.timeOut}>{timeOut}</FinePrint1>
        ) : (
          <FinePrint1
            className={cn({
              [styles.label]: variant === 'present',
            })}
          >
            {timeOut ? t('employee.timeSoFar', { timeDuration: timeOut }) : ''}
          </FinePrint1>
        )}
      </div>
    </div>
  );
};

export default AtWorkCardTimeLineBar;
