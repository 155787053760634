import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { smallProfilePhoto } from 'Consts/defintions';
import type {
  Mac,
  Nullable,
  OpenTriggerEventType,
  Person,
  ZoneDevice,
} from 'Consts/types';

import { Card } from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';

import useDevices from 'State/hooks/useDevices';
import useEmployees from 'State/hooks/useEmployees';

import { useSidepanel } from 'Utils/hooks/useSidepanel';

import DeviceSettings from 'Pages/Zones/Components/Devices/Device Settings /DeviceSettings';
import { DeviceItem } from 'Pages/Zones/Components/Devices/Devices card';
import MacAndIpAddressSidePanel from 'Pages/Zones/Components/Devices/Mac And IP Address SidePanel';
import * as actions from 'State/actions';
import { AppDispatch } from 'State/store';
import CardHeader from 'UI/Components/Headers/Header card';
import Menu, { MenuItemProps } from 'UI/Components/Menu';
import Avatar from 'UI/Molecules/Avatar';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';
import useNavigateToEmployee from 'Utils/hooks/useNavigateToEmployee';
import useNavigateToEmployeeAssignedDevices from 'Utils/hooks/useNavigateToEmployeeAssignedDevices';
import { useDispatch } from 'react-redux';
import AddEmployeeSidePanel from '../AddEmployee/AddEmployee';
import ShareAccessSidePanel from '../ShareAccess/ShareAccessSidePanel';
import styles from './style.module.css';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

type EmployeeAssignedDevicesProps = {
  employee: Person;
  devices: ZoneDevice[];
  isLoading?: boolean;
  handleAssignDevices?: React.MouseEventHandler;
};

type EmployeesAtWorkUIProps = {
  isLoading?: boolean;
  errorMessage?: string;
  employees: Nullable<Person[]>;
  devices: Nullable<ZoneDevice[]>;
  addEmployeeOnClick: React.MouseEventHandler;
  employeeLoginFeatureIsSupportedAndEnabled?: boolean;
};

type EmployeesAtWorkProps = {
  employeeLoginFeatureIsSupportedAndEnabled?: boolean;
};

export const EmployeeAssignedDevices: FunctionComponent<
  EmployeeAssignedDevicesProps
> = ({ isLoading, devices, employee, handleAssignDevices }) => {
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();

  const dispatch = useDispatch<AppDispatch>();
  const { setContent, closeSidepanel } = useSidepanel();
  const [selectedItem, setSelectedItem] = useState<ZoneDevice | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const parentRef = useRef<Element | null>(null);
  let filteredDevices: Mac[];
  let assignedDevices: Mac[];
  const navigateToEmployeAssignedDevices =
    useNavigateToEmployeeAssignedDevices();

  const handleMenuOpen = useCallback(
    (ev: React.MouseEvent, device: ZoneDevice) => {
      handleTriggerEvent(ev);
      setSelectedItem(device);
      setIsMenuOpen(true);
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
      parentRef.current = ev.currentTarget;
    },
    []
  );

  const employeeDevices = useMemo(() => {
    return devices
      ?.filter(({ personId }: ZoneDevice) => personId === employee?.id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [devices, employee?.id]);
  const reducedDevices = employeeDevices?.slice(0, 4);

  assignedDevices = useMemo(() => {
    return (
      devices
        ?.filter((device) => device.personId === employee.id)
        .map((device) => device.mac) || []
    );
  }, [devices, employee.id]);

  const handleMacAndIpClick = useCallback(
    (ev: React.MouseEvent) => {
      if (!selectedItem) return;

      handleTriggerEvent(ev);

      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );

      setContent(
        <MacAndIpAddressSidePanel
          mac={selectedItem?.mac}
          ip={selectedItem?.ip}
          deviceName={selectedItem?.name}
        />
      );
    },
    [selectedItem, setContent]
  );

  const handleShareAccess = useCallback(
    (ev: React.MouseEvent) => {
      handleTriggerEvent(ev);

      dispatch(actions.ui.devicesAndGroups.closeMenus());
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );
      setContent(<ShareAccessSidePanel device={selectedItem!} />);
    },
    [setContent, dispatch, selectedItem]
  );

  const handleDeviceSettings = useCallback(
    (ev: React.MouseEvent) => {
      if (!selectedItem) return;

      handleTriggerEvent(ev);

      const selectedDevice = employeeDevices.find(
        (device) => device.mac === selectedItem.mac
      );

      if (selectedDevice) {
        dispatch(
          actions.ui.page.setSidepanelOpenTriggerType(
            ev.type as OpenTriggerEventType
          )
        );

        setContent(<DeviceSettings device={selectedDevice} />);
      }
    },
    [selectedItem, employeeDevices]
  );

  const handleUnAssignDevice = useCallback(() => {
    if (!selectedItem || !employee) return;

    filteredDevices = assignedDevices.filter((mac) => {
      return mac !== selectedItem.mac;
    });

    if (!filteredDevices) return;
    dispatch(
      actions.employee.assignDeviceToEmployee(employee?.id, filteredDevices)
    );

    dispatch(
      actions.ui.miniBanner.notify({
        label: t('devices.assignDeviceZone.unassigned', {
          employee: employee.nickname,
        }),
        state: 'set',
      })
    );
  }, [selectedItem, employee, devices, assignedDevices]);

  const menuItems: MenuItemProps[] = [
    {
      label: t('homepage.deviceSettings'),
      iconName: IconNames.Edit,
      onClick: handleDeviceSettings,
    },
    {
      label: t('homepage.macAndIp'),
      iconName: IconNames.CircleInfo,
      onClick: handleMacAndIpClick,
    },
    {
      label: t('devices.shareAccess'),
      iconName: IconNames.ShareAccess,
      onClick: handleShareAccess,
    },
    {
      label: t('employeeZone.unassign'),
      iconName: IconNames.Devices,
      onClick: handleUnAssignDevice,
    },
  ];

  const handleOnClose = useCallback(() => {
    setSelectedItem(null);

    setIsMenuOpen(false);

    parentRef.current = null;
  }, []);
  const handleAssignedDevicesViewAll = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.selectEmployee([employee]));
    navigateToEmployeAssignedDevices(employee?.id);
    closeSidepanel();
  }, [employee?.id]);

  const showIsViewMore =
    employeeDevices?.length > 0
      ? {
          label: t('common.viewMore'),
          ariaLabel: t('common.viewMoreAriaLabel', {
            label: t('homepage.assignedDevices'),
          }),
          onClick: () => {
            handleAssignedDevicesViewAll();
          },
        }
      : undefined;
  return (
    <>
      {(!isLoading || Boolean(assignedDevices?.length)) && (
        <Card
          isLoading={isLoading}
          footer={showIsViewMore}
          noBottomPadding={!!employeeDevices.length}
        >
          <CardHeader
            L2Props={{
              label: t('homepage.assignedDevices'),
              secondLabel: employeeDevices?.length
                ? employeeDevices.length
                : '',
              paragraph:
                employeeDevices?.length === 0
                  ? t('employeeZone.assignedDeviceEmployeeText', {
                      personName: employee.nickname,
                    })
                  : '',
            }}
            RProps={{
              icon1Props: {
                name: IconNames.Plus,
                onClick: handleAssignDevices,
              },
            }}
          />

          {reducedDevices?.map((device: ZoneDevice, i) => (
            <DeviceItem
              key={device.mac}
              device={device}
              withDivider={i !== 3 && i !== reducedDevices.length - 1}
              onMenuClick={(ev) => handleMenuOpen(ev, device)}
              personIcon={
                smallProfilePhoto[employee.imageId] ||
                IconNames.Profile24Generic04
              }
            />
          ))}

          <Menu
            isOpen={isMenuOpen}
            parent={parentRef.current}
            items={menuItems}
            onClose={handleOnClose}
            openTriggerEventType={menuOpenTrigger}
          />
        </Card>
      )}
    </>
  );
};

export const EmployeesAtWorkUI: FunctionComponent<EmployeesAtWorkUIProps> = ({
  isLoading,
  errorMessage,
  employees,
  addEmployeeOnClick,
  employeeLoginFeatureIsSupportedAndEnabled,
}) => {
  const { t } = useTranslation();
  const noOfEmployees = employees?.length ?? 0;
  const navigateToEmployee = useNavigateToEmployee();

  const handleEmployeeClick = (person: Person) => {
    navigateToEmployee(person.id);
  };

  const cardHeaderPlusIcon = () => {
    return employeeLoginFeatureIsSupportedAndEnabled
      ? undefined
      : {
          className: styles.plusIcon,
          icon1Props: {
            name: IconNames.Plus,
            onClick: addEmployeeOnClick,
            tooltipLabel: t('common.addEmployee'),
          },
          iconTestId: 'add-employee-plus-icon',
        };
  };

  return (
    <Card
      isLoading={isLoading}
      errorMessage={errorMessage}
      emptyCardLabel={errorMessage}
      header={{
        L2Props: {
          label: t('homepage.employeesAtWork'),
          secondLabel: noOfEmployees && !isLoading ? noOfEmployees : '0',
          paragraph:
            noOfEmployees === 0
              ? t('homepage.noEmployeesAtWorkEmptyStateParagraph')
              : '',
        },
        RProps: cardHeaderPlusIcon(),
      }}
    >
      {employees?.length ? (
        <div className={styles.employeeRow}>
          {employees?.map((employee, i) => (
            <Avatar key={i} person={employee} onClick={handleEmployeeClick} />
          ))}
        </div>
      ) : (
        <div className={styles.employeeRow}></div>
      )}
    </Card>
  );
};

const EmployeesAtWork: FunctionComponent<EmployeesAtWorkProps> = ({
  employeeLoginFeatureIsSupportedAndEnabled,
}) => {
  const { handleTriggerEvent } = useTrapFocus();
  const employees = useEmployees();
  const { allDevices } = useDevices();
  const { setContent } = useSidepanel();
  const dispatch = useDispatch<AppDispatch>();

  const handleAddEmployee = useCallback(
    (ev: React.MouseEvent) => {
      handleTriggerEvent(ev);

      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );

      setContent(<AddEmployeeSidePanel />);
    },
    [setContent]
  );

  const employeesAtWork = useMemo(() => {
    return (
      employees.data?.filter(({ connectionState }: Person) => {
        return connectionState === 'connected';
      }) || null
    );
  }, [employees.data]);

  return (
    <EmployeesAtWorkUI
      isLoading={
        (employees.isLoading || allDevices.isLoading) &&
        !(employees.data && allDevices.data)
      }
      errorMessage={employees.errorMessage || allDevices.errorMessage}
      employees={employeesAtWork}
      devices={allDevices.data || []}
      addEmployeeOnClick={handleAddEmployee}
      employeeLoginFeatureIsSupportedAndEnabled={
        employeeLoginFeatureIsSupportedAndEnabled
      }
    />
  );
};

export default EmployeesAtWork;
