import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useGuestMonthlyAppsOnlineTime = () =>
  useUpdatedData({
    selector: selectors.appsOnlineTime.guest.monthly,
    action: actions.appsOnlineTime.guest.monthly.fetchData(),
  });

export default useGuestMonthlyAppsOnlineTime;
