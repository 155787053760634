import React, { FunctionComponent, useMemo } from 'react';
import cn from 'classnames';

import StandardListItem from 'UI/Components/Lists/List standard';

import ActionSheet from 'UI/Elements/Modals/Action sheet';
import Divider from 'UI/Elements/Divider';
import Card from 'UI/Elements/Card';
import { CHECKLIST_ICON_NAMES, IconName } from 'UI/Elements/Icon';

import styles from './style.module.css';
import { Maybe, Nullable } from '../../../Consts/types';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Props } from 'UI/Elements/Toggle';
import { useTranslation } from 'react-i18next';
import useFocusFirstInteractable, {
  MenuOpenTriggerEventType,
} from 'Utils/hooks/useFocusFirstInteractable';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

export type MenuItemProps = {
  className?: string;
  theme?: string;
  onClick?: React.MouseEventHandler;
  label?: Nullable<string>;
  subtitle?: Nullable<string>;
  iconName?: IconName;
  isDivider?: boolean;
  isHeader?: boolean;
  key?: number;
  preventOnClose?: boolean;
  toggle?: Nullable<Props>;
};

type MenuItemComponentProps = MenuItemProps & {
  onClose: () => void;
};

type MenuProps = {
  className?: string;
  isOpen: boolean;
  items: Maybe<MenuItemProps>[];
  parent: Nullable<Element>;
  maxHeight?: number;
  onClose: () => void;
  openTriggerEventType?: MenuOpenTriggerEventType;
};

const BELL = 'bell';

const MenuItem: FunctionComponent<MenuItemComponentProps> = ({
  theme,
  isDivider,
  label,
  subtitle,
  iconName,
  className,
  isHeader,
  toggle,
  onClick,
  onClose,
  preventOnClose,
}) => {
  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);
  const { t } = useTranslation();

  const classNames = cn(
    {
      [styles.danger]: theme === 'danger',
      [styles.notAvailable]: theme === 'notAvailable',
      [styles.interactive]: !!onClick,
      [styles.header]: isHeader,
    },
    className
  );

  if (isDivider) {
    return <Divider />;
  }

  const handleIconClick = (
    ev:
      | React.MouseEvent<Element, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (iconName && onClick && CHECKLIST_ICON_NAMES.includes(iconName)) {
      onClick(ev);
    }
  };

  return (
    <StandardListItem
      className={classNames}
      L2Props={{ label: label, paragraph: subtitle }}
      RProps={
        iconName
          ? {
              icon1Props: {
                name: iconName,
                onClick:
                  iconName && onClick && CHECKLIST_ICON_NAMES.includes(iconName)
                    ? handleIconClick
                    : undefined,
              },
            }
          : toggle
          ? {
              toggleProps: {
                disabled: toggle.disabled,
                checked: toggle.checked,
                onChange: toggle.onChange,
                ariaLabel: label || t('common.menuItem'),
              },
            }
          : {}
      }
      onClick={(ev) => {
        if (onClick) {
          onClick(ev);
        }

        if (!preventOnClose) {
          onClose();
        }
      }}
      navItemTheme={isBell ? 'bell' : ''}
      ariaLabel={label || t('common.menuItem')}
      isHeader={isHeader}
    />
  );
};

const Menu: FunctionComponent<MenuProps> = ({
  className,
  isOpen,
  items,
  parent,
  maxHeight,
  onClose,
  openTriggerEventType,
}) => {
  const { focusTriggerElement } = useTrapFocus({ trapFocus: true });
  const { containerRef } = useFocusFirstInteractable<HTMLDivElement>({
    focusOnMount: false,
    focusWhen: isOpen,
    focusDelay: 100,
    openTriggerEventType,
  });

  if (!parent || !items?.length) {
    return null;
  }

  const close = () => {
    focusTriggerElement();
    onClose();
  };

  const menuItems = () =>
    items.map((props, i) =>
      props ? <MenuItem {...props} onClose={close} key={i} /> : undefined
    );

  return (
    <ActionSheet isOpen={isOpen} parent={parent} onClose={close}>
      <Card noBottomPadding className={className}>
        {maxHeight ? (
          <PerfectScrollbar
            style={{
              maxHeight: `${maxHeight}px`,
            }}
          >
            <div ref={containerRef}>{menuItems()}</div>
          </PerfectScrollbar>
        ) : (
          <div ref={containerRef}>{menuItems()}</div>
        )}
      </Card>
    </ActionSheet>
  );
};

export default Menu;
