import { SecurityEvent } from 'Consts/types';

export const groupBySecurityKeyValue = (
  shieldEvents: SecurityEvent[],
  primarykeygroup: keyof SecurityEvent['eventData'],
  secondarykeygroup: keyof SecurityEvent['eventData']
): { fqdn: string; events: SecurityEvent[]; isOpen: boolean }[] => {
  const groupedDatabyfqdn: { [key: string]: SecurityEvent[] } = {};

  shieldEvents.forEach((events) => {
    const nestedValue = events.eventData[primarykeygroup]
      ? events.eventData[primarykeygroup]
      : events.eventData[secondarykeygroup];
    if (!groupedDatabyfqdn[nestedValue!]) {
      groupedDatabyfqdn[nestedValue!] = [];
    }
    groupedDatabyfqdn[nestedValue!].push(events);
  });
  const sortedGroupedData = Object.entries(groupedDatabyfqdn)
    .map(([fqdn, events]) => ({
      fqdn,
      events,
      isOpen: false,
    }))
    .sort((a, b) => b.events.length - a.events.length);
  return sortedGroupedData;
};
