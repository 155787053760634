import { useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';
import { Mac } from 'Consts/types';

const useDeviceSecurityPolicy = (mac: Mac) => {
  const deviceShieldSettings = useSelector(
    selectors.deviceSecurityPolicy.deviceShieldSettings
  );

  const deviceSecurityPolicyData = useUpdatedData({
    selector: selectors.deviceSecurityPolicy.deviceSecurityPolicy,
    action: actions.deviceSecurityPolicy.fetchData(mac),
    dataDependencyKey: mac,
  });

  return {
    deviceSecurityPolicy: deviceSecurityPolicyData,
    deviceShieldSettings,
  };
};

export default useDeviceSecurityPolicy;
