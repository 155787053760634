import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDefaultMonthlyDataUsage = () =>
  useUpdatedData({
    selector: selectors.dataUsage.default.monthly,
    action: actions.dataUsage.default.monthly.fetchData(),
  });

export default useDefaultMonthlyDataUsage;
