import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import type { DeviceGroup, ZoneDevice } from 'Consts/types';

import { MenuItemProps } from 'UI/Components/Menu';

import DevicesCard from '../Devices card';
import { View } from '../types';
import useNetworkAccess from 'State/hooks/useNetworkAccess';

type ApprovedDeviceProps = {
  isLoading?: boolean;
  errorMessage?: string;
  group?: DeviceGroup;
  columnSpan: 1 | 2 | 3;
  itemLimitForOneColumn?: number | null;
  devices?: ZoneDevice[];
  headerMenuItems: MenuItemProps[];
  menuItems: MenuItemProps[];
  infoTooltipLabel: string;
  isEmployeeZone?: boolean;
  onViewAllClick: (view: View, groupId?: string) => void;
};

const getGroupName = (
  group: DeviceGroup | undefined,
  isEmployeeZone?: boolean
) => {
  if (isEmployeeZone) {
    return '';
  }

  if (!group) {
    return t('common.defaultGroup');
  }

  return group.name;
};

const ApprovedDevicesCards: FunctionComponent<ApprovedDeviceProps> = ({
  isLoading,
  errorMessage,
  group,
  columnSpan,
  itemLimitForOneColumn = null,
  devices = [],
  headerMenuItems,
  menuItems,
  infoTooltipLabel,
  isEmployeeZone = false,
  onViewAllClick,
}) => {
  const { t } = useTranslation();

  const groupName = getGroupName(group, isEmployeeZone);
  const networkAccessData = useNetworkAccess();
  const secureNetworkAccessMode = networkAccessData?.data?.default?.purgatory;
  const employeeNetworkAccessMode =
    networkAccessData?.data?.employee?.purgatory;

  const handleApprovedDevicesCardViewAllClick = useCallback(() => {
    onViewAllClick('approved', group?.groupId);
  }, [group?.groupId, onViewAllClick]);

  const getDeviceCardTitle = useMemo(() => {
    if (isEmployeeZone) {
      return employeeNetworkAccessMode
        ? t('devices.approvedDevices')
        : t('devices.devices');
    }

    if (secureNetworkAccessMode) {
      return t('devices.approvedDevices');
    } else {
      if (groupName) {
        if (groupName === t('common.defaultGroup')) {
          return t('devices.devices');
        } else {
          return groupName;
        }
      } else {
        return '';
      }
    }
  }, [isEmployeeZone, networkAccessData]);

  return (
    <DevicesCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      devices={devices}
      group={group}
      title={getDeviceCardTitle}
      groupName={groupName}
      columnSpan={columnSpan}
      itemLimitForOneColumn={itemLimitForOneColumn}
      headerMenuItems={headerMenuItems}
      menuItems={menuItems}
      onViewAllClick={handleApprovedDevicesCardViewAllClick}
      infoTooltipLabel={infoTooltipLabel}
    />
  );
};

export default ApprovedDevicesCards;
