import { atWorkEvent } from 'Consts/types';
import AtWorkDataTile from 'UI/Components/AtWorkDataTile/AtWorkDataTile';
import { Card } from 'UI/Elements/Card';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';

export type AtWorkCardProps = {
  className?: string;
  isLoading?: boolean;
  errorMessage?: string;
  atWorkEvents: atWorkEvent[] | undefined;
} & JSX.IntrinsicElements['div'];

export const generateLast7DaysAtWorkTime = (
  atWorkEvents: atWorkEvent[] | undefined
) => {
  const dateMap = new Map();
  const today = new Date();
  if (atWorkEvents) {
    for (let i = atWorkEvents?.length - 1; i >= 0; i--) {
      const item = atWorkEvents[i];
      const atHomeChangedAt = new Date(item.atHomeChangedAt);
      const dateKey = atHomeChangedAt.toDateString();
      if (!dateMap.has(dateKey)) {
        dateMap.set(dateKey, {
          date: dateKey,
          timeRanges: [],
        });
      }

      if (dateMap.has(dateKey)) {
        const currentDateData = dateMap.get(dateKey);

        if (!item.atHome) {
          const lastIndex = currentDateData.timeRanges.length;
          if (i < atWorkEvents.length - 1) {
            const nextItem = atWorkEvents[i + 1];
            const nextDate = new Date(nextItem.atHomeChangedAt).toDateString();
            const currentDate = atHomeChangedAt.toDateString();

            if (nextDate !== currentDate) {
              if (atWorkEvents[i + 1].atHome) {
                if (
                  dateMap.has(nextDate) &&
                  !dateMap.get(nextDate).timeRanges[
                    dateMap.get(nextDate).timeRanges.length - 1
                  ].endTime
                ) {
                  dateMap.get(nextDate).timeRanges[
                    dateMap.get(nextDate).timeRanges.length - 1
                  ].endTime = new Date(
                    new Date(nextDate).setHours(23, 59, 59, 999)
                  );
                  dateMap.get(currentDate).timeRanges.unshift({
                    startTime: new Date(
                      new Date(currentDate).setHours(0, 0, 0, 0)
                    ),
                    endTime: atHomeChangedAt,
                  });
                }
              } else {
                // Transition is to the next date with atHome: false
                dateMap.get(currentDate).timeRanges.push({
                  endTime: new Date(
                    new Date(currentDate).setHours(23, 59, 59, 999)
                  ),
                });
                dateMap.get(nextDate).timeRanges = [
                  {
                    startTime: new Date(
                      new Date(nextDate).setHours(0, 0, 0, 0)
                    ),
                  },
                ];
              }
              if (
                !areDatesConsecutive(
                  new Date(nextItem.atHomeChangedAt),
                  atHomeChangedAt
                )
              ) {
                // Calculate and fill the missing dates until the next "atHome" false
                fillMissingDates(
                  dateMap,
                  new Date(nextItem.atHomeChangedAt),
                  atHomeChangedAt
                );
              }
            } else {
              // Last entry and atHome is false, set end time for the last range
              if (!currentDateData.timeRanges[lastIndex - 1]?.endTime) {
                currentDateData.timeRanges[lastIndex - 1].endTime =
                  atHomeChangedAt;
              } else {
                currentDateData.timeRanges.push({ endTime: atHomeChangedAt });
              }
            }
          }
        } else if (item.atHome) {
          const lastIndex = currentDateData.timeRanges.length;
          if (
            lastIndex > 0 &&
            !currentDateData.timeRanges[lastIndex - 1].startTime
          ) {
            currentDateData.timeRanges[lastIndex - 1].startTime =
              atHomeChangedAt;
          } else {
            currentDateData.timeRanges.push({ startTime: atHomeChangedAt });
          }
        }
      }
    }
  }

  if (atWorkEvents && atWorkEvents.length > 0 && atWorkEvents[0].atHome) {
    const currentDate = new Date(
      atWorkEvents[0].atHomeChangedAt
    ).toDateString();

    fillMissingDates(dateMap, new Date(currentDate), today);
  }

  const sortedDateMap = new Map(
    [...dateMap.entries()].sort(
      ([a], [b]) => new Date(b).getTime() - new Date(a).getTime()
    )
  );

  const atWorkData = Array.from(sortedDateMap.values());
  return atWorkData;
};
const areDatesConsecutive = (date1: Date, date2: Date) => {
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const timeDifference = Math.abs(date1.getTime() - date2.getTime()); // Get the time difference in milliseconds
  const daysDifference = Math.floor(timeDifference / oneDay); // Calculate the difference in days

  return daysDifference === 1;
};

// Helper function to fill in missing dates between two dates
const fillMissingDates = (
  dateMap: Map<string, any>,
  startDate: Date,
  endDate: Date
) => {
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const dateKey = currentDate.toDateString();

    if (!dateMap.has(dateKey)) {
      const timeRanges = [
        {
          startTime: new Date(new Date(currentDate).setHours(0, 0, 0, 0)),
          endTime:
            currentDate.toDateString() === new Date().toDateString()
              ? new Date()
              : new Date(new Date(currentDate).setHours(23, 59, 59, 999)),
        },
      ];

      dateMap.set(dateKey, {
        date: dateKey,
        timeRanges,
      });
    } else {
      if (dateKey === new Date().toDateString()) {
        const dateData = dateMap.get(dateKey);
        if (dateData.timeRanges.length > 0) {
          const lastTimeRange =
            dateData.timeRanges[dateData.timeRanges.length - 1];
          if (!lastTimeRange.endTime) {
            // Check if the date is today, there are timeRanges, and the last one has no endTime
            lastTimeRange.endTime = new Date();
          }
        }
      } else {
        const dateData = dateMap.get(dateKey);
        if (dateData.timeRanges.length > 0) {
          const lastTimeRange =
            dateData.timeRanges[dateData.timeRanges.length - 1];
          if (!lastTimeRange.endTime) {
            // Check if the date is today, there are timeRanges, and the last one has no endTime
            lastTimeRange.endTime = new Date(
              new Date(currentDate).setHours(23, 59, 59, 999)
            );
          }
        }
      }
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
};

// Helper function to get the last 7 days as date strings starting from a given date
const getLast7Days = (startDate: Date) => {
  const last7Days = [];
  const currentDate = new Date(startDate);

  for (let i = 0; i < 7; i++) {
    last7Days.push(currentDate.toDateString());
    currentDate.setDate(currentDate.getDate() - 1);
  }

  return last7Days;
};
const AtWorkCard: FunctionComponent<AtWorkCardProps> = ({
  className,
  isLoading,
  errorMessage,
  atWorkEvents,
}) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const results = generateLast7DaysAtWorkTime(atWorkEvents);
  const last7DaysAtWorkEvents = getLast7Days(currentDate);
  last7DaysAtWorkEvents.forEach((date) => {
    const dateKey = new Date(date).toDateString();
    const found = results.find((item) => item.date === dateKey);
    if (!found) {
      results.push({
        date: dateKey,
        timeRanges: [{ startTime: null, endTime: null }],
      });
    }
  });
  // Sort the results array
  results.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  return (
    <>
      <Card
        className={className}
        isLoading={isLoading}
        errorMessage={errorMessage}
        header={{
          L2Props: {
            label: t('employee.atWork'),
          },
        }}
      >
        <div className={styles.atWorkCardList}>
          {results?.slice(0, 7).map((event, index) => {
            const specificDate = new Date(event.date);
            specificDate.setHours(0, 0, 0, 0); // Set time to 00:00:00

            currentDate.setHours(0, 0, 0, 0); // Set time to 00:00:00

            const isToday = specificDate.getTime() === currentDate.getTime();
            const variant = isToday ? 'present' : 'past';
            // Get the day of the month
            const dayOfMonth = specificDate.getDate();

            // Get the month name (e.g., "Sep")
            const monthName = specificDate.toLocaleString('default', {
              month: 'short',
            });
            const date = `${monthName} ${dayOfMonth}`;
            return (
              <AtWorkDataTile
                key={index}
                date={date}
                time={event.timeRanges}
                variant={variant}
              />
            );
          })}
        </div>
      </Card>
    </>
  );
};
export default AtWorkCard;
