import React, { FunctionComponent, useEffect, useState } from 'react';

import styles from './style.module.css';

export type CircularProgressBarProps = {
  size?: number;
  strokeWidth?: number;
  percent?: number;
  color?: string;
  textSize?: number;
  symbolSize?: number;
};

const CircularProgressBar: FunctionComponent<CircularProgressBarProps> = ({
  size = 44,
  strokeWidth = 2,
  percent = 0,
  color,
  textSize = 14,
  symbolSize = 12,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(percent);
  }, [percent]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        className={styles.circle}
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={`${dash} ${circumference - dash}`}
        strokeLinecap="round"
      />
      <text
        fill="black"
        fontSize={`${textSize}px`}
        fontWeight="500"
        x="50%"
        y="50%"
        dy="10%"
        textAnchor="middle"
      >
        {`${percent}`}
        <tspan fontSize={`${symbolSize}px`} fontWeight="400">
          %
        </tspan>
      </text>
    </svg>
  );
};

export default CircularProgressBar;
