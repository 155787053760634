import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import useDevices from './useDevices';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import useEmployees from './useEmployees';
import useLocations from './useLocations';
import { useEffect, useMemo } from 'react';

const useLocationSummary = () => {
  const trackEvent = useTrackEvent();
  const { defaultDevices, employeeDevices, guestDevices } = useDevices();
  const { data: employeesData } = useEmployees();
  const { activeLocation } = useLocations();
  const localStorageItem = localStorage.getItem('locationSummaryLogDate') ?? '';
  const summaryLoggedDate = localStorageItem
    ? JSON.parse(localStorageItem)
    : '';

  const allSecureDevices = [
    defaultDevices?.approved,
    defaultDevices?.auto,
    defaultDevices?.blocked,
    defaultDevices?.quarantined,
  ];
  const allSecureDevicesCount = useMemo(() => {
    if (
      defaultDevices?.approved !== undefined &&
      defaultDevices?.auto !== undefined &&
      defaultDevices?.blocked !== undefined &&
      defaultDevices?.quarantined !== undefined
    ) {
      return allSecureDevices.reduce((acc, devicesType) => {
        const count = devicesType?.length ?? 0;
        return acc + count;
      }, 0);
    }
  }, [
    defaultDevices?.approved,
    defaultDevices?.auto,
    defaultDevices?.blocked,
    defaultDevices?.quarantined,
  ]);

  const allEmployeeDevices = [
    employeeDevices?.approved,
    employeeDevices?.auto,
    employeeDevices?.blocked,
    employeeDevices?.quarantined,
  ];
  const allEmployeeDevicesCount = useMemo(() => {
    if (
      employeeDevices?.approved !== undefined &&
      employeeDevices?.auto !== undefined &&
      employeeDevices?.blocked !== undefined &&
      employeeDevices?.quarantined !== undefined
    ) {
      return allEmployeeDevices.reduce((acc, devicesType) => {
        const count = devicesType?.length ?? 0;
        return acc + count;
      }, 0);
    }
  }, [
    employeeDevices?.approved,
    employeeDevices?.auto,
    employeeDevices?.blocked,
    employeeDevices?.quarantined,
  ]);

  const allGuestDevices = [
    guestDevices?.approved,
    guestDevices?.auto,
    guestDevices?.blocked,
    guestDevices?.quarantined,
  ];
  const allGuestDevicesCount = useMemo(() => {
    if (
      guestDevices?.approved !== undefined &&
      guestDevices?.auto !== undefined &&
      guestDevices?.blocked !== undefined &&
      guestDevices?.quarantined !== undefined
    ) {
      return allGuestDevices.reduce((acc, devicesType) => {
        const count = devicesType?.length ?? 0;
        return acc + count;
      }, 0);
    }
  }, [
    guestDevices?.approved,
    guestDevices?.auto,
    guestDevices?.blocked,
    guestDevices?.quarantined,
  ]);

  const wardenEmployeesCount = useMemo(() => {
    if (employeesData) {
      return employeesData.reduce((acc, employee) => {
        const wardenCount =
          employee?.serviceLinking?.providerId === 'warden' ? 1 : 0;
        return acc + wardenCount;
      }, 0);
    }
  }, [employeesData]);

  const manualEmployeesCount = useMemo(() => {
    if (employeesData) {
      return employeesData.reduce((acc, employee) => {
        const manualCount = employee?.serviceLinking === undefined ? 1 : 0;
        return acc + manualCount;
      }, 0);
    }
  }, [employeesData]);

  useEffect(() => {
    if (
      activeLocation.data &&
      summaryLoggedDate[activeLocation.data?.name] !==
        new Date().toDateString() &&
      allSecureDevicesCount !== undefined &&
      allEmployeeDevicesCount !== undefined &&
      allGuestDevicesCount !== undefined &&
      manualEmployeesCount !== undefined &&
      wardenEmployeesCount !== undefined
    ) {
      trackEvent({
        eventName: MixPanelEvents.LOCATION_SUMMARY,
        additionalContent: {
          COUNT_DEVICES_SECURE: allSecureDevicesCount,
          COUNT_DEVICES_EMPLOYEE: allEmployeeDevicesCount,
          COUNT_DEVICES_GUEST: allGuestDevicesCount,
          COUNT_MANUAL_EMPLOYEE: manualEmployeesCount,
          COUNT_WARDEN_EMPLOYEE: wardenEmployeesCount,
        },
      });

      const newSummaryObject = {
        ...summaryLoggedDate,
        [activeLocation.data?.name]: new Date().toDateString(),
      };
      localStorage.setItem(
        'locationSummaryLogDate',
        JSON.stringify(newSummaryObject)
      );
    }
  }, [
    activeLocation,
    allSecureDevicesCount,
    allEmployeeDevicesCount,
    allGuestDevicesCount,
    manualEmployeesCount,
    wardenEmployeesCount,
  ]);
};

export default useLocationSummary;
