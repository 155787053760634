import { useSelector } from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';
import useConditionalUpdate from './utils/useConditionalUpdate';

const useEmployee = (personId: string) => {
  const employee = useSelector(selectors.employee);

  useConditionalUpdate({
    data: employee,
    dependencies: [],
    action: actions.employee.fetchData(personId),
    forceUpdate: employee?.data?.id !== personId,
  });

  return employee;
};

export default useEmployee;
