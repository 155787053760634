import React, { FunctionComponent, useMemo } from 'react';
import cn from 'classnames';

import V2ListItem from 'UI/Molecules/ListItem';
import { Body1, Body2 } from 'UI/Elements/Typography';

import ActionSheet from 'UI/Elements/Modals/Action sheet';
import Divider from 'UI/Elements/Divider';
import Card from 'UI/Elements/Card';
import Icon, { IconName } from 'UI/Elements/Icon';

import styles from './style.module.css';
import colorStyles from 'Styles/colors.module.css';
import { Nullable } from '../../../Consts/types';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import useFocusFirstInteractable, {
  MenuOpenTriggerEventType,
} from 'Utils/hooks/useFocusFirstInteractable';

export type MenuItemProps = {
  className?: string;
  theme?: string;
  onClick?: React.MouseEventHandler;
  label?: Nullable<string>;
  subtitle?: Nullable<string>;
  iconName?: IconName;
  isDivider?: boolean;
  isHeader?: boolean;
  key?: number;
  preventOnClose?: boolean;
};

type MenuItemComponentProps = MenuItemProps & {
  onClose: () => void;
};

type MenuProps = {
  className?: string;
  isOpen: boolean;
  items: MenuItemProps[];
  parent: Nullable<Element>;
  maxHeight?: number;
  onClose: () => void;
  menuOpenTrigger: MenuOpenTriggerEventType | undefined;
};

const BELL = 'bell';

const MenuItem: FunctionComponent<MenuItemComponentProps> = ({
  theme,
  isDivider,
  label,
  subtitle,
  iconName,
  className,
  isHeader,
  onClick,
  onClose,
  preventOnClose,
}) => {
  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);
  const { t } = useTranslation();

  const classNames = cn(
    {
      [styles.danger]: theme === 'danger',
      [styles.interactive]: !!onClick,
      [styles.header]: isHeader,
    },
    className
  );

  if (isDivider) {
    return <Divider />;
  }

  return (
    <V2ListItem
      className={classNames}
      main={
        <>
          <Body1>{label}</Body1>
          <Body2 className={colorStyles.still400}>{subtitle}</Body2>
        </>
      }
      mainTextWrap={true}
      right={iconName && <Icon name={iconName} />}
      onClick={(ev) => {
        if (onClick) {
          onClick(ev);
        }
        if (!preventOnClose) {
          onClose();
        }
      }}
      navItemTheme={isBell ? 'bell' : ''}
      ariaLabel={label || t('common.menuItem')}
    />
  );
};

const Menu: FunctionComponent<MenuProps> = ({
  className,
  isOpen,
  items,
  parent,
  maxHeight,
  onClose,
  menuOpenTrigger,
}) => {
  const { containerRef } = useFocusFirstInteractable<HTMLDivElement>({
    focusOnMount: false,
    focusWhen: isOpen,
    focusDelay: 100,
    openTriggerEventType: menuOpenTrigger,
  });

  if (!parent || !items?.length) {
    return null;
  }

  const menuItems = () =>
    items.map((props, i) => <MenuItem {...props} onClose={onClose} key={i} />);

  return (
    <ActionSheet isOpen={isOpen} parent={parent} onClose={onClose}>
      <Card noBottomPadding className={className}>
        {maxHeight ? (
          <PerfectScrollbar
            style={{
              maxHeight: `${400}px`,
              paddingRight: '8px',
            }}
          >
            <div ref={containerRef}>{menuItems()}</div>
          </PerfectScrollbar>
        ) : (
          <div ref={containerRef}>{menuItems()}</div>
        )}
      </Card>
    </ActionSheet>
  );
};

export default Menu;
