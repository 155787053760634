import {
  DeviceGroup,
  Mac,
  Maybe,
  NetworkType,
  Nullable,
  Person,
  ZoneDevice,
} from 'Consts/types';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import useEmployees from 'State/hooks/useEmployees';
import Card from 'UI/Elements/Card';
import { Body3Medium } from 'UI/Elements/Typography';
import ContentLayout from 'UI/Components/ContentLayout';
import { IconNames } from 'UI/Elements/Icon';
import { mediumProfilePhoto, smallProfilePhoto } from 'Consts/defintions';
import Divider from 'UI/Elements/Divider';
import useDevices from 'State/hooks/useDevices';
import { sortDevices } from 'Pages/Zones/Device management view all/utils';
import useDeviceFilters from 'State/hooks/useDeviceFilters';
import DeviceListItem from 'UI/Components/Lists/List device';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import * as actions from 'State/actions';
import { AppDispatch } from 'State/store';
import { useDispatch } from 'react-redux';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import useDeviceGroup from 'State/hooks/useDeviceGroup';
import { getConfigurationFromDomain } from 'subDomainConfiguration';

const BELL = 'bell';

type ShareAccessSidePanelProps = {
  device?: ZoneDevice;
  group?: DeviceGroup;
  person?: Person;
};

const ShareAccessSidePanel: FunctionComponent<ShareAccessSidePanelProps> = ({
  device,
  group,
  person,
}) => {
  const { closeSidepanel } = useSidepanel();
  const dispatch = useDispatch<AppDispatch>();
  const employees = useEmployees();
  const { t } = useTranslation();
  const { data: deviceGroups } = useDeviceGroup();
  const { data: employeeDeviceGroups } = useDeviceGroup('employee'); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { sortingType } = useDeviceFilters();
  const [groupId, setGroupId] = useState<string>();
  const [networkId, setNetworkId] = useState<NetworkType>();
  const [deviceGroupNetworkId, setDeviceGroupNetworkId] =
    useState<NetworkType>();
  const [selectedEmployeesOrGroups, setSelectedEmployeesOrGroups] = useState<
    string[]
  >([]);
  const [zoneDevices, setZoneDevices] = useState<ZoneDevice[]>([]);
  const [employeeOrGroups, setEmployeeOrGroups] = useState<
    Person[] | DeviceGroup[]
  >([]);
  const [selectedGroups, setSelectedGroups] = useState<
    {
      employeeId?: string;
      deviceInfo: { networkId: NetworkType; mac?: Mac }[];
    }[]
  >([]);
  const environment = getConfigurationFromDomain();

  const checkListOnIcon =
    environment.id === BELL ? IconNames.CheckListOnBell : IconNames.CheckListOn;

  const checkListOffIcon =
    environment.id === BELL
      ? IconNames.CheckListOffBell
      : IconNames.CheckListOff;

  const [selectedDevices, setSelectedDevices] = useState<
    { networkId?: NetworkType; mac?: Mac }[]
  >([]);

  const { employeeDevices, defaultDevices } = useDevices();
  const [filteredDeviceGroups, setFilteredDeviceGroups] = useState<
    DeviceGroup[]
  >([]);

  type DeviceToAdd = {
    employeeId: string;
    deviceInfo: { networkId: NetworkType; mac: string }[];
  };
  const devicesToAdd: DeviceToAdd[] = [];

  const sortedDefaultDevices = sortDevices(
    defaultDevices?.approved || [],
    sortingType
  ) as ZoneDevice[];

  const sortedApprovedDevices = sortDevices(
    employeeDevices?.approved || [],
    sortingType
  ) as ZoneDevice[];

  useEffect(() => { }, [deviceGroups]);

  useEffect(() => {
    setSelectedEmployeesOrGroups([]);
    setSelectedGroups([]);
    setSelectedDevices([]);
  }, [device, group, person]);

  useEffect(() => {
    if (deviceGroups?.default) {
      const filteredGroups =
        deviceGroups?.default?.filter(
          (deviceGroup) => !deviceGroup?.standalone
        ) || [];
      setFilteredDeviceGroups(filteredGroups);
    }
  }, [deviceGroups, person]);

  useEffect(() => {
    if (group || device?.networkId === 'default') {
      setZoneDevices(sortedApprovedDevices);
      setEmployeeOrGroups(employees?.data!);
    }
  }, [group, employees.data, device]);

  useEffect(() => {
    const devicesToAddWithEmptyGroupId: DeviceToAdd[] = [];
    if (person || device?.networkId === 'employee') {
      setZoneDevices(sortedDefaultDevices);
      setEmployeeOrGroups(filteredDeviceGroups);
      deviceGroups?.default?.forEach((deviceGroup) => {
        if (deviceGroup.groupShares.includes(person?.id!)) {
          const groupId = deviceGroup.groupId;
          if (
            filteredDeviceGroups
              ?.map((deviceGroup) => deviceGroup.groupId)
              .includes(groupId)
          ) {
            setSelectedEmployeesOrGroups((current) => [...current, groupId]);
          }
          deviceGroup?.devices.forEach((device) => {
            const deviceToAdd = {
              mac: device,
              networkId: deviceGroup.networkId,
            };
            if (
              sortedDefaultDevices.some(
                (device) => device.mac === deviceToAdd.mac
              )
            ) {
              devicesToAddWithEmptyGroupId.push({
                employeeId: '',
                deviceInfo: [deviceToAdd],
              });
            }
          });
          setSelectedDevices((currentDevices) => {
            return getUniqueDevices(
              currentDevices,
              devicesToAddWithEmptyGroupId
            );
          });
          setSelectedGroups((currentSelectedDevices) => {
            const devicesToAddFiltered = devicesToAddWithEmptyGroupId.filter(
              (device) => {
                return (
                  device.employeeId !== '' ||
                  !currentSelectedDevices.some((selectedDevice) =>
                    selectedDevice.deviceInfo.some(
                      (info) => info.mac === device.deviceInfo[0].mac
                    )
                  )
                );
              }
            );

            return [...currentSelectedDevices, ...devicesToAddFiltered];
          });
        }
      });
    }
  }, [person, filteredDeviceGroups, device, setSelectedEmployeesOrGroups]);

  useEffect(() => {
    if (group?.networkId === 'default' || device?.networkId === 'default') {
      setNetworkId('default');
      setDeviceGroupNetworkId('employee');
    }
    if (person || device?.networkId === 'employee') {
      setNetworkId('employee');
      setDeviceGroupNetworkId('default');
    }
  }, [group, device, person]);
  const addUniqueEmployeeOrGroups = (
    currentArray: string[],
    itemsToAdd: string[]
  ): string[] => {
    const uniqueSet = new Set<string>(currentArray);
    itemsToAdd.forEach((item) => {
      uniqueSet.add(item);
    });

    const uniqueArray = Array.from(uniqueSet);

    return uniqueArray;
  };

  const getUniqueDevices = (targetDevices: any[], sourceDevices: any[]) => {
    const uniqueDeviceInfo = new Set(
      sourceDevices
        .filter((device) => device && device.deviceInfo)
        .flatMap((device) =>
          device.deviceInfo.map((info: any) => JSON.stringify(info))
        )
    );

    const newDevices = Array.from(uniqueDeviceInfo).map((info) =>
      JSON.parse(info)
    );

    newDevices.forEach((newDevice) => {
      const isMacAlreadyPresent = targetDevices.some(
        (existingDevice) => existingDevice.mac === newDevice.mac
      );
      if (!isMacAlreadyPresent) {
        targetDevices.push(newDevice);
      }
    });

    return targetDevices;
  };

  useEffect(() => {
    const devicesToAddWithEmptyEmployeeId: DeviceToAdd[] = [];
    if (group) {
      setGroupId(group?.groupId);
      deviceGroups?.employee?.forEach((deviceGroup) => {
        const employeeIdsToAdd = employees
          .data!.filter((employee) => deviceGroup.groupId.includes(employee.id))
          .map((employee) => employee.id);
        if (deviceGroup.groupShares.includes(group?.groupId!)) {
          if (employeeIdsToAdd.length > 0) {
            setSelectedEmployeesOrGroups((current) =>
              addUniqueEmployeeOrGroups(current, employeeIdsToAdd)
            );
          }

          deviceGroup.devices.forEach((device) => {
            const deviceToAdd = {
              mac: device,
              networkId: deviceGroup.networkId,
            };

            if (
              employeeDevices?.approved.some(
                (approvedDevice) => approvedDevice.mac === deviceToAdd.mac
              )
            ) {
              devicesToAddWithEmptyEmployeeId.push({
                employeeId: '',
                deviceInfo: [deviceToAdd],
              });
            }
          });

          setSelectedDevices((currentDevices) => {
            return getUniqueDevices(
              currentDevices,
              devicesToAddWithEmptyEmployeeId
            );
          });

          setSelectedGroups((currentSelectedDevices) => {
            const devicesToAddFiltered = devicesToAddWithEmptyEmployeeId.filter(
              (device) => {
                return (
                  device.employeeId !== '' ||
                  !currentSelectedDevices.some((selectedDevice) =>
                    selectedDevice.deviceInfo.some(
                      (info) => info.mac === device.deviceInfo[0].mac
                    )
                  )
                );
              }
            );

            return [...currentSelectedDevices, ...devicesToAddFiltered];
          });
        }
      });
    }
  }, [group, deviceGroups, device]);

  useEffect(() => {
    const devicesToAddWithEmptyEmployeeId: DeviceToAdd[] = [];
    if (
      (device?.networkId === 'default' || device?.networkId === 'employee') &&
      deviceGroups
    ) {
      const relevantDeviceGroups =
        device?.networkId === 'default'
          ? deviceGroups?.default
          : deviceGroups?.employee;

      relevantDeviceGroups?.forEach((deviceGroup) => {
        const groupShares = deviceGroup?.groupShares.filter((groupShare) =>
          deviceGroup.devices.some((de) => de === device.mac)
        );

        const isEmployeeNetwork = device?.networkId === 'default';
        const employeeIdsToAdd = isEmployeeNetwork
          ? groupShares.filter((groupShare) =>
            employees?.data!.some((employee) => groupShare === employee.id)
          )
          : [];

        if (employeeIdsToAdd.length > 0) {
          setSelectedEmployeesOrGroups((current) =>
            addUniqueEmployeeOrGroups(current, employeeIdsToAdd)
          );
        }

        const groupsToAdd: DeviceToAdd[] = isEmployeeNetwork
          ? []
          : groupShares.flatMap((groupShare) => {
            const matchingGroup = filteredDeviceGroups?.find(
              (group) => groupShare === group.groupId
            );

            const devicesForGroup = matchingGroup?.devices || [];

            return matchingGroup
              ? {
                employeeId: groupShare,
                deviceInfo: devicesForGroup.map((device) => ({
                  networkId: 'employee', // or use the actual networkId value
                  mac: device,
                })),
              }
              : [];
          });

        if (groupsToAdd.length > 0) {
          setSelectedEmployeesOrGroups((current) =>
            addUniqueEmployeeOrGroups(
              current,
              groupsToAdd.map((group) => group.employeeId)
            )
          );
          // Add groups with devices to setSelectedGroups
          setSelectedGroups((currentSelectedDevices) => [
            ...currentSelectedDevices,
            ...groupsToAdd,
          ]);
        }

        groupShares.forEach((groupShare) => {
          const deviceGroupToAdd = isEmployeeNetwork
            ? deviceGroups.employee?.find((dg) => dg.groupId === groupShare)
            : deviceGroups.default?.find((dg) => dg.groupId === groupShare);

          if (deviceGroupToAdd) {
            deviceGroupToAdd.devices.forEach((device) => {
              const isDeviceInZone = zoneDevices
                .map((zoneDevice) => zoneDevice.mac)
                .includes(device);

              if (isDeviceInZone) {
                const deviceToAdd = {
                  networkId: deviceGroupToAdd.networkId,
                  mac: device,
                };

                devicesToAddWithEmptyEmployeeId.push({
                  employeeId: '',
                  deviceInfo: [deviceToAdd],
                });
              }
            });
          }
        });
      });

      setSelectedDevices((currentDevices) =>
        getUniqueDevices(currentDevices, devicesToAddWithEmptyEmployeeId)
      );

      setSelectedGroups((currentSelectedDevices) => {
        const devicesToAddFiltered = devicesToAddWithEmptyEmployeeId.filter(
          (device) => {
            return (
              device.employeeId !== '' ||
              !currentSelectedDevices.some((selectedDevice) =>
                selectedDevice.deviceInfo.some(
                  (info) => info.mac === device.deviceInfo[0].mac
                )
              )
            );
          }
        );

        return [...currentSelectedDevices, ...devicesToAddFiltered];
      });
    }
  }, [device, deviceGroups, filteredDeviceGroups, employees.data]);

  useEffect(() => {
    handleSelectDevices();
  }, [selectedEmployeesOrGroups]);

  const handleSelectDevices = (selectedGroup?: DeviceGroup) => {
    if (group || device?.networkId === 'default') {
      if (selectedEmployeesOrGroups.length >= 1 && employeeDevices?.approved) {
        for (const employeeId of selectedEmployeesOrGroups) {
          const employeeDevicesFiltered = employeeDevices.approved
            .filter((device) => device.personId === employeeId)
            .reduce((acc, device) => {
              if (!(employeeId in acc)) {
                acc[employeeId] = [];
              }

              const existingDevice = acc[employeeId].find(
                (existing) => existing.mac === device.mac
              );

              if (!existingDevice) {
                acc[employeeId].push({
                  networkId: device.networkId,
                  mac: device.mac,
                });
              }

              return acc;
            }, {} as Record<string, { networkId: NetworkType; mac: Mac }[]>);
          const selectedDevicesArray: DeviceToAdd[] = Object.entries(
            employeeDevicesFiltered
          ).map(([employeeId, devices]) => ({
            employeeId,
            deviceInfo: devices,
          }));
          devicesToAdd.push(...selectedDevicesArray);
        }

        if (devicesToAdd.length > 0) {
          setSelectedGroups((currentSelectedDevices) => {
            const updatedSelectedDevices = [...currentSelectedDevices];

            for (const newDevice of devicesToAdd) {
              const index = updatedSelectedDevices.findIndex(
                (device) => device.employeeId === newDevice.employeeId
              );

              if (index !== -1) {
                updatedSelectedDevices[index].deviceInfo = newDevice.deviceInfo;
              } else {
                updatedSelectedDevices.push(newDevice);
              }
            }

            setSelectedDevices((currentSelectedOnlyDevices) => {
              return getUniqueDevices(currentSelectedOnlyDevices, devicesToAdd);
            });

            return updatedSelectedDevices;
          });
        }
      }
    }
    if (person || device?.networkId === 'employee') {
      const personId: string | undefined = person?.id;
      // Create an array to store devices to be added to selectedGroups and selectedDevices

      filteredDeviceGroups?.forEach((deviceGroup) => {
        if (deviceGroup.groupShares.includes(personId!)) {
          const isGroupSelected = selectedGroups.some(
            (device) => device.employeeId === deviceGroup.groupId
          );
          const isGroupInSelectedEmployees = selectedEmployeesOrGroups.includes(
            deviceGroup.groupId
          );

          // Remove the group from selectedGroups if it is already selected
          if (isGroupSelected) {
            setSelectedGroups((currentSelectedDevices) =>
              currentSelectedDevices.filter(
                (device) => device.employeeId !== deviceGroup.groupId
              )
            );
          }

          // Add the group and its devices to selectedGroups and devicesToAdd
          if (
            isGroupInSelectedEmployees &&
            deviceGroup.groupShares.includes(personId!)
          ) {
            const newGroup: DeviceToAdd = {
              employeeId: deviceGroup.groupId,
              deviceInfo: (deviceGroup.devices || []).map((mac) => ({
                networkId: 'employee', // or use the actual networkId value
                mac,
              })),
            };

            // Add the newGroup to devicesToAdd
            devicesToAdd.push(newGroup);

            // Add or update the group in selectedGroups
            setSelectedGroups((currentSelectedDevices) => [
              ...currentSelectedDevices.filter(
                (device) => device.employeeId !== deviceGroup.groupId
              ),
              newGroup,
            ]);
          }
        }
      });

      // Add devicesToAdd to selectedDevices
      if (devicesToAdd.length > 0) {
        setSelectedDevices((currentSelectedOnlyDevices) =>
          getUniqueDevices(currentSelectedOnlyDevices, devicesToAdd)
        );
      }
    }
    if (selectedGroup) {
      const isGroupSelected = selectedGroups.some(
        (device) => device.employeeId === selectedGroup.groupId
      );

      if (isGroupSelected) {
        setSelectedGroups((currentSelectedDevices) =>
          currentSelectedDevices.filter(
            (device) => device.employeeId !== selectedGroup.groupId
          )
        );
      } else {
        const newGroup: DeviceToAdd = {
          employeeId: selectedGroup.groupId,
          deviceInfo: (selectedGroup.devices || [])
            .filter((mac) =>
              sortedDefaultDevices.map((device) => device.mac).includes(mac)
            )
            .map((mac) => ({
              networkId: 'default',
              mac,
            })),
        };

        setSelectedGroups((currentSelectedDevices) => [
          ...currentSelectedDevices.filter(
            (device) => device.employeeId !== selectedGroup.groupId
          ),
          newGroup,
        ]);

        // Create devicesToAdd array for the selectedGroup
        const devicesToAdd: DeviceToAdd[] = [newGroup];

        setSelectedDevices((currentSelectedOnlyDevices) => {
          const uniqueDevices = getUniqueDevices(
            currentSelectedOnlyDevices,
            devicesToAdd
          );
          return [...uniqueDevices];
        });
      }
    }
  };

  const getImageId = (personId: Nullable<Maybe<string>>) => {
    if (!personId) {
      return;
    }

    return employees?.data?.filter((e) => e.id === personId)?.[0]?.imageId;
  };

  const selectEmployee = useCallback(
    (employeeOrGroup?: Person | DeviceGroup) => {
      let id: string | undefined;
      if ('id' in employeeOrGroup!) {
        // It's a Person
        id = employeeOrGroup.id;
      } else if ('groupId' in employeeOrGroup!) {
        // It's a DeviceGroup
        id = employeeOrGroup.groupId;
      }
      if (!id) return;

      const isEmployeeSelectedIndex = selectedEmployeesOrGroups.indexOf(id);
      if (isEmployeeSelectedIndex !== -1) {
        setSelectedEmployeesOrGroups((current) =>
          current.filter((item) => item !== id)
        );

        let devicesToRemove: (string | undefined)[] = [];

        setSelectedGroups((currentSelectedDevices) => {
          devicesToRemove =
            currentSelectedDevices &&
            currentSelectedDevices
              .filter((selectedDevice) => selectedDevice.employeeId === id)
              .flatMap((device) => device.deviceInfo.map((info) => info.mac));

          const updatedSelectedDevices = currentSelectedDevices
            ? currentSelectedDevices.filter((selectedDevice) => {
              const deviceInfo = selectedDevice.deviceInfo[0];
              return (
                deviceInfo &&
                selectedDevice.employeeId !== id &&
                !devicesToRemove.includes(deviceInfo.mac || '')
              );
            })
            : [];

          setSelectedDevices((currentSelectedOnlyDevices) =>
            currentSelectedOnlyDevices
              ? currentSelectedOnlyDevices.filter(
                (selectedDevice) =>
                  !devicesToRemove.includes(selectedDevice.mac)
              )
              : []
          );

          return updatedSelectedDevices;
        });
      } else {
        setSelectedEmployeesOrGroups((current) => [...current, id!]);
        if ('groupId' in employeeOrGroup! && id === employeeOrGroup.groupId) {
          handleSelectDevices(employeeOrGroup);
        }
      }
    },
    [selectedEmployeesOrGroups]
  );

  const handleSelectAll = useCallback(async () => {
    if (
      selectedEmployeesOrGroups.length === employees?.data?.length ||
      selectedEmployeesOrGroups.length === filteredDeviceGroups?.length
    ) {
      setSelectedEmployeesOrGroups([]);
      setSelectedGroups([]);
      setSelectedDevices([]);
    } else {
      if (group || device?.networkId === 'default') {
        const allEmployeeIds = employees?.data?.map(
          (employee: Person) => employee.id
        );
        setSelectedEmployeesOrGroups(allEmployeeIds!);
      } else if (person || device?.networkId === 'employee') {
        const allGroupsIds = filteredDeviceGroups?.map(
          (deviceGroup) => deviceGroup.groupId
        );
        setSelectedEmployeesOrGroups(allGroupsIds!);
      }
    }
  }, [
    employees.data,
    selectedEmployeesOrGroups,
    setSelectedEmployeesOrGroups,
    filteredDeviceGroups,
  ]);

  const handleDevicesSelectAll = useCallback(async () => {
    let allDevices: { networkId: NetworkType; mac: string }[] = [];
    if (
      selectedDevices.length === sortedApprovedDevices?.length ||
      selectedDevices.length === sortedDefaultDevices?.length
    ) {
      setSelectedDevices([]);
      setSelectedGroups([]);
    } else {
      if (group || device?.networkId === 'default') {
        allDevices = sortedApprovedDevices?.map((device: ZoneDevice) => ({
          networkId: device.networkId,
          mac: device.mac,
        }));
      } else if (person || device?.networkId === 'employee') {
        allDevices = sortedDefaultDevices?.map((device: ZoneDevice) => ({
          networkId: device.networkId,
          mac: device.mac,
        }));
      }

      setSelectedDevices((currentSelectedOnlyDevices) => {
        const uniqueDevices = allDevices.filter(
          (newDevice) =>
            !currentSelectedOnlyDevices.some(
              (existingDevice) => existingDevice.mac === newDevice.mac
            )
        );
        return [
          ...currentSelectedOnlyDevices,
          ...uniqueDevices.filter(
            (uniqueDevice) =>
              !currentSelectedOnlyDevices.some(
                (existingDevice) => existingDevice.mac === uniqueDevice.mac
              )
          ),
        ];
      });
    }
  }, [
    setSelectedDevices,
    sortedApprovedDevices,
    selectedDevices,
    sortedDefaultDevices,
  ]);

  const selectDevice = useCallback((device: ZoneDevice) => {
    setSelectedGroups((currentSelectedDevices) => {
      const isDeviceSelectedIndices = currentSelectedDevices.reduce(
        (indices, selectedDevice, index) => {
          if (selectedDevice.deviceInfo.some((de) => de.mac === device.mac)) {
            indices.push(index);
          }
          return indices;
        },
        [] as number[]
      );

      if (isDeviceSelectedIndices.length > 0) {
        const updatedSelectedDevices = currentSelectedDevices.filter(
          (_, index) => !isDeviceSelectedIndices.includes(index)
        );

        const removedEmployeeIds = isDeviceSelectedIndices.map(
          (index) => currentSelectedDevices[index].employeeId
        );
        setSelectedEmployeesOrGroups((currentSelectedEmployees) =>
          currentSelectedEmployees.filter(
            (selectedEmployee) => !removedEmployeeIds.includes(selectedEmployee)
          )
        );
        setSelectedDevices((currentSelectedDevices) =>
          currentSelectedDevices.filter(
            (selectedDevice) => selectedDevice.mac !== device.mac
          )
        );

        return updatedSelectedDevices;
      } else {
        const newDevice = {
          employeeId: '',
          deviceInfo: [{ networkId: device.networkId, mac: device.mac }],
        };

        const updatedSelectedDevices = [...currentSelectedDevices, newDevice];
        setSelectedGroups(updatedSelectedDevices);

        const filteredDeviceInfo = newDevice.deviceInfo.filter(
          (info) =>
            !currentSelectedDevices.some((selectedDevice) =>
              selectedDevice.deviceInfo.some((de) => de.mac === info.mac)
            )
        );

        setSelectedDevices((currentSelectedOnlyDevices) => [
          ...currentSelectedOnlyDevices,
          ...filteredDeviceInfo.map((info) => ({
            networkId: info.networkId || '',
            mac: info.mac || '',
          })),
        ]);

        return updatedSelectedDevices;
      }
    });
  }, []);
  const getIdentifier = (item: Person | DeviceGroup): string => {
    return 'id' in item ? item.id : item.groupId;
  };

  const handleSubmit = () => {
    const finalDevices = selectedDevices
      .filter(
        (selectedDevice) =>
          selectedGroups.some(
            (device) =>
              (device.employeeId === '' || device.employeeId === undefined) &&
              device.deviceInfo.some((info) => info.mac === selectedDevice.mac)
          ) &&
          !selectedGroups.some((device) =>
            device.deviceInfo.some(
              (info) =>
                info.mac === selectedDevice.mac && device.employeeId !== ''
            )
          )
      )
      .concat(
        selectedDevices.filter(
          (selectedDevice) =>
            !selectedGroups.some((device) =>
              device.deviceInfo.some((info) => info.mac === selectedDevice.mac)
            )
        )
      )
      .map((selectedDevice) => ({
        networkId: selectedDevice.networkId,
        mac: selectedDevice.mac || '',
      }));

    if (group) {
      dispatch(
        actions.zones.employeeGroupShareAccess.updateEmployeeOrGroupShareAccess(
          {
            groups: selectedEmployeesOrGroups,
            groupId: groupId,
            devices: finalDevices,
            networkId: networkId,
            deviceGroupNetworkId: deviceGroupNetworkId,
          }
        )
      );
    }
    if (device) {
      dispatch(
        actions.zones.employeeGroupShareAccess.updateDeviceShareAccess({
          groups: selectedEmployeesOrGroups,
          networkId: networkId,
          mac: device?.mac,
          devices: finalDevices,
          deviceGroupNetworkId: deviceGroupNetworkId,
        })
      );
    }
    if (person) {
      dispatch(
        actions.zones.employeeGroupShareAccess.updateEmployeeOrGroupShareAccess(
          {
            groups: selectedEmployeesOrGroups,
            groupId: person.id,
            devices: finalDevices,
            networkId: networkId,
            deviceGroupNetworkId: deviceGroupNetworkId,
          }
        )
      );
    }
    closeSidepanel();
  };
  return (
    <SimpleSidePanel title={t('devices.shareAccess')}>
      <SidePanelContentWrapper className={styles.sidePanelContentWrapper}>
        <ContentLayout
          main={
            group || device?.networkId === 'default' ? (
              <Body3Medium>{t('shareAccess.devicesBelong')}</Body3Medium>
            ) : (
              <Body3Medium>{t('shareAccess.secureZoneGroups')}</Body3Medium>
            )
          }
          mainTextWrap={false}
          right={
            <Body3Medium
              className={styles.selectAll}
              onClick={() => handleSelectAll()}
            >
              {selectedEmployeesOrGroups.length === employees.data?.length ||
                selectedEmployeesOrGroups.length === filteredDeviceGroups.length
                ? t('shareAccess.deSelectAll')
                : t('shareAccess.selectAll')}
            </Body3Medium>
          }
        />
        <Card noBottomPadding>
          {employeeOrGroups?.map((item, i) => (
            <Fragment key={`${getIdentifier(item)}_${i}`}>
              <DeviceListItem
                className={
                  person || device?.networkId === 'employee'
                    ? styles.listItem
                    : undefined
                }
                L1Props={{
                  iconProps:
                    group || (device && device.networkId === 'default')
                      ? {
                        name:
                          (item as Person).imageId &&
                            mediumProfilePhoto.hasOwnProperty(
                              (item as Person).imageId
                            )
                            ? mediumProfilePhoto[(item as Person).imageId]
                            : mediumProfilePhoto.PROFILE_MAN,
                        className: styles.employeeAvatar,
                      }
                      : undefined,
                }}
                L2Props={{
                  label:
                    group || device?.networkId === 'default'
                      ? (item as Person).nickname
                      : (item as DeviceGroup).name,
                }}
                RProps={{
                  iconProps: {
                    name:
                      selectedEmployeesOrGroups.includes((item as Person).id) ||
                        selectedEmployeesOrGroups.includes(
                          (item as DeviceGroup).groupId
                        )
                        ? checkListOnIcon
                        : checkListOffIcon,
                    onClick: () => selectEmployee(item),
                    ariaLabel:
                      group || device?.networkId === 'default'
                        ? (item as Person).nickname
                        : (item as DeviceGroup).name,
                  },
                }}
                onClick={() => selectEmployee(item)}
              />
              <Divider />
            </Fragment>
          ))}
        </Card>
        <ContentLayout
          main={
            group || device?.networkId === 'default' ? (
              <Body3Medium>{t('shareAccess.employeeZoneDevices')}</Body3Medium>
            ) : (
              <Body3Medium>{t('shareAccess.secureGroupDevices')}</Body3Medium>
            )
          }
          mainTextWrap={false}
          right={
            <Body3Medium
              className={styles.selectAll}
              onClick={() => handleDevicesSelectAll()}
            >
              {selectedDevices.length === sortedApprovedDevices.length ||
                selectedDevices.length === sortedDefaultDevices.length
                ? t('shareAccess.deSelectAll')
                : t('shareAccess.selectAll')}
            </Body3Medium>
          }
        />
        <Card noBottomPadding className={styles.bottomCard}>
          {zoneDevices.map((device, index) => {
            const imageId: string | undefined = getImageId(
              (device as ZoneDevice).personId
            );
            return (
              <Fragment key={`${device.mac}_${index}`}>
                <DeviceListItem
                  L1Props={{
                    iconProps: {
                      name: smallProfilePhoto[imageId!],
                    },
                    mediumDeviceIconProps: {
                      fileName: device.icon,
                      size: 'small',
                    },
                    className: styles.deviceWithNoPersonIcons,
                  }}
                  L2Props={{
                    label: device.nickname || device.name,
                  }}
                  RProps={{
                    iconProps: {
                      name: selectedDevices.some(
                        (selectedDevice) => selectedDevice.mac === device.mac
                      )
                        ? checkListOnIcon
                        : checkListOffIcon,
                    },
                  }}
                  onClick={() => selectDevice(device)}
                />

                <Divider />
              </Fragment>
            );
          })}
        </Card>
      </SidePanelContentWrapper>
      <div
        className={
          environment.id === BELL
            ? styles.doneBtnContainerBell
            : styles.doneBtnContainer
        }
      >
        <Button
          className={
            environment.id === BELL ? styles.doneBtnBell : styles.doneBtn
          }
          theme={BUTTON_THEMES.black}
          label={t('common.save')}
          onClick={() => handleSubmit()}
        />
      </div>
    </SimpleSidePanel>
  );
};

export default ShareAccessSidePanel;
