import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  SpeedTestStates,
  SPEED_TEST_STATES,
} from 'Pages/Homepage/Components/Wlan/Components/Internet speed';

import * as api from 'Api/endpoints';

import * as actions from '../actions';
import * as selectors from '../selectors';
import type { AppDispatch } from '../store';

import useLocations from './useLocations';
import useIspSpeedTest from './useIspSpeedTest';

const useCheckSpeed = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { activeLocation } = useLocations();

  const { data } = useIspSpeedTest();
  const customerId = activeLocation.data?.customerId;
  const { data: token } = useSelector(selectors.auth.locationToken);
  const cloud = useSelector(selectors.auth.cloud);

  const [speedTestState, setSpeedTestState] = useState<SpeedTestStates>(
    SPEED_TEST_STATES.results
  );
  const previousLastChecked = useRef(data?.dashboard.mostRecent.timestamp ?? 0);

  const onCheckSpeed = useCallback(() => {
    (async () => {
      if (!customerId || !activeLocation.data?.id || !token) {
        return;
      }

      setSpeedTestState(SPEED_TEST_STATES.firstCheck);

      await api.startIspSpeedTest({
        customerId,
        locationId: activeLocation.data?.id,
        token,
        cloud,
      });
    })();
  }, [activeLocation.data?.id, cloud, customerId, token]);

  useEffect(() => {
    if (!data?.dashboard.mostRecent.timestamp) {
      return;
    }

    if (data.dashboard.mostRecent.timestamp > previousLastChecked.current) {
      // TODO add snackbar notification
      setSpeedTestState(SPEED_TEST_STATES.results);
    }

    previousLastChecked.current = data?.dashboard.mostRecent.timestamp ?? 0;
  }, [data]);

  useEffect(() => {
    let phaseTimeoutId: NodeJS.Timeout | null = null;
    let checkIntervalId: NodeJS.Timeout | null = null;

    const cancelTimeout = () => {
      phaseTimeoutId && clearTimeout(phaseTimeoutId);
      checkIntervalId && clearInterval(checkIntervalId);
    };
    switch (speedTestState) {
      case SPEED_TEST_STATES.firstCheck:
        phaseTimeoutId = setTimeout(() => {
          setSpeedTestState(SPEED_TEST_STATES.secondCheck);
        }, 5000);

        checkIntervalId = setInterval(() => {
          dispatch(actions.speedTest.fetchData());
        }, 2000);

        break;

      case SPEED_TEST_STATES.secondCheck:
        phaseTimeoutId = setTimeout(() => {
          setSpeedTestState(SPEED_TEST_STATES.thirdCheck);
        }, 15000);

        checkIntervalId = setInterval(() => {
          dispatch(actions.speedTest.fetchData());
        }, 2000);

        break;

      case SPEED_TEST_STATES.thirdCheck:
        phaseTimeoutId = setTimeout(() => {
          setSpeedTestState(SPEED_TEST_STATES.fourthCheck);
        }, 15000);

        checkIntervalId = setInterval(() => {
          dispatch(actions.speedTest.fetchData());
        }, 2000);

        break;

      case SPEED_TEST_STATES.fourthCheck:
        phaseTimeoutId = setTimeout(() => {
          setSpeedTestState(SPEED_TEST_STATES.error);
        }, 25000);

        checkIntervalId = setInterval(() => {
          dispatch(actions.speedTest.fetchData());
        }, 2000);

        break;

      default:
        cancelTimeout();

        break;
    }

    return cancelTimeout;
  }, [speedTestState, dispatch]);

  return {
    speedTestState,
    onCheckSpeed,
  };
};

export default useCheckSpeed;
