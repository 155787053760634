import React, { FunctionComponent } from 'react';
import Page from 'UI/Layout/Page';
import Background from 'UI/Layout/Background';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import useWifiSettings from 'State/hooks/useWifiSettings';
import SettingsContent from './SettingsContent';
import { SettingsKey, SETTINGS_LIST } from './definitions';
import useIsMobile from 'Utils/hooks/useIsMobile';

export type ActiveProps = {
  active?: SettingsKey;
  setActive?: (index: SettingsKey) => void | undefined;
};

export type NetworkAccessPayload = {
  purgatory: boolean;
  networkId: string;
};

type SettingsPageProps = {
  active?: SettingsKey;
  setActive?: (index: SettingsKey) => void | undefined;
};

const SettingsPage: FunctionComponent<SettingsPageProps> = ({
  active = SETTINGS_LIST.index,
  setActive,
}) => {
  const isMobile = useIsMobile();

  useWifiSettings();
  useNetworkAccess();

  const selectedActive =
    active === SETTINGS_LIST.index && !isMobile ? SETTINGS_LIST.secure : active;

  return (
    <Page>
      {isMobile ? (
        <SettingsContent active={selectedActive} />
      ) : (
        <Background>
          <SettingsContent active={selectedActive} />
        </Background>
      )}
    </Page>
  );
};

export default SettingsPage;
