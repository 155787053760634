import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'UI/Layout/Page';
import pageHeaderStyles from 'UI/Layout/Page header/style.module.css';
import Background from 'UI/Layout/Background';
import ScreenHeader from 'UI/Components/Headers/Header screen web';
import { useTranslation } from 'react-i18next';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import styles from './style.module.css';
import { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import { AppList } from 'UI/Reusable/AppTime';
import useEmployeeMonthlyAppsOnlineTime from 'State/hooks/useEmployeeMonthlyAppsOnlineTime';
import { Card } from 'UI/Elements/Card';
import useGuestMonthlyAppsOnlineTime from 'State/hooks/useGuestMonthlyAppsOnlineTime';
import useDefaultMonthlyAppsOnlineTime from 'State/hooks/useDefaultMonthlyAppsOnlineTime';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from 'trackingAnalytics/types';
import { AppTimeFilters } from './Components/Header filter';
import {
  AppOnlineTime,
  OnlineTimeAppLast12Months,
  OnlineTimeAppLast30Days,
  Period,
  TimeDailyAppStats,
  TimeMonthlyAppStats,
  AppCountPerCategory,
  Categories,
  NetworkType,
} from 'Consts/types';
import { PERIODS } from 'Consts/defintions';
import useEmployeeLast12MonthsAppsOnlineTime from 'State/hooks/useEmployeeLast12MonthsAppsOnlineTime';
import useGuestLast12MonthsAppsOnlineTime from 'State/hooks/useGuestLast12MonthsAppsOnlineTime';
import useDefaultLast12MonthsAppsOnlineTime from 'State/hooks/useDefaultLast12MonthsAppsOnlineTime';
import { appCategories } from 'Consts/appCategories';
import { ROUTES } from 'Consts/routes';
import { useDispatch } from 'react-redux';
import * as globalActions from '../../State/actions';
import usePersonLast12MonthsAppsOnlineTime from 'State/hooks/usePersonLast12MonthsAppsOnlineTime';
import usePersonMonthlyAppsOnlineTime from 'State/hooks/usePersonMonthlyAppsOnlineTime';
import useEmployee from 'State/hooks/useEmployee';

const AppTimeInsights: FunctionComponent = () => {
  const { t } = useTranslation();
  const { closeSidepanel } = useSidepanel();
  const { personId, network } = useParams();
  const navigate = useNavigate();
  const employeeAppsTime = useEmployeeMonthlyAppsOnlineTime();
  const guestAppsTime = useGuestMonthlyAppsOnlineTime();
  const secureAppsTime = useDefaultMonthlyAppsOnlineTime();
  const employeeAppsTimeLast12Months = useEmployeeLast12MonthsAppsOnlineTime();
  const guestAppsTimeLast12Months = useGuestLast12MonthsAppsOnlineTime();
  const { data: employeedata } = useEmployee(personId || '');

  const personAppsTime = usePersonMonthlyAppsOnlineTime(personId || '');
  const personLast12MonthsAppsOnlineTime = usePersonLast12MonthsAppsOnlineTime(
    personId || ''
  );
  const secureAppsTimeLast12Months = useDefaultLast12MonthsAppsOnlineTime();
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();
  const [period, setPeriod] = useState<Period>(PERIODS.day);
  const [category, setCategory] = useState<string>('all');

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.AppInsights,
      },
    });
  }, []);

  useEffect(() => {
    if (
      !['employee', 'default', 'guest'].includes(network as NetworkType) &&
      !personId
    ) {
      dispatch(
        globalActions.ui.errorAlert.set({
          errorState: {
            message: 'Invalid network type',
            redirectRoute: ROUTES.home.index,
          },
        })
      );
    }
  }, [network, personId]);

  const networkLabel = useMemo(() => {
    switch (network) {
      case 'default':
        return t('common.secureZone');
      case 'employee':
        return t('common.employeeZone');
      case 'guest':
        return t('common.guestZone');
      default:
        return t('common.unknown');
    }
  }, [network]);

  const deduplicateAndSumValues = (
    arr: TimeDailyAppStats[] | TimeMonthlyAppStats[]
  ): AppOnlineTime[] => {
    if (!arr) return [];
    const map = new Map<string, AppOnlineTime>();

    // Iterate over each (day or month) item in the array
    for (const subArr of arr) {
      // Iterate over each App for that day/month
      for (const obj of subArr?.apps) {
        const existingObj = map.get(obj.appId);

        // If an app object with the same ID already exists, add the value to it
        if (existingObj) {
          existingObj.onlineSeconds += obj.onlineSeconds;
        } else {
          // Otherwise, add the app object to the map
          map.set(obj.appId, {
            appId: obj.appId,
            onlineSeconds: obj.onlineSeconds,
            category: obj.category,
          });
        }
      }
    }

    // Convert the map values to an array of apps
    return Array.from(map.values());
  };

  const applicationsDataSetToDisplay = useMemo(() => {
    if (personId) {
      return period === PERIODS.year
        ? personLast12MonthsAppsOnlineTime
        : personAppsTime;
    }
    switch (network) {
      case 'default':
        return period === PERIODS.year
          ? secureAppsTimeLast12Months
          : secureAppsTime;
      case 'employee':
        return period === PERIODS.year
          ? employeeAppsTimeLast12Months
          : employeeAppsTime;
      case 'guest':
        return period === PERIODS.year
          ? guestAppsTimeLast12Months
          : guestAppsTime;
      default:
        return secureAppsTime;
    }
  }, [
    network,
    employeeAppsTime,
    guestAppsTime,
    secureAppsTime,
    personAppsTime,
    secureAppsTimeLast12Months,
    employeeAppsTimeLast12Months,
    guestAppsTimeLast12Months,
    personLast12MonthsAppsOnlineTime,
    personId,
  ]);

  const fullAppSet = useMemo(() => {
    if (period === PERIODS.day) {
      return (
        applicationsDataSetToDisplay.data as OnlineTimeAppLast30Days
      )?.dailyStats?.[0]?.apps.slice();
    }
    if (period === PERIODS.month) {
      return deduplicateAndSumValues(
        (applicationsDataSetToDisplay.data as OnlineTimeAppLast30Days)
          ?.dailyStats
      );
    } else
      return deduplicateAndSumValues(
        (applicationsDataSetToDisplay.data as OnlineTimeAppLast12Months)
          ?.monthlyStats
      );
  }, [applicationsDataSetToDisplay, period, category]);

  const appSetCategoryFiltered = useMemo(() => {
    return fullAppSet?.filter(
      (app) => app?.category === category || !app.category || category === 'all'
    );
  }, [fullAppSet]);

  const appCategoriesWithCount: AppCountPerCategory[] = useMemo(() => {
    return appCategories.map((categoryItem) => {
      return {
        category: categoryItem as Categories,
        appCount:
          categoryItem !== 'all'
            ? fullAppSet?.filter((app) => app.category === categoryItem).length
            : fullAppSet?.length,
      };
    });
  }, [fullAppSet]);

  return (
    <Page>
      <Background>
        <ScreenHeader
          className={pageHeaderStyles.pageHeader}
          LProps={{
            label: t('common.appTime'),
            paragraph: network
              ? networkLabel
              : employeedata?.nickname || personId || 'unknown',
            iconProps: {
              name: IconNames.ChevronLeft,
              onClick: () => {
                navigate(-1);
                closeSidepanel();
              },
              tooltipLabel: t('common.goBack'),
              shape: IconShape.square,
            },
          }}
        >
          <AppTimeFilters
            period={period}
            setPeriod={setPeriod}
            category={category}
            setCategory={setCategory}
            categorySet={appCategoriesWithCount}
          ></AppTimeFilters>
        </ScreenHeader>
        <div className={styles.contentWrapper}>
          <Card
            className={styles.appTimeCard}
            isLoading={applicationsDataSetToDisplay.isLoading}
            errorMessage={applicationsDataSetToDisplay.errorMessage}
            emptyCardLabel={
              (appSetCategoryFiltered ?? []).length === 0 &&
              !applicationsDataSetToDisplay.isLoading
                ? t('appTime.noEvents')
                : ''
            }
          >
            <AppList applications={appSetCategoryFiltered ?? []} />
          </Card>
        </div>
      </Background>
    </Page>
  );
};

export default AppTimeInsights;
