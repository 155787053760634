import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDefaultMonthlyAppsOnlineTime = () =>
  useUpdatedData({
    selector: selectors.appsOnlineTime.default.monthly,
    action: actions.appsOnlineTime.default.monthly.fetchData(),
  });

export default useDefaultMonthlyAppsOnlineTime;
