import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ZoneDevice } from 'Consts/types';

import { MenuItemProps } from 'UI/Components/Menu';

import DevicesCard from '../Devices card';
import { View } from '../types';

import styles from './style.module.css';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import cn from 'classnames';

const BELL = 'bell';

type QuarantinedDevicesCardProps = {
  isLoading?: boolean;
  errorMessage?: string;
  devices?: ZoneDevice[];
  columnSpan: 1 | 2 | 3;
  itemLimitForOneColumn?: number | null;
  headerMenuItems: MenuItemProps[];
  menuItems: MenuItemProps[];
  onViewAllClick: (view: View) => void;
};

const QuarantinedDevicesCard: FunctionComponent<
  QuarantinedDevicesCardProps
> = ({
  isLoading,
  errorMessage,
  devices = [],
  columnSpan,
  itemLimitForOneColumn = null,
  headerMenuItems,
  menuItems,
  onViewAllClick,
}) => {
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();
  const handleQuarantinedDevicesCardViewAllClick = useCallback(() => {
    onViewAllClick('quarantined'); //TODO
  }, [onViewAllClick]);

  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);

  return (
    <DevicesCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      devices={devices}
      title={t('devices.quarantinedDevices')}
      columnSpan={columnSpan}
      itemLimitForOneColumn={itemLimitForOneColumn}
      headerMenuItems={headerMenuItems}
      menuItems={menuItems}
      className={cn(styles.soreCardBackground, {
        [styles.bellCardBackground]: isBell,
      })}
      footerClassName={cn(styles.soreCardFooterBackground, {
        [styles.bellCardFooterBackground]: isBell,
      })}
      onViewAllClick={handleQuarantinedDevicesCardViewAllClick}
      infoTooltipLabel={t('devices.quarantinedTooltip', { ns: namespace })}
    />
  );
};

export default QuarantinedDevicesCard;
