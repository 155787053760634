import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';
import StandardListItem from 'UI/Components/Lists/List standard';
import Menu, { MenuItemProps } from 'UI/Components/Menu';
import { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import { Body3 } from 'UI/Elements/Typography';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.css';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

const BlockedEmployeesSidePanel: FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const [selectedEmployeeIdp, setSelectedEmployeeIdp] = useState('');
  const parentRef = useRef<Element | null>(null);
  const blockedEmployees = useSelector(
    selectors.settings.blockedEmployees
  ).data;
  const handleShowBlockedEmployeeMenu = useCallback(
    (ev: React.MouseEvent, idpUserId: string) => {
      handleTriggerEvent(ev);

      setSelectedEmployeeIdp(idpUserId);
      setIsMenuOpen(true);
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
      parentRef.current = ev.currentTarget;
    },
    []
  );

  const handleUnblockOrDeleteClick = (action: 'unblock' | 'delete') => {
    const successMessage =
      action === 'unblock'
        ? t('settings.unblocked')
        : t('settings.recordDeleted');

    dispatch(
      actions.settings.blockedEmployees.unblockEmployee(
        selectedEmployeeIdp,
        successMessage
      )
    );
  };

  const menuItems: MenuItemProps[] = [
    {
      label: t('settings.unblock'),
      iconName: IconNames.Check,
      onClick: () => handleUnblockOrDeleteClick('unblock'),
    },
    {
      label: t('settings.deleteRecord'),
      iconName: IconNames.Trash,
      onClick: () => handleUnblockOrDeleteClick('delete'),
    },
  ];

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    parentRef.current = null;
  }, []);

  return (
    <SimpleSidePanel title={t('settings.blockedEmployees')}>
      <SidePanelContentWrapper className={styles.blockedEmployeeList}>
        {blockedEmployees?.map((employee) => (
          <StandardListItem
            className={styles.blockedEmployeeListItem}
            L2Props={{ label: employee.name }}
            RProps={{
              icon3Props: {
                name: IconNames.OverflowVertical,
                shape: IconShape.square,
                onClick: (ev) =>
                  handleShowBlockedEmployeeMenu(ev, employee.idpUserId),
                tooltipLabel: t('common.moreActions'),
                ariaLabel: t('common.moreActions'),
              },
            }}
            key={employee.idpUserId}
            ariaLabel={employee.name}
          />
        ))}
        <Body3 className={styles.blockedEmployeesDescription}>
          {t('settings.blockedEmployeesDescription')}
        </Body3>
      </SidePanelContentWrapper>
      <Menu
        isOpen={isMenuOpen}
        parent={parentRef.current}
        items={menuItems}
        onClose={handleCloseMenu}
        openTriggerEventType={menuOpenTrigger}
      />
    </SimpleSidePanel>
  );
};

export default BlockedEmployeesSidePanel;
