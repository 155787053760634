import React, { FunctionComponent, useCallback } from 'react';
import styles from './style.module.css';
import colorStyles from 'Styles/colors.module.css';
import {
  Body1,
  Heading4,
  Heading4Medium,
  Heading5Medium,
} from 'UI/Elements/Typography';
import Button from 'UI/Elements/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';

export type ChartPlaceholderProps = {
  backgroundImageUrl: string;
  placeholderTitle: string;
  placeholderSubtitle?: string;
  height: string;
};

const ChartPlaceholder: FunctionComponent<ChartPlaceholderProps> = ({
  backgroundImageUrl,
  placeholderTitle,
  placeholderSubtitle,
  height,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const onAppTimeEnableSuccess = useCallback(() => {
    dispatch(
      actions.ui.miniBanner.notify({
        label: t('appTime.appTimeEnableSuccess'),
        state: 'set',
      })
    );
  }, [dispatch, t]);

  const onAppTimeEnableError = useCallback(() => {
    dispatch(
      actions.ui.miniBanner.notify({
        label: t('appTime.appTimeEnableFailure'),
        state: 'unset',
      })
    );
  }, [dispatch, t]);

  const handleAppTimeEnable = useCallback(() => {
    dispatch(
      actions.locations.updateActiveLocationAppTime(
        true,
        onAppTimeEnableSuccess,
        onAppTimeEnableError
      )
    );
  }, [dispatch, onAppTimeEnableSuccess, onAppTimeEnableError, t]);

  return (
    <div className={styles.chartPlaceholder} style={{ height }}>
      <div className={styles.chartPlaceholderHeader}>
        <Heading4Medium>{placeholderTitle}</Heading4Medium>
        {placeholderSubtitle && (
          <Heading4 className={colorStyles.still400}>
            {placeholderSubtitle}
          </Heading4>
        )}
      </div>
      <div
        className={styles.chartPlaceholderImage}
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
        }}
      >
        <div className={styles.placeholderOverlay}>
          <div className={styles.placeholderText}>
            <Heading5Medium>{t('appTime.placeholderTitle')}</Heading5Medium>
            <Body1 className={colorStyles.still400}>
              {t('appTime.placeholderSubtitle')}
            </Body1>
          </div>
          <Button
            label={t('common.enable')}
            onClick={handleAppTimeEnable}
            theme="white"
            ariaLabel="Enable application monitoring"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartPlaceholder;
