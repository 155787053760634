import React, { FunctionComponent, useState } from 'react';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import merge from 'lodash/merge';

import Modal from '../';
import { Nullable } from '../../../../Consts/types';

const config = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: { offset: [0, 16] },
    },
    {
      name: 'computeStyles',
      options: { adaptive: true },
    },
  ],
  placement: 'bottom-end',
};

type ActionSheetProps = {
  parent: Nullable<Element>;
  popperOptions?: PopperJS.Options;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
};

const ActionSheet: FunctionComponent<ActionSheetProps> = ({
  parent,
  popperOptions,
  isOpen,
  className,
  children,
  onClose,
}) => {
  const [popperWrapper, setPopperWrapper] = useState<HTMLElement>();
  const { styles: popperStyles, attributes } = usePopper(
    parent,
    popperWrapper,
    merge(config, popperOptions)
  );

  return (
    <Modal
      wrapperClassName={className}
      isOpen={isOpen}
      onClose={onClose}
      ref={setPopperWrapper as React.Ref<HTMLDivElement>}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      {children}
    </Modal>
  );
};

export default ActionSheet;
