import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Categories } from 'Consts/types';

import { SimpleSidePanel, SidePanelContentWrapper } from 'UI/Layout/Side panel';

import DataTile from 'UI/Components/Tiles/Data tile';

import Card from 'UI/Elements/Card';

import { sentenceCase } from 'Utils/formatters/string';
import { displayBytes } from 'Utils/mbMath';

import { DataUsageColors } from 'Consts/defintions';

type DataUsageSidePanelProps = {
  statistics: [Categories, number][];
};

const DataUsageSidePanel: FunctionComponent<DataUsageSidePanelProps> = ({
  statistics,
}) => {
  const { t } = useTranslation();
  const total = statistics.reduce((acc, [, number]) => acc + number, 0);

  return (
    <SimpleSidePanel title={t('common.dataUsage')}>
      <SidePanelContentWrapper>
        {statistics.map(([category, count], index) => {
          const color = DataUsageColors[index] || DataUsageColors[2];

          if (count === 0) return null;

          return (
            <Card key={index}>
              <DataTile
                L2TopProps={{
                  label1: t(`categories.${category}`) || sentenceCase(category),
                }}
                RightProps={{ smallLabel1: displayBytes(count) }}
                ProgressBarProps={{
                  color1: color,
                  color2: color,
                  percent: (count / total) * 100,
                }}
              />
            </Card>
          );
        })}
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export default DataUsageSidePanel;
