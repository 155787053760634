import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import styles from './style.module.css';
import colors from 'Styles/colors';

type DividerProps = {
  className?: string;
  color?: string;
};

const Divider: FunctionComponent<DividerProps> = ({
  className,
  color = colors.still200,
}) => {
  return (
    <hr
      className={cn(styles.divider, className)}
      style={{ borderTop: `1px solid ${color}` }}
    />
  );
};

export default Divider;
