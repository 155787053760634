import {
  HealthStatusFullLabels,
  WiFiInterfaceToGHzLabel,
} from 'Consts/defintions';
import { ZoneDevice } from 'Consts/types';
import DeviceTile from 'UI/Components/Tiles/Device tile';
import { Card } from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';
import MediumDeviceIcon from 'UI/Elements/Icon/device';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../style.module.css';

type DeviceInfoCardProps = {
  device?: ZoneDevice;
};

const DeviceInfoCard: FunctionComponent<DeviceInfoCardProps> = ({ device }) => {
  const deviceHealth = device?.health;
  const { t } = useTranslation();

  const pillSmallVariant = useMemo(() => {
    if (deviceHealth?.status === 'fair' || deviceHealth?.status === 'poor') {
      return 'poor';
    } else if (
      deviceHealth?.status === 'excellent' ||
      deviceHealth?.status === 'good'
    ) {
      return 'good';
    }
  }, [deviceHealth?.status]);

  const healthLabelForPill = useMemo(() => {
    return deviceHealth?.status
      ? t(HealthStatusFullLabels[deviceHealth?.status])
      : '';
  }, [deviceHealth?.status]);

  const gatewayIcon = useMemo(() => {
    if (!device) return '';
    if (device?.leafToRoot?.length === 1) {
      return IconNames.GatewayPod;
    } else return IconNames.NoGatewayPod;
  }, [device?.leafToRoot]);

  const dataConsumption = useMemo(() => {
    if (device?.medium === 'wifi') {
      return (
        (device?.freqBand
          ? WiFiInterfaceToGHzLabel[device?.freqBand] + ', '
          : '') + (device?.channel || '')
      );
    } else if (device?.medium === 'ethernet') {
      return t('devices.ethernet');
    }
  }, [device]);
  const deviceConnectionState = device?.connectionState;
  return (
    <>
      <Card className={styles.deviceCard}>
        {deviceConnectionState === 'disconnected' && (
          <MediumDeviceIcon
            fileName={device?.icon || 'unknown.svg'}
            className={styles.deviceIcon}
          />
        )}

        {deviceConnectionState === 'connected' && device?.medium === 'wifi' && (
          <DeviceTile
            MProps={{ iconName: device?.icon }}
            BProps={{
              iconName: gatewayIcon ? gatewayIcon : undefined,
              title: dataConsumption,
              pillSmall: healthLabelForPill,
              pillSmallVariant: pillSmallVariant,
              label: device?.leafToRoot?.[0]?.nickname,
            }}
          />
        )}

        {deviceConnectionState === 'connected' &&
          device?.medium === 'ethernet' && (
            <DeviceTile
              MProps={{ iconName: device?.icon }}
              BProps={{
                iconName: gatewayIcon ? gatewayIcon : undefined,
                title: dataConsumption,
                label: device?.leafToRoot?.[0]?.nickname,
              }}
            />
          )}
      </Card>
    </>
  );
};
export default DeviceInfoCard;
