export const appCategories: string[] = [
  'all',
  'education',
  'email',
  'entertainment',
  'file_sharing',
  'gaming',
  'health_fitness',
  'messaging',
  'network_security',
  'networking',
  'news',
  'productivity',
  'shopping',
  'social',
  'vpn',
];
