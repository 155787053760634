import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDeviceDailyDataUsage = (mac: string) =>
  useUpdatedData({
    selector: selectors.dataUsage.device.daily,
    action: actions.dataUsage.device.daily.fetchData(mac),
    dataDependencyKey: mac,
  });

export default useDeviceDailyDataUsage;
