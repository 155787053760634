import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { NetworkType, Nullable } from 'Consts/types';
import { useSelector } from 'react-redux';
import { useRealTimeNotificationSubscribe } from 'realtimeNotifications/hooks/useRealTimeNotificationSubscribe';
import * as selectors from 'State/selectors';
import { MenuItemProps } from 'UI/Components/Menu';
import { IconNames } from 'UI/Elements/Icon';
import PillWeb from 'UI/Elements/Pill web';
import Background from 'UI/Layout/Background';
import Page from 'UI/Layout/Page';
import { FullscreenPageHeader } from 'UI/Layout/Page header';
import Filters from 'UI/Reusable/Filters/Filters';
import useIsMobile from 'Utils/hooks/useIsMobile';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import TopologyGraph from './Components/TopologyGraph';
import styles from './style.module.css';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

const NETWORK_LIST: Record<NetworkFilter, NetworkProps> = {
  all: {
    key: 'all',
    i18key: 'common.all',
  },
  default: {
    key: 'default',
    i18key: 'common.secure',
  },
  employee: {
    key: 'employee',
    i18key: 'common.employee',
  },
  guest: {
    key: 'guest',
    i18key: 'common.guest',
  },
};

type NetworkProps = {
  key: NetworkFilter;
  i18key: string;
};

type NetworkFilter = NetworkType | 'all';

type FilterProps = {
  network?: NetworkFilter;
  setNetwork?: (value: NetworkFilter) => void;
};

const Network: FunctionComponent = () => {
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();
  const [network, setNetwork] = useState<NetworkFilter>('all');

  const pubnub = useRealTimeNotificationSubscribe();
  const trackEvent = useTrackEvent();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const isMobile = useIsMobile();
  const commonFilter = { filterKey: 'Filter', label: t('common.filter') };
  const parentRef = useRef<Nullable<Element>>(null);
  const pubNubConfig = useSelector(selectors.pubNub);

  const setReferenceClick = useCallback(
    (ev: React.MouseEvent, item: string) => {
      handleTriggerEvent(ev);

      parentRef.current = ev.currentTarget;
      setIsMenuOpen(true);
      setMenuOpenTrigger(ev.type as MenuOpenTriggerEventType);
    },
    []
  );

  const mobileMenuItems: MenuItemProps[] = Object.keys(NETWORK_LIST).map(
    (key) => {
      return {
        label: t(NETWORK_LIST[key as NetworkFilter].i18key),
        iconName: network === key ? IconNames.Check : undefined,
        onClick: () => {
          setNetwork && setNetwork(key as NetworkFilter);
        },
      };
    }
  );

  const MobileNetworkFilters = useMemo(() => {
    return (
      <Filters
        parent={parentRef.current}
        isMenuOpen={isMenuOpen}
        menuItems={mobileMenuItems}
        handleClick={setReferenceClick}
        handleOnClose={() => setIsMenuOpen(false)}
        filters={[commonFilter]}
        menuOpenTrigger={menuOpenTrigger}
      />
    );
  }, [parentRef.current, isMenuOpen]);

  const NetworkFilters = ({ network, setNetwork }: FilterProps) => {
    const { t } = useTranslation();

    return (
      <div
        className={styles.controls}
        role="navigation"
        aria-label={t('common.networkFilters')}
      >
        {Object.keys(NETWORK_LIST).map((item) => {
          const currentNetwork = NETWORK_LIST[item as NetworkFilter];
          const isActive = currentNetwork.key === network;

          const handleOnClick = (ev: React.MouseEvent) => {
            ev.stopPropagation();

            if (currentNetwork?.key && setNetwork)
              setNetwork(currentNetwork.key);
          };

          return (
            <PillWeb
              key={item}
              label={t(currentNetwork.i18key)}
              onClick={handleOnClick}
              className={cn({ [styles.active]: isActive })}
            />
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (pubNubConfig) pubnub();
  }, [pubNubConfig]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Network,
      },
    });
  }, []);

  return (
    <Page noHorizontalScroll noVerticalScroll>
      <Background>
        <span role="banner">
          <FullscreenPageHeader label={t('common.network')}>
            {!isMobile && (
              <NetworkFilters network={network} setNetwork={setNetwork} />
            )}
            {isMobile && MobileNetworkFilters}
          </FullscreenPageHeader>
        </span>
        <main role="main">
          <TopologyGraph network={network} />
        </main>
      </Background>
    </Page>
  );
};

export default Network;
