import {
  DataLast30Days,
  OnlineTimeAppLast12Months,
  TimeLast30Days,
} from 'Consts/types';

export const prepareDataForCSV = (data: any) => {
  let result: any[] = [];
  for (let i = 0; i < data.length; i++) {
    let flattenedObj: Record<string, string | string[]> = {};
    for (const [key, value] of Object.entries(data[i])) {
      if (Array.isArray(value)) {
        if (value[0] && typeof value[0] === 'object') {
          value.forEach((item) => {
            for (const [subKey, subValue] of Object.entries(item)) {
              flattenedObj[`${key}_${subKey}`] = subValue as string;
            }
          });
        } else {
          flattenedObj[key] = value.toString();
        }
      } else if (typeof value === 'object' && value !== null) {
        for (const [subKey, subValue] of Object.entries(value)) {
          flattenedObj[`${key}_${subKey}`] = subValue;
        }
      } else {
        flattenedObj[key] = value as any;
      }
    }
    result.push(flattenedObj);
  }

  return result;
};

export const prepareTimeSeriesDataForCSV = (
  data: TimeLast30Days | DataLast30Days | OnlineTimeAppLast12Months,
  dataType: 'Data usage' | 'Online activity' | 'App time'
) => {
  let result: any[] = [];
  let stats: any[];
  if (data === undefined) {
    stats = [];
  } else if (dataType === 'App time') {
    const appTimeData = data as OnlineTimeAppLast12Months;
    stats = appTimeData.monthlyStats;
  } else {
    const chartData = data as TimeLast30Days | DataLast30Days;
    stats = chartData.dailyStats;
  }

  for (let i = 0; i < stats.length; i++) {
    const flattenedObj: Record<string, string> = {};
    for (const [key, value] of Object.entries(stats[i])) {
      if (Array.isArray(value)) {
        let stringifiedValue = '';
        for (let j = 0; j < value.length; j++) {
          for (const [subKey, subValue] of Object.entries(value[j])) {
            stringifiedValue = stringifiedValue.concat(
              `${subKey}: ${subValue}, `
            );
          }
        }
        flattenedObj[key] = stringifiedValue;
      } else {
        flattenedObj[key] = value as string;
      }
    }
    result.push(flattenedObj);
  }

  return result;
};
