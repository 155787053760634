import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../style.module.css';
import { Body1, Body2, Heading3Medium } from 'UI/Elements/Typography';
import Icon, { IconNames } from 'UI/Elements/Icon';
import BenefitsTooltip from './BenefitsTooltip';
import i18n from 'i18next';

export type BenefitsType = {
  tailoredCoverage: string;
  affordableRates: string;
  easyOnlineAccess: string;
  instantCertificatesOfInsurance: string;
  exceptionalCustomerSupport: string;
};

export const benefitsLabels = {
  tailoredCoverage: i18n.t('insurance.tailoredCoverage'),
  affordableRates: i18n.t('insurance.affordableRates'),
  easyOnlineAccess: i18n.t('insurance.easyOnlineAccess'),
  instantCertificatesOfInsurance: i18n.t(
    'insurance.instantCertificatesOfInsurance'
  ),
  exceptionalCustomerSupport: i18n.t('insurance.exceptionalCustomerSupport'),
};

const benefitsCopy = {
  tailoredCoverage: i18n.t('insurance.tailoredCoverageCopy'),
  affordableRates: i18n.t('insurance.affordableRatesCopy'),
  easyOnlineAccess: i18n.t('insurance.easyOnlineAccessCopy'),
  instantCertificatesOfInsurance: i18n.t(
    'insurance.instantCertificatesOfInsuranceCopy'
  ),
  exceptionalCustomerSupport: i18n.t(
    'insurance.exceptionalCustomerSupportCopy'
  ),
};

const Benefits: FunctionComponent = () => {
  const { t } = useTranslation();
  const [currentTarget, setCurrentTarget] = useState<HTMLLIElement | null>(
    null
  );
  const listRef = useRef<(HTMLLIElement | null)[]>([]);
  const [showTooltip, setShowTooltip] = useState<boolean[]>([]);
  const [clickedBenefit, setClickedBenefit] = useState<string | null>(null);
  const [focusedElementBeforeTooltip, setFocusedElementBeforeTooltip] =
    useState<HTMLElement | null>(null);
  const learnMoreBtnRefs = useRef<Array<React.RefObject<HTMLButtonElement>>>(
    Array(Object.keys(benefitsLabels).length)
      .fill(null)
      .map(() => React.createRef<HTMLButtonElement>())
  );

  const toggleTooltip = (index: number) => {
    setShowTooltip((prev) => {
      const updatedState = [...prev];
      updatedState[index] = !updatedState[index];
      return updatedState;
    });
  };

  const handleCloseTooltip = (index: number) => {
    focusedElementBeforeTooltip?.focus();
    setShowTooltip((prev) => {
      const updatedState = [...prev];
      updatedState[index] = false;
      return updatedState;
    });
  };

  const handleLearnMoreClick = (benefit: string, index: number) => {
    toggleTooltip(index);
    setClickedBenefit(benefit);
    setCurrentTarget(listRef.current[index]);
    setFocusedElementBeforeTooltip(document.activeElement as HTMLElement);
  };

  return (
    <div className={styles.benefits}>
      <Heading3Medium>{t('insurance.benefits')}</Heading3Medium>
      <ul className={styles.benefitsList}>
        {Object.keys(benefitsLabels).map((benefit, index) => (
          <li
            className={styles.benefitsListItem}
            id={clickedBenefit === benefit ? 'tooltip-root' : undefined}
            ref={(el) => (listRef.current[index] = el)}
            key={benefit}
          >
            <Icon name={IconNames.CircleCheck} />

            <div className={styles.benefitsCopy}>
              <Body1>{benefitsLabels[benefit as keyof BenefitsType]}</Body1>
              <button
                className={styles.benefitsLearnMoreBtn}
                onClick={() => handleLearnMoreClick(benefit, index)}
                ref={learnMoreBtnRefs.current[index]}
                tabIndex={0}
                aria-label={`Learn more about ${
                  benefitsLabels[benefit as keyof BenefitsType]
                }`}
              >
                <Body2>{t('common.learnMore')}</Body2>
              </button>
            </div>
            <BenefitsTooltip
              targetRef={{ current: currentTarget }}
              showTooltip={showTooltip[index]}
              onCloseClick={() => handleCloseTooltip(index)}
              ariaLabel={benefitsCopy[clickedBenefit as keyof BenefitsType]}
              triggerLearnMoreBtnRef={learnMoreBtnRefs.current[index]}
            >
              <Body2>
                {benefitsCopy[clickedBenefit as keyof BenefitsType]}
              </Body2>
            </BenefitsTooltip>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Benefits;
