import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTheme } from 'UI/Elements/Button';
import { Body1Medium } from 'UI/Elements/Typography';

import styles from './style.module.css';

type ResetResendButtonProps = {
  label: string;
  theme?: ButtonTheme;
  emailSent: boolean;
  onClick: () => void;
};

const ResetResendButton: FunctionComponent<ResetResendButtonProps> = ({
  label,
  theme,
  emailSent,
  onClick,
}) => {
  const { t } = useTranslation();

  if (emailSent) {
    return (
      <Body1Medium className={styles.emailSent}>
        {t('onboarding.emailSent')}
      </Body1Medium>
    );
  }

  return <Button theme={theme} label={label} onClick={onClick} />;
};

export default ResetResendButton;
