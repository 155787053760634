import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import typography from 'Styles/typography.module.css';

import styles from './style.module.css';

export type ButtonProps = {
  label: string;
  disabled?: boolean;
  theme: 'approve' | 'block';
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

const Button: FunctionComponent<ButtonProps> = ({
  label,
  disabled = false,
  theme,
  onClick,
}) => {
  const classNames = cn(typography.text, typography.subtitle1, styles.main, {
    [styles.approve]: theme === 'approve',
    [styles.block]: theme === 'block',
  });

  return (
    <button className={classNames} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  );
};

export default Button;
