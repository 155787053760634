import capitalize from 'lodash/capitalize';
import * as selectors from 'State/selectors';
import * as actions from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { environments } from 'Consts/environments';
import { ApplicationInfo, CloudBackend, Maybe } from 'Consts/types';
import { AppDispatch } from 'State/store';

const useApplicationInfo = () => {
  const dispatch = useDispatch<AppDispatch>();

  const appInfo = useSelector(selectors.applicationInfo);
  const cloud = useSelector(selectors.auth.cloud);

  const getInfoURL = (appId: string, cloud: CloudBackend): string => {
    return environments[cloud]?.metasaurusUrl + '/apps/' + appId + '/info.json';
  };

  const findApplicationName = useCallback(async (appId: string) => {
    try {
      const existingLookup: Maybe<string> = appInfo.applications?.find(
        (app) => app.appId === appId
      )?.appName;

      let localeName: Maybe<string>;

      if (!existingLookup) {
        const response = await fetch(getInfoURL(appId, cloud));
        const parsedResponse: ApplicationInfo = await response.json();
        localeName = parsedResponse.name.en; // TODO pick the user lang
        dispatch(actions.applicationInfo.updateAppNames(appId, localeName));
      }

      return existingLookup || localeName || capitalize(appId);
    } catch {
      console.error('API calling error');
      dispatch(
        actions.applicationInfo.updateAppNames(appId, capitalize(appId))
      );
      return capitalize(appId);
    }
  }, []);

  return findApplicationName;
};

export default useApplicationInfo;
