import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Pill from 'UI/Elements/Pill large';
import { Body2Medium, Heading4Medium } from 'UI/Elements/Typography';
import Icon, { IconNames, IconTooltip } from 'UI/Elements/Icon';

import styles from './style.module.css';
import { NetworkStatusType } from 'Consts/types';

export type HeaderProps = {
  isp: string;
  networkStatus: NetworkStatusType;
  onCheckSpeed?: React.MouseEventHandler | null;
};

const Header = ({ isp, networkStatus, onCheckSpeed }: HeaderProps) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const handleOnMouseOver = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      setShowTooltip(true && !isMobile);
    },
    [isMobile]
  );

  const handleOnMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  // This is a hack to detect mobile devices
  const handleOnTouchStart = useCallback(() => {
    setIsMobile(true);
  }, []);

  return (
    <div
      className={cn(styles.header, { [styles.networkStatus]: networkStatus })}
    >
      <div className={styles.headerLeft}>
        <Heading4Medium>{isp}</Heading4Medium>

        {networkStatus === 'connected' && (
          <>
            <div
              ref={tooltipRef}
              className={styles.headerIcon}
              onMouseOver={handleOnMouseOver}
              onMouseLeave={handleOnMouseLeave}
              onTouchStart={handleOnTouchStart}
            >
              <div className={styles.iconCircle} />
              <div className={styles.iconWave} />
            </div>

            <IconTooltip
              showTooltip={showTooltip}
              parent={tooltipRef.current}
              tooltipLabel={t('wlan.online')}
            />
          </>
        )}
      </div>

      {networkStatus === 'disconnected' ? (
        <div className={styles.networkOffline}>
          <Icon name={IconNames.Info16} />
          <Body2Medium>{t('wlan.networkOffline')}</Body2Medium>
        </div>
      ) : (
        onCheckSpeed && (
          <Pill
            className={styles.headerRight}
            label={t('wlan.checkSpeed')}
            onClick={onCheckSpeed}
          />
        )
      )}
    </div>
  );
};

export default Header;
