import { ICONS } from './deviceIcons';

export const getDeviceIconFileName = (icon: string, icons = ICONS): string => {
  let _icon = icon;

  if (!icon) {
    return 'unknown.svg';
  }
  const found = icons.IotMedium[`${_icon}.svg`];

  if (found) {
    return found;
  }

  _icon = _icon.substring(0, _icon.lastIndexOf('-'));

  if (!_icon) {
    return 'unknown.svg';
  }

  return getDeviceIconFileName(_icon);
};
