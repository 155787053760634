import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon, { IconName } from 'UI/Elements/Icon';
import { Body2, Heading3Medium } from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors.module.css';

import { onEnterKeydown } from 'Utils/keyboardEvents';
import styles from './style.module.css';

export type FilterProps = {
  label: string;
  value: number;
  key: string;
  color: string;
  iconName: IconName;
};

type FilterCardProps = {
  label: string;
  count: number;
  isActive?: boolean;
  iconName: IconName;
  onClick: React.MouseEventHandler;
  color: string;
} & JSX.IntrinsicElements['div'];

const FilterCard: FunctionComponent<FilterCardProps> = ({
  label,
  count,
  iconName,
  color,
  isActive = false,
  className,
  onClick,
}) => {
  return (
    <div
      // TODO background color
      className={cn(styles.item, { [styles.itemActive]: isActive }, className)}
      onClick={onClick}
      role="tab"
      aria-label={label}
      tabIndex={0}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
      aria-selected={isActive}
    >
      <div>
        <Body2
          className={cn(
            isActive ? colorStyles.still900 : colorStyles.still400,
            styles.title
          )}
        >
          {label}
        </Body2>
        {count ? (
          <Heading3Medium style={{ color }}>{count}</Heading3Medium>
        ) : (
          <Heading3Medium className={colorStyles.still400}>
            {'--'}
          </Heading3Medium>
        )}
      </div>

      <div style={{ color }}>
        <Icon name={iconName} />
      </div>
    </div>
  );
};

export default FilterCard;
