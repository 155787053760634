import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useEmployeeMonthlyDataUsage = () =>
  useUpdatedData({
    selector: selectors.dataUsage.employee.monthly,
    action: actions.dataUsage.employee.monthly.fetchData(),
  });

export default useEmployeeMonthlyDataUsage;
