import React, { FunctionComponent } from 'react';
import useGuestMonthlyAppsOnlineTime from 'State/hooks/useGuestMonthlyAppsOnlineTime';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from 'Consts/routes';
import { useTranslation } from 'react-i18next';
import { AppTimeProps, AppTimeUI } from './index';

const AppTimeGuestZone: FunctionComponent<AppTimeProps> = ({
  className,
  maxItems,
}) => {
  const guestAppsTime = useGuestMonthlyAppsOnlineTime();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <AppTimeUI
      className={className}
      maxItems={maxItems}
      isLoading={guestAppsTime.isLoading}
      errorMessage={guestAppsTime.errorMessage}
      applications={guestAppsTime.data?.dailyStats[0]?.apps ?? []}
      title={t('common.appTime') as string}
      viewAllHandler={() =>
        navigate(
          generatePath(ROUTES.appTimeInsightsByNetworkId, { network: 'guest' })
        )
      }
    />
  );
};

export default AppTimeGuestZone;
