import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react';
import { useMixPanelRegistration } from './trackingAnalytics/hooks/useMixPanelRegistration';
import usePubNubRegistration from 'State/hooks/usePubNubRegistration';

import { Auth0Provider } from '@auth0/auth0-react';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import * as selectors from 'State/selectors';
import { useSelector } from 'react-redux';
import { environments } from 'Consts/environments';

const PLUME_TITLE = 'WorkPass Web - Plume';
const BELL_TITLE = 'Bell Business Wi-Fi';
const BELL = 'bell';

const AppContainer: FunctionComponent<PropsWithChildren> = ({ children }) => {
  useMixPanelRegistration();
  usePubNubRegistration();

  const cloud = useSelector(selectors.auth.cloud);

  const environment = getConfigurationFromDomain();

  const theme = useMemo(() => {
    return environment.id === BELL ? 'bell' : '';
  }, [environment]);

  const childrenAndTheme = <div color-theme={theme}>{children}</div>;

  useEffect(() => {
    const title =
      document.querySelector('title') || document.createElement('title');
    title.innerHTML = environment.id === BELL ? BELL_TITLE : PLUME_TITLE;

    const linkElement =
      (document.querySelector(
        'link[rel="shortcut icon"]'
      ) as HTMLAnchorElement) ||
      (document.createElement(
        'link[rel="shortcut icon"]'
      ) as HTMLAnchorElement);
    linkElement.type = environment.id === BELL ? 'image/png' : 'image/svg+xml';
    linkElement.href =
      environment.id === BELL ? '/bellFavicon.png' : '/favicon.svg';
  }, []);

  return environment.authenticationsMethodsPermitted.includes('globalAuth') &&
    Boolean(environments[cloud]?.auth0Domain) &&
    Boolean(environments[cloud]?.auth0ClientId) ? (
    <Auth0Provider
      domain={environments[cloud]?.auth0Domain || ''}
      clientId={environments[cloud]?.auth0ClientId || ''}
      useRefreshTokens
      cacheLocation="localstorage"
      authorizationParams={{
        scope: 'openid profile email offline_access',
        audience: 'http://plume.com/GlobalAuthApi',
        redirect_uri: window.location.origin + '/onboarding/global-auth',
      }}
    >
      {childrenAndTheme}
    </Auth0Provider>
  ) : (
    <>{childrenAndTheme}</>
  );
};

export default AppContainer;
