import { t } from 'i18next';

import { DeviceCategory, DeviceGroup, ZoneDevice, Guest } from 'Consts/types';

import { SORTING_TYPES, GROUPING_TYPES } from 'State/hooks/useDeviceFilters';

type DeviceFilter = {
  name: string;
  category: DeviceCategory | undefined;
} & Guest;

export type DeviceFilters = Array<DeviceFilter | ZoneDevice>;

const groupDevicesByDeviceCategory = (devices: DeviceFilters) => {
  const deviceCategories = devices.reduce((acc, device) => {
    const category = device.category ?? 'Other';

    if (!acc[category]) {
      acc[category] = [];
    }

    acc[category].push(device);

    return acc;
  }, {} as Record<DeviceCategory | 'Other', DeviceFilters>);
  return Object.entries(deviceCategories) as [string, DeviceFilters][];
};

const sortAlphabetically = (devices: DeviceFilters) => {
  return devices.sort((a, b) => a.name.localeCompare(b.name));
};

const sortByRecentlyConnected = (devices: DeviceFilters) => {
  return devices.sort((a, b) => {
    let aDate = a.connectionStateChangeAt
      ? new Date(a.connectionStateChangeAt)
      : new Date(0);
    let bDate = b.connectionStateChangeAt
      ? new Date(b.connectionStateChangeAt)
      : new Date(0);

    if (
      a.connectionState === 'connected' &&
      b.connectionState !== 'connected'
    ) {
      return -1;
    }
    if (
      a.connectionState !== 'connected' &&
      b.connectionState === 'connected'
    ) {
      return 1;
    }
    return bDate.getTime() - aDate.getTime();
  });
};

export const sortDevices = (
  devices: DeviceFilters,
  type: SORTING_TYPES,
  group?: DeviceGroup
) => {
  if (type === SORTING_TYPES.alphabetically) {
    return sortAlphabetically([...devices]);
  }

  if (type === SORTING_TYPES.recentlyConnected) {
    return sortByRecentlyConnected([...devices]);
  }

  if (type === SORTING_TYPES.sharedDevices) {
    if (group && group.groupShares.length) {
      // TODO need to be handled when we have info about groupShares
      return sortByRecentlyConnected([...devices]);
    }

    return sortByRecentlyConnected([...devices]);
  }

  return devices;
};

export const groupDevices = (devices: DeviceFilters, type: GROUPING_TYPES) => {
  if (type === GROUPING_TYPES.device) {
    return groupDevicesByDeviceCategory(devices);
  }

  return [[t('common.allDevices'), devices]] as [string, DeviceFilters][];
};
