import { Person } from 'Consts/types';

export const PERSONS: Person[] = [
  {
    id: '63847c5842b6f50052081b90',
    nickname: 'Test Guy',
    imageId: 'PROFILE_MAN',
    primaryDevice: '30:07:4d:0f:cf:6b',
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    createdAt: '2022-11-28T09:16:08.787Z',
    locationId: '6380910cdf937a00517509b8',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: 'connected',
    connectionStateChangeAt: '2022-12-20T11:50:57.193Z',
    atHome: false,
    atHomeChangedAt: '2022-12-13T13:55:22.763Z',
  },
  {
    id: '63847c5842b6f50052081b91',
    nickname: 'Second Test Guy',
    imageId: 'PROFILE_MAN',
    primaryDevice: '9a:d0:a9:bc:9d:b4',
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    createdAt: '2022-11-28T09:16:08.727Z',
    locationId: '6380910cdf937a00517509b8',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: 'connected',
    connectionStateChangeAt: '2022-12-20T11:50:57.193Z',
    atHome: false,
    atHomeChangedAt: '2022-12-13T13:55:22.763Z',
  },
  {
    id: '63847c63344742005134b648',
    nickname: 'Test Girl',
    imageId: 'PROFILE_WOMAN',
    primaryDevice: 'fe:85:bf:dc:4e:78',
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    createdAt: '2022-11-28T09:16:19.421Z',
    locationId: '6380910cdf937a00517509b8',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: 'connected',
    connectionStateChangeAt: '2022-11-29T21:54:46.469Z',
  },
  {
    id: '63847c63344742205134b647',
    nickname: 'Second Test Girl',
    imageId: 'PROFILE_WOMAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    createdAt: '2022-11-28T09:16:19.411Z',
    locationId: '6380910cdf937a00517509b8',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
];

export const MORE_PERSONS: Person[] = [
  {
    id: '620dfc2eecf8d369dd847d19',
    nickname: 'co-manager readonly',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'readonly',
      status: 'active',
      createdAt: '2022-02-17T07:41:34.315Z',
    },
    email: 'dvrecer+readonly3392@plume.com',
    createdAt: '2022-02-17T07:41:34.008Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '620e0dd5d3a0b86a7d440fdb',
    nickname: 'co-manager admin',
    imageId: 'PROFILE_WOMAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'admin',
      status: 'active',
      createdAt: '2022-02-17T08:56:53.708Z',
    },
    email: 'dvrecer+admin3392@plume.com',
    createdAt: '2022-02-17T08:56:53.411Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '62274bce5fab83003127d695',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'owner',
    },
    email: 'dvrecer+lte@plume.com',
    createdAt: '2022-03-08T12:27:58.546Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '62331b2b018f8b0031459382',
    nickname: 'test',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    createdAt: '2022-03-17T11:27:39.007Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '62386f6495a5dc003319d14e',
    nickname: 'test1234',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+profiletest1@plume.com',
    createdAt: '2022-03-21T12:28:20.990Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '6238705395a5dc003319d14f',
    nickname: 'test1234',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+profiletest1@plume.com',
    createdAt: '2022-03-21T12:32:19.119Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '623890b695a5dc003319d17d',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+testingprofiles@plume.com',
    createdAt: '2022-03-21T14:50:30.085Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '623890c695a5dc003319d17e',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+testingprofiles@plume.com',
    createdAt: '2022-03-21T14:50:46.869Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '6238910195a5dc003319d17f',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+testingprofiles2@plume.com',
    createdAt: '2022-03-21T14:51:45.728Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '6238911495a5dc003319d180',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+testingxrofiles2@plume.com',
    createdAt: '2022-03-21T14:52:04.645Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '6238912995a5dc003319d181',
    nickname: 'testingsadfasdf',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+testingxrofiles2@plume.com',
    createdAt: '2022-03-21T14:52:25.947Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '623892df95a5dc003319d185',
    nickname: 'testingsadfasdf',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer@plume.com',
    createdAt: '2022-03-21T14:59:43.320Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '6238b02a95a5dc003319d1b1',
    nickname: 'testingsadfasdf',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer@plume.com',
    createdAt: '2022-03-21T17:04:42.879Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '624ed5e385a0b70031a6aa20',
    nickname: 'david',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    createdAt: '2022-04-07T12:15:31.214Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '6259d5178f38d500324c2083',
    nickname: 'david manager',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'readonly',
      status: 'active',
      createdAt: '2022-04-15T20:27:06.358Z',
    },
    email: 'dvrecer+testingapprovalrequests@plume.com',
    createdAt: '2022-04-15T20:27:03.309Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '626a69abf48e32003153bc9b',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+lte@plume.com',
    createdAt: '2022-04-28T10:17:15.285Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '626a69b5f48e32003153bc9c',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'admin',
      status: 'pending',
      createdAt: '2022-04-28T10:17:26.514Z',
    },
    email: 'dvrecer+lte2@plume.com',
    createdAt: '2022-04-28T10:17:25.437Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '626a69c7f48e32003153bca0',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+lte@plume.com',
    createdAt: '2022-04-28T10:17:43.659Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
  {
    id: '626a69cef48e32003153bca1',
    nickname: 'testing',
    imageId: 'PROFILE_MAN',
    primaryDevice: null,
    homeAwayNotification: false,
    appTime: true,
    permission: {
      accessType: 'none',
    },
    email: 'dvrecer+lte@plume.com',
    createdAt: '2022-04-28T10:17:50.492Z',
    locationId: '610ba40ed577437faf2be78e',
    freeze: {
      frozen: false,
      suspended: {
        id: 'suspend',
        name: 'Suspend',
        enable: false,
      },
      timeTemplates: [
        {
          id: 'untilMidnight',
          name: 'Until End of Day',
          enable: false,
        },
        {
          id: 'schoolNights',
          name: 'School Nights',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [7, 1, 2, 3, 4],
            },
          ],
        },
        {
          id: 'bedTime',
          name: 'Bed Time',
          enable: false,
          schedules: [
            {
              times: [
                {
                  start: '20:00',
                  end: '06:00',
                },
              ],
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
            },
          ],
        },
        {
          id: 'forever',
          name: 'Indefinitely',
          enable: false,
        },
        {
          id: 'residentialGwManaged',
          name: 'Residential gateway Managed',
          enable: false,
        },
      ],
      autoExpire: {
        id: 'autoExpire',
        enable: false,
        expiresAt: null,
      },
      schedules: [],
      forever: {
        id: 'forever',
        name: 'Indefinitely',
        enable: false,
      },
    },
    connectionState: null,
    connectionStateChangeAt: null,
  },
];
