import Alert from 'UI/Components/Alert';
import AlertModal from 'UI/Elements/Modals/Alerts';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';
type SpeedTestCompleteAlertModalProps = {
  open: boolean;
  download: number | null;
  upload: number | null;
};
const SpeedTestCompleteAlertModal: FunctionComponent<
  SpeedTestCompleteAlertModalProps
> = ({ open, download, upload }) => {
  const { t } = useTranslation();
  const [speedTestCompleteAlertModalOpen, setSpeedTestCompleteAlertModalOpen] =
    useState<boolean>(open);

  useEffect(() => {
    setSpeedTestCompleteAlertModalOpen(open);
  }, [open]);

  const handleSpeedTestCompleteModalHide = useCallback(() => {
    setSpeedTestCompleteAlertModalOpen(false);
  }, []);

  return (
    <>
      <AlertModal
        isOpen={speedTestCompleteAlertModalOpen}
        onClose={handleSpeedTestCompleteModalHide}
      >
        <Alert
          topProps={{
            label: t('wlan.speedTestCompleteAlertHeader'),
            paragraph: (
              <>
                {t('wlan.downloadSpeed', {
                  download: download ? download + ' ' + t('common.mbps') : '--',
                })}
                <br />
                {t('wlan.uploadSpeed', {
                  upload: upload ? upload + ' ' + t('common.mbps') : '--',
                })}
              </>
            ),
            className: styles.modalHeader,
          }}
          bottomProps={{
            button1Props: {
              label: t('common.done'),
              onClick: handleSpeedTestCompleteModalHide,
            },
          }}
        />
      </AlertModal>
    </>
  );
};
export default SpeedTestCompleteAlertModal;
