import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Period, SpeedDimension } from 'Consts/types';
import { PERIODS, SPEED_DIMENSIONS } from 'Consts/defintions';
import { ROUTES } from 'Consts/routes';

import Page from 'UI/Layout/Page';
import Background from 'UI/Layout/Background';
import { FullscreenPageHeader } from 'UI/Layout/Page header';
import Columns from 'UI/Layout/Columns';

import { Card } from 'UI/Elements/Card';

import {
  WlanFilters,
  MobileWlanFilters,
} from '../Components/Wlan/Components/Header filter';
import DeviceList from '../Components/Wlan/Components/Devices';
import AutoCheck from '../Components/Wlan/Components/Autocheck';

import SpeedTest from './Components/Speed test';
import DataUsage from './Components/Data usage';

import useDefaultDailyDataUsage from 'State/hooks/useDefaultDailyDataUsage';
import useEmployeeDailyDataUsage from 'State/hooks/useEmployeeDailyDataUsage';
import useGuestDailyDataUsage from 'State/hooks/useGuestDailyDataUsage';

import useDefaultMonthlyDataUsage from 'State/hooks/useDefaultMonthlyDataUsage';
import useEmployeeMonthlyDataUsage from 'State/hooks/useEmployeeMonthlyDataUsage';
import useGuestMonthlyDataUsage from 'State/hooks/useGuestMonthlyDataUsage';

import useCheckSpeed from 'State/hooks/useCheckSpeed';
import useIspSpeedTest from 'State/hooks/useIspSpeedTest';

import useLayoutColumns from 'Utils/hooks/useLayout';
import useIsMobile from 'Utils/hooks/useIsMobile';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';

const WlanViewAllSection: FunctionComponent = () => {
  const { t } = useTranslation();
  const [columns, ref] = useLayoutColumns();
  const isMobile = useIsMobile();

  const speedTest = useIspSpeedTest();

  const defaultDailyDataUsage = useDefaultDailyDataUsage();
  const employeeDailyDataUsage = useEmployeeDailyDataUsage();
  const guestDailyDataUsage = useGuestDailyDataUsage();

  const defaultMonthlyDataUsage = useDefaultMonthlyDataUsage();
  const employeeMonthlyDataUsage = useEmployeeMonthlyDataUsage();
  const guestMonthlyDataUsage = useGuestMonthlyDataUsage();

  const { onCheckSpeed, speedTestState } = useCheckSpeed();
  const trackEvent = useTrackEvent();
  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const [period, setPeriod] = useState<Period>(PERIODS.day);

  const [speedDimension, setSpeedDimension] = useState<SpeedDimension>(
    SPEED_DIMENSIONS.download
  );

  const filteredDevices = speedTest.data?.[period];

  const isLoadingDataUsage =
    (defaultDailyDataUsage.isLoading && !defaultDailyDataUsage.data) ||
    (employeeDailyDataUsage.isLoading && !employeeDailyDataUsage.data) ||
    (guestDailyDataUsage.isLoading && !guestDailyDataUsage.data) ||
    (defaultMonthlyDataUsage.isLoading && !defaultMonthlyDataUsage.data) ||
    (employeeMonthlyDataUsage.isLoading && !employeeMonthlyDataUsage.data) ||
    (guestMonthlyDataUsage.isLoading && !guestMonthlyDataUsage.data);

  const errorMessageDataUsage =
    defaultDailyDataUsage.errorMessage ||
    employeeDailyDataUsage.errorMessage ||
    guestDailyDataUsage.errorMessage ||
    defaultMonthlyDataUsage.errorMessage ||
    employeeMonthlyDataUsage.errorMessage ||
    guestMonthlyDataUsage.errorMessage;

  const dailyDataUsage = useMemo(
    () => ({
      secure: [...(defaultDailyDataUsage.data?.hourlyStats ?? [])].reverse(),
      employee: [...(employeeDailyDataUsage.data?.hourlyStats ?? [])].reverse(),
      guest: [...(guestDailyDataUsage.data?.hourlyStats ?? [])].reverse(),
    }),
    [
      defaultDailyDataUsage.data?.hourlyStats,
      employeeDailyDataUsage.data?.hourlyStats,
      guestDailyDataUsage.data?.hourlyStats,
    ]
  );

  const monthlyDataUsage = useMemo(
    () => ({
      secure: [...(defaultMonthlyDataUsage.data?.dailyStats ?? [])].reverse(),
      employee: [
        ...(employeeMonthlyDataUsage.data?.dailyStats ?? []),
      ].reverse(),
      guest: [...(guestMonthlyDataUsage.data?.dailyStats ?? [])].reverse(),
    }),
    [
      defaultMonthlyDataUsage.data?.dailyStats,
      employeeMonthlyDataUsage.data?.dailyStats,
      guestMonthlyDataUsage.data?.dailyStats,
    ]
  );

  const hideSpeedTestUI = useMemo(() => {
    return customerSupportConfigurations?.customerFeatureEnablement
      .hideSpeedTestUiEnabled;
  }, [
    customerSupportConfigurations?.customerFeatureEnablement
      .hideSpeedTestUiEnabled,
  ]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.WlanViewAll,
      },
    });
  }, []);

  const speedTestComponent = (
    <SpeedTest
      speedTestState={speedTestState}
      speedTest={speedTest.data}
      isLoading={speedTest.isLoading && !speedTest.data}
      errorMessage={speedTest.errorMessage}
      period={period}
      onCheckSpeed={onCheckSpeed}
      speedDimension={speedDimension}
    />
  );

  const dataUsageComponent = (
    <DataUsage
      period={period}
      isLoading={isLoadingDataUsage}
      errorMessage={errorMessageDataUsage}
      speedDimension={speedDimension}
      dailyDataUsage={dailyDataUsage}
      monthlyDataUsage={monthlyDataUsage}
    />
  );

  const mostActiveDevicesComponent = (
    <Card>
      <DeviceList
        totalConsumption={filteredDevices?.devices.totalConsumption ?? null}
        devices={filteredDevices?.devices.mostActive ?? null}
        speedDimension={speedDimension}
        viewMoreView={true}
      />
    </Card>
  );

  const hideISPName =
    customerSupportConfigurations?.customerFeatureEnablement.hideIspNameEnabled;

  const renderSpeedTestFeature = () => {
    return (
      <>
        {speedTestComponent}
        <AutoCheck />
      </>
    );
  };

  return (
    <Page>
      <Background ref={ref}>
        <FullscreenPageHeader
          label={
            hideISPName
              ? t('wlan.genericISP')
              : speedTest.data?.dashboard.isp || t('wlan.unknownISP')
          }
          backRoute={ROUTES.home.index}
        >
          {isMobile ? (
            <MobileWlanFilters
              period={period}
              setPeriod={setPeriod}
              speedDimension={speedDimension}
              setSpeedDimension={setSpeedDimension}
            />
          ) : (
            <WlanFilters
              period={period}
              setPeriod={setPeriod}
              speedDimension={speedDimension}
              setSpeedDimension={setSpeedDimension}
            />
          )}
        </FullscreenPageHeader>
        <Columns columnCount={columns} topMargin={16}>
          {columns === 1 && (
            <>
              {!hideSpeedTestUI && renderSpeedTestFeature()}
              {dataUsageComponent}
              {mostActiveDevicesComponent}
            </>
          )}

          {columns === 2 && (
            <>
              {!hideSpeedTestUI && (
                <Columns columnCount={1}>{renderSpeedTestFeature()}</Columns>
              )}
              {!hideSpeedTestUI ? (
                <Columns columnCount={1}>
                  {dataUsageComponent}
                  {mostActiveDevicesComponent}
                </Columns>
              ) : (
                <>
                  <Columns columnCount={1}>{dataUsageComponent}</Columns>
                  <Columns columnCount={1}>
                    {mostActiveDevicesComponent}
                  </Columns>
                </>
              )}
            </>
          )}

          {columns === 3 && (
            <>
              {!hideSpeedTestUI && (
                <Columns columnCount={1}>{renderSpeedTestFeature()}</Columns>
              )}
              <Columns columnCount={1}>{dataUsageComponent}</Columns>
              <Columns columnCount={1}>{mostActiveDevicesComponent}</Columns>
            </>
          )}
        </Columns>
      </Background>
    </Page>
  );
};

export default WlanViewAllSection;
