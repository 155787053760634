interface CategoryType {
  [key: number]: string;
}

const shieldEventCategoryList: CategoryType = {
  0: 'undetermined',
  1: 'realEstate',
  2: 'computerandInternetSecurity',
  3: 'financialServices',
  4: 'nusinessandEconomy',
  5: 'computerandInternetInfo',
  6: 'auctions',
  7: 'shopping',
  8: 'cultandOccult',
  9: 'travel',
  10: 'abusedDrugs',
  11: 'adultandPornography',
  12: 'homeandGarden',
  13: 'military',
  14: 'socialNetworking',
  15: 'deadSites',
  16: 'individualStockAdviceandTools',
  17: 'trainingandTools',
  18: 'dating',
  19: 'sexEducation',
  20: 'religion',
  21: 'entertainmentandArts',
  22: 'personalsitesandBlogs',
  23: 'legal',
  24: 'localInformation',
  25: 'streamingMedia',
  26: 'jobSearch',
  27: 'gambling',
  28: 'translation',
  29: 'referenceandResearch',
  30: 'sharewareandFreeware',
  31: 'peertoPeer',
  32: 'marijuana',
  33: 'hacking',
  34: 'games',
  35: 'philosophyandPoliticalAdvocacy',
  36: 'weapons',
  37: 'paytoSurf',
  38: 'huntingandFishing',
  39: 'society',
  40: 'educationalInstitutions',
  41: 'onlineGreetingcards',
  42: 'sports',
  43: 'swimsuitsIntimateApparel',
  44: 'questionable',
  45: 'kids',
  46: 'hateandRacism',
  47: 'personalStorage',
  48: 'violence',
  49: 'keyloggersandMonitoring',
  50: 'searchEngines',
  51: 'internetPortals',
  52: 'webAdvertisements',
  53: 'cheating',
  54: 'gross',
  55: 'webbasedemail',
  56: 'malwareSites',
  57: 'phishingandOtherFrauds',
  58: 'proxyAvoidanceandAnonymizers',
  59: 'spywareandAdware',
  60: 'music',
  61: 'government',
  62: 'nudity',
  63: 'newsandMedia',
  64: 'illegal',
  65: 'contentDeliveryNetworks',
  66: 'internetCommunications',
  67: 'botNets',
  68: 'abortion',
  69: 'healthandMedicine',
  71: 'SPAMURLs',
  74: 'dynamicallyGeneratedContent',
  75: 'parkedDomains',
  76: 'alcoholandTobacco',
  77: 'privateIPAddresses',
  78: 'imageandVideoSearch',
  79: 'fashionandBeauty',
  80: 'recreationandHobbies',
  81: 'motorVehicles',
  82: 'webHosting',
  500: 'selfHarm',
  501: 'socialNetworking',
  502: 'chat',
  503: 'childAbuse',
  510: 'malwareorPhishing',
};

export const categoryToTranslationKey = (categoryId: number) => {
  if (!categoryId) return;
  const category =
    shieldEventCategoryList[categoryId] || shieldEventCategoryList[0];
  return `shield.eventCategories.${category}`;
};
