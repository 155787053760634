/* eslint-disable comma-dangle */
export const ICONS: {
  IotLarge: Record<string, string>;
  IotMedium: Record<string, string>;
  IotSmall: Record<string, string>;
} = {
  IotLarge: {
    'airconditioner-daikin-airconditioner-frozen.svg':
      'airconditioner-daikin-airconditioner-frozen.svg',
    'airconditioner-daikin-airconditioner.svg':
      'airconditioner-daikin-airconditioner.svg',
    'airconditioner-daikin-frozen.svg': 'airconditioner-daikin-frozen.svg',
    'airconditioner-daikin.svg': 'airconditioner-daikin.svg',
    'airconditioner-frozen.svg': 'airconditioner-frozen.svg',
    'airconditioner-fujitsu-ac-frozen.svg':
      'airconditioner-fujitsu-ac-frozen.svg',
    'airconditioner-fujitsu-ac.svg': 'airconditioner-fujitsu-ac.svg',
    'airconditioner-fujitsu-frozen.svg': 'airconditioner-fujitsu-frozen.svg',
    'airconditioner-fujitsu.svg': 'airconditioner-fujitsu.svg',
    'airconditioner-haier-frozen.svg': 'airconditioner-haier-frozen.svg',
    'airconditioner-haier.svg': 'airconditioner-haier.svg',
    'airconditioner-midea-airconditioner-frozen.svg':
      'airconditioner-midea-airconditioner-frozen.svg',
    'airconditioner-midea-airconditioner.svg':
      'airconditioner-midea-airconditioner.svg',
    'airconditioner-midea-frozen.svg': 'airconditioner-midea-frozen.svg',
    'airconditioner-midea.svg': 'airconditioner-midea.svg',
    'airconditioner-mitsubishi-ac-frozen.svg':
      'airconditioner-mitsubishi-ac-frozen.svg',
    'airconditioner-mitsubishi-ac.svg': 'airconditioner-mitsubishi-ac.svg',
    'airconditioner-mitsubishi-frozen.svg':
      'airconditioner-mitsubishi-frozen.svg',
    'airconditioner-mitsubishi.svg': 'airconditioner-mitsubishi.svg',
    'airconditioner-samsung-ac-frozen.svg':
      'airconditioner-samsung-ac-frozen.svg',
    'airconditioner-samsung-ac.svg': 'airconditioner-samsung-ac.svg',
    'airconditioner-samsung-frozen.svg': 'airconditioner-samsung-frozen.svg',
    'airconditioner-samsung.svg': 'airconditioner-samsung.svg',
    'airconditioner-sensibo-frozen.svg': 'airconditioner-sensibo-frozen.svg',
    'airconditioner-sensibo-skysmartac-frozen.svg':
      'airconditioner-sensibo-skysmartac-frozen.svg',
    'airconditioner-sensibo-skysmartac.svg':
      'airconditioner-sensibo-skysmartac.svg',
    'airconditioner-sensibo-smartairconditioner-frozen.svg':
      'airconditioner-sensibo-smartairconditioner-frozen.svg',
    'airconditioner-sensibo-smartairconditioner.svg':
      'airconditioner-sensibo-smartairconditioner.svg',
    'airconditioner-sensibo.svg': 'airconditioner-sensibo.svg',
    'airconditioner-tado-frozen.svg': 'airconditioner-tado-frozen.svg',
    'airconditioner-tado-smartac-frozen.svg':
      'airconditioner-tado-smartac-frozen.svg',
    'airconditioner-tado-smartac.svg': 'airconditioner-tado-smartac.svg',
    'airconditioner-tado-smartaccontrol-frozen.svg':
      'airconditioner-tado-smartaccontrol-frozen.svg',
    'airconditioner-tado-smartaccontrol.svg':
      'airconditioner-tado-smartaccontrol.svg',
    'airconditioner-tado.svg': 'airconditioner-tado.svg',
    'airconditioner-whirlpool-frozen.svg':
      'airconditioner-whirlpool-frozen.svg',
    'airconditioner-whirlpool.svg': 'airconditioner-whirlpool.svg',
    'airconditioner-xiaomi-frozen.svg': 'airconditioner-xiaomi-frozen.svg',
    'airconditioner-xiaomi.svg': 'airconditioner-xiaomi.svg',
    'airconditioner.svg': 'airconditioner.svg',
    'airfryer-frozen.svg': 'airfryer-frozen.svg',
    'airfryer.svg': 'airfryer.svg',
    'airpurifier-amway-atmosphereairpurifier-frozen.svg':
      'airpurifier-amway-atmosphereairpurifier-frozen.svg',
    'airpurifier-amway-atmosphereairpurifier.svg':
      'airpurifier-amway-atmosphereairpurifier.svg',
    'airpurifier-amway-frozen.svg': 'airpurifier-amway-frozen.svg',
    'airpurifier-amway.svg': 'airpurifier-amway.svg',
    'airpurifier-awair-frozen.svg': 'airpurifier-awair-frozen.svg',
    'airpurifier-awair-glow-2-frozen.svg':
      'airpurifier-awair-glow-2-frozen.svg',
    'airpurifier-awair-glow-2.svg': 'airpurifier-awair-glow-2.svg',
    'airpurifier-awair-glow-frozen.svg': 'airpurifier-awair-glow-frozen.svg',
    'airpurifier-awair-glow.svg': 'airpurifier-awair-glow.svg',
    'airpurifier-awair.svg': 'airpurifier-awair.svg',
    'airpurifier-blueair-airpurifier-frozen.svg':
      'airpurifier-blueair-airpurifier-frozen.svg',
    'airpurifier-blueair-airpurifier.svg':
      'airpurifier-blueair-airpurifier.svg',
    'airpurifier-blueair-frozen.svg': 'airpurifier-blueair-frozen.svg',
    'airpurifier-blueair.svg': 'airpurifier-blueair.svg',
    'airpurifier-brid-airpurifier-frozen.svg':
      'airpurifier-brid-airpurifier-frozen.svg',
    'airpurifier-brid-airpurifier.svg': 'airpurifier-brid-airpurifier.svg',
    'airpurifier-brid-frozen.svg': 'airpurifier-brid-frozen.svg',
    'airpurifier-brid.svg': 'airpurifier-brid.svg',
    'airpurifier-broadlink-a1-frozen.svg':
      'airpurifier-broadlink-a1-frozen.svg',
    'airpurifier-broadlink-a1.svg': 'airpurifier-broadlink-a1.svg',
    'airpurifier-broadlink-frozen.svg': 'airpurifier-broadlink-frozen.svg',
    'airpurifier-broadlink.svg': 'airpurifier-broadlink.svg',
    'airpurifier-coway-airmega-frozen.svg':
      'airpurifier-coway-airmega-frozen.svg',
    'airpurifier-coway-airmega.svg': 'airpurifier-coway-airmega.svg',
    'airpurifier-coway-frozen.svg': 'airpurifier-coway-frozen.svg',
    'airpurifier-coway.svg': 'airpurifier-coway.svg',
    'airpurifier-dyson-frozen.svg': 'airpurifier-dyson-frozen.svg',
    'airpurifier-dyson-purecool-frozen.svg':
      'airpurifier-dyson-purecool-frozen.svg',
    'airpurifier-dyson-purecool.svg': 'airpurifier-dyson-purecool.svg',
    'airpurifier-dyson-purecoolhepa-frozen.svg':
      'airpurifier-dyson-purecoolhepa-frozen.svg',
    'airpurifier-dyson-purecoolhepa.svg': 'airpurifier-dyson-purecoolhepa.svg',
    'airpurifier-dyson-purecoollink-frozen.svg':
      'airpurifier-dyson-purecoollink-frozen.svg',
    'airpurifier-dyson-purecoollink.svg': 'airpurifier-dyson-purecoollink.svg',
    'airpurifier-dyson-purehot+cool-frozen.svg':
      'airpurifier-dyson-purehot+cool-frozen.svg',
    'airpurifier-dyson-purehot+cool.svg': 'airpurifier-dyson-purehot+cool.svg',
    'airpurifier-dyson-purehot+coollink-frozen.svg':
      'airpurifier-dyson-purehot+coollink-frozen.svg',
    'airpurifier-dyson-purehot+coollink.svg':
      'airpurifier-dyson-purehot+coollink.svg',
    'airpurifier-dyson-purehumidify+cool-frozen.svg':
      'airpurifier-dyson-purehumidify+cool-frozen.svg',
    'airpurifier-dyson-purehumidify+cool.svg':
      'airpurifier-dyson-purehumidify+cool.svg',
    'airpurifier-dyson.svg': 'airpurifier-dyson.svg',
    'airpurifier-frozen.svg': 'airpurifier-frozen.svg',
    'airpurifier-levoit-airpurifier-frozen.svg':
      'airpurifier-levoit-airpurifier-frozen.svg',
    'airpurifier-levoit-airpurifier.svg': 'airpurifier-levoit-airpurifier.svg',
    'airpurifier-levoit-frozen.svg': 'airpurifier-levoit-frozen.svg',
    'airpurifier-levoit.svg': 'airpurifier-levoit.svg',
    'airpurifier-molekule-frozen.svg': 'airpurifier-molekule-frozen.svg',
    'airpurifier-molekule.svg': 'airpurifier-molekule.svg',
    'airpurifier-philips-airpurifier-frozen.svg':
      'airpurifier-philips-airpurifier-frozen.svg',
    'airpurifier-philips-airpurifier.svg':
      'airpurifier-philips-airpurifier.svg',
    'airpurifier-philips-frozen.svg': 'airpurifier-philips-frozen.svg',
    'airpurifier-philips.svg': 'airpurifier-philips.svg',
    'airpurifier-rabbitair-airpurifier-frozen.svg':
      'airpurifier-rabbitair-airpurifier-frozen.svg',
    'airpurifier-rabbitair-airpurifier.svg':
      'airpurifier-rabbitair-airpurifier.svg',
    'airpurifier-rabbitair-frozen.svg': 'airpurifier-rabbitair-frozen.svg',
    'airpurifier-rabbitair.svg': 'airpurifier-rabbitair.svg',
    'airpurifier-samsung-frozen.svg': 'airpurifier-samsung-frozen.svg',
    'airpurifier-samsung.svg': 'airpurifier-samsung.svg',
    'airpurifier-vocolinc-flowerbud-frozen.svg':
      'airpurifier-vocolinc-flowerbud-frozen.svg',
    'airpurifier-vocolinc-flowerbud.svg': 'airpurifier-vocolinc-flowerbud.svg',
    'airpurifier-vocolinc-frozen.svg': 'airpurifier-vocolinc-frozen.svg',
    'airpurifier-vocolinc-pureflow-frozen.svg':
      'airpurifier-vocolinc-pureflow-frozen.svg',
    'airpurifier-vocolinc-pureflow.svg': 'airpurifier-vocolinc-pureflow.svg',
    'airpurifier-vocolinc.svg': 'airpurifier-vocolinc.svg',
    'airpurifier-winix-frozen.svg': 'airpurifier-winix-frozen.svg',
    'airpurifier-winix.svg': 'airpurifier-winix.svg',
    'airpurifier-xiaomi-frozen.svg': 'airpurifier-xiaomi-frozen.svg',
    'airpurifier-xiaomi.svg': 'airpurifier-xiaomi.svg',
    'airpurifier.svg': 'airpurifier.svg',
    'applianceorwearable-asix-ethernetadapter-frozen.svg':
      'applianceorwearable-asix-ethernetadapter-frozen.svg',
    'applianceorwearable-asix-ethernetadapter.svg':
      'applianceorwearable-asix-ethernetadapter.svg',
    'atgames-legendsultimate-frozen.svg': 'atgames-legendsultimate-frozen.svg',
    'atgames-legendsultimate.svg': 'atgames-legendsultimate.svg',
    'babycamera-arlo-frozen.svg': 'babycamera-arlo-frozen.svg',
    'babycamera-arlo.svg': 'babycamera-arlo.svg',
    'babycamera-cubo-ai-frozen.svg': 'babycamera-cubo-ai-frozen.svg',
    'babycamera-cubo-ai.svg': 'babycamera-cubo-ai.svg',
    'babycamera-dophigo-camera-frozen.svg':
      'babycamera-dophigo-camera-frozen.svg',
    'babycamera-dophigo-camera.svg': 'babycamera-dophigo-camera.svg',
    'babycamera-lollipop-babymonitorsmartcamera-frozen.svg':
      'babycamera-lollipop-babymonitorsmartcamera-frozen.svg',
    'babycamera-lollipop-babymonitorsmartcamera.svg':
      'babycamera-lollipop-babymonitorsmartcamera.svg',
    'babycamera-miku-babymonitor-frozen.svg':
      'babycamera-miku-babymonitor-frozen.svg',
    'babycamera-miku-babymonitor.svg': 'babycamera-miku-babymonitor.svg',
    'babycamera-nanit-frozen.svg': 'babycamera-nanit-frozen.svg',
    'babycamera-nanit.svg': 'babycamera-nanit.svg',
    'babycamera-nooie-ipc100-frozen.svg': 'babycamera-nooie-ipc100-frozen.svg',
    'babycamera-nooie-ipc100.svg': 'babycamera-nooie-ipc100.svg',
    'babycamera-owlet-babymonitor-frozen.svg':
      'babycamera-owlet-babymonitor-frozen.svg',
    'babycamera-owlet-babymonitor.svg': 'babycamera-owlet-babymonitor.svg',
    'babycamera-philips-insight-frozen.svg':
      'babycamera-philips-insight-frozen.svg',
    'babycamera-philips-insight.svg': 'babycamera-philips-insight.svg',
    'babycamera-safety1st-babymonitor-frozen.svg':
      'babycamera-safety1st-babymonitor-frozen.svg',
    'babycamera-safety1st-babymonitor.svg':
      'babycamera-safety1st-babymonitor.svg',
    'babycamera-vava-babymonitor-frozen.svg':
      'babycamera-vava-babymonitor-frozen.svg',
    'babycamera-vava-babymonitor.svg': 'babycamera-vava-babymonitor.svg',
    'babycamera-victure-pc420-frozen.svg':
      'babycamera-victure-pc420-frozen.svg',
    'babycamera-victure-pc420.svg': 'babycamera-victure-pc420.svg',
    'babycamera-vtech-babymonitor-frozen.svg':
      'babycamera-vtech-babymonitor-frozen.svg',
    'babycamera-vtech-babymonitor.svg': 'babycamera-vtech-babymonitor.svg',
    'bitcoinminer-2-frozen.svg': 'bitcoinminer-2-frozen.svg',
    'bitcoinminer-2.svg': 'bitcoinminer-2.svg',
    'bitcoinminer-antminer-frozen.svg': 'bitcoinminer-antminer-frozen.svg',
    'bitcoinminer-antminer.svg': 'bitcoinminer-antminer.svg',
    'bitcoinminer-coinmine-one-frozen.svg':
      'bitcoinminer-coinmine-one-frozen.svg',
    'bitcoinminer-coinmine-one.svg': 'bitcoinminer-coinmine-one.svg',
    'bitcoinminer-frozen.svg': 'bitcoinminer-frozen.svg',
    'bitcoinminer-halongmining-dragonmint-frozen.svg':
      'bitcoinminer-halongmining-dragonmint-frozen.svg',
    'bitcoinminer-halongmining-dragonmint.svg':
      'bitcoinminer-halongmining-dragonmint.svg',
    'bitcoinminer-innosilicon-miner-frozen.svg':
      'bitcoinminer-innosilicon-miner-frozen.svg',
    'bitcoinminer-innosilicon-miner.svg': 'bitcoinminer-innosilicon-miner.svg',
    'bitcoinminer.svg': 'bitcoinminer.svg',
    'blender-frozen.svg': 'blender-frozen.svg',
    'blender.svg': 'blender.svg',
    'camera-2gig-hd100-frozen.svg': 'camera-2gig-hd100-frozen.svg',
    'camera-2gig-hd100.svg': 'camera-2gig-hd100.svg',
    'camera-360-camera-frozen.svg': 'camera-360-camera-frozen.svg',
    'camera-360-camera.svg': 'camera-360-camera.svg',
    'camera-360eyes-pro-frozen.svg': 'camera-360eyes-pro-frozen.svg',
    'camera-360eyes-pro.svg': 'camera-360eyes-pro.svg',
    'camera-amazon-cloudcam-frozen.svg': 'camera-amazon-cloudcam-frozen.svg',
    'camera-amazon-cloudcam.svg': 'camera-amazon-cloudcam.svg',
    'camera-amazon-echo-look-frozen.svg': 'camera-amazon-echo-look-frozen.svg',
    'camera-amazon-echo-look.svg': 'camera-amazon-echo-look.svg',
    'camera-amazonring-floodlightcam-frozen.svg':
      'camera-amazonring-floodlightcam-frozen.svg',
    'camera-amazonring-floodlightcam.svg':
      'camera-amazonring-floodlightcam.svg',
    'camera-amazonring-frozen.svg': 'camera-amazonring-frozen.svg',
    'camera-amazonring-indoorcam-frozen.svg':
      'camera-amazonring-indoorcam-frozen.svg',
    'camera-amazonring-indoorcam.svg': 'camera-amazonring-indoorcam.svg',
    'camera-amazonring-spotlightcam-frozen.svg':
      'camera-amazonring-spotlightcam-frozen.svg',
    'camera-amazonring-spotlightcam.svg': 'camera-amazonring-spotlightcam.svg',
    'camera-amazonring-stickupcam-frozen.svg':
      'camera-amazonring-stickupcam-frozen.svg',
    'camera-amazonring-stickupcam.svg': 'camera-amazonring-stickupcam.svg',
    'camera-amazonring.svg': 'camera-amazonring.svg',
    'camera-ambarella-securitycamera-frozen.svg':
      'camera-ambarella-securitycamera-frozen.svg',
    'camera-ambarella-securitycamera.svg':
      'camera-ambarella-securitycamera.svg',
    'camera-amcrest-frozen.svg': 'camera-amcrest-frozen.svg',
    'camera-amcrest.svg': 'camera-amcrest.svg',
    'camera-arlo-essentialspotlightcamera-frozen.svg':
      'camera-arlo-essentialspotlightcamera-frozen.svg',
    'camera-arlo-essentialspotlightcamera.svg':
      'camera-arlo-essentialspotlightcamera.svg',
    'camera-arlo-floodlightcamera-frozen.svg':
      'camera-arlo-floodlightcamera-frozen.svg',
    'camera-arlo-floodlightcamera.svg': 'camera-arlo-floodlightcamera.svg',
    'camera-arlo-frozen.svg': 'camera-arlo-frozen.svg',
    'camera-arlo.svg': 'camera-arlo.svg',
    'camera-axis-networkcamera-frozen.svg':
      'camera-axis-networkcamera-frozen.svg',
    'camera-axis-networkcamera.svg': 'camera-axis-networkcamera.svg',
    'camera-belkin-netcam-frozen.svg': 'camera-belkin-netcam-frozen.svg',
    'camera-belkin-netcam.svg': 'camera-belkin-netcam.svg',
    'camera-binatone-homemonitor-frozen.svg':
      'camera-binatone-homemonitor-frozen.svg',
    'camera-binatone-homemonitor.svg': 'camera-binatone-homemonitor.svg',
    'camera-blink-frozen.svg': 'camera-blink-frozen.svg',
    'camera-blink.svg': 'camera-blink.svg',
    'camera-bosch-autodome-frozen.svg': 'camera-bosch-autodome-frozen.svg',
    'camera-bosch-autodome.svg': 'camera-bosch-autodome.svg',
    'camera-bosch-flexidome-frozen.svg': 'camera-bosch-flexidome-frozen.svg',
    'camera-bosch-flexidome.svg': 'camera-bosch-flexidome.svg',
    'camera-canary-frozen.svg': 'camera-canary-frozen.svg',
    'camera-canary.svg': 'camera-canary.svg',
    'camera-canon-eos-frozen.svg': 'camera-canon-eos-frozen.svg',
    'camera-canon-eos.svg': 'camera-canon-eos.svg',
    'camera-canon-powershot-frozen.svg': 'camera-canon-powershot-frozen.svg',
    'camera-canon-powershot.svg': 'camera-canon-powershot.svg',
    'camera-cocoon-frozen.svg': 'camera-cocoon-frozen.svg',
    'camera-cocoon.svg': 'camera-cocoon.svg',
    'camera-comcast-xcam2-frozen.svg': 'camera-comcast-xcam2-frozen.svg',
    'camera-comcast-xcam2.svg': 'camera-comcast-xcam2.svg',
    'camera-conico-securitycamera-frozen.svg':
      'camera-conico-securitycamera-frozen.svg',
    'camera-conico-securitycamera.svg': 'camera-conico-securitycamera.svg',
    'camera-dlink-frozen.svg': 'camera-dlink-frozen.svg',
    'camera-dlink-miniwificamera-frozen.svg':
      'camera-dlink-miniwificamera-frozen.svg',
    'camera-dlink-miniwificamera.svg': 'camera-dlink-miniwificamera.svg',
    'camera-dlink-pantiltwificamera-frozen.svg':
      'camera-dlink-pantiltwificamera-frozen.svg',
    'camera-dlink-pantiltwificamera.svg': 'camera-dlink-pantiltwificamera.svg',
    'camera-dlink-vigilance-frozen.svg': 'camera-dlink-vigilance-frozen.svg',
    'camera-dlink-vigilance.svg': 'camera-dlink-vigilance.svg',
    'camera-dlink-wificamera-frozen.svg': 'camera-dlink-wificamera-frozen.svg',
    'camera-dlink-wificamera.svg': 'camera-dlink-wificamera.svg',
    'camera-dlink.svg': 'camera-dlink.svg',
    'camera-etiger-ipcamera-frozen.svg': 'camera-etiger-ipcamera-frozen.svg',
    'camera-etiger-ipcamera.svg': 'camera-etiger-ipcamera.svg',
    'camera-eve-cam-frozen.svg': 'camera-eve-cam-frozen.svg',
    'camera-eve-cam.svg': 'camera-eve-cam.svg',
    'camera-ezviz-c2c-frozen.svg': 'camera-ezviz-c2c-frozen.svg',
    'camera-ezviz-c2c.svg': 'camera-ezviz-c2c.svg',
    'camera-ezviz-c6tc-frozen.svg': 'camera-ezviz-c6tc-frozen.svg',
    'camera-ezviz-c6tc.svg': 'camera-ezviz-c6tc.svg',
    'camera-ezviz-camera-frozen.svg': 'camera-ezviz-camera-frozen.svg',
    'camera-ezviz-camera.svg': 'camera-ezviz-camera.svg',
    'camera-ezviz-frozen.svg': 'camera-ezviz-frozen.svg',
    'camera-ezviz-husky-frozen.svg': 'camera-ezviz-husky-frozen.svg',
    'camera-ezviz-husky.svg': 'camera-ezviz-husky.svg',
    'camera-ezviz.svg': 'camera-ezviz.svg',
    'camera-faleemi-frozen.svg': 'camera-faleemi-frozen.svg',
    'camera-faleemi.svg': 'camera-faleemi.svg',
    'camera-flir-networkvideorecorder-frozen.svg':
      'camera-flir-networkvideorecorder-frozen.svg',
    'camera-flir-networkvideorecorder.svg':
      'camera-flir-networkvideorecorder.svg',
    'camera-frozen.svg': 'camera-frozen.svg',
    'camera-furbo-dogcamera-frozen.svg': 'camera-furbo-dogcamera-frozen.svg',
    'camera-furbo-dogcamera.svg': 'camera-furbo-dogcamera.svg',
    'camera-geeni-smartwificamera-frozen.svg':
      'camera-geeni-smartwificamera-frozen.svg',
    'camera-geeni-smartwificamera.svg': 'camera-geeni-smartwificamera.svg',
    'camera-googlenest-frozen.svg': 'camera-googlenest-frozen.svg',
    'camera-googlenest.svg': 'camera-googlenest.svg',
    'camera-gopro-camera-frozen.svg': 'camera-gopro-camera-frozen.svg',
    'camera-gopro-camera.svg': 'camera-gopro-camera.svg',
    'camera-gopro-frozen.svg': 'camera-gopro-frozen.svg',
    'camera-gopro.svg': 'camera-gopro.svg',
    'camera-gwsecurity-outdoorcamera-frozen.svg':
      'camera-gwsecurity-outdoorcamera-frozen.svg',
    'camera-gwsecurity-outdoorcamera.svg':
      'camera-gwsecurity-outdoorcamera.svg',
    'camera-hikvision-networkcamera-frozen.svg':
      'camera-hikvision-networkcamera-frozen.svg',
    'camera-hikvision-networkcamera.svg': 'camera-hikvision-networkcamera.svg',
    'camera-hive-view-frozen.svg': 'camera-hive-view-frozen.svg',
    'camera-hive-view.svg': 'camera-hive-view.svg',
    'camera-honeywell-hqa-frozen.svg': 'camera-honeywell-hqa-frozen.svg',
    'camera-honeywell-hqa.svg': 'camera-honeywell-hqa.svg',
    'camera-honeywell-lyricsecuritycamera-frozen.svg':
      'camera-honeywell-lyricsecuritycamera-frozen.svg',
    'camera-honeywell-lyricsecuritycamera.svg':
      'camera-honeywell-lyricsecuritycamera.svg',
    'camera-honeywelllyric-securitycamera-frozen.svg':
      'camera-honeywelllyric-securitycamera-frozen.svg',
    'camera-honeywelllyric-securitycamera.svg':
      'camera-honeywelllyric-securitycamera.svg',
    'camera-hugoai-securitycamera-frozen.svg':
      'camera-hugoai-securitycamera-frozen.svg',
    'camera-hugoai-securitycamera.svg': 'camera-hugoai-securitycamera.svg',
    'camera-iodata-tsns110w-frozen.svg': 'camera-iodata-tsns110w-frozen.svg',
    'camera-iodata-tsns110w.svg': 'camera-iodata-tsns110w.svg',
    'camera-ismart-icamerakeep-frozen.svg':
      'camera-ismart-icamerakeep-frozen.svg',
    'camera-ismart-icamerakeep.svg': 'camera-ismart-icamerakeep.svg',
    'camera-johndeere-camera-frozen.svg': 'camera-johndeere-camera-frozen.svg',
    'camera-johndeere-camera.svg': 'camera-johndeere-camera.svg',
    'camera-jsw-ipcamera-frozen.svg': 'camera-jsw-ipcamera-frozen.svg',
    'camera-jsw-ipcamera.svg': 'camera-jsw-ipcamera.svg',
    'camera-kodak-pixpro-frozen.svg': 'camera-kodak-pixpro-frozen.svg',
    'camera-kodak-pixpro.svg': 'camera-kodak-pixpro.svg',
    'camera-kodak-videomonitor-frozen.svg':
      'camera-kodak-videomonitor-frozen.svg',
    'camera-kodak-videomonitor.svg': 'camera-kodak-videomonitor.svg',
    'camera-kuna-frozen.svg': 'camera-kuna-frozen.svg',
    'camera-kuna.svg': 'camera-kuna.svg',
    'camera-leica-q-frozen.svg': 'camera-leica-q-frozen.svg',
    'camera-leica-q.svg': 'camera-leica-q.svg',
    'camera-litmor-frozen.svg': 'camera-litmor-frozen.svg',
    'camera-litmor.svg': 'camera-litmor.svg',
    'camera-logitech-circle-frozen.svg': 'camera-logitech-circle-frozen.svg',
    'camera-logitech-circle.svg': 'camera-logitech-circle.svg',
    'camera-lorex-ip-frozen.svg': 'camera-lorex-ip-frozen.svg',
    'camera-lorex-ip.svg': 'camera-lorex-ip.svg',
    'camera-lorex-ipcamera-frozen.svg': 'camera-lorex-ipcamera-frozen.svg',
    'camera-lorex-ipcamera.svg': 'camera-lorex-ipcamera.svg',
    'camera-lorex-wificamera-frozen.svg': 'camera-lorex-wificamera-frozen.svg',
    'camera-lorex-wificamera.svg': 'camera-lorex-wificamera.svg',
    'camera-marshall-cv346-frozen.svg': 'camera-marshall-cv346-frozen.svg',
    'camera-marshall-cv346.svg': 'camera-marshall-cv346.svg',
    'camera-mevo-camera-frozen.svg': 'camera-mevo-camera-frozen.svg',
    'camera-mevo-camera.svg': 'camera-mevo-camera.svg',
    'camera-microseven-camera-frozen.svg':
      'camera-microseven-camera-frozen.svg',
    'camera-microseven-camera.svg': 'camera-microseven-camera.svg',
    'camera-momentum-axelsmartcamera-frozen.svg':
      'camera-momentum-axelsmartcamera-frozen.svg',
    'camera-momentum-axelsmartcamera.svg':
      'camera-momentum-axelsmartcamera.svg',
    'camera-momentum-cori-frozen.svg': 'camera-momentum-cori-frozen.svg',
    'camera-momentum-cori.svg': 'camera-momentum-cori.svg',
    'camera-momentum-mocam-frozen.svg': 'camera-momentum-mocam-frozen.svg',
    'camera-momentum-mocam.svg': 'camera-momentum-mocam.svg',
    'camera-momentum-robbi-frozen.svg': 'camera-momentum-robbi-frozen.svg',
    'camera-momentum-robbi.svg': 'camera-momentum-robbi.svg',
    'camera-netatmo-frozen.svg': 'camera-netatmo-frozen.svg',
    'camera-netatmo-presence-frozen.svg': 'camera-netatmo-presence-frozen.svg',
    'camera-netatmo-presence.svg': 'camera-netatmo-presence.svg',
    'camera-netatmo.svg': 'camera-netatmo.svg',
    'camera-nightowl-securitycamera-frozen.svg':
      'camera-nightowl-securitycamera-frozen.svg',
    'camera-nightowl-securitycamera.svg': 'camera-nightowl-securitycamera.svg',
    'camera-nikon-z6-frozen.svg': 'camera-nikon-z6-frozen.svg',
    'camera-nikon-z6.svg': 'camera-nikon-z6.svg',
    'camera-nucam-yieyespycamera-frozen.svg':
      'camera-nucam-yieyespycamera-frozen.svg',
    'camera-nucam-yieyespycamera.svg': 'camera-nucam-yieyespycamera.svg',
    'camera-olympus-stylus-frozen.svg': 'camera-olympus-stylus-frozen.svg',
    'camera-olympus-stylus.svg': 'camera-olympus-stylus.svg',
    'camera-owlet-frozen.svg': 'camera-owlet-frozen.svg',
    'camera-owlet.svg': 'camera-owlet.svg',
    'camera-panasonic-homehawk-frozen.svg':
      'camera-panasonic-homehawk-frozen.svg',
    'camera-panasonic-homehawk.svg': 'camera-panasonic-homehawk.svg',
    'camera-panasonic-lumix-frozen.svg': 'camera-panasonic-lumix-frozen.svg',
    'camera-panasonic-lumix.svg': 'camera-panasonic-lumix.svg',
    'camera-petchatz-hd-frozen.svg': 'camera-petchatz-hd-frozen.svg',
    'camera-petchatz-hd.svg': 'camera-petchatz-hd.svg',
    'camera-petcube-bites-frozen.svg': 'camera-petcube-bites-frozen.svg',
    'camera-petcube-bites.svg': 'camera-petcube-bites.svg',
    'camera-petcube-frozen.svg': 'camera-petcube-frozen.svg',
    'camera-petcube.svg': 'camera-petcube.svg',
    'camera-petzi-treatcam-frozen.svg': 'camera-petzi-treatcam-frozen.svg',
    'camera-petzi-treatcam.svg': 'camera-petzi-treatcam.svg',
    'camera-piper-securitycamera-frozen.svg':
      'camera-piper-securitycamera-frozen.svg',
    'camera-piper-securitycamera.svg': 'camera-piper-securitycamera.svg',
    'camera-ptzoptics-camera-frozen.svg': 'camera-ptzoptics-camera-frozen.svg',
    'camera-ptzoptics-camera.svg': 'camera-ptzoptics-camera.svg',
    'camera-reolink-c1pro-frozen.svg': 'camera-reolink-c1pro-frozen.svg',
    'camera-reolink-c1pro.svg': 'camera-reolink-c1pro.svg',
    'camera-reolink-c2-frozen.svg': 'camera-reolink-c2-frozen.svg',
    'camera-reolink-c2.svg': 'camera-reolink-c2.svg',
    'camera-reolink-camera-frozen.svg': 'camera-reolink-camera-frozen.svg',
    'camera-reolink-camera.svg': 'camera-reolink-camera.svg',
    'camera-reolink-e1-frozen.svg': 'camera-reolink-e1-frozen.svg',
    'camera-reolink-e1.svg': 'camera-reolink-e1.svg',
    'camera-reolink-rlc410w-frozen.svg': 'camera-reolink-rlc410w-frozen.svg',
    'camera-reolink-rlc410w.svg': 'camera-reolink-rlc410w.svg',
    'camera-reolink-rlc511w-frozen.svg': 'camera-reolink-rlc511w-frozen.svg',
    'camera-reolink-rlc511w.svg': 'camera-reolink-rlc511w.svg',
    'camera-rflink-frozen.svg': 'camera-rflink-frozen.svg',
    'camera-rflink.svg': 'camera-rflink.svg',
    'camera-samsung-smartcam-frozen.svg': 'camera-samsung-smartcam-frozen.svg',
    'camera-samsung-smartcam.svg': 'camera-samsung-smartcam.svg',
    'camera-samsung-smartthingscam-frozen.svg':
      'camera-samsung-smartthingscam-frozen.svg',
    'camera-samsung-smartthingscam.svg': 'camera-samsung-smartthingscam.svg',
    'camera-sercomm-ipcamera-frozen.svg': 'camera-sercomm-ipcamera-frozen.svg',
    'camera-sercomm-ipcamera.svg': 'camera-sercomm-ipcamera.svg',
    'camera-shenzhen-ipcamera-frozen.svg':
      'camera-shenzhen-ipcamera-frozen.svg',
    'camera-shenzhen-ipcamera.svg': 'camera-shenzhen-ipcamera.svg',
    'camera-simplisafe-securitycamera-frozen.svg':
      'camera-simplisafe-securitycamera-frozen.svg',
    'camera-simplisafe-securitycamera.svg':
      'camera-simplisafe-securitycamera.svg',
    'camera-smarteye-frozen.svg': 'camera-smarteye-frozen.svg',
    'camera-smarteye.svg': 'camera-smarteye.svg',
    'camera-somfy-indoorcamera-frozen.svg':
      'camera-somfy-indoorcamera-frozen.svg',
    'camera-somfy-indoorcamera.svg': 'camera-somfy-indoorcamera.svg',
    'camera-somfy-one-frozen.svg': 'camera-somfy-one-frozen.svg',
    'camera-somfy-one.svg': 'camera-somfy-one.svg',
    'camera-somfy-outdoorcamera-frozen.svg':
      'camera-somfy-outdoorcamera-frozen.svg',
    'camera-somfy-outdoorcamera.svg': 'camera-somfy-outdoorcamera.svg',
    'camera-sony-frozen.svg': 'camera-sony-frozen.svg',
    'camera-sony.svg': 'camera-sony.svg',
    'camera-sricam-ipcamera-frozen.svg': 'camera-sricam-ipcamera-frozen.svg',
    'camera-sricam-ipcamera.svg': 'camera-sricam-ipcamera.svg',
    'camera-swann-pantilt-frozen.svg': 'camera-swann-pantilt-frozen.svg',
    'camera-swann-pantilt.svg': 'camera-swann-pantilt.svg',
    'camera-swann-securitycamera-frozen.svg':
      'camera-swann-securitycamera-frozen.svg',
    'camera-swann-securitycamera.svg': 'camera-swann-securitycamera.svg',
    'camera-teckin-tc100-frozen.svg': 'camera-teckin-tc100-frozen.svg',
    'camera-teckin-tc100.svg': 'camera-teckin-tc100.svg',
    'camera-tend-lynx-frozen.svg': 'camera-tend-lynx-frozen.svg',
    'camera-tend-lynx.svg': 'camera-tend-lynx.svg',
    'camera-tendinsights-stuartcam-frozen.svg':
      'camera-tendinsights-stuartcam-frozen.svg',
    'camera-tendinsights-stuartcam.svg': 'camera-tendinsights-stuartcam.svg',
    'camera-toucan-outdoorcamera-frozen.svg':
      'camera-toucan-outdoorcamera-frozen.svg',
    'camera-toucan-outdoorcamera.svg': 'camera-toucan-outdoorcamera.svg',
    'camera-tplink-cloudcamera-frozen.svg':
      'camera-tplink-cloudcamera-frozen.svg',
    'camera-tplink-cloudcamera.svg': 'camera-tplink-cloudcamera.svg',
    'camera-tplink-hdpantiltcamera-frozen.svg':
      'camera-tplink-hdpantiltcamera-frozen.svg',
    'camera-tplink-hdpantiltcamera.svg': 'camera-tplink-hdpantiltcamera.svg',
    'camera-tplink-kasacam-frozen.svg': 'camera-tplink-kasacam-frozen.svg',
    'camera-tplink-kasacam.svg': 'camera-tplink-kasacam.svg',
    'camera-tplink-kasaoutdoorcam-frozen.svg':
      'camera-tplink-kasaoutdoorcam-frozen.svg',
    'camera-tplink-kasaoutdoorcam.svg': 'camera-tplink-kasaoutdoorcam.svg',
    'camera-tplink-tapo-frozen.svg': 'camera-tplink-tapo-frozen.svg',
    'camera-tplink-tapo.svg': 'camera-tplink-tapo.svg',
    'camera-ubiquiti-unifi-frozen.svg': 'camera-ubiquiti-unifi-frozen.svg',
    'camera-ubiquiti-unifi.svg': 'camera-ubiquiti-unifi.svg',
    'camera-ulifecam-ipcamera-frozen.svg':
      'camera-ulifecam-ipcamera-frozen.svg',
    'camera-ulifecam-ipcamera.svg': 'camera-ulifecam-ipcamera.svg',
    'camera-veo-camera-frozen.svg': 'camera-veo-camera-frozen.svg',
    'camera-veo-camera.svg': 'camera-veo-camera.svg',
    'camera-victure-ipcamera-frozen.svg': 'camera-victure-ipcamera-frozen.svg',
    'camera-victure-ipcamera.svg': 'camera-victure-ipcamera.svg',
    'camera-vivint-outdoorcamera-frozen.svg':
      'camera-vivint-outdoorcamera-frozen.svg',
    'camera-vivint-outdoorcamera.svg': 'camera-vivint-outdoorcamera.svg',
    'camera-vivotek-frozen.svg': 'camera-vivotek-frozen.svg',
    'camera-vivotek.svg': 'camera-vivotek.svg',
    'camera-wansview-frozen.svg': 'camera-wansview-frozen.svg',
    'camera-wansview.svg': 'camera-wansview.svg',
    'camera-withings-home-frozen.svg': 'camera-withings-home-frozen.svg',
    'camera-withings-home.svg': 'camera-withings-home.svg',
    'camera-wyze-cam-frozen.svg': 'camera-wyze-cam-frozen.svg',
    'camera-wyze-cam.svg': 'camera-wyze-cam.svg',
    'camera-xfinity-homeindooroutdoorcamera-frozen.svg':
      'camera-xfinity-homeindooroutdoorcamera-frozen.svg',
    'camera-xfinity-homeindooroutdoorcamera.svg':
      'camera-xfinity-homeindooroutdoorcamera.svg',
    'camera-xiaomi-chuangmicamera-frozen.svg':
      'camera-xiaomi-chuangmicamera-frozen.svg',
    'camera-xiaomi-chuangmicamera.svg': 'camera-xiaomi-chuangmicamera.svg',
    'camera-xiaomi-frozen.svg': 'camera-xiaomi-frozen.svg',
    'camera-xiaomi-mijiacamera-frozen.svg':
      'camera-xiaomi-mijiacamera-frozen.svg',
    'camera-xiaomi-mijiacamera.svg': 'camera-xiaomi-mijiacamera.svg',
    'camera-xiaomi.svg': 'camera-xiaomi.svg',
    'camera-yi-frozen.svg': 'camera-yi-frozen.svg',
    'camera-yi.svg': 'camera-yi.svg',
    'camera-zmodo-frozen.svg': 'camera-zmodo-frozen.svg',
    'camera-zmodo.svg': 'camera-zmodo.svg',
    'camera.svg': 'camera.svg',
    'camerascamcorders-eufy-camera-frozen.svg':
      'camerascamcorders-eufy-camera-frozen.svg',
    'camerascamcorders-eufy-camera.svg': 'camerascamcorders-eufy-camera.svg',
    'camerascamcorders-neos-smartcam-frozen.svg':
      'camerascamcorders-neos-smartcam-frozen.svg',
    'camerascamcorders-neos-smartcam.svg':
      'camerascamcorders-neos-smartcam.svg',
    'camerascamcorders-swann-ads450-frozen.svg':
      'camerascamcorders-swann-ads450-frozen.svg',
    'camerascamcorders-swann-ads450.svg': 'camerascamcorders-swann-ads450.svg',
    'car-bolt-frozen.svg': 'car-bolt-frozen.svg',
    'car-bolt.svg': 'car-bolt.svg',
    'car-chevrolet-frozen.svg': 'car-chevrolet-frozen.svg',
    'car-chevrolet.svg': 'car-chevrolet.svg',
    'car-ford-frozen.svg': 'car-ford-frozen.svg',
    'car-ford-sync-frozen.svg': 'car-ford-sync-frozen.svg',
    'car-ford-sync.svg': 'car-ford-sync.svg',
    'car-ford.svg': 'car-ford.svg',
    'car-frozen.svg': 'car-frozen.svg',
    'car-tesla-frozen.svg': 'car-tesla-frozen.svg',
    'car-tesla.svg': 'car-tesla.svg',
    'car.svg': 'car.svg',
    'clock-2-frozen.svg': 'clock-2-frozen.svg',
    'clock-2.svg': 'clock-2.svg',
    'clock-frozen.svg': 'clock-frozen.svg',
    'clock-geochron-atlas4k-frozen.svg': 'clock-geochron-atlas4k-frozen.svg',
    'clock-geochron-atlas4k.svg': 'clock-geochron-atlas4k.svg',
    'clock-lametric-time-frozen.svg': 'clock-lametric-time-frozen.svg',
    'clock-lametric-time.svg': 'clock-lametric-time.svg',
    'clock-lenovo-smartclock-frozen.svg': 'clock-lenovo-smartclock-frozen.svg',
    'clock-lenovo-smartclock.svg': 'clock-lenovo-smartclock.svg',
    'clock-magnavox-digitalclock-frozen.svg':
      'clock-magnavox-digitalclock-frozen.svg',
    'clock-magnavox-digitalclock.svg': 'clock-magnavox-digitalclock.svg',
    'clock-puri-nixieclock-frozen.svg': 'clock-puri-nixieclock-frozen.svg',
    'clock-puri-nixieclock.svg': 'clock-puri-nixieclock.svg',
    'clock-raspberrypi-hamclock-frozen.svg':
      'clock-raspberrypi-hamclock-frozen.svg',
    'clock-raspberrypi-hamclock.svg': 'clock-raspberrypi-hamclock.svg',
    'clock-sandman-doppler-frozen.svg': 'clock-sandman-doppler-frozen.svg',
    'clock-sandman-doppler.svg': 'clock-sandman-doppler.svg',
    'clock-xiaomi-ai-frozen.svg': 'clock-xiaomi-ai-frozen.svg',
    'clock-xiaomi-ai.svg': 'clock-xiaomi-ai.svg',
    'clock-xiaomi-mijiaclock-frozen.svg': 'clock-xiaomi-mijiaclock-frozen.svg',
    'clock-xiaomi-mijiaclock.svg': 'clock-xiaomi-mijiaclock.svg',
    'clock.svg': 'clock.svg',
    'coffeemaker-2-frozen.svg': 'coffeemaker-2-frozen.svg',
    'coffeemaker-2.svg': 'coffeemaker-2.svg',
    'coffeemaker-bosch-coffeemachine-frozen.svg':
      'coffeemaker-bosch-coffeemachine-frozen.svg',
    'coffeemaker-bosch-coffeemachine.svg':
      'coffeemaker-bosch-coffeemachine.svg',
    'coffeemaker-delonghi-coffeemaker-frozen.svg':
      'coffeemaker-delonghi-coffeemaker-frozen.svg',
    'coffeemaker-delonghi-coffeemaker.svg':
      'coffeemaker-delonghi-coffeemaker.svg',
    'coffeemaker-frozen.svg': 'coffeemaker-frozen.svg',
    'coffeemaker-keurig-frozen.svg': 'coffeemaker-keurig-frozen.svg',
    'coffeemaker-keurig.svg': 'coffeemaker-keurig.svg',
    'coffeemaker-siemens-coffeemachine-frozen.svg':
      'coffeemaker-siemens-coffeemachine-frozen.svg',
    'coffeemaker-siemens-coffeemachine.svg':
      'coffeemaker-siemens-coffeemachine.svg',
    'coffeemaker-thermador-coffeemachine-frozen.svg':
      'coffeemaker-thermador-coffeemachine-frozen.svg',
    'coffeemaker-thermador-coffeemachine.svg':
      'coffeemaker-thermador-coffeemachine.svg',
    'coffeemaker.svg': 'coffeemaker.svg',
    'computer-acer-aspire-frozen.svg': 'computer-acer-aspire-frozen.svg',
    'computer-acer-aspire.svg': 'computer-acer-aspire.svg',
    'computer-acer-chromebook-frozen.svg':
      'computer-acer-chromebook-frozen.svg',
    'computer-acer-chromebook.svg': 'computer-acer-chromebook.svg',
    'computer-apple-imac-frozen.svg': 'computer-apple-imac-frozen.svg',
    'computer-apple-imac.svg': 'computer-apple-imac.svg',
    'computer-apple-mac-frozen.svg': 'computer-apple-mac-frozen.svg',
    'computer-apple-mac.svg': 'computer-apple-mac.svg',
    'computer-apple-macmini-frozen.svg': 'computer-apple-macmini-frozen.svg',
    'computer-apple-macmini.svg': 'computer-apple-macmini.svg',
    'computer-apple-macpro-frozen.svg': 'computer-apple-macpro-frozen.svg',
    'computer-apple-macpro.svg': 'computer-apple-macpro.svg',
    'computer-asus-chromebook-frozen.svg':
      'computer-asus-chromebook-frozen.svg',
    'computer-asus-chromebook.svg': 'computer-asus-chromebook.svg',
    'computer-asus-desktop-frozen.svg': 'computer-asus-desktop-frozen.svg',
    'computer-asus-desktop.svg': 'computer-asus-desktop.svg',
    'computer-asus-frozen.svg': 'computer-asus-frozen.svg',
    'computer-asus-tinkerboard-frozen.svg':
      'computer-asus-tinkerboard-frozen.svg',
    'computer-asus-tinkerboard.svg': 'computer-asus-tinkerboard.svg',
    'computer-asus-windows-frozen.svg': 'computer-asus-windows-frozen.svg',
    'computer-asus-windows.svg': 'computer-asus-windows.svg',
    'computer-asus-zenbook-frozen.svg': 'computer-asus-zenbook-frozen.svg',
    'computer-asus-zenbook.svg': 'computer-asus-zenbook.svg',
    'computer-asus.svg': 'computer-asus.svg',
    'computer-atrust-thinclient-frozen.svg':
      'computer-atrust-thinclient-frozen.svg',
    'computer-atrust-thinclient.svg': 'computer-atrust-thinclient.svg',
    'computer-compulab-airtop-frozen.svg':
      'computer-compulab-airtop-frozen.svg',
    'computer-compulab-airtop.svg': 'computer-compulab-airtop.svg',
    'computer-dell-alienware-frozen.svg': 'computer-dell-alienware-frozen.svg',
    'computer-dell-alienware.svg': 'computer-dell-alienware.svg',
    'computer-dell-chromebook-frozen.svg':
      'computer-dell-chromebook-frozen.svg',
    'computer-dell-chromebook.svg': 'computer-dell-chromebook.svg',
    'computer-dell-dimension-frozen.svg': 'computer-dell-dimension-frozen.svg',
    'computer-dell-dimension.svg': 'computer-dell-dimension.svg',
    'computer-dell-g5-frozen.svg': 'computer-dell-g5-frozen.svg',
    'computer-dell-g5.svg': 'computer-dell-g5.svg',
    'computer-dell-windows-1-frozen.svg': 'computer-dell-windows-1-frozen.svg',
    'computer-dell-windows-1.svg': 'computer-dell-windows-1.svg',
    'computer-dell-windows-frozen.svg': 'computer-dell-windows-frozen.svg',
    'computer-dell-windows.svg': 'computer-dell-windows.svg',
    'computer-dell-xps-frozen.svg': 'computer-dell-xps-frozen.svg',
    'computer-dell-xps.svg': 'computer-dell-xps.svg',
    'computer-desktop-frozen.svg': 'computer-desktop-frozen.svg',
    'computer-desktop.svg': 'computer-desktop.svg',
    'computer-frozen.svg': 'computer-frozen.svg',
    'computer-fujitsu-fmvda2a041-frozen.svg':
      'computer-fujitsu-fmvda2a041-frozen.svg',
    'computer-fujitsu-fmvda2a041.svg': 'computer-fujitsu-fmvda2a041.svg',
    'computer-gigabyte-brix-frozen.svg': 'computer-gigabyte-brix-frozen.svg',
    'computer-gigabyte-brix.svg': 'computer-gigabyte-brix.svg',
    'computer-google-chromebook-frozen.svg':
      'computer-google-chromebook-frozen.svg',
    'computer-google-chromebook.svg': 'computer-google-chromebook.svg',
    'computer-google-pixelbook-frozen.svg':
      'computer-google-pixelbook-frozen.svg',
    'computer-google-pixelbook.svg': 'computer-google-pixelbook.svg',
    'computer-hp-chromebook-frozen.svg': 'computer-hp-chromebook-frozen.svg',
    'computer-hp-chromebook.svg': 'computer-hp-chromebook.svg',
    'computer-hp-eliteone-frozen.svg': 'computer-hp-eliteone-frozen.svg',
    'computer-hp-eliteone.svg': 'computer-hp-eliteone.svg',
    'computer-hp-workstation-frozen.svg': 'computer-hp-workstation-frozen.svg',
    'computer-hp-workstation.svg': 'computer-hp-workstation.svg',
    'computer-ibuypower-gamingcomputer-frozen.svg':
      'computer-ibuypower-gamingcomputer-frozen.svg',
    'computer-ibuypower-gamingcomputer.svg':
      'computer-ibuypower-gamingcomputer.svg',
    'computer-ibuypower-revolt2-frozen.svg':
      'computer-ibuypower-revolt2-frozen.svg',
    'computer-ibuypower-revolt2.svg': 'computer-ibuypower-revolt2.svg',
    'computer-igel-thinclient-frozen.svg':
      'computer-igel-thinclient-frozen.svg',
    'computer-igel-thinclient.svg': 'computer-igel-thinclient.svg',
    'computer-imsai-8080-frozen.svg': 'computer-imsai-8080-frozen.svg',
    'computer-imsai-8080.svg': 'computer-imsai-8080.svg',
    'computer-intel-nuc-frozen.svg': 'computer-intel-nuc-frozen.svg',
    'computer-intel-nuc.svg': 'computer-intel-nuc.svg',
    'computer-leadtek-pcoip-frozen.svg': 'computer-leadtek-pcoip-frozen.svg',
    'computer-leadtek-pcoip.svg': 'computer-leadtek-pcoip.svg',
    'computer-lenovo-thinkcentre-frozen.svg':
      'computer-lenovo-thinkcentre-frozen.svg',
    'computer-lenovo-thinkcentre.svg': 'computer-lenovo-thinkcentre.svg',
    'computer-microsoft-surface-frozen.svg':
      'computer-microsoft-surface-frozen.svg',
    'computer-microsoft-surface.svg': 'computer-microsoft-surface.svg',
    'computer-optiplex-frozen.svg': 'computer-optiplex-frozen.svg',
    'computer-optiplex.svg': 'computer-optiplex.svg',
    'computer-orangepi-pc-frozen.svg': 'computer-orangepi-pc-frozen.svg',
    'computer-orangepi-pc.svg': 'computer-orangepi-pc.svg',
    'computer-pidp-computer-frozen.svg': 'computer-pidp-computer-frozen.svg',
    'computer-pidp-computer.svg': 'computer-pidp-computer.svg',
    'computer-vizio-frozen.svg': 'computer-vizio-frozen.svg',
    'computer-vizio.svg': 'computer-vizio.svg',
    'computer.svg': 'computer.svg',
    'cooker-bosch-cookit-frozen.svg': 'cooker-bosch-cookit-frozen.svg',
    'cooker-bosch-cookit.svg': 'cooker-bosch-cookit.svg',
    'cooker-bosch-frozen.svg': 'cooker-bosch-frozen.svg',
    'cooker-bosch-oven-frozen.svg': 'cooker-bosch-oven-frozen.svg',
    'cooker-bosch-oven.svg': 'cooker-bosch-oven.svg',
    'cooker-bosch.svg': 'cooker-bosch.svg',
    'cooker-frozen.svg': 'cooker-frozen.svg',
    'cooker-thermomix-frozen.svg': 'cooker-thermomix-frozen.svg',
    'cooker-thermomix-tm6-frozen.svg': 'cooker-thermomix-tm6-frozen.svg',
    'cooker-thermomix-tm6.svg': 'cooker-thermomix-tm6.svg',
    'cooker-thermomix.svg': 'cooker-thermomix.svg',
    'cooker.svg': 'cooker.svg',
    'cooktop-bosch-cooktop-frozen.svg': 'cooktop-bosch-cooktop-frozen.svg',
    'cooktop-bosch-cooktop.svg': 'cooktop-bosch-cooktop.svg',
    'cooktop-frozen.svg': 'cooktop-frozen.svg',
    'cooktop-siemens-frozen.svg': 'cooktop-siemens-frozen.svg',
    'cooktop-siemens.svg': 'cooktop-siemens.svg',
    'cooktop-thermador-frozen.svg': 'cooktop-thermador-frozen.svg',
    'cooktop-thermador-gasrangev-frozen.svg':
      'cooktop-thermador-gasrangev-frozen.svg',
    'cooktop-thermador-gasrangev.svg': 'cooktop-thermador-gasrangev.svg',
    'cooktop-thermador-hood-frozen.svg': 'cooktop-thermador-hood-frozen.svg',
    'cooktop-thermador-hood.svg': 'cooktop-thermador-hood.svg',
    'cooktop-thermador.svg': 'cooktop-thermador.svg',
    'cooktop-vzug-frozen.svg': 'cooktop-vzug-frozen.svg',
    'cooktop-vzug.svg': 'cooktop-vzug.svg',
    'cooktop.svg': 'cooktop.svg',
    'customgateway-cambium-epmp-frozen.svg':
      'customgateway-cambium-epmp-frozen.svg',
    'customgateway-cambium-epmp.svg': 'customgateway-cambium-epmp.svg',
    'customgateway-circle-home-frozen.svg':
      'customgateway-circle-home-frozen.svg',
    'customgateway-circle-home.svg': 'customgateway-circle-home.svg',
    'customgateway-cujo-securegateway-frozen.svg':
      'customgateway-cujo-securegateway-frozen.svg',
    'customgateway-cujo-securegateway.svg':
      'customgateway-cujo-securegateway.svg',
    'customgateway-frozen.svg': 'customgateway-frozen.svg',
    'customgateway-intesis-gateway-frozen.svg':
      'customgateway-intesis-gateway-frozen.svg',
    'customgateway-intesis-gateway.svg': 'customgateway-intesis-gateway.svg',
    'customgateway-sharkrf-openspot-frozen.svg':
      'customgateway-sharkrf-openspot-frozen.svg',
    'customgateway-sharkrf-openspot.svg': 'customgateway-sharkrf-openspot.svg',
    'customgateway.svg': 'customgateway.svg',
    'diffuser-aera-smartdiffuser-frozen.svg':
      'diffuser-aera-smartdiffuser-frozen.svg',
    'diffuser-aera-smartdiffuser.svg': 'diffuser-aera-smartdiffuser.svg',
    'diffuser-frozen.svg': 'diffuser-frozen.svg',
    'diffuser-pura-smartdiffuser-frozen.svg':
      'diffuser-pura-smartdiffuser-frozen.svg',
    'diffuser-pura-smartdiffuser.svg': 'diffuser-pura-smartdiffuser.svg',
    'diffuser.svg': 'diffuser.svg',
    'dishwasher-bosch-dishwasher-frozen.svg':
      'dishwasher-bosch-dishwasher-frozen.svg',
    'dishwasher-bosch-dishwasher.svg': 'dishwasher-bosch-dishwasher.svg',
    'dishwasher-bosch-shem78wh5n-frozen.svg':
      'dishwasher-bosch-shem78wh5n-frozen.svg',
    'dishwasher-bosch-shem78wh5n.svg': 'dishwasher-bosch-shem78wh5n.svg',
    'dishwasher-cove-dishwasher-frozen.svg':
      'dishwasher-cove-dishwasher-frozen.svg',
    'dishwasher-cove-dishwasher.svg': 'dishwasher-cove-dishwasher.svg',
    'dishwasher-frozen.svg': 'dishwasher-frozen.svg',
    'dishwasher-ge-frozen.svg': 'dishwasher-ge-frozen.svg',
    'dishwasher-ge.svg': 'dishwasher-ge.svg',
    'dishwasher-lg-frozen.svg': 'dishwasher-lg-frozen.svg',
    'dishwasher-lg.svg': 'dishwasher-lg.svg',
    'dishwasher-samsung-frozen.svg': 'dishwasher-samsung-frozen.svg',
    'dishwasher-samsung.svg': 'dishwasher-samsung.svg',
    'dishwasher-seimens-frozen.svg': 'dishwasher-seimens-frozen.svg',
    'dishwasher-seimens.svg': 'dishwasher-seimens.svg',
    'dishwasher-thermador-frozen.svg': 'dishwasher-thermador-frozen.svg',
    'dishwasher-thermador.svg': 'dishwasher-thermador.svg',
    'dishwasher-vzug-frozen.svg': 'dishwasher-vzug-frozen.svg',
    'dishwasher-vzug.svg': 'dishwasher-vzug.svg',
    'dishwasher-whirlpool-frozen.svg': 'dishwasher-whirlpool-frozen.svg',
    'dishwasher-whirlpool.svg': 'dishwasher-whirlpool.svg',
    'dishwasher.svg': 'dishwasher.svg',
    'doorbell-1.svg': 'doorbell-1.svg',
    'doorbell-2-frozen.svg': 'doorbell-2-frozen.svg',
    'doorbell-2.svg': 'doorbell-2.svg',
    'doorbell-amazonring-chime-frozen.svg':
      'doorbell-amazonring-chime-frozen.svg',
    'doorbell-amazonring-chime.svg': 'doorbell-amazonring-chime.svg',
    'doorbell-amazonring-chimepro-frozen.svg':
      'doorbell-amazonring-chimepro-frozen.svg',
    'doorbell-amazonring-chimepro.svg': 'doorbell-amazonring-chimepro.svg',
    'doorbell-amazonring-elite-frozen.svg':
      'doorbell-amazonring-elite-frozen.svg',
    'doorbell-amazonring-elite.svg': 'doorbell-amazonring-elite.svg',
    'doorbell-amazonring-frozen.svg': 'doorbell-amazonring-frozen.svg',
    'doorbell-amazonring-pro-frozen.svg': 'doorbell-amazonring-pro-frozen.svg',
    'doorbell-amazonring-pro.svg': 'doorbell-amazonring-pro.svg',
    'doorbell-amazonring-videodoorbell-frozen.svg':
      'doorbell-amazonring-videodoorbell-frozen.svg',
    'doorbell-amazonring-videodoorbell.svg':
      'doorbell-amazonring-videodoorbell.svg',
    'doorbell-amazonring.svg': 'doorbell-amazonring.svg',
    'doorbell-amcrest-videodoorbell-frozen.svg':
      'doorbell-amcrest-videodoorbell-frozen.svg',
    'doorbell-amcrest-videodoorbell.svg': 'doorbell-amcrest-videodoorbell.svg',
    'doorbell-arlo-videodoorbell-frozen.svg':
      'doorbell-arlo-videodoorbell-frozen.svg',
    'doorbell-arlo-videodoorbell.svg': 'doorbell-arlo-videodoorbell.svg',
    'doorbell-august-view-frozen.svg': 'doorbell-august-view-frozen.svg',
    'doorbell-august-view.svg': 'doorbell-august-view.svg',
    'doorbell-bticino-classe-frozen.svg': 'doorbell-bticino-classe-frozen.svg',
    'doorbell-bticino-classe.svg': 'doorbell-bticino-classe.svg',
    'doorbell-control4-chime-frozen.svg': 'doorbell-control4-chime-frozen.svg',
    'doorbell-control4-chime.svg': 'doorbell-control4-chime.svg',
    'doorbell-dling-smartvideodoorbell-frozen.svg':
      'doorbell-dling-smartvideodoorbell-frozen.svg',
    'doorbell-dling-smartvideodoorbell.svg':
      'doorbell-dling-smartvideodoorbell.svg',
    'doorbell-doorbird-videointercom-frozen.svg':
      'doorbell-doorbird-videointercom-frozen.svg',
    'doorbell-doorbird-videointercom.svg':
      'doorbell-doorbird-videointercom.svg',
    'doorbell-eufy-videodoorbell-frozen.svg':
      'doorbell-eufy-videodoorbell-frozen.svg',
    'doorbell-eufy-videodoorbell.svg': 'doorbell-eufy-videodoorbell.svg',
    'doorbell-frozen-1.svg': 'doorbell-frozen-1.svg',
    'doorbell-frozen.svg': 'doorbell-frozen.svg',
    'doorbell-kangaroo-doorbellcamera-frozen.svg':
      'doorbell-kangaroo-doorbellcamera-frozen.svg',
    'doorbell-kangaroo-doorbellcamera.svg':
      'doorbell-kangaroo-doorbellcamera.svg',
    'doorbell-nest-frozen.svg': 'doorbell-nest-frozen.svg',
    'doorbell-nest.svg': 'doorbell-nest.svg',
    'doorbell-panasonic-videointercom-frozen.svg':
      'doorbell-panasonic-videointercom-frozen.svg',
    'doorbell-panasonic-videointercom.svg':
      'doorbell-panasonic-videointercom.svg',
    'doorbell-remo+-frozen.svg': 'doorbell-remo+-frozen.svg',
    'doorbell-remo+.svg': 'doorbell-remo+.svg',
    'doorbell-ring-frozen.svg': 'doorbell-ring-frozen.svg',
    'doorbell-ring-videodoorbell-frozen.svg':
      'doorbell-ring-videodoorbell-frozen.svg',
    'doorbell-ring-videodoorbell.svg': 'doorbell-ring-videodoorbell.svg',
    'doorbell-ring.svg': 'doorbell-ring.svg',
    'doorbell-skybell-frozen.svg': 'doorbell-skybell-frozen.svg',
    'doorbell-skybell.svg': 'doorbell-skybell.svg',
    'doorbell-toucan-videodoorbell-frozen.svg':
      'doorbell-toucan-videodoorbell-frozen.svg',
    'doorbell-toucan-videodoorbell.svg': 'doorbell-toucan-videodoorbell.svg',
    'doorbell.svg': 'doorbell.svg',
    'doorlock-2-frozen.svg': 'doorlock-2-frozen.svg',
    'doorlock-2.svg': 'doorlock-2.svg',
    'doorlock-august-smartlock-frozen.svg':
      'doorlock-august-smartlock-frozen.svg',
    'doorlock-august-smartlock.svg': 'doorlock-august-smartlock.svg',
    'doorlock-frozen.svg': 'doorlock-frozen.svg',
    'doorlock-halo-smartlock-frozen.svg': 'doorlock-halo-smartlock-frozen.svg',
    'doorlock-halo-smartlock.svg': 'doorlock-halo-smartlock.svg',
    'doorlock-kwikset-halo-frozen.svg': 'doorlock-kwikset-halo-frozen.svg',
    'doorlock-kwikset-halo.svg': 'doorlock-kwikset-halo.svg',
    'doorlock-nuki-smartlock-frozen.svg': 'doorlock-nuki-smartlock-frozen.svg',
    'doorlock-nuki-smartlock.svg': 'doorlock-nuki-smartlock.svg',
    'doorlock-schlage-smartdeadbolt-frozen.svg':
      'doorlock-schlage-smartdeadbolt-frozen.svg',
    'doorlock-schlage-smartdeadbolt.svg': 'doorlock-schlage-smartdeadbolt.svg',
    'doorlock-sesame-smartlock-frozen.svg':
      'doorlock-sesame-smartlock-frozen.svg',
    'doorlock-sesame-smartlock.svg': 'doorlock-sesame-smartlock.svg',
    'doorlock-ultraloq-deadbolt-frozen.svg':
      'doorlock-ultraloq-deadbolt-frozen.svg',
    'doorlock-ultraloq-deadbolt.svg': 'doorlock-ultraloq-deadbolt.svg',
    'doorlock-wyze-lock-frozen.svg': 'doorlock-wyze-lock-frozen.svg',
    'doorlock-wyze-lock.svg': 'doorlock-wyze-lock.svg',
    'doorlock.svg': 'doorlock.svg',
    'ereader-amazon-kindle-frozen.svg': 'ereader-amazon-kindle-frozen.svg',
    'ereader-amazon-kindle.svg': 'ereader-amazon-kindle.svg',
    'ereader-barnesandnoble-nook-frozen.svg':
      'ereader-barnesandnoble-nook-frozen.svg',
    'ereader-barnesandnoble-nook.svg': 'ereader-barnesandnoble-nook.svg',
    'ereader-ebook-frozen.svg': 'ereader-ebook-frozen.svg',
    'ereader-ebook.svg': 'ereader-ebook.svg',
    'ereader-ereader-amazon-kindle-frozen.svg':
      'ereader-ereader-amazon-kindle-frozen.svg',
    'ereader-ereader-amazon-kindle.svg': 'ereader-ereader-amazon-kindle.svg',
    'ereader-kobo-frozen.svg': 'ereader-kobo-frozen.svg',
    'ereader-kobo.svg': 'ereader-kobo.svg',
    'ereader-tolino-frozen.svg': 'ereader-tolino-frozen.svg',
    'ereader-tolino.svg': 'ereader-tolino.svg',
    'evcharger-chargepoint-frozen.svg': 'evcharger-chargepoint-frozen.svg',
    'evcharger-chargepoint.svg': 'evcharger-chargepoint.svg',
    'evcharger-easee-home-frozen.svg': 'evcharger-easee-home-frozen.svg',
    'evcharger-easee-home.svg': 'evcharger-easee-home.svg',
    'evcharger-flo-home-frozen.svg': 'evcharger-flo-home-frozen.svg',
    'evcharger-flo-home.svg': 'evcharger-flo-home.svg',
    'evcharger-frozen.svg': 'evcharger-frozen.svg',
    'evcharger-goe-evcharger-frozen.svg': 'evcharger-goe-evcharger-frozen.svg',
    'evcharger-goe-evcharger.svg': 'evcharger-goe-evcharger.svg',
    'evcharger-juicebox-frozen.svg': 'evcharger-juicebox-frozen.svg',
    'evcharger-juicebox.svg': 'evcharger-juicebox.svg',
    'evcharger-openevse-evcharger-frozen.svg':
      'evcharger-openevse-evcharger-frozen.svg',
    'evcharger-openevse-evcharger.svg': 'evcharger-openevse-evcharger.svg',
    'evcharger-tesla-wallconnector-frozen.svg':
      'evcharger-tesla-wallconnector-frozen.svg',
    'evcharger-tesla-wallconnector.svg': 'evcharger-tesla-wallconnector.svg',
    'evcharger.svg': 'evcharger.svg',
    'fan-bigassfans-haiku-frozen.svg': 'fan-bigassfans-haiku-frozen.svg',
    'fan-bigassfans-haiku.svg': 'fan-bigassfans-haiku.svg',
    'fan-bigassfans-haikufan-frozen.svg': 'fan-bigassfans-haikufan-frozen.svg',
    'fan-bigassfans-haikufan.svg': 'fan-bigassfans-haikufan.svg',
    'fan-dyson-coolfan-frozen.svg': 'fan-dyson-coolfan-frozen.svg',
    'fan-dyson-coolfan.svg': 'fan-dyson-coolfan.svg',
    'fan-dyson-frozen.svg': 'fan-dyson-frozen.svg',
    'fan-dyson-purecool-frozen.svg': 'fan-dyson-purecool-frozen.svg',
    'fan-dyson-purecool.svg': 'fan-dyson-purecool.svg',
    'fan-dyson-purecoolfan-frozen.svg': 'fan-dyson-purecoolfan-frozen.svg',
    'fan-dyson-purecoolfan.svg': 'fan-dyson-purecoolfan.svg',
    'fan-dyson-purehot+cool-frozen.svg': 'fan-dyson-purehot+cool-frozen.svg',
    'fan-dyson-purehot+cool.svg': 'fan-dyson-purehot+cool.svg',
    'fan-dyson-purehot+coolfan-frozen.svg':
      'fan-dyson-purehot+coolfan-frozen.svg',
    'fan-dyson-purehot+coolfan.svg': 'fan-dyson-purehot+coolfan.svg',
    'fan-dyson.svg': 'fan-dyson.svg',
    'fan-frozen.svg': 'fan-frozen.svg',
    'fan-haiku-frozen.svg': 'fan-haiku-frozen.svg',
    'fan-haiku.svg': 'fan-haiku.svg',
    'fan-hunter-ceilingfan-frozen.svg': 'fan-hunter-ceilingfan-frozen.svg',
    'fan-hunter-ceilingfan.svg': 'fan-hunter-ceilingfan.svg',
    'fan-xiaomi-smartmipedestalfan-frozen.svg':
      'fan-xiaomi-smartmipedestalfan-frozen.svg',
    'fan-xiaomi-smartmipedestalfan.svg': 'fan-xiaomi-smartmipedestalfan.svg',
    'fan.svg': 'fan.svg',
    'fitnessbike-frozen.svg': 'fitnessbike-frozen.svg',
    'fitnessbike-myxfitness-bike-frozen.svg':
      'fitnessbike-myxfitness-bike-frozen.svg',
    'fitnessbike-myxfitness-bike.svg': 'fitnessbike-myxfitness-bike.svg',
    'fitnessbike-myxfitness-frozen.svg': 'fitnessbike-myxfitness-frozen.svg',
    'fitnessbike-myxfitness.svg': 'fitnessbike-myxfitness.svg',
    'fitnessbike-nordictrack-bike-frozen.svg':
      'fitnessbike-nordictrack-bike-frozen.svg',
    'fitnessbike-nordictrack-bike.svg': 'fitnessbike-nordictrack-bike.svg',
    'fitnessbike-nordictrack-frozen.svg': 'fitnessbike-nordictrack-frozen.svg',
    'fitnessbike-nordictrack.svg': 'fitnessbike-nordictrack.svg',
    'fitnessbike-peleton-bike-frozen.svg':
      'fitnessbike-peleton-bike-frozen.svg',
    'fitnessbike-peleton-bike.svg': 'fitnessbike-peleton-bike.svg',
    'fitnessbike-peleton-frozen.svg': 'fitnessbike-peleton-frozen.svg',
    'fitnessbike-peleton.svg': 'fitnessbike-peleton.svg',
    'fitnessbike.svg': 'fitnessbike.svg',
    'fitnesstrainer-fightcamp-athomeboxing-frozen.svg':
      'fitnesstrainer-fightcamp-athomeboxing-frozen.svg',
    'fitnesstrainer-fightcamp-athomeboxing.svg':
      'fitnesstrainer-fightcamp-athomeboxing.svg',
    'fitnesstrainer-frozen.svg': 'fitnesstrainer-frozen.svg',
    'fitnesstrainer-mirror-fitness-frozen.svg':
      'fitnesstrainer-mirror-fitness-frozen.svg',
    'fitnesstrainer-mirror-fitness.svg': 'fitnesstrainer-mirror-fitness.svg',
    'fitnesstrainer-nordictrack-frozen.svg':
      'fitnesstrainer-nordictrack-frozen.svg',
    'fitnesstrainer-nordictrack-trainer-frozen.svg':
      'fitnesstrainer-nordictrack-trainer-frozen.svg',
    'fitnesstrainer-nordictrack-trainer.svg':
      'fitnesstrainer-nordictrack-trainer.svg',
    'fitnesstrainer-nordictrack-treadmill-frozen.svg':
      'fitnesstrainer-nordictrack-treadmill-frozen.svg',
    'fitnesstrainer-nordictrack-treadmill.svg':
      'fitnesstrainer-nordictrack-treadmill.svg',
    'fitnesstrainer-nordictrack.svg': 'fitnesstrainer-nordictrack.svg',
    'fitnesstrainer-peloton-tread-frozen.svg':
      'fitnesstrainer-peloton-tread-frozen.svg',
    'fitnesstrainer-peloton-tread.svg': 'fitnesstrainer-peloton-tread.svg',
    'fitnesstrainer-proform-elliptical-frozen.svg':
      'fitnesstrainer-proform-elliptical-frozen.svg',
    'fitnesstrainer-proform-elliptical.svg':
      'fitnesstrainer-proform-elliptical.svg',
    'fitnesstrainer-proform-frozen.svg': 'fitnesstrainer-proform-frozen.svg',
    'fitnesstrainer-proform-hiittrainer-frozen.svg':
      'fitnesstrainer-proform-hiittrainer-frozen.svg',
    'fitnesstrainer-proform-hiittrainer.svg':
      'fitnesstrainer-proform-hiittrainer.svg',
    'fitnesstrainer-proform-trainer-frozen.svg':
      'fitnesstrainer-proform-trainer-frozen.svg',
    'fitnesstrainer-proform-trainer.svg': 'fitnesstrainer-proform-trainer.svg',
    'fitnesstrainer-proform-treadmill-frozen.svg':
      'fitnesstrainer-proform-treadmill-frozen.svg',
    'fitnesstrainer-proform-treadmill.svg':
      'fitnesstrainer-proform-treadmill.svg',
    'fitnesstrainer-proform.svg': 'fitnesstrainer-proform.svg',
    'fitnesstrainer-tonal-trainer-frozen.svg':
      'fitnesstrainer-tonal-trainer-frozen.svg',
    'fitnesstrainer-tonal-trainer.svg': 'fitnesstrainer-tonal-trainer.svg',
    'fitnesstrainer-walkingpad-foldingtreadmill-frozen.svg':
      'fitnesstrainer-walkingpad-foldingtreadmill-frozen.svg',
    'fitnesstrainer-walkingpad-foldingtreadmill.svg':
      'fitnesstrainer-walkingpad-foldingtreadmill.svg',
    'fitnesstrainer.svg': 'fitnesstrainer.svg',
    'fridge-bosch-freezer-frozen.svg': 'fridge-bosch-freezer-frozen.svg',
    'fridge-bosch-freezer.svg': 'fridge-bosch-freezer.svg',
    'fridge-bosch-refrigerator-frozen.svg':
      'fridge-bosch-refrigerator-frozen.svg',
    'fridge-bosch-refrigerator.svg': 'fridge-bosch-refrigerator.svg',
    'fridge-frozen.svg': 'fridge-frozen.svg',
    'fridge-ge-refrigerator-frozen.svg': 'fridge-ge-refrigerator-frozen.svg',
    'fridge-ge-refrigerator.svg': 'fridge-ge-refrigerator.svg',
    'fridge-lg-refrigerator-frozen.svg': 'fridge-lg-refrigerator-frozen.svg',
    'fridge-lg-refrigerator.svg': 'fridge-lg-refrigerator.svg',
    'fridge-samsung-familyhub-frozen.svg':
      'fridge-samsung-familyhub-frozen.svg',
    'fridge-samsung-familyhub.svg': 'fridge-samsung-familyhub.svg',
    'fridge-siemens-frozen.svg': 'fridge-siemens-frozen.svg',
    'fridge-siemens.svg': 'fridge-siemens.svg',
    'fridge-subzero-refrigerator-frozen.svg':
      'fridge-subzero-refrigerator-frozen.svg',
    'fridge-subzero-refrigerator.svg': 'fridge-subzero-refrigerator.svg',
    'fridge-thermador-frozen.svg': 'fridge-thermador-frozen.svg',
    'fridge-thermador.svg': 'fridge-thermador.svg',
    'fridge-vzug-refrigerator-frozen.svg':
      'fridge-vzug-refrigerator-frozen.svg',
    'fridge-vzug-refrigerator.svg': 'fridge-vzug-refrigerator.svg',
    'fridge-whirlpool-refrigerator-frozen.svg':
      'fridge-whirlpool-refrigerator-frozen.svg',
    'fridge-whirlpool-refrigerator.svg': 'fridge-whirlpool-refrigerator.svg',
    'fridge.svg': 'fridge.svg',
    'gameconsole-atari-gameconsole-frozen.svg':
      'gameconsole-atari-gameconsole-frozen.svg',
    'gameconsole-atari-gameconsole.svg': 'gameconsole-atari-gameconsole.svg',
    'gameconsole-atari-vcs-frozen.svg': 'gameconsole-atari-vcs-frozen.svg',
    'gameconsole-atari-vcs.svg': 'gameconsole-atari-vcs.svg',
    'gameconsole-atgames-ha2810-frozen.svg':
      'gameconsole-atgames-ha2810-frozen.svg',
    'gameconsole-atgames-ha2810.svg': 'gameconsole-atgames-ha2810.svg',
    'gameconsole-goldentee-live-frozen.svg':
      'gameconsole-goldentee-live-frozen.svg',
    'gameconsole-goldentee-live.svg': 'gameconsole-goldentee-live.svg',
    'gameconsole-google-stadia-frozen.svg':
      'gameconsole-google-stadia-frozen.svg',
    'gameconsole-google-stadia.svg': 'gameconsole-google-stadia.svg',
    'gameconsole-leapfrog-explorer-frozen.svg':
      'gameconsole-leapfrog-explorer-frozen.svg',
    'gameconsole-leapfrog-explorer.svg': 'gameconsole-leapfrog-explorer.svg',
    'gameconsole-microsoft-frozen.svg': 'gameconsole-microsoft-frozen.svg',
    'gameconsole-microsoft.svg': 'gameconsole-microsoft.svg',
    'gameconsole-nintendo-3ds-frozen.svg':
      'gameconsole-nintendo-3ds-frozen.svg',
    'gameconsole-nintendo-3ds.svg': 'gameconsole-nintendo-3ds.svg',
    'gameconsole-nintendo-ds-frozen.svg': 'gameconsole-nintendo-ds-frozen.svg',
    'gameconsole-nintendo-ds.svg': 'gameconsole-nintendo-ds.svg',
    'gameconsole-nintendo-wii-frozen.svg':
      'gameconsole-nintendo-wii-frozen.svg',
    'gameconsole-nintendo-wii.svg': 'gameconsole-nintendo-wii.svg',
    'gameconsole-odroid-goadvance-frozen.svg':
      'gameconsole-odroid-goadvance-frozen.svg',
    'gameconsole-odroid-goadvance.svg': 'gameconsole-odroid-goadvance.svg',
    'gameconsole-playbox-4one-frozen.svg':
      'gameconsole-playbox-4one-frozen.svg',
    'gameconsole-playbox-4one.svg': 'gameconsole-playbox-4one.svg',
    'gameconsole-raspberrypi-retropie-frozen.svg':
      'gameconsole-raspberrypi-retropie-frozen.svg',
    'gameconsole-raspberrypi-retropie.svg':
      'gameconsole-raspberrypi-retropie.svg',
    'gameconsole-sony-playstation-frozen.svg':
      'gameconsole-sony-playstation-frozen.svg',
    'gameconsole-sony-playstation.svg': 'gameconsole-sony-playstation.svg',
    'garageopener-chamberlain-frozen.svg':
      'garageopener-chamberlain-frozen.svg',
    'garageopener-chamberlain-myq-frozen.svg':
      'garageopener-chamberlain-myq-frozen.svg',
    'garageopener-chamberlain-myq.svg': 'garageopener-chamberlain-myq.svg',
    'garageopener-chamberlain.svg': 'garageopener-chamberlain.svg',
    'garageopener-frozen.svg': 'garageopener-frozen.svg',
    'garageopener-gogogate-garageopener-frozen.svg':
      'garageopener-gogogate-garageopener-frozen.svg',
    'garageopener-gogogate-garageopener.svg':
      'garageopener-gogogate-garageopener.svg',
    'garageopener-insignia-wifigaragedoorcontroller-frozen.svg':
      'garageopener-insignia-wifigaragedoorcontroller-frozen.svg',
    'garageopener-insignia-wifigaragedoorcontroller.svg':
      'garageopener-insignia-wifigaragedoorcontroller.svg',
    'garageopener-ismartgate-frozen.svg': 'garageopener-ismartgate-frozen.svg',
    'garageopener-ismartgate.svg': 'garageopener-ismartgate.svg',
    'garageopener-meross-smartgarageopener-frozen.svg':
      'garageopener-meross-smartgarageopener-frozen.svg',
    'garageopener-meross-smartgarageopener.svg':
      'garageopener-meross-smartgarageopener.svg',
    'garageopener-momentum-niro-frozen.svg':
      'garageopener-momentum-niro-frozen.svg',
    'garageopener-momentum-niro.svg': 'garageopener-momentum-niro.svg',
    'garageopener-nexx-garage-frozen.svg':
      'garageopener-nexx-garage-frozen.svg',
    'garageopener-nexx-garage.svg': 'garageopener-nexx-garage.svg',
    'garageopener-remootio-garageopener-frozen.svg':
      'garageopener-remootio-garageopener-frozen.svg',
    'garageopener-remootio-garageopener.svg':
      'garageopener-remootio-garageopener.svg',
    'garageopener-skylink-garagedooropener-frozen.svg':
      'garageopener-skylink-garagedooropener-frozen.svg',
    'garageopener-skylink-garagedooropener.svg':
      'garageopener-skylink-garagedooropener.svg',
    'garageopener.svg': 'garageopener.svg',
    'homesecurity-abode-frozen.svg': 'homesecurity-abode-frozen.svg',
    'homesecurity-abode.svg': 'homesecurity-abode.svg',
    'homesecurity-adt-securitypanel-frozen.svg':
      'homesecurity-adt-securitypanel-frozen.svg',
    'homesecurity-adt-securitypanel.svg': 'homesecurity-adt-securitypanel.svg',
    'homesecurity-alula-batconnect-frozen.svg':
      'homesecurity-alula-batconnect-frozen.svg',
    'homesecurity-alula-batconnect.svg': 'homesecurity-alula-batconnect.svg',
    'homesecurity-amcrest-dvr-frozen.svg':
      'homesecurity-amcrest-dvr-frozen.svg',
    'homesecurity-amcrest-dvr.svg': 'homesecurity-amcrest-dvr.svg',
    'homesecurity-amcrest-nvr-frozen.svg':
      'homesecurity-amcrest-nvr-frozen.svg',
    'homesecurity-amcrest-nvr.svg': 'homesecurity-amcrest-nvr.svg',
    'homesecurity-bosch-divar-frozen.svg':
      'homesecurity-bosch-divar-frozen.svg',
    'homesecurity-bosch-divar.svg': 'homesecurity-bosch-divar.svg',
    'homesecurity-brinks-homesecurity-frozen.svg':
      'homesecurity-brinks-homesecurity-frozen.svg',
    'homesecurity-brinks-homesecurity.svg':
      'homesecurity-brinks-homesecurity.svg',
    'homesecurity-defender-guard-frozen.svg':
      'homesecurity-defender-guard-frozen.svg',
    'homesecurity-defender-guard.svg': 'homesecurity-defender-guard.svg',
    'homesecurity-dsc-iotega-frozen.svg': 'homesecurity-dsc-iotega-frozen.svg',
    'homesecurity-dsc-iotega.svg': 'homesecurity-dsc-iotega.svg',
    'homesecurity-eyezon-envizalink-frozen.svg':
      'homesecurity-eyezon-envizalink-frozen.svg',
    'homesecurity-eyezon-envizalink.svg': 'homesecurity-eyezon-envizalink.svg',
    'homesecurity-frozen.svg': 'homesecurity-frozen.svg',
    'homesecurity-home8-homesecurity-frozen.svg':
      'homesecurity-home8-homesecurity-frozen.svg',
    'homesecurity-home8-homesecurity.svg':
      'homesecurity-home8-homesecurity.svg',
    'homesecurity-honeywell-alarmpanel-frozen.svg':
      'homesecurity-honeywell-alarmpanel-frozen.svg',
    'homesecurity-honeywell-alarmpanel.svg':
      'homesecurity-honeywell-alarmpanel.svg',
    'homesecurity-honeywell-frozen.svg': 'homesecurity-honeywell-frozen.svg',
    'homesecurity-honeywell-tuxedotouchalarmpanel-frozen.svg':
      'homesecurity-honeywell-tuxedotouchalarmpanel-frozen.svg',
    'homesecurity-honeywell-tuxedotouchalarmpanel.svg':
      'homesecurity-honeywell-tuxedotouchalarmpanel.svg',
    'homesecurity-honeywell.svg': 'homesecurity-honeywell.svg',
    'homesecurity-honeywelllyric-frozen.svg':
      'homesecurity-honeywelllyric-frozen.svg',
    'homesecurity-honeywelllyric.svg': 'homesecurity-honeywelllyric.svg',
    'homesecurity-hub6-safe-frozen.svg': 'homesecurity-hub6-safe-frozen.svg',
    'homesecurity-hub6-safe.svg': 'homesecurity-hub6-safe.svg',
    'homesecurity-ingrid-homesecurity-frozen.svg':
      'homesecurity-ingrid-homesecurity-frozen.svg',
    'homesecurity-ingrid-homesecurity.svg':
      'homesecurity-ingrid-homesecurity.svg',
    'homesecurity-interlogix-zerowire-frozen.svg':
      'homesecurity-interlogix-zerowire-frozen.svg',
    'homesecurity-interlogix-zerowire.svg':
      'homesecurity-interlogix-zerowire.svg',
    'homesecurity-ismart-smartsecurity-frozen.svg':
      'homesecurity-ismart-smartsecurity-frozen.svg',
    'homesecurity-ismart-smartsecurity.svg':
      'homesecurity-ismart-smartsecurity.svg',
    'homesecurity-konnected-nodemcuwifimodule-frozen.svg':
      'homesecurity-konnected-nodemcuwifimodule-frozen.svg',
    'homesecurity-konnected-nodemcuwifimodule.svg':
      'homesecurity-konnected-nodemcuwifimodule.svg',
    'homesecurity-konnectedsecurity-frozen.svg':
      'homesecurity-konnectedsecurity-frozen.svg',
    'homesecurity-konnectedsecurity.svg': 'homesecurity-konnectedsecurity.svg',
    'homesecurity-lorex-4kdvr-frozen.svg':
      'homesecurity-lorex-4kdvr-frozen.svg',
    'homesecurity-lorex-4kdvr.svg': 'homesecurity-lorex-4kdvr.svg',
    'homesecurity-lorex-4knvr-frozen.svg':
      'homesecurity-lorex-4knvr-frozen.svg',
    'homesecurity-lorex-4knvr.svg': 'homesecurity-lorex-4knvr.svg',
    'homesecurity-lorex-dv916-frozen.svg':
      'homesecurity-lorex-dv916-frozen.svg',
    'homesecurity-lorex-dv916.svg': 'homesecurity-lorex-dv916.svg',
    'homesecurity-lorex-dvr-frozen.svg': 'homesecurity-lorex-dvr-frozen.svg',
    'homesecurity-lorex-dvr.svg': 'homesecurity-lorex-dvr.svg',
    'homesecurity-lorex-ipcamera-frozen.svg':
      'homesecurity-lorex-ipcamera-frozen.svg',
    'homesecurity-lorex-ipcamera.svg': 'homesecurity-lorex-ipcamera.svg',
    'homesecurity-lorex-lvh5100series-frozen.svg':
      'homesecurity-lorex-lvh5100series-frozen.svg',
    'homesecurity-lorex-lvh5100series.svg':
      'homesecurity-lorex-lvh5100series.svg',
    'homesecurity-lorex-nvr-frozen.svg': 'homesecurity-lorex-nvr-frozen.svg',
    'homesecurity-lorex-nvr.svg': 'homesecurity-lorex-nvr.svg',
    'homesecurity-minut-point-frozen.svg':
      'homesecurity-minut-point-frozen.svg',
    'homesecurity-minut-point.svg': 'homesecurity-minut-point.svg',
    'homesecurity-nestguard-frozen.svg': 'homesecurity-nestguard-frozen.svg',
    'homesecurity-nestguard.svg': 'homesecurity-nestguard.svg',
    'homesecurity-netatmo-welcome-frozen.svg':
      'homesecurity-netatmo-welcome-frozen.svg',
    'homesecurity-netatmo-welcome.svg': 'homesecurity-netatmo-welcome.svg',
    'homesecurity-qlosys-iqpanel-frozen.svg':
      'homesecurity-qlosys-iqpanel-frozen.svg',
    'homesecurity-qlosys-iqpanel.svg': 'homesecurity-qlosys-iqpanel.svg',
    'homesecurity-qlosys-iqpanel2-frozen.svg':
      'homesecurity-qlosys-iqpanel2-frozen.svg',
    'homesecurity-qlosys-iqpanel2.svg': 'homesecurity-qlosys-iqpanel2.svg',
    'homesecurity-ringalarmhomesecuritysystem-frozen.svg':
      'homesecurity-ringalarmhomesecuritysystem-frozen.svg',
    'homesecurity-ringalarmhomesecuritysystem.svg':
      'homesecurity-ringalarmhomesecuritysystem.svg',
    'homesecurity-somfyprotect-frozen.svg':
      'homesecurity-somfyprotect-frozen.svg',
    'homesecurity-somfyprotect.svg': 'homesecurity-somfyprotect.svg',
    'homesecurity-technicolor-tablet-frozen.svg':
      'homesecurity-technicolor-tablet-frozen.svg',
    'homesecurity-technicolor-tablet.svg':
      'homesecurity-technicolor-tablet.svg',
    'homesecurity-texe-homesecurity-frozen.svg':
      'homesecurity-texe-homesecurity-frozen.svg',
    'homesecurity-texe-homesecurity.svg': 'homesecurity-texe-homesecurity.svg',
    'homesecurity.svg': 'homesecurity.svg',
    'hub-adt-securityhub-frozen.svg': 'hub-adt-securityhub-frozen.svg',
    'hub-adt-securityhub.svg': 'hub-adt-securityhub.svg',
    'hub-amazonring-bridge-frozen.svg': 'hub-amazonring-bridge-frozen.svg',
    'hub-amazonring-bridge.svg': 'hub-amazonring-bridge.svg',
    'hub-aqara-frozen.svg': 'hub-aqara-frozen.svg',
    'hub-aqara.svg': 'hub-aqara.svg',
    'hub-aquascape-smartcontrolhub-frozen.svg':
      'hub-aquascape-smartcontrolhub-frozen.svg',
    'hub-aquascape-smartcontrolhub.svg': 'hub-aquascape-smartcontrolhub.svg',
    'hub-arlo-basestation-frozen.svg': 'hub-arlo-basestation-frozen.svg',
    'hub-arlo-basestation.svg': 'hub-arlo-basestation.svg',
    'hub-arlo-bridge-frozen.svg': 'hub-arlo-bridge-frozen.svg',
    'hub-arlo-bridge.svg': 'hub-arlo-bridge.svg',
    'hub-august-connect-frozen.svg': 'hub-august-connect-frozen.svg',
    'hub-august-connect.svg': 'hub-august-connect.svg',
    'hub-belkin-wemo-frozen.svg': 'hub-belkin-wemo-frozen.svg',
    'hub-belkin-wemo.svg': 'hub-belkin-wemo.svg',
    'hub-blink-syncmodule-frozen.svg': 'hub-blink-syncmodule-frozen.svg',
    'hub-blink-syncmodule.svg': 'hub-blink-syncmodule.svg',
    'hub-bond-bridge-frozen.svg': 'hub-bond-bridge-frozen.svg',
    'hub-bond-bridge.svg': 'hub-bond-bridge.svg',
    'hub-brilliant-smarthomecontrolpanel-frozen.svg':
      'hub-brilliant-smarthomecontrolpanel-frozen.svg',
    'hub-brilliant-smarthomecontrolpanel.svg':
      'hub-brilliant-smarthomecontrolpanel.svg',
    'hub-broadlink-rm-frozen.svg': 'hub-broadlink-rm-frozen.svg',
    'hub-broadlink-rm.svg': 'hub-broadlink-rm.svg',
    'hub-broadlink-rm2-frozen.svg': 'hub-broadlink-rm2-frozen.svg',
    'hub-broadlink-rm2.svg': 'hub-broadlink-rm2.svg',
    'hub-broadlink-rm4mini-frozen.svg': 'hub-broadlink-rm4mini-frozen.svg',
    'hub-broadlink-rm4mini.svg': 'hub-broadlink-rm4mini.svg',
    'hub-broadlink-rm4pro-frozen.svg': 'hub-broadlink-rm4pro-frozen.svg',
    'hub-broadlink-rm4pro.svg': 'hub-broadlink-rm4pro.svg',
    'hub-broadlink-rmhome+-frozen.svg': 'hub-broadlink-rmhome+-frozen.svg',
    'hub-broadlink-rmhome+.svg': 'hub-broadlink-rmhome+.svg',
    'hub-broadlink-rmmini3-frozen.svg': 'hub-broadlink-rmmini3-frozen.svg',
    'hub-broadlink-rmmini3.svg': 'hub-broadlink-rmmini3.svg',
    'hub-broadlink-rmpro+-frozen.svg': 'hub-broadlink-rmpro+-frozen.svg',
    'hub-broadlink-rmpro+.svg': 'hub-broadlink-rmpro+.svg',
    'hub-broadlink-rmpro-frozen.svg': 'hub-broadlink-rmpro-frozen.svg',
    'hub-broadlink-rmpro.svg': 'hub-broadlink-rmpro.svg',
    'hub-bt-minihub-frozen.svg': 'hub-bt-minihub-frozen.svg',
    'hub-bt-minihub.svg': 'hub-bt-minihub.svg',
    'hub-camect-home-frozen.svg': 'hub-camect-home-frozen.svg',
    'hub-camect-home.svg': 'hub-camect-home.svg',
    'hub-cbyge-creachsmartbridge-frozen.svg':
      'hub-cbyge-creachsmartbridge-frozen.svg',
    'hub-cbyge-creachsmartbridge.svg': 'hub-cbyge-creachsmartbridge.svg',
    'hub-control4-ca1-frozen.svg': 'hub-control4-ca1-frozen.svg',
    'hub-control4-ca1.svg': 'hub-control4-ca1.svg',
    'hub-control4-ea1-frozen.svg': 'hub-control4-ea1-frozen.svg',
    'hub-control4-ea1.svg': 'hub-control4-ea1.svg',
    'hub-control4-ea3-frozen.svg': 'hub-control4-ea3-frozen.svg',
    'hub-control4-ea3.svg': 'hub-control4-ea3.svg',
    'hub-control4-ea5-frozen.svg': 'hub-control4-ea5-frozen.svg',
    'hub-control4-ea5.svg': 'hub-control4-ea5.svg',
    'hub-control4-hc800-frozen.svg': 'hub-control4-hc800-frozen.svg',
    'hub-control4-hc800.svg': 'hub-control4-hc800.svg',
    'hub-control4-homecontroller-frozen.svg':
      'hub-control4-homecontroller-frozen.svg',
    'hub-control4-homecontroller.svg': 'hub-control4-homecontroller.svg',
    'hub-control4-ioextender-frozen.svg': 'hub-control4-ioextender-frozen.svg',
    'hub-control4-ioextender.svg': 'hub-control4-ioextender.svg',
    'hub-develco-gateway-frozen.svg': 'hub-develco-gateway-frozen.svg',
    'hub-develco-gateway.svg': 'hub-develco-gateway.svg',
    'hub-ezviz-w2d-frozen.svg': 'hub-ezviz-w2d-frozen.svg',
    'hub-ezviz-w2d.svg': 'hub-ezviz-w2d.svg',
    'hub-heatmiser-neohub-frozen.svg': 'hub-heatmiser-neohub-frozen.svg',
    'hub-heatmiser-neohub.svg': 'hub-heatmiser-neohub.svg',
    'hub-hive-frozen.svg': 'hub-hive-frozen.svg',
    'hub-hive.svg': 'hub-hive.svg',
    'hub-homey-frozen.svg': 'hub-homey-frozen.svg',
    'hub-homey.svg': 'hub-homey.svg',
    'hub-hoobs-hub-frozen.svg': 'hub-hoobs-hub-frozen.svg',
    'hub-hoobs-hub.svg': 'hub-hoobs-hub.svg',
    'hub-hubitat-elevation-frozen.svg': 'hub-hubitat-elevation-frozen.svg',
    'hub-hubitat-elevation.svg': 'hub-hubitat-elevation.svg',
    'hub-hunterdouglas-powerviewhub-frozen.svg':
      'hub-hunterdouglas-powerviewhub-frozen.svg',
    'hub-hunterdouglas-powerviewhub.svg': 'hub-hunterdouglas-powerviewhub.svg',
    'hub-ikea-tradfrigateway-frozen.svg': 'hub-ikea-tradfrigateway-frozen.svg',
    'hub-ikea-tradfrigateway.svg': 'hub-ikea-tradfrigateway.svg',
    'hub-kangaroo-siren-frozen.svg': 'hub-kangaroo-siren-frozen.svg',
    'hub-kangaroo-siren.svg': 'hub-kangaroo-siren.svg',
    'hub-leedarson-minihub-frozen.svg': 'hub-leedarson-minihub-frozen.svg',
    'hub-leedarson-minihub.svg': 'hub-leedarson-minihub.svg',
    'hub-lightwaverf-linkplus-frozen.svg':
      'hub-lightwaverf-linkplus-frozen.svg',
    'hub-lightwaverf-linkplus.svg': 'hub-lightwaverf-linkplus.svg',
    'hub-lockly-securelink-frozen.svg': 'hub-lockly-securelink-frozen.svg',
    'hub-lockly-securelink.svg': 'hub-lockly-securelink.svg',
    'hub-logitech-harmony-frozen.svg': 'hub-logitech-harmony-frozen.svg',
    'hub-logitech-harmony.svg': 'hub-logitech-harmony.svg',
    'hub-logitech-pop-frozen.svg': 'hub-logitech-pop-frozen.svg',
    'hub-logitech-pop.svg': 'hub-logitech-pop.svg',
    'hub-logitech-squeezeboxcontroller-frozen.svg':
      'hub-logitech-squeezeboxcontroller-frozen.svg',
    'hub-logitech-squeezeboxcontroller.svg':
      'hub-logitech-squeezeboxcontroller.svg',
    'hub-magichue-frozen.svg': 'hub-magichue-frozen.svg',
    'hub-magichue.svg': 'hub-magichue.svg',
    'hub-meross-smarthub-frozen.svg': 'hub-meross-smarthub-frozen.svg',
    'hub-meross-smarthub.svg': 'hub-meross-smarthub.svg',
    'hub-napco-ibridge-frozen.svg': 'hub-napco-ibridge-frozen.svg',
    'hub-napco-ibridge.svg': 'hub-napco-ibridge.svg',
    'hub-nature-remo-frozen.svg': 'hub-nature-remo-frozen.svg',
    'hub-nature-remo.svg': 'hub-nature-remo.svg',
    'hub-netatmo-relay-frozen.svg': 'hub-netatmo-relay-frozen.svg',
    'hub-netatmo-relay.svg': 'hub-netatmo-relay.svg',
    'hub-notion-bridge-frozen.svg': 'hub-notion-bridge-frozen.svg',
    'hub-notion-bridge.svg': 'hub-notion-bridge.svg',
    'hub-nuki-bridge-frozen.svg': 'hub-nuki-bridge-frozen.svg',
    'hub-nuki-bridge.svg': 'hub-nuki-bridge.svg',
    'hub-overkiz-gateway-frozen.svg': 'hub-overkiz-gateway-frozen.svg',
    'hub-overkiz-gateway.svg': 'hub-overkiz-gateway.svg',
    'hub-panasonic-frozen.svg': 'hub-panasonic-frozen.svg',
    'hub-panasonic.svg': 'hub-panasonic.svg',
    'hub-philips-frozen.svg': 'hub-philips-frozen.svg',
    'hub-philips-hue-frozen.svg': 'hub-philips-hue-frozen.svg',
    'hub-philips-hue.svg': 'hub-philips-hue.svg',
    'hub-philips.svg': 'hub-philips.svg',
    'hub-qivicon-homebase-frozen.svg': 'hub-qivicon-homebase-frozen.svg',
    'hub-qivicon-homebase.svg': 'hub-qivicon-homebase.svg',
    'hub-samsung-smartthings-hub-1.svg': 'hub-samsung-smartthings-hub-1.svg',
    'hub-samsung-smartthings-hub-frozen-1.svg':
      'hub-samsung-smartthings-hub-frozen-1.svg',
    'hub-samsung-smartthings-hub-frozen.svg':
      'hub-samsung-smartthings-hub-frozen.svg',
    'hub-samsung-smartthings-hub.svg': 'hub-samsung-smartthings-hub.svg',
    'hub-samsung-smartthingsconnecthome-frozen.svg':
      'hub-samsung-smartthingsconnecthome-frozen.svg',
    'hub-samsung-smartthingsconnecthome.svg':
      'hub-samsung-smartthingsconnecthome.svg',
    'hub-samsung-smartthingshub-frozen.svg':
      'hub-samsung-smartthingshub-frozen.svg',
    'hub-samsung-smartthingshub.svg': 'hub-samsung-smartthingshub.svg',
    'hub-sangoma-vega-frozen.svg': 'hub-sangoma-vega-frozen.svg',
    'hub-sangoma-vega.svg': 'hub-sangoma-vega.svg',
    'hub-sonos-bridge-frozen.svg': 'hub-sonos-bridge-frozen.svg',
    'hub-sonos-bridge.svg': 'hub-sonos-bridge.svg',
    'hub-tado-bridge-frozen.svg': 'hub-tado-bridge-frozen.svg',
    'hub-tado-bridge.svg': 'hub-tado-bridge.svg',
    'hub-thermoworks-smokegateway-frozen.svg':
      'hub-thermoworks-smokegateway-frozen.svg',
    'hub-thermoworks-smokegateway.svg': 'hub-thermoworks-smokegateway.svg',
    'hub-tplink-omadacloudcontroller-frozen.svg':
      'hub-tplink-omadacloudcontroller-frozen.svg',
    'hub-tplink-omadacloudcontroller.svg':
      'hub-tplink-omadacloudcontroller.svg',
    'hub-urc-mxhomepro-frozen.svg': 'hub-urc-mxhomepro-frozen.svg',
    'hub-urc-mxhomepro.svg': 'hub-urc-mxhomepro.svg',
    'hub-velux-active-frozen.svg': 'hub-velux-active-frozen.svg',
    'hub-velux-active.svg': 'hub-velux-active.svg',
    'hub-vivintsmarthome-frozen.svg': 'hub-vivintsmarthome-frozen.svg',
    'hub-vivintsmarthome.svg': 'hub-vivintsmarthome.svg',
    'hub-wink-relay-frozen.svg': 'hub-wink-relay-frozen.svg',
    'hub-wink-relay.svg': 'hub-wink-relay.svg',
    'hub-winkhub-frozen.svg': 'hub-winkhub-frozen.svg',
    'hub-winkhub.svg': 'hub-winkhub.svg',
    'hub-xiaomi-mijiagateway-frozen.svg': 'hub-xiaomi-mijiagateway-frozen.svg',
    'hub-xiaomi-mijiagateway.svg': 'hub-xiaomi-mijiagateway.svg',
    'humidifier-dyson-frozen.svg': 'humidifier-dyson-frozen.svg',
    'humidifier-dyson.svg': 'humidifier-dyson.svg',
    'humidifier-frozen.svg': 'humidifier-frozen.svg',
    'humidifier-hisense-dehumidifier-frozen.svg':
      'humidifier-hisense-dehumidifier-frozen.svg',
    'humidifier-hisense-dehumidifier.svg':
      'humidifier-hisense-dehumidifier.svg',
    'humidifier-levoit-humidifier-frozen.svg':
      'humidifier-levoit-humidifier-frozen.svg',
    'humidifier-levoit-humidifier.svg': 'humidifier-levoit-humidifier.svg',
    'humidifier-meross-smarthumidifier-frozen.svg':
      'humidifier-meross-smarthumidifier-frozen.svg',
    'humidifier-meross-smarthumidifier.svg':
      'humidifier-meross-smarthumidifier.svg',
    'humidifier-vocolinc-mistflow-frozen.svg':
      'humidifier-vocolinc-mistflow-frozen.svg',
    'humidifier-vocolinc-mistflow.svg': 'humidifier-vocolinc-mistflow.svg',
    'humidifier.svg': 'humidifier.svg',
    'iotplatform-frozen.svg': 'iotplatform-frozen.svg',
    'iotplatform.svg': 'iotplatform.svg',
    'laptop-acer-aspire-frozen.svg': 'laptop-acer-aspire-frozen.svg',
    'laptop-acer-aspire.svg': 'laptop-acer-aspire.svg',
    'laptop-acer-chromebook-frozen.svg': 'laptop-acer-chromebook-frozen.svg',
    'laptop-acer-chromebook.svg': 'laptop-acer-chromebook.svg',
    'laptop-acer-computer-frozen.svg': 'laptop-acer-computer-frozen.svg',
    'laptop-acer-computer.svg': 'laptop-acer-computer.svg',
    'laptop-acer-predator-frozen.svg': 'laptop-acer-predator-frozen.svg',
    'laptop-acer-predator.svg': 'laptop-acer-predator.svg',
    'laptop-acer-swift-frozen.svg': 'laptop-acer-swift-frozen.svg',
    'laptop-acer-swift.svg': 'laptop-acer-swift.svg',
    'laptop-apple-macbook-frozen.svg': 'laptop-apple-macbook-frozen.svg',
    'laptop-apple-macbook.svg': 'laptop-apple-macbook.svg',
    'laptop-asus-chromebook-frozen.svg': 'laptop-asus-chromebook-frozen.svg',
    'laptop-asus-chromebook.svg': 'laptop-asus-chromebook.svg',
    'laptop-asus-rog-frozen.svg': 'laptop-asus-rog-frozen.svg',
    'laptop-asus-rog.svg': 'laptop-asus-rog.svg',
    'laptop-asus-windows-frozen.svg': 'laptop-asus-windows-frozen.svg',
    'laptop-asus-windows.svg': 'laptop-asus-windows.svg',
    'laptop-asus-x75a-frozen.svg': 'laptop-asus-x75a-frozen.svg',
    'laptop-asus-x75a.svg': 'laptop-asus-x75a.svg',
    'laptop-asus-zenbook-frozen.svg': 'laptop-asus-zenbook-frozen.svg',
    'laptop-asus-zenbook.svg': 'laptop-asus-zenbook.svg',
    'laptop-chromeos-frozen.svg': 'laptop-chromeos-frozen.svg',
    'laptop-chromeos.svg': 'laptop-chromeos.svg',
    'laptop-dell-alienware-frozen.svg': 'laptop-dell-alienware-frozen.svg',
    'laptop-dell-alienware.svg': 'laptop-dell-alienware.svg',
    'laptop-dell-chromebook-frozen.svg': 'laptop-dell-chromebook-frozen.svg',
    'laptop-dell-chromebook.svg': 'laptop-dell-chromebook.svg',
    'laptop-dell-frozen.svg': 'laptop-dell-frozen.svg',
    'laptop-dell-inspiron-frozen.svg': 'laptop-dell-inspiron-frozen.svg',
    'laptop-dell-inspiron.svg': 'laptop-dell-inspiron.svg',
    'laptop-dell-latitude-frozen.svg': 'laptop-dell-latitude-frozen.svg',
    'laptop-dell-latitude.svg': 'laptop-dell-latitude.svg',
    'laptop-dell-windows-frozen.svg': 'laptop-dell-windows-frozen.svg',
    'laptop-dell-windows.svg': 'laptop-dell-windows.svg',
    'laptop-dell-xps-frozen.svg': 'laptop-dell-xps-frozen.svg',
    'laptop-dell-xps.svg': 'laptop-dell-xps.svg',
    'laptop-dell.svg': 'laptop-dell.svg',
    'laptop-ematic-laptop-frozen.svg': 'laptop-ematic-laptop-frozen.svg',
    'laptop-ematic-laptop.svg': 'laptop-ematic-laptop.svg',
    'laptop-evoo-laptop-frozen.svg': 'laptop-evoo-laptop-frozen.svg',
    'laptop-evoo-laptop.svg': 'laptop-evoo-laptop.svg',
    'laptop-frozen.svg': 'laptop-frozen.svg',
    'laptop-gateway-ne71b-frozen.svg': 'laptop-gateway-ne71b-frozen.svg',
    'laptop-gateway-ne71b.svg': 'laptop-gateway-ne71b.svg',
    'laptop-google-chromebook-frozen.svg':
      'laptop-google-chromebook-frozen.svg',
    'laptop-google-chromebook.svg': 'laptop-google-chromebook.svg',
    'laptop-google-pixelbook-frozen.svg': 'laptop-google-pixelbook-frozen.svg',
    'laptop-google-pixelbook.svg': 'laptop-google-pixelbook.svg',
    'laptop-hp-chromebook-frozen.svg': 'laptop-hp-chromebook-frozen.svg',
    'laptop-hp-chromebook.svg': 'laptop-hp-chromebook.svg',
    'laptop-hp-computer-frozen.svg': 'laptop-hp-computer-frozen.svg',
    'laptop-hp-computer.svg': 'laptop-hp-computer.svg',
    'laptop-hp-envy-frozen.svg': 'laptop-hp-envy-frozen.svg',
    'laptop-hp-envy.svg': 'laptop-hp-envy.svg',
    'laptop-hp-frozen.svg': 'laptop-hp-frozen.svg',
    'laptop-hp-notebook-frozen.svg': 'laptop-hp-notebook-frozen.svg',
    'laptop-hp-notebook.svg': 'laptop-hp-notebook.svg',
    'laptop-hp.svg': 'laptop-hp.svg',
    'laptop.svg': 'laptop.svg',
    'lawnmower-frozen.svg': 'lawnmower-frozen.svg',
    'lawnmower.svg': 'lawnmower.svg',
    'lightbulb-ai-hydra-frozen.svg': 'lightbulb-ai-hydra-frozen.svg',
    'lightbulb-ai-hydra.svg': 'lightbulb-ai-hydra.svg',
    'lightbulb-ai-primehd-frozen.svg': 'lightbulb-ai-primehd-frozen.svg',
    'lightbulb-ai-primehd.svg': 'lightbulb-ai-primehd.svg',
    'lightbulb-cololight-smartledlightpanel-frozen.svg':
      'lightbulb-cololight-smartledlightpanel-frozen.svg',
    'lightbulb-cololight-smartledlightpanel.svg':
      'lightbulb-cololight-smartledlightpanel.svg',
    'lightbulb-corsair-icue-frozen.svg': 'lightbulb-corsair-icue-frozen.svg',
    'lightbulb-corsair-icue.svg': 'lightbulb-corsair-icue.svg',
    'lightbulb-dyson-lightcycle-frozen.svg':
      'lightbulb-dyson-lightcycle-frozen.svg',
    'lightbulb-dyson-lightcycle.svg': 'lightbulb-dyson-lightcycle.svg',
    'lightbulb-elgato-keylight-frozen.svg':
      'lightbulb-elgato-keylight-frozen.svg',
    'lightbulb-elgato-keylight.svg': 'lightbulb-elgato-keylight.svg',
    'lightbulb-etekcity-lightbulb-frozen.svg':
      'lightbulb-etekcity-lightbulb-frozen.svg',
    'lightbulb-etekcity-lightbulb.svg': 'lightbulb-etekcity-lightbulb.svg',
    'lightbulb-eve-lightstrip-frozen.svg':
      'lightbulb-eve-lightstrip-frozen.svg',
    'lightbulb-eve-lightstrip.svg': 'lightbulb-eve-lightstrip.svg',
    'lightbulb-everlights-lightbulb-frozen.svg':
      'lightbulb-everlights-lightbulb-frozen.svg',
    'lightbulb-everlights-lightbulb.svg': 'lightbulb-everlights-lightbulb.svg',
    'lightbulb-feit-lightbulb-frozen.svg':
      'lightbulb-feit-lightbulb-frozen.svg',
    'lightbulb-feit-lightbulb.svg': 'lightbulb-feit-lightbulb.svg',
    'lightbulb-friendship-lamp-frozen.svg':
      'lightbulb-friendship-lamp-frozen.svg',
    'lightbulb-friendship-lamp.svg': 'lightbulb-friendship-lamp.svg',
    'lightbulb-frozen.svg': 'lightbulb-frozen.svg',
    'lightbulb-ge-lightbulb-frozen.svg': 'lightbulb-ge-lightbulb-frozen.svg',
    'lightbulb-ge-lightbulb.svg': 'lightbulb-ge-lightbulb.svg',
    'lightbulb-greenwavereality-lightbulb-frozen.svg':
      'lightbulb-greenwavereality-lightbulb-frozen.svg',
    'lightbulb-greenwavereality-lightbulb.svg':
      'lightbulb-greenwavereality-lightbulb.svg',
    'lightbulb-juno-ai-frozen.svg': 'lightbulb-juno-ai-frozen.svg',
    'lightbulb-juno-ai.svg': 'lightbulb-juno-ai.svg',
    'lightbulb-koogeek-frozen.svg': 'lightbulb-koogeek-frozen.svg',
    'lightbulb-koogeek-lightbulb-frozen.svg':
      'lightbulb-koogeek-lightbulb-frozen.svg',
    'lightbulb-koogeek-lightbulb.svg': 'lightbulb-koogeek-lightbulb.svg',
    'lightbulb-koogeek-lightstrip-frozen.svg':
      'lightbulb-koogeek-lightstrip-frozen.svg',
    'lightbulb-koogeek-lightstrip.svg': 'lightbulb-koogeek-lightstrip.svg',
    'lightbulb-koogeek.svg': 'lightbulb-koogeek.svg',
    'lightbulb-lifx-1.svg': 'lightbulb-lifx-1.svg',
    'lightbulb-lifx-colorlightbulb-frozen.svg':
      'lightbulb-lifx-colorlightbulb-frozen.svg',
    'lightbulb-lifx-colorlightbulb.svg': 'lightbulb-lifx-colorlightbulb.svg',
    'lightbulb-lifx-downlight-frozen.svg':
      'lightbulb-lifx-downlight-frozen.svg',
    'lightbulb-lifx-downlight.svg': 'lightbulb-lifx-downlight.svg',
    'lightbulb-lifx-frozen-1.svg': 'lightbulb-lifx-frozen-1.svg',
    'lightbulb-lifx-frozen.svg': 'lightbulb-lifx-frozen.svg',
    'lightbulb-lifx-tile-frozen.svg': 'lightbulb-lifx-tile-frozen.svg',
    'lightbulb-lifx-tile.svg': 'lightbulb-lifx-tile.svg',
    'lightbulb-lifx.svg': 'lightbulb-lifx.svg',
    'lightbulb-lohas-smartledbulb-frozen.svg':
      'lightbulb-lohas-smartledbulb-frozen.svg',
    'lightbulb-lohas-smartledbulb.svg': 'lightbulb-lohas-smartledbulb.svg',
    'lightbulb-magichue-light-frozen.svg':
      'lightbulb-magichue-light-frozen.svg',
    'lightbulb-magichue-light.svg': 'lightbulb-magichue-light.svg',
    'lightbulb-merkury-smartbulb-frozen.svg':
      'lightbulb-merkury-smartbulb-frozen.svg',
    'lightbulb-merkury-smartbulb.svg': 'lightbulb-merkury-smartbulb.svg',
    'lightbulb-meross-smartambientlight-frozen.svg':
      'lightbulb-meross-smartambientlight-frozen.svg',
    'lightbulb-meross-smartambientlight.svg':
      'lightbulb-meross-smartambientlight.svg',
    'lightbulb-meross-smartlightstrip-frozen.svg':
      'lightbulb-meross-smartlightstrip-frozen.svg',
    'lightbulb-meross-smartlightstrip.svg':
      'lightbulb-meross-smartlightstrip.svg',
    'lightbulb-meross-smartwifibulb-frozen.svg':
      'lightbulb-meross-smartwifibulb-frozen.svg',
    'lightbulb-meross-smartwifibulb.svg': 'lightbulb-meross-smartwifibulb.svg',
    'lightbulb-mirabellagenio-dimmable-frozen.svg':
      'lightbulb-mirabellagenio-dimmable-frozen.svg',
    'lightbulb-mirabellagenio-dimmable.svg':
      'lightbulb-mirabellagenio-dimmable.svg',
    'lightbulb-mystrom-wifibulb-frozen.svg':
      'lightbulb-mystrom-wifibulb-frozen.svg',
    'lightbulb-mystrom-wifibulb.svg': 'lightbulb-mystrom-wifibulb.svg',
    'lightbulb-mystrom-wifiledstrip-frozen.svg':
      'lightbulb-mystrom-wifiledstrip-frozen.svg',
    'lightbulb-mystrom-wifiledstrip.svg': 'lightbulb-mystrom-wifiledstrip.svg',
    'lightbulb-nanoleaf-canvas-frozen.svg':
      'lightbulb-nanoleaf-canvas-frozen.svg',
    'lightbulb-nanoleaf-canvas.svg': 'lightbulb-nanoleaf-canvas.svg',
    'lightbulb-nanoleaf-lightpanels-frozen.svg':
      'lightbulb-nanoleaf-lightpanels-frozen.svg',
    'lightbulb-nanoleaf-lightpanels.svg': 'lightbulb-nanoleaf-lightpanels.svg',
    'lightbulb-philips-hue-frozen.svg': 'lightbulb-philips-hue-frozen.svg',
    'lightbulb-philips-hue.svg': 'lightbulb-philips-hue.svg',
    'lightbulb-philips-smartsleep-frozen.svg':
      'lightbulb-philips-smartsleep-frozen.svg',
    'lightbulb-philips-smartsleep.svg': 'lightbulb-philips-smartsleep.svg',
    'lightbulb-redsea-reefled-frozen.svg':
      'lightbulb-redsea-reefled-frozen.svg',
    'lightbulb-redsea-reefled.svg': 'lightbulb-redsea-reefled.svg',
    'lightbulb-samsung-smartthingsdesklamp-frozen.svg':
      'lightbulb-samsung-smartthingsdesklamp-frozen.svg',
    'lightbulb-samsung-smartthingsdesklamp.svg':
      'lightbulb-samsung-smartthingsdesklamp.svg',
    'lightbulb-sengled-smartwifiledbulb-frozen.svg':
      'lightbulb-sengled-smartwifiledbulb-frozen.svg',
    'lightbulb-sengled-smartwifiledbulb.svg':
      'lightbulb-sengled-smartwifiledbulb.svg',
    'lightbulb-sengled-wifiledlightstrip-frozen.svg':
      'lightbulb-sengled-wifiledlightstrip-frozen.svg',
    'lightbulb-sengled-wifiledlightstrip.svg':
      'lightbulb-sengled-wifiledlightstrip.svg',
    'lightbulb-shelly-bulb-frozen.svg': 'lightbulb-shelly-bulb-frozen.svg',
    'lightbulb-shelly-bulb.svg': 'lightbulb-shelly-bulb.svg',
    'lightbulb-shelly-duo-frozen.svg': 'lightbulb-shelly-duo-frozen.svg',
    'lightbulb-shelly-duo.svg': 'lightbulb-shelly-duo.svg',
    'lightbulb-shelly-vintage-frozen.svg':
      'lightbulb-shelly-vintage-frozen.svg',
    'lightbulb-shelly-vintage.svg': 'lightbulb-shelly-vintage.svg',
    'lightbulb-sony-multifunctionallight-frozen.svg':
      'lightbulb-sony-multifunctionallight-frozen.svg',
    'lightbulb-sony-multifunctionallight.svg':
      'lightbulb-sony-multifunctionallight.svg',
    'lightbulb-teckin-smartbulb-frozen.svg':
      'lightbulb-teckin-smartbulb-frozen.svg',
    'lightbulb-teckin-smartbulb.svg': 'lightbulb-teckin-smartbulb.svg',
    'lightbulb-tplink-kasawifiledbulb-frozen.svg':
      'lightbulb-tplink-kasawifiledbulb-frozen.svg',
    'lightbulb-tplink-kasawifiledbulb.svg':
      'lightbulb-tplink-kasawifiledbulb.svg',
    'lightbulb-tplink-tapo-frozen.svg': 'lightbulb-tplink-tapo-frozen.svg',
    'lightbulb-tplink-tapo.svg': 'lightbulb-tplink-tapo.svg',
    'lightbulb-tplinkcasalightbulb-frozen.svg':
      'lightbulb-tplinkcasalightbulb-frozen.svg',
    'lightbulb-tplinkcasalightbulb.svg': 'lightbulb-tplinkcasalightbulb.svg',
    'lightbulb-tuya-smartledbulb-frozen.svg':
      'lightbulb-tuya-smartledbulb-frozen.svg',
    'lightbulb-tuya-smartledbulb.svg': 'lightbulb-tuya-smartledbulb.svg',
    'lightbulb-twinkly-ledlightstring-frozen.svg':
      'lightbulb-twinkly-ledlightstring-frozen.svg',
    'lightbulb-twinkly-ledlightstring.svg':
      'lightbulb-twinkly-ledlightstring.svg',
    'lightbulb-vocolinc-frozen.svg': 'lightbulb-vocolinc-frozen.svg',
    'lightbulb-vocolinc-lightstrip-frozen.svg':
      'lightbulb-vocolinc-lightstrip-frozen.svg',
    'lightbulb-vocolinc-lightstrip.svg': 'lightbulb-vocolinc-lightstrip.svg',
    'lightbulb-vocolinc.svg': 'lightbulb-vocolinc.svg',
    'lightbulb-wiz-light-frozen.svg': 'lightbulb-wiz-light-frozen.svg',
    'lightbulb-wiz-light.svg': 'lightbulb-wiz-light.svg',
    'lightbulb-wyze-bulb-frozen.svg': 'lightbulb-wyze-bulb-frozen.svg',
    'lightbulb-wyze-bulb.svg': 'lightbulb-wyze-bulb.svg',
    'lightbulb-xiaomi-yeelightledbulb-frozen.svg':
      'lightbulb-xiaomi-yeelightledbulb-frozen.svg',
    'lightbulb-xiaomi-yeelightledbulb.svg':
      'lightbulb-xiaomi-yeelightledbulb.svg',
    'lightbulb-zengge-ledlight-frozen.svg':
      'lightbulb-zengge-ledlight-frozen.svg',
    'lightbulb-zengge-ledlight.svg': 'lightbulb-zengge-ledlight.svg',
    'lightbulb.svg': 'lightbulb.svg',
    'lightswitch-belkin-wemo-frozen.svg': 'lightswitch-belkin-wemo-frozen.svg',
    'lightswitch-belkin-wemo.svg': 'lightswitch-belkin-wemo.svg',
    'lightswitch-control4-c4din8dime-1.svg':
      'lightswitch-control4-c4din8dime-1.svg',
    'lightswitch-control4-c4din8dime-frozen-1.svg':
      'lightswitch-control4-c4din8dime-frozen-1.svg',
    'lightswitch-control4-c4din8dime-frozen.svg':
      'lightswitch-control4-c4din8dime-frozen.svg',
    'lightswitch-control4-c4din8dime.svg':
      'lightswitch-control4-c4din8dime.svg',
    'lightswitch-crestron-switch-frozen.svg':
      'lightswitch-crestron-switch-frozen.svg',
    'lightswitch-crestron-switch.svg': 'lightswitch-crestron-switch.svg',
    'lightswitch-deako-smartlighting-frozen.svg':
      'lightswitch-deako-smartlighting-frozen.svg',
    'lightswitch-deako-smartlighting.svg':
      'lightswitch-deako-smartlighting.svg',
    'lightswitch-dingz-lightswitch-frozen.svg':
      'lightswitch-dingz-lightswitch-frozen.svg',
    'lightswitch-dingz-lightswitch.svg': 'lightswitch-dingz-lightswitch.svg',
    'lightswitch-ecobee-switch+-frozen.svg':
      'lightswitch-ecobee-switch+-frozen.svg',
    'lightswitch-ecobee-switch+.svg': 'lightswitch-ecobee-switch+.svg',
    'lightswitch-etekcity-dimmer-frozen.svg':
      'lightswitch-etekcity-dimmer-frozen.svg',
    'lightswitch-etekcity-dimmer.svg': 'lightswitch-etekcity-dimmer.svg',
    'lightswitch-feller-zeptrion-frozen.svg':
      'lightswitch-feller-zeptrion-frozen.svg',
    'lightswitch-feller-zeptrion.svg': 'lightswitch-feller-zeptrion.svg',
    'lightswitch-frozen.svg': 'lightswitch-frozen.svg',
    'lightswitch-ge-dimmer-frozen.svg': 'lightswitch-ge-dimmer-frozen.svg',
    'lightswitch-ge-dimmer.svg': 'lightswitch-ge-dimmer.svg',
    'lightswitch-ge-switch-frozen.svg': 'lightswitch-ge-switch-frozen.svg',
    'lightswitch-ge-switch.svg': 'lightswitch-ge-switch.svg',
    'lightswitch-idevices-dimmerswitch-frozen.svg':
      'lightswitch-idevices-dimmerswitch-frozen.svg',
    'lightswitch-idevices-dimmerswitch.svg':
      'lightswitch-idevices-dimmerswitch.svg',
    'lightswitch-idevices-outdoorswitch-frozen.svg':
      'lightswitch-idevices-outdoorswitch-frozen.svg',
    'lightswitch-idevices-outdoorswitch.svg':
      'lightswitch-idevices-outdoorswitch.svg',
    'lightswitch-idevices-wallswitch-frozen.svg':
      'lightswitch-idevices-wallswitch-frozen.svg',
    'lightswitch-idevices-wallswitch.svg':
      'lightswitch-idevices-wallswitch.svg',
    'lightswitch-iotty-smartswitch-frozen.svg':
      'lightswitch-iotty-smartswitch-frozen.svg',
    'lightswitch-iotty-smartswitch.svg': 'lightswitch-iotty-smartswitch.svg',
    'lightswitch-koogeek-dimmer-frozen.svg':
      'lightswitch-koogeek-dimmer-frozen.svg',
    'lightswitch-koogeek-dimmer.svg': 'lightswitch-koogeek-dimmer.svg',
    'lightswitch-leviton-dimmer-frozen.svg':
      'lightswitch-leviton-dimmer-frozen.svg',
    'lightswitch-leviton-dimmer.svg': 'lightswitch-leviton-dimmer.svg',
    'lightswitch-leviton-switch-frozen.svg':
      'lightswitch-leviton-switch-frozen.svg',
    'lightswitch-leviton-switch.svg': 'lightswitch-leviton-switch.svg',
    'lightswitch-lutron-dimmer-frozen.svg':
      'lightswitch-lutron-dimmer-frozen.svg',
    'lightswitch-lutron-dimmer.svg': 'lightswitch-lutron-dimmer.svg',
    'lightswitch-lutrondimmerswitch-frozen.svg':
      'lightswitch-lutrondimmerswitch-frozen.svg',
    'lightswitch-lutrondimmerswitch.svg': 'lightswitch-lutrondimmerswitch.svg',
    'lightswitch-martinjerry-smartdimmerswitch-frozen.svg':
      'lightswitch-martinjerry-smartdimmerswitch-frozen.svg',
    'lightswitch-martinjerry-smartdimmerswitch.svg':
      'lightswitch-martinjerry-smartdimmerswitch.svg',
    'lightswitch-martinjerry-smartswitch-frozen.svg':
      'lightswitch-martinjerry-smartswitch-frozen.svg',
    'lightswitch-martinjerry-smartswitch.svg':
      'lightswitch-martinjerry-smartswitch.svg',
    'lightswitch-meross-dimmerswitch-frozen.svg':
      'lightswitch-meross-dimmerswitch-frozen.svg',
    'lightswitch-meross-dimmerswitch.svg':
      'lightswitch-meross-dimmerswitch.svg',
    'lightswitch-meross-smartwifiswitch-frozen.svg':
      'lightswitch-meross-smartwifiswitch-frozen.svg',
    'lightswitch-meross-smartwifiswitch.svg':
      'lightswitch-meross-smartwifiswitch.svg',
    'lightswitch-noonroomdirector-frozen.svg':
      'lightswitch-noonroomdirector-frozen.svg',
    'lightswitch-noonroomdirector.svg': 'lightswitch-noonroomdirector.svg',
    'lightswitch-orro-switch-frozen.svg': 'lightswitch-orro-switch-frozen.svg',
    'lightswitch-orro-switch.svg': 'lightswitch-orro-switch.svg',
    'lightswitch-plum-lightpaddimmer-frozen.svg':
      'lightswitch-plum-lightpaddimmer-frozen.svg',
    'lightswitch-plum-lightpaddimmer.svg':
      'lightswitch-plum-lightpaddimmer.svg',
    'lightswitch-shelly-1-frozen.svg': 'lightswitch-shelly-1-frozen.svg',
    'lightswitch-shelly-1.svg': 'lightswitch-shelly-1.svg',
    'lightswitch-shelly-1l-frozen.svg': 'lightswitch-shelly-1l-frozen.svg',
    'lightswitch-shelly-1l.svg': 'lightswitch-shelly-1l.svg',
    'lightswitch-shelly-1pmrelayswitch-1.svg':
      'lightswitch-shelly-1pmrelayswitch-1.svg',
    'lightswitch-shelly-1pmrelayswitch-frozen-1.svg':
      'lightswitch-shelly-1pmrelayswitch-frozen-1.svg',
    'lightswitch-shelly-1pmrelayswitch-frozen.svg':
      'lightswitch-shelly-1pmrelayswitch-frozen.svg',
    'lightswitch-shelly-1pmrelayswitch.svg':
      'lightswitch-shelly-1pmrelayswitch.svg',
    'lightswitch-shelly-button-frozen.svg':
      'lightswitch-shelly-button-frozen.svg',
    'lightswitch-shelly-button.svg': 'lightswitch-shelly-button.svg',
    'lightswitch-shelly-dimmer-frozen.svg':
      'lightswitch-shelly-dimmer-frozen.svg',
    'lightswitch-shelly-dimmer.svg': 'lightswitch-shelly-dimmer.svg',
    'lightswitch-sonoffswitch-frozen.svg':
      'lightswitch-sonoffswitch-frozen.svg',
    'lightswitch-sonoffswitch.svg': 'lightswitch-sonoffswitch.svg',
    'lightswitch-tplink-kasawifilightswitch-frozen.svg':
      'lightswitch-tplink-kasawifilightswitch-frozen.svg',
    'lightswitch-tplink-kasawifilightswitch.svg':
      'lightswitch-tplink-kasawifilightswitch.svg',
    'lightswitch.svg': 'lightswitch.svg',
    'litterbox-frozen.svg': 'litterbox-frozen.svg',
    'litterbox.svg': 'litterbox.svg',
    'mediaplayer-akai-mpc-frozen.svg': 'mediaplayer-akai-mpc-frozen.svg',
    'mediaplayer-akai-mpc.svg': 'mediaplayer-akai-mpc.svg',
    'mediaplayer-amazon-echolink-frozen.svg':
      'mediaplayer-amazon-echolink-frozen.svg',
    'mediaplayer-amazon-echolink.svg': 'mediaplayer-amazon-echolink.svg',
    'mediaplayer-apple-appletv-frozen.svg':
      'mediaplayer-apple-appletv-frozen.svg',
    'mediaplayer-apple-appletv.svg': 'mediaplayer-apple-appletv.svg',
    'mediaplayer-apple-ipod-frozen.svg': 'mediaplayer-apple-ipod-frozen.svg',
    'mediaplayer-apple-ipod.svg': 'mediaplayer-apple-ipod.svg',
    'mediaplayer-astellkern-ak70-frozen.svg':
      'mediaplayer-astellkern-ak70-frozen.svg',
    'mediaplayer-astellkern-ak70.svg': 'mediaplayer-astellkern-ak70.svg',
    'mediaplayer-bluesound-nodemediaplayer-frozen.svg':
      'mediaplayer-bluesound-nodemediaplayer-frozen.svg',
    'mediaplayer-bluesound-nodemediaplayer.svg':
      'mediaplayer-bluesound-nodemediaplayer.svg',
    'mediaplayer-bluesound-powernodemediaplayer-frozen.svg':
      'mediaplayer-bluesound-powernodemediaplayer-frozen.svg',
    'mediaplayer-bluesound-powernodemediaplayer.svg':
      'mediaplayer-bluesound-powernodemediaplayer.svg',
    'mediaplayer-bluesound-pulse-frozen.svg':
      'mediaplayer-bluesound-pulse-frozen.svg',
    'mediaplayer-bluesound-pulse.svg': 'mediaplayer-bluesound-pulse.svg',
    'mediaplayer-bluesound-vaultmediaplayer-frozen.svg':
      'mediaplayer-bluesound-vaultmediaplayer-frozen.svg',
    'mediaplayer-bluesound-vaultmediaplayer.svg':
      'mediaplayer-bluesound-vaultmediaplayer.svg',
    'mediaplayer-cambridgeaudio-cxnv2-frozen.svg':
      'mediaplayer-cambridgeaudio-cxnv2-frozen.svg',
    'mediaplayer-cambridgeaudio-cxnv2.svg':
      'mediaplayer-cambridgeaudio-cxnv2.svg',
    'mediaplayer-comoaudio-frozen.svg': 'mediaplayer-comoaudio-frozen.svg',
    'mediaplayer-comoaudio.svg': 'mediaplayer-comoaudio.svg',
    'mediaplayer-control4-4zoneamplifier-frozen.svg':
      'mediaplayer-control4-4zoneamplifier-frozen.svg',
    'mediaplayer-control4-4zoneamplifier.svg':
      'mediaplayer-control4-4zoneamplifier.svg',
    'mediaplayer-control4-triad-frozen.svg':
      'mediaplayer-control4-triad-frozen.svg',
    'mediaplayer-control4-triad.svg': 'mediaplayer-control4-triad.svg',
    'mediaplayer-denon-ceol-frozen.svg': 'mediaplayer-denon-ceol-frozen.svg',
    'mediaplayer-denon-ceol.svg': 'mediaplayer-denon-ceol.svg',
    'mediaplayer-denon-dj-frozen.svg': 'mediaplayer-denon-dj-frozen.svg',
    'mediaplayer-denon-dj.svg': 'mediaplayer-denon-dj.svg',
    'mediaplayer-denon-dnp800ne-frozen.svg':
      'mediaplayer-denon-dnp800ne-frozen.svg',
    'mediaplayer-denon-dnp800ne.svg': 'mediaplayer-denon-dnp800ne.svg',
    'mediaplayer-denon-heosamp-frozen.svg':
      'mediaplayer-denon-heosamp-frozen.svg',
    'mediaplayer-denon-heosamp.svg': 'mediaplayer-denon-heosamp.svg',
    'mediaplayer-denon-heosdrive-frozen.svg':
      'mediaplayer-denon-heosdrive-frozen.svg',
    'mediaplayer-denon-heosdrive.svg': 'mediaplayer-denon-heosdrive.svg',
    'mediaplayer-denon-heoslink-frozen.svg':
      'mediaplayer-denon-heoslink-frozen.svg',
    'mediaplayer-denon-heoslink.svg': 'mediaplayer-denon-heoslink.svg',
    'mediaplayer-denon-heossuperlink-frozen.svg':
      'mediaplayer-denon-heossuperlink-frozen.svg',
    'mediaplayer-denon-heossuperlink.svg':
      'mediaplayer-denon-heossuperlink.svg',
    'mediaplayer-denondj-prime-frozen.svg':
      'mediaplayer-denondj-prime-frozen.svg',
    'mediaplayer-denondj-prime.svg': 'mediaplayer-denondj-prime.svg',
    'mediaplayer-dlink-cameravideorecorder-frozen.svg':
      'mediaplayer-dlink-cameravideorecorder-frozen.svg',
    'mediaplayer-dlink-cameravideorecorder.svg':
      'mediaplayer-dlink-cameravideorecorder.svg',
    'mediaplayer-dstv-explora-frozen.svg':
      'mediaplayer-dstv-explora-frozen.svg',
    'mediaplayer-dstv-explora.svg': 'mediaplayer-dstv-explora.svg',
    'mediaplayer-frozen.svg': 'mediaplayer-frozen.svg',
    'mediaplayer-gracedigital-musicplayer-frozen.svg':
      'mediaplayer-gracedigital-musicplayer-frozen.svg',
    'mediaplayer-gracedigital-musicplayer.svg':
      'mediaplayer-gracedigital-musicplayer.svg',
    'mediaplayer-h96-max-frozen.svg': 'mediaplayer-h96-max-frozen.svg',
    'mediaplayer-h96-max.svg': 'mediaplayer-h96-max.svg',
    'mediaplayer-hitachi-bluraydiscrecorder-frozen.svg':
      'mediaplayer-hitachi-bluraydiscrecorder-frozen.svg',
    'mediaplayer-hitachi-bluraydiscrecorder.svg':
      'mediaplayer-hitachi-bluraydiscrecorder.svg',
    'mediaplayer-idis-dvr-frozen.svg': 'mediaplayer-idis-dvr-frozen.svg',
    'mediaplayer-idis-dvr.svg': 'mediaplayer-idis-dvr.svg',
    'mediaplayer-jooki-musicplayer-frozen.svg':
      'mediaplayer-jooki-musicplayer-frozen.svg',
    'mediaplayer-jooki-musicplayer.svg': 'mediaplayer-jooki-musicplayer.svg',
    'mediaplayer-kingbox-tvbox-frozen.svg':
      'mediaplayer-kingbox-tvbox-frozen.svg',
    'mediaplayer-kingbox-tvbox.svg': 'mediaplayer-kingbox-tvbox.svg',
    'mediaplayer-kodi-frozen.svg': 'mediaplayer-kodi-frozen.svg',
    'mediaplayer-kodi.svg': 'mediaplayer-kodi.svg',
    'mediaplayer-logitech-squeezebox-frozen.svg':
      'mediaplayer-logitech-squeezebox-frozen.svg',
    'mediaplayer-logitech-squeezebox.svg':
      'mediaplayer-logitech-squeezebox.svg',
    'mediaplayer-magictv-mtv3300d-frozen.svg':
      'mediaplayer-magictv-mtv3300d-frozen.svg',
    'mediaplayer-magictv-mtv3300d.svg': 'mediaplayer-magictv-mtv3300d.svg',
    'mediaplayer-magictv-mtv8000d-frozen.svg':
      'mediaplayer-magictv-mtv8000d-frozen.svg',
    'mediaplayer-magictv-mtv8000d.svg': 'mediaplayer-magictv-mtv8000d.svg',
    'mediaplayer-marantz-mcr612-frozen.svg':
      'mediaplayer-marantz-mcr612-frozen.svg',
    'mediaplayer-marantz-mcr612.svg': 'mediaplayer-marantz-mcr612.svg',
    'mediaplayer-microjukebox-frozen.svg':
      'mediaplayer-microjukebox-frozen.svg',
    'mediaplayer-microjukebox.svg': 'mediaplayer-microjukebox.svg',
    'mediaplayer-mighty-musicplayer-frozen.svg':
      'mediaplayer-mighty-musicplayer-frozen.svg',
    'mediaplayer-mighty-musicplayer.svg': 'mediaplayer-mighty-musicplayer.svg',
    'mediaplayer-nad-t-frozen.svg': 'mediaplayer-nad-t-frozen.svg',
    'mediaplayer-nad-t.svg': 'mediaplayer-nad-t.svg',
    'mediaplayer-nightowl-dvr-frozen.svg':
      'mediaplayer-nightowl-dvr-frozen.svg',
    'mediaplayer-nightowl-dvr.svg': 'mediaplayer-nightowl-dvr.svg',
    'mediaplayer-nuvo-homeaudiosystem-frozen.svg':
      'mediaplayer-nuvo-homeaudiosystem-frozen.svg',
    'mediaplayer-nuvo-homeaudiosystem.svg':
      'mediaplayer-nuvo-homeaudiosystem.svg',
    'mediaplayer-panasonic-blurayplayer-frozen.svg':
      'mediaplayer-panasonic-blurayplayer-frozen.svg',
    'mediaplayer-panasonic-blurayplayer.svg':
      'mediaplayer-panasonic-blurayplayer.svg',
    'mediaplayer-panasonic-blurayrecorder-frozen.svg':
      'mediaplayer-panasonic-blurayrecorder-frozen.svg',
    'mediaplayer-panasonic-blurayrecorder.svg':
      'mediaplayer-panasonic-blurayrecorder.svg',
    'mediaplayer-panasonic-diga-frozen.svg':
      'mediaplayer-panasonic-diga-frozen.svg',
    'mediaplayer-panasonic-diga.svg': 'mediaplayer-panasonic-diga.svg',
    'mediaplayer-panasonic-hddrecorder-frozen.svg':
      'mediaplayer-panasonic-hddrecorder-frozen.svg',
    'mediaplayer-panasonic-hddrecorder.svg':
      'mediaplayer-panasonic-hddrecorder.svg',
    'mediaplayer-philips-bdp7501-frozen.svg':
      'mediaplayer-philips-bdp7501-frozen.svg',
    'mediaplayer-philips-bdp7501.svg': 'mediaplayer-philips-bdp7501.svg',
    'mediaplayer-philips-tapr802-frozen.svg':
      'mediaplayer-philips-tapr802-frozen.svg',
    'mediaplayer-philips-tapr802.svg': 'mediaplayer-philips-tapr802.svg',
    'mediaplayer-pure-evoke-frozen.svg': 'mediaplayer-pure-evoke-frozen.svg',
    'mediaplayer-pure-evoke.svg': 'mediaplayer-pure-evoke.svg',
    'mediaplayer-pure-sensia-frozen.svg': 'mediaplayer-pure-sensia-frozen.svg',
    'mediaplayer-pure-sensia.svg': 'mediaplayer-pure-sensia.svg',
    'mediaplayer-russound-mbxamp-frozen.svg':
      'mediaplayer-russound-mbxamp-frozen.svg',
    'mediaplayer-russound-mbxamp.svg': 'mediaplayer-russound-mbxamp.svg',
    'mediaplayer-russound-xsource-frozen.svg':
      'mediaplayer-russound-xsource-frozen.svg',
    'mediaplayer-russound-xsource.svg': 'mediaplayer-russound-xsource.svg',
    'mediaplayer-samsung-blurayplayer-frozen.svg':
      'mediaplayer-samsung-blurayplayer-frozen.svg',
    'mediaplayer-samsung-blurayplayer.svg':
      'mediaplayer-samsung-blurayplayer.svg',
    'mediaplayer-scishion-v88-frozen.svg':
      'mediaplayer-scishion-v88-frozen.svg',
    'mediaplayer-scishion-v88.svg': 'mediaplayer-scishion-v88.svg',
    'mediaplayer-simaudio-mind-frozen.svg':
      'mediaplayer-simaudio-mind-frozen.svg',
    'mediaplayer-simaudio-mind.svg': 'mediaplayer-simaudio-mind.svg',
    'mediaplayer-siriusxm-soundstation-frozen.svg':
      'mediaplayer-siriusxm-soundstation-frozen.svg',
    'mediaplayer-siriusxm-soundstation.svg':
      'mediaplayer-siriusxm-soundstation.svg',
    'mediaplayer-sony-blurayplayer-frozen.svg':
      'mediaplayer-sony-blurayplayer-frozen.svg',
    'mediaplayer-sony-blurayplayer.svg': 'mediaplayer-sony-blurayplayer.svg',
    'mediaplayer-sony-blurayrecoder-frozen.svg':
      'mediaplayer-sony-blurayrecoder-frozen.svg',
    'mediaplayer-sony-blurayrecoder.svg': 'mediaplayer-sony-blurayrecoder.svg',
    'mediaplayer-sony-player-frozen.svg': 'mediaplayer-sony-player-frozen.svg',
    'mediaplayer-sony-player.svg': 'mediaplayer-sony-player.svg',
    'mediaplayer-sony-walkman-frozen.svg':
      'mediaplayer-sony-walkman-frozen.svg',
    'mediaplayer-sony-walkman.svg': 'mediaplayer-sony-walkman.svg',
    'mediaplayer-teufel-radio3sixty-frozen.svg':
      'mediaplayer-teufel-radio3sixty-frozen.svg',
    'mediaplayer-teufel-radio3sixty.svg': 'mediaplayer-teufel-radio3sixty.svg',
    'mediaplayer-toshiba-regza-frozen.svg':
      'mediaplayer-toshiba-regza-frozen.svg',
    'mediaplayer-toshiba-regza.svg': 'mediaplayer-toshiba-regza.svg',
    'mediaplayer-volumio-musicplayer-frozen.svg':
      'mediaplayer-volumio-musicplayer-frozen.svg',
    'mediaplayer-volumio-musicplayer.svg':
      'mediaplayer-volumio-musicplayer.svg',
    'mediaplayer-wd-tvlivehubmediaplayer-frozen.svg':
      'mediaplayer-wd-tvlivehubmediaplayer-frozen.svg',
    'mediaplayer-wd-tvlivehubmediaplayer.svg':
      'mediaplayer-wd-tvlivehubmediaplayer.svg',
    'mediaplayer-wd-tvlivemediaplayer-frozen.svg':
      'mediaplayer-wd-tvlivemediaplayer-frozen.svg',
    'mediaplayer-wd-tvlivemediaplayer.svg':
      'mediaplayer-wd-tvlivemediaplayer.svg',
    'mediaplayer-wd-tvliveplusmediaplayer-frozen.svg':
      'mediaplayer-wd-tvliveplusmediaplayer-frozen.svg',
    'mediaplayer-wd-tvliveplusmediaplayer.svg':
      'mediaplayer-wd-tvliveplusmediaplayer.svg',
    'mediaplayer-wd-tvplaymediaplayer-frozen.svg':
      'mediaplayer-wd-tvplaymediaplayer-frozen.svg',
    'mediaplayer-wd-tvplaymediaplayer.svg':
      'mediaplayer-wd-tvplaymediaplayer.svg',
    'mediaplayer-wdtvmediaplayer-frozen.svg':
      'mediaplayer-wdtvmediaplayer-frozen.svg',
    'mediaplayer-wdtvmediaplayer.svg': 'mediaplayer-wdtvmediaplayer.svg',
    'mediaplayer-yamaha-avreceiver-frozen.svg':
      'mediaplayer-yamaha-avreceiver-frozen.svg',
    'mediaplayer-yamaha-avreceiver.svg': 'mediaplayer-yamaha-avreceiver.svg',
    'mediaplayer-yandex-station-frozen.svg':
      'mediaplayer-yandex-station-frozen.svg',
    'mediaplayer-yandex-station.svg': 'mediaplayer-yandex-station.svg',
    'mediaplayer.svg': 'mediaplayer.svg',
    'meshrouter-airties-air-frozen.svg': 'meshrouter-airties-air-frozen.svg',
    'meshrouter-airties-air.svg': 'meshrouter-airties-air.svg',
    'meshrouter-bt-wholehome-frozen.svg': 'meshrouter-bt-wholehome-frozen.svg',
    'meshrouter-bt-wholehome.svg': 'meshrouter-bt-wholehome.svg',
    'meshrouter-cambiumnetworks-router-frozen.svg':
      'meshrouter-cambiumnetworks-router-frozen.svg',
    'meshrouter-cambiumnetworks-router.svg':
      'meshrouter-cambiumnetworks-router.svg',
    'meshrouter-commscope-frozen.svg': 'meshrouter-commscope-frozen.svg',
    'meshrouter-commscope.svg': 'meshrouter-commscope.svg',
    'meshrouter-dlink-covr-frozen.svg': 'meshrouter-dlink-covr-frozen.svg',
    'meshrouter-dlink-covr.svg': 'meshrouter-dlink-covr.svg',
    'meshrouter-eero-frozen.svg': 'meshrouter-eero-frozen.svg',
    'meshrouter-eero.svg': 'meshrouter-eero.svg',
    'meshrouter-elecom-meshrouter-frozen.svg':
      'meshrouter-elecom-meshrouter-frozen.svg',
    'meshrouter-elecom-meshrouter.svg': 'meshrouter-elecom-meshrouter.svg',
    'meshrouter-frozen.svg': 'meshrouter-frozen.svg',
    'meshrouter-googlenest-wifi-frozen.svg':
      'meshrouter-googlenest-wifi-frozen.svg',
    'meshrouter-googlenest-wifi.svg': 'meshrouter-googlenest-wifi.svg',
    'meshrouter-helium-hotspot-frozen.svg':
      'meshrouter-helium-hotspot-frozen.svg',
    'meshrouter-helium-hotspot.svg': 'meshrouter-helium-hotspot.svg',
    'meshrouter-linksys-velop-frozen.svg':
      'meshrouter-linksys-velop-frozen.svg',
    'meshrouter-linksys-velop.svg': 'meshrouter-linksys-velop.svg',
    'meshrouter-netgear-orbipro-frozen.svg':
      'meshrouter-netgear-orbipro-frozen.svg',
    'meshrouter-netgear-orbipro.svg': 'meshrouter-netgear-orbipro.svg',
    'meshrouter-plume-frozen.svg': 'meshrouter-plume-frozen.svg',
    'meshrouter-plume-pod-frozen.svg': 'meshrouter-plume-pod-frozen.svg',
    'meshrouter-plume-pod.svg': 'meshrouter-plume-pod.svg',
    'meshrouter-plume-powerpod-frozen.svg':
      'meshrouter-plume-powerpod-frozen.svg',
    'meshrouter-plume-powerpod.svg': 'meshrouter-plume-powerpod.svg',
    'meshrouter-plume-superpod-frozen.svg':
      'meshrouter-plume-superpod-frozen.svg',
    'meshrouter-plume-superpod.svg': 'meshrouter-plume-superpod.svg',
    'meshrouter-plume.svg': 'meshrouter-plume.svg',
    'meshrouter-sky-qbooster-frozen.svg': 'meshrouter-sky-qbooster-frozen.svg',
    'meshrouter-sky-qbooster.svg': 'meshrouter-sky-qbooster.svg',
    'meshrouter-tenda-nova-frozen.svg': 'meshrouter-tenda-nova-frozen.svg',
    'meshrouter-tenda-nova.svg': 'meshrouter-tenda-nova.svg',
    'meshrouter.svg': 'meshrouter.svg',
    'microwave-frozen.svg': 'microwave-frozen.svg',
    'microwave-siemens-microwave-frozen.svg':
      'microwave-siemens-microwave-frozen.svg',
    'microwave-siemens-microwave.svg': 'microwave-siemens-microwave.svg',
    'microwave-whirlpool-frozen.svg': 'microwave-whirlpool-frozen.svg',
    'microwave-whirlpool.svg': 'microwave-whirlpool.svg',
    'microwave.svg': 'microwave.svg',
    'nasstorage-antsle-frozen.svg': 'nasstorage-antsle-frozen.svg',
    'nasstorage-antsle-privatecloudserver-1.svg':
      'nasstorage-antsle-privatecloudserver-1.svg',
    'nasstorage-antsle-privatecloudserver-frozen-1.svg':
      'nasstorage-antsle-privatecloudserver-frozen-1.svg',
    'nasstorage-antsle-privatecloudserver-frozen.svg':
      'nasstorage-antsle-privatecloudserver-frozen.svg',
    'nasstorage-antsle-privatecloudserver.svg':
      'nasstorage-antsle-privatecloudserver.svg',
    'nasstorage-antsle.svg': 'nasstorage-antsle.svg',
    'nasstorage-asustor-as1002t-frozen.svg':
      'nasstorage-asustor-as1002t-frozen.svg',
    'nasstorage-asustor-as1002t.svg': 'nasstorage-asustor-as1002t.svg',
    'nasstorage-asustor-nas-frozen.svg': 'nasstorage-asustor-nas-frozen.svg',
    'nasstorage-asustor-nas.svg': 'nasstorage-asustor-nas.svg',
    'nasstorage-buffalo-frozen.svg': 'nasstorage-buffalo-frozen.svg',
    'nasstorage-buffalo-linkstation-frozen.svg':
      'nasstorage-buffalo-linkstation-frozen.svg',
    'nasstorage-buffalo-linkstation.svg': 'nasstorage-buffalo-linkstation.svg',
    'nasstorage-buffalo.svg': 'nasstorage-buffalo.svg',
    'nasstorage-cubbit-frozen.svg': 'nasstorage-cubbit-frozen.svg',
    'nasstorage-cubbit-storagehub-frozen.svg':
      'nasstorage-cubbit-storagehub-frozen.svg',
    'nasstorage-cubbit-storagehub.svg': 'nasstorage-cubbit-storagehub.svg',
    'nasstorage-cubbit.svg': 'nasstorage-cubbit.svg',
    'nasstorage-dlink-frozen.svg': 'nasstorage-dlink-frozen.svg',
    'nasstorage-dlink-sharecenter-frozen.svg':
      'nasstorage-dlink-sharecenter-frozen.svg',
    'nasstorage-dlink-sharecenter.svg': 'nasstorage-dlink-sharecenter.svg',
    'nasstorage-dlink.svg': 'nasstorage-dlink.svg',
    'nasstorage-echelon-smartserver-frozen.svg':
      'nasstorage-echelon-smartserver-frozen.svg',
    'nasstorage-frozen.svg': 'nasstorage-frozen.svg',
    'nasstorage-innuous-frozen.svg': 'nasstorage-innuous-frozen.svg',
    'nasstorage-innuous-zenmini-frozen.svg':
      'nasstorage-innuous-zenmini-frozen.svg',
    'nasstorage-innuous-zenmini.svg': 'nasstorage-innuous-zenmini.svg',
    'nasstorage-innuous.svg': 'nasstorage-innuous.svg',
    'nasstorage-iodata-frozen.svg': 'nasstorage-iodata-frozen.svg',
    'nasstorage-iodata-landisk-frozen.svg':
      'nasstorage-iodata-landisk-frozen.svg',
    'nasstorage-iodata-landisk.svg': 'nasstorage-iodata-landisk.svg',
    'nasstorage-iodata-pokedoracloud-frozen.svg':
      'nasstorage-iodata-pokedoracloud-frozen.svg',
    'nasstorage-iodata-pokedoracloud.svg':
      'nasstorage-iodata-pokedoracloud.svg',
    'nasstorage-iodata.svg': 'nasstorage-iodata.svg',
    'nasstorage-lacie-2big-frozen.svg': 'nasstorage-lacie-2big-frozen.svg',
    'nasstorage-lacie-2big.svg': 'nasstorage-lacie-2big.svg',
    'nasstorage-lacie-5big-frozen.svg': 'nasstorage-lacie-5big-frozen.svg',
    'nasstorage-lacie-5big.svg': 'nasstorage-lacie-5big.svg',
    'nasstorage-lacie-cloudbox-frozen.svg':
      'nasstorage-lacie-cloudbox-frozen.svg',
    'nasstorage-lacie-cloudbox.svg': 'nasstorage-lacie-cloudbox.svg',
    'nasstorage-lacie-frozen.svg': 'nasstorage-lacie-frozen.svg',
    'nasstorage-lacie-fuel-frozen.svg': 'nasstorage-lacie-fuel-frozen.svg',
    'nasstorage-lacie-fuel.svg': 'nasstorage-lacie-fuel.svg',
    'nasstorage-lacie-networkstorage-frozen.svg':
      'nasstorage-lacie-networkstorage-frozen.svg',
    'nasstorage-lacie-networkstorage.svg':
      'nasstorage-lacie-networkstorage.svg',
    'nasstorage-lacie.svg': 'nasstorage-lacie.svg',
    'nasstorage-lenovo-frozen.svg': 'nasstorage-lenovo-frozen.svg',
    'nasstorage-lenovo.svg': 'nasstorage-lenovo.svg',
    'nasstorage-lg-frozen.svg': 'nasstorage-lg-frozen.svg',
    'nasstorage-lg-nas-frozen.svg': 'nasstorage-lg-nas-frozen.svg',
    'nasstorage-lg-nas.svg': 'nasstorage-lg-nas.svg',
    'nasstorage-lg.svg': 'nasstorage-lg.svg',
    'nasstorage-monument-frozen.svg': 'nasstorage-monument-frozen.svg',
    'nasstorage-monument-photostorage-frozen.svg':
      'nasstorage-monument-photostorage-frozen.svg',
    'nasstorage-monument-photostorage.svg':
      'nasstorage-monument-photostorage.svg',
    'nasstorage-monument.svg': 'nasstorage-monument.svg',
    'nasstorage-netgear-frozen.svg': 'nasstorage-netgear-frozen.svg',
    'nasstorage-netgear-nas-frozen.svg': 'nasstorage-netgear-nas-frozen.svg',
    'nasstorage-netgear-nas.svg': 'nasstorage-netgear-nas.svg',
    'nasstorage-netgear-readynas-frozen.svg':
      'nasstorage-netgear-readynas-frozen.svg',
    'nasstorage-netgear-readynas.svg': 'nasstorage-netgear-readynas.svg',
    'nasstorage-netgear-stora-frozen.svg':
      'nasstorage-netgear-stora-frozen.svg',
    'nasstorage-netgear-stora.svg': 'nasstorage-netgear-stora.svg',
    'nasstorage-netgear.svg': 'nasstorage-netgear.svg',
    'nasstorage-omoidebako-frozen.svg': 'nasstorage-omoidebako-frozen.svg',
    'nasstorage-omoidebako.svg': 'nasstorage-omoidebako.svg',
    'nasstorage-qnap-frozen.svg': 'nasstorage-qnap-frozen.svg',
    'nasstorage-qnap-nas-frozen.svg': 'nasstorage-qnap-nas-frozen.svg',
    'nasstorage-qnap-nas.svg': 'nasstorage-qnap-nas.svg',
    'nasstorage-qnap.svg': 'nasstorage-qnap.svg',
    'nasstorage-sandisk-frozen.svg': 'nasstorage-sandisk-frozen.svg',
    'nasstorage-sandisk-ixpand-frozen.svg':
      'nasstorage-sandisk-ixpand-frozen.svg',
    'nasstorage-sandisk-ixpand.svg': 'nasstorage-sandisk-ixpand.svg',
    'nasstorage-sandisk.svg': 'nasstorage-sandisk.svg',
    'nasstorage-seagate-frozen.svg': 'nasstorage-seagate-frozen.svg',
    'nasstorage-seagate-goflexhome-frozen.svg':
      'nasstorage-seagate-goflexhome-frozen.svg',
    'nasstorage-seagate-goflexhome.svg': 'nasstorage-seagate-goflexhome.svg',
    'nasstorage-seagate-nas-frozen.svg': 'nasstorage-seagate-nas-frozen.svg',
    'nasstorage-seagate-nas.svg': 'nasstorage-seagate-nas.svg',
    'nasstorage-seagate.svg': 'nasstorage-seagate.svg',
    'nasstorage-synology-diskstationnas-frozen.svg':
      'nasstorage-synology-diskstationnas-frozen.svg',
    'nasstorage-synology-diskstationnas.svg':
      'nasstorage-synology-diskstationnas.svg',
    'nasstorage-synology-frozen.svg': 'nasstorage-synology-frozen.svg',
    'nasstorage-synology.svg': 'nasstorage-synology.svg',
    'nasstorage-truenas-frozen.svg': 'nasstorage-truenas-frozen.svg',
    'nasstorage-truenas-storage-frozen.svg':
      'nasstorage-truenas-storage-frozen.svg',
    'nasstorage-truenas-storage.svg': 'nasstorage-truenas-storage.svg',
    'nasstorage-truenas.svg': 'nasstorage-truenas.svg',
    'nasstorage-ubiquiti-frozen.svg': 'nasstorage-ubiquiti-frozen.svg',
    'nasstorage-ubiquiti-unifi-frozen.svg':
      'nasstorage-ubiquiti-unifi-frozen.svg',
    'nasstorage-ubiquiti-unifi.svg': 'nasstorage-ubiquiti-unifi.svg',
    'nasstorage-ubiquiti.svg': 'nasstorage-ubiquiti.svg',
    'nasstorage-vivint-frozen.svg': 'nasstorage-vivint-frozen.svg',
    'nasstorage-vivint-smartdrive-frozen.svg':
      'nasstorage-vivint-smartdrive-frozen.svg',
    'nasstorage-vivint-smartdrive.svg': 'nasstorage-vivint-smartdrive.svg',
    'nasstorage-vivint.svg': 'nasstorage-vivint.svg',
    'nasstorage-vivotek-videoserver-frozen.svg':
      'nasstorage-vivotek-videoserver-frozen.svg',
    'nasstorage-vivotek-videoserver.svg': 'nasstorage-vivotek-videoserver.svg',
    'nasstorage-westerndigital-frozen.svg':
      'nasstorage-westerndigital-frozen.svg',
    'nasstorage-westerndigital-mybooklive-frozen.svg':
      'nasstorage-westerndigital-mybooklive-frozen.svg',
    'nasstorage-westerndigital-mybooklive.svg':
      'nasstorage-westerndigital-mybooklive.svg',
    'nasstorage-westerndigital-mycloud-frozen.svg':
      'nasstorage-westerndigital-mycloud-frozen.svg',
    'nasstorage-westerndigital-mycloud.svg':
      'nasstorage-westerndigital-mycloud.svg',
    'nasstorage-westerndigital-mypassport-frozen.svg':
      'nasstorage-westerndigital-mypassport-frozen.svg',
    'nasstorage-westerndigital-mypassport.svg':
      'nasstorage-westerndigital-mypassport.svg',
    'nasstorage-westerndigital.svg': 'nasstorage-westerndigital.svg',
    'nasstorage-zyxel-frozen.svg': 'nasstorage-zyxel-frozen.svg',
    'nasstorage-zyxel-mediaserver-frozen.svg':
      'nasstorage-zyxel-mediaserver-frozen.svg',
    'nasstorage-zyxel-mediaserver.svg': 'nasstorage-zyxel-mediaserver.svg',
    'nasstorage-zyxel.svg': 'nasstorage-zyxel.svg',
    'nasstorage.svg': 'nasstorage.svg',
    'networkcontroller-frozen.svg': 'networkcontroller-frozen.svg',
    'networkcontroller-gardena-gateway-frozen.svg':
      'networkcontroller-gardena-gateway-frozen.svg',
    'networkcontroller-gardena-gateway.svg':
      'networkcontroller-gardena-gateway.svg',
    'networkcontroller-generac-mobilelink-frozen.svg':
      'networkcontroller-generac-mobilelink-frozen.svg',
    'networkcontroller-generac-mobilelink.svg':
      'networkcontroller-generac-mobilelink.svg',
    'networkcontroller-meross-remotecontrol-frozen.svg':
      'networkcontroller-meross-remotecontrol-frozen.svg',
    'networkcontroller-meross-remotecontrol.svg':
      'networkcontroller-meross-remotecontrol.svg',
    'networkcontroller-ubiquiti-unifi-frozen.svg':
      'networkcontroller-ubiquiti-unifi-frozen.svg',
    'networkcontroller-ubiquiti-unifi.svg':
      'networkcontroller-ubiquiti-unifi.svg',
    'networkcontroller-vmware-velocloud-frozen.svg':
      'networkcontroller-vmware-velocloud-frozen.svg',
    'networkcontroller-vmware-velocloud.svg':
      'networkcontroller-vmware-velocloud.svg',
    'networkcontroller-xyo-bridgex-frozen.svg':
      'networkcontroller-xyo-bridgex-frozen.svg',
    'networkcontroller-xyo-bridgex.svg': 'networkcontroller-xyo-bridgex.svg',
    'networkcontroller-zinwell-ethernetadapter-frozen.svg':
      'networkcontroller-zinwell-ethernetadapter-frozen.svg',
    'networkcontroller-zinwell-ethernetadapter.svg':
      'networkcontroller-zinwell-ethernetadapter.svg',
    'networkcontroller.svg': 'networkcontroller.svg',
    'oven-anovaculinary-precisionoven-frozen.svg':
      'oven-anovaculinary-precisionoven-frozen.svg',
    'oven-anovaculinary-precisionoven.svg':
      'oven-anovaculinary-precisionoven.svg',
    'oven-bosch-oven-frozen.svg': 'oven-bosch-oven-frozen.svg',
    'oven-bosch-oven.svg': 'oven-bosch-oven.svg',
    'oven-brava-frozen.svg': 'oven-brava-frozen.svg',
    'oven-brava.svg': 'oven-brava.svg',
    'oven-cosori-oven-frozen.svg': 'oven-cosori-oven-frozen.svg',
    'oven-cosori-oven.svg': 'oven-cosori-oven.svg',
    'oven-frozen.svg': 'oven-frozen.svg',
    'oven-ge-frozen.svg': 'oven-ge-frozen.svg',
    'oven-ge.svg': 'oven-ge.svg',
    'oven-june-frozen.svg': 'oven-june-frozen.svg',
    'oven-june.svg': 'oven-june.svg',
    'oven-lg-smartoven-frozen.svg': 'oven-lg-smartoven-frozen.svg',
    'oven-lg-smartoven.svg': 'oven-lg-smartoven.svg',
    'oven-neff-n90-frozen.svg': 'oven-neff-n90-frozen.svg',
    'oven-neff-n90.svg': 'oven-neff-n90.svg',
    'oven-samsung-frozen.svg': 'oven-samsung-frozen.svg',
    'oven-samsung.svg': 'oven-samsung.svg',
    'oven-seimens-frozen.svg': 'oven-seimens-frozen.svg',
    'oven-seimens.svg': 'oven-seimens.svg',
    'oven-siemens-compactoven-frozen.svg':
      'oven-siemens-compactoven-frozen.svg',
    'oven-siemens-compactoven.svg': 'oven-siemens-compactoven.svg',
    'oven-thermador-frozen.svg': 'oven-thermador-frozen.svg',
    'oven-thermador.svg': 'oven-thermador.svg',
    'oven-vzug-frozen.svg': 'oven-vzug-frozen.svg',
    'oven-vzug.svg': 'oven-vzug.svg',
    'oven-whirlpool-frozen.svg': 'oven-whirlpool-frozen.svg',
    'oven-whirlpool.svg': 'oven-whirlpool.svg',
    'oven-wolf-oven-frozen.svg': 'oven-wolf-oven-frozen.svg',
    'oven-wolf-oven.svg': 'oven-wolf-oven.svg',
    'oven.svg': 'oven.svg',
    'petaccessory-fi-collar-frozen.svg': 'petaccessory-fi-collar-frozen.svg',
    'petaccessory-fi-collar.svg': 'petaccessory-fi-collar.svg',
    'petaccessory-frozen.svg': 'petaccessory-frozen.svg',
    'petaccessory-petkit-cozy-frozen.svg':
      'petaccessory-petkit-cozy-frozen.svg',
    'petaccessory-petkit-cozy.svg': 'petaccessory-petkit-cozy.svg',
    'petaccessory-petkit-petfeeder-frozen.svg':
      'petaccessory-petkit-petfeeder-frozen.svg',
    'petaccessory-petkit-petfeeder.svg': 'petaccessory-petkit-petfeeder.svg',
    'petaccessory-petoneer-catfountain-frozen.svg':
      'petaccessory-petoneer-catfountain-frozen.svg',
    'petaccessory-petoneer-catfountain.svg':
      'petaccessory-petoneer-catfountain.svg',
    'petaccessory.svg': 'petaccessory.svg',
    'pictureframe-aluratek-photoframe-frozen.svg':
      'pictureframe-aluratek-photoframe-frozen.svg',
    'pictureframe-aluratek-photoframe.svg':
      'pictureframe-aluratek-photoframe.svg',
    'pictureframe-aura-frame-frozen.svg': 'pictureframe-aura-frame-frozen.svg',
    'pictureframe-aura-frame.svg': 'pictureframe-aura-frame.svg',
    'pictureframe-canvia-smartartframe-frozen.svg':
      'pictureframe-canvia-smartartframe-frozen.svg',
    'pictureframe-canvia-smartartframe.svg':
      'pictureframe-canvia-smartartframe.svg',
    'pictureframe-frozen.svg': 'pictureframe-frozen.svg',
    'pictureframe-meural-canvas-frozen.svg':
      'pictureframe-meural-canvas-frozen.svg',
    'pictureframe-meural-canvas.svg': 'pictureframe-meural-canvas.svg',
    'pictureframe-nixplay-seed-frozen.svg':
      'pictureframe-nixplay-seed-frozen.svg',
    'pictureframe-nixplay-seed.svg': 'pictureframe-nixplay-seed.svg',
    'pictureframe-nixplay-smartphotoframe-frozen.svg':
      'pictureframe-nixplay-smartphotoframe-frozen.svg',
    'pictureframe-nixplay-smartphotoframe.svg':
      'pictureframe-nixplay-smartphotoframe.svg',
    'pictureframe-photospring-digitalframe-frozen.svg':
      'pictureframe-photospring-digitalframe-frozen.svg',
    'pictureframe-photospring-digitalframe.svg':
      'pictureframe-photospring-digitalframe.svg',
    'pictureframe-pixstar-digitalphotoframe-frozen.svg':
      'pictureframe-pixstar-digitalphotoframe-frozen.svg',
    'pictureframe-pixstar-digitalphotoframe.svg':
      'pictureframe-pixstar-digitalphotoframe.svg',
    'pictureframe-simplysmarthome-photoshare-frozen.svg':
      'pictureframe-simplysmarthome-photoshare-frozen.svg',
    'pictureframe-simplysmarthome-photoshare.svg':
      'pictureframe-simplysmarthome-photoshare.svg',
    'pictureframe-skylight-frame-frozen.svg':
      'pictureframe-skylight-frame-frozen.svg',
    'pictureframe-skylight-frame.svg': 'pictureframe-skylight-frame.svg',
    'pictureframe.svg': 'pictureframe.svg',
    'plug-amazon-smartplug-1.svg': 'plug-amazon-smartplug-1.svg',
    'plug-amazon-smartplug-frozen-1.svg': 'plug-amazon-smartplug-frozen-1.svg',
    'plug-amazon-smartplug-frozen.svg': 'plug-amazon-smartplug-frozen.svg',
    'plug-amazon-smartplug.svg': 'plug-amazon-smartplug.svg',
    'plug-apc-wifipowerstrip-frozen.svg': 'plug-apc-wifipowerstrip-frozen.svg',
    'plug-apc-wifipowerstrip.svg': 'plug-apc-wifipowerstrip.svg',
    'plug-belkin-wemo-frozen.svg': 'plug-belkin-wemo-frozen.svg',
    'plug-belkin-wemo.svg': 'plug-belkin-wemo.svg',
    'plug-belkin-wemosmartplug-frozen.svg':
      'plug-belkin-wemosmartplug-frozen.svg',
    'plug-belkin-wemosmartplug.svg': 'plug-belkin-wemosmartplug.svg',
    'plug-broadlink-smartplug-frozen.svg':
      'plug-broadlink-smartplug-frozen.svg',
    'plug-broadlink-smartplug.svg': 'plug-broadlink-smartplug.svg',
    'plug-buddee-smartplug-frozen.svg': 'plug-buddee-smartplug-frozen.svg',
    'plug-buddee-smartplug.svg': 'plug-buddee-smartplug.svg',
    'plug-cbyge-smartplug-frozen.svg': 'plug-cbyge-smartplug-frozen.svg',
    'plug-cbyge-smartplug.svg': 'plug-cbyge-smartplug.svg',
    'plug-connectsense-smartinwalloutlet-1.svg':
      'plug-connectsense-smartinwalloutlet-1.svg',
    'plug-connectsense-smartinwalloutlet-frozen-1.svg':
      'plug-connectsense-smartinwalloutlet-frozen-1.svg',
    'plug-connectsense-smartinwalloutlet-frozen.svg':
      'plug-connectsense-smartinwalloutlet-frozen.svg',
    'plug-connectsense-smartinwalloutlet.svg':
      'plug-connectsense-smartinwalloutlet.svg',
    'plug-connectsense-smartoutlet-frozen.svg':
      'plug-connectsense-smartoutlet-frozen.svg',
    'plug-connectsense-smartoutlet.svg': 'plug-connectsense-smartoutlet.svg',
    'plug-currant-wifismartoutlet-frozen.svg':
      'plug-currant-wifismartoutlet-frozen.svg',
    'plug-currant-wifismartoutlet.svg': 'plug-currant-wifismartoutlet.svg',
    'plug-ecoplugs-smartplug-frozen.svg': 'plug-ecoplugs-smartplug-frozen.svg',
    'plug-ecoplugs-smartplug.svg': 'plug-ecoplugs-smartplug.svg',
    'plug-etekcity-ihomesmartplug-frozen.svg':
      'plug-etekcity-ihomesmartplug-frozen.svg',
    'plug-etekcity-ihomesmartplug.svg': 'plug-etekcity-ihomesmartplug.svg',
    'plug-etekcity-voltsonsmartplug-frozen.svg':
      'plug-etekcity-voltsonsmartplug-frozen.svg',
    'plug-etekcity-voltsonsmartplug.svg': 'plug-etekcity-voltsonsmartplug.svg',
    'plug-frozen.svg': 'plug-frozen.svg',
    'plug-geeni-spot-frozen.svg': 'plug-geeni-spot-frozen.svg',
    'plug-geeni-spot.svg': 'plug-geeni-spot.svg',
    'plug-idevices-socket-frozen.svg': 'plug-idevices-socket-frozen.svg',
    'plug-idevices-socket.svg': 'plug-idevices-socket.svg',
    'plug-idevices-switch-frozen.svg': 'plug-idevices-switch-frozen.svg',
    'plug-idevices-switch.svg': 'plug-idevices-switch.svg',
    'plug-idevices-walloutlet-frozen.svg':
      'plug-idevices-walloutlet-frozen.svg',
    'plug-idevices-walloutlet.svg': 'plug-idevices-walloutlet.svg',
    'plug-ihome-smartplug-frozen.svg': 'plug-ihome-smartplug-frozen.svg',
    'plug-ihome-smartplug.svg': 'plug-ihome-smartplug.svg',
    'plug-iris-smartplug-frozen.svg': 'plug-iris-smartplug-frozen.svg',
    'plug-iris-smartplug.svg': 'plug-iris-smartplug.svg',
    'plug-konke-miniussmartplug-frozen.svg':
      'plug-konke-miniussmartplug-frozen.svg',
    'plug-konke-miniussmartplug.svg': 'plug-konke-miniussmartplug.svg',
    'plug-koogeek-frozen.svg': 'plug-koogeek-frozen.svg',
    'plug-koogeek-outlet-frozen.svg': 'plug-koogeek-outlet-frozen.svg',
    'plug-koogeek-outlet.svg': 'plug-koogeek-outlet.svg',
    'plug-koogeek-socket-frozen.svg': 'plug-koogeek-socket-frozen.svg',
    'plug-koogeek-socket.svg': 'plug-koogeek-socket.svg',
    'plug-koogeek.svg': 'plug-koogeek.svg',
    'plug-meross-smartsurgeprotector-frozen.svg':
      'plug-meross-smartsurgeprotector-frozen.svg',
    'plug-meross-smartsurgeprotector.svg':
      'plug-meross-smartsurgeprotector.svg',
    'plug-meross-smartwifiplug-frozen.svg':
      'plug-meross-smartwifiplug-frozen.svg',
    'plug-meross-smartwifiplug.svg': 'plug-meross-smartwifiplug.svg',
    'plug-meross-smartwifiwallswitch-frozen.svg':
      'plug-meross-smartwifiwallswitch-frozen.svg',
    'plug-meross-smartwifiwallswitch.svg':
      'plug-meross-smartwifiwallswitch.svg',
    'plug-mobvoi-smartplug-frozen.svg': 'plug-mobvoi-smartplug-frozen.svg',
    'plug-mobvoi-smartplug.svg': 'plug-mobvoi-smartplug.svg',
    'plug-mystrom-wifiswitch-frozen.svg': 'plug-mystrom-wifiswitch-frozen.svg',
    'plug-mystrom-wifiswitch.svg': 'plug-mystrom-wifiswitch.svg',
    'plug-mytouchsmart-smartplug-frozen.svg':
      'plug-mytouchsmart-smartplug-frozen.svg',
    'plug-mytouchsmart-smartplug.svg': 'plug-mytouchsmart-smartplug.svg',
    'plug-nest-connect-frozen.svg': 'plug-nest-connect-frozen.svg',
    'plug-nest-connect.svg': 'plug-nest-connect.svg',
    'plug-nexx-smartplug-frozen.svg': 'plug-nexx-smartplug-frozen.svg',
    'plug-nexx-smartplug.svg': 'plug-nexx-smartplug.svg',
    'plug-opso-hkou1-frozen.svg': 'plug-opso-hkou1-frozen.svg',
    'plug-opso-hkou1.svg': 'plug-opso-hkou1.svg',
    'plug-pumpspy-smartoutlet-frozen.svg':
      'plug-pumpspy-smartoutlet-frozen.svg',
    'plug-pumpspy-smartoutlet.svg': 'plug-pumpspy-smartoutlet.svg',
    'plug-samsung-smartthingsoutlet-frozen.svg':
      'plug-samsung-smartthingsoutlet-frozen.svg',
    'plug-samsung-smartthingsoutlet.svg': 'plug-samsung-smartthingsoutlet.svg',
    'plug-samsung-smartthingssmartplug-frozen.svg':
      'plug-samsung-smartthingssmartplug-frozen.svg',
    'plug-samsung-smartthingssmartplug.svg':
      'plug-samsung-smartthingssmartplug.svg',
    'plug-satechi-dualoutlet-frozen.svg': 'plug-satechi-dualoutlet-frozen.svg',
    'plug-satechi-dualoutlet.svg': 'plug-satechi-dualoutlet.svg',
    'plug-shelly-frozen.svg': 'plug-shelly-frozen.svg',
    'plug-shelly.svg': 'plug-shelly.svg',
    'plug-smartlife-smartplug-frozen.svg':
      'plug-smartlife-smartplug-frozen.svg',
    'plug-smartlife-smartplug.svg': 'plug-smartlife-smartplug.svg',
    'plug-snapav-wattbox-frozen.svg': 'plug-snapav-wattbox-frozen.svg',
    'plug-snapav-wattbox.svg': 'plug-snapav-wattbox.svg',
    'plug-switchmate-powersmartplug-frozen.svg':
      'plug-switchmate-powersmartplug-frozen.svg',
    'plug-switchmate-powersmartplug.svg': 'plug-switchmate-powersmartplug.svg',
    'plug-teckin-smartplug-frozen.svg': 'plug-teckin-smartplug-frozen.svg',
    'plug-teckin-smartplug.svg': 'plug-teckin-smartplug.svg',
    'plug-thinkeco-smartacplug-frozen.svg':
      'plug-thinkeco-smartacplug-frozen.svg',
    'plug-thinkeco-smartacplug.svg': 'plug-thinkeco-smartacplug.svg',
    'plug-tplink-frozen.svg': 'plug-tplink-frozen.svg',
    'plug-tplink-kasa-frozen.svg': 'plug-tplink-kasa-frozen.svg',
    'plug-tplink-kasa.svg': 'plug-tplink-kasa.svg',
    'plug-tplink-kasaoutlet-frozen.svg': 'plug-tplink-kasaoutlet-frozen.svg',
    'plug-tplink-kasaoutlet.svg': 'plug-tplink-kasaoutlet.svg',
    'plug-tplink-kasawifiplug-frozen.svg':
      'plug-tplink-kasawifiplug-frozen.svg',
    'plug-tplink-kasawifiplug.svg': 'plug-tplink-kasawifiplug.svg',
    'plug-tplink-kasawifipowerstrip-frozen.svg':
      'plug-tplink-kasawifipowerstrip-frozen.svg',
    'plug-tplink-kasawifipowerstrip.svg': 'plug-tplink-kasawifipowerstrip.svg',
    'plug-tplink-powerline-frozen.svg': 'plug-tplink-powerline-frozen.svg',
    'plug-tplink-powerline.svg': 'plug-tplink-powerline.svg',
    'plug-tplink-taposmartplug-frozen.svg':
      'plug-tplink-taposmartplug-frozen.svg',
    'plug-tplink-taposmartplug.svg': 'plug-tplink-taposmartplug.svg',
    'plug-tplink.svg': 'plug-tplink.svg',
    'plug-ubiquiti-unifi-frozen.svg': 'plug-ubiquiti-unifi-frozen.svg',
    'plug-ubiquiti-unifi.svg': 'plug-ubiquiti-unifi.svg',
    'plug-vocolinc-powerstrip-frozen.svg':
      'plug-vocolinc-powerstrip-frozen.svg',
    'plug-vocolinc-powerstrip.svg': 'plug-vocolinc-powerstrip.svg',
    'plug-vocolinc-smartbar-frozen.svg': 'plug-vocolinc-smartbar-frozen.svg',
    'plug-vocolinc-smartbar.svg': 'plug-vocolinc-smartbar.svg',
    'plug-vocolinc-smartoutlet-frozen.svg':
      'plug-vocolinc-smartoutlet-frozen.svg',
    'plug-vocolinc-smartoutlet.svg': 'plug-vocolinc-smartoutlet.svg',
    'plug-vocolinc-vp5-frozen.svg': 'plug-vocolinc-vp5-frozen.svg',
    'plug-vocolinc-vp5.svg': 'plug-vocolinc-vp5.svg',
    'plug-wion-smartplug-frozen.svg': 'plug-wion-smartplug-frozen.svg',
    'plug-wion-smartplug.svg': 'plug-wion-smartplug.svg',
    'plug-wyze-smartplug-frozen.svg': 'plug-wyze-smartplug-frozen.svg',
    'plug-wyze-smartplug.svg': 'plug-wyze-smartplug.svg',
    'plug-xiaomi-mismartplug-frozen.svg': 'plug-xiaomi-mismartplug-frozen.svg',
    'plug-xiaomi-mismartplug.svg': 'plug-xiaomi-mismartplug.svg',
    'plug.svg': 'plug.svg',
    'pod-frozen.svg': 'pod-frozen.svg',
    'pod.svg': 'pod.svg',
    'pointofsale-clover-flex-frozen.svg': 'pointofsale-clover-flex-frozen.svg',
    'pointofsale-clover-flex.svg': 'pointofsale-clover-flex.svg',
    'pointofsale-clover-frozen.svg': 'pointofsale-clover-frozen.svg',
    'pointofsale-clover-mini-frozen.svg': 'pointofsale-clover-mini-frozen.svg',
    'pointofsale-clover-mini.svg': 'pointofsale-clover-mini.svg',
    'pointofsale-clover-station-frozen.svg':
      'pointofsale-clover-station-frozen.svg',
    'pointofsale-clover-station.svg': 'pointofsale-clover-station.svg',
    'pointofsale-clover.svg': 'pointofsale-clover.svg',
    'pointofsale-frozen.svg': 'pointofsale-frozen.svg',
    'pointofsale-ingenico-frozen.svg': 'pointofsale-ingenico-frozen.svg',
    'pointofsale-ingenico-smartpos-frozen.svg':
      'pointofsale-ingenico-smartpos-frozen.svg',
    'pointofsale-ingenico-smartpos.svg': 'pointofsale-ingenico-smartpos.svg',
    'pointofsale-ingenico.svg': 'pointofsale-ingenico.svg',
    'pointofsale-pax-a80-frozen.svg': 'pointofsale-pax-a80-frozen.svg',
    'pointofsale-pax-a80.svg': 'pointofsale-pax-a80.svg',
    'pointofsale-pax-a910-frozen.svg': 'pointofsale-pax-a910-frozen.svg',
    'pointofsale-pax-a910.svg': 'pointofsale-pax-a910.svg',
    'pointofsale-pax-a920-frozen.svg': 'pointofsale-pax-a920-frozen.svg',
    'pointofsale-pax-a920.svg': 'pointofsale-pax-a920.svg',
    'pointofsale-pax-a930-frozen.svg': 'pointofsale-pax-a930-frozen.svg',
    'pointofsale-pax-a930.svg': 'pointofsale-pax-a930.svg',
    'pointofsale-pax-frozen.svg': 'pointofsale-pax-frozen.svg',
    'pointofsale-pax-pos-frozen.svg': 'pointofsale-pax-pos-frozen.svg',
    'pointofsale-pax-pos.svg': 'pointofsale-pax-pos.svg',
    'pointofsale-pax-s80-frozen.svg': 'pointofsale-pax-s80-frozen.svg',
    'pointofsale-pax-s80.svg': 'pointofsale-pax-s80.svg',
    'pointofsale-pax.svg': 'pointofsale-pax.svg',
    'pointofsale-verifone-frozen.svg': 'pointofsale-verifone-frozen.svg',
    'pointofsale-verifone-pos-frozen.svg':
      'pointofsale-verifone-pos-frozen.svg',
    'pointofsale-verifone-pos.svg': 'pointofsale-verifone-pos.svg',
    'pointofsale-verifone.svg': 'pointofsale-verifone.svg',
    'pointofsale.svg': 'pointofsale.svg',
    'poolsystem-1.svg': 'poolsystem-1.svg',
    'poolsystem-frozen-1.svg': 'poolsystem-frozen-1.svg',
    'poolsystem-frozen.svg': 'poolsystem-frozen.svg',
    'poolsystem-iaqualink-frozen.svg': 'poolsystem-iaqualink-frozen.svg',
    'poolsystem-iaqualink.svg': 'poolsystem-iaqualink.svg',
    'poolsystem-pentair-intellichlor-frozen.svg':
      'poolsystem-pentair-intellichlor-frozen.svg',
    'poolsystem-pentair-intellichlor.svg':
      'poolsystem-pentair-intellichlor.svg',
    'poolsystem-pentair-poolcontrolsystem-frozen.svg':
      'poolsystem-pentair-poolcontrolsystem-frozen.svg',
    'poolsystem-pentair-poolcontrolsystem.svg':
      'poolsystem-pentair-poolcontrolsystem.svg',
    'poolsystem-waterguru-sense-frozen.svg':
      'poolsystem-waterguru-sense-frozen.svg',
    'poolsystem-waterguru-sense.svg': 'poolsystem-waterguru-sense.svg',
    'poolsystem.svg': 'poolsystem.svg',
    'printer-brother-frozen.svg': 'printer-brother-frozen.svg',
    'printer-brother.svg': 'printer-brother.svg',
    'printer-canon-frozen.svg': 'printer-canon-frozen.svg',
    'printer-canon-photoprinter-frozen.svg':
      'printer-canon-photoprinter-frozen.svg',
    'printer-canon-photoprinter.svg': 'printer-canon-photoprinter.svg',
    'printer-canon-pixma-frozen.svg': 'printer-canon-pixma-frozen.svg',
    'printer-canon-pixma.svg': 'printer-canon-pixma.svg',
    'printer-canon.svg': 'printer-canon.svg',
    'printer-canon2-frozen.svg': 'printer-canon2-frozen.svg',
    'printer-canon2.svg': 'printer-canon2.svg',
    'printer-creality-cr10s-frozen.svg': 'printer-creality-cr10s-frozen.svg',
    'printer-creality-cr10s.svg': 'printer-creality-cr10s.svg',
    'printer-creality-ender-frozen.svg': 'printer-creality-ender-frozen.svg',
    'printer-creality-ender.svg': 'printer-creality-ender.svg',
    'printer-dell-frozen.svg': 'printer-dell-frozen.svg',
    'printer-dell.svg': 'printer-dell.svg',
    'printer-epson-frozen.svg': 'printer-epson-frozen.svg',
    'printer-epson.svg': 'printer-epson.svg',
    'printer-frozen.svg': 'printer-frozen.svg',
    'printer-glowforge-3dprinter-frozen.svg':
      'printer-glowforge-3dprinter-frozen.svg',
    'printer-glowforge-3dprinter.svg': 'printer-glowforge-3dprinter.svg',
    'printer-hp-frozen.svg': 'printer-hp-frozen.svg',
    'printer-hp.svg': 'printer-hp.svg',
    'printer-kodak-verite-frozen.svg': 'printer-kodak-verite-frozen.svg',
    'printer-kodak-verite.svg': 'printer-kodak-verite.svg',
    'printer-panasonic-fax-frozen.svg': 'printer-panasonic-fax-frozen.svg',
    'printer-panasonic-fax.svg': 'printer-panasonic-fax.svg',
    'printer-pantum-frozen.svg': 'printer-pantum-frozen.svg',
    'printer-pantum.svg': 'printer-pantum.svg',
    'printer-ricoh-frozen.svg': 'printer-ricoh-frozen.svg',
    'printer-ricoh-sp-frozen.svg': 'printer-ricoh-sp-frozen.svg',
    'printer-ricoh-sp.svg': 'printer-ricoh-sp.svg',
    'printer-ricoh.svg': 'printer-ricoh.svg',
    'printer-samsung-frozen.svg': 'printer-samsung-frozen.svg',
    'printer-samsung.svg': 'printer-samsung.svg',
    'printer-toybox-3dprinter-frozen.svg':
      'printer-toybox-3dprinter-frozen.svg',
    'printer-toybox-3dprinter.svg': 'printer-toybox-3dprinter.svg',
    'printer-ultimaker-3dprinter-frozen.svg':
      'printer-ultimaker-3dprinter-frozen.svg',
    'printer-ultimaker-3dprinter.svg': 'printer-ultimaker-3dprinter.svg',
    'printer-xerox-frozen.svg': 'printer-xerox-frozen.svg',
    'printer-xerox-phaser-frozen.svg': 'printer-xerox-phaser-frozen.svg',
    'printer-xerox-phaser.svg': 'printer-xerox-phaser.svg',
    'printer-xerox.svg': 'printer-xerox.svg',
    'printer.svg': 'printer.svg',
    'projector-epson-frozen.svg': 'projector-epson-frozen.svg',
    'projector-epson.svg': 'projector-epson.svg',
    'projector-frozen.svg': 'projector-frozen.svg',
    'projector.svg': 'projector.svg',
    'rangeextender-arris-frozen.svg': 'rangeextender-arris-frozen.svg',
    'rangeextender-arris-gca2002-frozen.svg':
      'rangeextender-arris-gca2002-frozen.svg',
    'rangeextender-arris-gca2002.svg': 'rangeextender-arris-gca2002.svg',
    'rangeextender-arris-gpe1003-frozen.svg':
      'rangeextender-arris-gpe1003-frozen.svg',
    'rangeextender-arris-gpe1003.svg': 'rangeextender-arris-gpe1003.svg',
    'rangeextender-arris-gpe2001-frozen.svg':
      'rangeextender-arris-gpe2001-frozen.svg',
    'rangeextender-arris-gpe2001.svg': 'rangeextender-arris-gpe2001.svg',
    'rangeextender-arris.svg': 'rangeextender-arris.svg',
    'rangeextender-asus-n300repeater-frozen.svg':
      'rangeextender-asus-n300repeater-frozen.svg',
    'rangeextender-asus-n300repeater.svg':
      'rangeextender-asus-n300repeater.svg',
    'rangeextender-dlink-wifiextender-frozen.svg':
      'rangeextender-dlink-wifiextender-frozen.svg',
    'rangeextender-dlink-wifiextender.svg':
      'rangeextender-dlink-wifiextender.svg',
    'rangeextender-frozen.svg': 'rangeextender-frozen.svg',
    'rangeextender.svg': 'rangeextender.svg',
    'rangestove-frozen.svg': 'rangestove-frozen.svg',
    'rangestove.svg': 'rangestove.svg',
    'rings-charger-frozen.svg': 'rings-charger-frozen.svg',
    'rings-charger.svg': 'rings-charger.svg',
    'rings-frozen.svg': 'rings-frozen.svg',
    'rings.svg': 'rings.svg',
    'robotvacuumcleaner-360-frozen.svg': 'robotvacuumcleaner-360-frozen.svg',
    'robotvacuumcleaner-360-robotvacuumcleaner-frozen.svg':
      'robotvacuumcleaner-360-robotvacuumcleaner-frozen.svg',
    'robotvacuumcleaner-360-robotvacuumcleaner.svg':
      'robotvacuumcleaner-360-robotvacuumcleaner.svg',
    'robotvacuumcleaner-360.svg': 'robotvacuumcleaner-360.svg',
    'robotvacuumcleaner-bissell-frozen.svg':
      'robotvacuumcleaner-bissell-frozen.svg',
    'robotvacuumcleaner-bissell-robotvacuum-frozen.svg':
      'robotvacuumcleaner-bissell-robotvacuum-frozen.svg',
    'robotvacuumcleaner-bissell-robotvacuum.svg':
      'robotvacuumcleaner-bissell-robotvacuum.svg',
    'robotvacuumcleaner-bissell.svg': 'robotvacuumcleaner-bissell.svg',
    'robotvacuumcleaner-dyson-360-frozen.svg':
      'robotvacuumcleaner-dyson-360-frozen.svg',
    'robotvacuumcleaner-dyson-360.svg': 'robotvacuumcleaner-dyson-360.svg',
    'robotvacuumcleaner-dyson-frozen.svg':
      'robotvacuumcleaner-dyson-frozen.svg',
    'robotvacuumcleaner-dyson.svg': 'robotvacuumcleaner-dyson.svg',
    'robotvacuumcleaner-ecovacs-deebot-frozen.svg':
      'robotvacuumcleaner-ecovacs-deebot-frozen.svg',
    'robotvacuumcleaner-ecovacs-deebot.svg':
      'robotvacuumcleaner-ecovacs-deebot.svg',
    'robotvacuumcleaner-ecovacs-frozen.svg':
      'robotvacuumcleaner-ecovacs-frozen.svg',
    'robotvacuumcleaner-ecovacs.svg': 'robotvacuumcleaner-ecovacs.svg',
    'robotvacuumcleaner-electrolux-frozen.svg':
      'robotvacuumcleaner-electrolux-frozen.svg',
    'robotvacuumcleaner-electrolux-pure-frozen.svg':
      'robotvacuumcleaner-electrolux-pure-frozen.svg',
    'robotvacuumcleaner-electrolux-pure.svg':
      'robotvacuumcleaner-electrolux-pure.svg',
    'robotvacuumcleaner-electrolux.svg': 'robotvacuumcleaner-electrolux.svg',
    'robotvacuumcleaner-eufy-frozen.svg': 'robotvacuumcleaner-eufy-frozen.svg',
    'robotvacuumcleaner-eufy-robovac-frozen.svg':
      'robotvacuumcleaner-eufy-robovac-frozen.svg',
    'robotvacuumcleaner-eufy-robovac.svg':
      'robotvacuumcleaner-eufy-robovac.svg',
    'robotvacuumcleaner-eufy.svg': 'robotvacuumcleaner-eufy.svg',
    'robotvacuumcleaner-frozen.svg': 'robotvacuumcleaner-frozen.svg',
    'robotvacuumcleaner-irobot-frozen.svg':
      'robotvacuumcleaner-irobot-frozen.svg',
    'robotvacuumcleaner-irobot-roomba-frozen.svg':
      'robotvacuumcleaner-irobot-roomba-frozen.svg',
    'robotvacuumcleaner-irobot-roomba.svg':
      'robotvacuumcleaner-irobot-roomba.svg',
    'robotvacuumcleaner-irobot.svg': 'robotvacuumcleaner-irobot.svg',
    'robotvacuumcleaner-narwal-frozen.svg':
      'robotvacuumcleaner-narwal-frozen.svg',
    'robotvacuumcleaner-narwal-robotmop-frozen.svg':
      'robotvacuumcleaner-narwal-robotmop-frozen.svg',
    'robotvacuumcleaner-narwal-robotmop.svg':
      'robotvacuumcleaner-narwal-robotmop.svg',
    'robotvacuumcleaner-narwal.svg': 'robotvacuumcleaner-narwal.svg',
    'robotvacuumcleaner-neabot-frozen.svg':
      'robotvacuumcleaner-neabot-frozen.svg',
    'robotvacuumcleaner-neabot-robotvacuum-frozen.svg':
      'robotvacuumcleaner-neabot-robotvacuum-frozen.svg',
    'robotvacuumcleaner-neabot-robotvacuum.svg':
      'robotvacuumcleaner-neabot-robotvacuum.svg',
    'robotvacuumcleaner-neabot.svg': 'robotvacuumcleaner-neabot.svg',
    'robotvacuumcleaner-neato-botvac-frozen.svg':
      'robotvacuumcleaner-neato-botvac-frozen.svg',
    'robotvacuumcleaner-neato-botvac.svg':
      'robotvacuumcleaner-neato-botvac.svg',
    'robotvacuumcleaner-neato-frozen.svg':
      'robotvacuumcleaner-neato-frozen.svg',
    'robotvacuumcleaner-neato.svg': 'robotvacuumcleaner-neato.svg',
    'robotvacuumcleaner-plus360-frozen.svg':
      'robotvacuumcleaner-plus360-frozen.svg',
    'robotvacuumcleaner-plus360-s7-frozen.svg':
      'robotvacuumcleaner-plus360-s7-frozen.svg',
    'robotvacuumcleaner-plus360-s7.svg': 'robotvacuumcleaner-plus360-s7.svg',
    'robotvacuumcleaner-plus360.svg': 'robotvacuumcleaner-plus360.svg',
    'robotvacuumcleaner-samsung-frozen.svg':
      'robotvacuumcleaner-samsung-frozen.svg',
    'robotvacuumcleaner-samsung-powerbotvacuum-frozen.svg':
      'robotvacuumcleaner-samsung-powerbotvacuum-frozen.svg',
    'robotvacuumcleaner-samsung-powerbotvacuum.svg':
      'robotvacuumcleaner-samsung-powerbotvacuum.svg',
    'robotvacuumcleaner-samsung.svg': 'robotvacuumcleaner-samsung.svg',
    'robotvacuumcleaner-shark-frozen.svg':
      'robotvacuumcleaner-shark-frozen.svg',
    'robotvacuumcleaner-shark-robotvacuum-frozen.svg':
      'robotvacuumcleaner-shark-robotvacuum-frozen.svg',
    'robotvacuumcleaner-shark-robotvacuum.svg':
      'robotvacuumcleaner-shark-robotvacuum.svg',
    'robotvacuumcleaner-shark.svg': 'robotvacuumcleaner-shark.svg',
    'robotvacuumcleaner-viomi-frozen.svg':
      'robotvacuumcleaner-viomi-frozen.svg',
    'robotvacuumcleaner-viomi-robotvacuum-frozen.svg':
      'robotvacuumcleaner-viomi-robotvacuum-frozen.svg',
    'robotvacuumcleaner-viomi-robotvacuum.svg':
      'robotvacuumcleaner-viomi-robotvacuum.svg',
    'robotvacuumcleaner-viomi.svg': 'robotvacuumcleaner-viomi.svg',
    'robotvacuumcleaner-xiaomi-frozen.svg':
      'robotvacuumcleaner-xiaomi-frozen.svg',
    'robotvacuumcleaner-xiaomi-mi-frozen.svg':
      'robotvacuumcleaner-xiaomi-mi-frozen.svg',
    'robotvacuumcleaner-xiaomi-mi.svg': 'robotvacuumcleaner-xiaomi-mi.svg',
    'robotvacuumcleaner-xiaomi-roborock-frozen.svg':
      'robotvacuumcleaner-xiaomi-roborock-frozen.svg',
    'robotvacuumcleaner-xiaomi-roborock.svg':
      'robotvacuumcleaner-xiaomi-roborock.svg',
    'robotvacuumcleaner-xiaomi.svg': 'robotvacuumcleaner-xiaomi.svg',
    'robotvacuumcleaner.svg': 'robotvacuumcleaner.svg',
    'rotimatic-frozen.svg': 'rotimatic-frozen.svg',
    'rotimatic.svg': 'rotimatic.svg',
    'router-1.svg': 'router-1.svg',
    'router-814v6-frozen.svg': 'router-814v6-frozen.svg',
    'router-814v6.svg': 'router-814v6.svg',
    'router-actiontec-frozen.svg': 'router-actiontec-frozen.svg',
    'router-actiontec-web6000q-frozen.svg':
      'router-actiontec-web6000q-frozen.svg',
    'router-actiontec-web6000q.svg': 'router-actiontec-web6000q.svg',
    'router-actiontec.svg': 'router-actiontec.svg',
    'router-adtran-netvanta-frozen.svg': 'router-adtran-netvanta-frozen.svg',
    'router-adtran-netvanta.svg': 'router-adtran-netvanta.svg',
    'router-adtran-sdg814v6-frozen.svg': 'router-adtran-sdg814v6-frozen.svg',
    'router-adtran-sdg814v6.svg': 'router-adtran-sdg814v6.svg',
    'router-airties-frozen.svg': 'router-airties-frozen.svg',
    'router-airties.svg': 'router-airties.svg',
    'router-apple-airport-frozen.svg': 'router-apple-airport-frozen.svg',
    'router-apple-airport.svg': 'router-apple-airport.svg',
    'router-araknis-accesspoint-frozen.svg':
      'router-araknis-accesspoint-frozen.svg',
    'router-araknis-accesspoint.svg': 'router-araknis-accesspoint.svg',
    'router-araknis-networkswitch-frozen.svg':
      'router-araknis-networkswitch-frozen.svg',
    'router-araknis-networkswitch.svg': 'router-araknis-networkswitch.svg',
    'router-araknis-router-frozen.svg': 'router-araknis-router-frozen.svg',
    'router-araknis-router.svg': 'router-araknis-router.svg',
    'router-arris-gca2002-frozen.svg': 'router-arris-gca2002-frozen.svg',
    'router-arris-gca2002.svg': 'router-arris-gca2002.svg',
    'router-arris-gpe1003-frozen.svg': 'router-arris-gpe1003-frozen.svg',
    'router-arris-gpe1003.svg': 'router-arris-gpe1003.svg',
    'router-arris-router-frozen.svg': 'router-arris-router-frozen.svg',
    'router-arris-router.svg': 'router-arris-router.svg',
    'router-aruba-ap-frozen.svg': 'router-aruba-ap-frozen.svg',
    'router-aruba-ap.svg': 'router-aruba-ap.svg',
    'router-asus-frozen.svg': 'router-asus-frozen.svg',
    'router-asus-wirelessrouter-frozen.svg':
      'router-asus-wirelessrouter-frozen.svg',
    'router-asus-wirelessrouter.svg': 'router-asus-wirelessrouter.svg',
    'router-asus.svg': 'router-asus.svg',
    'router-att-uverse-frozen.svg': 'router-att-uverse-frozen.svg',
    'router-att-uverse.svg': 'router-att-uverse.svg',
    'router-avm-fritzbox-frozen.svg': 'router-avm-fritzbox-frozen.svg',
    'router-avm-fritzbox.svg': 'router-avm-fritzbox.svg',
    'router-belkin-frozen.svg': 'router-belkin-frozen.svg',
    'router-belkin.svg': 'router-belkin.svg',
    'router-bt-minihotspot-frozen.svg': 'router-bt-minihotspot-frozen.svg',
    'router-bt-minihotspot.svg': 'router-bt-minihotspot.svg',
    'router-cambium-cnpilot-frozen.svg': 'router-cambium-cnpilot-frozen.svg',
    'router-cambium-cnpilot.svg': 'router-cambium-cnpilot.svg',
    'router-cisco-encs-frozen.svg': 'router-cisco-encs-frozen.svg',
    'router-cisco-encs.svg': 'router-cisco-encs.svg',
    'router-cisco-meraki-frozen.svg': 'router-cisco-meraki-frozen.svg',
    'router-cisco-meraki.svg': 'router-cisco-meraki.svg',
    'router-cisco-rf-1.svg': 'router-cisco-rf-1.svg',
    'router-cisco-rf-2.svg': 'router-cisco-rf-2.svg',
    'router-cisco-rf-frozen-1.svg': 'router-cisco-rf-frozen-1.svg',
    'router-cisco-rf-frozen-2.svg': 'router-cisco-rf-frozen-2.svg',
    'router-cisco-rf-frozen.svg': 'router-cisco-rf-frozen.svg',
    'router-cisco-rf.svg': 'router-cisco-rf.svg',
    'router-creston-cenioir104-frozen.svg':
      'router-creston-cenioir104-frozen.svg',
    'router-creston-cenioir104.svg': 'router-creston-cenioir104.svg',
    'router-crestron-cengwexer-frozen.svg':
      'router-crestron-cengwexer-frozen.svg',
    'router-crestron-cengwexer.svg': 'router-crestron-cengwexer.svg',
    'router-dlink-dirx1560-frozen.svg': 'router-dlink-dirx1560-frozen.svg',
    'router-dlink-dirx1560.svg': 'router-dlink-dirx1560.svg',
    'router-dlink-frozen.svg': 'router-dlink-frozen.svg',
    'router-dlink-wirelesshomecloudrouter-frozen.svg':
      'router-dlink-wirelesshomecloudrouter-frozen.svg',
    'router-dlink-wirelesshomecloudrouter.svg':
      'router-dlink-wirelesshomecloudrouter.svg',
    'router-dlink-wirelessngigabitrouter-frozen.svg':
      'router-dlink-wirelessngigabitrouter-frozen.svg',
    'router-dlink-wirelessngigabitrouter.svg':
      'router-dlink-wirelessngigabitrouter.svg',
    'router-dlink-wirelessnrouter-frozen.svg':
      'router-dlink-wirelessnrouter-frozen.svg',
    'router-dlink-wirelessnrouter.svg': 'router-dlink-wirelessnrouter.svg',
    'router-dlink-xtremenduo-frozen.svg': 'router-dlink-xtremenduo-frozen.svg',
    'router-dlink-xtremenduo.svg': 'router-dlink-xtremenduo.svg',
    'router-dlink.svg': 'router-dlink.svg',
    'router-elecom-router-frozen.svg': 'router-elecom-router-frozen.svg',
    'router-elecom-router.svg': 'router-elecom-router.svg',
    'router-fios-quantumgateway-frozen.svg':
      'router-fios-quantumgateway-frozen.svg',
    'router-fios-quantumgateway.svg': 'router-fios-quantumgateway.svg',
    'router-frozen-1.svg': 'router-frozen-1.svg',
    'router-frozen.svg': 'router-frozen.svg',
    'router-glinet-iotgateway-frozen.svg':
      'router-glinet-iotgateway-frozen.svg',
    'router-glinet-iotgateway.svg': 'router-glinet-iotgateway.svg',
    'router-glinet-meshwifi-frozen.svg': 'router-glinet-meshwifi-frozen.svg',
    'router-glinet-meshwifi.svg': 'router-glinet-meshwifi.svg',
    'router-glinet-minirouter-frozen.svg':
      'router-glinet-minirouter-frozen.svg',
    'router-glinet-minirouter.svg': 'router-glinet-minirouter.svg',
    'router-glinet-travelrouter-frozen.svg':
      'router-glinet-travelrouter-frozen.svg',
    'router-glinet-travelrouter.svg': 'router-glinet-travelrouter.svg',
    'router-google-fiber-frozen.svg': 'router-google-fiber-frozen.svg',
    'router-google-fiber.svg': 'router-google-fiber.svg',
    'router-hitron-gateway-frozen.svg': 'router-hitron-gateway-frozen.svg',
    'router-hitron-gateway.svg': 'router-hitron-gateway.svg',
    'router-huawei-frozen.svg': 'router-huawei-frozen.svg',
    'router-huawei.svg': 'router-huawei.svg',
    'router-iodata-airport-frozen.svg': 'router-iodata-airport-frozen.svg',
    'router-iodata-airport.svg': 'router-iodata-airport.svg',
    'router-iodata-wifirouter-frozen.svg':
      'router-iodata-wifirouter-frozen.svg',
    'router-iodata-wifirouter.svg': 'router-iodata-wifirouter.svg',
    'router-legrand-gateway-frozen.svg': 'router-legrand-gateway-frozen.svg',
    'router-legrand-gateway.svg': 'router-legrand-gateway.svg',
    'router-linksys-ethernetbridge-frozen.svg':
      'router-linksys-ethernetbridge-frozen.svg',
    'router-linksys-ethernetbridge.svg': 'router-linksys-ethernetbridge.svg',
    'router-linksys-frozen.svg': 'router-linksys-frozen.svg',
    'router-linksys-mediaconnector-frozen.svg':
      'router-linksys-mediaconnector-frozen.svg',
    'router-linksys-mediaconnector.svg': 'router-linksys-mediaconnector.svg',
    'router-linksys-wifirouter-frozen.svg':
      'router-linksys-wifirouter-frozen.svg',
    'router-linksys-wifirouter.svg': 'router-linksys-wifirouter.svg',
    'router-linksys.svg': 'router-linksys.svg',
    'router-luma-frozen.svg': 'router-luma-frozen.svg',
    'router-luma.svg': 'router-luma.svg',
    'router-luxul-xap-frozen.svg': 'router-luxul-xap-frozen.svg',
    'router-luxul-xap.svg': 'router-luxul-xap.svg',
    'router-mikrotik-router-frozen.svg': 'router-mikrotik-router-frozen.svg',
    'router-mikrotik-router.svg': 'router-mikrotik-router.svg',
    'router-mimosa-a5-frozen.svg': 'router-mimosa-a5-frozen.svg',
    'router-mimosa-a5.svg': 'router-mimosa-a5.svg',
    'router-nec-aterm-frozen.svg': 'router-nec-aterm-frozen.svg',
    'router-nec-aterm.svg': 'router-nec-aterm.svg',
    'router-netgear-ac1200-frozen.svg': 'router-netgear-ac1200-frozen.svg',
    'router-netgear-ac1200.svg': 'router-netgear-ac1200.svg',
    'router-netgear-dm200-frozen.svg': 'router-netgear-dm200-frozen.svg',
    'router-netgear-dm200.svg': 'router-netgear-dm200.svg',
    'router-netgear-ethernetswitch-frozen.svg':
      'router-netgear-ethernetswitch-frozen.svg',
    'router-netgear-ethernetswitch.svg': 'router-netgear-ethernetswitch.svg',
    'router-netgear-frozen.svg': 'router-netgear-frozen.svg',
    'router-netgear-nighthawk-frozen.svg':
      'router-netgear-nighthawk-frozen.svg',
    'router-netgear-nighthawk.svg': 'router-netgear-nighthawk.svg',
    'router-netgear-nighthawkrouter-frozen.svg':
      'router-netgear-nighthawkrouter-frozen.svg',
    'router-netgear-nighthawkrouter.svg': 'router-netgear-nighthawkrouter.svg',
    'router-netgear-powerline-frozen.svg':
      'router-netgear-powerline-frozen.svg',
    'router-netgear-powerline.svg': 'router-netgear-powerline.svg',
    'router-netgear-r6250-frozen.svg': 'router-netgear-r6250-frozen.svg',
    'router-netgear-r6250.svg': 'router-netgear-r6250.svg',
    'router-netgear-r6300-frozen.svg': 'router-netgear-r6300-frozen.svg',
    'router-netgear-r6300.svg': 'router-netgear-r6300.svg',
    'router-netgear-rangemax-frozen.svg': 'router-netgear-rangemax-frozen.svg',
    'router-netgear-rangemax.svg': 'router-netgear-rangemax.svg',
    'router-netgear-router-frozen.svg': 'router-netgear-router-frozen.svg',
    'router-netgear-router.svg': 'router-netgear-router.svg',
    'router-netgear-wifirouter-frozen.svg':
      'router-netgear-wifirouter-frozen.svg',
    'router-netgear-wifirouter.svg': 'router-netgear-wifirouter.svg',
    'router-netgear-wirelessn-frozen.svg':
      'router-netgear-wirelessn-frozen.svg',
    'router-netgear-wirelessn.svg': 'router-netgear-wirelessn.svg',
    'router-netgear-wirelessrouter-frozen.svg':
      'router-netgear-wirelessrouter-frozen.svg',
    'router-netgear-wirelessrouter.svg': 'router-netgear-wirelessrouter.svg',
    'router-netgear-wnce3001-frozen.svg': 'router-netgear-wnce3001-frozen.svg',
    'router-netgear-wnce3001.svg': 'router-netgear-wnce3001.svg',
    'router-netgear.svg': 'router-netgear.svg',
    'router-netgearnighthawk-progamingrouter-frozen.svg':
      'router-netgearnighthawk-progamingrouter-frozen.svg',
    'router-netgearnighthawk-progamingrouter.svg':
      'router-netgearnighthawk-progamingrouter.svg',
    'router-netgearnighthawk-smartwifirouter-frozen.svg':
      'router-netgearnighthawk-smartwifirouter-frozen.svg',
    'router-netgearnighthawk-smartwifirouter.svg':
      'router-netgearnighthawk-smartwifirouter.svg',
    'router-netgearnighthawk-wifirouter-frozen.svg':
      'router-netgearnighthawk-wifirouter-frozen.svg',
    'router-netgearnighthawk-wifirouter.svg':
      'router-netgearnighthawk-wifirouter.svg',
    'router-netgearorbi-frozen.svg': 'router-netgearorbi-frozen.svg',
    'router-netgearorbi-satellite-frozen.svg':
      'router-netgearorbi-satellite-frozen.svg',
    'router-netgearorbi-satellite.svg': 'router-netgearorbi-satellite.svg',
    'router-netgearorbi-wallplug-frozen.svg':
      'router-netgearorbi-wallplug-frozen.svg',
    'router-netgearorbi-wallplug.svg': 'router-netgearorbi-wallplug.svg',
    'router-netgearorbi-wifisystem-frozen.svg':
      'router-netgearorbi-wifisystem-frozen.svg',
    'router-netgearorbi-wifisystem.svg': 'router-netgearorbi-wifisystem.svg',
    'router-netgearorbi.svg': 'router-netgearorbi.svg',
    'router-openwrt-frozen.svg': 'router-openwrt-frozen.svg',
    'router-openwrt.svg': 'router-openwrt.svg',
    'router-pakedge-rk1-frozen.svg': 'router-pakedge-rk1-frozen.svg',
    'router-pakedge-rk1.svg': 'router-pakedge-rk1.svg',
    'router-panasonic-accesspoint-frozen.svg':
      'router-panasonic-accesspoint-frozen.svg',
    'router-panasonic-accesspoint.svg': 'router-panasonic-accesspoint.svg',
    'router-peplink-max-frozen.svg': 'router-peplink-max-frozen.svg',
    'router-peplink-max.svg': 'router-peplink-max.svg',
    'router-peplink-surfsoho-frozen.svg': 'router-peplink-surfsoho-frozen.svg',
    'router-peplink-surfsoho.svg': 'router-peplink-surfsoho.svg',
    'router-planet-wirelessrouter-frozen.svg':
      'router-planet-wirelessrouter-frozen.svg',
    'router-planet-wirelessrouter.svg': 'router-planet-wirelessrouter.svg',
    'router-rockspace-router-frozen.svg': 'router-rockspace-router-frozen.svg',
    'router-rockspace-router.svg': 'router-rockspace-router.svg',
    'router-ruckus-switch-frozen.svg': 'router-ruckus-switch-frozen.svg',
    'router-ruckus-switch.svg': 'router-ruckus-switch.svg',
    'router-ruckus-unleashed-frozen.svg': 'router-ruckus-unleashed-frozen.svg',
    'router-ruckus-unleashed.svg': 'router-ruckus-unleashed.svg',
    'router-samsung-connecthome-frozen.svg':
      'router-samsung-connecthome-frozen.svg',
    'router-samsung-connecthome.svg': 'router-samsung-connecthome.svg',
    'router-smartrg-sr400ac-frozen.svg': 'router-smartrg-sr400ac-frozen.svg',
    'router-smartrg-sr400ac.svg': 'router-smartrg-sr400ac.svg',
    'router-synology-frozen.svg': 'router-synology-frozen.svg',
    'router-synology.svg': 'router-synology.svg',
    'router-tplink-accesspoint-frozen.svg':
      'router-tplink-accesspoint-frozen.svg',
    'router-tplink-accesspoint.svg': 'router-tplink-accesspoint.svg',
    'router-tplink-ap-frozen.svg': 'router-tplink-ap-frozen.svg',
    'router-tplink-ap.svg': 'router-tplink-ap.svg',
    'router-tplink-archer-frozen.svg': 'router-tplink-archer-frozen.svg',
    'router-tplink-archer.svg': 'router-tplink-archer.svg',
    'router-tplink-deco-frozen.svg': 'router-tplink-deco-frozen.svg',
    'router-tplink-deco.svg': 'router-tplink-deco.svg',
    'router-tplink-frozen.svg': 'router-tplink-frozen.svg',
    'router-tplink-tlwa801nd-frozen.svg': 'router-tplink-tlwa801nd-frozen.svg',
    'router-tplink-tlwa801nd.svg': 'router-tplink-tlwa801nd.svg',
    'router-tplink-tlwa901nd-frozen.svg': 'router-tplink-tlwa901nd-frozen.svg',
    'router-tplink-tlwa901nd.svg': 'router-tplink-tlwa901nd.svg',
    'router-tplink-tlwr802n-frozen.svg': 'router-tplink-tlwr802n-frozen.svg',
    'router-tplink-tlwr802n.svg': 'router-tplink-tlwr802n.svg',
    'router-tplink-tlwr841nd-frozen.svg': 'router-tplink-tlwr841nd-frozen.svg',
    'router-tplink-tlwr841nd.svg': 'router-tplink-tlwr841nd.svg',
    'router-tplink-tlwr940n-frozen.svg': 'router-tplink-tlwr940n-frozen.svg',
    'router-tplink-tlwr940n.svg': 'router-tplink-tlwr940n.svg',
    'router-tplink-touch-frozen.svg': 'router-tplink-touch-frozen.svg',
    'router-tplink-touch.svg': 'router-tplink-touch.svg',
    'router-tplink.svg': 'router-tplink.svg',
    'router-trendnet-tew-frozen.svg': 'router-trendnet-tew-frozen.svg',
    'router-trendnet-tew.svg': 'router-trendnet-tew.svg',
    'router-trendnet-wirelessrouter-frozen.svg':
      'router-trendnet-wirelessrouter-frozen.svg',
    'router-trendnet-wirelessrouter.svg': 'router-trendnet-wirelessrouter.svg',
    'router-ubiquiti-edgerouter-frozen.svg':
      'router-ubiquiti-edgerouter-frozen.svg',
    'router-ubiquiti-edgerouter.svg': 'router-ubiquiti-edgerouter.svg',
    'router-ubiquiti-nanostation-frozen.svg':
      'router-ubiquiti-nanostation-frozen.svg',
    'router-ubiquiti-nanostation.svg': 'router-ubiquiti-nanostation.svg',
    'router-ubiquiti-unifi-frozen.svg': 'router-ubiquiti-unifi-frozen.svg',
    'router-ubiquiti-unifi.svg': 'router-ubiquiti-unifi.svg',
    'router-ubiquiti-usw-frozen.svg': 'router-ubiquiti-usw-frozen.svg',
    'router-ubiquiti-usw.svg': 'router-ubiquiti-usw.svg',
    'router-verizon-fios-frozen.svg': 'router-verizon-fios-frozen.svg',
    'router-verizon-fios.svg': 'router-verizon-fios.svg',
    'router-viasat-surfbeam-frozen.svg': 'router-viasat-surfbeam-frozen.svg',
    'router-viasat-surfbeam.svg': 'router-viasat-surfbeam.svg',
    'router-wifi6-frozen.svg': 'router-wifi6-frozen.svg',
    'router-wifi6.svg': 'router-wifi6.svg',
    'router-wodesys-rangeextender-frozen.svg':
      'router-wodesys-rangeextender-frozen.svg',
    'router-wodesys-rangeextender.svg': 'router-wodesys-rangeextender.svg',
    'router-xiaomi-aiot-frozen.svg': 'router-xiaomi-aiot-frozen.svg',
    'router-xiaomi-aiot.svg': 'router-xiaomi-aiot.svg',
    'router-xiaomi-miwifi-frozen.svg': 'router-xiaomi-miwifi-frozen.svg',
    'router-xiaomi-miwifi.svg': 'router-xiaomi-miwifi.svg',
    'router-zyxel-emg2926-frozen.svg': 'router-zyxel-emg2926-frozen.svg',
    'router-zyxel-emg2926.svg': 'router-zyxel-emg2926.svg',
    'router-zyxel-frozen.svg': 'router-zyxel-frozen.svg',
    'router-zyxel.svg': 'router-zyxel.svg',
    'router.svg': 'router.svg',
    'scale-fitbit-wifismartscale-frozen.svg':
      'scale-fitbit-wifismartscale-frozen.svg',
    'scale-fitbit-wifismartscale.svg': 'scale-fitbit-wifismartscale.svg',
    'scale-frozen.svg': 'scale-frozen.svg',
    'scale-qardio-qardiobase2-frozen.svg':
      'scale-qardio-qardiobase2-frozen.svg',
    'scale-qardio-qardiobase2.svg': 'scale-qardio-qardiobase2.svg',
    'scale-shenzhen-smartscale-frozen.svg':
      'scale-shenzhen-smartscale-frozen.svg',
    'scale-shenzhen-smartscale.svg': 'scale-shenzhen-smartscale.svg',
    'scale-withings-body+-frozen.svg': 'scale-withings-body+-frozen.svg',
    'scale-withings-body+.svg': 'scale-withings-body+.svg',
    'scale-withings-body-frozen.svg': 'scale-withings-body-frozen.svg',
    'scale-withings-body.svg': 'scale-withings-body.svg',
    'scale-withings-bodycardio-frozen.svg':
      'scale-withings-bodycardio-frozen.svg',
    'scale-withings-bodycardio.svg': 'scale-withings-bodycardio.svg',
    'scale-withings-frozen.svg': 'scale-withings-frozen.svg',
    'scale-withings.svg': 'scale-withings.svg',
    'scale-yolanda-frozen.svg': 'scale-yolanda-frozen.svg',
    'scale-yolanda.svg': 'scale-yolanda.svg',
    'scale-yunmaimini-smartscale-frozen.svg':
      'scale-yunmaimini-smartscale-frozen.svg',
    'scale-yunmaimini-smartscale.svg': 'scale-yunmaimini-smartscale.svg',
    'scale.svg': 'scale.svg',
    'sciencekit-anki-vectorrobot-frozen.svg':
      'sciencekit-anki-vectorrobot-frozen.svg',
    'sciencekit-anki-vectorrobot.svg': 'sciencekit-anki-vectorrobot.svg',
    'sciencekit-frozen.svg': 'sciencekit-frozen.svg',
    'sciencekit.svg': 'sciencekit.svg',
    'sensor-belkin-wemo-frozen.svg': 'sensor-belkin-wemo-frozen.svg',
    'sensor-belkin-wemo.svg': 'sensor-belkin-wemo.svg',
    'sensor-connectedlifelabs-smartdry-frozen.svg':
      'sensor-connectedlifelabs-smartdry-frozen.svg',
    'sensor-connectedlifelabs-smartdry.svg':
      'sensor-connectedlifelabs-smartdry.svg',
    'sensor-dahua-aixvr-frozen.svg': 'sensor-dahua-aixvr-frozen.svg',
    'sensor-dahua-aixvr.svg': 'sensor-dahua-aixvr.svg',
    'sensor-dlink-motionsensor-frozen.svg':
      'sensor-dlink-motionsensor-frozen.svg',
    'sensor-dlink-motionsensor.svg': 'sensor-dlink-motionsensor.svg',
    'sensor-dlink-mydlink-frozen.svg': 'sensor-dlink-mydlink-frozen.svg',
    'sensor-dlink-mydlink.svg': 'sensor-dlink-mydlink.svg',
    'sensor-dlink-watersensor-1.svg': 'sensor-dlink-watersensor-1.svg',
    'sensor-dlink-watersensor-frozen-1.svg':
      'sensor-dlink-watersensor-frozen-1.svg',
    'sensor-dlink-watersensor-frozen.svg':
      'sensor-dlink-watersensor-frozen.svg',
    'sensor-dlink-watersensor.svg': 'sensor-dlink-watersensor.svg',
    'sensor-escort-max-frozen.svg': 'sensor-escort-max-frozen.svg',
    'sensor-escort-max.svg': 'sensor-escort-max.svg',
    'sensor-flo-watermonitor-frozen.svg': 'sensor-flo-watermonitor-frozen.svg',
    'sensor-flo-watermonitor.svg': 'sensor-flo-watermonitor.svg',
    'sensor-frozen.svg': 'sensor-frozen.svg',
    'sensor-idealsciences-thermostat-frozen.svg':
      'sensor-idealsciences-thermostat-frozen.svg',
    'sensor-idealsciences-thermostat.svg':
      'sensor-idealsciences-thermostat.svg',
    'sensor-kangaroo-motionsensor-frozen.svg':
      'sensor-kangaroo-motionsensor-frozen.svg',
    'sensor-kangaroo-motionsensor.svg': 'sensor-kangaroo-motionsensor.svg',
    'sensor-kinetico-leaksensor-frozen.svg':
      'sensor-kinetico-leaksensor-frozen.svg',
    'sensor-kinetico-leaksensor.svg': 'sensor-kinetico-leaksensor.svg',
    'sensor-leakalert-wifiwatersensor-frozen.svg':
      'sensor-leakalert-wifiwatersensor-frozen.svg',
    'sensor-leakalert-wifiwatersensor.svg':
      'sensor-leakalert-wifiwatersensor.svg',
    'sensor-leakbot-watersensor-frozen.svg':
      'sensor-leakbot-watersensor-frozen.svg',
    'sensor-leakbot-watersensor.svg': 'sensor-leakbot-watersensor.svg',
    'sensor-leaksmart-leakdetector-frozen.svg':
      'sensor-leaksmart-leakdetector-frozen.svg',
    'sensor-leaksmart-leakdetector.svg': 'sensor-leaksmart-leakdetector.svg',
    'sensor-leeo-frozen.svg': 'sensor-leeo-frozen.svg',
    'sensor-leeo-smartalert-frozen.svg': 'sensor-leeo-smartalert-frozen.svg',
    'sensor-leeo-smartalert.svg': 'sensor-leeo-smartalert.svg',
    'sensor-leeo.svg': 'sensor-leeo.svg',
    'sensor-levelsense-sumppump-frozen.svg':
      'sensor-levelsense-sumppump-frozen.svg',
    'sensor-levelsense-sumppump.svg': 'sensor-levelsense-sumppump.svg',
    'sensor-myfox-homealarm-frozen.svg': 'sensor-myfox-homealarm-frozen.svg',
    'sensor-myfox-homealarm.svg': 'sensor-myfox-homealarm.svg',
    'sensor-noiseaware-noisemonitor-frozen.svg':
      'sensor-noiseaware-noisemonitor-frozen.svg',
    'sensor-noiseaware-noisemonitor.svg': 'sensor-noiseaware-noisemonitor.svg',
    'sensor-purpleair-sensor-frozen.svg': 'sensor-purpleair-sensor-frozen.svg',
    'sensor-purpleair-sensor.svg': 'sensor-purpleair-sensor.svg',
    'sensor-roost-frozen.svg': 'sensor-roost-frozen.svg',
    'sensor-roost.svg': 'sensor-roost.svg',
    'sensor-shelly-doorsensor-frozen.svg':
      'sensor-shelly-doorsensor-frozen.svg',
    'sensor-shelly-doorsensor.svg': 'sensor-shelly-doorsensor.svg',
    'sensor-shelly-doorwindow-frozen.svg':
      'sensor-shelly-doorwindow-frozen.svg',
    'sensor-shelly-doorwindow.svg': 'sensor-shelly-doorwindow.svg',
    'sensor-shelly-floodsensor-frozen.svg':
      'sensor-shelly-floodsensor-frozen.svg',
    'sensor-shelly-floodsensor.svg': 'sensor-shelly-floodsensor.svg',
    'sensor-shelly-humiditysensor-frozen.svg':
      'sensor-shelly-humiditysensor-frozen.svg',
    'sensor-shelly-humiditysensor.svg': 'sensor-shelly-humiditysensor.svg',
    'sensor-shelly-ledlightstrip-frozen.svg':
      'sensor-shelly-ledlightstrip-frozen.svg',
    'sensor-shelly-ledlightstrip.svg': 'sensor-shelly-ledlightstrip.svg',
    'sensor-ting-smartsensor-frozen.svg': 'sensor-ting-smartsensor-frozen.svg',
    'sensor-ting-smartsensor.svg': 'sensor-ting-smartsensor.svg',
    'sensor.svg': 'sensor.svg',
    'settopbox-a95x-f1-frozen.svg': 'settopbox-a95x-f1-frozen.svg',
    'settopbox-a95x-f1.svg': 'settopbox-a95x-f1.svg',
    'settopbox-a95x-r2-frozen.svg': 'settopbox-a95x-r2-frozen.svg',
    'settopbox-a95x-r2.svg': 'settopbox-a95x-r2.svg',
    'settopbox-a98x-settopbox-frozen.svg':
      'settopbox-a98x-settopbox-frozen.svg',
    'settopbox-a98x-settopbox.svg': 'settopbox-a98x-settopbox.svg',
    'settopbox-abox-tvbox-frozen.svg': 'settopbox-abox-tvbox-frozen.svg',
    'settopbox-abox-tvbox.svg': 'settopbox-abox-tvbox.svg',
    'settopbox-adb-settopbox-frozen.svg': 'settopbox-adb-settopbox-frozen.svg',
    'settopbox-adb-settopbox.svg': 'settopbox-adb-settopbox.svg',
    'settopbox-airtel-xstreamstick-frozen.svg':
      'settopbox-airtel-xstreamstick-frozen.svg',
    'settopbox-airtel-xstreamstick.svg': 'settopbox-airtel-xstreamstick.svg',
    'settopbox-airtv-3-frozen.svg': 'settopbox-airtv-3-frozen.svg',
    'settopbox-airtv-3.svg': 'settopbox-airtv-3.svg',
    'settopbox-amazon-firetv-frozen.svg': 'settopbox-amazon-firetv-frozen.svg',
    'settopbox-amazon-firetv.svg': 'settopbox-amazon-firetv.svg',
    'settopbox-amiko-viper-frozen.svg': 'settopbox-amiko-viper-frozen.svg',
    'settopbox-amiko-viper.svg': 'settopbox-amiko-viper.svg',
    'settopbox-amino-amigo-frozen.svg': 'settopbox-amino-amigo-frozen.svg',
    'settopbox-amino-amigo.svg': 'settopbox-amino-amigo.svg',
    'settopbox-amino-aminet-frozen.svg': 'settopbox-amino-aminet-frozen.svg',
    'settopbox-amino-aminet.svg': 'settopbox-amino-aminet.svg',
    'settopbox-apple-tv-frozen.svg': 'settopbox-apple-tv-frozen.svg',
    'settopbox-apple-tv.svg': 'settopbox-apple-tv.svg',
    'settopbox-arris-eossettopbox-frozen.svg':
      'settopbox-arris-eossettopbox-frozen.svg',
    'settopbox-arris-eossettopbox.svg': 'settopbox-arris-eossettopbox.svg',
    'settopbox-arris-settopbox-frozen.svg':
      'settopbox-arris-settopbox-frozen.svg',
    'settopbox-arris-settopbox.svg': 'settopbox-arris-settopbox.svg',
    'settopbox-arris-videoaccesspoint-frozen.svg':
      'settopbox-arris-videoaccesspoint-frozen.svg',
    'settopbox-arris-videoaccesspoint.svg':
      'settopbox-arris-videoaccesspoint.svg',
    'settopbox-att-tv-frozen.svg': 'settopbox-att-tv-frozen.svg',
    'settopbox-att-tv.svg': 'settopbox-att-tv.svg',
    'settopbox-att-uversedvr-frozen.svg': 'settopbox-att-uversedvr-frozen.svg',
    'settopbox-att-uversedvr.svg': 'settopbox-att-uversedvr.svg',
    'settopbox-avermedia-avertv-frozen.svg':
      'settopbox-avermedia-avertv-frozen.svg',
    'settopbox-avermedia-avertv.svg': 'settopbox-avermedia-avertv.svg',
    'settopbox-avov-tvln2r-frozen.svg': 'settopbox-avov-tvln2r-frozen.svg',
    'settopbox-avov-tvln2r.svg': 'settopbox-avov-tvln2r.svg',
    'settopbox-beelink-gt1-frozen.svg': 'settopbox-beelink-gt1-frozen.svg',
    'settopbox-beelink-gt1.svg': 'settopbox-beelink-gt1.svg',
    'settopbox-bell-dvr-frozen.svg': 'settopbox-bell-dvr-frozen.svg',
    'settopbox-bell-dvr.svg': 'settopbox-bell-dvr.svg',
    'settopbox-bell-wholehomepvr-1.svg': 'settopbox-bell-wholehomepvr-1.svg',
    'settopbox-bell-wholehomepvr-frozen-1.svg':
      'settopbox-bell-wholehomepvr-frozen-1.svg',
    'settopbox-bell-wholehomepvr-frozen.svg':
      'settopbox-bell-wholehomepvr-frozen.svg',
    'settopbox-bell-wholehomepvr.svg': 'settopbox-bell-wholehomepvr.svg',
    'settopbox-caavo-controlcenter-frozen.svg':
      'settopbox-caavo-controlcenter-frozen.svg',
    'settopbox-caavo-controlcenter.svg': 'settopbox-caavo-controlcenter.svg',
    'settopbox-cisco-settopbox-frozen.svg':
      'settopbox-cisco-settopbox-frozen.svg',
    'settopbox-cisco-settopbox.svg': 'settopbox-cisco-settopbox.svg',
    'settopbox-comcast-xfinity-frozen.svg':
      'settopbox-comcast-xfinity-frozen.svg',
    'settopbox-comcast-xfinity.svg': 'settopbox-comcast-xfinity.svg',
    'settopbox-comcast-xi-frozen.svg': 'settopbox-comcast-xi-frozen.svg',
    'settopbox-comcast-xi.svg': 'settopbox-comcast-xi.svg',
    'settopbox-cristor-atlas-frozen.svg': 'settopbox-cristor-atlas-frozen.svg',
    'settopbox-cristor-atlas.svg': 'settopbox-cristor-atlas.svg',
    'settopbox-denon-avreceiver-frozen.svg':
      'settopbox-denon-avreceiver-frozen.svg',
    'settopbox-denon-avreceiver.svg': 'settopbox-denon-avreceiver.svg',
    'settopbox-deutschetelekom-frozen.svg':
      'settopbox-deutschetelekom-frozen.svg',
    'settopbox-deutschetelekom.svg': 'settopbox-deutschetelekom.svg',
    'settopbox-directv-geniehddvr-frozen.svg':
      'settopbox-directv-geniehddvr-frozen.svg',
    'settopbox-directv-geniehddvr.svg': 'settopbox-directv-geniehddvr.svg',
    'settopbox-dish-frozen.svg': 'settopbox-dish-frozen.svg',
    'settopbox-dish-hopper-frozen.svg': 'settopbox-dish-hopper-frozen.svg',
    'settopbox-dish-hopper.svg': 'settopbox-dish-hopper.svg',
    'settopbox-dish-joey-frozen.svg': 'settopbox-dish-joey-frozen.svg',
    'settopbox-dish-joey.svg': 'settopbox-dish-joey.svg',
    'settopbox-dish-joeyreceiver-frozen.svg':
      'settopbox-dish-joeyreceiver-frozen.svg',
    'settopbox-dish-joeyreceiver.svg': 'settopbox-dish-joeyreceiver.svg',
    'settopbox-dish-wally-frozen.svg': 'settopbox-dish-wally-frozen.svg',
    'settopbox-dish-wally.svg': 'settopbox-dish-wally.svg',
    'settopbox-dish-wirelessjoeyreceiver-frozen.svg':
      'settopbox-dish-wirelessjoeyreceiver-frozen.svg',
    'settopbox-dish-wirelessjoeyreceiver.svg':
      'settopbox-dish-wirelessjoeyreceiver.svg',
    'settopbox-dish.svg': 'settopbox-dish.svg',
    'settopbox-dlink-boxeebox-frozen.svg':
      'settopbox-dlink-boxeebox-frozen.svg',
    'settopbox-dlink-boxeebox.svg': 'settopbox-dlink-boxeebox.svg',
    'settopbox-dreambox-dm800se-frozen.svg':
      'settopbox-dreambox-dm800se-frozen.svg',
    'settopbox-dreambox-dm800se.svg': 'settopbox-dreambox-dm800se.svg',
    'settopbox-facebook-portaltv-frozen.svg':
      'settopbox-facebook-portaltv-frozen.svg',
    'settopbox-facebook-portaltv.svg': 'settopbox-facebook-portaltv.svg',
    'settopbox-formuler-f3-frozen.svg': 'settopbox-formuler-f3-frozen.svg',
    'settopbox-formuler-f3.svg': 'settopbox-formuler-f3.svg',
    'settopbox-formuler-f4turbo-frozen.svg':
      'settopbox-formuler-f4turbo-frozen.svg',
    'settopbox-formuler-f4turbo.svg': 'settopbox-formuler-f4turbo.svg',
    'settopbox-formuler-iptv-frozen.svg': 'settopbox-formuler-iptv-frozen.svg',
    'settopbox-formuler-iptv.svg': 'settopbox-formuler-iptv.svg',
    'settopbox-formuler-z-frozen.svg': 'settopbox-formuler-z-frozen.svg',
    'settopbox-formuler-z.svg': 'settopbox-formuler-z.svg',
    'settopbox-formuler-z8-frozen.svg': 'settopbox-formuler-z8-frozen.svg',
    'settopbox-formuler-z8.svg': 'settopbox-formuler-z8.svg',
    'settopbox-formuler-zx5g-frozen.svg': 'settopbox-formuler-zx5g-frozen.svg',
    'settopbox-formuler-zx5g.svg': 'settopbox-formuler-zx5g.svg',
    'settopbox-foxtel-box-frozen.svg': 'settopbox-foxtel-box-frozen.svg',
    'settopbox-foxtel-box.svg': 'settopbox-foxtel-box.svg',
    'settopbox-foxtel-iq3-frozen.svg': 'settopbox-foxtel-iq3-frozen.svg',
    'settopbox-foxtel-iq3.svg': 'settopbox-foxtel-iq3.svg',
    'settopbox-foxtel-iq4-frozen.svg': 'settopbox-foxtel-iq4-frozen.svg',
    'settopbox-foxtel-iq4.svg': 'settopbox-foxtel-iq4.svg',
    'settopbox-foxtel-nowbox-frozen.svg': 'settopbox-foxtel-nowbox-frozen.svg',
    'settopbox-foxtel-nowbox.svg': 'settopbox-foxtel-nowbox.svg',
    'settopbox-frozen.svg': 'settopbox-frozen.svg',
    'settopbox-goobangdoo-abox-frozen.svg':
      'settopbox-goobangdoo-abox-frozen.svg',
    'settopbox-goobangdoo-abox.svg': 'settopbox-goobangdoo-abox.svg',
    'settopbox-google-chromecast-frozen.svg':
      'settopbox-google-chromecast-frozen.svg',
    'settopbox-google-chromecast.svg': 'settopbox-google-chromecast.svg',
    'settopbox-google-nexus-frozen.svg': 'settopbox-google-nexus-frozen.svg',
    'settopbox-google-nexus.svg': 'settopbox-google-nexus.svg',
    'settopbox-gtmedia-frozen.svg': 'settopbox-gtmedia-frozen.svg',
    'settopbox-gtmedia.svg': 'settopbox-gtmedia.svg',
    'settopbox-h96-max-frozen.svg': 'settopbox-h96-max-frozen.svg',
    'settopbox-h96-max.svg': 'settopbox-h96-max.svg',
    'settopbox-himedia-hi3798mv100-frozen.svg':
      'settopbox-himedia-hi3798mv100-frozen.svg',
    'settopbox-himedia-hi3798mv100.svg': 'settopbox-himedia-hi3798mv100.svg',
    'settopbox-hisilicon-frozen.svg': 'settopbox-hisilicon-frozen.svg',
    'settopbox-hisilicon-q10-frozen.svg': 'settopbox-hisilicon-q10-frozen.svg',
    'settopbox-hisilicon-q10.svg': 'settopbox-hisilicon-q10.svg',
    'settopbox-hisilicon.svg': 'settopbox-hisilicon.svg',
    'settopbox-hke360-tvbox-frozen.svg': 'settopbox-hke360-tvbox-frozen.svg',
    'settopbox-hke360-tvbox.svg': 'settopbox-hke360-tvbox.svg',
    'settopbox-humax-settopbox-frozen.svg':
      'settopbox-humax-settopbox-frozen.svg',
    'settopbox-humax-settopbox.svg': 'settopbox-humax-settopbox.svg',
    'settopbox-infomir-frozen.svg': 'settopbox-infomir-frozen.svg',
    'settopbox-infomir.svg': 'settopbox-infomir.svg',
    'settopbox-jcom-link-frozen.svg': 'settopbox-jcom-link-frozen.svg',
    'settopbox-jcom-link.svg': 'settopbox-jcom-link.svg',
    'settopbox-kaon-settopbox-frozen.svg':
      'settopbox-kaon-settopbox-frozen.svg',
    'settopbox-kaon-settopbox.svg': 'settopbox-kaon-settopbox.svg',
    'settopbox-logitech-squeezeboxreceiver-frozen.svg':
      'settopbox-logitech-squeezeboxreceiver-frozen.svg',
    'settopbox-logitech-squeezeboxreceiver.svg':
      'settopbox-logitech-squeezeboxreceiver.svg',
    'settopbox-magenta-tvbox-frozen.svg': 'settopbox-magenta-tvbox-frozen.svg',
    'settopbox-magenta-tvbox.svg': 'settopbox-magenta-tvbox.svg',
    'settopbox-magictv-mtv9600d-frozen.svg':
      'settopbox-magictv-mtv9600d-frozen.svg',
    'settopbox-magictv-mtv9600d.svg': 'settopbox-magictv-mtv9600d.svg',
    'settopbox-marantz-avreceiver-frozen.svg':
      'settopbox-marantz-avreceiver-frozen.svg',
    'settopbox-marantz-avreceiver.svg': 'settopbox-marantz-avreceiver.svg',
    'settopbox-mecool-m8s-frozen.svg': 'settopbox-mecool-m8s-frozen.svg',
    'settopbox-mecool-m8s.svg': 'settopbox-mecool-m8s.svg',
    'settopbox-mecool-m8spro-frozen.svg': 'settopbox-mecool-m8spro-frozen.svg',
    'settopbox-mecool-m8spro.svg': 'settopbox-mecool-m8spro.svg',
    'settopbox-minix-neo-frozen.svg': 'settopbox-minix-neo-frozen.svg',
    'settopbox-minix-neo.svg': 'settopbox-minix-neo.svg',
    'settopbox-mxq-pro-frozen.svg': 'settopbox-mxq-pro-frozen.svg',
    'settopbox-mxq-pro.svg': 'settopbox-mxq-pro.svg',
    'settopbox-mytv-superbox-frozen.svg': 'settopbox-mytv-superbox-frozen.svg',
    'settopbox-mytv-superbox.svg': 'settopbox-mytv-superbox.svg',
    'settopbox-netgem-box-frozen.svg': 'settopbox-netgem-box-frozen.svg',
    'settopbox-netgem-box.svg': 'settopbox-netgem-box.svg',
    'settopbox-nowtv-rokusmartstick-frozen.svg':
      'settopbox-nowtv-rokusmartstick-frozen.svg',
    'settopbox-nowtv-rokusmartstick.svg': 'settopbox-nowtv-rokusmartstick.svg',
    'settopbox-nowtv-rokustb-frozen.svg': 'settopbox-nowtv-rokustb-frozen.svg',
    'settopbox-nowtv-rokustb.svg': 'settopbox-nowtv-rokustb.svg',
    'settopbox-nvidia-shieldtv-frozen.svg':
      'settopbox-nvidia-shieldtv-frozen.svg',
    'settopbox-nvidia-shieldtv.svg': 'settopbox-nvidia-shieldtv.svg',
    'settopbox-onkyo-avreceiver-frozen.svg':
      'settopbox-onkyo-avreceiver-frozen.svg',
    'settopbox-onkyo-avreceiver.svg': 'settopbox-onkyo-avreceiver.svg',
    'settopbox-optimum-alticeone-frozen.svg':
      'settopbox-optimum-alticeone-frozen.svg',
    'settopbox-optimum-alticeone.svg': 'settopbox-optimum-alticeone.svg',
    'settopbox-ott-box-frozen.svg': 'settopbox-ott-box-frozen.svg',
    'settopbox-ott-box.svg': 'settopbox-ott-box.svg',
    'settopbox-philips-huesyncbox-frozen.svg':
      'settopbox-philips-huesyncbox-frozen.svg',
    'settopbox-philips-huesyncbox.svg': 'settopbox-philips-huesyncbox.svg',
    'settopbox-pioneer-avreceiver-frozen.svg':
      'settopbox-pioneer-avreceiver-frozen.svg',
    'settopbox-pioneer-avreceiver.svg': 'settopbox-pioneer-avreceiver.svg',
    'settopbox-quickline-uhdbox-frozen.svg':
      'settopbox-quickline-uhdbox-frozen.svg',
    'settopbox-quickline-uhdbox.svg': 'settopbox-quickline-uhdbox.svg',
    'settopbox-realtek-atv-frozen.svg': 'settopbox-realtek-atv-frozen.svg',
    'settopbox-realtek-atv.svg': 'settopbox-realtek-atv.svg',
    'settopbox-roku-express+-frozen.svg': 'settopbox-roku-express+-frozen.svg',
    'settopbox-roku-express+.svg': 'settopbox-roku-express+.svg',
    'settopbox-roku-express-frozen.svg': 'settopbox-roku-express-frozen.svg',
    'settopbox-roku-express.svg': 'settopbox-roku-express.svg',
    'settopbox-roku-frozen.svg': 'settopbox-roku-frozen.svg',
    'settopbox-roku-hd-frozen.svg': 'settopbox-roku-hd-frozen.svg',
    'settopbox-roku-hd.svg': 'settopbox-roku-hd.svg',
    'settopbox-roku-lt-frozen.svg': 'settopbox-roku-lt-frozen.svg',
    'settopbox-roku-lt.svg': 'settopbox-roku-lt.svg',
    'settopbox-roku-player-frozen.svg': 'settopbox-roku-player-frozen.svg',
    'settopbox-roku-player.svg': 'settopbox-roku-player.svg',
    'settopbox-roku-premiere+-frozen.svg':
      'settopbox-roku-premiere+-frozen.svg',
    'settopbox-roku-premiere+.svg': 'settopbox-roku-premiere+.svg',
    'settopbox-roku-premiere-frozen.svg': 'settopbox-roku-premiere-frozen.svg',
    'settopbox-roku-premiere.svg': 'settopbox-roku-premiere.svg',
    'settopbox-roku-streamingstick+-frozen.svg':
      'settopbox-roku-streamingstick+-frozen.svg',
    'settopbox-roku-streamingstick+.svg': 'settopbox-roku-streamingstick+.svg',
    'settopbox-roku-streamingstick-frozen.svg':
      'settopbox-roku-streamingstick-frozen.svg',
    'settopbox-roku-streamingstick.svg': 'settopbox-roku-streamingstick.svg',
    'settopbox-roku-ultra-frozen.svg': 'settopbox-roku-ultra-frozen.svg',
    'settopbox-roku-ultra.svg': 'settopbox-roku-ultra.svg',
    'settopbox-roku.svg': 'settopbox-roku.svg',
    'settopbox-sagemcom-sunrise-frozen.svg':
      'settopbox-sagemcom-sunrise-frozen.svg',
    'settopbox-sagemcom-sunrise.svg': 'settopbox-sagemcom-sunrise.svg',
    'settopbox-samsung-frozen.svg': 'settopbox-samsung-frozen.svg',
    'settopbox-samsung.svg': 'settopbox-samsung.svg',
    'settopbox-sasktel-maxtvstream-frozen.svg':
      'settopbox-sasktel-maxtvstream-frozen.svg',
    'settopbox-sasktel-maxtvstream.svg': 'settopbox-sasktel-maxtvstream.svg',
    'settopbox-shadow-ghost-frozen.svg': 'settopbox-shadow-ghost-frozen.svg',
    'settopbox-shadow-ghost.svg': 'settopbox-shadow-ghost.svg',
    'settopbox-silicondust-hdhomerun-frozen.svg':
      'settopbox-silicondust-hdhomerun-frozen.svg',
    'settopbox-silicondust-hdhomerun.svg':
      'settopbox-silicondust-hdhomerun.svg',
    'settopbox-sky+-hdbox-frozen.svg': 'settopbox-sky+-hdbox-frozen.svg',
    'settopbox-sky+-hdbox.svg': 'settopbox-sky+-hdbox.svg',
    'settopbox-sky-hub-frozen.svg': 'settopbox-sky-hub-frozen.svg',
    'settopbox-sky-hub.svg': 'settopbox-sky-hub.svg',
    'settopbox-sky-qstb-frozen.svg': 'settopbox-sky-qstb-frozen.svg',
    'settopbox-sky-qstb.svg': 'settopbox-sky-qstb.svg',
    'settopbox-sling-airtvplayer-frozen.svg':
      'settopbox-sling-airtvplayer-frozen.svg',
    'settopbox-sling-airtvplayer.svg': 'settopbox-sling-airtvplayer.svg',
    'settopbox-slingbox-350-frozen.svg': 'settopbox-slingbox-350-frozen.svg',
    'settopbox-slingbox-350.svg': 'settopbox-slingbox-350.svg',
    'settopbox-slingbox-500-frozen.svg': 'settopbox-slingbox-500-frozen.svg',
    'settopbox-slingbox-500.svg': 'settopbox-slingbox-500.svg',
    'settopbox-slingbox-frozen.svg': 'settopbox-slingbox-frozen.svg',
    'settopbox-slingbox-m1-frozen.svg': 'settopbox-slingbox-m1-frozen.svg',
    'settopbox-slingbox-m1.svg': 'settopbox-slingbox-m1.svg',
    'settopbox-slingbox.svg': 'settopbox-slingbox.svg',
    'settopbox-swisscom-tvbox-frozen.svg':
      'settopbox-swisscom-tvbox-frozen.svg',
    'settopbox-swisscom-tvbox.svg': 'settopbox-swisscom-tvbox.svg',
    'settopbox-technicolor-frozen.svg': 'settopbox-technicolor-frozen.svg',
    'settopbox-technicolor-settopbox-frozen.svg':
      'settopbox-technicolor-settopbox-frozen.svg',
    'settopbox-technicolor-settopbox.svg':
      'settopbox-technicolor-settopbox.svg',
    'settopbox-technicolor.svg': 'settopbox-technicolor.svg',
    'settopbox-tivo-frozen.svg': 'settopbox-tivo-frozen.svg',
    'settopbox-tivo.svg': 'settopbox-tivo.svg',
    'settopbox-tx3-mini-frozen.svg': 'settopbox-tx3-mini-frozen.svg',
    'settopbox-tx3-mini.svg': 'settopbox-tx3-mini.svg',
    'settopbox-unblock-ubox4-frozen.svg': 'settopbox-unblock-ubox4-frozen.svg',
    'settopbox-unblock-ubox4.svg': 'settopbox-unblock-ubox4.svg',
    'settopbox-unblocktech-ubox6-frozen.svg':
      'settopbox-unblocktech-ubox6-frozen.svg',
    'settopbox-unblocktech-ubox6.svg': 'settopbox-unblocktech-ubox6.svg',
    'settopbox-upc-tvbox-frozen.svg': 'settopbox-upc-tvbox-frozen.svg',
    'settopbox-upc-tvbox.svg': 'settopbox-upc-tvbox.svg',
    'settopbox-valve-steamlink-frozen.svg':
      'settopbox-valve-steamlink-frozen.svg',
    'settopbox-valve-steamlink.svg': 'settopbox-valve-steamlink.svg',
    'settopbox-vestel-frozen.svg': 'settopbox-vestel-frozen.svg',
    'settopbox-vestel.svg': 'settopbox-vestel.svg',
    'settopbox-viark-combo-frozen.svg': 'settopbox-viark-combo-frozen.svg',
    'settopbox-viark-combo.svg': 'settopbox-viark-combo.svg',
    'settopbox-viark-lil-frozen.svg': 'settopbox-viark-lil-frozen.svg',
    'settopbox-viark-lil.svg': 'settopbox-viark-lil.svg',
    'settopbox-viark-sat-frozen.svg': 'settopbox-viark-sat-frozen.svg',
    'settopbox-viark-sat.svg': 'settopbox-viark-sat.svg',
    'settopbox-virgin-tvbox-frozen.svg': 'settopbox-virgin-tvbox-frozen.svg',
    'settopbox-virgin-tvbox.svg': 'settopbox-virgin-tvbox.svg',
    'settopbox-vodafone-frozen.svg': 'settopbox-vodafone-frozen.svg',
    'settopbox-vodafone-gigatv-frozen.svg':
      'settopbox-vodafone-gigatv-frozen.svg',
    'settopbox-vodafone-gigatv.svg': 'settopbox-vodafone-gigatv.svg',
    'settopbox-vodafone.svg': 'settopbox-vodafone.svg',
    'settopbox-voo-evasionbox-frozen.svg':
      'settopbox-voo-evasionbox-frozen.svg',
    'settopbox-voo-evasionbox.svg': 'settopbox-voo-evasionbox.svg',
    'settopbox-vuplus-solo-frozen.svg': 'settopbox-vuplus-solo-frozen.svg',
    'settopbox-vuplus-solo.svg': 'settopbox-vuplus-solo.svg',
    'settopbox-vuplus-zero-frozen.svg': 'settopbox-vuplus-zero-frozen.svg',
    'settopbox-vuplus-zero.svg': 'settopbox-vuplus-zero.svg',
    'settopbox-x92-tvbox-frozen.svg': 'settopbox-x92-tvbox-frozen.svg',
    'settopbox-x92-tvbox.svg': 'settopbox-x92-tvbox.svg',
    'settopbox-x96-q-frozen.svg': 'settopbox-x96-q-frozen.svg',
    'settopbox-x96-q.svg': 'settopbox-x96-q.svg',
    'settopbox-x96-tvbox-frozen.svg': 'settopbox-x96-tvbox-frozen.svg',
    'settopbox-x96-tvbox.svg': 'settopbox-x96-tvbox.svg',
    'settopbox-xiaomi-mibox-frozen.svg': 'settopbox-xiaomi-mibox-frozen.svg',
    'settopbox-xiaomi-mibox.svg': 'settopbox-xiaomi-mibox.svg',
    'settopbox-xiaomi-miboxtv-frozen.svg':
      'settopbox-xiaomi-miboxtv-frozen.svg',
    'settopbox-xiaomi-miboxtv.svg': 'settopbox-xiaomi-miboxtv.svg',
    'settopbox-yamaha-rxa830-frozen.svg': 'settopbox-yamaha-rxa830-frozen.svg',
    'settopbox-yamaha-rxa830.svg': 'settopbox-yamaha-rxa830.svg',
    'settopbox-yousee-frozen.svg': 'settopbox-yousee-frozen.svg',
    'settopbox-yousee.svg': 'settopbox-yousee.svg',
    'settopbox-zgemma-h2h-frozen.svg': 'settopbox-zgemma-h2h-frozen.svg',
    'settopbox-zgemma-h2h.svg': 'settopbox-zgemma-h2h.svg',
    'settopbox-zgemma-h9s-frozen.svg': 'settopbox-zgemma-h9s-frozen.svg',
    'settopbox-zgemma-h9s.svg': 'settopbox-zgemma-h9s.svg',
    'settopbox-zgemma-star-frozen.svg': 'settopbox-zgemma-star-frozen.svg',
    'settopbox-zgemma-star.svg': 'settopbox-zgemma-star.svg',
    'settopbox-ziggo-mediabox-frozen.svg':
      'settopbox-ziggo-mediabox-frozen.svg',
    'settopbox-ziggo-mediabox.svg': 'settopbox-ziggo-mediabox.svg',
    'settopbox.svg': 'settopbox.svg',
    'sleepsystem-1.svg': 'sleepsystem-1.svg',
    'sleepsystem-frozen-1.svg': 'sleepsystem-frozen-1.svg',
    'sleepsystem-frozen.svg': 'sleepsystem-frozen.svg',
    'sleepsystem.svg': 'sleepsystem.svg',
    'smartappliance-anovaculinary-frozen.svg':
      'smartappliance-anovaculinary-frozen.svg',
    'smartappliance-anovaculinary-precisioncooker-frozen.svg':
      'smartappliance-anovaculinary-precisioncooker-frozen.svg',
    'smartappliance-anovaculinary-precisioncooker.svg':
      'smartappliance-anovaculinary-precisioncooker.svg',
    'smartappliance-anovaculinary.svg': 'smartappliance-anovaculinary.svg',
    'smartappliance-frozen.svg': 'smartappliance-frozen.svg',
    'smartappliance-ge-frozen.svg': 'smartappliance-ge-frozen.svg',
    'smartappliance-ge.svg': 'smartappliance-ge.svg',
    'smartappliance.svg': 'smartappliance.svg',
    'smartbed-frozen.svg': 'smartbed-frozen.svg',
    'smartbed.svg': 'smartbed.svg',
    'smartdevice-amazon-dashbutton-frozen.svg':
      'smartdevice-amazon-dashbutton-frozen.svg',
    'smartdevice-amazon-dashbutton.svg': 'smartdevice-amazon-dashbutton.svg',
    'smartdevice-dyson-frozen.svg': 'smartdevice-dyson-frozen.svg',
    'smartdevice-dyson.svg': 'smartdevice-dyson.svg',
    'smartdevice-dyson2-frozen.svg': 'smartdevice-dyson2-frozen.svg',
    'smartdevice-dyson2.svg': 'smartdevice-dyson2.svg',
    'smartdevice-espressif-frozen.svg': 'smartdevice-espressif-frozen.svg',
    'smartdevice-espressif.svg': 'smartdevice-espressif.svg',
    'smartdevice-flir-frozen.svg': 'smartdevice-flir-frozen.svg',
    'smartdevice-flir.svg': 'smartdevice-flir.svg',
    'smartdevice-frozen.svg': 'smartdevice-frozen.svg',
    'smartdevice-funguypro-wateralarm-frozen.svg':
      'smartdevice-funguypro-wateralarm-frozen.svg',
    'smartdevice-funguypro-wateralarm.svg':
      'smartdevice-funguypro-wateralarm.svg',
    'smartdevice-idevices-frozen.svg': 'smartdevice-idevices-frozen.svg',
    'smartdevice-idevices.svg': 'smartdevice-idevices.svg',
    'smartdevice-ismart-smartsecurity-frozen.svg':
      'smartdevice-ismart-smartsecurity-frozen.svg',
    'smartdevice-ismart-smartsecurity.svg':
      'smartdevice-ismart-smartsecurity.svg',
    'smartdevice-konke-frozen.svg': 'smartdevice-konke-frozen.svg',
    'smartdevice-konke.svg': 'smartdevice-konke.svg',
    'smartdevice.svg': 'smartdevice.svg',
    'smartgrill.svg': 'smartgrill.svg',
    'smartgrilly-frozen.svg': 'smartgrilly-frozen.svg',
    'smartlaundry-frozen.svg': 'smartlaundry-frozen.svg',
    'smartlaundry.svg': 'smartlaundry.svg',
    'smartmonitor-ecowater-hydrolinkplus-frozen.svg':
      'smartmonitor-ecowater-hydrolinkplus-frozen.svg',
    'smartmonitor-ecowater-hydrolinkplus.svg':
      'smartmonitor-ecowater-hydrolinkplus.svg',
    'smartmonitor-emporia-vue-frozen.svg':
      'smartmonitor-emporia-vue-frozen.svg',
    'smartmonitor-emporia-vue.svg': 'smartmonitor-emporia-vue.svg',
    'smartmonitor-encored-enertalk-frozen.svg':
      'smartmonitor-encored-enertalk-frozen.svg',
    'smartmonitor-encored-enertalk.svg': 'smartmonitor-encored-enertalk.svg',
    'smartmonitor-energy-bridge2-frozen.svg':
      'smartmonitor-energy-bridge2-frozen.svg',
    'smartmonitor-energy-bridge2.svg': 'smartmonitor-energy-bridge2.svg',
    'smartmonitor-eyedro-electricitymonitor-frozen.svg':
      'smartmonitor-eyedro-electricitymonitor-frozen.svg',
    'smartmonitor-eyedro-electricitymonitor.svg':
      'smartmonitor-eyedro-electricitymonitor.svg',
    'smartmonitor-flume-smartwatermonitor-frozen.svg':
      'smartmonitor-flume-smartwatermonitor-frozen.svg',
    'smartmonitor-flume-smartwatermonitor.svg':
      'smartmonitor-flume-smartwatermonitor.svg',
    'smartmonitor-frozen.svg': 'smartmonitor-frozen.svg',
    'smartmonitor-guardian-valvecontroller-frozen.svg':
      'smartmonitor-guardian-valvecontroller-frozen.svg',
    'smartmonitor-guardian-valvecontroller.svg':
      'smartmonitor-guardian-valvecontroller.svg',
    'smartmonitor-ihome-frozen.svg': 'smartmonitor-ihome-frozen.svg',
    'smartmonitor-ihome.svg': 'smartmonitor-ihome.svg',
    'smartmonitor-lorex-homecenter-frozen.svg':
      'smartmonitor-lorex-homecenter-frozen.svg',
    'smartmonitor-lorex-homecenter.svg': 'smartmonitor-lorex-homecenter.svg',
    'smartmonitor-mystrom-wifibutton-frozen.svg':
      'smartmonitor-mystrom-wifibutton-frozen.svg',
    'smartmonitor-mystrom-wifibutton.svg':
      'smartmonitor-mystrom-wifibutton.svg',
    'smartmonitor-panasonic-aiseg-frozen.svg':
      'smartmonitor-panasonic-aiseg-frozen.svg',
    'smartmonitor-panasonic-aiseg.svg': 'smartmonitor-panasonic-aiseg.svg',
    'smartmonitor-phyn-watermonitor-frozen.svg':
      'smartmonitor-phyn-watermonitor-frozen.svg',
    'smartmonitor-phyn-watermonitor.svg': 'smartmonitor-phyn-watermonitor.svg',
    'smartmonitor-pumpspy-sumppump-frozen.svg':
      'smartmonitor-pumpspy-sumppump-frozen.svg',
    'smartmonitor-pumpspy-sumppump.svg': 'smartmonitor-pumpspy-sumppump.svg',
    'smartmonitor-qardio-1.svg': 'smartmonitor-qardio-1.svg',
    'smartmonitor-qardio-frozen-1.svg': 'smartmonitor-qardio-frozen-1.svg',
    'smartmonitor-qardio-frozen.svg': 'smartmonitor-qardio-frozen.svg',
    'smartmonitor-qardio.svg': 'smartmonitor-qardio.svg',
    'smartmonitor-roost-battery-frozen.svg':
      'smartmonitor-roost-battery-frozen.svg',
    'smartmonitor-roost-battery.svg': 'smartmonitor-roost-battery.svg',
    'smartmonitor-scram-smartdevice-frozen.svg':
      'smartmonitor-scram-smartdevice-frozen.svg',
    'smartmonitor-scram-smartdevice.svg': 'smartmonitor-scram-smartdevice.svg',
    'smartmonitor-sense-homeenergymonitor-frozen.svg':
      'smartmonitor-sense-homeenergymonitor-frozen.svg',
    'smartmonitor-sense-homeenergymonitor.svg':
      'smartmonitor-sense-homeenergymonitor.svg',
    'smartmonitor-shelly-3em-frozen.svg': 'smartmonitor-shelly-3em-frozen.svg',
    'smartmonitor-shelly-3em.svg': 'smartmonitor-shelly-3em.svg',
    'smartmonitor-tankutility-propanetankmonitor-frozen.svg':
      'smartmonitor-tankutility-propanetankmonitor-frozen.svg',
    'smartmonitor-tankutility-propanetankmonitor.svg':
      'smartmonitor-tankutility-propanetankmonitor.svg',
    'smartmonitor-victronenergy-colorcontrol-frozen.svg':
      'smartmonitor-victronenergy-colorcontrol-frozen.svg',
    'smartmonitor-victronenergy-colorcontrol.svg':
      'smartmonitor-victronenergy-colorcontrol.svg',
    'smartmonitor-wynd-halo-frozen.svg': 'smartmonitor-wynd-halo-frozen.svg',
    'smartmonitor-wynd-halo.svg': 'smartmonitor-wynd-halo.svg',
    'smartmonitor.svg': 'smartmonitor.svg',
    'smartphone-alcatel-1b-frozen.svg': 'smartphone-alcatel-1b-frozen.svg',
    'smartphone-alcatel-1b.svg': 'smartphone-alcatel-1b.svg',
    'smartphone-alcatel-1t-frozen.svg': 'smartphone-alcatel-1t-frozen.svg',
    'smartphone-alcatel-1t.svg': 'smartphone-alcatel-1t.svg',
    'smartphone-alcatel-1v-frozen.svg': 'smartphone-alcatel-1v-frozen.svg',
    'smartphone-alcatel-1v.svg': 'smartphone-alcatel-1v.svg',
    'smartphone-alcatel-1x-frozen.svg': 'smartphone-alcatel-1x-frozen.svg',
    'smartphone-alcatel-1x.svg': 'smartphone-alcatel-1x.svg',
    'smartphone-alcatel-1с-frozen.svg': 'smartphone-alcatel-1с-frozen.svg',
    'smartphone-alcatel-1с.svg': 'smartphone-alcatel-1с.svg',
    'smartphone-alcatel-5124j-frozen.svg':
      'smartphone-alcatel-5124j-frozen.svg',
    'smartphone-alcatel-5124j.svg': 'smartphone-alcatel-5124j.svg',
    'smartphone-alcatel-avalon-frozen.svg':
      'smartphone-alcatel-avalon-frozen.svg',
    'smartphone-alcatel-avalon.svg': 'smartphone-alcatel-avalon.svg',
    'smartphone-alcatel-go-frozen.svg': 'smartphone-alcatel-go-frozen.svg',
    'smartphone-alcatel-go.svg': 'smartphone-alcatel-go.svg',
    'smartphone-alcatel-onetouch-frozen.svg':
      'smartphone-alcatel-onetouch-frozen.svg',
    'smartphone-alcatel-onetouch.svg': 'smartphone-alcatel-onetouch.svg',
    'smartphone-alcatel-raven-frozen.svg':
      'smartphone-alcatel-raven-frozen.svg',
    'smartphone-alcatel-raven.svg': 'smartphone-alcatel-raven.svg',
    'smartphone-alcatel-tcl-frozen.svg': 'smartphone-alcatel-tcl-frozen.svg',
    'smartphone-alcatel-tcl.svg': 'smartphone-alcatel-tcl.svg',
    'smartphone-alcatel-x1-frozen.svg': 'smartphone-alcatel-x1-frozen.svg',
    'smartphone-alcatel-x1.svg': 'smartphone-alcatel-x1.svg',
    'smartphone-alcatel-zip-frozen.svg': 'smartphone-alcatel-zip-frozen.svg',
    'smartphone-alcatel-zip.svg': 'smartphone-alcatel-zip.svg',
    'smartphone-altice-s12-frozen.svg': 'smartphone-altice-s12-frozen.svg',
    'smartphone-altice-s12.svg': 'smartphone-altice-s12.svg',
    'smartphone-android-1.svg': 'smartphone-android-1.svg',
    'smartphone-android-frozen-1.svg': 'smartphone-android-frozen-1.svg',
    'smartphone-android-frozen.svg': 'smartphone-android-frozen.svg',
    'smartphone-android-l-frozen.svg': 'smartphone-android-l-frozen.svg',
    'smartphone-android-l.svg': 'smartphone-android-l.svg',
    'smartphone-android.svg': 'smartphone-android.svg',
    'smartphone-ans-ul40-frozen.svg': 'smartphone-ans-ul40-frozen.svg',
    'smartphone-ans-ul40.svg': 'smartphone-ans-ul40.svg',
    'smartphone-apple-iphone-frozen.svg': 'smartphone-apple-iphone-frozen.svg',
    'smartphone-apple-iphone-this-1.svg': 'smartphone-apple-iphone-this-1.svg',
    'smartphone-apple-iphone-this-frozen.svg':
      'smartphone-apple-iphone-this-frozen.svg',
    'smartphone-apple-iphone-this.svg': 'smartphone-apple-iphone-this.svg',
    'smartphone-apple-iphone.svg': 'smartphone-apple-iphone.svg',
    'smartphone-aquos-r-frozen.svg': 'smartphone-aquos-r-frozen.svg',
    'smartphone-aquos-r.svg': 'smartphone-aquos-r.svg',
    'smartphone-aquos-r2-frozen.svg': 'smartphone-aquos-r2-frozen.svg',
    'smartphone-aquos-r2.svg': 'smartphone-aquos-r2.svg',
    'smartphone-aquos-r3-frozen.svg': 'smartphone-aquos-r3-frozen.svg',
    'smartphone-aquos-r3.svg': 'smartphone-aquos-r3.svg',
    'smartphone-aquos-r5g-frozen.svg': 'smartphone-aquos-r5g-frozen.svg',
    'smartphone-aquos-r5g.svg': 'smartphone-aquos-r5g.svg',
    'smartphone-aquos-rcompact-frozen.svg':
      'smartphone-aquos-rcompact-frozen.svg',
    'smartphone-aquos-rcompact.svg': 'smartphone-aquos-rcompact.svg',
    'smartphone-aquos-sense-frozen.svg': 'smartphone-aquos-sense-frozen.svg',
    'smartphone-aquos-sense.svg': 'smartphone-aquos-sense.svg',
    'smartphone-aquos-sense2-frozen.svg': 'smartphone-aquos-sense2-frozen.svg',
    'smartphone-aquos-sense2.svg': 'smartphone-aquos-sense2.svg',
    'smartphone-aquos-sense3-frozen.svg': 'smartphone-aquos-sense3-frozen.svg',
    'smartphone-aquos-sense3.svg': 'smartphone-aquos-sense3.svg',
    'smartphone-aquos-sense3basic-frozen.svg':
      'smartphone-aquos-sense3basic-frozen.svg',
    'smartphone-aquos-sense3basic.svg': 'smartphone-aquos-sense3basic.svg',
    'smartphone-aquos-sense3plus-frozen.svg':
      'smartphone-aquos-sense3plus-frozen.svg',
    'smartphone-aquos-sense3plus.svg': 'smartphone-aquos-sense3plus.svg',
    'smartphone-aquos-serie-frozen.svg': 'smartphone-aquos-serie-frozen.svg',
    'smartphone-aquos-serie.svg': 'smartphone-aquos-serie.svg',
    'smartphone-aquos-seriemini-frozen.svg':
      'smartphone-aquos-seriemini-frozen.svg',
    'smartphone-aquos-seriemini.svg': 'smartphone-aquos-seriemini.svg',
    'smartphone-asus-rogphone-frozen.svg':
      'smartphone-asus-rogphone-frozen.svg',
    'smartphone-asus-rogphone.svg': 'smartphone-asus-rogphone.svg',
    'smartphone-asus-smartphone-frozen.svg':
      'smartphone-asus-smartphone-frozen.svg',
    'smartphone-asus-smartphone.svg': 'smartphone-asus-smartphone.svg',
    'smartphone-asus-zenfone-frozen.svg': 'smartphone-asus-zenfone-frozen.svg',
    'smartphone-asus-zenfone.svg': 'smartphone-asus-zenfone.svg',
    'smartphone-att-axia-frozen.svg': 'smartphone-att-axia-frozen.svg',
    'smartphone-att-axia.svg': 'smartphone-att-axia.svg',
    'smartphone-att-calypso-frozen.svg': 'smartphone-att-calypso-frozen.svg',
    'smartphone-att-calypso.svg': 'smartphone-att-calypso.svg',
    'smartphone-att-fusion-frozen.svg': 'smartphone-att-fusion-frozen.svg',
    'smartphone-att-fusion.svg': 'smartphone-att-fusion.svg',
    'smartphone-att-maestro-frozen.svg': 'smartphone-att-maestro-frozen.svg',
    'smartphone-att-maestro.svg': 'smartphone-att-maestro.svg',
    'smartphone-att-radiant-frozen.svg': 'smartphone-att-radiant-frozen.svg',
    'smartphone-att-radiant.svg': 'smartphone-att-radiant.svg',
    'smartphone-blackberry-smartphone-frozen.svg':
      'smartphone-blackberry-smartphone-frozen.svg',
    'smartphone-blackberry-smartphone.svg':
      'smartphone-blackberry-smartphone.svg',
    'smartphone-blackview-a60-frozen.svg':
      'smartphone-blackview-a60-frozen.svg',
    'smartphone-blackview-a60.svg': 'smartphone-blackview-a60.svg',
    'smartphone-blackview-a80-frozen.svg':
      'smartphone-blackview-a80-frozen.svg',
    'smartphone-blackview-a80.svg': 'smartphone-blackview-a80.svg',
    'smartphone-blackview-bv5500-frozen.svg':
      'smartphone-blackview-bv5500-frozen.svg',
    'smartphone-blackview-bv5500.svg': 'smartphone-blackview-bv5500.svg',
    'smartphone-blackview-bv5900-frozen.svg':
      'smartphone-blackview-bv5900-frozen.svg',
    'smartphone-blackview-bv5900.svg': 'smartphone-blackview-bv5900.svg',
    'smartphone-blu-frozen.svg': 'smartphone-blu-frozen.svg',
    'smartphone-blu-life-frozen.svg': 'smartphone-blu-life-frozen.svg',
    'smartphone-blu-life.svg': 'smartphone-blu-life.svg',
    'smartphone-blu-r1-frozen.svg': 'smartphone-blu-r1-frozen.svg',
    'smartphone-blu-r1.svg': 'smartphone-blu-r1.svg',
    'smartphone-blu-view-frozen.svg': 'smartphone-blu-view-frozen.svg',
    'smartphone-blu-view.svg': 'smartphone-blu-view.svg',
    'smartphone-blu.svg': 'smartphone-blu.svg',
    'smartphone-cat-s52-frozen.svg': 'smartphone-cat-s52-frozen.svg',
    'smartphone-cat-s52.svg': 'smartphone-cat-s52.svg',
    'smartphone-chinamobile-a3-frozen.svg':
      'smartphone-chinamobile-a3-frozen.svg',
    'smartphone-chinamobile-a3.svg': 'smartphone-chinamobile-a3.svg',
    'smartphone-coolpad-cool-frozen.svg': 'smartphone-coolpad-cool-frozen.svg',
    'smartphone-coolpad-cool.svg': 'smartphone-coolpad-cool.svg',
    'smartphone-coolpad-legacy-frozen.svg':
      'smartphone-coolpad-legacy-frozen.svg',
    'smartphone-coolpad-legacy.svg': 'smartphone-coolpad-legacy.svg',
    'smartphone-coolpad-quattro-frozen.svg':
      'smartphone-coolpad-quattro-frozen.svg',
    'smartphone-coolpad-quattro.svg': 'smartphone-coolpad-quattro.svg',
    'smartphone-cricket-coolpad-frozen.svg':
      'smartphone-cricket-coolpad-frozen.svg',
    'smartphone-cricket-coolpad.svg': 'smartphone-cricket-coolpad.svg',
    'smartphone-cricket-vision-frozen.svg':
      'smartphone-cricket-vision-frozen.svg',
    'smartphone-cricket-vision.svg': 'smartphone-cricket-vision.svg',
    'smartphone-cricket-wave-frozen.svg': 'smartphone-cricket-wave-frozen.svg',
    'smartphone-cricket-wave.svg': 'smartphone-cricket-wave.svg',
    'smartphone-cubot-j5-frozen.svg': 'smartphone-cubot-j5-frozen.svg',
    'smartphone-cubot-j5.svg': 'smartphone-cubot-j5.svg',
    'smartphone-cubot-kingkong-frozen.svg':
      'smartphone-cubot-kingkong-frozen.svg',
    'smartphone-cubot-kingkong.svg': 'smartphone-cubot-kingkong.svg',
    'smartphone-cubot-note-frozen.svg': 'smartphone-cubot-note-frozen.svg',
    'smartphone-cubot-note.svg': 'smartphone-cubot-note.svg',
    'smartphone-cubot-p30-frozen.svg': 'smartphone-cubot-p30-frozen.svg',
    'smartphone-cubot-p30.svg': 'smartphone-cubot-p30.svg',
    'smartphone-cubot-r15pro-frozen.svg': 'smartphone-cubot-r15pro-frozen.svg',
    'smartphone-cubot-r15pro.svg': 'smartphone-cubot-r15pro.svg',
    'smartphone-cubot-x19-frozen.svg': 'smartphone-cubot-x19-frozen.svg',
    'smartphone-cubot-x19.svg': 'smartphone-cubot-x19.svg',
    'smartphone-cubot-x30-frozen.svg': 'smartphone-cubot-x30-frozen.svg',
    'smartphone-cubot-x30.svg': 'smartphone-cubot-x30.svg',
    'smartphone-digicel-dl3-frozen.svg': 'smartphone-digicel-dl3-frozen.svg',
    'smartphone-digicel-dl3.svg': 'smartphone-digicel-dl3.svg',
    'smartphone-digicel-dl501-frozen.svg':
      'smartphone-digicel-dl501-frozen.svg',
    'smartphone-digicel-dl501.svg': 'smartphone-digicel-dl501.svg',
    'smartphone-doogee-n20-frozen.svg': 'smartphone-doogee-n20-frozen.svg',
    'smartphone-doogee-n20.svg': 'smartphone-doogee-n20.svg',
    'smartphone-evercoss-a75b-frozen.svg':
      'smartphone-evercoss-a75b-frozen.svg',
    'smartphone-evercoss-a75b.svg': 'smartphone-evercoss-a75b.svg',
    'smartphone-foxxd-miro-frozen.svg': 'smartphone-foxxd-miro-frozen.svg',
    'smartphone-foxxd-miro.svg': 'smartphone-foxxd-miro.svg',
    'smartphone-freetel-raijin-frozen.svg':
      'smartphone-freetel-raijin-frozen.svg',
    'smartphone-freetel-raijin.svg': 'smartphone-freetel-raijin.svg',
    'smartphone-freetel-samurai-frozen.svg':
      'smartphone-freetel-samurai-frozen.svg',
    'smartphone-freetel-samurai.svg': 'smartphone-freetel-samurai.svg',
    'smartphone-frozen.svg': 'smartphone-frozen.svg',
    'smartphone-fujitsu-arrows-frozen.svg':
      'smartphone-fujitsu-arrows-frozen.svg',
    'smartphone-fujitsu-arrows.svg': 'smartphone-fujitsu-arrows.svg',
    'smartphone-fujitsu-easyphone-frozen.svg':
      'smartphone-fujitsu-easyphone-frozen.svg',
    'smartphone-fujitsu-easyphone.svg': 'smartphone-fujitsu-easyphone.svg',
    'smartphone-fujitsu-f04j-frozen.svg': 'smartphone-fujitsu-f04j-frozen.svg',
    'smartphone-fujitsu-f04j.svg': 'smartphone-fujitsu-f04j.svg',
    'smartphone-fujitsu-smartphone-frozen.svg':
      'smartphone-fujitsu-smartphone-frozen.svg',
    'smartphone-fujitsu-smartphone.svg': 'smartphone-fujitsu-smartphone.svg',
    'smartphone-google-nexus-frozen.svg': 'smartphone-google-nexus-frozen.svg',
    'smartphone-google-nexus.svg': 'smartphone-google-nexus.svg',
    'smartphone-google-pixel-frozen.svg': 'smartphone-google-pixel-frozen.svg',
    'smartphone-google-pixel.svg': 'smartphone-google-pixel.svg',
    'smartphone-honeywell-scanpal-frozen.svg':
      'smartphone-honeywell-scanpal-frozen.svg',
    'smartphone-honeywell-scanpal.svg': 'smartphone-honeywell-scanpal.svg',
    'smartphone-hotpepper-poblano-frozen.svg':
      'smartphone-hotpepper-poblano-frozen.svg',
    'smartphone-hotpepper-poblano.svg': 'smartphone-hotpepper-poblano.svg',
    'smartphone-htc-10-frozen.svg': 'smartphone-htc-10-frozen.svg',
    'smartphone-htc-10.svg': 'smartphone-htc-10.svg',
    'smartphone-htc-butterfly-frozen.svg':
      'smartphone-htc-butterfly-frozen.svg',
    'smartphone-htc-butterfly.svg': 'smartphone-htc-butterfly.svg',
    'smartphone-htc-j-frozen.svg': 'smartphone-htc-j-frozen.svg',
    'smartphone-htc-j.svg': 'smartphone-htc-j.svg',
    'smartphone-htc-one-frozen.svg': 'smartphone-htc-one-frozen.svg',
    'smartphone-htc-one.svg': 'smartphone-htc-one.svg',
    'smartphone-htc-u11-frozen.svg': 'smartphone-htc-u11-frozen.svg',
    'smartphone-htc-u11.svg': 'smartphone-htc-u11.svg',
    'smartphone-huawei-ascend-frozen.svg':
      'smartphone-huawei-ascend-frozen.svg',
    'smartphone-huawei-ascend.svg': 'smartphone-huawei-ascend.svg',
    'smartphone-huawei-ascendxt-frozen.svg':
      'smartphone-huawei-ascendxt-frozen.svg',
    'smartphone-huawei-ascendxt.svg': 'smartphone-huawei-ascendxt.svg',
    'smartphone-huawei-enjoy-frozen.svg': 'smartphone-huawei-enjoy-frozen.svg',
    'smartphone-huawei-enjoy.svg': 'smartphone-huawei-enjoy.svg',
    'smartphone-huawei-frozen.svg': 'smartphone-huawei-frozen.svg',
    'smartphone-huawei-h1621-frozen.svg': 'smartphone-huawei-h1621-frozen.svg',
    'smartphone-huawei-h1621.svg': 'smartphone-huawei-h1621.svg',
    'smartphone-huawei-honor-frozen.svg': 'smartphone-huawei-honor-frozen.svg',
    'smartphone-huawei-honor.svg': 'smartphone-huawei-honor.svg',
    'smartphone-huawei-maimang-frozen.svg':
      'smartphone-huawei-maimang-frozen.svg',
    'smartphone-huawei-maimang.svg': 'smartphone-huawei-maimang.svg',
    'smartphone-huawei-mate-frozen.svg': 'smartphone-huawei-mate-frozen.svg',
    'smartphone-huawei-mate.svg': 'smartphone-huawei-mate.svg',
    'smartphone-huawei-mate30-frozen.svg':
      'smartphone-huawei-mate30-frozen.svg',
    'smartphone-huawei-mate30.svg': 'smartphone-huawei-mate30.svg',
    'smartphone-huawei-mate40-frozen.svg':
      'smartphone-huawei-mate40-frozen.svg',
    'smartphone-huawei-mate40.svg': 'smartphone-huawei-mate40.svg',
    'smartphone-huawei-nova-frozen.svg': 'smartphone-huawei-nova-frozen.svg',
    'smartphone-huawei-nova.svg': 'smartphone-huawei-nova.svg',
    'smartphone-huawei-p10-frozen.svg': 'smartphone-huawei-p10-frozen.svg',
    'smartphone-huawei-p10.svg': 'smartphone-huawei-p10.svg',
    'smartphone-huawei-p20-frozen.svg': 'smartphone-huawei-p20-frozen.svg',
    'smartphone-huawei-p20.svg': 'smartphone-huawei-p20.svg',
    'smartphone-huawei-p30-frozen.svg': 'smartphone-huawei-p30-frozen.svg',
    'smartphone-huawei-p30.svg': 'smartphone-huawei-p30.svg',
    'smartphone-huawei-p8-1.svg': 'smartphone-huawei-p8-1.svg',
    'smartphone-huawei-p8-frozen-1.svg': 'smartphone-huawei-p8-frozen-1.svg',
    'smartphone-huawei-p8-frozen.svg': 'smartphone-huawei-p8-frozen.svg',
    'smartphone-huawei-p8.svg': 'smartphone-huawei-p8.svg',
    'smartphone-huawei-p9-frozen.svg': 'smartphone-huawei-p9-frozen.svg',
    'smartphone-huawei-p9.svg': 'smartphone-huawei-p9.svg',
    'smartphone-huawei-sensa-frozen.svg': 'smartphone-huawei-sensa-frozen.svg',
    'smartphone-huawei-sensa.svg': 'smartphone-huawei-sensa.svg',
    'smartphone-huawei-y6-frozen.svg': 'smartphone-huawei-y6-frozen.svg',
    'smartphone-huawei-y6.svg': 'smartphone-huawei-y6.svg',
    'smartphone-huawei-y9-frozen.svg': 'smartphone-huawei-y9-frozen.svg',
    'smartphone-huawei-y9.svg': 'smartphone-huawei-y9.svg',
    'smartphone-huawei.svg': 'smartphone-huawei.svg',
    'smartphone-kazuna-etalk-frozen.svg': 'smartphone-kazuna-etalk-frozen.svg',
    'smartphone-kazuna-etalk.svg': 'smartphone-kazuna-etalk.svg',
    'smartphone-kyocera-1.svg': 'smartphone-kyocera-1.svg',
    'smartphone-kyocera-androidone-frozen.svg':
      'smartphone-kyocera-androidone-frozen.svg',
    'smartphone-kyocera-androidone.svg': 'smartphone-kyocera-androidone.svg',
    'smartphone-kyocera-basio-frozen.svg':
      'smartphone-kyocera-basio-frozen.svg',
    'smartphone-kyocera-basio.svg': 'smartphone-kyocera-basio.svg',
    'smartphone-kyocera-basio3-frozen.svg':
      'smartphone-kyocera-basio3-frozen.svg',
    'smartphone-kyocera-basio3.svg': 'smartphone-kyocera-basio3.svg',
    'smartphone-kyocera-basio4-frozen.svg':
      'smartphone-kyocera-basio4-frozen.svg',
    'smartphone-kyocera-basio4.svg': 'smartphone-kyocera-basio4.svg',
    'smartphone-kyocera-cadence-frozen.svg':
      'smartphone-kyocera-cadence-frozen.svg',
    'smartphone-kyocera-cadence.svg': 'smartphone-kyocera-cadence.svg',
    'smartphone-kyocera-digno-frozen.svg':
      'smartphone-kyocera-digno-frozen.svg',
    'smartphone-kyocera-digno.svg': 'smartphone-kyocera-digno.svg',
    'smartphone-kyocera-dignorafre-frozen.svg':
      'smartphone-kyocera-dignorafre-frozen.svg',
    'smartphone-kyocera-dignorafre.svg': 'smartphone-kyocera-dignorafre.svg',
    'smartphone-kyocera-duraxv-1.svg': 'smartphone-kyocera-duraxv-1.svg',
    'smartphone-kyocera-duraxv-frozen-1.svg':
      'smartphone-kyocera-duraxv-frozen-1.svg',
    'smartphone-kyocera-duraxv-frozen.svg':
      'smartphone-kyocera-duraxv-frozen.svg',
    'smartphone-kyocera-duraxv.svg': 'smartphone-kyocera-duraxv.svg',
    'smartphone-kyocera-frozen-1.svg': 'smartphone-kyocera-frozen-1.svg',
    'smartphone-kyocera-frozen.svg': 'smartphone-kyocera-frozen.svg',
    'smartphone-kyocera-gratina-frozen.svg':
      'smartphone-kyocera-gratina-frozen.svg',
    'smartphone-kyocera-gratina.svg': 'smartphone-kyocera-gratina.svg',
    'smartphone-kyocera-gratina4g-frozen.svg':
      'smartphone-kyocera-gratina4g-frozen.svg',
    'smartphone-kyocera-gratina4g.svg': 'smartphone-kyocera-gratina4g.svg',
    'smartphone-kyocera-infobar-frozen.svg':
      'smartphone-kyocera-infobar-frozen.svg',
    'smartphone-kyocera-infobar.svg': 'smartphone-kyocera-infobar.svg',
    'smartphone-kyocera-kantan-frozen.svg':
      'smartphone-kyocera-kantan-frozen.svg',
    'smartphone-kyocera-kantan.svg': 'smartphone-kyocera-kantan.svg',
    'smartphone-kyocera-marvera-frozen.svg':
      'smartphone-kyocera-marvera-frozen.svg',
    'smartphone-kyocera-marvera.svg': 'smartphone-kyocera-marvera.svg',
    'smartphone-kyocera-miraie-frozen.svg':
      'smartphone-kyocera-miraie-frozen.svg',
    'smartphone-kyocera-miraie.svg': 'smartphone-kyocera-miraie.svg',
    'smartphone-kyocera-qua-frozen.svg': 'smartphone-kyocera-qua-frozen.svg',
    'smartphone-kyocera-qua.svg': 'smartphone-kyocera-qua.svg',
    'smartphone-kyocera-quaphone-frozen.svg':
      'smartphone-kyocera-quaphone-frozen.svg',
    'smartphone-kyocera-quaphone.svg': 'smartphone-kyocera-quaphone.svg',
    'smartphone-kyocera-rafre-frozen.svg':
      'smartphone-kyocera-rafre-frozen.svg',
    'smartphone-kyocera-rafre.svg': 'smartphone-kyocera-rafre.svg',
    'smartphone-kyocera-torque-frozen.svg':
      'smartphone-kyocera-torque-frozen.svg',
    'smartphone-kyocera-torque.svg': 'smartphone-kyocera-torque.svg',
    'smartphone-kyocera-urbano-frozen.svg':
      'smartphone-kyocera-urbano-frozen.svg',
    'smartphone-kyocera-urbano.svg': 'smartphone-kyocera-urbano.svg',
    'smartphone-kyocera.svg': 'smartphone-kyocera.svg',
    'smartphone-lenovo-701lv-1.svg': 'smartphone-lenovo-701lv-1.svg',
    'smartphone-lenovo-701lv-frozen-1.svg':
      'smartphone-lenovo-701lv-frozen-1.svg',
    'smartphone-lenovo-701lv-frozen.svg': 'smartphone-lenovo-701lv-frozen.svg',
    'smartphone-lenovo-701lv.svg': 'smartphone-lenovo-701lv.svg',
    'smartphone-letv-leeco-frozen.svg': 'smartphone-letv-leeco-frozen.svg',
    'smartphone-letv-leeco.svg': 'smartphone-letv-leeco.svg',
    'smartphone-lg-arena-frozen.svg': 'smartphone-lg-arena-frozen.svg',
    'smartphone-lg-arena.svg': 'smartphone-lg-arena.svg',
    'smartphone-lg-aristo-frozen.svg': 'smartphone-lg-aristo-frozen.svg',
    'smartphone-lg-aristo.svg': 'smartphone-lg-aristo.svg',
    'smartphone-lg-d160-frozen.svg': 'smartphone-lg-d160-frozen.svg',
    'smartphone-lg-d160.svg': 'smartphone-lg-d160.svg',
    'smartphone-lg-escape-frozen.svg': 'smartphone-lg-escape-frozen.svg',
    'smartphone-lg-escape.svg': 'smartphone-lg-escape.svg',
    'smartphone-lg-exalt-frozen.svg': 'smartphone-lg-exalt-frozen.svg',
    'smartphone-lg-exalt.svg': 'smartphone-lg-exalt.svg',
    'smartphone-lg-fortune-frozen.svg': 'smartphone-lg-fortune-frozen.svg',
    'smartphone-lg-fortune.svg': 'smartphone-lg-fortune.svg',
    'smartphone-lg-g6-frozen.svg': 'smartphone-lg-g6-frozen.svg',
    'smartphone-lg-g6.svg': 'smartphone-lg-g6.svg',
    'smartphone-lg-g7-frozen.svg': 'smartphone-lg-g7-frozen.svg',
    'smartphone-lg-g7.svg': 'smartphone-lg-g7.svg',
    'smartphone-lg-g8-frozen.svg': 'smartphone-lg-g8-frozen.svg',
    'smartphone-lg-g8.svg': 'smartphone-lg-g8.svg',
    'smartphone-lg-g8thinq-frozen.svg': 'smartphone-lg-g8thinq-frozen.svg',
    'smartphone-lg-g8thinq.svg': 'smartphone-lg-g8thinq.svg',
    'smartphone-lg-g8x-frozen.svg': 'smartphone-lg-g8x-frozen.svg',
    'smartphone-lg-g8x.svg': 'smartphone-lg-g8x.svg',
    'smartphone-lg-harmony-frozen.svg': 'smartphone-lg-harmony-frozen.svg',
    'smartphone-lg-harmony.svg': 'smartphone-lg-harmony.svg',
    'smartphone-lg-it-frozen.svg': 'smartphone-lg-it-frozen.svg',
    'smartphone-lg-it.svg': 'smartphone-lg-it.svg',
    'smartphone-lg-journey-frozen.svg': 'smartphone-lg-journey-frozen.svg',
    'smartphone-lg-journey.svg': 'smartphone-lg-journey.svg',
    'smartphone-lg-k10-frozen.svg': 'smartphone-lg-k10-frozen.svg',
    'smartphone-lg-k10.svg': 'smartphone-lg-k10.svg',
    'smartphone-lg-k22-1.svg': 'smartphone-lg-k22-1.svg',
    'smartphone-lg-k22-frozen-1.svg': 'smartphone-lg-k22-frozen-1.svg',
    'smartphone-lg-k22-frozen.svg': 'smartphone-lg-k22-frozen.svg',
    'smartphone-lg-k22.svg': 'smartphone-lg-k22.svg',
    'smartphone-lg-k30-frozen.svg': 'smartphone-lg-k30-frozen.svg',
    'smartphone-lg-k30.svg': 'smartphone-lg-k30.svg',
    'smartphone-lg-k31-frozen.svg': 'smartphone-lg-k31-frozen.svg',
    'smartphone-lg-k31.svg': 'smartphone-lg-k31.svg',
    'smartphone-lg-k40-frozen.svg': 'smartphone-lg-k40-frozen.svg',
    'smartphone-lg-k40.svg': 'smartphone-lg-k40.svg',
    'smartphone-lg-k40s-frozen.svg': 'smartphone-lg-k40s-frozen.svg',
    'smartphone-lg-k40s.svg': 'smartphone-lg-k40s.svg',
    'smartphone-lg-k41s-frozen.svg': 'smartphone-lg-k41s-frozen.svg',
    'smartphone-lg-k41s.svg': 'smartphone-lg-k41s.svg',
    'smartphone-lg-k42-frozen.svg': 'smartphone-lg-k42-frozen.svg',
    'smartphone-lg-k42.svg': 'smartphone-lg-k42.svg',
    'smartphone-lg-k50-frozen.svg': 'smartphone-lg-k50-frozen.svg',
    'smartphone-lg-k50.svg': 'smartphone-lg-k50.svg',
    'smartphone-lg-k50s-frozen.svg': 'smartphone-lg-k50s-frozen.svg',
    'smartphone-lg-k50s.svg': 'smartphone-lg-k50s.svg',
    'smartphone-lg-k51-frozen.svg': 'smartphone-lg-k51-frozen.svg',
    'smartphone-lg-k51.svg': 'smartphone-lg-k51.svg',
    'smartphone-lg-k61-frozen.svg': 'smartphone-lg-k61-frozen.svg',
    'smartphone-lg-k61.svg': 'smartphone-lg-k61.svg',
    'smartphone-lg-k8x-frozen.svg': 'smartphone-lg-k8x-frozen.svg',
    'smartphone-lg-k8x.svg': 'smartphone-lg-k8x.svg',
    'smartphone-lg-k92-frozen.svg': 'smartphone-lg-k92-frozen.svg',
    'smartphone-lg-k92.svg': 'smartphone-lg-k92.svg',
    'smartphone-lg-neon-frozen.svg': 'smartphone-lg-neon-frozen.svg',
    'smartphone-lg-neon.svg': 'smartphone-lg-neon.svg',
    'smartphone-lg-phoenix-frozen.svg': 'smartphone-lg-phoenix-frozen.svg',
    'smartphone-lg-phoenix.svg': 'smartphone-lg-phoenix.svg',
    'smartphone-lg-premier-frozen.svg': 'smartphone-lg-premier-frozen.svg',
    'smartphone-lg-premier.svg': 'smartphone-lg-premier.svg',
    'smartphone-lg-premierpro-frozen.svg':
      'smartphone-lg-premierpro-frozen.svg',
    'smartphone-lg-premierpro.svg': 'smartphone-lg-premierpro.svg',
    'smartphone-lg-prime-frozen.svg': 'smartphone-lg-prime-frozen.svg',
    'smartphone-lg-prime.svg': 'smartphone-lg-prime.svg',
    'smartphone-lg-q60-frozen.svg': 'smartphone-lg-q60-frozen.svg',
    'smartphone-lg-q60.svg': 'smartphone-lg-q60.svg',
    'smartphone-lg-q7-frozen.svg': 'smartphone-lg-q7-frozen.svg',
    'smartphone-lg-q7.svg': 'smartphone-lg-q7.svg',
    'smartphone-lg-q70-frozen.svg': 'smartphone-lg-q70-frozen.svg',
    'smartphone-lg-q70.svg': 'smartphone-lg-q70.svg',
    'smartphone-lg-qstylus-frozen.svg': 'smartphone-lg-qstylus-frozen.svg',
    'smartphone-lg-qstylus.svg': 'smartphone-lg-qstylus.svg',
    'smartphone-lg-reflect-frozen.svg': 'smartphone-lg-reflect-frozen.svg',
    'smartphone-lg-reflect.svg': 'smartphone-lg-reflect.svg',
    'smartphone-lg-risio-frozen.svg': 'smartphone-lg-risio-frozen.svg',
    'smartphone-lg-risio.svg': 'smartphone-lg-risio.svg',
    'smartphone-lg-style-frozen.svg': 'smartphone-lg-style-frozen.svg',
    'smartphone-lg-style.svg': 'smartphone-lg-style.svg',
    'smartphone-lg-style2-frozen.svg': 'smartphone-lg-style2-frozen.svg',
    'smartphone-lg-style2.svg': 'smartphone-lg-style2.svg',
    'smartphone-lg-stylo-frozen.svg': 'smartphone-lg-stylo-frozen.svg',
    'smartphone-lg-stylo.svg': 'smartphone-lg-stylo.svg',
    'smartphone-lg-tribute-frozen.svg': 'smartphone-lg-tribute-frozen.svg',
    'smartphone-lg-tribute.svg': 'smartphone-lg-tribute.svg',
    'smartphone-lg-v30-frozen.svg': 'smartphone-lg-v30-frozen.svg',
    'smartphone-lg-v30.svg': 'smartphone-lg-v30.svg',
    'smartphone-lg-v35-frozen.svg': 'smartphone-lg-v35-frozen.svg',
    'smartphone-lg-v35.svg': 'smartphone-lg-v35.svg',
    'smartphone-lg-v40-frozen.svg': 'smartphone-lg-v40-frozen.svg',
    'smartphone-lg-v40.svg': 'smartphone-lg-v40.svg',
    'smartphone-lg-v50-frozen.svg': 'smartphone-lg-v50-frozen.svg',
    'smartphone-lg-v50.svg': 'smartphone-lg-v50.svg',
    'smartphone-lg-v60-frozen.svg': 'smartphone-lg-v60-frozen.svg',
    'smartphone-lg-v60.svg': 'smartphone-lg-v60.svg',
    'smartphone-lg-velvet-1.svg': 'smartphone-lg-velvet-1.svg',
    'smartphone-lg-velvet-frozen-1.svg': 'smartphone-lg-velvet-frozen-1.svg',
    'smartphone-lg-velvet-frozen.svg': 'smartphone-lg-velvet-frozen.svg',
    'smartphone-lg-velvet.svg': 'smartphone-lg-velvet.svg',
    'smartphone-lg-x-frozen.svg': 'smartphone-lg-x-frozen.svg',
    'smartphone-lg-x.svg': 'smartphone-lg-x.svg',
    'smartphone-lg-xpression-frozen.svg': 'smartphone-lg-xpression-frozen.svg',
    'smartphone-lg-xpression.svg': 'smartphone-lg-xpression.svg',
    'smartphone-logic-x50a-frozen.svg': 'smartphone-logic-x50a-frozen.svg',
    'smartphone-logic-x50a.svg': 'smartphone-logic-x50a.svg',
    'smartphone-microsoft-surface-frozen.svg':
      'smartphone-microsoft-surface-frozen.svg',
    'smartphone-microsoft-surface.svg': 'smartphone-microsoft-surface.svg',
    'smartphone-microsoft-windowsphone-frozen.svg':
      'smartphone-microsoft-windowsphone-frozen.svg',
    'smartphone-microsoft-windowsphone.svg':
      'smartphone-microsoft-windowsphone.svg',
    'smartphone-motorola-frozen.svg': 'smartphone-motorola-frozen.svg',
    'smartphone-motorola-moto-frozen.svg':
      'smartphone-motorola-moto-frozen.svg',
    'smartphone-motorola-moto.svg': 'smartphone-motorola-moto.svg',
    'smartphone-motorola.svg': 'smartphone-motorola.svg',
    'smartphone-myphone-prime-frozen.svg':
      'smartphone-myphone-prime-frozen.svg',
    'smartphone-myphone-prime.svg': 'smartphone-myphone-prime.svg',
    'smartphone-nec-101n-frozen.svg': 'smartphone-nec-101n-frozen.svg',
    'smartphone-nec-101n.svg': 'smartphone-nec-101n.svg',
    'smartphone-nextbook-ares-frozen.svg':
      'smartphone-nextbook-ares-frozen.svg',
    'smartphone-nextbook-ares.svg': 'smartphone-nextbook-ares.svg',
    'smartphone-nixplay-seed-frozen.svg': 'smartphone-nixplay-seed-frozen.svg',
    'smartphone-nixplay-seed.svg': 'smartphone-nixplay-seed.svg',
    'smartphone-nokia-oneplus-frozen.svg':
      'smartphone-nokia-oneplus-frozen.svg',
    'smartphone-nokia-oneplus.svg': 'smartphone-nokia-oneplus.svg',
    'smartphone-nokia-phone-frozen.svg': 'smartphone-nokia-phone-frozen.svg',
    'smartphone-nokia-phone.svg': 'smartphone-nokia-phone.svg',
    'smartphone-nubia-redmagic-frozen.svg':
      'smartphone-nubia-redmagic-frozen.svg',
    'smartphone-nubia-redmagic.svg': 'smartphone-nubia-redmagic.svg',
    'smartphone-nuumobile-a6l-frozen.svg':
      'smartphone-nuumobile-a6l-frozen.svg',
    'smartphone-nuumobile-a6l.svg': 'smartphone-nuumobile-a6l.svg',
    'smartphone-oneplus-3-frozen.svg': 'smartphone-oneplus-3-frozen.svg',
    'smartphone-oneplus-3.svg': 'smartphone-oneplus-3.svg',
    'smartphone-oneplus-3t-frozen.svg': 'smartphone-oneplus-3t-frozen.svg',
    'smartphone-oneplus-3t.svg': 'smartphone-oneplus-3t.svg',
    'smartphone-oneplus-5-frozen.svg': 'smartphone-oneplus-5-frozen.svg',
    'smartphone-oneplus-5.svg': 'smartphone-oneplus-5.svg',
    'smartphone-oneplus-5t-frozen.svg': 'smartphone-oneplus-5t-frozen.svg',
    'smartphone-oneplus-5t.svg': 'smartphone-oneplus-5t.svg',
    'smartphone-oneplus-6-frozen.svg': 'smartphone-oneplus-6-frozen.svg',
    'smartphone-oneplus-6.svg': 'smartphone-oneplus-6.svg',
    'smartphone-oneplus-7-frozen.svg': 'smartphone-oneplus-7-frozen.svg',
    'smartphone-oneplus-7.svg': 'smartphone-oneplus-7.svg',
    'smartphone-oneplus-7pro-frozen.svg': 'smartphone-oneplus-7pro-frozen.svg',
    'smartphone-oneplus-7pro.svg': 'smartphone-oneplus-7pro.svg',
    'smartphone-oneplus-7t-frozen.svg': 'smartphone-oneplus-7t-frozen.svg',
    'smartphone-oneplus-7t.svg': 'smartphone-oneplus-7t.svg',
    'smartphone-oneplus-8pro-frozen.svg': 'smartphone-oneplus-8pro-frozen.svg',
    'smartphone-oneplus-8pro.svg': 'smartphone-oneplus-8pro.svg',
    'smartphone-oneplus-8t-frozen.svg': 'smartphone-oneplus-8t-frozen.svg',
    'smartphone-oneplus-8t.svg': 'smartphone-oneplus-8t.svg',
    'smartphone-oneplus-frozen.svg': 'smartphone-oneplus-frozen.svg',
    'smartphone-oneplus-nord-frozen.svg': 'smartphone-oneplus-nord-frozen.svg',
    'smartphone-oneplus-nord.svg': 'smartphone-oneplus-nord.svg',
    'smartphone-oneplus.svg': 'smartphone-oneplus.svg',
    'smartphone-oppo-a1k-frozen.svg': 'smartphone-oppo-a1k-frozen.svg',
    'smartphone-oppo-a1k.svg': 'smartphone-oppo-a1k.svg',
    'smartphone-oppo-a31u-frozen.svg': 'smartphone-oppo-a31u-frozen.svg',
    'smartphone-oppo-a31u.svg': 'smartphone-oppo-a31u.svg',
    'smartphone-oppo-a3s-frozen.svg': 'smartphone-oppo-a3s-frozen.svg',
    'smartphone-oppo-a3s.svg': 'smartphone-oppo-a3s.svg',
    'smartphone-oppo-a5s-frozen.svg': 'smartphone-oppo-a5s-frozen.svg',
    'smartphone-oppo-a5s.svg': 'smartphone-oppo-a5s.svg',
    'smartphone-oppo-a7x-frozen.svg': 'smartphone-oppo-a7x-frozen.svg',
    'smartphone-oppo-a7x.svg': 'smartphone-oppo-a7x.svg',
    'smartphone-oppo-a83-frozen.svg': 'smartphone-oppo-a83-frozen.svg',
    'smartphone-oppo-a83.svg': 'smartphone-oppo-a83.svg',
    'smartphone-oppo-ax7-frozen.svg': 'smartphone-oppo-ax7-frozen.svg',
    'smartphone-oppo-ax7.svg': 'smartphone-oppo-ax7.svg',
    'smartphone-oppo-f11-frozen.svg': 'smartphone-oppo-f11-frozen.svg',
    'smartphone-oppo-f11.svg': 'smartphone-oppo-f11.svg',
    'smartphone-oppo-f9-frozen.svg': 'smartphone-oppo-f9-frozen.svg',
    'smartphone-oppo-f9.svg': 'smartphone-oppo-f9.svg',
    'smartphone-oppo-find-frozen.svg': 'smartphone-oppo-find-frozen.svg',
    'smartphone-oppo-find.svg': 'smartphone-oppo-find.svg',
    'smartphone-oppo-r11-frozen.svg': 'smartphone-oppo-r11-frozen.svg',
    'smartphone-oppo-r11.svg': 'smartphone-oppo-r11.svg',
    'smartphone-oppo-r11s-frozen.svg': 'smartphone-oppo-r11s-frozen.svg',
    'smartphone-oppo-r11s.svg': 'smartphone-oppo-r11s.svg',
    'smartphone-oppo-r15-frozen.svg': 'smartphone-oppo-r15-frozen.svg',
    'smartphone-oppo-r15.svg': 'smartphone-oppo-r15.svg',
    'smartphone-oppo-r17-frozen.svg': 'smartphone-oppo-r17-frozen.svg',
    'smartphone-oppo-r17.svg': 'smartphone-oppo-r17.svg',
    'smartphone-oppo-reno-frozen.svg': 'smartphone-oppo-reno-frozen.svg',
    'smartphone-oppo-reno.svg': 'smartphone-oppo-reno.svg',
    'smartphone-oppo-reno2-frozen.svg': 'smartphone-oppo-reno2-frozen.svg',
    'smartphone-oppo-reno2.svg': 'smartphone-oppo-reno2.svg',
    'smartphone-oppo-reno4-frozen.svg': 'smartphone-oppo-reno4-frozen.svg',
    'smartphone-oppo-reno4.svg': 'smartphone-oppo-reno4.svg',
    'smartphone-oukitel-k10-frozen.svg': 'smartphone-oukitel-k10-frozen.svg',
    'smartphone-oukitel-k10.svg': 'smartphone-oukitel-k10.svg',
    'smartphone-oukitel-k7-frozen.svg': 'smartphone-oukitel-k7-frozen.svg',
    'smartphone-oukitel-k7.svg': 'smartphone-oukitel-k7.svg',
    'smartphone-oukitel-u23-frozen.svg': 'smartphone-oukitel-u23-frozen.svg',
    'smartphone-oukitel-u23.svg': 'smartphone-oukitel-u23.svg',
    'smartphone-oukitel-wp5-frozen.svg': 'smartphone-oukitel-wp5-frozen.svg',
    'smartphone-oukitel-wp5.svg': 'smartphone-oukitel-wp5.svg',
    'smartphone-oukitel-wp6-frozen.svg': 'smartphone-oukitel-wp6-frozen.svg',
    'smartphone-oukitel-wp6.svg': 'smartphone-oukitel-wp6.svg',
    'smartphone-p43-pro-frozen.svg': 'smartphone-p43-pro-frozen.svg',
    'smartphone-p43-pro.svg': 'smartphone-p43-pro.svg',
    'smartphone-palm-pvg100-frozen.svg': 'smartphone-palm-pvg100-frozen.svg',
    'smartphone-palm-pvg100.svg': 'smartphone-palm-pvg100.svg',
    'smartphone-qilive-q8s6in4g-frozen.svg':
      'smartphone-qilive-q8s6in4g-frozen.svg',
    'smartphone-qilive-q8s6in4g.svg': 'smartphone-qilive-q8s6in4g.svg',
    'smartphone-realme-2-frozen.svg': 'smartphone-realme-2-frozen.svg',
    'smartphone-realme-2.svg': 'smartphone-realme-2.svg',
    'smartphone-realme-3-frozen.svg': 'smartphone-realme-3-frozen.svg',
    'smartphone-realme-3.svg': 'smartphone-realme-3.svg',
    'smartphone-realme-5-frozen.svg': 'smartphone-realme-5-frozen.svg',
    'smartphone-realme-5.svg': 'smartphone-realme-5.svg',
    'smartphone-realme-5i-frozen.svg': 'smartphone-realme-5i-frozen.svg',
    'smartphone-realme-5i.svg': 'smartphone-realme-5i.svg',
    'smartphone-realme-6-frozen.svg': 'smartphone-realme-6-frozen.svg',
    'smartphone-realme-6.svg': 'smartphone-realme-6.svg',
    'smartphone-realme-c2-frozen.svg': 'smartphone-realme-c2-frozen.svg',
    'smartphone-realme-c2.svg': 'smartphone-realme-c2.svg',
    'smartphone-realme-x2-frozen.svg': 'smartphone-realme-x2-frozen.svg',
    'smartphone-realme-x2.svg': 'smartphone-realme-x2.svg',
    'smartphone-red-hydrogenone-frozen.svg':
      'smartphone-red-hydrogenone-frozen.svg',
    'smartphone-red-hydrogenone.svg': 'smartphone-red-hydrogenone.svg',
    'smartphone-samsung-galaxy-frozen.svg':
      'smartphone-samsung-galaxy-frozen.svg',
    'smartphone-samsung-galaxy.svg': 'smartphone-samsung-galaxy.svg',
    'smartphone-samsung-omnia-frozen.svg':
      'smartphone-samsung-omnia-frozen.svg',
    'smartphone-samsung-omnia.svg': 'smartphone-samsung-omnia.svg',
    'smartphone-samsung-sghi957-frozen.svg':
      'smartphone-samsung-sghi957-frozen.svg',
    'smartphone-samsung-sghi957.svg': 'smartphone-samsung-sghi957.svg',
    'smartphone-sharp-androidone-frozen.svg':
      'smartphone-sharp-androidone-frozen.svg',
    'smartphone-sharp-androidone.svg': 'smartphone-sharp-androidone.svg',
    'smartphone-sharp-aquos-frozen.svg': 'smartphone-sharp-aquos-frozen.svg',
    'smartphone-sharp-aquos.svg': 'smartphone-sharp-aquos.svg',
    'smartphone-sharp-basio2-frozen.svg': 'smartphone-sharp-basio2-frozen.svg',
    'smartphone-sharp-basio2.svg': 'smartphone-sharp-basio2.svg',
    'smartphone-sharp-simplesumaho-frozen.svg':
      'smartphone-sharp-simplesumaho-frozen.svg',
    'smartphone-sharp-simplesumaho.svg': 'smartphone-sharp-simplesumaho.svg',
    'smartphone-skydevices-elite-frozen.svg':
      'smartphone-skydevices-elite-frozen.svg',
    'smartphone-skydevices-elite.svg': 'smartphone-skydevices-elite.svg',
    'smartphone-sonim-xp8-frozen.svg': 'smartphone-sonim-xp8-frozen.svg',
    'smartphone-sonim-xp8.svg': 'smartphone-sonim-xp8.svg',
    'smartphone-sony-frozen.svg': 'smartphone-sony-frozen.svg',
    'smartphone-sony-xperia-frozen.svg': 'smartphone-sony-xperia-frozen.svg',
    'smartphone-sony-xperia.svg': 'smartphone-sony-xperia.svg',
    'smartphone-sony.svg': 'smartphone-sony.svg',
    'smartphone-sprint-slate-frozen.svg': 'smartphone-sprint-slate-frozen.svg',
    'smartphone-sprint-slate.svg': 'smartphone-sprint-slate.svg',
    'smartphone-this-frozen.svg': 'smartphone-this-frozen.svg',
    'smartphone-this.svg': 'smartphone-this.svg',
    'smartphone-tmobile-revvl-frozen.svg':
      'smartphone-tmobile-revvl-frozen.svg',
    'smartphone-tmobile-revvl.svg': 'smartphone-tmobile-revvl.svg',
    'smartphone-tmobile-tcl-frozen.svg': 'smartphone-tmobile-tcl-frozen.svg',
    'smartphone-tmobile-tcl.svg': 'smartphone-tmobile-tcl.svg',
    'smartphone-truconnect-flame-frozen.svg':
      'smartphone-truconnect-flame-frozen.svg',
    'smartphone-truconnect-flame.svg': 'smartphone-truconnect-flame.svg',
    'smartphone-ulefone-note9p-frozen.svg':
      'smartphone-ulefone-note9p-frozen.svg',
    'smartphone-ulefone-note9p.svg': 'smartphone-ulefone-note9p.svg',
    'smartphone-umidigi-a7-frozen.svg': 'smartphone-umidigi-a7-frozen.svg',
    'smartphone-umidigi-a7.svg': 'smartphone-umidigi-a7.svg',
    'smartphone-unimax-tracfone-frozen.svg':
      'smartphone-unimax-tracfone-frozen.svg',
    'smartphone-unimax-tracfone.svg': 'smartphone-unimax-tracfone.svg',
    'smartphone-unimax-umx-frozen.svg': 'smartphone-unimax-umx-frozen.svg',
    'smartphone-unimax-umx.svg': 'smartphone-unimax-umx.svg',
    'smartphone-vaio-phone-frozen.svg': 'smartphone-vaio-phone-frozen.svg',
    'smartphone-vaio-phone.svg': 'smartphone-vaio-phone.svg',
    'smartphone-verizon-orbicwonder-frozen.svg':
      'smartphone-verizon-orbicwonder-frozen.svg',
    'smartphone-verizon-orbicwonder.svg': 'smartphone-verizon-orbicwonder.svg',
    'smartphone-vinsmart-v341u-frozen.svg':
      'smartphone-vinsmart-v341u-frozen.svg',
    'smartphone-vinsmart-v341u.svg': 'smartphone-vinsmart-v341u.svg',
    'smartphone-vivo-iqoo-frozen.svg': 'smartphone-vivo-iqoo-frozen.svg',
    'smartphone-vivo-iqoo.svg': 'smartphone-vivo-iqoo.svg',
    'smartphone-vivo-phone-frozen.svg': 'smartphone-vivo-phone-frozen.svg',
    'smartphone-vivo-phone.svg': 'smartphone-vivo-phone.svg',
    'smartphone-vivo-y93s-frozen.svg': 'smartphone-vivo-y93s-frozen.svg',
    'smartphone-vivo-y93s.svg': 'smartphone-vivo-y93s.svg',
    'smartphone-wiko-life-frozen.svg': 'smartphone-wiko-life-frozen.svg',
    'smartphone-wiko-life.svg': 'smartphone-wiko-life.svg',
    'smartphone-wiko-smartphone-frozen.svg':
      'smartphone-wiko-smartphone-frozen.svg',
    'smartphone-wiko-smartphone.svg': 'smartphone-wiko-smartphone.svg',
    'smartphone-wiko-view2-frozen.svg': 'smartphone-wiko-view2-frozen.svg',
    'smartphone-wiko-view2.svg': 'smartphone-wiko-view2.svg',
    'smartphone-wiko-view3-frozen.svg': 'smartphone-wiko-view3-frozen.svg',
    'smartphone-wiko-view3.svg': 'smartphone-wiko-view3.svg',
    'smartphone-wiko-y80-frozen.svg': 'smartphone-wiko-y80-frozen.svg',
    'smartphone-wiko-y80.svg': 'smartphone-wiko-y80.svg',
    'smartphone-windowsphone-frozen.svg': 'smartphone-windowsphone-frozen.svg',
    'smartphone-windowsphone.svg': 'smartphone-windowsphone.svg',
    'smartphone-xiaomi-blackshark-frozen.svg':
      'smartphone-xiaomi-blackshark-frozen.svg',
    'smartphone-xiaomi-blackshark.svg': 'smartphone-xiaomi-blackshark.svg',
    'smartphone-xiaomi-mi-frozen.svg': 'smartphone-xiaomi-mi-frozen.svg',
    'smartphone-xiaomi-mi.svg': 'smartphone-xiaomi-mi.svg',
    'smartphone-xiaomi-mix-frozen.svg': 'smartphone-xiaomi-mix-frozen.svg',
    'smartphone-xiaomi-mix.svg': 'smartphone-xiaomi-mix.svg',
    'smartphone-xiaomi-poco-frozen.svg': 'smartphone-xiaomi-poco-frozen.svg',
    'smartphone-xiaomi-poco.svg': 'smartphone-xiaomi-poco.svg',
    'smartphone-xiaomi-pocophone-frozen.svg':
      'smartphone-xiaomi-pocophone-frozen.svg',
    'smartphone-xiaomi-pocophone.svg': 'smartphone-xiaomi-pocophone.svg',
    'smartphone-xiaomi-redmi-frozen.svg': 'smartphone-xiaomi-redmi-frozen.svg',
    'smartphone-xiaomi-redmi.svg': 'smartphone-xiaomi-redmi.svg',
    'smartphone-xiaomi-smartphone-frozen.svg':
      'smartphone-xiaomi-smartphone-frozen.svg',
    'smartphone-xiaomi-smartphone.svg': 'smartphone-xiaomi-smartphone.svg',
    'smartphone-zte-atrium-frozen.svg': 'smartphone-zte-atrium-frozen.svg',
    'smartphone-zte-atrium.svg': 'smartphone-zte-atrium.svg',
    'smartphone-zte-axon-frozen.svg': 'smartphone-zte-axon-frozen.svg',
    'smartphone-zte-axon.svg': 'smartphone-zte-axon.svg',
    'smartphone-zte-blade-frozen.svg': 'smartphone-zte-blade-frozen.svg',
    'smartphone-zte-blade.svg': 'smartphone-zte-blade.svg',
    'smartphone-zte-cymbal-frozen.svg': 'smartphone-zte-cymbal-frozen.svg',
    'smartphone-zte-cymbal.svg': 'smartphone-zte-cymbal.svg',
    'smartphone-zte-frozen.svg': 'smartphone-zte-frozen.svg',
    'smartphone-zte-grand-frozen.svg': 'smartphone-zte-grand-frozen.svg',
    'smartphone-zte-grand.svg': 'smartphone-zte-grand.svg',
    'smartphone-zte-grandx-frozen.svg': 'smartphone-zte-grandx-frozen.svg',
    'smartphone-zte-grandx.svg': 'smartphone-zte-grandx.svg',
    'smartphone-zte-lever-frozen.svg': 'smartphone-zte-lever-frozen.svg',
    'smartphone-zte-lever.svg': 'smartphone-zte-lever.svg',
    'smartphone-zte-libero-frozen.svg': 'smartphone-zte-libero-frozen.svg',
    'smartphone-zte-libero.svg': 'smartphone-zte-libero.svg',
    'smartphone-zte-mono-frozen.svg': 'smartphone-zte-mono-frozen.svg',
    'smartphone-zte-mono.svg': 'smartphone-zte-mono.svg',
    'smartphone-zte-qlink-frozen.svg': 'smartphone-zte-qlink-frozen.svg',
    'smartphone-zte-qlink.svg': 'smartphone-zte-qlink.svg',
    'smartphone-zte-quest-frozen.svg': 'smartphone-zte-quest-frozen.svg',
    'smartphone-zte-quest.svg': 'smartphone-zte-quest.svg',
    'smartphone-zte-savvy-frozen.svg': 'smartphone-zte-savvy-frozen.svg',
    'smartphone-zte-savvy.svg': 'smartphone-zte-savvy.svg',
    'smartphone-zte-visible-frozen.svg': 'smartphone-zte-visible-frozen.svg',
    'smartphone-zte-visible.svg': 'smartphone-zte-visible.svg',
    'smartphone-zte-warp-frozen.svg': 'smartphone-zte-warp-frozen.svg',
    'smartphone-zte-warp.svg': 'smartphone-zte-warp.svg',
    'smartphone-zte-zfive-frozen.svg': 'smartphone-zte-zfive-frozen.svg',
    'smartphone-zte-zfive.svg': 'smartphone-zte-zfive.svg',
    'smartphone-zte-zmax-frozen.svg': 'smartphone-zte-zmax-frozen.svg',
    'smartphone-zte-zmax.svg': 'smartphone-zte-zmax.svg',
    'smartphone-zte.svg': 'smartphone-zte.svg',
    'smartphone.svg': 'smartphone.svg',
    'smartremote-frozen.svg': 'smartremote-frozen.svg',
    'smartremote.svg': 'smartremote.svg',
    'smokealarm-frozen.svg': 'smokealarm-frozen.svg',
    'smokealarm-googlenest-protect-frozen.svg':
      'smokealarm-googlenest-protect-frozen.svg',
    'smokealarm-googlenest-protect.svg': 'smokealarm-googlenest-protect.svg',
    'smokealarm.svg': 'smokealarm.svg',
    'solar-enphase-envoy-frozen.svg': 'solar-enphase-envoy-frozen.svg',
    'solar-enphase-envoy.svg': 'solar-enphase-envoy.svg',
    'solar-frozen.svg': 'solar-frozen.svg',
    'solar.svg': 'solar.svg',
    'speaker-altice-amplify-frozen.svg': 'speaker-altice-amplify-frozen.svg',
    'speaker-altice-amplify.svg': 'speaker-altice-amplify.svg',
    'speaker-altice-frozen.svg': 'speaker-altice-frozen.svg',
    'speaker-altice.svg': 'speaker-altice.svg',
    'speaker-amazon-echo-frozen.svg': 'speaker-amazon-echo-frozen.svg',
    'speaker-amazon-echo.svg': 'speaker-amazon-echo.svg',
    'speaker-amazon-echodot-frozen.svg': 'speaker-amazon-echodot-frozen.svg',
    'speaker-amazon-echodot.svg': 'speaker-amazon-echodot.svg',
    'speaker-amazon-echosub-frozen.svg': 'speaker-amazon-echosub-frozen.svg',
    'speaker-amazon-echosub.svg': 'speaker-amazon-echosub.svg',
    'speaker-amazon-frozen.svg': 'speaker-amazon-frozen.svg',
    'speaker-amazon-tap-frozen.svg': 'speaker-amazon-tap-frozen.svg',
    'speaker-amazon-tap.svg': 'speaker-amazon-tap.svg',
    'speaker-amazon.svg': 'speaker-amazon.svg',
    'speaker-anker-frozen.svg': 'speaker-anker-frozen.svg',
    'speaker-anker-zolomojo-frozen.svg': 'speaker-anker-zolomojo-frozen.svg',
    'speaker-anker-zolomojo.svg': 'speaker-anker-zolomojo.svg',
    'speaker-anker.svg': 'speaker-anker.svg',
    'speaker-apple-frozen.svg': 'speaker-apple-frozen.svg',
    'speaker-apple-homepod-frozen.svg': 'speaker-apple-homepod-frozen.svg',
    'speaker-apple-homepod.svg': 'speaker-apple-homepod.svg',
    'speaker-apple.svg': 'speaker-apple.svg',
    'speaker-audiocast-frozen.svg': 'speaker-audiocast-frozen.svg',
    'speaker-audiocast-wifiaudio-frozen.svg':
      'speaker-audiocast-wifiaudio-frozen.svg',
    'speaker-audiocast-wifiaudio.svg': 'speaker-audiocast-wifiaudio.svg',
    'speaker-audiocast.svg': 'speaker-audiocast.svg',
    'speaker-audiopro-addon-frozen.svg': 'speaker-audiopro-addon-frozen.svg',
    'speaker-audiopro-addon.svg': 'speaker-audiopro-addon.svg',
    'speaker-audiopro-drumfire-frozen.svg':
      'speaker-audiopro-drumfire-frozen.svg',
    'speaker-audiopro-drumfire.svg': 'speaker-audiopro-drumfire.svg',
    'speaker-audiopro-frozen.svg': 'speaker-audiopro-frozen.svg',
    'speaker-audiopro.svg': 'speaker-audiopro.svg',
    'speaker-baidu-1.svg': 'speaker-baidu-1.svg',
    'speaker-baidu-frozen-1.svg': 'speaker-baidu-frozen-1.svg',
    'speaker-baidu-frozen.svg': 'speaker-baidu-frozen.svg',
    'speaker-baidu.svg': 'speaker-baidu.svg',
    'speaker-bangolufsen-beosound-frozen.svg':
      'speaker-bangolufsen-beosound-frozen.svg',
    'speaker-bangolufsen-beosound.svg': 'speaker-bangolufsen-beosound.svg',
    'speaker-bangolufson-1.svg': 'speaker-bangolufson-1.svg',
    'speaker-bangolufson-beolit-frozen.svg':
      'speaker-bangolufson-beolit-frozen.svg',
    'speaker-bangolufson-beolit.svg': 'speaker-bangolufson-beolit.svg',
    'speaker-bangolufson-beoplay-frozen.svg':
      'speaker-bangolufson-beoplay-frozen.svg',
    'speaker-bangolufson-beoplay.svg': 'speaker-bangolufson-beoplay.svg',
    'speaker-bangolufson-frozen-1.svg': 'speaker-bangolufson-frozen-1.svg',
    'speaker-bangolufson-frozen.svg': 'speaker-bangolufson-frozen.svg',
    'speaker-bangolufson.svg': 'speaker-bangolufson.svg',
    'speaker-belkin-frozen.svg': 'speaker-belkin-frozen.svg',
    'speaker-belkin-soundform-frozen.svg':
      'speaker-belkin-soundform-frozen.svg',
    'speaker-belkin-soundform.svg': 'speaker-belkin-soundform.svg',
    'speaker-belkin.svg': 'speaker-belkin.svg',
    'speaker-blackweb-frozen.svg': 'speaker-blackweb-frozen.svg',
    'speaker-blackweb-soundbar-frozen.svg':
      'speaker-blackweb-soundbar-frozen.svg',
    'speaker-blackweb-soundbar.svg': 'speaker-blackweb-soundbar.svg',
    'speaker-blackweb.svg': 'speaker-blackweb.svg',
    'speaker-bluesound-flexmediaplayer-frozen.svg':
      'speaker-bluesound-flexmediaplayer-frozen.svg',
    'speaker-bluesound-flexmediaplayer.svg':
      'speaker-bluesound-flexmediaplayer.svg',
    'speaker-bluesound-frozen.svg': 'speaker-bluesound-frozen.svg',
    'speaker-bluesound-pulsespeaker-frozen.svg':
      'speaker-bluesound-pulsespeaker-frozen.svg',
    'speaker-bluesound-pulsespeaker.svg': 'speaker-bluesound-pulsespeaker.svg',
    'speaker-bluesound-soundbar-frozen.svg':
      'speaker-bluesound-soundbar-frozen.svg',
    'speaker-bluesound-soundbar.svg': 'speaker-bluesound-soundbar.svg',
    'speaker-bluesound.svg': 'speaker-bluesound.svg',
    'speaker-bose-frozen.svg': 'speaker-bose-frozen.svg',
    'speaker-bose-homespeaker-frozen.svg':
      'speaker-bose-homespeaker-frozen.svg',
    'speaker-bose-homespeaker.svg': 'speaker-bose-homespeaker.svg',
    'speaker-bose-soundbar-frozen.svg': 'speaker-bose-soundbar-frozen.svg',
    'speaker-bose-soundbar.svg': 'speaker-bose-soundbar.svg',
    'speaker-bose-soundlink-frozen.svg': 'speaker-bose-soundlink-frozen.svg',
    'speaker-bose-soundlink.svg': 'speaker-bose-soundlink.svg',
    'speaker-bose-soundtouch-frozen.svg': 'speaker-bose-soundtouch-frozen.svg',
    'speaker-bose-soundtouch.svg': 'speaker-bose-soundtouch.svg',
    'speaker-bose-wavesoundtouch-frozen.svg':
      'speaker-bose-wavesoundtouch-frozen.svg',
    'speaker-bose-wavesoundtouch.svg': 'speaker-bose-wavesoundtouch.svg',
    'speaker-bose.svg': 'speaker-bose.svg',
    'speaker-bowerswilkins-a7-frozen.svg':
      'speaker-bowerswilkins-a7-frozen.svg',
    'speaker-bowerswilkins-a7.svg': 'speaker-bowerswilkins-a7.svg',
    'speaker-bowerswilkins-formation-frozen.svg':
      'speaker-bowerswilkins-formation-frozen.svg',
    'speaker-bowerswilkins-formation.svg':
      'speaker-bowerswilkins-formation.svg',
    'speaker-bowerswilkins-frozen.svg': 'speaker-bowerswilkins-frozen.svg',
    'speaker-bowerswilkins-zeppelin-frozen.svg':
      'speaker-bowerswilkins-zeppelin-frozen.svg',
    'speaker-bowerswilkins-zeppelin.svg': 'speaker-bowerswilkins-zeppelin.svg',
    'speaker-bowerswilkins.svg': 'speaker-bowerswilkins.svg',
    'speaker-cavalier-air-frozen.svg': 'speaker-cavalier-air-frozen.svg',
    'speaker-cavalier-air.svg': 'speaker-cavalier-air.svg',
    'speaker-cavalier-frozen.svg': 'speaker-cavalier-frozen.svg',
    'speaker-cavalier-maverick-frozen.svg':
      'speaker-cavalier-maverick-frozen.svg',
    'speaker-cavalier-maverick.svg': 'speaker-cavalier-maverick.svg',
    'speaker-cavalier.svg': 'speaker-cavalier.svg',
    'speaker-clova-frozen.svg': 'speaker-clova-frozen.svg',
    'speaker-clova-virtualassistant-frozen.svg':
      'speaker-clova-virtualassistant-frozen.svg',
    'speaker-clova-virtualassistant.svg': 'speaker-clova-virtualassistant.svg',
    'speaker-clova-wave-frozen.svg': 'speaker-clova-wave-frozen.svg',
    'speaker-clova-wave.svg': 'speaker-clova-wave.svg',
    'speaker-clova.svg': 'speaker-clova.svg',
    'speaker-control4-frozen.svg': 'speaker-control4-frozen.svg',
    'speaker-control4-speakerpoint-frozen.svg':
      'speaker-control4-speakerpoint-frozen.svg',
    'speaker-control4-speakerpoint.svg': 'speaker-control4-speakerpoint.svg',
    'speaker-control4-triad-frozen.svg': 'speaker-control4-triad-frozen.svg',
    'speaker-control4-triad.svg': 'speaker-control4-triad.svg',
    'speaker-control4.svg': 'speaker-control4.svg',
    'speaker-creative-frozen.svg': 'speaker-creative-frozen.svg',
    'speaker-creative-nova-frozen.svg': 'speaker-creative-nova-frozen.svg',
    'speaker-creative-nova.svg': 'speaker-creative-nova.svg',
    'speaker-creative-omni-frozen.svg': 'speaker-creative-omni-frozen.svg',
    'speaker-creative-omni.svg': 'speaker-creative-omni.svg',
    'speaker-creative.svg': 'speaker-creative.svg',
    'speaker-denon-frozen.svg': 'speaker-denon-frozen.svg',
    'speaker-denon-heos-frozen.svg': 'speaker-denon-heos-frozen.svg',
    'speaker-denon-heos.svg': 'speaker-denon-heos.svg',
    'speaker-denon-heos1-frozen.svg': 'speaker-denon-heos1-frozen.svg',
    'speaker-denon-heos1.svg': 'speaker-denon-heos1.svg',
    'speaker-denon-heosbar-frozen.svg': 'speaker-denon-heosbar-frozen.svg',
    'speaker-denon-heosbar.svg': 'speaker-denon-heosbar.svg',
    'speaker-denon-heoshomecinema-frozen.svg':
      'speaker-denon-heoshomecinema-frozen.svg',
    'speaker-denon-heoshomecinema.svg': 'speaker-denon-heoshomecinema.svg',
    'speaker-denon-heossubwoofer-frozen.svg':
      'speaker-denon-heossubwoofer-frozen.svg',
    'speaker-denon-heossubwoofer.svg': 'speaker-denon-heossubwoofer.svg',
    'speaker-denon-home-frozen.svg': 'speaker-denon-home-frozen.svg',
    'speaker-denon-home.svg': 'speaker-denon-home.svg',
    'speaker-denon.svg': 'speaker-denon.svg',
    'speaker-devialet-frozen.svg': 'speaker-devialet-frozen.svg',
    'speaker-devialet-goldphantom-frozen.svg':
      'speaker-devialet-goldphantom-frozen.svg',
    'speaker-devialet-goldphantom.svg': 'speaker-devialet-goldphantom.svg',
    'speaker-devialet-phantomreactor-frozen.svg':
      'speaker-devialet-phantomreactor-frozen.svg',
    'speaker-devialet-phantomreactor.svg':
      'speaker-devialet-phantomreactor.svg',
    'speaker-devialet.svg': 'speaker-devialet.svg',
    'speaker-echo-show-frozen.svg': 'speaker-echo-show-frozen.svg',
    'speaker-echo-show.svg': 'speaker-echo-show.svg',
    'speaker-echo-spot-frozen.svg': 'speaker-echo-spot-frozen.svg',
    'speaker-echo-spot.svg': 'speaker-echo-spot.svg',
    'speaker-eufy-frozen.svg': 'speaker-eufy-frozen.svg',
    'speaker-eufy-genie-frozen.svg': 'speaker-eufy-genie-frozen.svg',
    'speaker-eufy-genie.svg': 'speaker-eufy-genie.svg',
    'speaker-eufy.svg': 'speaker-eufy.svg',
    'speaker-fabriq-frozen.svg': 'speaker-fabriq-frozen.svg',
    'speaker-fabriq-wifispeaker-frozen.svg':
      'speaker-fabriq-wifispeaker-frozen.svg',
    'speaker-fabriq-wifispeaker.svg': 'speaker-fabriq-wifispeaker.svg',
    'speaker-fabriq.svg': 'speaker-fabriq.svg',
    'speaker-frozen.svg': 'speaker-frozen.svg',
    'speaker-ggmm-frozen.svg': 'speaker-ggmm-frozen.svg',
    'speaker-ggmm-smartspeaker-frozen.svg':
      'speaker-ggmm-smartspeaker-frozen.svg',
    'speaker-ggmm-smartspeaker.svg': 'speaker-ggmm-smartspeaker.svg',
    'speaker-ggmm.svg': 'speaker-ggmm.svg',
    'speaker-gohawk-frozen.svg': 'speaker-gohawk-frozen.svg',
    'speaker-gohawk-lit-frozen.svg': 'speaker-gohawk-lit-frozen.svg',
    'speaker-gohawk-lit.svg': 'speaker-gohawk-lit.svg',
    'speaker-gohawk.svg': 'speaker-gohawk.svg',
    'speaker-google-frozen.svg': 'speaker-google-frozen.svg',
    'speaker-google-home-frozen.svg': 'speaker-google-home-frozen.svg',
    'speaker-google-home.svg': 'speaker-google-home.svg',
    'speaker-google-nestaudio-frozen.svg':
      'speaker-google-nestaudio-frozen.svg',
    'speaker-google-nestaudio.svg': 'speaker-google-nestaudio.svg',
    'speaker-google.svg': 'speaker-google.svg',
    'speaker-harmankardon-allure-frozen.svg':
      'speaker-harmankardon-allure-frozen.svg',
    'speaker-harmankardon-allure.svg': 'speaker-harmankardon-allure.svg',
    'speaker-harmankardon-astra-frozen.svg':
      'speaker-harmankardon-astra-frozen.svg',
    'speaker-harmankardon-astra.svg': 'speaker-harmankardon-astra.svg',
    'speaker-harmankardon-aura-frozen.svg':
      'speaker-harmankardon-aura-frozen.svg',
    'speaker-harmankardon-aura.svg': 'speaker-harmankardon-aura.svg',
    'speaker-harmankardon-citation-frozen.svg':
      'speaker-harmankardon-citation-frozen.svg',
    'speaker-harmankardon-citation.svg': 'speaker-harmankardon-citation.svg',
    'speaker-harmankardon-enchant-frozen.svg':
      'speaker-harmankardon-enchant-frozen.svg',
    'speaker-harmankardon-enchant.svg': 'speaker-harmankardon-enchant.svg',
    'speaker-harmankardon-frozen.svg': 'speaker-harmankardon-frozen.svg',
    'speaker-harmankardon-invokespeaker-frozen.svg':
      'speaker-harmankardon-invokespeaker-frozen.svg',
    'speaker-harmankardon-invokespeaker.svg':
      'speaker-harmankardon-invokespeaker.svg',
    'speaker-harmankardon-omni-frozen.svg':
      'speaker-harmankardon-omni-frozen.svg',
    'speaker-harmankardon-omni.svg': 'speaker-harmankardon-omni.svg',
    'speaker-harmankardon.svg': 'speaker-harmankardon.svg',
    'speaker-ilive-frozen.svg': 'speaker-ilive-frozen.svg',
    'speaker-ilive-platinumconcierge-frozen.svg':
      'speaker-ilive-platinumconcierge-frozen.svg',
    'speaker-ilive-platinumconcierge.svg':
      'speaker-ilive-platinumconcierge.svg',
    'speaker-ilive-soundbar-frozen.svg': 'speaker-ilive-soundbar-frozen.svg',
    'speaker-ilive-soundbar.svg': 'speaker-ilive-soundbar.svg',
    'speaker-ilive-undercabinetspeaker-frozen.svg':
      'speaker-ilive-undercabinetspeaker-frozen.svg',
    'speaker-ilive-undercabinetspeaker.svg':
      'speaker-ilive-undercabinetspeaker.svg',
    'speaker-ilive-wirelessspeaker-frozen.svg':
      'speaker-ilive-wirelessspeaker-frozen.svg',
    'speaker-ilive-wirelessspeaker.svg': 'speaker-ilive-wirelessspeaker.svg',
    'speaker-ilive.svg': 'speaker-ilive.svg',
    'speaker-insignia-frozen.svg': 'speaker-insignia-frozen.svg',
    'speaker-insignia.svg': 'speaker-insignia.svg',
    'speaker-invoxia-frozen.svg': 'speaker-invoxia-frozen.svg',
    'speaker-invoxia-triby-frozen.svg': 'speaker-invoxia-triby-frozen.svg',
    'speaker-invoxia-triby.svg': 'speaker-invoxia-triby.svg',
    'speaker-invoxia.svg': 'speaker-invoxia.svg',
    'speaker-jbl-authentics-frozen.svg': 'speaker-jbl-authentics-frozen.svg',
    'speaker-jbl-authentics.svg': 'speaker-jbl-authentics.svg',
    'speaker-jbl-bar-frozen.svg': 'speaker-jbl-bar-frozen.svg',
    'speaker-jbl-bar.svg': 'speaker-jbl-bar.svg',
    'speaker-jbl-frozen.svg': 'speaker-jbl-frozen.svg',
    'speaker-jbl-playlist-frozen.svg': 'speaker-jbl-playlist-frozen.svg',
    'speaker-jbl-playlist.svg': 'speaker-jbl-playlist.svg',
    'speaker-jbl-soundfly-frozen.svg': 'speaker-jbl-soundfly-frozen.svg',
    'speaker-jbl-soundfly.svg': 'speaker-jbl-soundfly.svg',
    'speaker-jbl.svg': 'speaker-jbl.svg',
    'speaker-kef-frozen.svg': 'speaker-kef-frozen.svg',
    'speaker-kef-ls50-frozen.svg': 'speaker-kef-ls50-frozen.svg',
    'speaker-kef-ls50.svg': 'speaker-kef-ls50.svg',
    'speaker-kef-lsx-frozen.svg': 'speaker-kef-lsx-frozen.svg',
    'speaker-kef-lsx.svg': 'speaker-kef-lsx.svg',
    'speaker-kef.svg': 'speaker-kef.svg',
    'speaker-lg-frozen.svg': 'speaker-lg-frozen.svg',
    'speaker-lg-soundbar-frozen.svg': 'speaker-lg-soundbar-frozen.svg',
    'speaker-lg-soundbar.svg': 'speaker-lg-soundbar.svg',
    'speaker-lg.svg': 'speaker-lg.svg',
    'speaker-logitech-frozen.svg': 'speaker-logitech-frozen.svg',
    'speaker-logitech-squeezebox-frozen.svg':
      'speaker-logitech-squeezebox-frozen.svg',
    'speaker-logitech-squeezebox.svg': 'speaker-logitech-squeezebox.svg',
    'speaker-logitech-squeezeboxboom-frozen.svg':
      'speaker-logitech-squeezeboxboom-frozen.svg',
    'speaker-logitech-squeezeboxboom.svg':
      'speaker-logitech-squeezeboxboom.svg',
    'speaker-logitech-squeezeboxduet-frozen.svg':
      'speaker-logitech-squeezeboxduet-frozen.svg',
    'speaker-logitech-squeezeboxduet.svg':
      'speaker-logitech-squeezeboxduet.svg',
    'speaker-logitech-squeezeboxradio-frozen.svg':
      'speaker-logitech-squeezeboxradio-frozen.svg',
    'speaker-logitech-squeezeboxradio.svg':
      'speaker-logitech-squeezeboxradio.svg',
    'speaker-logitech-squeezeboxtouch-frozen.svg':
      'speaker-logitech-squeezeboxtouch-frozen.svg',
    'speaker-logitech-squeezeboxtouch.svg':
      'speaker-logitech-squeezeboxtouch.svg',
    'speaker-logitech-transporter-frozen.svg':
      'speaker-logitech-transporter-frozen.svg',
    'speaker-logitech-transporter.svg': 'speaker-logitech-transporter.svg',
    'speaker-logitech.svg': 'speaker-logitech.svg',
    'speaker-marantz-frozen.svg': 'speaker-marantz-frozen.svg',
    'speaker-marantz-pm7000n-frozen.svg': 'speaker-marantz-pm7000n-frozen.svg',
    'speaker-marantz-pm7000n.svg': 'speaker-marantz-pm7000n.svg',
    'speaker-marantz.svg': 'speaker-marantz.svg',
    'speaker-marshall-acton-frozen.svg': 'speaker-marshall-acton-frozen.svg',
    'speaker-marshall-acton.svg': 'speaker-marshall-acton.svg',
    'speaker-marshall-frozen.svg': 'speaker-marshall-frozen.svg',
    'speaker-marshall-stanmore-frozen.svg':
      'speaker-marshall-stanmore-frozen.svg',
    'speaker-marshall-stanmore.svg': 'speaker-marshall-stanmore.svg',
    'speaker-marshall-uxbridge-frozen.svg':
      'speaker-marshall-uxbridge-frozen.svg',
    'speaker-marshall-uxbridge.svg': 'speaker-marshall-uxbridge.svg',
    'speaker-marshall.svg': 'speaker-marshall.svg',
    'speaker-naim-frozen.svg': 'speaker-naim-frozen.svg',
    'speaker-naim-muso-frozen.svg': 'speaker-naim-muso-frozen.svg',
    'speaker-naim-muso.svg': 'speaker-naim-muso.svg',
    'speaker-naim-unitinova-frozen.svg': 'speaker-naim-unitinova-frozen.svg',
    'speaker-naim-unitinova.svg': 'speaker-naim-unitinova.svg',
    'speaker-naim.svg': 'speaker-naim.svg',
    'speaker-onn-frozen.svg': 'speaker-onn-frozen.svg',
    'speaker-onn-roku-frozen.svg': 'speaker-onn-roku-frozen.svg',
    'speaker-onn-roku.svg': 'speaker-onn-roku.svg',
    'speaker-onn.svg': 'speaker-onn.svg',
    'speaker-panasonic-frozen.svg': 'speaker-panasonic-frozen.svg',
    'speaker-panasonic-scga10-frozen.svg':
      'speaker-panasonic-scga10-frozen.svg',
    'speaker-panasonic-scga10.svg': 'speaker-panasonic-scga10.svg',
    'speaker-panasonic-schc20-frozen.svg':
      'speaker-panasonic-schc20-frozen.svg',
    'speaker-panasonic-schc20.svg': 'speaker-panasonic-schc20.svg',
    'speaker-panasonic-soundbar-frozen.svg':
      'speaker-panasonic-soundbar-frozen.svg',
    'speaker-panasonic-soundbar.svg': 'speaker-panasonic-soundbar.svg',
    'speaker-panasonic.svg': 'speaker-panasonic.svg',
    'speaker-polaroid-frozen.svg': 'speaker-polaroid-frozen.svg',
    'speaker-polaroid-pwf1002-frozen.svg':
      'speaker-polaroid-pwf1002-frozen.svg',
    'speaker-polaroid-pwf1002.svg': 'speaker-polaroid-pwf1002.svg',
    'speaker-polaroid.svg': 'speaker-polaroid.svg',
    'speaker-polkaudio-frozen.svg': 'speaker-polkaudio-frozen.svg',
    'speaker-polkaudio-magnifi-frozen.svg':
      'speaker-polkaudio-magnifi-frozen.svg',
    'speaker-polkaudio-magnifi.svg': 'speaker-polkaudio-magnifi.svg',
    'speaker-polkaudio-woodbourne-frozen.svg':
      'speaker-polkaudio-woodbourne-frozen.svg',
    'speaker-polkaudio-woodbourne.svg': 'speaker-polkaudio-woodbourne.svg',
    'speaker-polkaudio.svg': 'speaker-polkaudio.svg',
    'speaker-riva-festival-frozen.svg': 'speaker-riva-festival-frozen.svg',
    'speaker-riva-festival.svg': 'speaker-riva-festival.svg',
    'speaker-riva-frozen.svg': 'speaker-riva-frozen.svg',
    'speaker-riva.svg': 'speaker-riva.svg',
    'speaker-roberts-frozen.svg': 'speaker-roberts-frozen.svg',
    'speaker-roberts-stream-frozen.svg': 'speaker-roberts-stream-frozen.svg',
    'speaker-roberts-stream.svg': 'speaker-roberts-stream.svg',
    'speaker-roberts.svg': 'speaker-roberts.svg',
    'speaker-roku-frozen.svg': 'speaker-roku-frozen.svg',
    'speaker-roku-smart-frozen.svg': 'speaker-roku-smart-frozen.svg',
    'speaker-roku-smart.svg': 'speaker-roku-smart.svg',
    'speaker-roku-streambar-frozen.svg': 'speaker-roku-streambar-frozen.svg',
    'speaker-roku-streambar.svg': 'speaker-roku-streambar.svg',
    'speaker-roku.svg': 'speaker-roku.svg',
    'speaker-ruark-audio-frozen.svg': 'speaker-ruark-audio-frozen.svg',
    'speaker-ruark-audio.svg': 'speaker-ruark-audio.svg',
    'speaker-ruark-frozen.svg': 'speaker-ruark-frozen.svg',
    'speaker-ruark.svg': 'speaker-ruark.svg',
    'speaker-russound-frozen.svg': 'speaker-russound-frozen.svg',
    'speaker-russound-mca66-frozen.svg': 'speaker-russound-mca66-frozen.svg',
    'speaker-russound-mca66.svg': 'speaker-russound-mca66.svg',
    'speaker-russound-mca88x-frozen.svg': 'speaker-russound-mca88x-frozen.svg',
    'speaker-russound-mca88x.svg': 'speaker-russound-mca88x.svg',
    'speaker-russound.svg': 'speaker-russound.svg',
    'speaker-sagemcom-frozen.svg': 'speaker-sagemcom-frozen.svg',
    'speaker-sagemcom-soundbox-frozen.svg':
      'speaker-sagemcom-soundbox-frozen.svg',
    'speaker-sagemcom-soundbox.svg': 'speaker-sagemcom-soundbox.svg',
    'speaker-sagemcom.svg': 'speaker-sagemcom.svg',
    'speaker-samsung-audiodock-frozen.svg':
      'speaker-samsung-audiodock-frozen.svg',
    'speaker-samsung-audiodock.svg': 'speaker-samsung-audiodock.svg',
    'speaker-samsung-frozen.svg': 'speaker-samsung-frozen.svg',
    'speaker-samsung-soundbar-frozen.svg':
      'speaker-samsung-soundbar-frozen.svg',
    'speaker-samsung-soundbar.svg': 'speaker-samsung-soundbar.svg',
    'speaker-samsung-wirelessmultiroomspeaker-frozen.svg':
      'speaker-samsung-wirelessmultiroomspeaker-frozen.svg',
    'speaker-samsung-wirelessmultiroomspeaker.svg':
      'speaker-samsung-wirelessmultiroomspeaker.svg',
    'speaker-samsung.svg': 'speaker-samsung.svg',
    'speaker-sharperimage-frozen.svg': 'speaker-sharperimage-frozen.svg',
    'speaker-sharperimage-swf1002-frozen.svg':
      'speaker-sharperimage-swf1002-frozen.svg',
    'speaker-sharperimage-swf1002.svg': 'speaker-sharperimage-swf1002.svg',
    'speaker-sharperimage-towerspeaker-frozen.svg':
      'speaker-sharperimage-towerspeaker-frozen.svg',
    'speaker-sharperimage-towerspeaker.svg':
      'speaker-sharperimage-towerspeaker.svg',
    'speaker-sharperimage.svg': 'speaker-sharperimage.svg',
    'speaker-sonos-amp-frozen.svg': 'speaker-sonos-amp-frozen.svg',
    'speaker-sonos-amp.svg': 'speaker-sonos-amp.svg',
    'speaker-sonos-arc-frozen.svg': 'speaker-sonos-arc-frozen.svg',
    'speaker-sonos-arc.svg': 'speaker-sonos-arc.svg',
    'speaker-sonos-beam-frozen.svg': 'speaker-sonos-beam-frozen.svg',
    'speaker-sonos-beam.svg': 'speaker-sonos-beam.svg',
    'speaker-sonos-boost-frozen.svg': 'speaker-sonos-boost-frozen.svg',
    'speaker-sonos-boost.svg': 'speaker-sonos-boost.svg',
    'speaker-sonos-connect-frozen.svg': 'speaker-sonos-connect-frozen.svg',
    'speaker-sonos-connect.svg': 'speaker-sonos-connect.svg',
    'speaker-sonos-connectamp-frozen.svg':
      'speaker-sonos-connectamp-frozen.svg',
    'speaker-sonos-connectamp.svg': 'speaker-sonos-connectamp.svg',
    'speaker-sonos-dock-frozen.svg': 'speaker-sonos-dock-frozen.svg',
    'speaker-sonos-dock.svg': 'speaker-sonos-dock.svg',
    'speaker-sonos-frozen.svg': 'speaker-sonos-frozen.svg',
    'speaker-sonos-move-frozen.svg': 'speaker-sonos-move-frozen.svg',
    'speaker-sonos-move.svg': 'speaker-sonos-move.svg',
    'speaker-sonos-one-frozen.svg': 'speaker-sonos-one-frozen.svg',
    'speaker-sonos-one.svg': 'speaker-sonos-one.svg',
    'speaker-sonos-play1-frozen.svg': 'speaker-sonos-play1-frozen.svg',
    'speaker-sonos-play1.svg': 'speaker-sonos-play1.svg',
    'speaker-sonos-play3-frozen.svg': 'speaker-sonos-play3-frozen.svg',
    'speaker-sonos-play3.svg': 'speaker-sonos-play3.svg',
    'speaker-sonos-play5-frozen.svg': 'speaker-sonos-play5-frozen.svg',
    'speaker-sonos-play5.svg': 'speaker-sonos-play5.svg',
    'speaker-sonos-playbar-frozen.svg': 'speaker-sonos-playbar-frozen.svg',
    'speaker-sonos-playbar.svg': 'speaker-sonos-playbar.svg',
    'speaker-sonos-playbase-frozen.svg': 'speaker-sonos-playbase-frozen.svg',
    'speaker-sonos-playbase.svg': 'speaker-sonos-playbase.svg',
    'speaker-sonos-port-frozen.svg': 'speaker-sonos-port-frozen.svg',
    'speaker-sonos-port.svg': 'speaker-sonos-port.svg',
    'speaker-sonos-soundbar-frozen.svg': 'speaker-sonos-soundbar-frozen.svg',
    'speaker-sonos-soundbar.svg': 'speaker-sonos-soundbar.svg',
    'speaker-sonos-sub-frozen.svg': 'speaker-sonos-sub-frozen.svg',
    'speaker-sonos-sub.svg': 'speaker-sonos-sub.svg',
    'speaker-sonos-symfoniskwifispeaker-frozen.svg':
      'speaker-sonos-symfoniskwifispeaker-frozen.svg',
    'speaker-sonos-symfoniskwifispeaker.svg':
      'speaker-sonos-symfoniskwifispeaker.svg',
    'speaker-sonos-zoneplayer-frozen.svg':
      'speaker-sonos-zoneplayer-frozen.svg',
    'speaker-sonos-zoneplayer.svg': 'speaker-sonos-zoneplayer.svg',
    'speaker-sonos.svg': 'speaker-sonos.svg',
    'speaker-sony-frozen.svg': 'speaker-sony-frozen.svg',
    'speaker-sony-smartspeaker-frozen.svg':
      'speaker-sony-smartspeaker-frozen.svg',
    'speaker-sony-smartspeaker.svg': 'speaker-sony-smartspeaker.svg',
    'speaker-sony-soundbar-frozen.svg': 'speaker-sony-soundbar-frozen.svg',
    'speaker-sony-soundbar.svg': 'speaker-sony-soundbar.svg',
    'speaker-sony.svg': 'speaker-sony.svg',
    'speaker-tmall-frozen.svg': 'speaker-tmall-frozen.svg',
    'speaker-tmall.svg': 'speaker-tmall.svg',
    'speaker-tmalll-genie-frozen.svg': 'speaker-tmalll-genie-frozen.svg',
    'speaker-tmalll-genie.svg': 'speaker-tmalll-genie.svg',
    'speaker-ultimateears-frozen.svg': 'speaker-ultimateears-frozen.svg',
    'speaker-ultimateears-megablast-frozen.svg':
      'speaker-ultimateears-megablast-frozen.svg',
    'speaker-ultimateears-megablast.svg': 'speaker-ultimateears-megablast.svg',
    'speaker-ultimateears.svg': 'speaker-ultimateears.svg',
    'speaker-varo-cube-frozen.svg': 'speaker-varo-cube-frozen.svg',
    'speaker-varo-cube.svg': 'speaker-varo-cube.svg',
    'speaker-varo-frozen.svg': 'speaker-varo-frozen.svg',
    'speaker-varo-sidekick-frozen.svg': 'speaker-varo-sidekick-frozen.svg',
    'speaker-varo-sidekick.svg': 'speaker-varo-sidekick.svg',
    'speaker-varo.svg': 'speaker-varo.svg',
    'speaker-vizio-frozen.svg': 'speaker-vizio-frozen.svg',
    'speaker-vizio-soundbar-frozen.svg': 'speaker-vizio-soundbar-frozen.svg',
    'speaker-vizio-soundbar.svg': 'speaker-vizio-soundbar.svg',
    'speaker-vizio.svg': 'speaker-vizio.svg',
    'speaker-vodafone-atika-frozen.svg': 'speaker-vodafone-atika-frozen.svg',
    'speaker-vodafone-atika.svg': 'speaker-vodafone-atika.svg',
    'speaker-vodafone-frozen.svg': 'speaker-vodafone-frozen.svg',
    'speaker-vodafone.svg': 'speaker-vodafone.svg',
    'speaker-yamaha-frozen.svg': 'speaker-yamaha-frozen.svg',
    'speaker-yamaha-soundbar-frozen.svg': 'speaker-yamaha-soundbar-frozen.svg',
    'speaker-yamaha-soundbar.svg': 'speaker-yamaha-soundbar.svg',
    'speaker-yamaha.svg': 'speaker-yamaha.svg',
    'speaker-yoto-frozen.svg': 'speaker-yoto-frozen.svg',
    'speaker-yoto-player-frozen.svg': 'speaker-yoto-player-frozen.svg',
    'speaker-yoto-player.svg': 'speaker-yoto-player.svg',
    'speaker-yoto.svg': 'speaker-yoto.svg',
    'speaker-zeppelin-frozen.svg': 'speaker-zeppelin-frozen.svg',
    'speaker-zeppelin-wirelessspeaker-frozen.svg':
      'speaker-zeppelin-wirelessspeaker-frozen.svg',
    'speaker-zeppelin-wirelessspeaker.svg':
      'speaker-zeppelin-wirelessspeaker.svg',
    'speaker-zeppelin.svg': 'speaker-zeppelin.svg',
    'speaker-zolo-frozen.svg': 'speaker-zolo-frozen.svg',
    'speaker-zolo.svg': 'speaker-zolo.svg',
    'speaker.svg': 'speaker.svg',
    'sprinkler-blossom-frozen.svg': 'sprinkler-blossom-frozen.svg',
    'sprinkler-blossom.svg': 'sprinkler-blossom.svg',
    'sprinkler-frozen.svg': 'sprinkler-frozen.svg',
    'sprinkler-melnor-aquatimer-frozen.svg':
      'sprinkler-melnor-aquatimer-frozen.svg',
    'sprinkler-melnor-aquatimer.svg': 'sprinkler-melnor-aquatimer.svg',
    'sprinkler-melnor-raincloud-frozen.svg':
      'sprinkler-melnor-raincloud-frozen.svg',
    'sprinkler-melnor-raincloud.svg': 'sprinkler-melnor-raincloud.svg',
    'sprinkler-orbitirrigation-frozen.svg':
      'sprinkler-orbitirrigation-frozen.svg',
    'sprinkler-orbitirrigation.svg': 'sprinkler-orbitirrigation.svg',
    'sprinkler-rachio-frozen.svg': 'sprinkler-rachio-frozen.svg',
    'sprinkler-rachio.svg': 'sprinkler-rachio.svg',
    'sprinkler-rainmachine-frozen.svg': 'sprinkler-rainmachine-frozen.svg',
    'sprinkler-rainmachine.svg': 'sprinkler-rainmachine.svg',
    'sprinkler-scotts-grocontroller-frozen.svg':
      'sprinkler-scotts-grocontroller-frozen.svg',
    'sprinkler-scotts-grocontroller.svg': 'sprinkler-scotts-grocontroller.svg',
    'sprinkler-skydrop-sprinklercontroller-frozen.svg':
      'sprinkler-skydrop-sprinklercontroller-frozen.svg',
    'sprinkler-skydrop-sprinklercontroller.svg':
      'sprinkler-skydrop-sprinklercontroller.svg',
    'sprinkler.svg': 'sprinkler.svg',
    'storage-buffalo-linkstation-frozen.svg':
      'storage-buffalo-linkstation-frozen.svg',
    'storage-buffalo-linkstation.svg': 'storage-buffalo-linkstation.svg',
    'storage-frozen.svg': 'storage-frozen.svg',
    'storage.svg': 'storage.svg',
    'tablet-aila-sitplay-frozen.svg': 'tablet-aila-sitplay-frozen.svg',
    'tablet-aila-sitplay.svg': 'tablet-aila-sitplay.svg',
    'tablet-alcatel-1t-frozen.svg': 'tablet-alcatel-1t-frozen.svg',
    'tablet-alcatel-1t.svg': 'tablet-alcatel-1t.svg',
    'tablet-alcatel-joytab-frozen.svg': 'tablet-alcatel-joytab-frozen.svg',
    'tablet-alcatel-joytab.svg': 'tablet-alcatel-joytab.svg',
    'tablet-amazon-fire-frozen.svg': 'tablet-amazon-fire-frozen.svg',
    'tablet-amazon-fire.svg': 'tablet-amazon-fire.svg',
    'tablet-amazon-frozen.svg': 'tablet-amazon-frozen.svg',
    'tablet-amazon.svg': 'tablet-amazon.svg',
    'tablet-android-frozen.svg': 'tablet-android-frozen.svg',
    'tablet-android.svg': 'tablet-android.svg',
    'tablet-apple-ipad-frozen.svg': 'tablet-apple-ipad-frozen.svg',
    'tablet-apple-ipad-this-frozen.svg': 'tablet-apple-ipad-this-frozen.svg',
    'tablet-apple-ipad-this.svg': 'tablet-apple-ipad-this.svg',
    'tablet-apple-ipad.svg': 'tablet-apple-ipad.svg',
    'tablet-archos-oxygen-frozen.svg': 'tablet-archos-oxygen-frozen.svg',
    'tablet-archos-oxygen.svg': 'tablet-archos-oxygen.svg',
    'tablet-asus-memopad-frozen.svg': 'tablet-asus-memopad-frozen.svg',
    'tablet-asus-memopad.svg': 'tablet-asus-memopad.svg',
    'tablet-asus-zenpad-frozen.svg': 'tablet-asus-zenpad-frozen.svg',
    'tablet-asus-zenpad.svg': 'tablet-asus-zenpad.svg',
    'tablet-azpen-tablet-frozen.svg': 'tablet-azpen-tablet-frozen.svg',
    'tablet-azpen-tablet.svg': 'tablet-azpen-tablet.svg',
    'tablet-bdf-phablet-frozen.svg': 'tablet-bdf-phablet-frozen.svg',
    'tablet-bdf-phablet.svg': 'tablet-bdf-phablet.svg',
    'tablet-blackberry-playbook-frozen.svg':
      'tablet-blackberry-playbook-frozen.svg',
    'tablet-blackberry-playbook.svg': 'tablet-blackberry-playbook.svg',
    'tablet-bosch-ads-frozen.svg': 'tablet-bosch-ads-frozen.svg',
    'tablet-bosch-ads.svg': 'tablet-bosch-ads.svg',
    'tablet-chuwi-hi9-frozen.svg': 'tablet-chuwi-hi9-frozen.svg',
    'tablet-chuwi-hi9.svg': 'tablet-chuwi-hi9.svg',
    'tablet-control4-touchscreen-frozen.svg':
      'tablet-control4-touchscreen-frozen.svg',
    'tablet-control4-touchscreen.svg': 'tablet-control4-touchscreen.svg',
    'tablet-craig-cmp828-frozen.svg': 'tablet-craig-cmp828-frozen.svg',
    'tablet-craig-cmp828.svg': 'tablet-craig-cmp828.svg',
    'tablet-delphin-microtablet-frozen.svg':
      'tablet-delphin-microtablet-frozen.svg',
    'tablet-delphin-microtablet.svg': 'tablet-delphin-microtablet.svg',
    'tablet-digiland-tablet-frozen.svg': 'tablet-digiland-tablet-frozen.svg',
    'tablet-digiland-tablet.svg': 'tablet-digiland-tablet.svg',
    'tablet-ematic-pbsplaytimepad-frozen.svg':
      'tablet-ematic-pbsplaytimepad-frozen.svg',
    'tablet-ematic-pbsplaytimepad.svg': 'tablet-ematic-pbsplaytimepad.svg',
    'tablet-ematic-tablet-frozen.svg': 'tablet-ematic-tablet-frozen.svg',
    'tablet-ematic-tablet.svg': 'tablet-ematic-tablet.svg',
    'tablet-epad-tablet-frozen.svg': 'tablet-epad-tablet-frozen.svg',
    'tablet-epad-tablet.svg': 'tablet-epad-tablet.svg',
    'tablet-epik-highq-frozen.svg': 'tablet-epik-highq-frozen.svg',
    'tablet-epik-highq.svg': 'tablet-epik-highq.svg',
    'tablet-facebook-frozen.svg': 'tablet-facebook-frozen.svg',
    'tablet-facebook-portal+-frozen.svg': 'tablet-facebook-portal+-frozen.svg',
    'tablet-facebook-portal+.svg': 'tablet-facebook-portal+.svg',
    'tablet-facebook-portal-frozen.svg': 'tablet-facebook-portal-frozen.svg',
    'tablet-facebook-portal.svg': 'tablet-facebook-portal.svg',
    'tablet-facebook-portalmini-frozen.svg':
      'tablet-facebook-portalmini-frozen.svg',
    'tablet-facebook-portalmini.svg': 'tablet-facebook-portalmini.svg',
    'tablet-facebook.svg': 'tablet-facebook.svg',
    'tablet-frozen.svg': 'tablet-frozen.svg',
    'tablet-fujitsu-arrowstab-frozen.svg':
      'tablet-fujitsu-arrowstab-frozen.svg',
    'tablet-fujitsu-arrowstab.svg': 'tablet-fujitsu-arrowstab.svg',
    'tablet-google-frozen.svg': 'tablet-google-frozen.svg',
    'tablet-google-nexus-frozen.svg': 'tablet-google-nexus-frozen.svg',
    'tablet-google-nexus.svg': 'tablet-google-nexus.svg',
    'tablet-google-pixel-frozen.svg': 'tablet-google-pixel-frozen.svg',
    'tablet-google-pixel.svg': 'tablet-google-pixel.svg',
    'tablet-google.svg': 'tablet-google.svg',
    'tablet-googlenest-hub-frozen.svg': 'tablet-googlenest-hub-frozen.svg',
    'tablet-googlenest-hub.svg': 'tablet-googlenest-hub.svg',
    'tablet-hoozo-tablet-frozen.svg': 'tablet-hoozo-tablet-frozen.svg',
    'tablet-hoozo-tablet.svg': 'tablet-hoozo-tablet.svg',
    'tablet-huawei-dtab-frozen.svg': 'tablet-huawei-dtab-frozen.svg',
    'tablet-huawei-dtab.svg': 'tablet-huawei-dtab.svg',
    'tablet-huawei-dtabcompact-frozen.svg':
      'tablet-huawei-dtabcompact-frozen.svg',
    'tablet-huawei-dtabcompact.svg': 'tablet-huawei-dtabcompact.svg',
    'tablet-huawei-frozen.svg': 'tablet-huawei-frozen.svg',
    'tablet-huawei-mediapad-frozen.svg': 'tablet-huawei-mediapad-frozen.svg',
    'tablet-huawei-mediapad.svg': 'tablet-huawei-mediapad.svg',
    'tablet-huawei-quatab-frozen.svg': 'tablet-huawei-quatab-frozen.svg',
    'tablet-huawei-quatab.svg': 'tablet-huawei-quatab.svg',
    'tablet-huawei.svg': 'tablet-huawei.svg',
    'tablet-insignia-flextouchscreentabletlaptop-frozen.svg':
      'tablet-insignia-flextouchscreentabletlaptop-frozen.svg',
    'tablet-insignia-flextouchscreentabletlaptop.svg':
      'tablet-insignia-flextouchscreentabletlaptop.svg',
    'tablet-insignia-tablet-frozen.svg': 'tablet-insignia-tablet-frozen.svg',
    'tablet-insignia-tablet.svg': 'tablet-insignia-tablet.svg',
    'tablet-jty-tablet-frozen.svg': 'tablet-jty-tablet-frozen.svg',
    'tablet-jty-tablet.svg': 'tablet-jty-tablet.svg',
    'tablet-kte-tyd108h-frozen.svg': 'tablet-kte-tyd108h-frozen.svg',
    'tablet-kte-tyd108h.svg': 'tablet-kte-tyd108h.svg',
    'tablet-kurio-next-frozen.svg': 'tablet-kurio-next-frozen.svg',
    'tablet-kurio-next.svg': 'tablet-kurio-next.svg',
    'tablet-kyocera-quatab-frozen.svg': 'tablet-kyocera-quatab-frozen.svg',
    'tablet-kyocera-quatab.svg': 'tablet-kyocera-quatab.svg',
    'tablet-kyocera-szj-frozen.svg': 'tablet-kyocera-szj-frozen.svg',
    'tablet-kyocera-szj.svg': 'tablet-kyocera-szj.svg',
    'tablet-leapfrog-epic-frozen.svg': 'tablet-leapfrog-epic-frozen.svg',
    'tablet-leapfrog-epic.svg': 'tablet-leapfrog-epic.svg',
    'tablet-leapfrog-leappad-frozen.svg': 'tablet-leapfrog-leappad-frozen.svg',
    'tablet-leapfrog-leappad.svg': 'tablet-leapfrog-leappad.svg',
    'tablet-lenovo-everypad-frozen.svg': 'tablet-lenovo-everypad-frozen.svg',
    'tablet-lenovo-everypad.svg': 'tablet-lenovo-everypad.svg',
    'tablet-lenovo-tab3-frozen.svg': 'tablet-lenovo-tab3-frozen.svg',
    'tablet-lenovo-tab3.svg': 'tablet-lenovo-tab3.svg',
    'tablet-lenovo-tab4-frozen.svg': 'tablet-lenovo-tab4-frozen.svg',
    'tablet-lenovo-tab4.svg': 'tablet-lenovo-tab4.svg',
    'tablet-lenovo-tablet-frozen.svg': 'tablet-lenovo-tablet-frozen.svg',
    'tablet-lenovo-tablet.svg': 'tablet-lenovo-tablet.svg',
    'tablet-lenovo-thinkpad-frozen.svg': 'tablet-lenovo-thinkpad-frozen.svg',
    'tablet-lenovo-thinkpad.svg': 'tablet-lenovo-thinkpad.svg',
    'tablet-lg-gpad-frozen.svg': 'tablet-lg-gpad-frozen.svg',
    'tablet-lg-gpad.svg': 'tablet-lg-gpad.svg',
    'tablet-maitai-mt107-frozen.svg': 'tablet-maitai-mt107-frozen.svg',
    'tablet-maitai-mt107.svg': 'tablet-maitai-mt107.svg',
    'tablet-manjee-mj108-frozen.svg': 'tablet-manjee-mj108-frozen.svg',
    'tablet-manjee-mj108.svg': 'tablet-manjee-mj108.svg',
    'tablet-mediacom-smartpad-frozen.svg':
      'tablet-mediacom-smartpad-frozen.svg',
    'tablet-mediacom-smartpad.svg': 'tablet-mediacom-smartpad.svg',
    'tablet-mediatek-k960-frozen.svg': 'tablet-mediatek-k960-frozen.svg',
    'tablet-mediatek-k960.svg': 'tablet-mediatek-k960.svg',
    'tablet-mediatek-t906-frozen.svg': 'tablet-mediatek-t906-frozen.svg',
    'tablet-mediatek-t906.svg': 'tablet-mediatek-t906.svg',
    'tablet-mediatek-zh960-frozen.svg': 'tablet-mediatek-zh960-frozen.svg',
    'tablet-mediatek-zh960.svg': 'tablet-mediatek-zh960.svg',
    'tablet-medion-lifetab-frozen.svg': 'tablet-medion-lifetab-frozen.svg',
    'tablet-medion-lifetab.svg': 'tablet-medion-lifetab.svg',
    'tablet-microsoft-surface-frozen.svg':
      'tablet-microsoft-surface-frozen.svg',
    'tablet-microsoft-surface.svg': 'tablet-microsoft-surface.svg',
    'tablet-nabi-tablet-1.svg': 'tablet-nabi-tablet-1.svg',
    'tablet-nabi-tablet-frozen-1.svg': 'tablet-nabi-tablet-frozen-1.svg',
    'tablet-nabi-tablet-frozen.svg': 'tablet-nabi-tablet-frozen.svg',
    'tablet-nabi-tablet.svg': 'tablet-nabi-tablet.svg',
    'tablet-naxa-nid1050-frozen.svg': 'tablet-naxa-nid1050-frozen.svg',
    'tablet-naxa-nid1050.svg': 'tablet-naxa-nid1050.svg',
    'tablet-nec-lavietab-frozen.svg': 'tablet-nec-lavietab-frozen.svg',
    'tablet-nec-lavietab.svg': 'tablet-nec-lavietab.svg',
    'tablet-nextbook-ares-frozen.svg': 'tablet-nextbook-ares-frozen.svg',
    'tablet-nextbook-ares.svg': 'tablet-nextbook-ares.svg',
    'tablet-nextbook-frozen.svg': 'tablet-nextbook-frozen.svg',
    'tablet-nextbook.svg': 'tablet-nextbook.svg',
    'tablet-nordictrack-portal-frozen.svg':
      'tablet-nordictrack-portal-frozen.svg',
    'tablet-nordictrack-portal.svg': 'tablet-nordictrack-portal.svg',
    'tablet-onn-surftablet-frozen.svg': 'tablet-onn-surftablet-frozen.svg',
    'tablet-onn-surftablet.svg': 'tablet-onn-surftablet.svg',
    'tablet-onn-tablet-frozen.svg': 'tablet-onn-tablet-frozen.svg',
    'tablet-onn-tablet.svg': 'tablet-onn-tablet.svg',
    'tablet-pantech-frozen.svg': 'tablet-pantech-frozen.svg',
    'tablet-pantech.svg': 'tablet-pantech.svg',
    'tablet-pbskids-playtime-frozen.svg': 'tablet-pbskids-playtime-frozen.svg',
    'tablet-pbskids-playtime.svg': 'tablet-pbskids-playtime.svg',
    'tablet-phablet-frozen.svg': 'tablet-phablet-frozen.svg',
    'tablet-phablet-this-frozen.svg': 'tablet-phablet-this-frozen.svg',
    'tablet-phablet-this.svg': 'tablet-phablet-this.svg',
    'tablet-phablet.svg': 'tablet-phablet.svg',
    'tablet-pipo-tablet-frozen.svg': 'tablet-pipo-tablet-frozen.svg',
    'tablet-pipo-tablet.svg': 'tablet-pipo-tablet.svg',
    'tablet-qere-qr8-frozen.svg': 'tablet-qere-qr8-frozen.svg',
    'tablet-qere-qr8.svg': 'tablet-qere-qr8.svg',
    'tablet-qilive-tablet-frozen.svg': 'tablet-qilive-tablet-frozen.svg',
    'tablet-qilive-tablet.svg': 'tablet-qilive-tablet.svg',
    'tablet-rca-pro12-frozen.svg': 'tablet-rca-pro12-frozen.svg',
    'tablet-rca-pro12.svg': 'tablet-rca-pro12.svg',
    'tablet-rca-tablet-frozen.svg': 'tablet-rca-tablet-frozen.svg',
    'tablet-rca-tablet.svg': 'tablet-rca-tablet.svg',
    'tablet-remarkable-papertablet-frozen.svg':
      'tablet-remarkable-papertablet-frozen.svg',
    'tablet-remarkable-papertablet.svg': 'tablet-remarkable-papertablet.svg',
    'tablet-samsung-galaxy-frozen.svg': 'tablet-samsung-galaxy-frozen.svg',
    'tablet-samsung-galaxy.svg': 'tablet-samsung-galaxy.svg',
    'tablet-samsung-galaxybook-frozen.svg':
      'tablet-samsung-galaxybook-frozen.svg',
    'tablet-samsung-galaxybook.svg': 'tablet-samsung-galaxybook.svg',
    'tablet-samsung-galaxytab-frozen.svg':
      'tablet-samsung-galaxytab-frozen.svg',
    'tablet-samsung-galaxytab.svg': 'tablet-samsung-galaxytab.svg',
    'tablet-samsung-galaxyview-frozen.svg':
      'tablet-samsung-galaxyview-frozen.svg',
    'tablet-samsung-galaxyview.svg': 'tablet-samsung-galaxyview.svg',
    'tablet-samsung-gizmotablet-frozen.svg':
      'tablet-samsung-gizmotablet-frozen.svg',
    'tablet-samsung-gizmotablet.svg': 'tablet-samsung-gizmotablet.svg',
    'tablet-sanyo-benesse-frozen.svg': 'tablet-sanyo-benesse-frozen.svg',
    'tablet-sanyo-benesse.svg': 'tablet-sanyo-benesse.svg',
    'tablet-sharp-aquospad-frozen.svg': 'tablet-sharp-aquospad-frozen.svg',
    'tablet-sharp-aquospad.svg': 'tablet-sharp-aquospad.svg',
    'tablet-sharp-galapagos-frozen.svg': 'tablet-sharp-galapagos-frozen.svg',
    'tablet-sharp-galapagos.svg': 'tablet-sharp-galapagos.svg',
    'tablet-shenzhen-tablet-frozen.svg': 'tablet-shenzhen-tablet-frozen.svg',
    'tablet-shenzhen-tablet.svg': 'tablet-shenzhen-tablet.svg',
    'tablet-slate-8-frozen.svg': 'tablet-slate-8-frozen.svg',
    'tablet-slate-8.svg': 'tablet-slate-8.svg',
    'tablet-smartab-st1009-frozen.svg': 'tablet-smartab-st1009-frozen.svg',
    'tablet-smartab-st1009.svg': 'tablet-smartab-st1009.svg',
    'tablet-smartak-tab910-frozen.svg': 'tablet-smartak-tab910-frozen.svg',
    'tablet-smartak-tab910.svg': 'tablet-smartak-tab910.svg',
    'tablet-softwinerevb-tablet-frozen.svg':
      'tablet-softwinerevb-tablet-frozen.svg',
    'tablet-softwinerevb-tablet.svg': 'tablet-softwinerevb-tablet.svg',
    'tablet-sony-xperia-frozen.svg': 'tablet-sony-xperia-frozen.svg',
    'tablet-sony-xperia.svg': 'tablet-sony-xperia.svg',
    'tablet-sproutchannel-cubby-frozen.svg':
      'tablet-sproutchannel-cubby-frozen.svg',
    'tablet-sproutchannel-cubby.svg': 'tablet-sproutchannel-cubby.svg',
    'tablet-sylvania-sltdvd1023-frozen.svg':
      'tablet-sylvania-sltdvd1023-frozen.svg',
    'tablet-sylvania-sltdvd1023.svg': 'tablet-sylvania-sltdvd1023.svg',
    'tablet-tagital-t10k-frozen.svg': 'tablet-tagital-t10k-frozen.svg',
    'tablet-tagital-t10k.svg': 'tablet-tagital-t10k.svg',
    'tablet-this-frozen.svg': 'tablet-this-frozen.svg',
    'tablet-this.svg': 'tablet-this.svg',
    'tablet-trimble-t10-frozen.svg': 'tablet-trimble-t10-frozen.svg',
    'tablet-trimble-t10.svg': 'tablet-trimble-t10.svg',
    'tablet-verizon-ellipsis-frozen.svg': 'tablet-verizon-ellipsis-frozen.svg',
    'tablet-verizon-ellipsis.svg': 'tablet-verizon-ellipsis.svg',
    'tablet-verizon-gizmotab-frozen.svg': 'tablet-verizon-gizmotab-frozen.svg',
    'tablet-verizon-gizmotab.svg': 'tablet-verizon-gizmotab.svg',
    'tablet-visualland-prestige-frozen.svg':
      'tablet-visualland-prestige-frozen.svg',
    'tablet-visualland-prestige.svg': 'tablet-visualland-prestige.svg',
    'tablet-vizio-frozen.svg': 'tablet-vizio-frozen.svg',
    'tablet-vizio.svg': 'tablet-vizio.svg',
    'tablet-xiaomi-mipad-frozen.svg': 'tablet-xiaomi-mipad-frozen.svg',
    'tablet-xiaomi-mipad.svg': 'tablet-xiaomi-mipad.svg',
    'tablet-yuntab-k107-frozen.svg': 'tablet-yuntab-k107-frozen.svg',
    'tablet-yuntab-k107.svg': 'tablet-yuntab-k107.svg',
    'tablet-zonko-k106-frozen.svg': 'tablet-zonko-k106-frozen.svg',
    'tablet-zonko-k106.svg': 'tablet-zonko-k106.svg',
    'tablet-zte-grandx-frozen.svg': 'tablet-zte-grandx-frozen.svg',
    'tablet-zte-grandx.svg': 'tablet-zte-grandx.svg',
    'tablet-zte-trek-frozen.svg': 'tablet-zte-trek-frozen.svg',
    'tablet-zte-trek.svg': 'tablet-zte-trek.svg',
    'tablet-zte-zpad-frozen.svg': 'tablet-zte-zpad-frozen.svg',
    'tablet-zte-zpad.svg': 'tablet-zte-zpad.svg',
    'tablet.svg': 'tablet.svg',
    'thermostat-aprilaire-frozen.svg': 'thermostat-aprilaire-frozen.svg',
    'thermostat-aprilaire-thermostat-frozen.svg':
      'thermostat-aprilaire-thermostat-frozen.svg',
    'thermostat-aprilaire-thermostat.svg':
      'thermostat-aprilaire-thermostat.svg',
    'thermostat-aprilaire.svg': 'thermostat-aprilaire.svg',
    'thermostat-bosch-bcc100-frozen.svg': 'thermostat-bosch-bcc100-frozen.svg',
    'thermostat-bosch-bcc100.svg': 'thermostat-bosch-bcc100.svg',
    'thermostat-bosch-bcc50-frozen.svg': 'thermostat-bosch-bcc50-frozen.svg',
    'thermostat-bosch-bcc50.svg': 'thermostat-bosch-bcc50.svg',
    'thermostat-bticino-smarther-frozen.svg':
      'thermostat-bticino-smarther-frozen.svg',
    'thermostat-bticino-smarther.svg': 'thermostat-bticino-smarther.svg',
    'thermostat-danfosslink-frozen.svg': 'thermostat-danfosslink-frozen.svg',
    'thermostat-danfosslink.svg': 'thermostat-danfosslink.svg',
    'thermostat-ecobee-frozen.svg': 'thermostat-ecobee-frozen.svg',
    'thermostat-ecobee.svg': 'thermostat-ecobee.svg',
    'thermostat-eneco-toonthermostat-frozen.svg':
      'thermostat-eneco-toonthermostat-frozen.svg',
    'thermostat-eneco-toonthermostat.svg':
      'thermostat-eneco-toonthermostat.svg',
    'thermostat-engie-boxx-frozen.svg': 'thermostat-engie-boxx-frozen.svg',
    'thermostat-engie-boxx.svg': 'thermostat-engie-boxx.svg',
    'thermostat-flair-puck-frozen.svg': 'thermostat-flair-puck-frozen.svg',
    'thermostat-flair-puck.svg': 'thermostat-flair-puck.svg',
    'thermostat-frozen.svg': 'thermostat-frozen.svg',
    'thermostat-googlenest-frozen.svg': 'thermostat-googlenest-frozen.svg',
    'thermostat-googlenest.svg': 'thermostat-googlenest.svg',
    'thermostat-honeywell-frozen.svg': 'thermostat-honeywell-frozen.svg',
    'thermostat-honeywell.svg': 'thermostat-honeywell.svg',
    'thermostat-idevices-frozen.svg': 'thermostat-idevices-frozen.svg',
    'thermostat-idevices.svg': 'thermostat-idevices.svg',
    'thermostat-johnsoncontrols-glas-frozen.svg':
      'thermostat-johnsoncontrols-glas-frozen.svg',
    'thermostat-johnsoncontrols-glas.svg':
      'thermostat-johnsoncontrols-glas.svg',
    'thermostat-lux-konosmartthermostat-frozen.svg':
      'thermostat-lux-konosmartthermostat-frozen.svg',
    'thermostat-lux-konosmartthermostat.svg':
      'thermostat-lux-konosmartthermostat.svg',
    'thermostat-mysa-frozen.svg': 'thermostat-mysa-frozen.svg',
    'thermostat-mysa.svg': 'thermostat-mysa.svg',
    'thermostat-netatmo-smartthermostat-frozen.svg':
      'thermostat-netatmo-smartthermostat-frozen.svg',
    'thermostat-netatmo-smartthermostat.svg':
      'thermostat-netatmo-smartthermostat.svg',
    'thermostat-nvent-nuheat-frozen.svg': 'thermostat-nvent-nuheat-frozen.svg',
    'thermostat-nvent-nuheat.svg': 'thermostat-nvent-nuheat.svg',
    'thermostat-pro1-wifithermostat-frozen.svg':
      'thermostat-pro1-wifithermostat-frozen.svg',
    'thermostat-pro1-wifithermostat.svg': 'thermostat-pro1-wifithermostat.svg',
    'thermostat-radio-frozen.svg': 'thermostat-radio-frozen.svg',
    'thermostat-radio.svg': 'thermostat-radio.svg',
    'thermostat-sensi-frozen.svg': 'thermostat-sensi-frozen.svg',
    'thermostat-sensi.svg': 'thermostat-sensi.svg',
    'thermostat-trane-frozen.svg': 'thermostat-trane-frozen.svg',
    'thermostat-trane.svg': 'thermostat-trane.svg',
    'thermostat-wyze-thermostat-frozen.svg':
      'thermostat-wyze-thermostat-frozen.svg',
    'thermostat-wyze-thermostat.svg': 'thermostat-wyze-thermostat.svg',
    'thermostat.svg': 'thermostat.svg',
    'toy-frozen.svg': 'toy-frozen.svg',
    'toy.svg': 'toy.svg',
    'tv-akai-tv-frozen.svg': 'tv-akai-tv-frozen.svg',
    'tv-akai-tv.svg': 'tv-akai-tv.svg',
    'tv-aldinord-smarttv-frozen.svg': 'tv-aldinord-smarttv-frozen.svg',
    'tv-aldinord-smarttv.svg': 'tv-aldinord-smarttv.svg',
    'tv-amazon-fire-frozen.svg': 'tv-amazon-fire-frozen.svg',
    'tv-amazon-fire.svg': 'tv-amazon-fire.svg',
    'tv-amazon-firetv-frozen.svg': 'tv-amazon-firetv-frozen.svg',
    'tv-amazon-firetv.svg': 'tv-amazon-firetv.svg',
    'tv-argos-bushtv-frozen.svg': 'tv-argos-bushtv-frozen.svg',
    'tv-argos-bushtv.svg': 'tv-argos-bushtv.svg',
    'tv-atron-tv-frozen.svg': 'tv-atron-tv-frozen.svg',
    'tv-atron-tv.svg': 'tv-atron-tv.svg',
    'tv-bangolufsen-beoplay-frozen.svg': 'tv-bangolufsen-beoplay-frozen.svg',
    'tv-bangolufsen-beoplay.svg': 'tv-bangolufsen-beoplay.svg',
    'tv-bangolufsen-beovision-frozen.svg':
      'tv-bangolufsen-beovision-frozen.svg',
    'tv-bangolufsen-beovision.svg': 'tv-bangolufsen-beovision.svg',
    'tv-digihome-smarttv-frozen.svg': 'tv-digihome-smarttv-frozen.svg',
    'tv-digihome-smarttv.svg': 'tv-digihome-smarttv.svg',
    'tv-durabase-smarttv-frozen.svg': 'tv-durabase-smarttv-frozen.svg',
    'tv-durabase-smarttv.svg': 'tv-durabase-smarttv.svg',
    'tv-edenwood-smarttv-frozen.svg': 'tv-edenwood-smarttv-frozen.svg',
    'tv-edenwood-smarttv.svg': 'tv-edenwood-smarttv.svg',
    'tv-electronia-smarttv-frozen.svg': 'tv-electronia-smarttv-frozen.svg',
    'tv-electronia-smarttv.svg': 'tv-electronia-smarttv.svg',
    'tv-element-rokutv-frozen.svg': 'tv-element-rokutv-frozen.svg',
    'tv-element-rokutv.svg': 'tv-element-rokutv.svg',
    'tv-finlux-smarttvv-frozen.svg': 'tv-finlux-smarttvv-frozen.svg',
    'tv-finlux-smarttvv.svg': 'tv-finlux-smarttvv.svg',
    'tv-frozen.svg': 'tv-frozen.svg',
    'tv-fu-smarttv-frozen.svg': 'tv-fu-smarttv-frozen.svg',
    'tv-fu-smarttv.svg': 'tv-fu-smarttv.svg',
    'tv-funai-tv-frozen.svg': 'tv-funai-tv-frozen.svg',
    'tv-funai-tv.svg': 'tv-funai-tv.svg',
    'tv-grundig-firetv-frozen.svg': 'tv-grundig-firetv-frozen.svg',
    'tv-grundig-firetv.svg': 'tv-grundig-firetv.svg',
    'tv-grundig-vision6-frozen.svg': 'tv-grundig-vision6-frozen.svg',
    'tv-grundig-vision6.svg': 'tv-grundig-vision6.svg',
    'tv-grundig-vision7-frozen.svg': 'tv-grundig-vision7-frozen.svg',
    'tv-grundig-vision7.svg': 'tv-grundig-vision7.svg',
    'tv-haier-smarttv-frozen.svg': 'tv-haier-smarttv-frozen.svg',
    'tv-haier-smarttv.svg': 'tv-haier-smarttv.svg',
    'tv-hanseatic-smarttv-frozen.svg': 'tv-hanseatic-smarttv-frozen.svg',
    'tv-hanseatic-smarttv.svg': 'tv-hanseatic-smarttv.svg',
    'tv-highone-smarttv-frozen.svg': 'tv-highone-smarttv-frozen.svg',
    'tv-highone-smarttv.svg': 'tv-highone-smarttv.svg',
    'tv-hisense-4kuhdtv-frozen.svg': 'tv-hisense-4kuhdtv-frozen.svg',
    'tv-hisense-4kuhdtv.svg': 'tv-hisense-4kuhdtv.svg',
    'tv-hisense-frozen.svg': 'tv-hisense-frozen.svg',
    'tv-hisense-rokutv-frozen.svg': 'tv-hisense-rokutv-frozen.svg',
    'tv-hisense-rokutv.svg': 'tv-hisense-rokutv.svg',
    'tv-hisense.svg': 'tv-hisense.svg',
    'tv-hitachi-rokutv-frozen.svg': 'tv-hitachi-rokutv-frozen.svg',
    'tv-hitachi-rokutv.svg': 'tv-hitachi-rokutv.svg',
    'tv-hitachi-smarttv-frozen.svg': 'tv-hitachi-smarttv-frozen.svg',
    'tv-hitachi-smarttv.svg': 'tv-hitachi-smarttv.svg',
    'tv-hyundai-smarttv-frozen.svg': 'tv-hyundai-smarttv-frozen.svg',
    'tv-hyundai-smarttv.svg': 'tv-hyundai-smarttv.svg',
    'tv-insignia-rokutv-frozen.svg': 'tv-insignia-rokutv-frozen.svg',
    'tv-insignia-rokutv.svg': 'tv-insignia-rokutv.svg',
    'tv-jvc-firetv-frozen.svg': 'tv-jvc-firetv-frozen.svg',
    'tv-jvc-firetv.svg': 'tv-jvc-firetv.svg',
    'tv-jvc-smartdevice-frozen.svg': 'tv-jvc-smartdevice-frozen.svg',
    'tv-jvc-smartdevice.svg': 'tv-jvc-smartdevice.svg',
    'tv-jvc-smarttv-frozen.svg': 'tv-jvc-smarttv-frozen.svg',
    'tv-jvc-smarttv.svg': 'tv-jvc-smarttv.svg',
    'tv-kendo-smarttv-frozen.svg': 'tv-kendo-smarttv-frozen.svg',
    'tv-kendo-smarttv.svg': 'tv-kendo-smarttv.svg',
    'tv-kubo-smarttv-frozen.svg': 'tv-kubo-smarttv-frozen.svg',
    'tv-kubo-smarttv.svg': 'tv-kubo-smarttv.svg',
    'tv-kydos-smarttv-frozen.svg': 'tv-kydos-smarttv-frozen.svg',
    'tv-kydos-smarttv.svg': 'tv-kydos-smarttv.svg',
    'tv-leapfrog-leaptv-frozen.svg': 'tv-leapfrog-leaptv-frozen.svg',
    'tv-leapfrog-leaptv.svg': 'tv-leapfrog-leaptv.svg',
    'tv-leeco-letv-frozen.svg': 'tv-leeco-letv-frozen.svg',
    'tv-leeco-letv.svg': 'tv-leeco-letv.svg',
    'tv-lg-frozen.svg': 'tv-lg-frozen.svg',
    'tv-lg-rokutv-frozen.svg': 'tv-lg-rokutv-frozen.svg',
    'tv-lg-rokutv.svg': 'tv-lg-rokutv.svg',
    'tv-lg-smarttv-frozen.svg': 'tv-lg-smarttv-frozen.svg',
    'tv-lg-smarttv.svg': 'tv-lg-smarttv.svg',
    'tv-lg-webostv-frozen.svg': 'tv-lg-webostv-frozen.svg',
    'tv-lg-webostv.svg': 'tv-lg-webostv.svg',
    'tv-lg.svg': 'tv-lg.svg',
    'tv-linsar-smarttv-frozen.svg': 'tv-linsar-smarttv-frozen.svg',
    'tv-linsar-smarttv.svg': 'tv-linsar-smarttv.svg',
    'tv-logic-smarttv-frozen.svg': 'tv-logic-smarttv-frozen.svg',
    'tv-logic-smarttv.svg': 'tv-logic-smarttv.svg',
    'tv-luxor-smarttv-frozen.svg': 'tv-luxor-smarttv-frozen.svg',
    'tv-luxor-smarttv.svg': 'tv-luxor-smarttv.svg',
    'tv-m8s-androidtvbox-frozen.svg': 'tv-m8s-androidtvbox-frozen.svg',
    'tv-m8s-androidtvbox.svg': 'tv-m8s-androidtvbox.svg',
    'tv-magnavox-rokutv-frozen.svg': 'tv-magnavox-rokutv-frozen.svg',
    'tv-magnavox-rokutv.svg': 'tv-magnavox-rokutv.svg',
    'tv-medion-life-frozen.svg': 'tv-medion-life-frozen.svg',
    'tv-medion-life.svg': 'tv-medion-life.svg',
    'tv-medion-smarttv-frozen.svg': 'tv-medion-smarttv-frozen.svg',
    'tv-medion-smarttv.svg': 'tv-medion-smarttv.svg',
    'tv-mitchellbrown-smarttv-frozen.svg':
      'tv-mitchellbrown-smarttv-frozen.svg',
    'tv-mitchellbrown-smarttv.svg': 'tv-mitchellbrown-smarttv.svg',
    'tv-netgem-frozen.svg': 'tv-netgem-frozen.svg',
    'tv-netgem.svg': 'tv-netgem.svg',
    'tv-onida-firetv-frozen.svg': 'tv-onida-firetv-frozen.svg',
    'tv-onida-firetv.svg': 'tv-onida-firetv.svg',
    'tv-onn-rokutv-frozen.svg': 'tv-onn-rokutv-frozen.svg',
    'tv-onn-rokutv.svg': 'tv-onn-rokutv.svg',
    'tv-orima-smarttv-frozen.svg': 'tv-orima-smarttv-frozen.svg',
    'tv-orima-smarttv.svg': 'tv-orima-smarttv.svg',
    'tv-panasonic-portabletv-frozen.svg': 'tv-panasonic-portabletv-frozen.svg',
    'tv-panasonic-portabletv.svg': 'tv-panasonic-portabletv.svg',
    'tv-panasonic-tv-frozen.svg': 'tv-panasonic-tv-frozen.svg',
    'tv-panasonic-tv.svg': 'tv-panasonic-tv.svg',
    'tv-philips-rokutv-frozen.svg': 'tv-philips-rokutv-frozen.svg',
    'tv-philips-rokutv.svg': 'tv-philips-rokutv.svg',
    'tv-philips-smarttv-frozen.svg': 'tv-philips-smarttv-frozen.svg',
    'tv-philips-smarttv.svg': 'tv-philips-smarttv.svg',
    'tv-philips-tv-frozen.svg': 'tv-philips-tv-frozen.svg',
    'tv-philips-tv.svg': 'tv-philips-tv.svg',
    'tv-polaroid-ledtv-frozen.svg': 'tv-polaroid-ledtv-frozen.svg',
    'tv-polaroid-ledtv.svg': 'tv-polaroid-ledtv.svg',
    'tv-polaroid-smarttv-frozen.svg': 'tv-polaroid-smarttv-frozen.svg',
    'tv-polaroid-smarttv.svg': 'tv-polaroid-smarttv.svg',
    'tv-qilive-tv-frozen.svg': 'tv-qilive-tv-frozen.svg',
    'tv-qilive-tv.svg': 'tv-qilive-tv.svg',
    'tv-roku-tv-frozen.svg': 'tv-roku-tv-frozen.svg',
    'tv-roku-tv.svg': 'tv-roku-tv.svg',
    'tv-saba-smarttv-frozen.svg': 'tv-saba-smarttv-frozen.svg',
    'tv-saba-smarttv.svg': 'tv-saba-smarttv.svg',
    'tv-salora-ledtv-frozen.svg': 'tv-salora-ledtv-frozen.svg',
    'tv-salora-ledtv.svg': 'tv-salora-ledtv.svg',
    'tv-samsung-frozen.svg': 'tv-samsung-frozen.svg',
    'tv-samsung-q80-frozen.svg': 'tv-samsung-q80-frozen.svg',
    'tv-samsung-q80.svg': 'tv-samsung-q80.svg',
    'tv-samsung.svg': 'tv-samsung.svg',
    'tv-sanyo-rokutv-frozen.svg': 'tv-sanyo-rokutv-frozen.svg',
    'tv-sanyo-rokutv.svg': 'tv-sanyo-rokutv.svg',
    'tv-selecline-smarttv-frozen.svg': 'tv-selecline-smarttv-frozen.svg',
    'tv-selecline-smarttv.svg': 'tv-selecline-smarttv.svg',
    'tv-sharp-aquos-frozen.svg': 'tv-sharp-aquos-frozen.svg',
    'tv-sharp-aquos.svg': 'tv-sharp-aquos.svg',
    'tv-sharp-aquostv-frozen.svg': 'tv-sharp-aquostv-frozen.svg',
    'tv-sharp-aquostv.svg': 'tv-sharp-aquostv.svg',
    'tv-sharp-frozen.svg': 'tv-sharp-frozen.svg',
    'tv-sharp-rokutv-frozen.svg': 'tv-sharp-rokutv-frozen.svg',
    'tv-sharp-rokutv.svg': 'tv-sharp-rokutv.svg',
    'tv-sharp.svg': 'tv-sharp.svg',
    'tv-sony-bravia4ktv-frozen.svg': 'tv-sony-bravia4ktv-frozen.svg',
    'tv-sony-bravia4ktv.svg': 'tv-sony-bravia4ktv.svg',
    'tv-sony-braviatv-frozen.svg': 'tv-sony-braviatv-frozen.svg',
    'tv-sony-braviatv.svg': 'tv-sony-braviatv.svg',
    'tv-sony-frozen.svg': 'tv-sony-frozen.svg',
    'tv-sony.svg': 'tv-sony.svg',
    'tv-tcl-5series-frozen.svg': 'tv-tcl-5series-frozen.svg',
    'tv-tcl-5series.svg': 'tv-tcl-5series.svg',
    'tv-tcl-rokutv-frozen.svg': 'tv-tcl-rokutv-frozen.svg',
    'tv-tcl-rokutv.svg': 'tv-tcl-rokutv.svg',
    'tv-tcl-uniontv-frozen.svg': 'tv-tcl-uniontv-frozen.svg',
    'tv-tcl-uniontv.svg': 'tv-tcl-uniontv.svg',
    'tv-telefunken-smarttv-frozen.svg': 'tv-telefunken-smarttv-frozen.svg',
    'tv-telefunken-smarttv.svg': 'tv-telefunken-smarttv.svg',
    'tv-toshiba-4k-frozen.svg': 'tv-toshiba-4k-frozen.svg',
    'tv-toshiba-4k.svg': 'tv-toshiba-4k.svg',
    'tv-toshiba-chromecasttv-frozen.svg': 'tv-toshiba-chromecasttv-frozen.svg',
    'tv-toshiba-chromecasttv.svg': 'tv-toshiba-chromecasttv.svg',
    'tv-toshiba-firetv-frozen.svg': 'tv-toshiba-firetv-frozen.svg',
    'tv-toshiba-firetv.svg': 'tv-toshiba-firetv.svg',
    'tv-toshiba-regzalcdtv-frozen.svg': 'tv-toshiba-regzalcdtv-frozen.svg',
    'tv-toshiba-regzalcdtv.svg': 'tv-toshiba-regzalcdtv.svg',
    'tv-toshiba-smarttv-frozen.svg': 'tv-toshiba-smarttv-frozen.svg',
    'tv-toshiba-smarttv.svg': 'tv-toshiba-smarttv.svg',
    'tv-vizio-smartcast-frozen.svg': 'tv-vizio-smartcast-frozen.svg',
    'tv-vizio-smartcast.svg': 'tv-vizio-smartcast.svg',
    'tv-vizio-smarttv-frozen.svg': 'tv-vizio-smarttv-frozen.svg',
    'tv-vizio-smarttv.svg': 'tv-vizio-smarttv.svg',
    'tv-vox-smarttv-frozen.svg': 'tv-vox-smarttv-frozen.svg',
    'tv-vox-smarttv.svg': 'tv-vox-smarttv.svg',
    'tv-westwood-smarttv-frozen.svg': 'tv-westwood-smarttv-frozen.svg',
    'tv-westwood-smarttv.svg': 'tv-westwood-smarttv.svg',
    'tv-xiaomi-mitv-frozen.svg': 'tv-xiaomi-mitv-frozen.svg',
    'tv-xiaomi-mitv.svg': 'tv-xiaomi-mitv.svg',
    'tv.svg': 'tv.svg',
    'unknown-frozen.svg': 'unknown-frozen.svg',
    'unknown.svg': 'unknown.svg',
    'vacuum-1.svg': 'vacuum-1.svg',
    'vacuum-ecovacs-deebot-frozen.svg': 'vacuum-ecovacs-deebot-frozen.svg',
    'vacuum-ecovacs-deebot.svg': 'vacuum-ecovacs-deebot.svg',
    'vacuum-eufy-robovac-frozen.svg': 'vacuum-eufy-robovac-frozen.svg',
    'vacuum-eufy-robovac.svg': 'vacuum-eufy-robovac.svg',
    'vacuum-frozen-1.svg': 'vacuum-frozen-1.svg',
    'vacuum-frozen.svg': 'vacuum-frozen.svg',
    'vacuum-irobot-roomba-frozen.svg': 'vacuum-irobot-roomba-frozen.svg',
    'vacuum-irobot-roomba.svg': 'vacuum-irobot-roomba.svg',
    'vacuum.svg': 'vacuum.svg',
    'virtualreality-frozen.svg': 'virtualreality-frozen.svg',
    'virtualreality-microsoft-hololens-frozen.svg':
      'virtualreality-microsoft-hololens-frozen.svg',
    'virtualreality-microsoft-hololens.svg':
      'virtualreality-microsoft-hololens.svg',
    'virtualreality-oculus-frozen.svg': 'virtualreality-oculus-frozen.svg',
    'virtualreality-oculus-go-frozen.svg':
      'virtualreality-oculus-go-frozen.svg',
    'virtualreality-oculus-go.svg': 'virtualreality-oculus-go.svg',
    'virtualreality-oculus-quest-frozen.svg':
      'virtualreality-oculus-quest-frozen.svg',
    'virtualreality-oculus-quest.svg': 'virtualreality-oculus-quest.svg',
    'virtualreality-oculus.svg': 'virtualreality-oculus.svg',
    'virtualreality-varjo-vr1-frozen.svg':
      'virtualreality-varjo-vr1-frozen.svg',
    'virtualreality-varjo-vr1.svg': 'virtualreality-varjo-vr1.svg',
    'virtualreality.svg': 'virtualreality.svg',
    'visitormanagement-envoyvisitors-frozen.svg':
      'visitormanagement-envoyvisitors-frozen.svg',
    'visitormanagement-envoyvisitors.svg':
      'visitormanagement-envoyvisitors.svg',
    'visitormanagement-frozen.svg': 'visitormanagement-frozen.svg',
    'visitormanagement.svg': 'visitormanagement.svg',
    'voiceassistant-amazon-dashwand-frozen.svg':
      'voiceassistant-amazon-dashwand-frozen.svg',
    'voiceassistant-amazon-dashwand.svg': 'voiceassistant-amazon-dashwand.svg',
    'voiceassistant-amazon-echo-frozen.svg':
      'voiceassistant-amazon-echo-frozen.svg',
    'voiceassistant-amazon-echo.svg': 'voiceassistant-amazon-echo.svg',
    'voiceassistant-amazon-echoflex-frozen.svg':
      'voiceassistant-amazon-echoflex-frozen.svg',
    'voiceassistant-amazon-echoflex.svg': 'voiceassistant-amazon-echoflex.svg',
    'voiceassistant-amazon-echoinput-frozen.svg':
      'voiceassistant-amazon-echoinput-frozen.svg',
    'voiceassistant-amazon-echoinput.svg':
      'voiceassistant-amazon-echoinput.svg',
    'voiceassistant-amazon-echoplus-frozen.svg':
      'voiceassistant-amazon-echoplus-frozen.svg',
    'voiceassistant-amazon-echoplus.svg': 'voiceassistant-amazon-echoplus.svg',
    'voiceassistant-amazon-echoshow-frozen.svg':
      'voiceassistant-amazon-echoshow-frozen.svg',
    'voiceassistant-amazon-echoshow.svg': 'voiceassistant-amazon-echoshow.svg',
    'voiceassistant-amazon-echospot-frozen.svg':
      'voiceassistant-amazon-echospot-frozen.svg',
    'voiceassistant-amazon-echospot.svg': 'voiceassistant-amazon-echospot.svg',
    'voiceassistant-amazon-echostudio-frozen.svg':
      'voiceassistant-amazon-echostudio-frozen.svg',
    'voiceassistant-amazon-echostudio.svg':
      'voiceassistant-amazon-echostudio.svg',
    'voiceassistant-clova-desk-frozen.svg':
      'voiceassistant-clova-desk-frozen.svg',
    'voiceassistant-clova-desk.svg': 'voiceassistant-clova-desk.svg',
    'voiceassistant-frozen.svg': 'voiceassistant-frozen.svg',
    'voiceassistant-google-nest-frozen.svg':
      'voiceassistant-google-nest-frozen.svg',
    'voiceassistant-google-nest.svg': 'voiceassistant-google-nest.svg',
    'voiceassistant-jbl-linkspeaker-frozen.svg':
      'voiceassistant-jbl-linkspeaker-frozen.svg',
    'voiceassistant-jbl-linkspeaker.svg': 'voiceassistant-jbl-linkspeaker.svg',
    'voiceassistant-samsung-galaxyhome-frozen.svg':
      'voiceassistant-samsung-galaxyhome-frozen.svg',
    'voiceassistant-samsung-galaxyhome.svg':
      'voiceassistant-samsung-galaxyhome.svg',
    'voiceassistant.svg': 'voiceassistant.svg',
    'voipgateway-amazon-echoconnect-frozen.svg':
      'voipgateway-amazon-echoconnect-frozen.svg',
    'voipgateway-amazon-echoconnect.svg': 'voipgateway-amazon-echoconnect.svg',
    'voipgateway-audiocodes-mediapack-frozen.svg':
      'voipgateway-audiocodes-mediapack-frozen.svg',
    'voipgateway-audiocodes-mediapack.svg':
      'voipgateway-audiocodes-mediapack.svg',
    'voipgateway-avaya-ipphone-frozen.svg':
      'voipgateway-avaya-ipphone-frozen.svg',
    'voipgateway-avaya-ipphone.svg': 'voipgateway-avaya-ipphone.svg',
    'voipgateway-cisco-selsius-frozen.svg':
      'voipgateway-cisco-selsius-frozen.svg',
    'voipgateway-cisco-selsius.svg': 'voipgateway-cisco-selsius.svg',
    'voipgateway-cisco-sipuravoipgateway-frozen.svg':
      'voipgateway-cisco-sipuravoipgateway-frozen.svg',
    'voipgateway-cisco-sipuravoipgateway.svg':
      'voipgateway-cisco-sipuravoipgateway.svg',
    'voipgateway-cisco-spa122-frozen.svg':
      'voipgateway-cisco-spa122-frozen.svg',
    'voipgateway-cisco-spa122.svg': 'voipgateway-cisco-spa122.svg',
    'voipgateway-cisco-spa122ata-frozen.svg':
      'voipgateway-cisco-spa122ata-frozen.svg',
    'voipgateway-cisco-spa122ata.svg': 'voipgateway-cisco-spa122ata.svg',
    'voipgateway-frozen.svg': 'voipgateway-frozen.svg',
    'voipgateway-gigaset-dect-frozen.svg':
      'voipgateway-gigaset-dect-frozen.svg',
    'voipgateway-gigaset-dect.svg': 'voipgateway-gigaset-dect.svg',
    'voipgateway-google-fiber-frozen.svg':
      'voipgateway-google-fiber-frozen.svg',
    'voipgateway-google-fiber.svg': 'voipgateway-google-fiber.svg',
    'voipgateway-grandstream-analogtelephoneadapter-frozen.svg':
      'voipgateway-grandstream-analogtelephoneadapter-frozen.svg',
    'voipgateway-grandstream-analogtelephoneadapter.svg':
      'voipgateway-grandstream-analogtelephoneadapter.svg',
    'voipgateway-grandstream-voipphone-frozen.svg':
      'voipgateway-grandstream-voipphone-frozen.svg',
    'voipgateway-grandstream-voipphone.svg':
      'voipgateway-grandstream-voipphone.svg',
    'voipgateway-linksys-pap2t-frozen.svg':
      'voipgateway-linksys-pap2t-frozen.svg',
    'voipgateway-linksys-pap2t.svg': 'voipgateway-linksys-pap2t.svg',
    'voipgateway-magicjack-home-frozen.svg':
      'voipgateway-magicjack-home-frozen.svg',
    'voipgateway-magicjack-home.svg': 'voipgateway-magicjack-home.svg',
    'voipgateway-mediatrix-frozen.svg': 'voipgateway-mediatrix-frozen.svg',
    'voipgateway-mediatrix.svg': 'voipgateway-mediatrix.svg',
    'voipgateway-mitel-aastra-frozen.svg':
      'voipgateway-mitel-aastra-frozen.svg',
    'voipgateway-mitel-aastra.svg': 'voipgateway-mitel-aastra.svg',
    'voipgateway-mitel-sipphone-1.svg': 'voipgateway-mitel-sipphone-1.svg',
    'voipgateway-mitel-sipphone-frozen-1.svg':
      'voipgateway-mitel-sipphone-frozen-1.svg',
    'voipgateway-mitel-sipphone-frozen.svg':
      'voipgateway-mitel-sipphone-frozen.svg',
    'voipgateway-mitel-sipphone.svg': 'voipgateway-mitel-sipphone.svg',
    'voipgateway-nettalk-voipgateway-frozen.svg':
      'voipgateway-nettalk-voipgateway-frozen.svg',
    'voipgateway-nettalk-voipgateway.svg':
      'voipgateway-nettalk-voipgateway.svg',
    'voipgateway-obihai-obi202-frozen.svg':
      'voipgateway-obihai-obi202-frozen.svg',
    'voipgateway-obihai-obi202.svg': 'voipgateway-obihai-obi202.svg',
    'voipgateway-ooma-telo-frozen.svg': 'voipgateway-ooma-telo-frozen.svg',
    'voipgateway-ooma-telo.svg': 'voipgateway-ooma-telo.svg',
    'voipgateway-panasonic-kxtgp600-frozen.svg':
      'voipgateway-panasonic-kxtgp600-frozen.svg',
    'voipgateway-panasonic-kxtgp600.svg': 'voipgateway-panasonic-kxtgp600.svg',
    'voipgateway-panasonic-kxut123-frozen.svg':
      'voipgateway-panasonic-kxut123-frozen.svg',
    'voipgateway-panasonic-kxut123.svg': 'voipgateway-panasonic-kxut123.svg',
    'voipgateway-panasonic-phone-frozen.svg':
      'voipgateway-panasonic-phone-frozen.svg',
    'voipgateway-panasonic-phone.svg': 'voipgateway-panasonic-phone.svg',
    'voipgateway-polycom-obi200telephoneadapter-frozen.svg':
      'voipgateway-polycom-obi200telephoneadapter-frozen.svg',
    'voipgateway-polycom-obi200telephoneadapter.svg':
      'voipgateway-polycom-obi200telephoneadapter.svg',
    'voipgateway-polycom-telephoneadapter-frozen.svg':
      'voipgateway-polycom-telephoneadapter-frozen.svg',
    'voipgateway-polycom-telephoneadapter.svg':
      'voipgateway-polycom-telephoneadapter.svg',
    'voipgateway-polycom-voipphone-frozen.svg':
      'voipgateway-polycom-voipphone-frozen.svg',
    'voipgateway-polycom-voipphone.svg': 'voipgateway-polycom-voipphone.svg',
    'voipgateway-polycom-vvx-frozen.svg': 'voipgateway-polycom-vvx-frozen.svg',
    'voipgateway-polycom-vvx.svg': 'voipgateway-polycom-vvx.svg',
    'voipgateway-sangoma-ipphone-1.svg': 'voipgateway-sangoma-ipphone-1.svg',
    'voipgateway-sangoma-ipphone-frozen-1.svg':
      'voipgateway-sangoma-ipphone-frozen-1.svg',
    'voipgateway-sangoma-ipphone-frozen.svg':
      'voipgateway-sangoma-ipphone-frozen.svg',
    'voipgateway-sangoma-ipphone.svg': 'voipgateway-sangoma-ipphone.svg',
    'voipgateway-tmobile-linelink-frozen.svg':
      'voipgateway-tmobile-linelink-frozen.svg',
    'voipgateway-tmobile-linelink.svg': 'voipgateway-tmobile-linelink.svg',
    'voipgateway-vonage-telephoneadapter-frozen.svg':
      'voipgateway-vonage-telephoneadapter-frozen.svg',
    'voipgateway-vonage-telephoneadapter.svg':
      'voipgateway-vonage-telephoneadapter.svg',
    'voipgateway-vonage-vonage-frozen.svg':
      'voipgateway-vonage-vonage-frozen.svg',
    'voipgateway-vonage-vonage.svg': 'voipgateway-vonage-vonage.svg',
    'voipgateway-vtech-et635-frozen.svg': 'voipgateway-vtech-et635-frozen.svg',
    'voipgateway-vtech-et635.svg': 'voipgateway-vtech-et635.svg',
    'voipgateway-yealink-ipphone-frozen.svg':
      'voipgateway-yealink-ipphone-frozen.svg',
    'voipgateway-yealink-ipphone.svg': 'voipgateway-yealink-ipphone.svg',
    'voipgateway-yealink-videophone-frozen.svg':
      'voipgateway-yealink-videophone-frozen.svg',
    'voipgateway-yealink-videophone.svg': 'voipgateway-yealink-videophone.svg',
    'voipgateway-yealink-w52p-frozen.svg':
      'voipgateway-yealink-w52p-frozen.svg',
    'voipgateway-yealink-w52p.svg': 'voipgateway-yealink-w52p.svg',
    'voipgateway-yealink-w60b-frozen.svg':
      'voipgateway-yealink-w60b-frozen.svg',
    'voipgateway-yealink-w60b.svg': 'voipgateway-yealink-w60b.svg',
    'voipgateway.svg': 'voipgateway.svg',
    'washerdryer-bosch-dryer-frozen.svg': 'washerdryer-bosch-dryer-frozen.svg',
    'washerdryer-bosch-dryer.svg': 'washerdryer-bosch-dryer.svg',
    'washerdryer-frozen.svg': 'washerdryer-frozen.svg',
    'washerdryer-ge-dryer-frozen.svg': 'washerdryer-ge-dryer-frozen.svg',
    'washerdryer-ge-dryer.svg': 'washerdryer-ge-dryer.svg',
    'washerdryer-lg-dryer-frozen.svg': 'washerdryer-lg-dryer-frozen.svg',
    'washerdryer-lg-dryer.svg': 'washerdryer-lg-dryer.svg',
    'washerdryer-lg-washer-frozen.svg': 'washerdryer-lg-washer-frozen.svg',
    'washerdryer-lg-washer.svg': 'washerdryer-lg-washer.svg',
    'washerdryer-miele-washer-frozen.svg':
      'washerdryer-miele-washer-frozen.svg',
    'washerdryer-miele-washer.svg': 'washerdryer-miele-washer.svg',
    'washerdryer-samsung-dryer-frozen.svg':
      'washerdryer-samsung-dryer-frozen.svg',
    'washerdryer-samsung-dryer.svg': 'washerdryer-samsung-dryer.svg',
    'washerdryer-samsung-washer-frozen.svg':
      'washerdryer-samsung-washer-frozen.svg',
    'washerdryer-samsung-washer.svg': 'washerdryer-samsung-washer.svg',
    'washerdryer-siemens-heatpumpdryer-frozen.svg':
      'washerdryer-siemens-heatpumpdryer-frozen.svg',
    'washerdryer-siemens-heatpumpdryer.svg':
      'washerdryer-siemens-heatpumpdryer.svg',
    'washerdryer-siemens-washer-frozen.svg':
      'washerdryer-siemens-washer-frozen.svg',
    'washerdryer-siemens-washer.svg': 'washerdryer-siemens-washer.svg',
    'washerdryer-siemens-washerdryer-frozen.svg':
      'washerdryer-siemens-washerdryer-frozen.svg',
    'washerdryer-siemens-washerdryer.svg':
      'washerdryer-siemens-washerdryer.svg',
    'washerdryer-vzug-adorawash-frozen.svg':
      'washerdryer-vzug-adorawash-frozen.svg',
    'washerdryer-vzug-adorawash.svg': 'washerdryer-vzug-adorawash.svg',
    'washerdryer-vzug-dryer-frozen.svg': 'washerdryer-vzug-dryer-frozen.svg',
    'washerdryer-vzug-dryer.svg': 'washerdryer-vzug-dryer.svg',
    'washerdryer-vzug-washingmachine-frozen.svg':
      'washerdryer-vzug-washingmachine-frozen.svg',
    'washerdryer-vzug-washingmachine.svg':
      'washerdryer-vzug-washingmachine.svg',
    'washerdryer-whirlpool-dryer-frozen.svg':
      'washerdryer-whirlpool-dryer-frozen.svg',
    'washerdryer-whirlpool-dryer.svg': 'washerdryer-whirlpool-dryer.svg',
    'washerdryer-whirlpool-washer-frozen.svg':
      'washerdryer-whirlpool-washer-frozen.svg',
    'washerdryer-whirlpool-washer.svg': 'washerdryer-whirlpool-washer.svg',
    'washerdryer.svg': 'washerdryer.svg',
    'watch-amazfit-pace-frozen.svg': 'watch-amazfit-pace-frozen.svg',
    'watch-amazfit-pace.svg': 'watch-amazfit-pace.svg',
    'watch-amazfit-stratos-frozen.svg': 'watch-amazfit-stratos-frozen.svg',
    'watch-amazfit-stratos.svg': 'watch-amazfit-stratos.svg',
    'watch-amazfit-watch-frozen.svg': 'watch-amazfit-watch-frozen.svg',
    'watch-amazfit-watch.svg': 'watch-amazfit-watch.svg',
    'watch-apple-watch-frozen.svg': 'watch-apple-watch-frozen.svg',
    'watch-apple-watch.svg': 'watch-apple-watch.svg',
    'watch-doki-watch-frozen.svg': 'watch-doki-watch-frozen.svg',
    'watch-doki-watch.svg': 'watch-doki-watch.svg',
    'watch-fitbit-sense-frozen.svg': 'watch-fitbit-sense-frozen.svg',
    'watch-fitbit-sense.svg': 'watch-fitbit-sense.svg',
    'watch-frozen.svg': 'watch-frozen.svg',
    'watch-littlegenius-z5h-frozen.svg': 'watch-littlegenius-z5h-frozen.svg',
    'watch-littlegenius-z5h.svg': 'watch-littlegenius-z5h.svg',
    'watch-mobvoi-ticwatch-frozen.svg': 'watch-mobvoi-ticwatch-frozen.svg',
    'watch-mobvoi-ticwatch.svg': 'watch-mobvoi-ticwatch.svg',
    'watch-motorola-moto-frozen.svg': 'watch-motorola-moto-frozen.svg',
    'watch-motorola-moto.svg': 'watch-motorola-moto.svg',
    'watch-oppo-watch-frozen.svg': 'watch-oppo-watch-frozen.svg',
    'watch-oppo-watch.svg': 'watch-oppo-watch.svg',
    'watch-puma-smartwatch-frozen.svg': 'watch-puma-smartwatch-frozen.svg',
    'watch-puma-smartwatch.svg': 'watch-puma-smartwatch.svg',
    'watch-samsung-galaxy-frozen.svg': 'watch-samsung-galaxy-frozen.svg',
    'watch-samsung-galaxy.svg': 'watch-samsung-galaxy.svg',
    'watch-samsung-gear-frozen.svg': 'watch-samsung-gear-frozen.svg',
    'watch-samsung-gear.svg': 'watch-samsung-gear.svg',
    'watch-ticktalk-3-frozen.svg': 'watch-ticktalk-3-frozen.svg',
    'watch-ticktalk-3.svg': 'watch-ticktalk-3.svg',
    'watch-xplora-x4-frozen.svg': 'watch-xplora-x4-frozen.svg',
    'watch-xplora-x4.svg': 'watch-xplora-x4.svg',
    'watch.svg': 'watch.svg',
    'waterheater-1.svg': 'waterheater-1.svg',
    'waterheater-aquanta-waterheatercontroller-frozen.svg':
      'waterheater-aquanta-waterheatercontroller-frozen.svg',
    'waterheater-aquanta-waterheatercontroller.svg':
      'waterheater-aquanta-waterheatercontroller.svg',
    'waterheater-frozen-1.svg': 'waterheater-frozen-1.svg',
    'waterheater-frozen.svg': 'waterheater-frozen.svg',
    'waterheater-navien-navilink-frozen.svg':
      'waterheater-navien-navilink-frozen.svg',
    'waterheater-navien-navilink.svg': 'waterheater-navien-navilink.svg',
    'waterheater-nti-boiler-frozen.svg': 'waterheater-nti-boiler-frozen.svg',
    'waterheater-nti-boiler.svg': 'waterheater-nti-boiler.svg',
    'waterheater-rheem-econetwaterheater-frozen.svg':
      'waterheater-rheem-econetwaterheater-frozen.svg',
    'waterheater-rheem-econetwaterheater.svg':
      'waterheater-rheem-econetwaterheater.svg',
    'waterheater-rinnai-waterheater-frozen.svg':
      'waterheater-rinnai-waterheater-frozen.svg',
    'waterheater-rinnai-waterheater.svg': 'waterheater-rinnai-waterheater.svg',
    'waterheater.svg': 'waterheater.svg',
    'wearable-brightsign-glove-frozen.svg':
      'wearable-brightsign-glove-frozen.svg',
    'wearable-brightsign-glove.svg': 'wearable-brightsign-glove.svg',
    'wearable-fitbit-ace-frozen.svg': 'wearable-fitbit-ace-frozen.svg',
    'wearable-fitbit-ace.svg': 'wearable-fitbit-ace.svg',
    'wearable-fitbit-charge-frozen.svg': 'wearable-fitbit-charge-frozen.svg',
    'wearable-fitbit-charge.svg': 'wearable-fitbit-charge.svg',
    'wearable-fitbit-frozen.svg': 'wearable-fitbit-frozen.svg',
    'wearable-fitbit-inspire-frozen.svg': 'wearable-fitbit-inspire-frozen.svg',
    'wearable-fitbit-inspire.svg': 'wearable-fitbit-inspire.svg',
    'wearable-fitbit-ionic-frozen.svg': 'wearable-fitbit-ionic-frozen.svg',
    'wearable-fitbit-ionic.svg': 'wearable-fitbit-ionic.svg',
    'wearable-fitbit-versa-frozen.svg': 'wearable-fitbit-versa-frozen.svg',
    'wearable-fitbit-versa.svg': 'wearable-fitbit-versa.svg',
    'wearable-fitbit.svg': 'wearable-fitbit.svg',
    'wearable-fossil-q-frozen.svg': 'wearable-fossil-q-frozen.svg',
    'wearable-fossil-q.svg': 'wearable-fossil-q.svg',
    'wearable-fossil-sport-frozen.svg': 'wearable-fossil-sport-frozen.svg',
    'wearable-fossil-sport.svg': 'wearable-fossil-sport.svg',
    'wearable-frozen.svg': 'wearable-frozen.svg',
    'wearable-garmin-forerunner-frozen.svg':
      'wearable-garmin-forerunner-frozen.svg',
    'wearable-garmin-forerunner.svg': 'wearable-garmin-forerunner.svg',
    'wearable-garmin-frozen.svg': 'wearable-garmin-frozen.svg',
    'wearable-garmin-vivoactive-1.svg': 'wearable-garmin-vivoactive-1.svg',
    'wearable-garmin-vivoactive-frozen-1.svg':
      'wearable-garmin-vivoactive-frozen-1.svg',
    'wearable-garmin-vivoactive-frozen.svg':
      'wearable-garmin-vivoactive-frozen.svg',
    'wearable-garmin-vivoactive.svg': 'wearable-garmin-vivoactive.svg',
    'wearable-garmin-vivofit-frozen.svg': 'wearable-garmin-vivofit-frozen.svg',
    'wearable-garmin-vivofit.svg': 'wearable-garmin-vivofit.svg',
    'wearable-garmin-vivomove-frozen.svg':
      'wearable-garmin-vivomove-frozen.svg',
    'wearable-garmin-vivomove.svg': 'wearable-garmin-vivomove.svg',
    'wearable-garmin-vivosmart-frozen.svg':
      'wearable-garmin-vivosmart-frozen.svg',
    'wearable-garmin-vivosmart.svg': 'wearable-garmin-vivosmart.svg',
    'wearable-garmin-vivosport-frozen.svg':
      'wearable-garmin-vivosport-frozen.svg',
    'wearable-garmin-vivosport.svg': 'wearable-garmin-vivosport.svg',
    'wearable-garmin.svg': 'wearable-garmin.svg',
    'wearable-huawei-frozen.svg': 'wearable-huawei-frozen.svg',
    'wearable-huawei.svg': 'wearable-huawei.svg',
    'wearable-neebo-monitor-frozen.svg': 'wearable-neebo-monitor-frozen.svg',
    'wearable-neebo-monitor.svg': 'wearable-neebo-monitor.svg',
    'wearable-whistle-fit-frozen.svg': 'wearable-whistle-fit-frozen.svg',
    'wearable-whistle-fit.svg': 'wearable-whistle-fit.svg',
    'wearable.svg': 'wearable.svg',
    'weathermonitor-acurite-weatherstation-frozen.svg':
      'weathermonitor-acurite-weatherstation-frozen.svg',
    'weathermonitor-acurite-weatherstation.svg':
      'weathermonitor-acurite-weatherstation.svg',
    'weathermonitor-ambient-frozen.svg': 'weathermonitor-ambient-frozen.svg',
    'weathermonitor-ambient.svg': 'weathermonitor-ambient.svg',
    'weathermonitor-bloomsky-frozen.svg': 'weathermonitor-bloomsky-frozen.svg',
    'weathermonitor-bloomsky-storm-frozen.svg':
      'weathermonitor-bloomsky-storm-frozen.svg',
    'weathermonitor-bloomsky-storm.svg': 'weathermonitor-bloomsky-storm.svg',
    'weathermonitor-bloomsky-weatherstation-frozen.svg':
      'weathermonitor-bloomsky-weatherstation-frozen.svg',
    'weathermonitor-bloomsky-weatherstation.svg':
      'weathermonitor-bloomsky-weatherstation.svg',
    'weathermonitor-bloomsky.svg': 'weathermonitor-bloomsky.svg',
    'weathermonitor-davisinstruments-weatherlinklive-frozen.svg':
      'weathermonitor-davisinstruments-weatherlinklive-frozen.svg',
    'weathermonitor-davisinstruments-weatherlinklive.svg':
      'weathermonitor-davisinstruments-weatherlinklive.svg',
    'weathermonitor-frozen.svg': 'weathermonitor-frozen.svg',
    'weathermonitor-lacrosse-weatherstation-frozen.svg':
      'weathermonitor-lacrosse-weatherstation-frozen.svg',
    'weathermonitor-lacrosse-weatherstation.svg':
      'weathermonitor-lacrosse-weatherstation.svg',
    'weathermonitor-meteobridge-weatherstation-frozen.svg':
      'weathermonitor-meteobridge-weatherstation-frozen.svg',
    'weathermonitor-meteobridge-weatherstation.svg':
      'weathermonitor-meteobridge-weatherstation.svg',
    'weathermonitor-netatmo-personalweatherstation-frozen.svg':
      'weathermonitor-netatmo-personalweatherstation-frozen.svg',
    'weathermonitor-netatmo-personalweatherstation.svg':
      'weathermonitor-netatmo-personalweatherstation.svg',
    'weathermonitor-onelink-environmentmonitor-frozen.svg':
      'weathermonitor-onelink-environmentmonitor-frozen.svg',
    'weathermonitor-onelink-environmentmonitor.svg':
      'weathermonitor-onelink-environmentmonitor.svg',
    'weathermonitor-weatherflow-frozen.svg':
      'weathermonitor-weatherflow-frozen.svg',
    'weathermonitor-weatherflow.svg': 'weathermonitor-weatherflow.svg',
    'weathermonitor.svg': 'weathermonitor.svg',
    'wifitracker-bosch-nyon-frozen.svg': 'wifitracker-bosch-nyon-frozen.svg',
    'wifitracker-bosch-nyon.svg': 'wifitracker-bosch-nyon.svg',
    'wifitracker-frozen.svg': 'wifitracker-frozen.svg',
    'wifitracker-garmin-alpha-frozen.svg':
      'wifitracker-garmin-alpha-frozen.svg',
    'wifitracker-garmin-alpha.svg': 'wifitracker-garmin-alpha.svg',
    'wifitracker-garmin-dezl-frozen.svg': 'wifitracker-garmin-dezl-frozen.svg',
    'wifitracker-garmin-dezl.svg': 'wifitracker-garmin-dezl.svg',
    'wifitracker-garmin-edge-frozen.svg': 'wifitracker-garmin-edge-frozen.svg',
    'wifitracker-garmin-edge.svg': 'wifitracker-garmin-edge.svg',
    'wifitracker-garmin-gpsmap-frozen.svg':
      'wifitracker-garmin-gpsmap-frozen.svg',
    'wifitracker-garmin-gpsmap.svg': 'wifitracker-garmin-gpsmap.svg',
    'wifitracker-garmin-montana-frozen.svg':
      'wifitracker-garmin-montana-frozen.svg',
    'wifitracker-garmin-montana.svg': 'wifitracker-garmin-montana.svg',
    'wifitracker-garmin-overlander-frozen.svg':
      'wifitracker-garmin-overlander-frozen.svg',
    'wifitracker-garmin-overlander.svg': 'wifitracker-garmin-overlander.svg',
    'wifitracker-hammerhead-karoo-frozen.svg':
      'wifitracker-hammerhead-karoo-frozen.svg',
    'wifitracker-hammerhead-karoo.svg': 'wifitracker-hammerhead-karoo.svg',
    'wifitracker-jiobit-tracker-frozen.svg':
      'wifitracker-jiobit-tracker-frozen.svg',
    'wifitracker-jiobit-tracker.svg': 'wifitracker-jiobit-tracker.svg',
    'wifitracker-jiobit-wifitracker-frozen.svg':
      'wifitracker-jiobit-wifitracker-frozen.svg',
    'wifitracker-jiobit-wifitracker.svg': 'wifitracker-jiobit-wifitracker.svg',
    'wifitracker-pioneer-sgxca600-frozen.svg':
      'wifitracker-pioneer-sgxca600-frozen.svg',
    'wifitracker-pioneer-sgxca600.svg': 'wifitracker-pioneer-sgxca600.svg',
    'wifitracker-randmcnally-tnd-frozen.svg':
      'wifitracker-randmcnally-tnd-frozen.svg',
    'wifitracker-randmcnally-tnd.svg': 'wifitracker-randmcnally-tnd.svg',
    'wifitracker-samsung-connecttag-frozen.svg':
      'wifitracker-samsung-connecttag-frozen.svg',
    'wifitracker-samsung-connecttag.svg': 'wifitracker-samsung-connecttag.svg',
    'wifitracker-samsung-frozen.svg': 'wifitracker-samsung-frozen.svg',
    'wifitracker-samsung.svg': 'wifitracker-samsung.svg',
    'wifitracker-tomtom-gps-frozen.svg': 'wifitracker-tomtom-gps-frozen.svg',
    'wifitracker-tomtom-gps.svg': 'wifitracker-tomtom-gps.svg',
    'wifitracker-wahoo-elemnt-frozen.svg':
      'wifitracker-wahoo-elemnt-frozen.svg',
    'wifitracker-wahoo-elemnt.svg': 'wifitracker-wahoo-elemnt.svg',
    'wifitracker-whistle-go-frozen.svg': 'wifitracker-whistle-go-frozen.svg',
    'wifitracker-whistle-go.svg': 'wifitracker-whistle-go.svg',
    'wifitracker.svg': 'wifitracker.svg',
  },
  IotMedium: {
    'airconditioner-daikin-airconditioner-frozen.svg':
      'airconditioner-daikin-airconditioner-frozen.svg',
    'airconditioner-daikin-airconditioner.svg':
      'airconditioner-daikin-airconditioner.svg',
    'airconditioner-daikin-frozen.svg': 'airconditioner-daikin-frozen.svg',
    'airconditioner-daikin.svg': 'airconditioner-daikin.svg',
    'airconditioner-frozen.svg': 'airconditioner-frozen.svg',
    'airconditioner-fujitsu-ac-frozen.svg':
      'airconditioner-fujitsu-ac-frozen.svg',
    'airconditioner-fujitsu-ac.svg': 'airconditioner-fujitsu-ac.svg',
    'airconditioner-fujitsu-frozen.svg': 'airconditioner-fujitsu-frozen.svg',
    'airconditioner-fujitsu.svg': 'airconditioner-fujitsu.svg',
    'airconditioner-haier-frozen.svg': 'airconditioner-haier-frozen.svg',
    'airconditioner-haier.svg': 'airconditioner-haier.svg',
    'airconditioner-midea-airconditioner.svg':
      'airconditioner-midea-airconditioner.svg',
    'airconditioner-midea-airconditionera-frozen.svg':
      'airconditioner-midea-airconditionera-frozen.svg',
    'airconditioner-midea-frozen.svg': 'airconditioner-midea-frozen.svg',
    'airconditioner-midea.svg': 'airconditioner-midea.svg',
    'airconditioner-mitsubishi-ac-frozen.svg':
      'airconditioner-mitsubishi-ac-frozen.svg',
    'airconditioner-mitsubishi-ac.svg': 'airconditioner-mitsubishi-ac.svg',
    'airconditioner-mitsubishi-frozen.svg':
      'airconditioner-mitsubishi-frozen.svg',
    'airconditioner-mitsubishi.svg': 'airconditioner-mitsubishi.svg',
    'airconditioner-samsung-ac-frozen.svg':
      'airconditioner-samsung-ac-frozen.svg',
    'airconditioner-samsung-ac.svg': 'airconditioner-samsung-ac.svg',
    'airconditioner-samsung-frozen.svg': 'airconditioner-samsung-frozen.svg',
    'airconditioner-samsung.svg': 'airconditioner-samsung.svg',
    'airconditioner-sensibo-frozen.svg': 'airconditioner-sensibo-frozen.svg',
    'airconditioner-sensibo-skysmartac-frozen.svg':
      'airconditioner-sensibo-skysmartac-frozen.svg',
    'airconditioner-sensibo-skysmartac.svg':
      'airconditioner-sensibo-skysmartac.svg',
    'airconditioner-sensibo-smartairconditioner-frozen.svg':
      'airconditioner-sensibo-smartairconditioner-frozen.svg',
    'airconditioner-sensibo-smartairconditioner.svg':
      'airconditioner-sensibo-smartairconditioner.svg',
    'airconditioner-sensibo.svg': 'airconditioner-sensibo.svg',
    'airconditioner-tado-frozen.svg': 'airconditioner-tado-frozen.svg',
    'airconditioner-tado-smartac-frozen.svg':
      'airconditioner-tado-smartac-frozen.svg',
    'airconditioner-tado-smartac.svg': 'airconditioner-tado-smartac.svg',
    'airconditioner-tado-smartaccontrol-frozen.svg':
      'airconditioner-tado-smartaccontrol-frozen.svg',
    'airconditioner-tado-smartaccontrol.svg':
      'airconditioner-tado-smartaccontrol.svg',
    'airconditioner-tado.svg': 'airconditioner-tado.svg',
    'airconditioner-whirlpool-frozen.svg':
      'airconditioner-whirlpool-frozen.svg',
    'airconditioner-whirlpool.svg': 'airconditioner-whirlpool.svg',
    'airconditioner-xiaomi-frozen.svg': 'airconditioner-xiaomi-frozen.svg',
    'airconditioner-xiaomi.svg': 'airconditioner-xiaomi.svg',
    'airconditioner.svg': 'airconditioner.svg',
    'airfryer-frozen.svg': 'airfryer-frozen.svg',
    'airfryer.svg': 'airfryer.svg',
    'airpurifier-amway-atmosphereairpurifier-frozen.svg':
      'airpurifier-amway-atmosphereairpurifier-frozen.svg',
    'airpurifier-amway-atmosphereairpurifier.svg':
      'airpurifier-amway-atmosphereairpurifier.svg',
    'airpurifier-amway-frozen.svg': 'airpurifier-amway-frozen.svg',
    'airpurifier-amway.svg': 'airpurifier-amway.svg',
    'airpurifier-awair-frozen.svg': 'airpurifier-awair-frozen.svg',
    'airpurifier-awair-glow-2-frozen.svg':
      'airpurifier-awair-glow-2-frozen.svg',
    'airpurifier-awair-glow-2.svg': 'airpurifier-awair-glow-2.svg',
    'airpurifier-awair-glow-frozen.svg': 'airpurifier-awair-glow-frozen.svg',
    'airpurifier-awair-glow.svg': 'airpurifier-awair-glow.svg',
    'airpurifier-awair.svg': 'airpurifier-awair.svg',
    'airpurifier-blueair-airpurifier-frozen.svg':
      'airpurifier-blueair-airpurifier-frozen.svg',
    'airpurifier-blueair-airpurifier.svg':
      'airpurifier-blueair-airpurifier.svg',
    'airpurifier-blueair-frozen.svg': 'airpurifier-blueair-frozen.svg',
    'airpurifier-blueair.svg': 'airpurifier-blueair.svg',
    'airpurifier-brid-airpurifier-frozen.svg':
      'airpurifier-brid-airpurifier-frozen.svg',
    'airpurifier-brid-airpurifier.svg': 'airpurifier-brid-airpurifier.svg',
    'airpurifier-brid-frozen.svg': 'airpurifier-brid-frozen.svg',
    'airpurifier-brid.svg': 'airpurifier-brid.svg',
    'airpurifier-broadlink-a1-frozen.svg':
      'airpurifier-broadlink-a1-frozen.svg',
    'airpurifier-broadlink-a1.svg': 'airpurifier-broadlink-a1.svg',
    'airpurifier-broadlink-frozen.svg': 'airpurifier-broadlink-frozen.svg',
    'airpurifier-broadlink.svg': 'airpurifier-broadlink.svg',
    'airpurifier-coway-airmega-frozen.svg':
      'airpurifier-coway-airmega-frozen.svg',
    'airpurifier-coway-airmega.svg': 'airpurifier-coway-airmega.svg',
    'airpurifier-coway-frozen.svg': 'airpurifier-coway-frozen.svg',
    'airpurifier-coway.svg': 'airpurifier-coway.svg',
    'airpurifier-dyson-frozen.svg': 'airpurifier-dyson-frozen.svg',
    'airpurifier-dyson-purecool-frozen.svg':
      'airpurifier-dyson-purecool-frozen.svg',
    'airpurifier-dyson-purecool.svg': 'airpurifier-dyson-purecool.svg',
    'airpurifier-dyson-purecoolhepa-frozen.svg':
      'airpurifier-dyson-purecoolhepa-frozen.svg',
    'airpurifier-dyson-purecoolhepa.svg': 'airpurifier-dyson-purecoolhepa.svg',
    'airpurifier-dyson-purecoollink-frozen.svg':
      'airpurifier-dyson-purecoollink-frozen.svg',
    'airpurifier-dyson-purecoollink.svg': 'airpurifier-dyson-purecoollink.svg',
    'airpurifier-dyson-purehot+cool-frozen.svg':
      'airpurifier-dyson-purehot+cool-frozen.svg',
    'airpurifier-dyson-purehot+cool.svg': 'airpurifier-dyson-purehot+cool.svg',
    'airpurifier-dyson-purehot+coollink-frozen.svg':
      'airpurifier-dyson-purehot+coollink-frozen.svg',
    'airpurifier-dyson-purehot+coollink.svg':
      'airpurifier-dyson-purehot+coollink.svg',
    'airpurifier-dyson-purehumidify+cool-frozen.svg':
      'airpurifier-dyson-purehumidify+cool-frozen.svg',
    'airpurifier-dyson-purehumidify+cool.svg':
      'airpurifier-dyson-purehumidify+cool.svg',
    'airpurifier-dyson.svg': 'airpurifier-dyson.svg',
    'airpurifier-frozen.svg': 'airpurifier-frozen.svg',
    'airpurifier-levoit-airpurifier-frozen.svg':
      'airpurifier-levoit-airpurifier-frozen.svg',
    'airpurifier-levoit-airpurifier.svg': 'airpurifier-levoit-airpurifier.svg',
    'airpurifier-levoit-frozen.svg': 'airpurifier-levoit-frozen.svg',
    'airpurifier-levoit.svg': 'airpurifier-levoit.svg',
    'airpurifier-molekule-frozen.svg': 'airpurifier-molekule-frozen.svg',
    'airpurifier-molekule.svg': 'airpurifier-molekule.svg',
    'airpurifier-philips-airpurifier-frozen.svg':
      'airpurifier-philips-airpurifier-frozen.svg',
    'airpurifier-philips-airpurifier.svg':
      'airpurifier-philips-airpurifier.svg',
    'airpurifier-philips-frozen.svg': 'airpurifier-philips-frozen.svg',
    'airpurifier-philips.svg': 'airpurifier-philips.svg',
    'airpurifier-rabbitair-airpurifier-frozen.svg':
      'airpurifier-rabbitair-airpurifier-frozen.svg',
    'airpurifier-rabbitair-airpurifier.svg':
      'airpurifier-rabbitair-airpurifier.svg',
    'airpurifier-rabbitair-frozen.svg': 'airpurifier-rabbitair-frozen.svg',
    'airpurifier-rabbitair.svg': 'airpurifier-rabbitair.svg',
    'airpurifier-samsung-frozen.svg': 'airpurifier-samsung-frozen.svg',
    'airpurifier-samsung.svg': 'airpurifier-samsung.svg',
    'airpurifier-vocolinc-flowerbud-frozen.svg':
      'airpurifier-vocolinc-flowerbud-frozen.svg',
    'airpurifier-vocolinc-flowerbud.svg': 'airpurifier-vocolinc-flowerbud.svg',
    'airpurifier-vocolinc-frozen.svg': 'airpurifier-vocolinc-frozen.svg',
    'airpurifier-vocolinc-pureflow-frozen.svg':
      'airpurifier-vocolinc-pureflow-frozen.svg',
    'airpurifier-vocolinc-pureflow.svg': 'airpurifier-vocolinc-pureflow.svg',
    'airpurifier-vocolinc.svg': 'airpurifier-vocolinc.svg',
    'airpurifier-winix-frozen.svg': 'airpurifier-winix-frozen.svg',
    'airpurifier-winix.svg': 'airpurifier-winix.svg',
    'airpurifier-xiaomi-frozen.svg': 'airpurifier-xiaomi-frozen.svg',
    'airpurifier-xiaomi.svg': 'airpurifier-xiaomi.svg',
    'airpurifier.svg': 'airpurifier.svg',
    'applianceorwearable-asix-ethernetadapter-frozen.svg':
      'applianceorwearable-asix-ethernetadapter-frozen.svg',
    'applianceorwearable-asix-ethernetadapter.svg':
      'applianceorwearable-asix-ethernetadapter.svg',
    'atgames-legendsultimate-frozen.svg': 'atgames-legendsultimate-frozen.svg',
    'atgames-legendsultimate.svg': 'atgames-legendsultimate.svg',
    'babycamera-arlo-frozen.svg': 'babycamera-arlo-frozen.svg',
    'babycamera-arlo.svg': 'babycamera-arlo.svg',
    'babycamera-cubo-ai-frozen.svg': 'babycamera-cubo-ai-frozen.svg',
    'babycamera-cubo-ai.svg': 'babycamera-cubo-ai.svg',
    'babycamera-dophigo-camera-frozen.svg':
      'babycamera-dophigo-camera-frozen.svg',
    'babycamera-dophigo-camera.svg': 'babycamera-dophigo-camera.svg',
    'babycamera-lollipop-babymonitorsmartcamera-frozen.svg':
      'babycamera-lollipop-babymonitorsmartcamera-frozen.svg',
    'babycamera-lollipop-babymonitorsmartcamera.svg':
      'babycamera-lollipop-babymonitorsmartcamera.svg',
    'babycamera-miku-babymonitor-frozen.svg':
      'babycamera-miku-babymonitor-frozen.svg',
    'babycamera-miku-babymonitor.svg': 'babycamera-miku-babymonitor.svg',
    'babycamera-nanit-frozen.svg': 'babycamera-nanit-frozen.svg',
    'babycamera-nanit.svg': 'babycamera-nanit.svg',
    'babycamera-nooie-ipc100-frozen.svg': 'babycamera-nooie-ipc100-frozen.svg',
    'babycamera-nooie-ipc100.svg': 'babycamera-nooie-ipc100.svg',
    'babycamera-owlet-babymonitor-frozen.svg':
      'babycamera-owlet-babymonitor-frozen.svg',
    'babycamera-owlet-babymonitor.svg': 'babycamera-owlet-babymonitor.svg',
    'babycamera-philips-insight-frozen.svg':
      'babycamera-philips-insight-frozen.svg',
    'babycamera-philips-insight.svg': 'babycamera-philips-insight.svg',
    'babycamera-safety1st-babymonitor-frozen.svg':
      'babycamera-safety1st-babymonitor-frozen.svg',
    'babycamera-safety1st-babymonitor.svg':
      'babycamera-safety1st-babymonitor.svg',
    'babycamera-vava-babymonitor-frozen.svg':
      'babycamera-vava-babymonitor-frozen.svg',
    'babycamera-vava-babymonitor.svg': 'babycamera-vava-babymonitor.svg',
    'babycamera-victure-pc420-frozen.svg':
      'babycamera-victure-pc420-frozen.svg',
    'babycamera-victure-pc420.svg': 'babycamera-victure-pc420.svg',
    'babycamera-vtech-babymonitor-frozen.svg':
      'babycamera-vtech-babymonitor-frozen.svg',
    'babycamera-vtech-babymonitor.svg': 'babycamera-vtech-babymonitor.svg',
    'bitcoinminer-2-frozen.svg': 'bitcoinminer-2-frozen.svg',
    'bitcoinminer-2.svg': 'bitcoinminer-2.svg',
    'bitcoinminer-antminer-frozen.svg': 'bitcoinminer-antminer-frozen.svg',
    'bitcoinminer-antminer.svg': 'bitcoinminer-antminer.svg',
    'bitcoinminer-coinmine-one-frozen.svg':
      'bitcoinminer-coinmine-one-frozen.svg',
    'bitcoinminer-coinmine-one.svg': 'bitcoinminer-coinmine-one.svg',
    'bitcoinminer-frozen.svg': 'bitcoinminer-frozen.svg',
    'bitcoinminer-halongmining-dragonmint-frozen.svg':
      'bitcoinminer-halongmining-dragonmint-frozen.svg',
    'bitcoinminer-halongmining-dragonmint.svg':
      'bitcoinminer-halongmining-dragonmint.svg',
    'bitcoinminer-innosilicon-miner-frozen.svg':
      'bitcoinminer-innosilicon-miner-frozen.svg',
    'bitcoinminer-innosilicon-miner.svg': 'bitcoinminer-innosilicon-miner.svg',
    'bitcoinminer.svg': 'bitcoinminer.svg',
    'blender-frozen.svg': 'blender-frozen.svg',
    'blender.svg': 'blender.svg',
    'camera-2gig-hd100-frozen.svg': 'camera-2gig-hd100-frozen.svg',
    'camera-2gig-hd100.svg': 'camera-2gig-hd100.svg',
    'camera-360-camera-frozen.svg': 'camera-360-camera-frozen.svg',
    'camera-360-camera.svg': 'camera-360-camera.svg',
    'camera-360eyes-pro-frozen.svg': 'camera-360eyes-pro-frozen.svg',
    'camera-360eyes-pro.svg': 'camera-360eyes-pro.svg',
    'camera-amazon-cloudcam-frozen.svg': 'camera-amazon-cloudcam-frozen.svg',
    'camera-amazon-cloudcam.svg': 'camera-amazon-cloudcam.svg',
    'camera-amazon-echo-look-frozen.svg': 'camera-amazon-echo-look-frozen.svg',
    'camera-amazon-echo-look.svg': 'camera-amazon-echo-look.svg',
    'camera-amazonring-floodlightcam-frozen.svg':
      'camera-amazonring-floodlightcam-frozen.svg',
    'camera-amazonring-floodlightcam.svg':
      'camera-amazonring-floodlightcam.svg',
    'camera-amazonring-frozen.svg': 'camera-amazonring-frozen.svg',
    'camera-amazonring-indoorcam-frozen.svg':
      'camera-amazonring-indoorcam-frozen.svg',
    'camera-amazonring-indoorcam.svg': 'camera-amazonring-indoorcam.svg',
    'camera-amazonring-spotlightcam-frozen.svg':
      'camera-amazonring-spotlightcam-frozen.svg',
    'camera-amazonring-spotlightcam.svg': 'camera-amazonring-spotlightcam.svg',
    'camera-amazonring-stickupcam-frozen.svg':
      'camera-amazonring-stickupcam-frozen.svg',
    'camera-amazonring-stickupcam.svg': 'camera-amazonring-stickupcam.svg',
    'camera-amazonring.svg': 'camera-amazonring.svg',
    'camera-ambarella-securitycamera-frozen.svg':
      'camera-ambarella-securitycamera-frozen.svg',
    'camera-ambarella-securitycamera.svg':
      'camera-ambarella-securitycamera.svg',
    'camera-amcrest-frozen.svg': 'camera-amcrest-frozen.svg',
    'camera-amcrest.svg': 'camera-amcrest.svg',
    'camera-arlo-essentialspotlightcamera-frozen.svg':
      'camera-arlo-essentialspotlightcamera-frozen.svg',
    'camera-arlo-essentialspotlightcamera.svg':
      'camera-arlo-essentialspotlightcamera.svg',
    'camera-arlo-floodlightcamera-frozen.svg':
      'camera-arlo-floodlightcamera-frozen.svg',
    'camera-arlo-floodlightcamera.svg': 'camera-arlo-floodlightcamera.svg',
    'camera-arlo-frozen.svg': 'camera-arlo-frozen.svg',
    'camera-arlo.svg': 'camera-arlo.svg',
    'camera-axis-networkcamera-frozen.svg':
      'camera-axis-networkcamera-frozen.svg',
    'camera-axis-networkcamera.svg': 'camera-axis-networkcamera.svg',
    'camera-belkin-netcam-frozen.svg': 'camera-belkin-netcam-frozen.svg',
    'camera-belkin-netcam.svg': 'camera-belkin-netcam.svg',
    'camera-binatone-homemonitor-frozen.svg':
      'camera-binatone-homemonitor-frozen.svg',
    'camera-binatone-homemonitor.svg': 'camera-binatone-homemonitor.svg',
    'camera-blink-frozen.svg': 'camera-blink-frozen.svg',
    'camera-blink.svg': 'camera-blink.svg',
    'camera-bosch-autodome-frozen.svg': 'camera-bosch-autodome-frozen.svg',
    'camera-bosch-autodome.svg': 'camera-bosch-autodome.svg',
    'camera-bosch-flexidome-frozen.svg': 'camera-bosch-flexidome-frozen.svg',
    'camera-bosch-flexidome.svg': 'camera-bosch-flexidome.svg',
    'camera-canary-frozen.svg': 'camera-canary-frozen.svg',
    'camera-canary.svg': 'camera-canary.svg',
    'camera-canon-eos-frozen.svg': 'camera-canon-eos-frozen.svg',
    'camera-canon-eos.svg': 'camera-canon-eos.svg',
    'camera-canon-powershot-frozen.svg': 'camera-canon-powershot-frozen.svg',
    'camera-canon-powershot.svg': 'camera-canon-powershot.svg',
    'camera-cocoon-frozen.svg': 'camera-cocoon-frozen.svg',
    'camera-cocoon.svg': 'camera-cocoon.svg',
    'camera-comcast-xcam2-frozen.svg': 'camera-comcast-xcam2-frozen.svg',
    'camera-comcast-xcam2.svg': 'camera-comcast-xcam2.svg',
    'camera-conico-securitycamera-frozen.svg':
      'camera-conico-securitycamera-frozen.svg',
    'camera-conico-securitycamera.svg': 'camera-conico-securitycamera.svg',
    'camera-dlink-frozen.svg': 'camera-dlink-frozen.svg',
    'camera-dlink-miniwificamera-frozen.svg':
      'camera-dlink-miniwificamera-frozen.svg',
    'camera-dlink-miniwificamera.svg': 'camera-dlink-miniwificamera.svg',
    'camera-dlink-pantiltwificamera-frozen.svg':
      'camera-dlink-pantiltwificamera-frozen.svg',
    'camera-dlink-pantiltwificamera.svg': 'camera-dlink-pantiltwificamera.svg',
    'camera-dlink-vigilance-frozen.svg': 'camera-dlink-vigilance-frozen.svg',
    'camera-dlink-vigilance.svg': 'camera-dlink-vigilance.svg',
    'camera-dlink-wificamera-frozen.svg': 'camera-dlink-wificamera-frozen.svg',
    'camera-dlink-wificamera.svg': 'camera-dlink-wificamera.svg',
    'camera-dlink.svg': 'camera-dlink.svg',
    'camera-etiger-ipcamera-frozen.svg': 'camera-etiger-ipcamera-frozen.svg',
    'camera-etiger-ipcamera.svg': 'camera-etiger-ipcamera.svg',
    'camera-eve-cam-frozen.svg': 'camera-eve-cam-frozen.svg',
    'camera-eve-cam.svg': 'camera-eve-cam.svg',
    'camera-ezviz-c2c-frozen.svg': 'camera-ezviz-c2c-frozen.svg',
    'camera-ezviz-c2c.svg': 'camera-ezviz-c2c.svg',
    'camera-ezviz-c6tc-frozen.svg': 'camera-ezviz-c6tc-frozen.svg',
    'camera-ezviz-c6tc.svg': 'camera-ezviz-c6tc.svg',
    'camera-ezviz-camera-frozen.svg': 'camera-ezviz-camera-frozen.svg',
    'camera-ezviz-camera.svg': 'camera-ezviz-camera.svg',
    'camera-ezviz-frozen.svg': 'camera-ezviz-frozen.svg',
    'camera-ezviz-husky-frozen.svg': 'camera-ezviz-husky-frozen.svg',
    'camera-ezviz-husky.svg': 'camera-ezviz-husky.svg',
    'camera-ezviz.svg': 'camera-ezviz.svg',
    'camera-faleemi-frozen.svg': 'camera-faleemi-frozen.svg',
    'camera-faleemi.svg': 'camera-faleemi.svg',
    'camera-flir-networkvideorecorder-frozen.svg':
      'camera-flir-networkvideorecorder-frozen.svg',
    'camera-flir-networkvideorecorder.svg':
      'camera-flir-networkvideorecorder.svg',
    'camera-frozen.svg': 'camera-frozen.svg',
    'camera-furbo-dogcamera-frozen.svg': 'camera-furbo-dogcamera-frozen.svg',
    'camera-furbo-dogcamera.svg': 'camera-furbo-dogcamera.svg',
    'camera-geeni-smartwificamera-frozen.svg':
      'camera-geeni-smartwificamera-frozen.svg',
    'camera-geeni-smartwificamera.svg': 'camera-geeni-smartwificamera.svg',
    'camera-googlenest-frozen.svg': 'camera-googlenest-frozen.svg',
    'camera-googlenest.svg': 'camera-googlenest.svg',
    'camera-gopro-camera-frozen.svg': 'camera-gopro-camera-frozen.svg',
    'camera-gopro-camera.svg': 'camera-gopro-camera.svg',
    'camera-gopro-frozen.svg': 'camera-gopro-frozen.svg',
    'camera-gopro.svg': 'camera-gopro.svg',
    'camera-gwsecurity-outdoorcamera-frozen.svg':
      'camera-gwsecurity-outdoorcamera-frozen.svg',
    'camera-gwsecurity-outdoorcamera.svg':
      'camera-gwsecurity-outdoorcamera.svg',
    'camera-hikvision-networkcamera-frozen.svg':
      'camera-hikvision-networkcamera-frozen.svg',
    'camera-hikvision-networkcamera.svg': 'camera-hikvision-networkcamera.svg',
    'camera-hive-view-frozen.svg': 'camera-hive-view-frozen.svg',
    'camera-hive-view.svg': 'camera-hive-view.svg',
    'camera-honeywell-hqa-frozen.svg': 'camera-honeywell-hqa-frozen.svg',
    'camera-honeywell-hqa.svg': 'camera-honeywell-hqa.svg',
    'camera-honeywell-lyricsecuritycamera-frozen.svg':
      'camera-honeywell-lyricsecuritycamera-frozen.svg',
    'camera-honeywell-lyricsecuritycamera.svg':
      'camera-honeywell-lyricsecuritycamera.svg',
    'camera-honeywelllyric-securitycamera-frozen.svg':
      'camera-honeywelllyric-securitycamera-frozen.svg',
    'camera-honeywelllyric-securitycamera.svg':
      'camera-honeywelllyric-securitycamera.svg',
    'camera-hugoai-securitycamera-frozen.svg':
      'camera-hugoai-securitycamera-frozen.svg',
    'camera-hugoai-securitycamera.svg': 'camera-hugoai-securitycamera.svg',
    'camera-iodata-tsns110w-frozen.svg': 'camera-iodata-tsns110w-frozen.svg',
    'camera-iodata-tsns110w.svg': 'camera-iodata-tsns110w.svg',
    'camera-ismart-icamerakeep-frozen.svg':
      'camera-ismart-icamerakeep-frozen.svg',
    'camera-ismart-icamerakeep.svg': 'camera-ismart-icamerakeep.svg',
    'camera-johndeere-camera-frozen.svg': 'camera-johndeere-camera-frozen.svg',
    'camera-johndeere-camera.svg': 'camera-johndeere-camera.svg',
    'camera-jsw-ipcamera-frozen.svg': 'camera-jsw-ipcamera-frozen.svg',
    'camera-jsw-ipcamera.svg': 'camera-jsw-ipcamera.svg',
    'camera-kodak-pixpro-frozen.svg': 'camera-kodak-pixpro-frozen.svg',
    'camera-kodak-pixpro.svg': 'camera-kodak-pixpro.svg',
    'camera-kodak-videomonitor-frozen.svg':
      'camera-kodak-videomonitor-frozen.svg',
    'camera-kodak-videomonitor.svg': 'camera-kodak-videomonitor.svg',
    'camera-kuna-frozen.svg': 'camera-kuna-frozen.svg',
    'camera-kuna.svg': 'camera-kuna.svg',
    'camera-leica-q-frozen.svg': 'camera-leica-q-frozen.svg',
    'camera-leica-q.svg': 'camera-leica-q.svg',
    'camera-litmor-frozen.svg': 'camera-litmor-frozen.svg',
    'camera-litmor.svg': 'camera-litmor.svg',
    'camera-logitech-circle-frozen.svg': 'camera-logitech-circle-frozen.svg',
    'camera-logitech-circle.svg': 'camera-logitech-circle.svg',
    'camera-lorex-ip-frozen.svg': 'camera-lorex-ip-frozen.svg',
    'camera-lorex-ip.svg': 'camera-lorex-ip.svg',
    'camera-lorex-ipcamera-frozen.svg': 'camera-lorex-ipcamera-frozen.svg',
    'camera-lorex-ipcamera.svg': 'camera-lorex-ipcamera.svg',
    'camera-lorex-wificamera-frozen.svg': 'camera-lorex-wificamera-frozen.svg',
    'camera-lorex-wificamera.svg': 'camera-lorex-wificamera.svg',
    'camera-marshall-cv346-frozen.svg': 'camera-marshall-cv346-frozen.svg',
    'camera-marshall-cv346.svg': 'camera-marshall-cv346.svg',
    'camera-mevo-camera-frozen.svg': 'camera-mevo-camera-frozen.svg',
    'camera-mevo-camera.svg': 'camera-mevo-camera.svg',
    'camera-microseven-camera-frozen.svg':
      'camera-microseven-camera-frozen.svg',
    'camera-microseven-camera.svg': 'camera-microseven-camera.svg',
    'camera-momentum-axelsmartcamera-frozen.svg':
      'camera-momentum-axelsmartcamera-frozen.svg',
    'camera-momentum-axelsmartcamera.svg':
      'camera-momentum-axelsmartcamera.svg',
    'camera-momentum-cori-frozen.svg': 'camera-momentum-cori-frozen.svg',
    'camera-momentum-cori.svg': 'camera-momentum-cori.svg',
    'camera-momentum-mocam-frozen.svg': 'camera-momentum-mocam-frozen.svg',
    'camera-momentum-mocam.svg': 'camera-momentum-mocam.svg',
    'camera-momentum-robbi-frozen.svg': 'camera-momentum-robbi-frozen.svg',
    'camera-momentum-robbi.svg': 'camera-momentum-robbi.svg',
    'camera-netatmo-frozen.svg': 'camera-netatmo-frozen.svg',
    'camera-netatmo-presence-frozen.svg': 'camera-netatmo-presence-frozen.svg',
    'camera-netatmo-presence.svg': 'camera-netatmo-presence.svg',
    'camera-netatmo.svg': 'camera-netatmo.svg',
    'camera-nightowl-securitycamera-frozen.svg':
      'camera-nightowl-securitycamera-frozen.svg',
    'camera-nightowl-securitycamera.svg': 'camera-nightowl-securitycamera.svg',
    'camera-nikon-z6-frozen.svg': 'camera-nikon-z6-frozen.svg',
    'camera-nikon-z6.svg': 'camera-nikon-z6.svg',
    'camera-nucam-yieyespycamera-frozen.svg':
      'camera-nucam-yieyespycamera-frozen.svg',
    'camera-nucam-yieyespycamera.svg': 'camera-nucam-yieyespycamera.svg',
    'camera-olympus-stylus-frozen.svg': 'camera-olympus-stylus-frozen.svg',
    'camera-olympus-stylus.svg': 'camera-olympus-stylus.svg',
    'camera-owlet-frozen.svg': 'camera-owlet-frozen.svg',
    'camera-owlet.svg': 'camera-owlet.svg',
    'camera-panasonic-homehawk-frozen.svg':
      'camera-panasonic-homehawk-frozen.svg',
    'camera-panasonic-homehawk.svg': 'camera-panasonic-homehawk.svg',
    'camera-panasonic-lumix-frozen.svg': 'camera-panasonic-lumix-frozen.svg',
    'camera-panasonic-lumix.svg': 'camera-panasonic-lumix.svg',
    'camera-petchatz-hd-frozen.svg': 'camera-petchatz-hd-frozen.svg',
    'camera-petchatz-hd.svg': 'camera-petchatz-hd.svg',
    'camera-petcube-bites-frozen.svg': 'camera-petcube-bites-frozen.svg',
    'camera-petcube-bites.svg': 'camera-petcube-bites.svg',
    'camera-petcube-frozen.svg': 'camera-petcube-frozen.svg',
    'camera-petcube.svg': 'camera-petcube.svg',
    'camera-petzi-treatcam-frozen.svg': 'camera-petzi-treatcam-frozen.svg',
    'camera-petzi-treatcam.svg': 'camera-petzi-treatcam.svg',
    'camera-piper-securitycamera-frozen.svg':
      'camera-piper-securitycamera-frozen.svg',
    'camera-piper-securitycamera.svg': 'camera-piper-securitycamera.svg',
    'camera-ptzoptics-camera-frozen.svg': 'camera-ptzoptics-camera-frozen.svg',
    'camera-ptzoptics-camera.svg': 'camera-ptzoptics-camera.svg',
    'camera-reolink-c1pro-frozen.svg': 'camera-reolink-c1pro-frozen.svg',
    'camera-reolink-c1pro.svg': 'camera-reolink-c1pro.svg',
    'camera-reolink-c2-frozen.svg': 'camera-reolink-c2-frozen.svg',
    'camera-reolink-c2.svg': 'camera-reolink-c2.svg',
    'camera-reolink-camera-frozen.svg': 'camera-reolink-camera-frozen.svg',
    'camera-reolink-camera.svg': 'camera-reolink-camera.svg',
    'camera-reolink-e1-frozen.svg': 'camera-reolink-e1-frozen.svg',
    'camera-reolink-e1.svg': 'camera-reolink-e1.svg',
    'camera-reolink-rlc410w-frozen.svg': 'camera-reolink-rlc410w-frozen.svg',
    'camera-reolink-rlc410w.svg': 'camera-reolink-rlc410w.svg',
    'camera-reolink-rlc511w-frozen.svg': 'camera-reolink-rlc511w-frozen.svg',
    'camera-reolink-rlc511w.svg': 'camera-reolink-rlc511w.svg',
    'camera-rflink-frozen.svg': 'camera-rflink-frozen.svg',
    'camera-rflink.svg': 'camera-rflink.svg',
    'camera-samsung-smartcam-frozen.svg': 'camera-samsung-smartcam-frozen.svg',
    'camera-samsung-smartcam.svg': 'camera-samsung-smartcam.svg',
    'camera-samsung-smartthingscam-frozen.svg':
      'camera-samsung-smartthingscam-frozen.svg',
    'camera-samsung-smartthingscam.svg': 'camera-samsung-smartthingscam.svg',
    'camera-sercomm-ipcamera-frozen.svg': 'camera-sercomm-ipcamera-frozen.svg',
    'camera-sercomm-ipcamera.svg': 'camera-sercomm-ipcamera.svg',
    'camera-shenzhen-ipcamera-frozen.svg':
      'camera-shenzhen-ipcamera-frozen.svg',
    'camera-shenzhen-ipcamera.svg': 'camera-shenzhen-ipcamera.svg',
    'camera-simplisafe-securitycamera-frozen.svg':
      'camera-simplisafe-securitycamera-frozen.svg',
    'camera-simplisafe-securitycamera.svg':
      'camera-simplisafe-securitycamera.svg',
    'camera-smarteye-frozen.svg': 'camera-smarteye-frozen.svg',
    'camera-smarteye.svg': 'camera-smarteye.svg',
    'camera-somfy-indoorcamera-frozen.svg':
      'camera-somfy-indoorcamera-frozen.svg',
    'camera-somfy-indoorcamera.svg': 'camera-somfy-indoorcamera.svg',
    'camera-somfy-one-frozen.svg': 'camera-somfy-one-frozen.svg',
    'camera-somfy-one.svg': 'camera-somfy-one.svg',
    'camera-somfy-outdoorcamera-frozen.svg':
      'camera-somfy-outdoorcamera-frozen.svg',
    'camera-somfy-outdoorcamera.svg': 'camera-somfy-outdoorcamera.svg',
    'camera-sony-frozen.svg': 'camera-sony-frozen.svg',
    'camera-sony.svg': 'camera-sony.svg',
    'camera-sricam-ipcamera-frozen.svg': 'camera-sricam-ipcamera-frozen.svg',
    'camera-sricam-ipcamera.svg': 'camera-sricam-ipcamera.svg',
    'camera-swann-pantilt-frozen.svg': 'camera-swann-pantilt-frozen.svg',
    'camera-swann-pantilt.svg': 'camera-swann-pantilt.svg',
    'camera-swann-securitycamera-frozen.svg':
      'camera-swann-securitycamera-frozen.svg',
    'camera-swann-securitycamera.svg': 'camera-swann-securitycamera.svg',
    'camera-teckin-tc100-frozen.svg': 'camera-teckin-tc100-frozen.svg',
    'camera-teckin-tc100.svg': 'camera-teckin-tc100.svg',
    'camera-tend-lynx-frozen.svg': 'camera-tend-lynx-frozen.svg',
    'camera-tend-lynx.svg': 'camera-tend-lynx.svg',
    'camera-tendinsights-stuartcam-frozen.svg':
      'camera-tendinsights-stuartcam-frozen.svg',
    'camera-tendinsights-stuartcam.svg': 'camera-tendinsights-stuartcam.svg',
    'camera-toucan-outdoorcamera-frozen.svg':
      'camera-toucan-outdoorcamera-frozen.svg',
    'camera-toucan-outdoorcamera.svg': 'camera-toucan-outdoorcamera.svg',
    'camera-tplink-cloudcamera-frozen.svg':
      'camera-tplink-cloudcamera-frozen.svg',
    'camera-tplink-cloudcamera.svg': 'camera-tplink-cloudcamera.svg',
    'camera-tplink-hdpantiltcamera-frozen.svg':
      'camera-tplink-hdpantiltcamera-frozen.svg',
    'camera-tplink-hdpantiltcamera.svg': 'camera-tplink-hdpantiltcamera.svg',
    'camera-tplink-kasacam-frozen.svg': 'camera-tplink-kasacam-frozen.svg',
    'camera-tplink-kasacam.svg': 'camera-tplink-kasacam.svg',
    'camera-tplink-kasaoutdoorcam-frozen.svg':
      'camera-tplink-kasaoutdoorcam-frozen.svg',
    'camera-tplink-kasaoutdoorcam.svg': 'camera-tplink-kasaoutdoorcam.svg',
    'camera-tplink-tapo-frozen.svg': 'camera-tplink-tapo-frozen.svg',
    'camera-tplink-tapo.svg': 'camera-tplink-tapo.svg',
    'camera-ubiquiti-unifi-frozen.svg': 'camera-ubiquiti-unifi-frozen.svg',
    'camera-ubiquiti-unifi.svg': 'camera-ubiquiti-unifi.svg',
    'camera-ulifecam-ipcamera-frozen.svg':
      'camera-ulifecam-ipcamera-frozen.svg',
    'camera-ulifecam-ipcamera.svg': 'camera-ulifecam-ipcamera.svg',
    'camera-veo-camera-frozen.svg': 'camera-veo-camera-frozen.svg',
    'camera-veo-camera.svg': 'camera-veo-camera.svg',
    'camera-victure-ipcamera-frozen.svg': 'camera-victure-ipcamera-frozen.svg',
    'camera-victure-ipcamera.svg': 'camera-victure-ipcamera.svg',
    'camera-vivint-outdoorcamera-frozen.svg':
      'camera-vivint-outdoorcamera-frozen.svg',
    'camera-vivint-outdoorcamera.svg': 'camera-vivint-outdoorcamera.svg',
    'camera-vivotek-frozen.svg': 'camera-vivotek-frozen.svg',
    'camera-vivotek.svg': 'camera-vivotek.svg',
    'camera-wansview-frozen.svg': 'camera-wansview-frozen.svg',
    'camera-wansview.svg': 'camera-wansview.svg',
    'camera-withings-home-frozen.svg': 'camera-withings-home-frozen.svg',
    'camera-withings-home.svg': 'camera-withings-home.svg',
    'camera-wyze-cam-frozen.svg': 'camera-wyze-cam-frozen.svg',
    'camera-wyze-cam.svg': 'camera-wyze-cam.svg',
    'camera-xfinity-homeindooroutdoorcamera-frozen.svg':
      'camera-xfinity-homeindooroutdoorcamera-frozen.svg',
    'camera-xfinity-homeindooroutdoorcamera.svg':
      'camera-xfinity-homeindooroutdoorcamera.svg',
    'camera-xiaomi-chuangmicamera-frozen.svg':
      'camera-xiaomi-chuangmicamera-frozen.svg',
    'camera-xiaomi-chuangmicamera.svg': 'camera-xiaomi-chuangmicamera.svg',
    'camera-xiaomi-frozen.svg': 'camera-xiaomi-frozen.svg',
    'camera-xiaomi-mijiacamera-frozen.svg':
      'camera-xiaomi-mijiacamera-frozen.svg',
    'camera-xiaomi-mijiacamera.svg': 'camera-xiaomi-mijiacamera.svg',
    'camera-xiaomi.svg': 'camera-xiaomi.svg',
    'camera-yi-frozen.svg': 'camera-yi-frozen.svg',
    'camera-yi.svg': 'camera-yi.svg',
    'camera-zmodo-frozen.svg': 'camera-zmodo-frozen.svg',
    'camera-zmodo.svg': 'camera-zmodo.svg',
    'camera.svg': 'camera.svg',
    'camerascamcorders-eufy-camera-frozen.svg':
      'camerascamcorders-eufy-camera-frozen.svg',
    'camerascamcorders-eufy-camera.svg': 'camerascamcorders-eufy-camera.svg',
    'camerascamcorders-neos-smartcam-frozen.svg':
      'camerascamcorders-neos-smartcam-frozen.svg',
    'camerascamcorders-neos-smartcam.svg':
      'camerascamcorders-neos-smartcam.svg',
    'camerascamcorders-swann-ads450-frozen.svg':
      'camerascamcorders-swann-ads450-frozen.svg',
    'camerascamcorders-swann-ads450.svg': 'camerascamcorders-swann-ads450.svg',
    'car-bolt-frozen.svg': 'car-bolt-frozen.svg',
    'car-bolt.svg': 'car-bolt.svg',
    'car-chevrolet-frozen.svg': 'car-chevrolet-frozen.svg',
    'car-chevrolet.svg': 'car-chevrolet.svg',
    'car-ford-frozen.svg': 'car-ford-frozen.svg',
    'car-ford-sync-frozen.svg': 'car-ford-sync-frozen.svg',
    'car-ford-sync.svg': 'car-ford-sync.svg',
    'car-ford.svg': 'car-ford.svg',
    'car-frozen.svg': 'car-frozen.svg',
    'car-tesla-frozen.svg': 'car-tesla-frozen.svg',
    'car-tesla.svg': 'car-tesla.svg',
    'car.svg': 'car.svg',
    'clock-2-frozen.svg': 'clock-2-frozen.svg',
    'clock-2.svg': 'clock-2.svg',
    'clock-frozen.svg': 'clock-frozen.svg',
    'clock-geochron-atlas4k-frozen.svg': 'clock-geochron-atlas4k-frozen.svg',
    'clock-geochron-atlas4k.svg': 'clock-geochron-atlas4k.svg',
    'clock-lametric-time-frozen.svg': 'clock-lametric-time-frozen.svg',
    'clock-lametric-time.svg': 'clock-lametric-time.svg',
    'clock-lenovo-smartclock-frozen.svg': 'clock-lenovo-smartclock-frozen.svg',
    'clock-lenovo-smartclock.svg': 'clock-lenovo-smartclock.svg',
    'clock-magnavox-digitalclock-frozen.svg':
      'clock-magnavox-digitalclock-frozen.svg',
    'clock-magnavox-digitalclock.svg': 'clock-magnavox-digitalclock.svg',
    'clock-puri-nixieclock-frozen.svg': 'clock-puri-nixieclock-frozen.svg',
    'clock-puri-nixieclock.svg': 'clock-puri-nixieclock.svg',
    'clock-raspberrypi-hamclock-frozen.svg':
      'clock-raspberrypi-hamclock-frozen.svg',
    'clock-raspberrypi-hamclock.svg': 'clock-raspberrypi-hamclock.svg',
    'clock-sandman-doppler-frozen.svg': 'clock-sandman-doppler-frozen.svg',
    'clock-sandman-doppler.svg': 'clock-sandman-doppler.svg',
    'clock-xiaomi-ai-frozen.svg': 'clock-xiaomi-ai-frozen.svg',
    'clock-xiaomi-ai.svg': 'clock-xiaomi-ai.svg',
    'clock-xiaomi-mijiaclock-frozen.svg': 'clock-xiaomi-mijiaclock-frozen.svg',
    'clock-xiaomi-mijiaclock.svg': 'clock-xiaomi-mijiaclock.svg',
    'clock.svg': 'clock.svg',
    'coffeemaker-2-frozen.svg': 'coffeemaker-2-frozen.svg',
    'coffeemaker-2.svg': 'coffeemaker-2.svg',
    'coffeemaker-bosch-coffeemachine-frozen.svg':
      'coffeemaker-bosch-coffeemachine-frozen.svg',
    'coffeemaker-bosch-coffeemachine.svg':
      'coffeemaker-bosch-coffeemachine.svg',
    'coffeemaker-delonghi-coffeemaker-frozen.svg':
      'coffeemaker-delonghi-coffeemaker-frozen.svg',
    'coffeemaker-delonghi-coffeemaker.svg':
      'coffeemaker-delonghi-coffeemaker.svg',
    'coffeemaker-frozen.svg': 'coffeemaker-frozen.svg',
    'coffeemaker-keurig-frozen.svg': 'coffeemaker-keurig-frozen.svg',
    'coffeemaker-keurig.svg': 'coffeemaker-keurig.svg',
    'coffeemaker-siemens-coffeemachine-frozen.svg':
      'coffeemaker-siemens-coffeemachine-frozen.svg',
    'coffeemaker-siemens-coffeemachine.svg':
      'coffeemaker-siemens-coffeemachine.svg',
    'coffeemaker-thermador-coffeemachine-frozen.svg':
      'coffeemaker-thermador-coffeemachine-frozen.svg',
    'coffeemaker-thermador-coffeemachine.svg':
      'coffeemaker-thermador-coffeemachine.svg',
    'coffeemaker.svg': 'coffeemaker.svg',
    'computer-acer-aspire-frozen.svg': 'computer-acer-aspire-frozen.svg',
    'computer-acer-aspire.svg': 'computer-acer-aspire.svg',
    'computer-acer-chromebook-frozen.svg':
      'computer-acer-chromebook-frozen.svg',
    'computer-acer-chromebook.svg': 'computer-acer-chromebook.svg',
    'computer-apple-imac-frozen.svg': 'computer-apple-imac-frozen.svg',
    'computer-apple-imac.svg': 'computer-apple-imac.svg',
    'computer-apple-mac-frozen.svg': 'computer-apple-mac-frozen.svg',
    'computer-apple-mac.svg': 'computer-apple-mac.svg',
    'computer-apple-macmini-frozen.svg': 'computer-apple-macmini-frozen.svg',
    'computer-apple-macmini.svg': 'computer-apple-macmini.svg',
    'computer-apple-macpro-frozen.svg': 'computer-apple-macpro-frozen.svg',
    'computer-apple-macpro.svg': 'computer-apple-macpro.svg',
    'computer-asus-chromebook-frozen.svg':
      'computer-asus-chromebook-frozen.svg',
    'computer-asus-chromebook.svg': 'computer-asus-chromebook.svg',
    'computer-asus-desktop-frozen.svg': 'computer-asus-desktop-frozen.svg',
    'computer-asus-desktop.svg': 'computer-asus-desktop.svg',
    'computer-asus-frozen.svg': 'computer-asus-frozen.svg',
    'computer-asus-tinkerboard-frozen.svg':
      'computer-asus-tinkerboard-frozen.svg',
    'computer-asus-tinkerboard.svg': 'computer-asus-tinkerboard.svg',
    'computer-asus-windows-frozen.svg': 'computer-asus-windows-frozen.svg',
    'computer-asus-windows.svg': 'computer-asus-windows.svg',
    'computer-asus-zenbook-frozen.svg': 'computer-asus-zenbook-frozen.svg',
    'computer-asus-zenbook.svg': 'computer-asus-zenbook.svg',
    'computer-asus.svg': 'computer-asus.svg',
    'computer-atrust-thinclient-frozen.svg':
      'computer-atrust-thinclient-frozen.svg',
    'computer-atrust-thinclient.svg': 'computer-atrust-thinclient.svg',
    'computer-compulab-airtop-frozen.svg':
      'computer-compulab-airtop-frozen.svg',
    'computer-compulab-airtop.svg': 'computer-compulab-airtop.svg',
    'computer-dell-alienware-frozen.svg': 'computer-dell-alienware-frozen.svg',
    'computer-dell-alienware.svg': 'computer-dell-alienware.svg',
    'computer-dell-chromebook-frozen.svg':
      'computer-dell-chromebook-frozen.svg',
    'computer-dell-chromebook.svg': 'computer-dell-chromebook.svg',
    'computer-dell-dimension-frozen.svg': 'computer-dell-dimension-frozen.svg',
    'computer-dell-dimension.svg': 'computer-dell-dimension.svg',
    'computer-dell-g5-frozen.svg': 'computer-dell-g5-frozen.svg',
    'computer-dell-g5.svg': 'computer-dell-g5.svg',
    'computer-dell-windows-1-frozen.svg': 'computer-dell-windows-1-frozen.svg',
    'computer-dell-windows-1.svg': 'computer-dell-windows-1.svg',
    'computer-dell-windows-frozen.svg': 'computer-dell-windows-frozen.svg',
    'computer-dell-windows.svg': 'computer-dell-windows.svg',
    'computer-dell-xps-frozen.svg': 'computer-dell-xps-frozen.svg',
    'computer-dell-xps.svg': 'computer-dell-xps.svg',
    'computer-desktop-frozen.svg': 'computer-desktop-frozen.svg',
    'computer-desktop.svg': 'computer-desktop.svg',
    'computer-frozen.svg': 'computer-frozen.svg',
    'computer-fujitsu-fmvda2a041-frozen.svg':
      'computer-fujitsu-fmvda2a041-frozen.svg',
    'computer-fujitsu-fmvda2a041.svg': 'computer-fujitsu-fmvda2a041.svg',
    'computer-gigabyte-brix-frozen.svg': 'computer-gigabyte-brix-frozen.svg',
    'computer-gigabyte-brix.svg': 'computer-gigabyte-brix.svg',
    'computer-google-chromebook-frozen.svg':
      'computer-google-chromebook-frozen.svg',
    'computer-google-chromebook.svg': 'computer-google-chromebook.svg',
    'computer-google-pixelbook-frozen.svg':
      'computer-google-pixelbook-frozen.svg',
    'computer-google-pixelbook.svg': 'computer-google-pixelbook.svg',
    'computer-hp-chromebook-frozen.svg': 'computer-hp-chromebook-frozen.svg',
    'computer-hp-chromebook.svg': 'computer-hp-chromebook.svg',
    'computer-hp-eliteone-frozen.svg': 'computer-hp-eliteone-frozen.svg',
    'computer-hp-eliteone.svg': 'computer-hp-eliteone.svg',
    'computer-hp-workstation-frozen.svg': 'computer-hp-workstation-frozen.svg',
    'computer-hp-workstation.svg': 'computer-hp-workstation.svg',
    'computer-ibuypower-gamingcomputer-frozen.svg':
      'computer-ibuypower-gamingcomputer-frozen.svg',
    'computer-ibuypower-gamingcomputer.svg':
      'computer-ibuypower-gamingcomputer.svg',
    'computer-ibuypower-revolt2-frozen.svg':
      'computer-ibuypower-revolt2-frozen.svg',
    'computer-ibuypower-revolt2.svg': 'computer-ibuypower-revolt2.svg',
    'computer-igel-thinclient-frozen.svg':
      'computer-igel-thinclient-frozen.svg',
    'computer-igel-thinclient.svg': 'computer-igel-thinclient.svg',
    'computer-imsai-8080-frozen.svg': 'computer-imsai-8080-frozen.svg',
    'computer-imsai-8080.svg': 'computer-imsai-8080.svg',
    'computer-intel-nuc-frozen.svg': 'computer-intel-nuc-frozen.svg',
    'computer-intel-nuc.svg': 'computer-intel-nuc.svg',
    'computer-leadtek-pcoip-frozen.svg': 'computer-leadtek-pcoip-frozen.svg',
    'computer-leadtek-pcoip.svg': 'computer-leadtek-pcoip.svg',
    'computer-lenovo-thinkcentre-frozen.svg':
      'computer-lenovo-thinkcentre-frozen.svg',
    'computer-lenovo-thinkcentre.svg': 'computer-lenovo-thinkcentre.svg',
    'computer-microsoft-surface-frozen.svg':
      'computer-microsoft-surface-frozen.svg',
    'computer-microsoft-surface.svg': 'computer-microsoft-surface.svg',
    'computer-optiplex-frozen.svg': 'computer-optiplex-frozen.svg',
    'computer-optiplex.svg': 'computer-optiplex.svg',
    'computer-orangepi-pc-frozen.svg': 'computer-orangepi-pc-frozen.svg',
    'computer-orangepi-pc.svg': 'computer-orangepi-pc.svg',
    'computer-pidp-computer-frozen.svg': 'computer-pidp-computer-frozen.svg',
    'computer-pidp-computer.svg': 'computer-pidp-computer.svg',
    'computer-vizio-frozen.svg': 'computer-vizio-frozen.svg',
    'computer-vizio.svg': 'computer-vizio.svg',
    'computer.svg': 'computer.svg',
    'cooker-bosch-cookit-frozen.svg': 'cooker-bosch-cookit-frozen.svg',
    'cooker-bosch-cookit.svg': 'cooker-bosch-cookit.svg',
    'cooker-bosch-frozen.svg': 'cooker-bosch-frozen.svg',
    'cooker-bosch-oven-frozen.svg': 'cooker-bosch-oven-frozen.svg',
    'cooker-bosch-oven.svg': 'cooker-bosch-oven.svg',
    'cooker-bosch.svg': 'cooker-bosch.svg',
    'cooker-frozen.svg': 'cooker-frozen.svg',
    'cooker-thermomix-frozen.svg': 'cooker-thermomix-frozen.svg',
    'cooker-thermomix-tm6-frozen.svg': 'cooker-thermomix-tm6-frozen.svg',
    'cooker-thermomix-tm6.svg': 'cooker-thermomix-tm6.svg',
    'cooker-thermomix.svg': 'cooker-thermomix.svg',
    'cooker.svg': 'cooker.svg',
    'cooktop-bosch-cooktop-frozen.svg': 'cooktop-bosch-cooktop-frozen.svg',
    'cooktop-bosch-cooktop.svg': 'cooktop-bosch-cooktop.svg',
    'cooktop-frozen.svg': 'cooktop-frozen.svg',
    'cooktop-siemens-frozen.svg': 'cooktop-siemens-frozen.svg',
    'cooktop-siemens.svg': 'cooktop-siemens.svg',
    'cooktop-thermador-frozen.svg': 'cooktop-thermador-frozen.svg',
    'cooktop-thermador-gasrangev-frozen.svg':
      'cooktop-thermador-gasrangev-frozen.svg',
    'cooktop-thermador-gasrangev.svg': 'cooktop-thermador-gasrangev.svg',
    'cooktop-thermador-hood-frozen.svg': 'cooktop-thermador-hood-frozen.svg',
    'cooktop-thermador-hood.svg': 'cooktop-thermador-hood.svg',
    'cooktop-thermador.svg': 'cooktop-thermador.svg',
    'cooktop-vzug-frozen.svg': 'cooktop-vzug-frozen.svg',
    'cooktop-vzug.svg': 'cooktop-vzug.svg',
    'cooktop.svg': 'cooktop.svg',
    'customgateway-cambium-epmp-frozen.svg':
      'customgateway-cambium-epmp-frozen.svg',
    'customgateway-cambium-epmp.svg': 'customgateway-cambium-epmp.svg',
    'customgateway-circle-home-frozen.svg':
      'customgateway-circle-home-frozen.svg',
    'customgateway-circle-home.svg': 'customgateway-circle-home.svg',
    'customgateway-cujo-securegateway-frozen.svg':
      'customgateway-cujo-securegateway-frozen.svg',
    'customgateway-cujo-securegateway.svg':
      'customgateway-cujo-securegateway.svg',
    'customgateway-frozen.svg': 'customgateway-frozen.svg',
    'customgateway-intesis-gateway-frozen.svg':
      'customgateway-intesis-gateway-frozen.svg',
    'customgateway-intesis-gateway.svg': 'customgateway-intesis-gateway.svg',
    'customgateway-sharkrf-openspot-frozen.svg':
      'customgateway-sharkrf-openspot-frozen.svg',
    'customgateway-sharkrf-openspot.svg': 'customgateway-sharkrf-openspot.svg',
    'customgateway.svg': 'customgateway.svg',
    'diffuser-aera-smartdiffuser-frozen.svg':
      'diffuser-aera-smartdiffuser-frozen.svg',
    'diffuser-aera-smartdiffuser.svg': 'diffuser-aera-smartdiffuser.svg',
    'diffuser-frozen.svg': 'diffuser-frozen.svg',
    'diffuser-pura-smartdiffuser-frozen.svg':
      'diffuser-pura-smartdiffuser-frozen.svg',
    'diffuser-pura-smartdiffuser.svg': 'diffuser-pura-smartdiffuser.svg',
    'diffuser.svg': 'diffuser.svg',
    'dishwasher-bosch-dishwasher-frozen.svg':
      'dishwasher-bosch-dishwasher-frozen.svg',
    'dishwasher-bosch-dishwasher.svg': 'dishwasher-bosch-dishwasher.svg',
    'dishwasher-bosch-shem78wh5n-frozen.svg':
      'dishwasher-bosch-shem78wh5n-frozen.svg',
    'dishwasher-bosch-shem78wh5n.svg': 'dishwasher-bosch-shem78wh5n.svg',
    'dishwasher-cove-dishwasher-frozen.svg':
      'dishwasher-cove-dishwasher-frozen.svg',
    'dishwasher-cove-dishwasher.svg': 'dishwasher-cove-dishwasher.svg',
    'dishwasher-frozen.svg': 'dishwasher-frozen.svg',
    'dishwasher-ge-frozen.svg': 'dishwasher-ge-frozen.svg',
    'dishwasher-ge.svg': 'dishwasher-ge.svg',
    'dishwasher-lg-frozen.svg': 'dishwasher-lg-frozen.svg',
    'dishwasher-lg.svg': 'dishwasher-lg.svg',
    'dishwasher-samsung-frozen.svg': 'dishwasher-samsung-frozen.svg',
    'dishwasher-samsung.svg': 'dishwasher-samsung.svg',
    'dishwasher-seimens-frozen.svg': 'dishwasher-seimens-frozen.svg',
    'dishwasher-seimens.svg': 'dishwasher-seimens.svg',
    'dishwasher-thermador-frozen.svg': 'dishwasher-thermador-frozen.svg',
    'dishwasher-thermador.svg': 'dishwasher-thermador.svg',
    'dishwasher-vzug-frozen.svg': 'dishwasher-vzug-frozen.svg',
    'dishwasher-vzug.svg': 'dishwasher-vzug.svg',
    'dishwasher-whirlpool-frozen.svg': 'dishwasher-whirlpool-frozen.svg',
    'dishwasher-whirlpool.svg': 'dishwasher-whirlpool.svg',
    'dishwasher.svg': 'dishwasher.svg',
    'doorbell-1.svg': 'doorbell-1.svg',
    'doorbell-2-frozen.svg': 'doorbell-2-frozen.svg',
    'doorbell-2.svg': 'doorbell-2.svg',
    'doorbell-amazonring-chime-frozen.svg':
      'doorbell-amazonring-chime-frozen.svg',
    'doorbell-amazonring-chime.svg': 'doorbell-amazonring-chime.svg',
    'doorbell-amazonring-chimepro-frozen.svg':
      'doorbell-amazonring-chimepro-frozen.svg',
    'doorbell-amazonring-chimepro.svg': 'doorbell-amazonring-chimepro.svg',
    'doorbell-amazonring-elite-frozen.svg':
      'doorbell-amazonring-elite-frozen.svg',
    'doorbell-amazonring-elite.svg': 'doorbell-amazonring-elite.svg',
    'doorbell-amazonring-frozen.svg': 'doorbell-amazonring-frozen.svg',
    'doorbell-amazonring-pro-frozen.svg': 'doorbell-amazonring-pro-frozen.svg',
    'doorbell-amazonring-pro.svg': 'doorbell-amazonring-pro.svg',
    'doorbell-amazonring-videodoorbell-frozen.svg':
      'doorbell-amazonring-videodoorbell-frozen.svg',
    'doorbell-amazonring-videodoorbell.svg':
      'doorbell-amazonring-videodoorbell.svg',
    'doorbell-amazonring.svg': 'doorbell-amazonring.svg',
    'doorbell-amcrest-videodoorbell-frozen.svg':
      'doorbell-amcrest-videodoorbell-frozen.svg',
    'doorbell-amcrest-videodoorbell.svg': 'doorbell-amcrest-videodoorbell.svg',
    'doorbell-arlo-videodoorbell-frozen.svg':
      'doorbell-arlo-videodoorbell-frozen.svg',
    'doorbell-arlo-videodoorbell.svg': 'doorbell-arlo-videodoorbell.svg',
    'doorbell-august-view-frozen.svg': 'doorbell-august-view-frozen.svg',
    'doorbell-august-view.svg': 'doorbell-august-view.svg',
    'doorbell-bticino-classe-frozen.svg': 'doorbell-bticino-classe-frozen.svg',
    'doorbell-bticino-classe.svg': 'doorbell-bticino-classe.svg',
    'doorbell-control4-chime-frozen.svg': 'doorbell-control4-chime-frozen.svg',
    'doorbell-control4-chime.svg': 'doorbell-control4-chime.svg',
    'doorbell-dling-smartvideodoorbell-frozen.svg':
      'doorbell-dling-smartvideodoorbell-frozen.svg',
    'doorbell-dling-smartvideodoorbell.svg':
      'doorbell-dling-smartvideodoorbell.svg',
    'doorbell-doorbird-videointercom-frozen.svg':
      'doorbell-doorbird-videointercom-frozen.svg',
    'doorbell-doorbird-videointercom.svg':
      'doorbell-doorbird-videointercom.svg',
    'doorbell-eufy-videodoorbell-frozen.svg':
      'doorbell-eufy-videodoorbell-frozen.svg',
    'doorbell-eufy-videodoorbell.svg': 'doorbell-eufy-videodoorbell.svg',
    'doorbell-frozen-1.svg': 'doorbell-frozen-1.svg',
    'doorbell-frozen.svg': 'doorbell-frozen.svg',
    'doorbell-kangaroo-doorbellcamera-frozen.svg':
      'doorbell-kangaroo-doorbellcamera-frozen.svg',
    'doorbell-kangaroo-doorbellcamera.svg':
      'doorbell-kangaroo-doorbellcamera.svg',
    'doorbell-nest-frozen.svg': 'doorbell-nest-frozen.svg',
    'doorbell-nest.svg': 'doorbell-nest.svg',
    'doorbell-panasonic-videointercom-frozen.svg':
      'doorbell-panasonic-videointercom-frozen.svg',
    'doorbell-panasonic-videointercom.svg':
      'doorbell-panasonic-videointercom.svg',
    'doorbell-remo+-frozen.svg': 'doorbell-remo+-frozen.svg',
    'doorbell-remo+.svg': 'doorbell-remo+.svg',
    'doorbell-ring-frozen.svg': 'doorbell-ring-frozen.svg',
    'doorbell-ring-videodoorbell-frozen.svg':
      'doorbell-ring-videodoorbell-frozen.svg',
    'doorbell-ring-videodoorbell.svg': 'doorbell-ring-videodoorbell.svg',
    'doorbell-ring.svg': 'doorbell-ring.svg',
    'doorbell-skybell-frozen.svg': 'doorbell-skybell-frozen.svg',
    'doorbell-skybell.svg': 'doorbell-skybell.svg',
    'doorbell-toucan-videodoorbell-frozen.svg':
      'doorbell-toucan-videodoorbell-frozen.svg',
    'doorbell-toucan-videodoorbell.svg': 'doorbell-toucan-videodoorbell.svg',
    'doorbell.svg': 'doorbell.svg',
    'doorlock-2-frozen.svg': 'doorlock-2-frozen.svg',
    'doorlock-2.svg': 'doorlock-2.svg',
    'doorlock-august-smartlock-frozen.svg':
      'doorlock-august-smartlock-frozen.svg',
    'doorlock-august-smartlock.svg': 'doorlock-august-smartlock.svg',
    'doorlock-frozen.svg': 'doorlock-frozen.svg',
    'doorlock-halo-smartlock-frozen.svg': 'doorlock-halo-smartlock-frozen.svg',
    'doorlock-halo-smartlock.svg': 'doorlock-halo-smartlock.svg',
    'doorlock-kwikset-halo-frozen.svg': 'doorlock-kwikset-halo-frozen.svg',
    'doorlock-kwikset-halo.svg': 'doorlock-kwikset-halo.svg',
    'doorlock-nuki-smartlock-frozen.svg': 'doorlock-nuki-smartlock-frozen.svg',
    'doorlock-nuki-smartlock.svg': 'doorlock-nuki-smartlock.svg',
    'doorlock-schlage-smartdeadbolt-frozen.svg':
      'doorlock-schlage-smartdeadbolt-frozen.svg',
    'doorlock-schlage-smartdeadbolt.svg': 'doorlock-schlage-smartdeadbolt.svg',
    'doorlock-sesame-smartlock-frozen.svg':
      'doorlock-sesame-smartlock-frozen.svg',
    'doorlock-sesame-smartlock.svg': 'doorlock-sesame-smartlock.svg',
    'doorlock-ultraloq-deadbolt-frozen.svg':
      'doorlock-ultraloq-deadbolt-frozen.svg',
    'doorlock-ultraloq-deadbolt.svg': 'doorlock-ultraloq-deadbolt.svg',
    'doorlock-wyze-lock-frozen.svg': 'doorlock-wyze-lock-frozen.svg',
    'doorlock-wyze-lock.svg': 'doorlock-wyze-lock.svg',
    'doorlock.svg': 'doorlock.svg',
    'ereader-amazon-kindle-frozen.svg': 'ereader-amazon-kindle-frozen.svg',
    'ereader-amazon-kindle.svg': 'ereader-amazon-kindle.svg',
    'ereader-barnesandnoble-nook-frozen.svg':
      'ereader-barnesandnoble-nook-frozen.svg',
    'ereader-barnesandnoble-nook.svg': 'ereader-barnesandnoble-nook.svg',
    'ereader-ebook-frozen.svg': 'ereader-ebook-frozen.svg',
    'ereader-ebook.svg': 'ereader-ebook.svg',
    'ereader-ereader-amazon-kindle-frozen.svg':
      'ereader-ereader-amazon-kindle-frozen.svg',
    'ereader-ereader-amazon-kindle.svg': 'ereader-ereader-amazon-kindle.svg',
    'ereader-kobo-frozen.svg': 'ereader-kobo-frozen.svg',
    'ereader-kobo.svg': 'ereader-kobo.svg',
    'ereader-tolino-frozen.svg': 'ereader-tolino-frozen.svg',
    'ereader-tolino.svg': 'ereader-tolino.svg',
    'evcharger-chargepoint-frozen.svg': 'evcharger-chargepoint-frozen.svg',
    'evcharger-chargepoint.svg': 'evcharger-chargepoint.svg',
    'evcharger-easee-home-frozen.svg': 'evcharger-easee-home-frozen.svg',
    'evcharger-easee-home.svg': 'evcharger-easee-home.svg',
    'evcharger-flo-home-frozen.svg': 'evcharger-flo-home-frozen.svg',
    'evcharger-flo-home.svg': 'evcharger-flo-home.svg',
    'evcharger-frozen.svg': 'evcharger-frozen.svg',
    'evcharger-goe-evcharger-frozen.svg': 'evcharger-goe-evcharger-frozen.svg',
    'evcharger-goe-evcharger.svg': 'evcharger-goe-evcharger.svg',
    'evcharger-juicebox-frozen.svg': 'evcharger-juicebox-frozen.svg',
    'evcharger-juicebox.svg': 'evcharger-juicebox.svg',
    'evcharger-openevse-evcharger-frozen.svg':
      'evcharger-openevse-evcharger-frozen.svg',
    'evcharger-openevse-evcharger.svg': 'evcharger-openevse-evcharger.svg',
    'evcharger-tesla-wallconnector-frozen.svg':
      'evcharger-tesla-wallconnector-frozen.svg',
    'evcharger-tesla-wallconnector.svg': 'evcharger-tesla-wallconnector.svg',
    'evcharger.svg': 'evcharger.svg',
    'fan-bigassfans-haiku-frozen.svg': 'fan-bigassfans-haiku-frozen.svg',
    'fan-bigassfans-haiku.svg': 'fan-bigassfans-haiku.svg',
    'fan-bigassfans-haikufan-frozen.svg': 'fan-bigassfans-haikufan-frozen.svg',
    'fan-bigassfans-haikufan.svg': 'fan-bigassfans-haikufan.svg',
    'fan-dyson-coolfan-frozen.svg': 'fan-dyson-coolfan-frozen.svg',
    'fan-dyson-coolfan.svg': 'fan-dyson-coolfan.svg',
    'fan-dyson-frozen.svg': 'fan-dyson-frozen.svg',
    'fan-dyson-purecool-frozen.svg': 'fan-dyson-purecool-frozen.svg',
    'fan-dyson-purecool.svg': 'fan-dyson-purecool.svg',
    'fan-dyson-purecoolfan-frozen.svg': 'fan-dyson-purecoolfan-frozen.svg',
    'fan-dyson-purecoolfan.svg': 'fan-dyson-purecoolfan.svg',
    'fan-dyson-purehot+cool-frozen.svg': 'fan-dyson-purehot+cool-frozen.svg',
    'fan-dyson-purehot+cool.svg': 'fan-dyson-purehot+cool.svg',
    'fan-dyson-purehot+coolfan-frozen.svg':
      'fan-dyson-purehot+coolfan-frozen.svg',
    'fan-dyson-purehot+coolfan.svg': 'fan-dyson-purehot+coolfan.svg',
    'fan-dyson.svg': 'fan-dyson.svg',
    'fan-frozen.svg': 'fan-frozen.svg',
    'fan-haiku-frozen.svg': 'fan-haiku-frozen.svg',
    'fan-haiku.svg': 'fan-haiku.svg',
    'fan-hunter-ceilingfan-frozen.svg': 'fan-hunter-ceilingfan-frozen.svg',
    'fan-hunter-ceilingfan.svg': 'fan-hunter-ceilingfan.svg',
    'fan-xiaomi-smartmipedestalfan-frozen.svg':
      'fan-xiaomi-smartmipedestalfan-frozen.svg',
    'fan-xiaomi-smartmipedestalfan.svg': 'fan-xiaomi-smartmipedestalfan.svg',
    'fan.svg': 'fan.svg',
    'fitnessbike-frozen.svg': 'fitnessbike-frozen.svg',
    'fitnessbike-myxfitness-bike-frozen.svg':
      'fitnessbike-myxfitness-bike-frozen.svg',
    'fitnessbike-myxfitness-bike.svg': 'fitnessbike-myxfitness-bike.svg',
    'fitnessbike-myxfitness-frozen.svg': 'fitnessbike-myxfitness-frozen.svg',
    'fitnessbike-myxfitness.svg': 'fitnessbike-myxfitness.svg',
    'fitnessbike-nordictrack-bike-frozen.svg':
      'fitnessbike-nordictrack-bike-frozen.svg',
    'fitnessbike-nordictrack-bike.svg': 'fitnessbike-nordictrack-bike.svg',
    'fitnessbike-nordictrack-frozen.svg': 'fitnessbike-nordictrack-frozen.svg',
    'fitnessbike-nordictrack.svg': 'fitnessbike-nordictrack.svg',
    'fitnessbike-peleton-bike-frozen.svg':
      'fitnessbike-peleton-bike-frozen.svg',
    'fitnessbike-peleton-bike.svg': 'fitnessbike-peleton-bike.svg',
    'fitnessbike-peleton-frozen.svg': 'fitnessbike-peleton-frozen.svg',
    'fitnessbike-peleton.svg': 'fitnessbike-peleton.svg',
    'fitnessbike.svg': 'fitnessbike.svg',
    'fitnesstrainer-fightcamp-athomeboxing-frozen.svg':
      'fitnesstrainer-fightcamp-athomeboxing-frozen.svg',
    'fitnesstrainer-fightcamp-athomeboxing.svg':
      'fitnesstrainer-fightcamp-athomeboxing.svg',
    'fitnesstrainer-frozen.svg': 'fitnesstrainer-frozen.svg',
    'fitnesstrainer-mirror-fitness-frozen.svg':
      'fitnesstrainer-mirror-fitness-frozen.svg',
    'fitnesstrainer-mirror-fitness.svg': 'fitnesstrainer-mirror-fitness.svg',
    'fitnesstrainer-nordictrack-frozen.svg':
      'fitnesstrainer-nordictrack-frozen.svg',
    'fitnesstrainer-nordictrack-trainer-frozen.svg':
      'fitnesstrainer-nordictrack-trainer-frozen.svg',
    'fitnesstrainer-nordictrack-trainer.svg':
      'fitnesstrainer-nordictrack-trainer.svg',
    'fitnesstrainer-nordictrack-treadmill-frozen.svg':
      'fitnesstrainer-nordictrack-treadmill-frozen.svg',
    'fitnesstrainer-nordictrack-treadmill.svg':
      'fitnesstrainer-nordictrack-treadmill.svg',
    'fitnesstrainer-nordictrack.svg': 'fitnesstrainer-nordictrack.svg',
    'fitnesstrainer-peloton-tread-frozen.svg':
      'fitnesstrainer-peloton-tread-frozen.svg',
    'fitnesstrainer-peloton-tread.svg': 'fitnesstrainer-peloton-tread.svg',
    'fitnesstrainer-proform-elliptical-frozen.svg':
      'fitnesstrainer-proform-elliptical-frozen.svg',
    'fitnesstrainer-proform-elliptical.svg':
      'fitnesstrainer-proform-elliptical.svg',
    'fitnesstrainer-proform-frozen.svg': 'fitnesstrainer-proform-frozen.svg',
    'fitnesstrainer-proform-hiittrainer-frozen.svg':
      'fitnesstrainer-proform-hiittrainer-frozen.svg',
    'fitnesstrainer-proform-hiittrainer.svg':
      'fitnesstrainer-proform-hiittrainer.svg',
    'fitnesstrainer-proform-trainer-frozen.svg':
      'fitnesstrainer-proform-trainer-frozen.svg',
    'fitnesstrainer-proform-trainer.svg': 'fitnesstrainer-proform-trainer.svg',
    'fitnesstrainer-proform-treadmill-frozen.svg':
      'fitnesstrainer-proform-treadmill-frozen.svg',
    'fitnesstrainer-proform-treadmill.svg':
      'fitnesstrainer-proform-treadmill.svg',
    'fitnesstrainer-proform.svg': 'fitnesstrainer-proform.svg',
    'fitnesstrainer-tonal-trainer-frozen.svg':
      'fitnesstrainer-tonal-trainer-frozen.svg',
    'fitnesstrainer-tonal-trainer.svg': 'fitnesstrainer-tonal-trainer.svg',
    'fitnesstrainer-walkingpad-foldingtreadmill-frozen.svg':
      'fitnesstrainer-walkingpad-foldingtreadmill-frozen.svg',
    'fitnesstrainer-walkingpad-foldingtreadmill.svg':
      'fitnesstrainer-walkingpad-foldingtreadmill.svg',
    'fitnesstrainer.svg': 'fitnesstrainer.svg',
    'fridge-bosch-freezer-frozen.svg': 'fridge-bosch-freezer-frozen.svg',
    'fridge-bosch-freezer.svg': 'fridge-bosch-freezer.svg',
    'fridge-bosch-refrigerator-frozen.svg':
      'fridge-bosch-refrigerator-frozen.svg',
    'fridge-bosch-refrigerator.svg': 'fridge-bosch-refrigerator.svg',
    'fridge-frozen.svg': 'fridge-frozen.svg',
    'fridge-ge-refrigerator-frozen.svg': 'fridge-ge-refrigerator-frozen.svg',
    'fridge-ge-refrigerator.svg': 'fridge-ge-refrigerator.svg',
    'fridge-lg-refrigerator-frozen.svg': 'fridge-lg-refrigerator-frozen.svg',
    'fridge-lg-refrigerator.svg': 'fridge-lg-refrigerator.svg',
    'fridge-samsung-familyhub-frozen.svg':
      'fridge-samsung-familyhub-frozen.svg',
    'fridge-samsung-familyhub.svg': 'fridge-samsung-familyhub.svg',
    'fridge-siemens-frozen.svg': 'fridge-siemens-frozen.svg',
    'fridge-siemens.svg': 'fridge-siemens.svg',
    'fridge-subzero-refrigerator-frozen.svg':
      'fridge-subzero-refrigerator-frozen.svg',
    'fridge-subzero-refrigerator.svg': 'fridge-subzero-refrigerator.svg',
    'fridge-thermador-frozen.svg': 'fridge-thermador-frozen.svg',
    'fridge-thermador.svg': 'fridge-thermador.svg',
    'fridge-vzug-refrigerator-frozen.svg':
      'fridge-vzug-refrigerator-frozen.svg',
    'fridge-vzug-refrigerator.svg': 'fridge-vzug-refrigerator.svg',
    'fridge-whirlpool-refrigerator-frozen.svg':
      'fridge-whirlpool-refrigerator-frozen.svg',
    'fridge-whirlpool-refrigerator.svg': 'fridge-whirlpool-refrigerator.svg',
    'fridge.svg': 'fridge.svg',
    'gameconsole-atari-gameconsole-frozen.svg':
      'gameconsole-atari-gameconsole-frozen.svg',
    'gameconsole-atari-gameconsole.svg': 'gameconsole-atari-gameconsole.svg',
    'gameconsole-atari-vcs-frozen.svg': 'gameconsole-atari-vcs-frozen.svg',
    'gameconsole-atari-vcs.svg': 'gameconsole-atari-vcs.svg',
    'gameconsole-atgames-ha2810-frozen.svg':
      'gameconsole-atgames-ha2810-frozen.svg',
    'gameconsole-atgames-ha2810.svg': 'gameconsole-atgames-ha2810.svg',
    'gameconsole-goldentee-live-frozen.svg':
      'gameconsole-goldentee-live-frozen.svg',
    'gameconsole-goldentee-live.svg': 'gameconsole-goldentee-live.svg',
    'gameconsole-google-stadia-frozen.svg':
      'gameconsole-google-stadia-frozen.svg',
    'gameconsole-google-stadia.svg': 'gameconsole-google-stadia.svg',
    'gameconsole-leapfrog-explorer-frozen.svg':
      'gameconsole-leapfrog-explorer-frozen.svg',
    'gameconsole-leapfrog-explorer.svg': 'gameconsole-leapfrog-explorer.svg',
    'gameconsole-microsoft-frozen.svg': 'gameconsole-microsoft-frozen.svg',
    'gameconsole-microsoft.svg': 'gameconsole-microsoft.svg',
    'gameconsole-nintendo-3ds-frozen.svg':
      'gameconsole-nintendo-3ds-frozen.svg',
    'gameconsole-nintendo-3ds.svg': 'gameconsole-nintendo-3ds.svg',
    'gameconsole-nintendo-ds-frozen.svg': 'gameconsole-nintendo-ds-frozen.svg',
    'gameconsole-nintendo-ds.svg': 'gameconsole-nintendo-ds.svg',
    'gameconsole-nintendo-wii-frozen.svg':
      'gameconsole-nintendo-wii-frozen.svg',
    'gameconsole-nintendo-wii.svg': 'gameconsole-nintendo-wii.svg',
    'gameconsole-odroid-goadvance-frozen.svg':
      'gameconsole-odroid-goadvance-frozen.svg',
    'gameconsole-odroid-goadvance.svg': 'gameconsole-odroid-goadvance.svg',
    'gameconsole-playbox-4one-frozen.svg':
      'gameconsole-playbox-4one-frozen.svg',
    'gameconsole-playbox-4one.svg': 'gameconsole-playbox-4one.svg',
    'gameconsole-raspberrypi-retropie-frozen.svg':
      'gameconsole-raspberrypi-retropie-frozen.svg',
    'gameconsole-raspberrypi-retropie.svg':
      'gameconsole-raspberrypi-retropie.svg',
    'gameconsole-sony-playstation-frozen.svg':
      'gameconsole-sony-playstation-frozen.svg',
    'gameconsole-sony-playstation.svg': 'gameconsole-sony-playstation.svg',
    'gameconsole.svg': 'gameconsole.svg',
    'garageopener-chamberlain-frozen.svg':
      'garageopener-chamberlain-frozen.svg',
    'garageopener-chamberlain-myq-frozen.svg':
      'garageopener-chamberlain-myq-frozen.svg',
    'garageopener-chamberlain-myq.svg': 'garageopener-chamberlain-myq.svg',
    'garageopener-chamberlain.svg': 'garageopener-chamberlain.svg',
    'garageopener-frozen.svg': 'garageopener-frozen.svg',
    'garageopener-gogogate-garageopener-frozen.svg':
      'garageopener-gogogate-garageopener-frozen.svg',
    'garageopener-gogogate-garageopener.svg':
      'garageopener-gogogate-garageopener.svg',
    'garageopener-insignia-wifigaragedoorcontroller-frozen.svg':
      'garageopener-insignia-wifigaragedoorcontroller-frozen.svg',
    'garageopener-insignia-wifigaragedoorcontroller.svg':
      'garageopener-insignia-wifigaragedoorcontroller.svg',
    'garageopener-ismartgate-frozen.svg': 'garageopener-ismartgate-frozen.svg',
    'garageopener-ismartgate.svg': 'garageopener-ismartgate.svg',
    'garageopener-meross-smartgarageopener-frozen.svg':
      'garageopener-meross-smartgarageopener-frozen.svg',
    'garageopener-meross-smartgarageopener.svg':
      'garageopener-meross-smartgarageopener.svg',
    'garageopener-momentum-niro-frozen.svg':
      'garageopener-momentum-niro-frozen.svg',
    'garageopener-momentum-niro.svg': 'garageopener-momentum-niro.svg',
    'garageopener-nexx-garage-frozen.svg':
      'garageopener-nexx-garage-frozen.svg',
    'garageopener-nexx-garage.svg': 'garageopener-nexx-garage.svg',
    'garageopener-remootio-garageopener-frozen.svg':
      'garageopener-remootio-garageopener-frozen.svg',
    'garageopener-remootio-garageopener.svg':
      'garageopener-remootio-garageopener.svg',
    'garageopener-skylink-garagedooropener-frozen.svg':
      'garageopener-skylink-garagedooropener-frozen.svg',
    'garageopener-skylink-garagedooropener.svg':
      'garageopener-skylink-garagedooropener.svg',
    'garageopener.svg': 'garageopener.svg',
    'homesecurity-abode-frozen.svg': 'homesecurity-abode-frozen.svg',
    'homesecurity-abode.svg': 'homesecurity-abode.svg',
    'homesecurity-adt-securitypanel-frozen.svg':
      'homesecurity-adt-securitypanel-frozen.svg',
    'homesecurity-adt-securitypanel.svg': 'homesecurity-adt-securitypanel.svg',
    'homesecurity-alula-batconnect-frozen.svg':
      'homesecurity-alula-batconnect-frozen.svg',
    'homesecurity-alula-batconnect.svg': 'homesecurity-alula-batconnect.svg',
    'homesecurity-amcrest-dvr-frozen.svg':
      'homesecurity-amcrest-dvr-frozen.svg',
    'homesecurity-amcrest-dvr.svg': 'homesecurity-amcrest-dvr.svg',
    'homesecurity-amcrest-nvr-frozen.svg':
      'homesecurity-amcrest-nvr-frozen.svg',
    'homesecurity-amcrest-nvr.svg': 'homesecurity-amcrest-nvr.svg',
    'homesecurity-bosch-divar-frozen.svg':
      'homesecurity-bosch-divar-frozen.svg',
    'homesecurity-bosch-divar.svg': 'homesecurity-bosch-divar.svg',
    'homesecurity-brinks-homesecurity-frozen.svg':
      'homesecurity-brinks-homesecurity-frozen.svg',
    'homesecurity-brinks-homesecurity.svg':
      'homesecurity-brinks-homesecurity.svg',
    'homesecurity-defender-guard-frozen.svg':
      'homesecurity-defender-guard-frozen.svg',
    'homesecurity-defender-guard.svg': 'homesecurity-defender-guard.svg',
    'homesecurity-dsc-iotega-frozen.svg': 'homesecurity-dsc-iotega-frozen.svg',
    'homesecurity-dsc-iotega.svg': 'homesecurity-dsc-iotega.svg',
    'homesecurity-eyezon-envizalink-frozen.svg':
      'homesecurity-eyezon-envizalink-frozen.svg',
    'homesecurity-eyezon-envizalink.svg': 'homesecurity-eyezon-envizalink.svg',
    'homesecurity-frozen.svg': 'homesecurity-frozen.svg',
    'homesecurity-home8-homesecurity-frozen.svg':
      'homesecurity-home8-homesecurity-frozen.svg',
    'homesecurity-home8-homesecurity.svg':
      'homesecurity-home8-homesecurity.svg',
    'homesecurity-honeywell-alarmpanel-frozen.svg':
      'homesecurity-honeywell-alarmpanel-frozen.svg',
    'homesecurity-honeywell-alarmpanel.svg':
      'homesecurity-honeywell-alarmpanel.svg',
    'homesecurity-honeywell-frozen.svg': 'homesecurity-honeywell-frozen.svg',
    'homesecurity-honeywell-tuxedotouchalarmpanel-frozen.svg':
      'homesecurity-honeywell-tuxedotouchalarmpanel-frozen.svg',
    'homesecurity-honeywell-tuxedotouchalarmpanel.svg':
      'homesecurity-honeywell-tuxedotouchalarmpanel.svg',
    'homesecurity-honeywell.svg': 'homesecurity-honeywell.svg',
    'homesecurity-honeywelllyric-frozen.svg':
      'homesecurity-honeywelllyric-frozen.svg',
    'homesecurity-honeywelllyric.svg': 'homesecurity-honeywelllyric.svg',
    'homesecurity-hub6-safe-frozen.svg': 'homesecurity-hub6-safe-frozen.svg',
    'homesecurity-hub6-safe.svg': 'homesecurity-hub6-safe.svg',
    'homesecurity-ingrid-homesecurity-frozen.svg':
      'homesecurity-ingrid-homesecurity-frozen.svg',
    'homesecurity-ingrid-homesecurity.svg':
      'homesecurity-ingrid-homesecurity.svg',
    'homesecurity-interlogix-zerowire-frozen.svg':
      'homesecurity-interlogix-zerowire-frozen.svg',
    'homesecurity-interlogix-zerowire.svg':
      'homesecurity-interlogix-zerowire.svg',
    'homesecurity-ismart-smartsecurity-frozen.svg':
      'homesecurity-ismart-smartsecurity-frozen.svg',
    'homesecurity-ismart-smartsecurity.svg':
      'homesecurity-ismart-smartsecurity.svg',
    'homesecurity-konnected-nodemcuwifimodule-frozen.svg':
      'homesecurity-konnected-nodemcuwifimodule-frozen.svg',
    'homesecurity-konnected-nodemcuwifimodule.svg':
      'homesecurity-konnected-nodemcuwifimodule.svg',
    'homesecurity-konnectedsecurity-frozen.svg':
      'homesecurity-konnectedsecurity-frozen.svg',
    'homesecurity-konnectedsecurity.svg': 'homesecurity-konnectedsecurity.svg',
    'homesecurity-lorex-4kdvr-frozen.svg':
      'homesecurity-lorex-4kdvr-frozen.svg',
    'homesecurity-lorex-4kdvr.svg': 'homesecurity-lorex-4kdvr.svg',
    'homesecurity-lorex-4knvr-frozen.svg':
      'homesecurity-lorex-4knvr-frozen.svg',
    'homesecurity-lorex-4knvr.svg': 'homesecurity-lorex-4knvr.svg',
    'homesecurity-lorex-dv916-frozen.svg':
      'homesecurity-lorex-dv916-frozen.svg',
    'homesecurity-lorex-dv916.svg': 'homesecurity-lorex-dv916.svg',
    'homesecurity-lorex-dvr-frozen.svg': 'homesecurity-lorex-dvr-frozen.svg',
    'homesecurity-lorex-dvr.svg': 'homesecurity-lorex-dvr.svg',
    'homesecurity-lorex-ipcamera-frozen.svg':
      'homesecurity-lorex-ipcamera-frozen.svg',
    'homesecurity-lorex-ipcamera.svg': 'homesecurity-lorex-ipcamera.svg',
    'homesecurity-lorex-lvh5100series-frozen.svg':
      'homesecurity-lorex-lvh5100series-frozen.svg',
    'homesecurity-lorex-lvh5100series.svg':
      'homesecurity-lorex-lvh5100series.svg',
    'homesecurity-lorex-nvr-frozen.svg': 'homesecurity-lorex-nvr-frozen.svg',
    'homesecurity-lorex-nvr.svg': 'homesecurity-lorex-nvr.svg',
    'homesecurity-minut-point-frozen.svg':
      'homesecurity-minut-point-frozen.svg',
    'homesecurity-minut-point.svg': 'homesecurity-minut-point.svg',
    'homesecurity-nestguard-frozen.svg': 'homesecurity-nestguard-frozen.svg',
    'homesecurity-nestguard.svg': 'homesecurity-nestguard.svg',
    'homesecurity-netatmo-welcome-frozen.svg':
      'homesecurity-netatmo-welcome-frozen.svg',
    'homesecurity-netatmo-welcome.svg': 'homesecurity-netatmo-welcome.svg',
    'homesecurity-qlosys-iqpanel-frozen.svg':
      'homesecurity-qlosys-iqpanel-frozen.svg',
    'homesecurity-qlosys-iqpanel.svg': 'homesecurity-qlosys-iqpanel.svg',
    'homesecurity-qlosys-iqpanel2-frozen.svg':
      'homesecurity-qlosys-iqpanel2-frozen.svg',
    'homesecurity-qlosys-iqpanel2.svg': 'homesecurity-qlosys-iqpanel2.svg',
    'homesecurity-ringalarmhomesecuritysystem-frozen.svg':
      'homesecurity-ringalarmhomesecuritysystem-frozen.svg',
    'homesecurity-ringalarmhomesecuritysystem.svg':
      'homesecurity-ringalarmhomesecuritysystem.svg',
    'homesecurity-somfyprotect-frozen.svg':
      'homesecurity-somfyprotect-frozen.svg',
    'homesecurity-somfyprotect.svg': 'homesecurity-somfyprotect.svg',
    'homesecurity-technicolor-tablet-frozen.svg':
      'homesecurity-technicolor-tablet-frozen.svg',
    'homesecurity-technicolor-tablet.svg':
      'homesecurity-technicolor-tablet.svg',
    'homesecurity-texe-homesecurity-frozen.svg':
      'homesecurity-texe-homesecurity-frozen.svg',
    'homesecurity-texe-homesecurity.svg': 'homesecurity-texe-homesecurity.svg',
    'homesecurity.svg': 'homesecurity.svg',
    'hub-adt-securityhub-frozen.svg': 'hub-adt-securityhub-frozen.svg',
    'hub-adt-securityhub.svg': 'hub-adt-securityhub.svg',
    'hub-amazonring-bridge-frozen.svg': 'hub-amazonring-bridge-frozen.svg',
    'hub-amazonring-bridge.svg': 'hub-amazonring-bridge.svg',
    'hub-aqara-frozen.svg': 'hub-aqara-frozen.svg',
    'hub-aqara.svg': 'hub-aqara.svg',
    'hub-aquascape-smartcontrolhub-frozen.svg':
      'hub-aquascape-smartcontrolhub-frozen.svg',
    'hub-aquascape-smartcontrolhub.svg': 'hub-aquascape-smartcontrolhub.svg',
    'hub-arlo-basestation-frozen.svg': 'hub-arlo-basestation-frozen.svg',
    'hub-arlo-basestation.svg': 'hub-arlo-basestation.svg',
    'hub-arlo-bridge-frozen.svg': 'hub-arlo-bridge-frozen.svg',
    'hub-arlo-bridge.svg': 'hub-arlo-bridge.svg',
    'hub-august-connect-frozen.svg': 'hub-august-connect-frozen.svg',
    'hub-august-connect.svg': 'hub-august-connect.svg',
    'hub-belkin-wemo-frozen.svg': 'hub-belkin-wemo-frozen.svg',
    'hub-belkin-wemo.svg': 'hub-belkin-wemo.svg',
    'hub-blink-syncmodule-frozen.svg': 'hub-blink-syncmodule-frozen.svg',
    'hub-blink-syncmodule.svg': 'hub-blink-syncmodule.svg',
    'hub-bond-bridge-frozen.svg': 'hub-bond-bridge-frozen.svg',
    'hub-bond-bridge.svg': 'hub-bond-bridge.svg',
    'hub-brilliant-smarthomecontrolpanel-frozen.svg':
      'hub-brilliant-smarthomecontrolpanel-frozen.svg',
    'hub-brilliant-smarthomecontrolpanel.svg':
      'hub-brilliant-smarthomecontrolpanel.svg',
    'hub-broadlink-rm-frozen.svg': 'hub-broadlink-rm-frozen.svg',
    'hub-broadlink-rm.svg': 'hub-broadlink-rm.svg',
    'hub-broadlink-rm2-frozen.svg': 'hub-broadlink-rm2-frozen.svg',
    'hub-broadlink-rm2.svg': 'hub-broadlink-rm2.svg',
    'hub-broadlink-rm4mini-frozen.svg': 'hub-broadlink-rm4mini-frozen.svg',
    'hub-broadlink-rm4mini.svg': 'hub-broadlink-rm4mini.svg',
    'hub-broadlink-rm4pro-frozen.svg': 'hub-broadlink-rm4pro-frozen.svg',
    'hub-broadlink-rm4pro.svg': 'hub-broadlink-rm4pro.svg',
    'hub-broadlink-rmhome+-frozen.svg': 'hub-broadlink-rmhome+-frozen.svg',
    'hub-broadlink-rmhome+.svg': 'hub-broadlink-rmhome+.svg',
    'hub-broadlink-rmmini3-frozen.svg': 'hub-broadlink-rmmini3-frozen.svg',
    'hub-broadlink-rmmini3.svg': 'hub-broadlink-rmmini3.svg',
    'hub-broadlink-rmpro+-frozen.svg': 'hub-broadlink-rmpro+-frozen.svg',
    'hub-broadlink-rmpro+.svg': 'hub-broadlink-rmpro+.svg',
    'hub-broadlink-rmpro-frozen.svg': 'hub-broadlink-rmpro-frozen.svg',
    'hub-broadlink-rmpro.svg': 'hub-broadlink-rmpro.svg',
    'hub-bt-minihub-frozen.svg': 'hub-bt-minihub-frozen.svg',
    'hub-bt-minihub.svg': 'hub-bt-minihub.svg',
    'hub-camect-home-frozen.svg': 'hub-camect-home-frozen.svg',
    'hub-camect-home.svg': 'hub-camect-home.svg',
    'hub-cbyge-creachsmartbridge-frozen.svg':
      'hub-cbyge-creachsmartbridge-frozen.svg',
    'hub-cbyge-creachsmartbridge.svg': 'hub-cbyge-creachsmartbridge.svg',
    'hub-control4-ca1-frozen.svg': 'hub-control4-ca1-frozen.svg',
    'hub-control4-ca1.svg': 'hub-control4-ca1.svg',
    'hub-control4-ea1-frozen.svg': 'hub-control4-ea1-frozen.svg',
    'hub-control4-ea1.svg': 'hub-control4-ea1.svg',
    'hub-control4-ea3-frozen.svg': 'hub-control4-ea3-frozen.svg',
    'hub-control4-ea3.svg': 'hub-control4-ea3.svg',
    'hub-control4-ea5-frozen.svg': 'hub-control4-ea5-frozen.svg',
    'hub-control4-ea5.svg': 'hub-control4-ea5.svg',
    'hub-control4-hc800-frozen.svg': 'hub-control4-hc800-frozen.svg',
    'hub-control4-hc800.svg': 'hub-control4-hc800.svg',
    'hub-control4-homecontroller-frozen.svg':
      'hub-control4-homecontroller-frozen.svg',
    'hub-control4-homecontroller.svg': 'hub-control4-homecontroller.svg',
    'hub-control4-ioextender-frozen.svg': 'hub-control4-ioextender-frozen.svg',
    'hub-control4-ioextender.svg': 'hub-control4-ioextender.svg',
    'hub-develco-gateway-frozen.svg': 'hub-develco-gateway-frozen.svg',
    'hub-develco-gateway.svg': 'hub-develco-gateway.svg',
    'hub-ezviz-w2d-frozen.svg': 'hub-ezviz-w2d-frozen.svg',
    'hub-ezviz-w2d.svg': 'hub-ezviz-w2d.svg',
    'hub-heatmiser-neohub-frozen.svg': 'hub-heatmiser-neohub-frozen.svg',
    'hub-heatmiser-neohub.svg': 'hub-heatmiser-neohub.svg',
    'hub-hive-frozen.svg': 'hub-hive-frozen.svg',
    'hub-hive.svg': 'hub-hive.svg',
    'hub-homey-frozen.svg': 'hub-homey-frozen.svg',
    'hub-homey.svg': 'hub-homey.svg',
    'hub-hoobs-hub-frozen.svg': 'hub-hoobs-hub-frozen.svg',
    'hub-hoobs-hub.svg': 'hub-hoobs-hub.svg',
    'hub-hubitat-elevation-frozen.svg': 'hub-hubitat-elevation-frozen.svg',
    'hub-hubitat-elevation.svg': 'hub-hubitat-elevation.svg',
    'hub-hunterdouglas-powerviewhub-frozen.svg':
      'hub-hunterdouglas-powerviewhub-frozen.svg',
    'hub-hunterdouglas-powerviewhub.svg': 'hub-hunterdouglas-powerviewhub.svg',
    'hub-ikea-tradfrigateway-frozen.svg': 'hub-ikea-tradfrigateway-frozen.svg',
    'hub-ikea-tradfrigateway.svg': 'hub-ikea-tradfrigateway.svg',
    'hub-kangaroo-siren-frozen.svg': 'hub-kangaroo-siren-frozen.svg',
    'hub-kangaroo-siren.svg': 'hub-kangaroo-siren.svg',
    'hub-leedarson-minihub-frozen.svg': 'hub-leedarson-minihub-frozen.svg',
    'hub-leedarson-minihub.svg': 'hub-leedarson-minihub.svg',
    'hub-lightwaverf-linkplus-frozen.svg':
      'hub-lightwaverf-linkplus-frozen.svg',
    'hub-lightwaverf-linkplus.svg': 'hub-lightwaverf-linkplus.svg',
    'hub-lockly-securelink-frozen.svg': 'hub-lockly-securelink-frozen.svg',
    'hub-lockly-securelink.svg': 'hub-lockly-securelink.svg',
    'hub-logitech-harmony-frozen.svg': 'hub-logitech-harmony-frozen.svg',
    'hub-logitech-harmony.svg': 'hub-logitech-harmony.svg',
    'hub-logitech-pop-frozen.svg': 'hub-logitech-pop-frozen.svg',
    'hub-logitech-pop.svg': 'hub-logitech-pop.svg',
    'hub-logitech-squeezeboxcontroller-frozen.svg':
      'hub-logitech-squeezeboxcontroller-frozen.svg',
    'hub-logitech-squeezeboxcontroller.svg':
      'hub-logitech-squeezeboxcontroller.svg',
    'hub-magichue-frozen.svg': 'hub-magichue-frozen.svg',
    'hub-magichue.svg': 'hub-magichue.svg',
    'hub-meross-smarthub-frozen.svg': 'hub-meross-smarthub-frozen.svg',
    'hub-meross-smarthub.svg': 'hub-meross-smarthub.svg',
    'hub-napco-ibridge-frozen.svg': 'hub-napco-ibridge-frozen.svg',
    'hub-napco-ibridge.svg': 'hub-napco-ibridge.svg',
    'hub-nature-remo-frozen.svg': 'hub-nature-remo-frozen.svg',
    'hub-nature-remo.svg': 'hub-nature-remo.svg',
    'hub-netatmo-relay-frozen.svg': 'hub-netatmo-relay-frozen.svg',
    'hub-netatmo-relay.svg': 'hub-netatmo-relay.svg',
    'hub-notion-bridge-frozen.svg': 'hub-notion-bridge-frozen.svg',
    'hub-notion-bridge.svg': 'hub-notion-bridge.svg',
    'hub-nuki-bridge-frozen.svg': 'hub-nuki-bridge-frozen.svg',
    'hub-nuki-bridge.svg': 'hub-nuki-bridge.svg',
    'hub-overkiz-gateway-frozen.svg': 'hub-overkiz-gateway-frozen.svg',
    'hub-overkiz-gateway.svg': 'hub-overkiz-gateway.svg',
    'hub-panasonic-frozen.svg': 'hub-panasonic-frozen.svg',
    'hub-panasonic.svg': 'hub-panasonic.svg',
    'hub-philips-frozen.svg': 'hub-philips-frozen.svg',
    'hub-philips-hue-frozen.svg': 'hub-philips-hue-frozen.svg',
    'hub-philips-hue.svg': 'hub-philips-hue.svg',
    'hub-philips.svg': 'hub-philips.svg',
    'hub-qivicon-homebase-frozen.svg': 'hub-qivicon-homebase-frozen.svg',
    'hub-qivicon-homebase.svg': 'hub-qivicon-homebase.svg',
    'hub-samsung-smartthings-hub-1.svg': 'hub-samsung-smartthings-hub-1.svg',
    'hub-samsung-smartthings-hub-frozen-1.svg':
      'hub-samsung-smartthings-hub-frozen-1.svg',
    'hub-samsung-smartthings-hub-frozen.svg':
      'hub-samsung-smartthings-hub-frozen.svg',
    'hub-samsung-smartthings-hub.svg': 'hub-samsung-smartthings-hub.svg',
    'hub-samsung-smartthingsconnecthome-frozen.svg':
      'hub-samsung-smartthingsconnecthome-frozen.svg',
    'hub-samsung-smartthingsconnecthome.svg':
      'hub-samsung-smartthingsconnecthome.svg',
    'hub-samsung-smartthingshub-frozen.svg':
      'hub-samsung-smartthingshub-frozen.svg',
    'hub-samsung-smartthingshub.svg': 'hub-samsung-smartthingshub.svg',
    'hub-sangoma-vega-frozen.svg': 'hub-sangoma-vega-frozen.svg',
    'hub-sangoma-vega.svg': 'hub-sangoma-vega.svg',
    'hub-sonos-bridge-frozen.svg': 'hub-sonos-bridge-frozen.svg',
    'hub-sonos-bridge.svg': 'hub-sonos-bridge.svg',
    'hub-tado-bridge-frozen.svg': 'hub-tado-bridge-frozen.svg',
    'hub-tado-bridge.svg': 'hub-tado-bridge.svg',
    'hub-thermoworks-smokegateway-frozen.svg':
      'hub-thermoworks-smokegateway-frozen.svg',
    'hub-thermoworks-smokegateway.svg': 'hub-thermoworks-smokegateway.svg',
    'hub-tplink-omadacloudcontroller-frozen.svg':
      'hub-tplink-omadacloudcontroller-frozen.svg',
    'hub-tplink-omadacloudcontroller.svg':
      'hub-tplink-omadacloudcontroller.svg',
    'hub-urc-mxhomepro-frozen.svg': 'hub-urc-mxhomepro-frozen.svg',
    'hub-urc-mxhomepro.svg': 'hub-urc-mxhomepro.svg',
    'hub-velux-active-frozen.svg': 'hub-velux-active-frozen.svg',
    'hub-velux-active.svg': 'hub-velux-active.svg',
    'hub-vivintsmarthome-frozen.svg': 'hub-vivintsmarthome-frozen.svg',
    'hub-vivintsmarthome.svg': 'hub-vivintsmarthome.svg',
    'hub-wink-relay-frozen.svg': 'hub-wink-relay-frozen.svg',
    'hub-wink-relay.svg': 'hub-wink-relay.svg',
    'hub-winkhub-frozen.svg': 'hub-winkhub-frozen.svg',
    'hub-winkhub.svg': 'hub-winkhub.svg',
    'hub-xiaomi-mijiagateway-frozen.svg': 'hub-xiaomi-mijiagateway-frozen.svg',
    'hub-xiaomi-mijiagateway.svg': 'hub-xiaomi-mijiagateway.svg',
    'humidifier-dyson-frozen.svg': 'humidifier-dyson-frozen.svg',
    'humidifier-dyson.svg': 'humidifier-dyson.svg',
    'humidifier-frozen.svg': 'humidifier-frozen.svg',
    'humidifier-hisense-dehumidifier-frozen.svg':
      'humidifier-hisense-dehumidifier-frozen.svg',
    'humidifier-hisense-dehumidifier.svg':
      'humidifier-hisense-dehumidifier.svg',
    'humidifier-levoit-humidifier-frozen.svg':
      'humidifier-levoit-humidifier-frozen.svg',
    'humidifier-levoit-humidifier.svg': 'humidifier-levoit-humidifier.svg',
    'humidifier-meross-smarthumidifier-frozen.svg':
      'humidifier-meross-smarthumidifier-frozen.svg',
    'humidifier-meross-smarthumidifier.svg':
      'humidifier-meross-smarthumidifier.svg',
    'humidifier-vocolinc-mistflow-frozen.svg':
      'humidifier-vocolinc-mistflow-frozen.svg',
    'humidifier-vocolinc-mistflow.svg': 'humidifier-vocolinc-mistflow.svg',
    'humidifier.svg': 'humidifier.svg',
    'iotplatform-frozen.svg': 'iotplatform-frozen.svg',
    'iotplatform.svg': 'iotplatform.svg',
    'laptop-acer-aspire-frozen.svg': 'laptop-acer-aspire-frozen.svg',
    'laptop-acer-aspire.svg': 'laptop-acer-aspire.svg',
    'laptop-acer-chromebook-frozen.svg': 'laptop-acer-chromebook-frozen.svg',
    'laptop-acer-chromebook.svg': 'laptop-acer-chromebook.svg',
    'laptop-acer-computer-frozen.svg': 'laptop-acer-computer-frozen.svg',
    'laptop-acer-computer.svg': 'laptop-acer-computer.svg',
    'laptop-acer-predator-frozen.svg': 'laptop-acer-predator-frozen.svg',
    'laptop-acer-predator.svg': 'laptop-acer-predator.svg',
    'laptop-acer-swift-frozen.svg': 'laptop-acer-swift-frozen.svg',
    'laptop-acer-swift.svg': 'laptop-acer-swift.svg',
    'laptop-apple-macbook-frozen.svg': 'laptop-apple-macbook-frozen.svg',
    'laptop-apple-macbook.svg': 'laptop-apple-macbook.svg',
    'laptop-asus-chromebook-frozen.svg': 'laptop-asus-chromebook-frozen.svg',
    'laptop-asus-chromebook.svg': 'laptop-asus-chromebook.svg',
    'laptop-asus-rog-frozen.svg': 'laptop-asus-rog-frozen.svg',
    'laptop-asus-rog.svg': 'laptop-asus-rog.svg',
    'laptop-asus-windows-frozen.svg': 'laptop-asus-windows-frozen.svg',
    'laptop-asus-windows.svg': 'laptop-asus-windows.svg',
    'laptop-asus-x75a-frozen.svg': 'laptop-asus-x75a-frozen.svg',
    'laptop-asus-x75a.svg': 'laptop-asus-x75a.svg',
    'laptop-asus-zenbook-frozen.svg': 'laptop-asus-zenbook-frozen.svg',
    'laptop-asus-zenbook.svg': 'laptop-asus-zenbook.svg',
    'laptop-chromeos-frozen.svg': 'laptop-chromeos-frozen.svg',
    'laptop-chromeos.svg': 'laptop-chromeos.svg',
    'laptop-computer.svg': 'laptop-computer.svg',
    'laptop-dell-alienware-frozen.svg': 'laptop-dell-alienware-frozen.svg',
    'laptop-dell-alienware.svg': 'laptop-dell-alienware.svg',
    'laptop-dell-chromebook-frozen.svg': 'laptop-dell-chromebook-frozen.svg',
    'laptop-dell-chromebook.svg': 'laptop-dell-chromebook.svg',
    'laptop-dell-frozen.svg': 'laptop-dell-frozen.svg',
    'laptop-dell-inspiron-frozen.svg': 'laptop-dell-inspiron-frozen.svg',
    'laptop-dell-inspiron.svg': 'laptop-dell-inspiron.svg',
    'laptop-dell-latitude-frozen.svg': 'laptop-dell-latitude-frozen.svg',
    'laptop-dell-latitude.svg': 'laptop-dell-latitude.svg',
    'laptop-dell-windows-frozen.svg': 'laptop-dell-windows-frozen.svg',
    'laptop-dell-windows.svg': 'laptop-dell-windows.svg',
    'laptop-dell-xps-frozen.svg': 'laptop-dell-xps-frozen.svg',
    'laptop-dell-xps.svg': 'laptop-dell-xps.svg',
    'laptop-dell.svg': 'laptop-dell.svg',
    'laptop-ematic-laptop-frozen.svg': 'laptop-ematic-laptop-frozen.svg',
    'laptop-ematic-laptop.svg': 'laptop-ematic-laptop.svg',
    'laptop-evoo-laptop-frozen.svg': 'laptop-evoo-laptop-frozen.svg',
    'laptop-evoo-laptop.svg': 'laptop-evoo-laptop.svg',
    'laptop-frozen.svg': 'laptop-frozen.svg',
    'laptop-gateway-ne71b-frozen.svg': 'laptop-gateway-ne71b-frozen.svg',
    'laptop-gateway-ne71b.svg': 'laptop-gateway-ne71b.svg',
    'laptop-google-chromebook-frozen.svg':
      'laptop-google-chromebook-frozen.svg',
    'laptop-google-chromebook.svg': 'laptop-google-chromebook.svg',
    'laptop-google-pixelbook-frozen.svg': 'laptop-google-pixelbook-frozen.svg',
    'laptop-google-pixelbook.svg': 'laptop-google-pixelbook.svg',
    'laptop-hp-chromebook-frozen.svg': 'laptop-hp-chromebook-frozen.svg',
    'laptop-hp-chromebook.svg': 'laptop-hp-chromebook.svg',
    'laptop-hp-computer-frozen.svg': 'laptop-hp-computer-frozen.svg',
    'laptop-hp-computer.svg': 'laptop-hp-computer.svg',
    'laptop-hp-envy-frozen.svg': 'laptop-hp-envy-frozen.svg',
    'laptop-hp-envy.svg': 'laptop-hp-envy.svg',
    'laptop-hp-frozen.svg': 'laptop-hp-frozen.svg',
    'laptop-hp-notebook-frozen.svg': 'laptop-hp-notebook-frozen.svg',
    'laptop-hp-notebook.svg': 'laptop-hp-notebook.svg',
    'laptop-hp.svg': 'laptop-hp.svg',
    'laptop.svg': 'laptop.svg',
    'lawnmower-frozen.svg': 'lawnmower-frozen.svg',
    'lawnmower.svg': 'lawnmower.svg',
    'lightbulb-ai-hydra-frozen.svg': 'lightbulb-ai-hydra-frozen.svg',
    'lightbulb-ai-hydra.svg': 'lightbulb-ai-hydra.svg',
    'lightbulb-ai-primehd-frozen.svg': 'lightbulb-ai-primehd-frozen.svg',
    'lightbulb-ai-primehd.svg': 'lightbulb-ai-primehd.svg',
    'lightbulb-cololight-smartledlightpanel-frozen.svg':
      'lightbulb-cololight-smartledlightpanel-frozen.svg',
    'lightbulb-cololight-smartledlightpanel.svg':
      'lightbulb-cololight-smartledlightpanel.svg',
    'lightbulb-corsair-icue-frozen.svg': 'lightbulb-corsair-icue-frozen.svg',
    'lightbulb-corsair-icue.svg': 'lightbulb-corsair-icue.svg',
    'lightbulb-dyson-lightcycle-frozen.svg':
      'lightbulb-dyson-lightcycle-frozen.svg',
    'lightbulb-dyson-lightcycle.svg': 'lightbulb-dyson-lightcycle.svg',
    'lightbulb-elgato-keylight-frozen.svg':
      'lightbulb-elgato-keylight-frozen.svg',
    'lightbulb-elgato-keylight.svg': 'lightbulb-elgato-keylight.svg',
    'lightbulb-etekcity-lightbulb-frozen.svg':
      'lightbulb-etekcity-lightbulb-frozen.svg',
    'lightbulb-etekcity-lightbulb.svg': 'lightbulb-etekcity-lightbulb.svg',
    'lightbulb-eve-lightstrip-frozen.svg':
      'lightbulb-eve-lightstrip-frozen.svg',
    'lightbulb-eve-lightstrip.svg': 'lightbulb-eve-lightstrip.svg',
    'lightbulb-everlights-lightbulb-frozen.svg':
      'lightbulb-everlights-lightbulb-frozen.svg',
    'lightbulb-everlights-lightbulb.svg': 'lightbulb-everlights-lightbulb.svg',
    'lightbulb-feit-lightbulb-frozen.svg':
      'lightbulb-feit-lightbulb-frozen.svg',
    'lightbulb-feit-lightbulb.svg': 'lightbulb-feit-lightbulb.svg',
    'lightbulb-friendship-lamp-frozen.svg':
      'lightbulb-friendship-lamp-frozen.svg',
    'lightbulb-friendship-lamp.svg': 'lightbulb-friendship-lamp.svg',
    'lightbulb-frozen.svg': 'lightbulb-frozen.svg',
    'lightbulb-ge-lightbulb-frozen.svg': 'lightbulb-ge-lightbulb-frozen.svg',
    'lightbulb-ge-lightbulb.svg': 'lightbulb-ge-lightbulb.svg',
    'lightbulb-greenwavereality-lightbulb-frozen.svg':
      'lightbulb-greenwavereality-lightbulb-frozen.svg',
    'lightbulb-greenwavereality-lightbulb.svg':
      'lightbulb-greenwavereality-lightbulb.svg',
    'lightbulb-juno-ai-frozen.svg': 'lightbulb-juno-ai-frozen.svg',
    'lightbulb-juno-ai.svg': 'lightbulb-juno-ai.svg',
    'lightbulb-koogeek-frozen.svg': 'lightbulb-koogeek-frozen.svg',
    'lightbulb-koogeek-lightbulb-frozen.svg':
      'lightbulb-koogeek-lightbulb-frozen.svg',
    'lightbulb-koogeek-lightbulb.svg': 'lightbulb-koogeek-lightbulb.svg',
    'lightbulb-koogeek-lightstrip-frozen.svg':
      'lightbulb-koogeek-lightstrip-frozen.svg',
    'lightbulb-koogeek-lightstrip.svg': 'lightbulb-koogeek-lightstrip.svg',
    'lightbulb-koogeek.svg': 'lightbulb-koogeek.svg',
    'lightbulb-lifx-1.svg': 'lightbulb-lifx-1.svg',
    'lightbulb-lifx-colorlightbulb-frozen.svg':
      'lightbulb-lifx-colorlightbulb-frozen.svg',
    'lightbulb-lifx-colorlightbulb.svg': 'lightbulb-lifx-colorlightbulb.svg',
    'lightbulb-lifx-downlight-frozen.svg':
      'lightbulb-lifx-downlight-frozen.svg',
    'lightbulb-lifx-downlight.svg': 'lightbulb-lifx-downlight.svg',
    'lightbulb-lifx-frozen-1.svg': 'lightbulb-lifx-frozen-1.svg',
    'lightbulb-lifx-frozen.svg': 'lightbulb-lifx-frozen.svg',
    'lightbulb-lifx-tile-frozen.svg': 'lightbulb-lifx-tile-frozen.svg',
    'lightbulb-lifx-tile.svg': 'lightbulb-lifx-tile.svg',
    'lightbulb-lifx.svg': 'lightbulb-lifx.svg',
    'lightbulb-lohas-smartledbulb-frozen.svg':
      'lightbulb-lohas-smartledbulb-frozen.svg',
    'lightbulb-lohas-smartledbulb.svg': 'lightbulb-lohas-smartledbulb.svg',
    'lightbulb-magichue-light-frozen.svg':
      'lightbulb-magichue-light-frozen.svg',
    'lightbulb-magichue-light.svg': 'lightbulb-magichue-light.svg',
    'lightbulb-merkury-smartbulb-frozen.svg':
      'lightbulb-merkury-smartbulb-frozen.svg',
    'lightbulb-merkury-smartbulb.svg': 'lightbulb-merkury-smartbulb.svg',
    'lightbulb-meross-smartambientlight-frozen.svg':
      'lightbulb-meross-smartambientlight-frozen.svg',
    'lightbulb-meross-smartambientlight.svg':
      'lightbulb-meross-smartambientlight.svg',
    'lightbulb-meross-smartlightstrip-frozen.svg':
      'lightbulb-meross-smartlightstrip-frozen.svg',
    'lightbulb-meross-smartlightstrip.svg':
      'lightbulb-meross-smartlightstrip.svg',
    'lightbulb-meross-smartwifibulb-frozen.svg':
      'lightbulb-meross-smartwifibulb-frozen.svg',
    'lightbulb-meross-smartwifibulb.svg': 'lightbulb-meross-smartwifibulb.svg',
    'lightbulb-mirabellagenio-dimmable-frozen.svg':
      'lightbulb-mirabellagenio-dimmable-frozen.svg',
    'lightbulb-mirabellagenio-dimmable.svg':
      'lightbulb-mirabellagenio-dimmable.svg',
    'lightbulb-mystrom-wifibulb-frozen.svg':
      'lightbulb-mystrom-wifibulb-frozen.svg',
    'lightbulb-mystrom-wifibulb.svg': 'lightbulb-mystrom-wifibulb.svg',
    'lightbulb-mystrom-wifiledstrip-frozen.svg':
      'lightbulb-mystrom-wifiledstrip-frozen.svg',
    'lightbulb-mystrom-wifiledstrip.svg': 'lightbulb-mystrom-wifiledstrip.svg',
    'lightbulb-nanoleaf-canvas-frozen.svg':
      'lightbulb-nanoleaf-canvas-frozen.svg',
    'lightbulb-nanoleaf-canvas.svg': 'lightbulb-nanoleaf-canvas.svg',
    'lightbulb-nanoleaf-lightpanels-frozen.svg':
      'lightbulb-nanoleaf-lightpanels-frozen.svg',
    'lightbulb-nanoleaf-lightpanels.svg': 'lightbulb-nanoleaf-lightpanels.svg',
    'lightbulb-philips-hue-frozen.svg': 'lightbulb-philips-hue-frozen.svg',
    'lightbulb-philips-hue.svg': 'lightbulb-philips-hue.svg',
    'lightbulb-philips-smartsleep-frozen.svg':
      'lightbulb-philips-smartsleep-frozen.svg',
    'lightbulb-philips-smartsleep.svg': 'lightbulb-philips-smartsleep.svg',
    'lightbulb-redsea-reefled-frozen.svg':
      'lightbulb-redsea-reefled-frozen.svg',
    'lightbulb-redsea-reefled.svg': 'lightbulb-redsea-reefled.svg',
    'lightbulb-samsung-smartthingsdesklamp-frozen.svg':
      'lightbulb-samsung-smartthingsdesklamp-frozen.svg',
    'lightbulb-samsung-smartthingsdesklamp.svg':
      'lightbulb-samsung-smartthingsdesklamp.svg',
    'lightbulb-sengled-smartwifiledbulb-frozen.svg':
      'lightbulb-sengled-smartwifiledbulb-frozen.svg',
    'lightbulb-sengled-smartwifiledbulb.svg':
      'lightbulb-sengled-smartwifiledbulb.svg',
    'lightbulb-sengled-wifiledlightstrip-frozen.svg':
      'lightbulb-sengled-wifiledlightstrip-frozen.svg',
    'lightbulb-sengled-wifiledlightstrip.svg':
      'lightbulb-sengled-wifiledlightstrip.svg',
    'lightbulb-shelly-bulb-frozen.svg': 'lightbulb-shelly-bulb-frozen.svg',
    'lightbulb-shelly-bulb.svg': 'lightbulb-shelly-bulb.svg',
    'lightbulb-shelly-duo-frozen.svg': 'lightbulb-shelly-duo-frozen.svg',
    'lightbulb-shelly-duo.svg': 'lightbulb-shelly-duo.svg',
    'lightbulb-shelly-vintage-frozen.svg':
      'lightbulb-shelly-vintage-frozen.svg',
    'lightbulb-shelly-vintage.svg': 'lightbulb-shelly-vintage.svg',
    'lightbulb-sony-multifunctionallight-frozen.svg':
      'lightbulb-sony-multifunctionallight-frozen.svg',
    'lightbulb-sony-multifunctionallight.svg':
      'lightbulb-sony-multifunctionallight.svg',
    'lightbulb-teckin-smartbulb-frozen.svg':
      'lightbulb-teckin-smartbulb-frozen.svg',
    'lightbulb-teckin-smartbulb.svg': 'lightbulb-teckin-smartbulb.svg',
    'lightbulb-tplink-kasawifiledbulb-frozen.svg':
      'lightbulb-tplink-kasawifiledbulb-frozen.svg',
    'lightbulb-tplink-kasawifiledbulb.svg':
      'lightbulb-tplink-kasawifiledbulb.svg',
    'lightbulb-tplink-tapo-frozen.svg': 'lightbulb-tplink-tapo-frozen.svg',
    'lightbulb-tplink-tapo.svg': 'lightbulb-tplink-tapo.svg',
    'lightbulb-tplinkcasalightbulb-frozen.svg':
      'lightbulb-tplinkcasalightbulb-frozen.svg',
    'lightbulb-tplinkcasalightbulb.svg': 'lightbulb-tplinkcasalightbulb.svg',
    'lightbulb-tuya-smartledbulb-frozen.svg':
      'lightbulb-tuya-smartledbulb-frozen.svg',
    'lightbulb-tuya-smartledbulb.svg': 'lightbulb-tuya-smartledbulb.svg',
    'lightbulb-twinkly-ledlightstring-frozen.svg':
      'lightbulb-twinkly-ledlightstring-frozen.svg',
    'lightbulb-twinkly-ledlightstring.svg':
      'lightbulb-twinkly-ledlightstring.svg',
    'lightbulb-vocolinc-frozen.svg': 'lightbulb-vocolinc-frozen.svg',
    'lightbulb-vocolinc-lightstrip-frozen.svg':
      'lightbulb-vocolinc-lightstrip-frozen.svg',
    'lightbulb-vocolinc-lightstrip.svg': 'lightbulb-vocolinc-lightstrip.svg',
    'lightbulb-vocolinc.svg': 'lightbulb-vocolinc.svg',
    'lightbulb-wiz-light-frozen.svg': 'lightbulb-wiz-light-frozen.svg',
    'lightbulb-wiz-light.svg': 'lightbulb-wiz-light.svg',
    'lightbulb-wyze-bulb-frozen.svg': 'lightbulb-wyze-bulb-frozen.svg',
    'lightbulb-wyze-bulb.svg': 'lightbulb-wyze-bulb.svg',
    'lightbulb-xiaomi-yeelightledbulb-frozen.svg':
      'lightbulb-xiaomi-yeelightledbulb-frozen.svg',
    'lightbulb-xiaomi-yeelightledbulb.svg':
      'lightbulb-xiaomi-yeelightledbulb.svg',
    'lightbulb-zengge-ledlight-frozen.svg':
      'lightbulb-zengge-ledlight-frozen.svg',
    'lightbulb-zengge-ledlight.svg': 'lightbulb-zengge-ledlight.svg',
    'lightbulb.svg': 'lightbulb.svg',
    'lightswitch-belkin-wemo-frozen.svg': 'lightswitch-belkin-wemo-frozen.svg',
    'lightswitch-belkin-wemo.svg': 'lightswitch-belkin-wemo.svg',
    'lightswitch-control4-c4din8dime-1.svg':
      'lightswitch-control4-c4din8dime-1.svg',
    'lightswitch-control4-c4din8dime-frozen-1.svg':
      'lightswitch-control4-c4din8dime-frozen-1.svg',
    'lightswitch-control4-c4din8dime-frozen.svg':
      'lightswitch-control4-c4din8dime-frozen.svg',
    'lightswitch-control4-c4din8dime.svg':
      'lightswitch-control4-c4din8dime.svg',
    'lightswitch-crestron-switch-frozen.svg':
      'lightswitch-crestron-switch-frozen.svg',
    'lightswitch-crestron-switch.svg': 'lightswitch-crestron-switch.svg',
    'lightswitch-deako-smartlighting-frozen.svg':
      'lightswitch-deako-smartlighting-frozen.svg',
    'lightswitch-deako-smartlighting.svg':
      'lightswitch-deako-smartlighting.svg',
    'lightswitch-dingz-lightswitch-frozen.svg':
      'lightswitch-dingz-lightswitch-frozen.svg',
    'lightswitch-dingz-lightswitch.svg': 'lightswitch-dingz-lightswitch.svg',
    'lightswitch-ecobee-switch+-frozen.svg':
      'lightswitch-ecobee-switch+-frozen.svg',
    'lightswitch-ecobee-switch+.svg': 'lightswitch-ecobee-switch+.svg',
    'lightswitch-etekcity-dimmer-frozen.svg':
      'lightswitch-etekcity-dimmer-frozen.svg',
    'lightswitch-etekcity-dimmer.svg': 'lightswitch-etekcity-dimmer.svg',
    'lightswitch-feller-zeptrion-frozen.svg':
      'lightswitch-feller-zeptrion-frozen.svg',
    'lightswitch-feller-zeptrion.svg': 'lightswitch-feller-zeptrion.svg',
    'lightswitch-frozen.svg': 'lightswitch-frozen.svg',
    'lightswitch-ge-dimmer-frozen.svg': 'lightswitch-ge-dimmer-frozen.svg',
    'lightswitch-ge-dimmer.svg': 'lightswitch-ge-dimmer.svg',
    'lightswitch-ge-switch-frozen.svg': 'lightswitch-ge-switch-frozen.svg',
    'lightswitch-ge-switch.svg': 'lightswitch-ge-switch.svg',
    'lightswitch-idevices-dimmerswitch-frozen.svg':
      'lightswitch-idevices-dimmerswitch-frozen.svg',
    'lightswitch-idevices-dimmerswitch.svg':
      'lightswitch-idevices-dimmerswitch.svg',
    'lightswitch-idevices-outdoorswitch-frozen.svg':
      'lightswitch-idevices-outdoorswitch-frozen.svg',
    'lightswitch-idevices-outdoorswitch.svg':
      'lightswitch-idevices-outdoorswitch.svg',
    'lightswitch-idevices-wallswitch-frozen.svg':
      'lightswitch-idevices-wallswitch-frozen.svg',
    'lightswitch-idevices-wallswitch.svg':
      'lightswitch-idevices-wallswitch.svg',
    'lightswitch-iotty-smartswitch-frozen.svg':
      'lightswitch-iotty-smartswitch-frozen.svg',
    'lightswitch-iotty-smartswitch.svg': 'lightswitch-iotty-smartswitch.svg',
    'lightswitch-koogeek-dimmer-frozen.svg':
      'lightswitch-koogeek-dimmer-frozen.svg',
    'lightswitch-koogeek-dimmer.svg': 'lightswitch-koogeek-dimmer.svg',
    'lightswitch-leviton-dimmer-frozen.svg':
      'lightswitch-leviton-dimmer-frozen.svg',
    'lightswitch-leviton-dimmer.svg': 'lightswitch-leviton-dimmer.svg',
    'lightswitch-leviton-switch-frozen.svg':
      'lightswitch-leviton-switch-frozen.svg',
    'lightswitch-leviton-switch.svg': 'lightswitch-leviton-switch.svg',
    'lightswitch-lutron-dimmer-frozen.svg':
      'lightswitch-lutron-dimmer-frozen.svg',
    'lightswitch-lutron-dimmer.svg': 'lightswitch-lutron-dimmer.svg',
    'lightswitch-lutrondimmerswitch-frozen.svg':
      'lightswitch-lutrondimmerswitch-frozen.svg',
    'lightswitch-lutrondimmerswitch.svg': 'lightswitch-lutrondimmerswitch.svg',
    'lightswitch-martinjerry-smartdimmerswitch-frozen.svg':
      'lightswitch-martinjerry-smartdimmerswitch-frozen.svg',
    'lightswitch-martinjerry-smartdimmerswitch.svg':
      'lightswitch-martinjerry-smartdimmerswitch.svg',
    'lightswitch-martinjerry-smartswitch-frozen.svg':
      'lightswitch-martinjerry-smartswitch-frozen.svg',
    'lightswitch-martinjerry-smartswitch.svg':
      'lightswitch-martinjerry-smartswitch.svg',
    'lightswitch-meross-dimmerswitch-frozen.svg':
      'lightswitch-meross-dimmerswitch-frozen.svg',
    'lightswitch-meross-dimmerswitch.svg':
      'lightswitch-meross-dimmerswitch.svg',
    'lightswitch-meross-smartwifiswitch-frozen.svg':
      'lightswitch-meross-smartwifiswitch-frozen.svg',
    'lightswitch-meross-smartwifiswitch.svg':
      'lightswitch-meross-smartwifiswitch.svg',
    'lightswitch-noonroomdirector-frozen.svg':
      'lightswitch-noonroomdirector-frozen.svg',
    'lightswitch-noonroomdirector.svg': 'lightswitch-noonroomdirector.svg',
    'lightswitch-orro-switch-frozen.svg': 'lightswitch-orro-switch-frozen.svg',
    'lightswitch-orro-switch.svg': 'lightswitch-orro-switch.svg',
    'lightswitch-plum-lightpaddimmer-frozen.svg':
      'lightswitch-plum-lightpaddimmer-frozen.svg',
    'lightswitch-plum-lightpaddimmer.svg':
      'lightswitch-plum-lightpaddimmer.svg',
    'lightswitch-shelly-1-frozen.svg': 'lightswitch-shelly-1-frozen.svg',
    'lightswitch-shelly-1.svg': 'lightswitch-shelly-1.svg',
    'lightswitch-shelly-1l-frozen.svg': 'lightswitch-shelly-1l-frozen.svg',
    'lightswitch-shelly-1l.svg': 'lightswitch-shelly-1l.svg',
    'lightswitch-shelly-1pmrelayswitch-1.svg':
      'lightswitch-shelly-1pmrelayswitch-1.svg',
    'lightswitch-shelly-1pmrelayswitch-frozen-1.svg':
      'lightswitch-shelly-1pmrelayswitch-frozen-1.svg',
    'lightswitch-shelly-1pmrelayswitch-frozen.svg':
      'lightswitch-shelly-1pmrelayswitch-frozen.svg',
    'lightswitch-shelly-1pmrelayswitch.svg':
      'lightswitch-shelly-1pmrelayswitch.svg',
    'lightswitch-shelly-button-frozen.svg':
      'lightswitch-shelly-button-frozen.svg',
    'lightswitch-shelly-button.svg': 'lightswitch-shelly-button.svg',
    'lightswitch-shelly-dimmer-frozen.svg':
      'lightswitch-shelly-dimmer-frozen.svg',
    'lightswitch-shelly-dimmer.svg': 'lightswitch-shelly-dimmer.svg',
    'lightswitch-sonoffswitch-frozen.svg':
      'lightswitch-sonoffswitch-frozen.svg',
    'lightswitch-sonoffswitch.svg': 'lightswitch-sonoffswitch.svg',
    'lightswitch-tplink-kasawifilightswitch-frozen.svg':
      'lightswitch-tplink-kasawifilightswitch-frozen.svg',
    'lightswitch-tplink-kasawifilightswitch.svg':
      'lightswitch-tplink-kasawifilightswitch.svg',
    'lightswitch.svg': 'lightswitch.svg',
    'litterbox-frozen.svg': 'litterbox-frozen.svg',
    'litterbox.svg': 'litterbox.svg',
    'mediaplayer-akai-mpc-frozen.svg': 'mediaplayer-akai-mpc-frozen.svg',
    'mediaplayer-akai-mpc.svg': 'mediaplayer-akai-mpc.svg',
    'mediaplayer-amazon-echolink-frozen.svg':
      'mediaplayer-amazon-echolink-frozen.svg',
    'mediaplayer-amazon-echolink.svg': 'mediaplayer-amazon-echolink.svg',
    'mediaplayer-apple-appletv-frozen.svg':
      'mediaplayer-apple-appletv-frozen.svg',
    'mediaplayer-apple-appletv.svg': 'mediaplayer-apple-appletv.svg',
    'mediaplayer-apple-ipod-frozen.svg': 'mediaplayer-apple-ipod-frozen.svg',
    'mediaplayer-apple-ipod.svg': 'mediaplayer-apple-ipod.svg',
    'mediaplayer-astellkern-ak70-frozen.svg':
      'mediaplayer-astellkern-ak70-frozen.svg',
    'mediaplayer-astellkern-ak70.svg': 'mediaplayer-astellkern-ak70.svg',
    'mediaplayer-bluesound-nodemediaplayer-frozen.svg':
      'mediaplayer-bluesound-nodemediaplayer-frozen.svg',
    'mediaplayer-bluesound-nodemediaplayer.svg':
      'mediaplayer-bluesound-nodemediaplayer.svg',
    'mediaplayer-bluesound-powernodemediaplayer-frozen.svg':
      'mediaplayer-bluesound-powernodemediaplayer-frozen.svg',
    'mediaplayer-bluesound-powernodemediaplayer.svg':
      'mediaplayer-bluesound-powernodemediaplayer.svg',
    'mediaplayer-bluesound-pulse-frozen.svg':
      'mediaplayer-bluesound-pulse-frozen.svg',
    'mediaplayer-bluesound-pulse.svg': 'mediaplayer-bluesound-pulse.svg',
    'mediaplayer-bluesound-vaultmediaplayer-frozen.svg':
      'mediaplayer-bluesound-vaultmediaplayer-frozen.svg',
    'mediaplayer-bluesound-vaultmediaplayer.svg':
      'mediaplayer-bluesound-vaultmediaplayer.svg',
    'mediaplayer-cambridgeaudio-cxnv2-frozen.svg':
      'mediaplayer-cambridgeaudio-cxnv2-frozen.svg',
    'mediaplayer-cambridgeaudio-cxnv2.svg':
      'mediaplayer-cambridgeaudio-cxnv2.svg',
    'mediaplayer-comoaudio-frozen.svg': 'mediaplayer-comoaudio-frozen.svg',
    'mediaplayer-comoaudio.svg': 'mediaplayer-comoaudio.svg',
    'mediaplayer-control4-4zoneamplifier-frozen.svg':
      'mediaplayer-control4-4zoneamplifier-frozen.svg',
    'mediaplayer-control4-4zoneamplifier.svg':
      'mediaplayer-control4-4zoneamplifier.svg',
    'mediaplayer-control4-triad-frozen.svg':
      'mediaplayer-control4-triad-frozen.svg',
    'mediaplayer-control4-triad.svg': 'mediaplayer-control4-triad.svg',
    'mediaplayer-denon-ceol-frozen.svg': 'mediaplayer-denon-ceol-frozen.svg',
    'mediaplayer-denon-ceol.svg': 'mediaplayer-denon-ceol.svg',
    'mediaplayer-denon-dj-frozen.svg': 'mediaplayer-denon-dj-frozen.svg',
    'mediaplayer-denon-dj.svg': 'mediaplayer-denon-dj.svg',
    'mediaplayer-denon-dnp800ne-frozen.svg':
      'mediaplayer-denon-dnp800ne-frozen.svg',
    'mediaplayer-denon-dnp800ne.svg': 'mediaplayer-denon-dnp800ne.svg',
    'mediaplayer-denon-heosamp-frozen.svg':
      'mediaplayer-denon-heosamp-frozen.svg',
    'mediaplayer-denon-heosamp.svg': 'mediaplayer-denon-heosamp.svg',
    'mediaplayer-denon-heosdrive-frozen.svg':
      'mediaplayer-denon-heosdrive-frozen.svg',
    'mediaplayer-denon-heosdrive.svg': 'mediaplayer-denon-heosdrive.svg',
    'mediaplayer-denon-heoslink-frozen.svg':
      'mediaplayer-denon-heoslink-frozen.svg',
    'mediaplayer-denon-heoslink.svg': 'mediaplayer-denon-heoslink.svg',
    'mediaplayer-denon-heossuperlink-frozen.svg':
      'mediaplayer-denon-heossuperlink-frozen.svg',
    'mediaplayer-denon-heossuperlink.svg':
      'mediaplayer-denon-heossuperlink.svg',
    'mediaplayer-denondj-prime-frozen.svg':
      'mediaplayer-denondj-prime-frozen.svg',
    'mediaplayer-denondj-prime.svg': 'mediaplayer-denondj-prime.svg',
    'mediaplayer-dlink-cameravideorecorder-frozen.svg':
      'mediaplayer-dlink-cameravideorecorder-frozen.svg',
    'mediaplayer-dlink-cameravideorecorder.svg':
      'mediaplayer-dlink-cameravideorecorder.svg',
    'mediaplayer-dstv-explora-frozen.svg':
      'mediaplayer-dstv-explora-frozen.svg',
    'mediaplayer-dstv-explora.svg': 'mediaplayer-dstv-explora.svg',
    'mediaplayer-frozen.svg': 'mediaplayer-frozen.svg',
    'mediaplayer-gracedigital-musicplayer-frozen.svg':
      'mediaplayer-gracedigital-musicplayer-frozen.svg',
    'mediaplayer-gracedigital-musicplayer.svg':
      'mediaplayer-gracedigital-musicplayer.svg',
    'mediaplayer-h96-max-frozen.svg': 'mediaplayer-h96-max-frozen.svg',
    'mediaplayer-h96-max.svg': 'mediaplayer-h96-max.svg',
    'mediaplayer-hitachi-bluraydiscrecorder-frozen.svg':
      'mediaplayer-hitachi-bluraydiscrecorder-frozen.svg',
    'mediaplayer-hitachi-bluraydiscrecorder.svg':
      'mediaplayer-hitachi-bluraydiscrecorder.svg',
    'mediaplayer-idis-dvr-frozen.svg': 'mediaplayer-idis-dvr-frozen.svg',
    'mediaplayer-idis-dvr.svg': 'mediaplayer-idis-dvr.svg',
    'mediaplayer-jooki-musicplayer-frozen.svg':
      'mediaplayer-jooki-musicplayer-frozen.svg',
    'mediaplayer-jooki-musicplayer.svg': 'mediaplayer-jooki-musicplayer.svg',
    'mediaplayer-kingbox-tvbox-frozen.svg':
      'mediaplayer-kingbox-tvbox-frozen.svg',
    'mediaplayer-kingbox-tvbox.svg': 'mediaplayer-kingbox-tvbox.svg',
    'mediaplayer-kodi-frozen.svg': 'mediaplayer-kodi-frozen.svg',
    'mediaplayer-kodi.svg': 'mediaplayer-kodi.svg',
    'mediaplayer-logitech-squeezebox-frozen.svg':
      'mediaplayer-logitech-squeezebox-frozen.svg',
    'mediaplayer-logitech-squeezebox.svg':
      'mediaplayer-logitech-squeezebox.svg',
    'mediaplayer-magictv-mtv3300d-frozen.svg':
      'mediaplayer-magictv-mtv3300d-frozen.svg',
    'mediaplayer-magictv-mtv3300d.svg': 'mediaplayer-magictv-mtv3300d.svg',
    'mediaplayer-magictv-mtv8000d-frozen.svg':
      'mediaplayer-magictv-mtv8000d-frozen.svg',
    'mediaplayer-magictv-mtv8000d.svg': 'mediaplayer-magictv-mtv8000d.svg',
    'mediaplayer-marantz-mcr612-frozen.svg':
      'mediaplayer-marantz-mcr612-frozen.svg',
    'mediaplayer-marantz-mcr612.svg': 'mediaplayer-marantz-mcr612.svg',
    'mediaplayer-microjukebox-frozen.svg':
      'mediaplayer-microjukebox-frozen.svg',
    'mediaplayer-microjukebox.svg': 'mediaplayer-microjukebox.svg',
    'mediaplayer-mighty-musicplayer-frozen.svg':
      'mediaplayer-mighty-musicplayer-frozen.svg',
    'mediaplayer-mighty-musicplayer.svg': 'mediaplayer-mighty-musicplayer.svg',
    'mediaplayer-nad-t-frozen.svg': 'mediaplayer-nad-t-frozen.svg',
    'mediaplayer-nad-t.svg': 'mediaplayer-nad-t.svg',
    'mediaplayer-nightowl-dvr-frozen.svg':
      'mediaplayer-nightowl-dvr-frozen.svg',
    'mediaplayer-nightowl-dvr.svg': 'mediaplayer-nightowl-dvr.svg',
    'mediaplayer-nuvo-homeaudiosystem-frozen.svg':
      'mediaplayer-nuvo-homeaudiosystem-frozen.svg',
    'mediaplayer-nuvo-homeaudiosystem.svg':
      'mediaplayer-nuvo-homeaudiosystem.svg',
    'mediaplayer-panasonic-blurayplayer-frozen.svg':
      'mediaplayer-panasonic-blurayplayer-frozen.svg',
    'mediaplayer-panasonic-blurayplayer.svg':
      'mediaplayer-panasonic-blurayplayer.svg',
    'mediaplayer-panasonic-blurayrecorder-frozen.svg':
      'mediaplayer-panasonic-blurayrecorder-frozen.svg',
    'mediaplayer-panasonic-blurayrecorder.svg':
      'mediaplayer-panasonic-blurayrecorder.svg',
    'mediaplayer-panasonic-diga-frozen.svg':
      'mediaplayer-panasonic-diga-frozen.svg',
    'mediaplayer-panasonic-diga.svg': 'mediaplayer-panasonic-diga.svg',
    'mediaplayer-panasonic-hddrecorder-frozen.svg':
      'mediaplayer-panasonic-hddrecorder-frozen.svg',
    'mediaplayer-panasonic-hddrecorder.svg':
      'mediaplayer-panasonic-hddrecorder.svg',
    'mediaplayer-philips-bdp7501-frozen.svg':
      'mediaplayer-philips-bdp7501-frozen.svg',
    'mediaplayer-philips-bdp7501.svg': 'mediaplayer-philips-bdp7501.svg',
    'mediaplayer-philips-tapr802-frozen.svg':
      'mediaplayer-philips-tapr802-frozen.svg',
    'mediaplayer-philips-tapr802.svg': 'mediaplayer-philips-tapr802.svg',
    'mediaplayer-pure-evoke-frozen.svg': 'mediaplayer-pure-evoke-frozen.svg',
    'mediaplayer-pure-evoke.svg': 'mediaplayer-pure-evoke.svg',
    'mediaplayer-pure-sensia-frozen.svg': 'mediaplayer-pure-sensia-frozen.svg',
    'mediaplayer-pure-sensia.svg': 'mediaplayer-pure-sensia.svg',
    'mediaplayer-russound-mbxamp-frozen.svg':
      'mediaplayer-russound-mbxamp-frozen.svg',
    'mediaplayer-russound-mbxamp.svg': 'mediaplayer-russound-mbxamp.svg',
    'mediaplayer-russound-xsource-frozen.svg':
      'mediaplayer-russound-xsource-frozen.svg',
    'mediaplayer-russound-xsource.svg': 'mediaplayer-russound-xsource.svg',
    'mediaplayer-samsung-blurayplayer-frozen.svg':
      'mediaplayer-samsung-blurayplayer-frozen.svg',
    'mediaplayer-samsung-blurayplayer.svg':
      'mediaplayer-samsung-blurayplayer.svg',
    'mediaplayer-scishion-v88-frozen.svg':
      'mediaplayer-scishion-v88-frozen.svg',
    'mediaplayer-scishion-v88.svg': 'mediaplayer-scishion-v88.svg',
    'mediaplayer-simaudio-mind-frozen.svg':
      'mediaplayer-simaudio-mind-frozen.svg',
    'mediaplayer-simaudio-mind.svg': 'mediaplayer-simaudio-mind.svg',
    'mediaplayer-siriusxm-soundstation-frozen.svg':
      'mediaplayer-siriusxm-soundstation-frozen.svg',
    'mediaplayer-siriusxm-soundstation.svg':
      'mediaplayer-siriusxm-soundstation.svg',
    'mediaplayer-sony-blurayplayer-frozen.svg':
      'mediaplayer-sony-blurayplayer-frozen.svg',
    'mediaplayer-sony-blurayplayer.svg': 'mediaplayer-sony-blurayplayer.svg',
    'mediaplayer-sony-blurayrecoder-frozen.svg':
      'mediaplayer-sony-blurayrecoder-frozen.svg',
    'mediaplayer-sony-blurayrecoder.svg': 'mediaplayer-sony-blurayrecoder.svg',
    'mediaplayer-sony-player-frozen.svg': 'mediaplayer-sony-player-frozen.svg',
    'mediaplayer-sony-player.svg': 'mediaplayer-sony-player.svg',
    'mediaplayer-sony-walkman-frozen.svg':
      'mediaplayer-sony-walkman-frozen.svg',
    'mediaplayer-sony-walkman.svg': 'mediaplayer-sony-walkman.svg',
    'mediaplayer-teufel-radio3sixty-frozen.svg':
      'mediaplayer-teufel-radio3sixty-frozen.svg',
    'mediaplayer-teufel-radio3sixty.svg': 'mediaplayer-teufel-radio3sixty.svg',
    'mediaplayer-toshiba-regza-frozen.svg':
      'mediaplayer-toshiba-regza-frozen.svg',
    'mediaplayer-toshiba-regza.svg': 'mediaplayer-toshiba-regza.svg',
    'mediaplayer-volumio-musicplayer-frozen.svg':
      'mediaplayer-volumio-musicplayer-frozen.svg',
    'mediaplayer-volumio-musicplayer.svg':
      'mediaplayer-volumio-musicplayer.svg',
    'mediaplayer-wd-tvlivehubmediaplayer-frozen.svg':
      'mediaplayer-wd-tvlivehubmediaplayer-frozen.svg',
    'mediaplayer-wd-tvlivehubmediaplayer.svg':
      'mediaplayer-wd-tvlivehubmediaplayer.svg',
    'mediaplayer-wd-tvlivemediaplayer-frozen.svg':
      'mediaplayer-wd-tvlivemediaplayer-frozen.svg',
    'mediaplayer-wd-tvlivemediaplayer.svg':
      'mediaplayer-wd-tvlivemediaplayer.svg',
    'mediaplayer-wd-tvliveplusmediaplayer-frozen.svg':
      'mediaplayer-wd-tvliveplusmediaplayer-frozen.svg',
    'mediaplayer-wd-tvliveplusmediaplayer.svg':
      'mediaplayer-wd-tvliveplusmediaplayer.svg',
    'mediaplayer-wd-tvplaymediaplayer-frozen.svg':
      'mediaplayer-wd-tvplaymediaplayer-frozen.svg',
    'mediaplayer-wd-tvplaymediaplayer.svg':
      'mediaplayer-wd-tvplaymediaplayer.svg',
    'mediaplayer-wdtvmediaplayer-frozen.svg':
      'mediaplayer-wdtvmediaplayer-frozen.svg',
    'mediaplayer-wdtvmediaplayer.svg': 'mediaplayer-wdtvmediaplayer.svg',
    'mediaplayer-yamaha-avreceiver-frozen.svg':
      'mediaplayer-yamaha-avreceiver-frozen.svg',
    'mediaplayer-yamaha-avreceiver.svg': 'mediaplayer-yamaha-avreceiver.svg',
    'mediaplayer-yandex-station-frozen.svg':
      'mediaplayer-yandex-station-frozen.svg',
    'mediaplayer-yandex-station.svg': 'mediaplayer-yandex-station.svg',
    'mediaplayer.svg': 'mediaplayer.svg',
    'meshrouter-airties-air-frozen.svg': 'meshrouter-airties-air-frozen.svg',
    'meshrouter-airties-air.svg': 'meshrouter-airties-air.svg',
    'meshrouter-bt-wholehome-frozen.svg': 'meshrouter-bt-wholehome-frozen.svg',
    'meshrouter-bt-wholehome.svg': 'meshrouter-bt-wholehome.svg',
    'meshrouter-cambiumnetworks-router-frozen.svg':
      'meshrouter-cambiumnetworks-router-frozen.svg',
    'meshrouter-cambiumnetworks-router.svg':
      'meshrouter-cambiumnetworks-router.svg',
    'meshrouter-commscope-frozen.svg': 'meshrouter-commscope-frozen.svg',
    'meshrouter-commscope.svg': 'meshrouter-commscope.svg',
    'meshrouter-dlink-covr-frozen.svg': 'meshrouter-dlink-covr-frozen.svg',
    'meshrouter-dlink-covr.svg': 'meshrouter-dlink-covr.svg',
    'meshrouter-eero-frozen.svg': 'meshrouter-eero-frozen.svg',
    'meshrouter-eero.svg': 'meshrouter-eero.svg',
    'meshrouter-elecom-meshrouter-frozen.svg':
      'meshrouter-elecom-meshrouter-frozen.svg',
    'meshrouter-elecom-meshrouter.svg': 'meshrouter-elecom-meshrouter.svg',
    'meshrouter-frozen.svg': 'meshrouter-frozen.svg',
    'meshrouter-googlenest-wifi-frozen.svg':
      'meshrouter-googlenest-wifi-frozen.svg',
    'meshrouter-googlenest-wifi.svg': 'meshrouter-googlenest-wifi.svg',
    'meshrouter-helium-hotspot-frozen.svg':
      'meshrouter-helium-hotspot-frozen.svg',
    'meshrouter-helium-hotspot.svg': 'meshrouter-helium-hotspot.svg',
    'meshrouter-linksys-velop-frozen.svg':
      'meshrouter-linksys-velop-frozen.svg',
    'meshrouter-linksys-velop.svg': 'meshrouter-linksys-velop.svg',
    'meshrouter-netgear-orbipro-frozen.svg':
      'meshrouter-netgear-orbipro-frozen.svg',
    'meshrouter-netgear-orbipro.svg': 'meshrouter-netgear-orbipro.svg',
    'meshrouter-plume-frozen.svg': 'meshrouter-plume-frozen.svg',
    'meshrouter-plume-pod-frozen.svg': 'meshrouter-plume-pod-frozen.svg',
    'meshrouter-plume-pod.svg': 'meshrouter-plume-pod.svg',
    'meshrouter-plume-powerpod-frozen.svg':
      'meshrouter-plume-powerpod-frozen.svg',
    'meshrouter-plume-powerpod.svg': 'meshrouter-plume-powerpod.svg',
    'meshrouter-plume-superpod-frozen.svg':
      'meshrouter-plume-superpod-frozen.svg',
    'meshrouter-plume-superpod.svg': 'meshrouter-plume-superpod.svg',
    'meshrouter-plume.svg': 'meshrouter-plume.svg',
    'meshrouter-sky-qbooster-frozen.svg': 'meshrouter-sky-qbooster-frozen.svg',
    'meshrouter-sky-qbooster.svg': 'meshrouter-sky-qbooster.svg',
    'meshrouter-tenda-nova-frozen.svg': 'meshrouter-tenda-nova-frozen.svg',
    'meshrouter-tenda-nova.svg': 'meshrouter-tenda-nova.svg',
    'meshrouter.svg': 'meshrouter.svg',
    'microwave-frozen.svg': 'microwave-frozen.svg',
    'microwave-siemens-microwave-frozen.svg':
      'microwave-siemens-microwave-frozen.svg',
    'microwave-siemens-microwave.svg': 'microwave-siemens-microwave.svg',
    'microwave-whirlpool-frozen.svg': 'microwave-whirlpool-frozen.svg',
    'microwave-whirlpool.svg': 'microwave-whirlpool.svg',
    'microwave.svg': 'microwave.svg',
    'nasstorage-antsle-frozen.svg': 'nasstorage-antsle-frozen.svg',
    'nasstorage-antsle-privatecloudserver-1.svg':
      'nasstorage-antsle-privatecloudserver-1.svg',
    'nasstorage-antsle-privatecloudserver-frozen-1.svg':
      'nasstorage-antsle-privatecloudserver-frozen-1.svg',
    'nasstorage-antsle-privatecloudserver-frozen.svg':
      'nasstorage-antsle-privatecloudserver-frozen.svg',
    'nasstorage-antsle-privatecloudserver.svg':
      'nasstorage-antsle-privatecloudserver.svg',
    'nasstorage-antsle.svg': 'nasstorage-antsle.svg',
    'nasstorage-asustor-as1002t-frozen.svg':
      'nasstorage-asustor-as1002t-frozen.svg',
    'nasstorage-asustor-as1002t.svg': 'nasstorage-asustor-as1002t.svg',
    'nasstorage-asustor-nas-frozen.svg': 'nasstorage-asustor-nas-frozen.svg',
    'nasstorage-asustor-nas.svg': 'nasstorage-asustor-nas.svg',
    'nasstorage-buffalo-frozen.svg': 'nasstorage-buffalo-frozen.svg',
    'nasstorage-buffalo-linkstation-frozen.svg':
      'nasstorage-buffalo-linkstation-frozen.svg',
    'nasstorage-buffalo-linkstation.svg': 'nasstorage-buffalo-linkstation.svg',
    'nasstorage-buffalo.svg': 'nasstorage-buffalo.svg',
    'nasstorage-cubbit-frozen.svg': 'nasstorage-cubbit-frozen.svg',
    'nasstorage-cubbit-storagehub-frozen.svg':
      'nasstorage-cubbit-storagehub-frozen.svg',
    'nasstorage-cubbit-storagehub.svg': 'nasstorage-cubbit-storagehub.svg',
    'nasstorage-cubbit.svg': 'nasstorage-cubbit.svg',
    'nasstorage-dlink-frozen.svg': 'nasstorage-dlink-frozen.svg',
    'nasstorage-dlink-sharecenter-frozen.svg':
      'nasstorage-dlink-sharecenter-frozen.svg',
    'nasstorage-dlink-sharecenter.svg': 'nasstorage-dlink-sharecenter.svg',
    'nasstorage-dlink.svg': 'nasstorage-dlink.svg',
    'nasstorage-echelon-smartserver-frozen.svg':
      'nasstorage-echelon-smartserver-frozen.svg',
    'nasstorage-echelon-smartserver.svg': 'nasstorage-echelon-smartserver.svg',
    'nasstorage-frozen.svg': 'nasstorage-frozen.svg',
    'nasstorage-innuous-frozen.svg': 'nasstorage-innuous-frozen.svg',
    'nasstorage-innuous-zenmini-frozen.svg':
      'nasstorage-innuous-zenmini-frozen.svg',
    'nasstorage-innuous-zenmini.svg': 'nasstorage-innuous-zenmini.svg',
    'nasstorage-innuous.svg': 'nasstorage-innuous.svg',
    'nasstorage-iodata-frozen.svg': 'nasstorage-iodata-frozen.svg',
    'nasstorage-iodata-landisk-frozen.svg':
      'nasstorage-iodata-landisk-frozen.svg',
    'nasstorage-iodata-landisk.svg': 'nasstorage-iodata-landisk.svg',
    'nasstorage-iodata-pokedoracloud-frozen.svg':
      'nasstorage-iodata-pokedoracloud-frozen.svg',
    'nasstorage-iodata-pokedoracloud.svg':
      'nasstorage-iodata-pokedoracloud.svg',
    'nasstorage-iodata.svg': 'nasstorage-iodata.svg',
    'nasstorage-lacie-2big-frozen.svg': 'nasstorage-lacie-2big-frozen.svg',
    'nasstorage-lacie-2big.svg': 'nasstorage-lacie-2big.svg',
    'nasstorage-lacie-5big-frozen.svg': 'nasstorage-lacie-5big-frozen.svg',
    'nasstorage-lacie-5big.svg': 'nasstorage-lacie-5big.svg',
    'nasstorage-lacie-cloudbox-frozen.svg':
      'nasstorage-lacie-cloudbox-frozen.svg',
    'nasstorage-lacie-cloudbox.svg': 'nasstorage-lacie-cloudbox.svg',
    'nasstorage-lacie-frozen.svg': 'nasstorage-lacie-frozen.svg',
    'nasstorage-lacie-fuel-frozen.svg': 'nasstorage-lacie-fuel-frozen.svg',
    'nasstorage-lacie-fuel.svg': 'nasstorage-lacie-fuel.svg',
    'nasstorage-lacie-networkstorage-frozen.svg':
      'nasstorage-lacie-networkstorage-frozen.svg',
    'nasstorage-lacie-networkstorage.svg':
      'nasstorage-lacie-networkstorage.svg',
    'nasstorage-lacie.svg': 'nasstorage-lacie.svg',
    'nasstorage-lenovo-frozen.svg': 'nasstorage-lenovo-frozen.svg',
    'nasstorage-lenovo.svg': 'nasstorage-lenovo.svg',
    'nasstorage-lg-frozen.svg': 'nasstorage-lg-frozen.svg',
    'nasstorage-lg-nas-frozen.svg': 'nasstorage-lg-nas-frozen.svg',
    'nasstorage-lg-nas.svg': 'nasstorage-lg-nas.svg',
    'nasstorage-lg.svg': 'nasstorage-lg.svg',
    'nasstorage-monument-frozen.svg': 'nasstorage-monument-frozen.svg',
    'nasstorage-monument-photostorage-frozen.svg':
      'nasstorage-monument-photostorage-frozen.svg',
    'nasstorage-monument-photostorage.svg':
      'nasstorage-monument-photostorage.svg',
    'nasstorage-monument.svg': 'nasstorage-monument.svg',
    'nasstorage-netgear-frozen.svg': 'nasstorage-netgear-frozen.svg',
    'nasstorage-netgear-nas-frozen.svg': 'nasstorage-netgear-nas-frozen.svg',
    'nasstorage-netgear-nas.svg': 'nasstorage-netgear-nas.svg',
    'nasstorage-netgear-readynas-frozen.svg':
      'nasstorage-netgear-readynas-frozen.svg',
    'nasstorage-netgear-readynas.svg': 'nasstorage-netgear-readynas.svg',
    'nasstorage-netgear-stora-frozen.svg':
      'nasstorage-netgear-stora-frozen.svg',
    'nasstorage-netgear-stora.svg': 'nasstorage-netgear-stora.svg',
    'nasstorage-netgear.svg': 'nasstorage-netgear.svg',
    'nasstorage-omoidebako-frozen.svg': 'nasstorage-omoidebako-frozen.svg',
    'nasstorage-omoidebako.svg': 'nasstorage-omoidebako.svg',
    'nasstorage-qnap-frozen.svg': 'nasstorage-qnap-frozen.svg',
    'nasstorage-qnap-nas-frozen.svg': 'nasstorage-qnap-nas-frozen.svg',
    'nasstorage-qnap.svg': 'nasstorage-qnap.svg',
    'nasstorage-sandisk-frozen.svg': 'nasstorage-sandisk-frozen.svg',
    'nasstorage-sandisk-ixpand-frozen.svg':
      'nasstorage-sandisk-ixpand-frozen.svg',
    'nasstorage-sandisk-ixpand.svg': 'nasstorage-sandisk-ixpand.svg',
    'nasstorage-sandisk.svg': 'nasstorage-sandisk.svg',
    'nasstorage-seagate-frozen.svg': 'nasstorage-seagate-frozen.svg',
    'nasstorage-seagate-goflexhome-frozen.svg':
      'nasstorage-seagate-goflexhome-frozen.svg',
    'nasstorage-seagate-goflexhome.svg': 'nasstorage-seagate-goflexhome.svg',
    'nasstorage-seagate-nas-frozen.svg': 'nasstorage-seagate-nas-frozen.svg',
    'nasstorage-seagate-nas.svg': 'nasstorage-seagate-nas.svg',
    'nasstorage-seagate.svg': 'nasstorage-seagate.svg',
    'nasstorage-synology-diskstationnas-frozen.svg':
      'nasstorage-synology-diskstationnas-frozen.svg',
    'nasstorage-synology-diskstationnas.svg':
      'nasstorage-synology-diskstationnas.svg',
    'nasstorage-synology-frozen.svg': 'nasstorage-synology-frozen.svg',
    'nasstorage-synology.svg': 'nasstorage-synology.svg',
    'nasstorage-truenas-frozen.svg': 'nasstorage-truenas-frozen.svg',
    'nasstorage-truenas-storage-frozen.svg':
      'nasstorage-truenas-storage-frozen.svg',
    'nasstorage-truenas-storage.svg': 'nasstorage-truenas-storage.svg',
    'nasstorage-truenas.svg': 'nasstorage-truenas.svg',
    'nasstorage-ubiquiti-frozen.svg': 'nasstorage-ubiquiti-frozen.svg',
    'nasstorage-ubiquiti-unifi-frozen.svg':
      'nasstorage-ubiquiti-unifi-frozen.svg',
    'nasstorage-ubiquiti-unifi.svg': 'nasstorage-ubiquiti-unifi.svg',
    'nasstorage-ubiquiti.svg': 'nasstorage-ubiquiti.svg',
    'nasstorage-vivint-frozen.svg': 'nasstorage-vivint-frozen.svg',
    'nasstorage-vivint-smartdrive-frozen.svg':
      'nasstorage-vivint-smartdrive-frozen.svg',
    'nasstorage-vivint-smartdrive.svg': 'nasstorage-vivint-smartdrive.svg',
    'nasstorage-vivint.svg': 'nasstorage-vivint.svg',
    'nasstorage-vivotek-videoserver-frozen.svg':
      'nasstorage-vivotek-videoserver-frozen.svg',
    'nasstorage-vivotek-videoserver.svg': 'nasstorage-vivotek-videoserver.svg',
    'nasstorage-westerndigital-frozen.svg':
      'nasstorage-westerndigital-frozen.svg',
    'nasstorage-westerndigital-mybooklive-frozen.svg':
      'nasstorage-westerndigital-mybooklive-frozen.svg',
    'nasstorage-westerndigital-mybooklive.svg':
      'nasstorage-westerndigital-mybooklive.svg',
    'nasstorage-westerndigital-mycloud-frozen.svg':
      'nasstorage-westerndigital-mycloud-frozen.svg',
    'nasstorage-westerndigital-mycloud.svg':
      'nasstorage-westerndigital-mycloud.svg',
    'nasstorage-westerndigital-mypassport-frozen.svg':
      'nasstorage-westerndigital-mypassport-frozen.svg',
    'nasstorage-westerndigital-mypassport.svg':
      'nasstorage-westerndigital-mypassport.svg',
    'nasstorage-westerndigital.svg': 'nasstorage-westerndigital.svg',
    'nasstorage-zyxel-frozen.svg': 'nasstorage-zyxel-frozen.svg',
    'nasstorage-zyxel-mediaserver-frozen.svg':
      'nasstorage-zyxel-mediaserver-frozen.svg',
    'nasstorage-zyxel-mediaserver.svg': 'nasstorage-zyxel-mediaserver.svg',
    'nasstorage-zyxel.svg': 'nasstorage-zyxel.svg',
    'nasstorage.svg': 'nasstorage.svg',
    'networkcontroller-frozen.svg': 'networkcontroller-frozen.svg',
    'networkcontroller-gardena-gateway-frozen.svg':
      'networkcontroller-gardena-gateway-frozen.svg',
    'networkcontroller-gardena-gateway.svg':
      'networkcontroller-gardena-gateway.svg',
    'networkcontroller-generac-mobilelink-frozen.svg':
      'networkcontroller-generac-mobilelink-frozen.svg',
    'networkcontroller-generac-mobilelink.svg':
      'networkcontroller-generac-mobilelink.svg',
    'networkcontroller-meross-remotecontrol-frozen.svg':
      'networkcontroller-meross-remotecontrol-frozen.svg',
    'networkcontroller-meross-remotecontrol.svg':
      'networkcontroller-meross-remotecontrol.svg',
    'networkcontroller-ubiquiti-unifi-frozen.svg':
      'networkcontroller-ubiquiti-unifi-frozen.svg',
    'networkcontroller-ubiquiti-unifi.svg':
      'networkcontroller-ubiquiti-unifi.svg',
    'networkcontroller-vmware-velocloud-frozen.svg':
      'networkcontroller-vmware-velocloud-frozen.svg',
    'networkcontroller-vmware-velocloud.svg':
      'networkcontroller-vmware-velocloud.svg',
    'networkcontroller-xyo-bridgex-frozen.svg':
      'networkcontroller-xyo-bridgex-frozen.svg',
    'networkcontroller-xyo-bridgex.svg': 'networkcontroller-xyo-bridgex.svg',
    'networkcontroller-zinwell-ethernetadapter-frozen.svg':
      'networkcontroller-zinwell-ethernetadapter-frozen.svg',
    'networkcontroller-zinwell-ethernetadapter.svg':
      'networkcontroller-zinwell-ethernetadapter.svg',
    'networkcontroller.svg': 'networkcontroller.svg',
    'oven-anovaculinary-precisionoven-frozen.svg':
      'oven-anovaculinary-precisionoven-frozen.svg',
    'oven-anovaculinary-precisionoven.svg':
      'oven-anovaculinary-precisionoven.svg',
    'oven-bosch-oven-frozen.svg': 'oven-bosch-oven-frozen.svg',
    'oven-bosch-oven.svg': 'oven-bosch-oven.svg',
    'oven-brava-frozen.svg': 'oven-brava-frozen.svg',
    'oven-brava.svg': 'oven-brava.svg',
    'oven-cosori-oven-frozen.svg': 'oven-cosori-oven-frozen.svg',
    'oven-cosori-oven.svg': 'oven-cosori-oven.svg',
    'oven-frozen.svg': 'oven-frozen.svg',
    'oven-ge-frozen.svg': 'oven-ge-frozen.svg',
    'oven-ge.svg': 'oven-ge.svg',
    'oven-june-frozen.svg': 'oven-june-frozen.svg',
    'oven-june.svg': 'oven-june.svg',
    'oven-lg-smartoven-frozen.svg': 'oven-lg-smartoven-frozen.svg',
    'oven-lg-smartoven.svg': 'oven-lg-smartoven.svg',
    'oven-neff-n90-frozen.svg': 'oven-neff-n90-frozen.svg',
    'oven-neff-n90.svg': 'oven-neff-n90.svg',
    'oven-samsung-frozen.svg': 'oven-samsung-frozen.svg',
    'oven-samsung.svg': 'oven-samsung.svg',
    'oven-seimens-frozen.svg': 'oven-seimens-frozen.svg',
    'oven-seimens.svg': 'oven-seimens.svg',
    'oven-siemens-compactoven-frozen.svg':
      'oven-siemens-compactoven-frozen.svg',
    'oven-siemens-compactoven.svg': 'oven-siemens-compactoven.svg',
    'oven-thermador-frozen.svg': 'oven-thermador-frozen.svg',
    'oven-thermador.svg': 'oven-thermador.svg',
    'oven-vzug-frozen.svg': 'oven-vzug-frozen.svg',
    'oven-vzug.svg': 'oven-vzug.svg',
    'oven-whirlpool-frozen.svg': 'oven-whirlpool-frozen.svg',
    'oven-whirlpool.svg': 'oven-whirlpool.svg',
    'oven-wolf-oven-frozen.svg': 'oven-wolf-oven-frozen.svg',
    'oven-wolf-oven.svg': 'oven-wolf-oven.svg',
    'oven.svg': 'oven.svg',
    'petaccessory-fi-collar-frozen.svg': 'petaccessory-fi-collar-frozen.svg',
    'petaccessory-fi-collar.svg': 'petaccessory-fi-collar.svg',
    'petaccessory-frozen.svg': 'petaccessory-frozen.svg',
    'petaccessory-petkit-cozy-frozen.svg':
      'petaccessory-petkit-cozy-frozen.svg',
    'petaccessory-petkit-cozy.svg': 'petaccessory-petkit-cozy.svg',
    'petaccessory-petkit-petfeeder-frozen.svg':
      'petaccessory-petkit-petfeeder-frozen.svg',
    'petaccessory-petkit-petfeeder.svg': 'petaccessory-petkit-petfeeder.svg',
    'petaccessory-petoneer-catfountain-frozen.svg':
      'petaccessory-petoneer-catfountain-frozen.svg',
    'petaccessory-petoneer-catfountain.svg':
      'petaccessory-petoneer-catfountain.svg',
    'petaccessory.svg': 'petaccessory.svg',
    'pictureframe-aluratek-photoframe-frozen.svg':
      'pictureframe-aluratek-photoframe-frozen.svg',
    'pictureframe-aluratek-photoframe.svg':
      'pictureframe-aluratek-photoframe.svg',
    'pictureframe-aura-frame-frozen.svg': 'pictureframe-aura-frame-frozen.svg',
    'pictureframe-aura-frame.svg': 'pictureframe-aura-frame.svg',
    'pictureframe-canvia-smartartframe-frozen.svg':
      'pictureframe-canvia-smartartframe-frozen.svg',
    'pictureframe-canvia-smartartframe.svg':
      'pictureframe-canvia-smartartframe.svg',
    'pictureframe-frozen.svg': 'pictureframe-frozen.svg',
    'pictureframe-meural-canvas-frozen.svg':
      'pictureframe-meural-canvas-frozen.svg',
    'pictureframe-meural-canvas.svg': 'pictureframe-meural-canvas.svg',
    'pictureframe-nixplay-seed-frozen.svg':
      'pictureframe-nixplay-seed-frozen.svg',
    'pictureframe-nixplay-seed.svg': 'pictureframe-nixplay-seed.svg',
    'pictureframe-nixplay-smartphotoframe-frozen.svg':
      'pictureframe-nixplay-smartphotoframe-frozen.svg',
    'pictureframe-nixplay-smartphotoframe.svg':
      'pictureframe-nixplay-smartphotoframe.svg',
    'pictureframe-photospring-digitalframe-frozen.svg':
      'pictureframe-photospring-digitalframe-frozen.svg',
    'pictureframe-photospring-digitalframe.svg':
      'pictureframe-photospring-digitalframe.svg',
    'pictureframe-pixstar-digitalphotoframe-frozen.svg':
      'pictureframe-pixstar-digitalphotoframe-frozen.svg',
    'pictureframe-pixstar-digitalphotoframe.svg':
      'pictureframe-pixstar-digitalphotoframe.svg',
    'pictureframe-simplysmarthome-photoshare-frozen.svg':
      'pictureframe-simplysmarthome-photoshare-frozen.svg',
    'pictureframe-simplysmarthome-photoshare.svg':
      'pictureframe-simplysmarthome-photoshare.svg',
    'pictureframe-skylight-frame-frozen.svg':
      'pictureframe-skylight-frame-frozen.svg',
    'pictureframe-skylight-frame.svg': 'pictureframe-skylight-frame.svg',
    'pictureframe.svg': 'pictureframe.svg',
    'plug-amazon-smartplug-1.svg': 'plug-amazon-smartplug-1.svg',
    'plug-amazon-smartplug-frozen-1.svg': 'plug-amazon-smartplug-frozen-1.svg',
    'plug-amazon-smartplug-frozen.svg': 'plug-amazon-smartplug-frozen.svg',
    'plug-amazon-smartplug.svg': 'plug-amazon-smartplug.svg',
    'plug-apc-wifipowerstrip-frozen.svg': 'plug-apc-wifipowerstrip-frozen.svg',
    'plug-apc-wifipowerstrip.svg': 'plug-apc-wifipowerstrip.svg',
    'plug-belkin-wemo-frozen.svg': 'plug-belkin-wemo-frozen.svg',
    'plug-belkin-wemo.svg': 'plug-belkin-wemo.svg',
    'plug-belkin-wemosmartplug-frozen.svg':
      'plug-belkin-wemosmartplug-frozen.svg',
    'plug-belkin-wemosmartplug.svg': 'plug-belkin-wemosmartplug.svg',
    'plug-broadlink-smartplug-frozen.svg':
      'plug-broadlink-smartplug-frozen.svg',
    'plug-broadlink-smartplug.svg': 'plug-broadlink-smartplug.svg',
    'plug-buddee-smartplug-frozen.svg': 'plug-buddee-smartplug-frozen.svg',
    'plug-buddee-smartplug.svg': 'plug-buddee-smartplug.svg',
    'plug-cbyge-smartplug-frozen.svg': 'plug-cbyge-smartplug-frozen.svg',
    'plug-cbyge-smartplug.svg': 'plug-cbyge-smartplug.svg',
    'plug-connectsense-smartinwalloutlet-1.svg':
      'plug-connectsense-smartinwalloutlet-1.svg',
    'plug-connectsense-smartinwalloutlet-frozen-1.svg':
      'plug-connectsense-smartinwalloutlet-frozen-1.svg',
    'plug-connectsense-smartinwalloutlet-frozen.svg':
      'plug-connectsense-smartinwalloutlet-frozen.svg',
    'plug-connectsense-smartinwalloutlet.svg':
      'plug-connectsense-smartinwalloutlet.svg',
    'plug-connectsense-smartoutlet-frozen.svg':
      'plug-connectsense-smartoutlet-frozen.svg',
    'plug-connectsense-smartoutlet.svg': 'plug-connectsense-smartoutlet.svg',
    'plug-currant-wifismartoutlet-frozen.svg':
      'plug-currant-wifismartoutlet-frozen.svg',
    'plug-currant-wifismartoutlet.svg': 'plug-currant-wifismartoutlet.svg',
    'plug-ecoplugs-smartplug-frozen.svg': 'plug-ecoplugs-smartplug-frozen.svg',
    'plug-ecoplugs-smartplug.svg': 'plug-ecoplugs-smartplug.svg',
    'plug-etekcity-ihomesmartplug-frozen.svg':
      'plug-etekcity-ihomesmartplug-frozen.svg',
    'plug-etekcity-ihomesmartplug.svg': 'plug-etekcity-ihomesmartplug.svg',
    'plug-etekcity-voltsonsmartplug-frozen.svg':
      'plug-etekcity-voltsonsmartplug-frozen.svg',
    'plug-etekcity-voltsonsmartplug.svg': 'plug-etekcity-voltsonsmartplug.svg',
    'plug-frozen.svg': 'plug-frozen.svg',
    'plug-geeni-spot-frozen.svg': 'plug-geeni-spot-frozen.svg',
    'plug-geeni-spot.svg': 'plug-geeni-spot.svg',
    'plug-idevices-socket-frozen.svg': 'plug-idevices-socket-frozen.svg',
    'plug-idevices-socket.svg': 'plug-idevices-socket.svg',
    'plug-idevices-switch-frozen.svg': 'plug-idevices-switch-frozen.svg',
    'plug-idevices-switch.svg': 'plug-idevices-switch.svg',
    'plug-idevices-walloutlet-frozen.svg':
      'plug-idevices-walloutlet-frozen.svg',
    'plug-idevices-walloutlet.svg': 'plug-idevices-walloutlet.svg',
    'plug-ihome-smartplug-frozen.svg': 'plug-ihome-smartplug-frozen.svg',
    'plug-ihome-smartplug.svg': 'plug-ihome-smartplug.svg',
    'plug-iris-smartplug-frozen.svg': 'plug-iris-smartplug-frozen.svg',
    'plug-iris-smartplug.svg': 'plug-iris-smartplug.svg',
    'plug-konke-miniussmartplug-frozen.svg':
      'plug-konke-miniussmartplug-frozen.svg',
    'plug-konke-miniussmartplug.svg': 'plug-konke-miniussmartplug.svg',
    'plug-koogeek-frozen.svg': 'plug-koogeek-frozen.svg',
    'plug-koogeek-outlet-frozen.svg': 'plug-koogeek-outlet-frozen.svg',
    'plug-koogeek-outlet.svg': 'plug-koogeek-outlet.svg',
    'plug-koogeek-socket-frozen.svg': 'plug-koogeek-socket-frozen.svg',
    'plug-koogeek-socket.svg': 'plug-koogeek-socket.svg',
    'plug-koogeek.svg': 'plug-koogeek.svg',
    'plug-meross-smartsurgeprotector-frozen.svg':
      'plug-meross-smartsurgeprotector-frozen.svg',
    'plug-meross-smartsurgeprotector.svg':
      'plug-meross-smartsurgeprotector.svg',
    'plug-meross-smartwifiplug-frozen.svg':
      'plug-meross-smartwifiplug-frozen.svg',
    'plug-meross-smartwifiplug.svg': 'plug-meross-smartwifiplug.svg',
    'plug-meross-smartwifiwallswitch-frozen.svg':
      'plug-meross-smartwifiwallswitch-frozen.svg',
    'plug-meross-smartwifiwallswitch.svg':
      'plug-meross-smartwifiwallswitch.svg',
    'plug-mobvoi-smartplug-frozen.svg': 'plug-mobvoi-smartplug-frozen.svg',
    'plug-mobvoi-smartplug.svg': 'plug-mobvoi-smartplug.svg',
    'plug-mystrom-wifiswitch-frozen.svg': 'plug-mystrom-wifiswitch-frozen.svg',
    'plug-mystrom-wifiswitch.svg': 'plug-mystrom-wifiswitch.svg',
    'plug-mytouchsmart-smartplug-frozen.svg':
      'plug-mytouchsmart-smartplug-frozen.svg',
    'plug-mytouchsmart-smartplug.svg': 'plug-mytouchsmart-smartplug.svg',
    'plug-nest-connect-frozen.svg': 'plug-nest-connect-frozen.svg',
    'plug-nest-connect.svg': 'plug-nest-connect.svg',
    'plug-nexx-smartplug-frozen.svg': 'plug-nexx-smartplug-frozen.svg',
    'plug-nexx-smartplug.svg': 'plug-nexx-smartplug.svg',
    'plug-opso-hkou1-frozen.svg': 'plug-opso-hkou1-frozen.svg',
    'plug-opso-hkou1.svg': 'plug-opso-hkou1.svg',
    'plug-pumpspy-smartoutlet-frozen.svg':
      'plug-pumpspy-smartoutlet-frozen.svg',
    'plug-pumpspy-smartoutlet.svg': 'plug-pumpspy-smartoutlet.svg',
    'plug-samsung-smartthingsoutlet-frozen.svg':
      'plug-samsung-smartthingsoutlet-frozen.svg',
    'plug-samsung-smartthingsoutlet.svg': 'plug-samsung-smartthingsoutlet.svg',
    'plug-samsung-smartthingssmartplug-frozen.svg':
      'plug-samsung-smartthingssmartplug-frozen.svg',
    'plug-samsung-smartthingssmartplug.svg':
      'plug-samsung-smartthingssmartplug.svg',
    'plug-satechi-dualoutlet-frozen.svg': 'plug-satechi-dualoutlet-frozen.svg',
    'plug-satechi-dualoutlet.svg': 'plug-satechi-dualoutlet.svg',
    'plug-shelly-frozen.svg': 'plug-shelly-frozen.svg',
    'plug-shelly.svg': 'plug-shelly.svg',
    'plug-smartlife-smartplug-frozen.svg':
      'plug-smartlife-smartplug-frozen.svg',
    'plug-smartlife-smartplug.svg': 'plug-smartlife-smartplug.svg',
    'plug-snapav-wattbox-frozen.svg': 'plug-snapav-wattbox-frozen.svg',
    'plug-snapav-wattbox.svg': 'plug-snapav-wattbox.svg',
    'plug-switchmate-powersmartplug-frozen.svg':
      'plug-switchmate-powersmartplug-frozen.svg',
    'plug-switchmate-powersmartplug.svg': 'plug-switchmate-powersmartplug.svg',
    'plug-teckin-smartplug-frozen.svg': 'plug-teckin-smartplug-frozen.svg',
    'plug-teckin-smartplug.svg': 'plug-teckin-smartplug.svg',
    'plug-thinkeco-smartacplug-frozen.svg':
      'plug-thinkeco-smartacplug-frozen.svg',
    'plug-thinkeco-smartacplug.svg': 'plug-thinkeco-smartacplug.svg',
    'plug-tplink-frozen.svg': 'plug-tplink-frozen.svg',
    'plug-tplink-kasa-frozen.svg': 'plug-tplink-kasa-frozen.svg',
    'plug-tplink-kasa.svg': 'plug-tplink-kasa.svg',
    'plug-tplink-kasaoutlet-frozen.svg': 'plug-tplink-kasaoutlet-frozen.svg',
    'plug-tplink-kasaoutlet.svg': 'plug-tplink-kasaoutlet.svg',
    'plug-tplink-kasawifiplug-frozen.svg':
      'plug-tplink-kasawifiplug-frozen.svg',
    'plug-tplink-kasawifiplug.svg': 'plug-tplink-kasawifiplug.svg',
    'plug-tplink-kasawifipowerstrip-frozen.svg':
      'plug-tplink-kasawifipowerstrip-frozen.svg',
    'plug-tplink-kasawifipowerstrip.svg': 'plug-tplink-kasawifipowerstrip.svg',
    'plug-tplink-powerline-frozen.svg': 'plug-tplink-powerline-frozen.svg',
    'plug-tplink-powerline.svg': 'plug-tplink-powerline.svg',
    'plug-tplink-taposmartplug-frozen.svg':
      'plug-tplink-taposmartplug-frozen.svg',
    'plug-tplink-taposmartplug.svg': 'plug-tplink-taposmartplug.svg',
    'plug-tplink.svg': 'plug-tplink.svg',
    'plug-ubiquiti-unifi-frozen.svg': 'plug-ubiquiti-unifi-frozen.svg',
    'plug-ubiquiti-unifi.svg': 'plug-ubiquiti-unifi.svg',
    'plug-vocolinc-powerstrip-frozen.svg':
      'plug-vocolinc-powerstrip-frozen.svg',
    'plug-vocolinc-powerstrip.svg': 'plug-vocolinc-powerstrip.svg',
    'plug-vocolinc-smartbar-frozen.svg': 'plug-vocolinc-smartbar-frozen.svg',
    'plug-vocolinc-smartbar.svg': 'plug-vocolinc-smartbar.svg',
    'plug-vocolinc-smartoutlet-frozen.svg':
      'plug-vocolinc-smartoutlet-frozen.svg',
    'plug-vocolinc-smartoutlet.svg': 'plug-vocolinc-smartoutlet.svg',
    'plug-vocolinc-vp5-frozen.svg': 'plug-vocolinc-vp5-frozen.svg',
    'plug-vocolinc-vp5.svg': 'plug-vocolinc-vp5.svg',
    'plug-wion-smartplug-frozen.svg': 'plug-wion-smartplug-frozen.svg',
    'plug-wion-smartplug.svg': 'plug-wion-smartplug.svg',
    'plug-wyze-smartplug-frozen.svg': 'plug-wyze-smartplug-frozen.svg',
    'plug-wyze-smartplug.svg': 'plug-wyze-smartplug.svg',
    'plug-xiaomi-mismartplug-frozen.svg': 'plug-xiaomi-mismartplug-frozen.svg',
    'plug-xiaomi-mismartplug.svg': 'plug-xiaomi-mismartplug.svg',
    'plug.svg': 'plug.svg',
    'pod-frozen.svg': 'pod-frozen.svg',
    'pod.svg': 'pod.svg',
    'pointofsale-clover-flex-frozen.svg': 'pointofsale-clover-flex-frozen.svg',
    'pointofsale-clover-flex.svg': 'pointofsale-clover-flex.svg',
    'pointofsale-clover-frozen.svg': 'pointofsale-clover-frozen.svg',
    'pointofsale-clover-mini-frozen.svg': 'pointofsale-clover-mini-frozen.svg',
    'pointofsale-clover-mini.svg': 'pointofsale-clover-mini.svg',
    'pointofsale-clover-station-frozen.svg':
      'pointofsale-clover-station-frozen.svg',
    'pointofsale-clover-station.svg': 'pointofsale-clover-station.svg',
    'pointofsale-clover.svg': 'pointofsale-clover.svg',
    'pointofsale-frozen.svg': 'pointofsale-frozen.svg',
    'pointofsale-ingenico-frozen.svg': 'pointofsale-ingenico-frozen.svg',
    'pointofsale-ingenico-smartpos-frozen.svg':
      'pointofsale-ingenico-smartpos-frozen.svg',
    'pointofsale-ingenico-smartpos.svg': 'pointofsale-ingenico-smartpos.svg',
    'pointofsale-ingenico.svg': 'pointofsale-ingenico.svg',
    'pointofsale-pax-a80-frozen.svg': 'pointofsale-pax-a80-frozen.svg',
    'pointofsale-pax-a80.svg': 'pointofsale-pax-a80.svg',
    'pointofsale-pax-a910-frozen.svg': 'pointofsale-pax-a910-frozen.svg',
    'pointofsale-pax-a910.svg': 'pointofsale-pax-a910.svg',
    'pointofsale-pax-a920-frozen.svg': 'pointofsale-pax-a920-frozen.svg',
    'pointofsale-pax-a920.svg': 'pointofsale-pax-a920.svg',
    'pointofsale-pax-a930-frozen.svg': 'pointofsale-pax-a930-frozen.svg',
    'pointofsale-pax-a930.svg': 'pointofsale-pax-a930.svg',
    'pointofsale-pax-frozen.svg': 'pointofsale-pax-frozen.svg',
    'pointofsale-pax-pos-frozen.svg': 'pointofsale-pax-pos-frozen.svg',
    'pointofsale-pax-pos.svg': 'pointofsale-pax-pos.svg',
    'pointofsale-pax-s80-frozen.svg': 'pointofsale-pax-s80-frozen.svg',
    'pointofsale-pax-s80.svg': 'pointofsale-pax-s80.svg',
    'pointofsale-pax.svg': 'pointofsale-pax.svg',
    'pointofsale-verifone-frozen.svg': 'pointofsale-verifone-frozen.svg',
    'pointofsale-verifone-pos-frozen.svg':
      'pointofsale-verifone-pos-frozen.svg',
    'pointofsale-verifone-pos.svg': 'pointofsale-verifone-pos.svg',
    'pointofsale-verifone.svg': 'pointofsale-verifone.svg',
    'pointofsale.svg': 'pointofsale.svg',
    'poolsystem-1.svg': 'poolsystem-1.svg',
    'poolsystem-frozen-1.svg': 'poolsystem-frozen-1.svg',
    'poolsystem-frozen.svg': 'poolsystem-frozen.svg',
    'poolsystem-iaqualink-frozen.svg': 'poolsystem-iaqualink-frozen.svg',
    'poolsystem-iaqualink.svg': 'poolsystem-iaqualink.svg',
    'poolsystem-pentair-intellichlor-frozen.svg':
      'poolsystem-pentair-intellichlor-frozen.svg',
    'poolsystem-pentair-intellichlor.svg':
      'poolsystem-pentair-intellichlor.svg',
    'poolsystem-pentair-poolcontrolsystem-frozen.svg':
      'poolsystem-pentair-poolcontrolsystem-frozen.svg',
    'poolsystem-pentair-poolcontrolsystem.svg':
      'poolsystem-pentair-poolcontrolsystem.svg',
    'poolsystem-waterguru-sense-frozen.svg':
      'poolsystem-waterguru-sense-frozen.svg',
    'poolsystem-waterguru-sense.svg': 'poolsystem-waterguru-sense.svg',
    'poolsystem.svg': 'poolsystem.svg',
    'printer-brother-frozen.svg': 'printer-brother-frozen.svg',
    'printer-brother.svg': 'printer-brother.svg',
    'printer-canon-frozen.svg': 'printer-canon-frozen.svg',
    'printer-canon-photoprinter-frozen.svg':
      'printer-canon-photoprinter-frozen.svg',
    'printer-canon-photoprinter.svg': 'printer-canon-photoprinter.svg',
    'printer-canon-pixma-frozen.svg': 'printer-canon-pixma-frozen.svg',
    'printer-canon-pixma.svg': 'printer-canon-pixma.svg',
    'printer-canon.svg': 'printer-canon.svg',
    'printer-canon2-frozen.svg': 'printer-canon2-frozen.svg',
    'printer-canon2.svg': 'printer-canon2.svg',
    'printer-creality-cr10s-frozen.svg': 'printer-creality-cr10s-frozen.svg',
    'printer-creality-cr10s.svg': 'printer-creality-cr10s.svg',
    'printer-creality-ender-frozen.svg': 'printer-creality-ender-frozen.svg',
    'printer-creality-ender.svg': 'printer-creality-ender.svg',
    'printer-dell-frozen.svg': 'printer-dell-frozen.svg',
    'printer-dell.svg': 'printer-dell.svg',
    'printer-epson-frozen.svg': 'printer-epson-frozen.svg',
    'printer-epson.svg': 'printer-epson.svg',
    'printer-frozen.svg': 'printer-frozen.svg',
    'printer-glowforge-3dprinter-frozen.svg':
      'printer-glowforge-3dprinter-frozen.svg',
    'printer-glowforge-3dprinter.svg': 'printer-glowforge-3dprinter.svg',
    'printer-hp-frozen.svg': 'printer-hp-frozen.svg',
    'printer-hp.svg': 'printer-hp.svg',
    'printer-kodak-verite-frozen.svg': 'printer-kodak-verite-frozen.svg',
    'printer-kodak-verite.svg': 'printer-kodak-verite.svg',
    'printer-panasonic-fax-frozen.svg': 'printer-panasonic-fax-frozen.svg',
    'printer-panasonic-fax.svg': 'printer-panasonic-fax.svg',
    'printer-pantum-frozen.svg': 'printer-pantum-frozen.svg',
    'printer-pantum.svg': 'printer-pantum.svg',
    'printer-ricoh-frozen.svg': 'printer-ricoh-frozen.svg',
    'printer-ricoh-sp-frozen.svg': 'printer-ricoh-sp-frozen.svg',
    'printer-ricoh-sp.svg': 'printer-ricoh-sp.svg',
    'printer-ricoh.svg': 'printer-ricoh.svg',
    'printer-samsung-frozen.svg': 'printer-samsung-frozen.svg',
    'printer-samsung.svg': 'printer-samsung.svg',
    'printer-toybox-3dprinter-frozen.svg':
      'printer-toybox-3dprinter-frozen.svg',
    'printer-toybox-3dprinter.svg': 'printer-toybox-3dprinter.svg',
    'printer-ultimaker-3dprinter-frozen.svg':
      'printer-ultimaker-3dprinter-frozen.svg',
    'printer-ultimaker-3dprinter.svg': 'printer-ultimaker-3dprinter.svg',
    'printer-xerox-frozen.svg': 'printer-xerox-frozen.svg',
    'printer-xerox-phaser-frozen.svg': 'printer-xerox-phaser-frozen.svg',
    'printer-xerox-phaser.svg': 'printer-xerox-phaser.svg',
    'printer-xerox.svg': 'printer-xerox.svg',
    'printer.svg': 'printer.svg',
    'projector-epson-frozen.svg': 'projector-epson-frozen.svg',
    'projector-epson.svg': 'projector-epson.svg',
    'projector-frozen.svg': 'projector-frozen.svg',
    'projector.svg': 'projector.svg',
    'rangeextender-arris-frozen.svg': 'rangeextender-arris-frozen.svg',
    'rangeextender-arris-gca2002-frozen.svg':
      'rangeextender-arris-gca2002-frozen.svg',
    'rangeextender-arris-gca2002.svg': 'rangeextender-arris-gca2002.svg',
    'rangeextender-arris-gpe1003-frozen.svg':
      'rangeextender-arris-gpe1003-frozen.svg',
    'rangeextender-arris-gpe1003.svg': 'rangeextender-arris-gpe1003.svg',
    'rangeextender-arris-gpe2001-frozen.svg':
      'rangeextender-arris-gpe2001-frozen.svg',
    'rangeextender-arris-gpe2001.svg': 'rangeextender-arris-gpe2001.svg',
    'rangeextender-arris.svg': 'rangeextender-arris.svg',
    'rangeextender-asus-n300repeater-frozen.svg':
      'rangeextender-asus-n300repeater-frozen.svg',
    'rangeextender-asus-n300repeater.svg':
      'rangeextender-asus-n300repeater.svg',
    'rangeextender-dlink-wifiextender-frozen.svg':
      'rangeextender-dlink-wifiextender-frozen.svg',
    'rangeextender-dlink-wifiextender.svg':
      'rangeextender-dlink-wifiextender.svg',
    'rangeextender-frozen.svg': 'rangeextender-frozen.svg',
    'rangeextender.svg': 'rangeextender.svg',
    'rangestove-frozen.svg': 'rangestove-frozen.svg',
    'rangestove.svg': 'rangestove.svg',
    'rings-charger-frozen.svg': 'rings-charger-frozen.svg',
    'rings-charger.svg': 'rings-charger.svg',
    'rings-frozen.svg': 'rings-frozen.svg',
    'rings.svg': 'rings.svg',
    'robotvacuumcleaner-360-frozen.svg': 'robotvacuumcleaner-360-frozen.svg',
    'robotvacuumcleaner-360-robotvacuumcleaner-frozen.svg':
      'robotvacuumcleaner-360-robotvacuumcleaner-frozen.svg',
    'robotvacuumcleaner-360-robotvacuumcleaner.svg':
      'robotvacuumcleaner-360-robotvacuumcleaner.svg',
    'robotvacuumcleaner-360.svg': 'robotvacuumcleaner-360.svg',
    'robotvacuumcleaner-bissell-frozen.svg':
      'robotvacuumcleaner-bissell-frozen.svg',
    'robotvacuumcleaner-bissell-robotvacuum-frozen.svg':
      'robotvacuumcleaner-bissell-robotvacuum-frozen.svg',
    'robotvacuumcleaner-bissell-robotvacuum.svg':
      'robotvacuumcleaner-bissell-robotvacuum.svg',
    'robotvacuumcleaner-bissell.svg': 'robotvacuumcleaner-bissell.svg',
    'robotvacuumcleaner-dyson-360-frozen.svg':
      'robotvacuumcleaner-dyson-360-frozen.svg',
    'robotvacuumcleaner-dyson-360.svg': 'robotvacuumcleaner-dyson-360.svg',
    'robotvacuumcleaner-dyson-frozen.svg':
      'robotvacuumcleaner-dyson-frozen.svg',
    'robotvacuumcleaner-dyson.svg': 'robotvacuumcleaner-dyson.svg',
    'robotvacuumcleaner-ecovacs-deebot-frozen.svg':
      'robotvacuumcleaner-ecovacs-deebot-frozen.svg',
    'robotvacuumcleaner-ecovacs-deebot.svg':
      'robotvacuumcleaner-ecovacs-deebot.svg',
    'robotvacuumcleaner-ecovacs-frozen.svg':
      'robotvacuumcleaner-ecovacs-frozen.svg',
    'robotvacuumcleaner-ecovacs.svg': 'robotvacuumcleaner-ecovacs.svg',
    'robotvacuumcleaner-electrolux-frozen.svg':
      'robotvacuumcleaner-electrolux-frozen.svg',
    'robotvacuumcleaner-electrolux-pure-frozen.svg':
      'robotvacuumcleaner-electrolux-pure-frozen.svg',
    'robotvacuumcleaner-electrolux-pure.svg':
      'robotvacuumcleaner-electrolux-pure.svg',
    'robotvacuumcleaner-electrolux.svg': 'robotvacuumcleaner-electrolux.svg',
    'robotvacuumcleaner-eufy-frozen.svg': 'robotvacuumcleaner-eufy-frozen.svg',
    'robotvacuumcleaner-eufy-robovac-frozen.svg':
      'robotvacuumcleaner-eufy-robovac-frozen.svg',
    'robotvacuumcleaner-eufy-robovac.svg':
      'robotvacuumcleaner-eufy-robovac.svg',
    'robotvacuumcleaner-eufy.svg': 'robotvacuumcleaner-eufy.svg',
    'robotvacuumcleaner-frozen.svg': 'robotvacuumcleaner-frozen.svg',
    'robotvacuumcleaner-irobot-frozen.svg':
      'robotvacuumcleaner-irobot-frozen.svg',
    'robotvacuumcleaner-irobot-roomba-frozen.svg':
      'robotvacuumcleaner-irobot-roomba-frozen.svg',
    'robotvacuumcleaner-irobot-roomba.svg':
      'robotvacuumcleaner-irobot-roomba.svg',
    'robotvacuumcleaner-irobot.svg': 'robotvacuumcleaner-irobot.svg',
    'robotvacuumcleaner-narwal-frozen.svg':
      'robotvacuumcleaner-narwal-frozen.svg',
    'robotvacuumcleaner-narwal-robotmop-frozen.svg':
      'robotvacuumcleaner-narwal-robotmop-frozen.svg',
    'robotvacuumcleaner-narwal-robotmop.svg':
      'robotvacuumcleaner-narwal-robotmop.svg',
    'robotvacuumcleaner-narwal.svg': 'robotvacuumcleaner-narwal.svg',
    'robotvacuumcleaner-neabot-frozen.svg':
      'robotvacuumcleaner-neabot-frozen.svg',
    'robotvacuumcleaner-neabot-robotvacuum-frozen.svg':
      'robotvacuumcleaner-neabot-robotvacuum-frozen.svg',
    'robotvacuumcleaner-neabot-robotvacuum.svg':
      'robotvacuumcleaner-neabot-robotvacuum.svg',
    'robotvacuumcleaner-neabot.svg': 'robotvacuumcleaner-neabot.svg',
    'robotvacuumcleaner-neato-botvac-frozen.svg':
      'robotvacuumcleaner-neato-botvac-frozen.svg',
    'robotvacuumcleaner-neato-botvac.svg':
      'robotvacuumcleaner-neato-botvac.svg',
    'robotvacuumcleaner-neato-frozen.svg':
      'robotvacuumcleaner-neato-frozen.svg',
    'robotvacuumcleaner-neato.svg': 'robotvacuumcleaner-neato.svg',
    'robotvacuumcleaner-plus360-frozen.svg':
      'robotvacuumcleaner-plus360-frozen.svg',
    'robotvacuumcleaner-plus360-s7-frozen.svg':
      'robotvacuumcleaner-plus360-s7-frozen.svg',
    'robotvacuumcleaner-plus360-s7.svg': 'robotvacuumcleaner-plus360-s7.svg',
    'robotvacuumcleaner-plus360.svg': 'robotvacuumcleaner-plus360.svg',
    'robotvacuumcleaner-samsung-frozen.svg':
      'robotvacuumcleaner-samsung-frozen.svg',
    'robotvacuumcleaner-samsung-powerbotvacuum-frozen.svg':
      'robotvacuumcleaner-samsung-powerbotvacuum-frozen.svg',
    'robotvacuumcleaner-samsung-powerbotvacuum.svg':
      'robotvacuumcleaner-samsung-powerbotvacuum.svg',
    'robotvacuumcleaner-samsung.svg': 'robotvacuumcleaner-samsung.svg',
    'robotvacuumcleaner-shark-frozen.svg':
      'robotvacuumcleaner-shark-frozen.svg',
    'robotvacuumcleaner-shark-robotvacuum-frozen.svg':
      'robotvacuumcleaner-shark-robotvacuum-frozen.svg',
    'robotvacuumcleaner-shark-robotvacuum.svg':
      'robotvacuumcleaner-shark-robotvacuum.svg',
    'robotvacuumcleaner-shark.svg': 'robotvacuumcleaner-shark.svg',
    'robotvacuumcleaner-viomi-frozen.svg':
      'robotvacuumcleaner-viomi-frozen.svg',
    'robotvacuumcleaner-viomi-robotvacuum-frozen.svg':
      'robotvacuumcleaner-viomi-robotvacuum-frozen.svg',
    'robotvacuumcleaner-viomi-robotvacuum.svg':
      'robotvacuumcleaner-viomi-robotvacuum.svg',
    'robotvacuumcleaner-viomi.svg': 'robotvacuumcleaner-viomi.svg',
    'robotvacuumcleaner-xiaomi-frozen.svg':
      'robotvacuumcleaner-xiaomi-frozen.svg',
    'robotvacuumcleaner-xiaomi-mi-frozen.svg':
      'robotvacuumcleaner-xiaomi-mi-frozen.svg',
    'robotvacuumcleaner-xiaomi-mi.svg': 'robotvacuumcleaner-xiaomi-mi.svg',
    'robotvacuumcleaner-xiaomi-roborock-frozen.svg':
      'robotvacuumcleaner-xiaomi-roborock-frozen.svg',
    'robotvacuumcleaner-xiaomi-roborock.svg':
      'robotvacuumcleaner-xiaomi-roborock.svg',
    'robotvacuumcleaner-xiaomi.svg': 'robotvacuumcleaner-xiaomi.svg',
    'robotvacuumcleaner.svg': 'robotvacuumcleaner.svg',
    'rotimatic-frozen.svg': 'rotimatic-frozen.svg',
    'rotimatic.svg': 'rotimatic.svg',
    'router-1.svg': 'router-1.svg',
    'router-814v6-frozen.svg': 'router-814v6-frozen.svg',
    'router-814v6.svg': 'router-814v6.svg',
    'router-actiontec-frozen.svg': 'router-actiontec-frozen.svg',
    'router-actiontec-web6000q-frozen.svg':
      'router-actiontec-web6000q-frozen.svg',
    'router-actiontec-web6000q.svg': 'router-actiontec-web6000q.svg',
    'router-actiontec.svg': 'router-actiontec.svg',
    'router-adtran-netvanta-frozen.svg': 'router-adtran-netvanta-frozen.svg',
    'router-adtran-netvanta.svg': 'router-adtran-netvanta.svg',
    'router-adtran-sdg814v6-frozen.svg': 'router-adtran-sdg814v6-frozen.svg',
    'router-adtran-sdg814v6.svg': 'router-adtran-sdg814v6.svg',
    'router-airties-frozen.svg': 'router-airties-frozen.svg',
    'router-airties.svg': 'router-airties.svg',
    'router-apple-airport-frozen.svg': 'router-apple-airport-frozen.svg',
    'router-apple-airport.svg': 'router-apple-airport.svg',
    'router-araknis-accesspoint-frozen.svg':
      'router-araknis-accesspoint-frozen.svg',
    'router-araknis-accesspoint.svg': 'router-araknis-accesspoint.svg',
    'router-araknis-networkswitch-frozen.svg':
      'router-araknis-networkswitch-frozen.svg',
    'router-araknis-networkswitch.svg': 'router-araknis-networkswitch.svg',
    'router-araknis-router-frozen.svg': 'router-araknis-router-frozen.svg',
    'router-araknis-router.svg': 'router-araknis-router.svg',
    'router-arris-gca2002-frozen.svg': 'router-arris-gca2002-frozen.svg',
    'router-arris-gca2002.svg': 'router-arris-gca2002.svg',
    'router-arris-gpe1003-frozen.svg': 'router-arris-gpe1003-frozen.svg',
    'router-arris-gpe1003.svg': 'router-arris-gpe1003.svg',
    'router-arris-router-frozen.svg': 'router-arris-router-frozen.svg',
    'router-arris-router.svg': 'router-arris-router.svg',
    'router-aruba-ap-frozen.svg': 'router-aruba-ap-frozen.svg',
    'router-aruba-ap.svg': 'router-aruba-ap.svg',
    'router-asus-frozen.svg': 'router-asus-frozen.svg',
    'router-asus-wirelessrouter-frozen.svg':
      'router-asus-wirelessrouter-frozen.svg',
    'router-asus-wirelessrouter.svg': 'router-asus-wirelessrouter.svg',
    'router-asus.svg': 'router-asus.svg',
    'router-att-uverse-frozen.svg': 'router-att-uverse-frozen.svg',
    'router-att-uverse.svg': 'router-att-uverse.svg',
    'router-avm-fritzbox-frozen.svg': 'router-avm-fritzbox-frozen.svg',
    'router-avm-fritzbox.svg': 'router-avm-fritzbox.svg',
    'router-belkin-frozen.svg': 'router-belkin-frozen.svg',
    'router-belkin.svg': 'router-belkin.svg',
    'router-bt-minihotspot-frozen.svg': 'router-bt-minihotspot-frozen.svg',
    'router-bt-minihotspot.svg': 'router-bt-minihotspot.svg',
    'router-cambium-cnpilot-frozen.svg': 'router-cambium-cnpilot-frozen.svg',
    'router-cambium-cnpilot.svg': 'router-cambium-cnpilot.svg',
    'router-cisco-encs-frozen.svg': 'router-cisco-encs-frozen.svg',
    'router-cisco-encs.svg': 'router-cisco-encs.svg',
    'router-cisco-meraki-frozen.svg': 'router-cisco-meraki-frozen.svg',
    'router-cisco-meraki.svg': 'router-cisco-meraki.svg',
    'router-cisco-rf-1.svg': 'router-cisco-rf-1.svg',
    'router-cisco-rf-2.svg': 'router-cisco-rf-2.svg',
    'router-cisco-rf-frozen-1.svg': 'router-cisco-rf-frozen-1.svg',
    'router-cisco-rf-frozen-2.svg': 'router-cisco-rf-frozen-2.svg',
    'router-cisco-rf-frozen.svg': 'router-cisco-rf-frozen.svg',
    'router-cisco-rf.svg': 'router-cisco-rf.svg',
    'router-creston-cenioir104-frozen.svg':
      'router-creston-cenioir104-frozen.svg',
    'router-creston-cenioir104.svg': 'router-creston-cenioir104.svg',
    'router-crestron-cengwexer-frozen.svg':
      'router-crestron-cengwexer-frozen.svg',
    'router-crestron-cengwexer.svg': 'router-crestron-cengwexer.svg',
    'router-dlink-dirx1560-frozen.svg': 'router-dlink-dirx1560-frozen.svg',
    'router-dlink-dirx1560.svg': 'router-dlink-dirx1560.svg',
    'router-dlink-frozen.svg': 'router-dlink-frozen.svg',
    'router-dlink-wirelesshomecloudrouter-frozen.svg':
      'router-dlink-wirelesshomecloudrouter-frozen.svg',
    'router-dlink-wirelesshomecloudrouter.svg':
      'router-dlink-wirelesshomecloudrouter.svg',
    'router-dlink-wirelessngigabitrouter-frozen.svg':
      'router-dlink-wirelessngigabitrouter-frozen.svg',
    'router-dlink-wirelessngigabitrouter.svg':
      'router-dlink-wirelessngigabitrouter.svg',
    'router-dlink-wirelessnrouter-frozen.svg':
      'router-dlink-wirelessnrouter-frozen.svg',
    'router-dlink-wirelessnrouter.svg': 'router-dlink-wirelessnrouter.svg',
    'router-dlink-xtremenduo-frozen.svg': 'router-dlink-xtremenduo-frozen.svg',
    'router-dlink-xtremenduo.svg': 'router-dlink-xtremenduo.svg',
    'router-dlink.svg': 'router-dlink.svg',
    'router-elecom-router-frozen.svg': 'router-elecom-router-frozen.svg',
    'router-elecom-router.svg': 'router-elecom-router.svg',
    'router-fios-quantumgateway-frozen.svg':
      'router-fios-quantumgateway-frozen.svg',
    'router-fios-quantumgateway.svg': 'router-fios-quantumgateway.svg',
    'router-frozen-1.svg': 'router-frozen-1.svg',
    'router-frozen.svg': 'router-frozen.svg',
    'router-glinet-iotgateway-frozen.svg':
      'router-glinet-iotgateway-frozen.svg',
    'router-glinet-iotgateway.svg': 'router-glinet-iotgateway.svg',
    'router-glinet-meshwifi-frozen.svg': 'router-glinet-meshwifi-frozen.svg',
    'router-glinet-meshwifi.svg': 'router-glinet-meshwifi.svg',
    'router-glinet-minirouter-frozen.svg':
      'router-glinet-minirouter-frozen.svg',
    'router-glinet-minirouter.svg': 'router-glinet-minirouter.svg',
    'router-glinet-travelrouter-frozen.svg':
      'router-glinet-travelrouter-frozen.svg',
    'router-glinet-travelrouter.svg': 'router-glinet-travelrouter.svg',
    'router-google-fiber-frozen.svg': 'router-google-fiber-frozen.svg',
    'router-google-fiber.svg': 'router-google-fiber.svg',
    'router-hitron-gateway-frozen.svg': 'router-hitron-gateway-frozen.svg',
    'router-hitron-gateway.svg': 'router-hitron-gateway.svg',
    'router-huawei-frozen.svg': 'router-huawei-frozen.svg',
    'router-huawei.svg': 'router-huawei.svg',
    'router-iodata-airport-frozen.svg': 'router-iodata-airport-frozen.svg',
    'router-iodata-airport.svg': 'router-iodata-airport.svg',
    'router-iodata-wifirouter-frozen.svg':
      'router-iodata-wifirouter-frozen.svg',
    'router-iodata-wifirouter.svg': 'router-iodata-wifirouter.svg',
    'router-legrand-gateway-frozen.svg': 'router-legrand-gateway-frozen.svg',
    'router-legrand-gateway.svg': 'router-legrand-gateway.svg',
    'router-linksys-ethernetbridge-frozen.svg':
      'router-linksys-ethernetbridge-frozen.svg',
    'router-linksys-ethernetbridge.svg': 'router-linksys-ethernetbridge.svg',
    'router-linksys-frozen.svg': 'router-linksys-frozen.svg',
    'router-linksys-mediaconnector-frozen.svg':
      'router-linksys-mediaconnector-frozen.svg',
    'router-linksys-mediaconnector.svg': 'router-linksys-mediaconnector.svg',
    'router-linksys-wifirouter-frozen.svg':
      'router-linksys-wifirouter-frozen.svg',
    'router-linksys-wifirouter.svg': 'router-linksys-wifirouter.svg',
    'router-linksys.svg': 'router-linksys.svg',
    'router-luma-frozen.svg': 'router-luma-frozen.svg',
    'router-luma.svg': 'router-luma.svg',
    'router-luxul-xap-frozen.svg': 'router-luxul-xap-frozen.svg',
    'router-luxul-xap.svg': 'router-luxul-xap.svg',
    'router-mikrotik-router-frozen.svg': 'router-mikrotik-router-frozen.svg',
    'router-mikrotik-router.svg': 'router-mikrotik-router.svg',
    'router-mimosa-a5-frozen.svg': 'router-mimosa-a5-frozen.svg',
    'router-mimosa-a5.svg': 'router-mimosa-a5.svg',
    'router-nec-aterm-frozen.svg': 'router-nec-aterm-frozen.svg',
    'router-nec-aterm.svg': 'router-nec-aterm.svg',
    'router-netgear-ac1200-frozen.svg': 'router-netgear-ac1200-frozen.svg',
    'router-netgear-ac1200.svg': 'router-netgear-ac1200.svg',
    'router-netgear-dm200-frozen.svg': 'router-netgear-dm200-frozen.svg',
    'router-netgear-dm200.svg': 'router-netgear-dm200.svg',
    'router-netgear-ethernetswitch-frozen.svg':
      'router-netgear-ethernetswitch-frozen.svg',
    'router-netgear-ethernetswitch.svg': 'router-netgear-ethernetswitch.svg',
    'router-netgear-frozen.svg': 'router-netgear-frozen.svg',
    'router-netgear-nighthawk-frozen.svg':
      'router-netgear-nighthawk-frozen.svg',
    'router-netgear-nighthawk.svg': 'router-netgear-nighthawk.svg',
    'router-netgear-nighthawkrouter-frozen.svg':
      'router-netgear-nighthawkrouter-frozen.svg',
    'router-netgear-nighthawkrouter.svg': 'router-netgear-nighthawkrouter.svg',
    'router-netgear-powerline-frozen.svg':
      'router-netgear-powerline-frozen.svg',
    'router-netgear-powerline.svg': 'router-netgear-powerline.svg',
    'router-netgear-r6250-frozen.svg': 'router-netgear-r6250-frozen.svg',
    'router-netgear-r6250.svg': 'router-netgear-r6250.svg',
    'router-netgear-r6300-frozen.svg': 'router-netgear-r6300-frozen.svg',
    'router-netgear-r6300.svg': 'router-netgear-r6300.svg',
    'router-netgear-rangemax-frozen.svg': 'router-netgear-rangemax-frozen.svg',
    'router-netgear-rangemax.svg': 'router-netgear-rangemax.svg',
    'router-netgear-router-frozen.svg': 'router-netgear-router-frozen.svg',
    'router-netgear-router.svg': 'router-netgear-router.svg',
    'router-netgear-wifirouter-frozen.svg':
      'router-netgear-wifirouter-frozen.svg',
    'router-netgear-wifirouter.svg': 'router-netgear-wifirouter.svg',
    'router-netgear-wirelessn-frozen.svg':
      'router-netgear-wirelessn-frozen.svg',
    'router-netgear-wirelessn.svg': 'router-netgear-wirelessn.svg',
    'router-netgear-wirelessrouter-frozen.svg':
      'router-netgear-wirelessrouter-frozen.svg',
    'router-netgear-wirelessrouter.svg': 'router-netgear-wirelessrouter.svg',
    'router-netgear-wnce3001-frozen.svg': 'router-netgear-wnce3001-frozen.svg',
    'router-netgear-wnce3001.svg': 'router-netgear-wnce3001.svg',
    'router-netgear.svg': 'router-netgear.svg',
    'router-netgearnighthawk-progamingrouter-frozen.svg':
      'router-netgearnighthawk-progamingrouter-frozen.svg',
    'router-netgearnighthawk-progamingrouter.svg':
      'router-netgearnighthawk-progamingrouter.svg',
    'router-netgearnighthawk-smartwifirouter-frozen.svg':
      'router-netgearnighthawk-smartwifirouter-frozen.svg',
    'router-netgearnighthawk-smartwifirouter.svg':
      'router-netgearnighthawk-smartwifirouter.svg',
    'router-netgearnighthawk-wifirouter-frozen.svg':
      'router-netgearnighthawk-wifirouter-frozen.svg',
    'router-netgearnighthawk-wifirouter.svg':
      'router-netgearnighthawk-wifirouter.svg',
    'router-netgearorbi-frozen.svg': 'router-netgearorbi-frozen.svg',
    'router-netgearorbi-satellite-frozen.svg':
      'router-netgearorbi-satellite-frozen.svg',
    'router-netgearorbi-satellite.svg': 'router-netgearorbi-satellite.svg',
    'router-netgearorbi-wallplug-frozen.svg':
      'router-netgearorbi-wallplug-frozen.svg',
    'router-netgearorbi-wallplug.svg': 'router-netgearorbi-wallplug.svg',
    'router-netgearorbi-wifisystem-frozen.svg':
      'router-netgearorbi-wifisystem-frozen.svg',
    'router-netgearorbi-wifisystem.svg': 'router-netgearorbi-wifisystem.svg',
    'router-netgearorbi.svg': 'router-netgearorbi.svg',
    'router-openwrt-frozen.svg': 'router-openwrt-frozen.svg',
    'router-openwrt.svg': 'router-openwrt.svg',
    'router-pakedge-rk1-frozen.svg': 'router-pakedge-rk1-frozen.svg',
    'router-pakedge-rk1.svg': 'router-pakedge-rk1.svg',
    'router-panasonic-accesspoint-frozen.svg':
      'router-panasonic-accesspoint-frozen.svg',
    'router-panasonic-accesspoint.svg': 'router-panasonic-accesspoint.svg',
    'router-peplink-max-frozen.svg': 'router-peplink-max-frozen.svg',
    'router-peplink-max.svg': 'router-peplink-max.svg',
    'router-peplink-surfsoho-frozen.svg': 'router-peplink-surfsoho-frozen.svg',
    'router-peplink-surfsoho.svg': 'router-peplink-surfsoho.svg',
    'router-planet-wirelessrouter-frozen.svg':
      'router-planet-wirelessrouter-frozen.svg',
    'router-planet-wirelessrouter.svg': 'router-planet-wirelessrouter.svg',
    'router-rockspace-router-frozen.svg': 'router-rockspace-router-frozen.svg',
    'router-rockspace-router.svg': 'router-rockspace-router.svg',
    'router-ruckus-switch-frozen.svg': 'router-ruckus-switch-frozen.svg',
    'router-ruckus-switch.svg': 'router-ruckus-switch.svg',
    'router-ruckus-unleashed-frozen.svg': 'router-ruckus-unleashed-frozen.svg',
    'router-ruckus-unleashed.svg': 'router-ruckus-unleashed.svg',
    'router-samsung-connecthome-frozen.svg':
      'router-samsung-connecthome-frozen.svg',
    'router-samsung-connecthome.svg': 'router-samsung-connecthome.svg',
    'router-smartrg-sr400ac-frozen.svg': 'router-smartrg-sr400ac-frozen.svg',
    'router-smartrg-sr400ac.svg': 'router-smartrg-sr400ac.svg',
    'router-synology-frozen.svg': 'router-synology-frozen.svg',
    'router-synology.svg': 'router-synology.svg',
    'router-tplink-accesspoint-frozen.svg':
      'router-tplink-accesspoint-frozen.svg',
    'router-tplink-accesspoint.svg': 'router-tplink-accesspoint.svg',
    'router-tplink-ap-frozen.svg': 'router-tplink-ap-frozen.svg',
    'router-tplink-ap.svg': 'router-tplink-ap.svg',
    'router-tplink-archer-frozen.svg': 'router-tplink-archer-frozen.svg',
    'router-tplink-archer.svg': 'router-tplink-archer.svg',
    'router-tplink-deco-frozen.svg': 'router-tplink-deco-frozen.svg',
    'router-tplink-deco.svg': 'router-tplink-deco.svg',
    'router-tplink-frozen.svg': 'router-tplink-frozen.svg',
    'router-tplink-tlwa801nd-frozen.svg': 'router-tplink-tlwa801nd-frozen.svg',
    'router-tplink-tlwa801nd.svg': 'router-tplink-tlwa801nd.svg',
    'router-tplink-tlwa901nd-frozen.svg': 'router-tplink-tlwa901nd-frozen.svg',
    'router-tplink-tlwa901nd.svg': 'router-tplink-tlwa901nd.svg',
    'router-tplink-tlwr802n-frozen.svg': 'router-tplink-tlwr802n-frozen.svg',
    'router-tplink-tlwr802n.svg': 'router-tplink-tlwr802n.svg',
    'router-tplink-tlwr841nd-frozen.svg': 'router-tplink-tlwr841nd-frozen.svg',
    'router-tplink-tlwr841nd.svg': 'router-tplink-tlwr841nd.svg',
    'router-tplink-tlwr940n-frozen.svg': 'router-tplink-tlwr940n-frozen.svg',
    'router-tplink-tlwr940n.svg': 'router-tplink-tlwr940n.svg',
    'router-tplink-touch-frozen.svg': 'router-tplink-touch-frozen.svg',
    'router-tplink-touch.svg': 'router-tplink-touch.svg',
    'router-tplink.svg': 'router-tplink.svg',
    'router-trendnet-tew-frozen.svg': 'router-trendnet-tew-frozen.svg',
    'router-trendnet-tew.svg': 'router-trendnet-tew.svg',
    'router-trendnet-wirelessrouter-frozen.svg':
      'router-trendnet-wirelessrouter-frozen.svg',
    'router-trendnet-wirelessrouter.svg': 'router-trendnet-wirelessrouter.svg',
    'router-ubiquiti-edgerouter-frozen.svg':
      'router-ubiquiti-edgerouter-frozen.svg',
    'router-ubiquiti-edgerouter.svg': 'router-ubiquiti-edgerouter.svg',
    'router-ubiquiti-nanostation-frozen.svg':
      'router-ubiquiti-nanostation-frozen.svg',
    'router-ubiquiti-nanostation.svg': 'router-ubiquiti-nanostation.svg',
    'router-ubiquiti-unifi-frozen.svg': 'router-ubiquiti-unifi-frozen.svg',
    'router-ubiquiti-unifi.svg': 'router-ubiquiti-unifi.svg',
    'router-ubiquiti-usw-frozen.svg': 'router-ubiquiti-usw-frozen.svg',
    'router-ubiquiti-usw.svg': 'router-ubiquiti-usw.svg',
    'router-verizon-fios-frozen.svg': 'router-verizon-fios-frozen.svg',
    'router-verizon-fios.svg': 'router-verizon-fios.svg',
    'router-viasat-surfbeam-frozen.svg': 'router-viasat-surfbeam-frozen.svg',
    'router-viasat-surfbeam.svg': 'router-viasat-surfbeam.svg',
    'router-wifi6-frozen.svg': 'router-wifi6-frozen.svg',
    'router-wifi6.svg': 'router-wifi6.svg',
    'router-wodesys-rangeextender-frozen.svg':
      'router-wodesys-rangeextender-frozen.svg',
    'router-wodesys-rangeextender.svg': 'router-wodesys-rangeextender.svg',
    'router-xiaomi-aiot-frozen.svg': 'router-xiaomi-aiot-frozen.svg',
    'router-xiaomi-aiot.svg': 'router-xiaomi-aiot.svg',
    'router-xiaomi-miwifi-frozen.svg': 'router-xiaomi-miwifi-frozen.svg',
    'router-xiaomi-miwifi.svg': 'router-xiaomi-miwifi.svg',
    'router-zyxel-emg2926-frozen.svg': 'router-zyxel-emg2926-frozen.svg',
    'router-zyxel-emg2926.svg': 'router-zyxel-emg2926.svg',
    'router-zyxel-frozen.svg': 'router-zyxel-frozen.svg',
    'router-zyxel.svg': 'router-zyxel.svg',
    'router.svg': 'router.svg',
    'scale-fitbit-wifismartscale-frozen.svg':
      'scale-fitbit-wifismartscale-frozen.svg',
    'scale-fitbit-wifismartscale.svg': 'scale-fitbit-wifismartscale.svg',
    'scale-frozen.svg': 'scale-frozen.svg',
    'scale-qardio-qardiobase2-frozen.svg':
      'scale-qardio-qardiobase2-frozen.svg',
    'scale-qardio-qardiobase2.svg': 'scale-qardio-qardiobase2.svg',
    'scale-shenzhen-smartscale-frozen.svg':
      'scale-shenzhen-smartscale-frozen.svg',
    'scale-shenzhen-smartscale.svg': 'scale-shenzhen-smartscale.svg',
    'scale-withings-body+-frozen.svg': 'scale-withings-body+-frozen.svg',
    'scale-withings-body+.svg': 'scale-withings-body+.svg',
    'scale-withings-body-frozen.svg': 'scale-withings-body-frozen.svg',
    'scale-withings-body.svg': 'scale-withings-body.svg',
    'scale-withings-bodycardio-frozen.svg':
      'scale-withings-bodycardio-frozen.svg',
    'scale-withings-bodycardio.svg': 'scale-withings-bodycardio.svg',
    'scale-withings-frozen.svg': 'scale-withings-frozen.svg',
    'scale-withings.svg': 'scale-withings.svg',
    'scale-yolanda-frozen.svg': 'scale-yolanda-frozen.svg',
    'scale-yolanda.svg': 'scale-yolanda.svg',
    'scale-yunmaimini-smartscale-frozen.svg':
      'scale-yunmaimini-smartscale-frozen.svg',
    'scale-yunmaimini-smartscale.svg': 'scale-yunmaimini-smartscale.svg',
    'scale.svg': 'scale.svg',
    'sciencekit-anki-vectorrobot-frozen.svg':
      'sciencekit-anki-vectorrobot-frozen.svg',
    'sciencekit-anki-vectorrobot.svg': 'sciencekit-anki-vectorrobot.svg',
    'sciencekit-frozen.svg': 'sciencekit-frozen.svg',
    'sciencekit.svg': 'sciencekit.svg',
    'sensor-belkin-wemo-frozen.svg': 'sensor-belkin-wemo-frozen.svg',
    'sensor-belkin-wemo.svg': 'sensor-belkin-wemo.svg',
    'sensor-connectedlifelabs-smartdry-frozen.svg':
      'sensor-connectedlifelabs-smartdry-frozen.svg',
    'sensor-connectedlifelabs-smartdry.svg':
      'sensor-connectedlifelabs-smartdry.svg',
    'sensor-dahua-aixvr-frozen.svg': 'sensor-dahua-aixvr-frozen.svg',
    'sensor-dahua-aixvr.svg': 'sensor-dahua-aixvr.svg',
    'sensor-dlink-motionsensor-frozen.svg':
      'sensor-dlink-motionsensor-frozen.svg',
    'sensor-dlink-motionsensor.svg': 'sensor-dlink-motionsensor.svg',
    'sensor-dlink-mydlink-frozen.svg': 'sensor-dlink-mydlink-frozen.svg',
    'sensor-dlink-mydlink.svg': 'sensor-dlink-mydlink.svg',
    'sensor-dlink-watersensor-1.svg': 'sensor-dlink-watersensor-1.svg',
    'sensor-dlink-watersensor-frozen-1.svg':
      'sensor-dlink-watersensor-frozen-1.svg',
    'sensor-dlink-watersensor-frozen.svg':
      'sensor-dlink-watersensor-frozen.svg',
    'sensor-dlink-watersensor.svg': 'sensor-dlink-watersensor.svg',
    'sensor-escort-max-frozen.svg': 'sensor-escort-max-frozen.svg',
    'sensor-escort-max.svg': 'sensor-escort-max.svg',
    'sensor-flo-watermonitor-frozen.svg': 'sensor-flo-watermonitor-frozen.svg',
    'sensor-flo-watermonitor.svg': 'sensor-flo-watermonitor.svg',
    'sensor-frozen.svg': 'sensor-frozen.svg',
    'sensor-idealsciences-thermostat-frozen.svg':
      'sensor-idealsciences-thermostat-frozen.svg',
    'sensor-idealsciences-thermostat.svg':
      'sensor-idealsciences-thermostat.svg',
    'sensor-kangaroo-motionsensor-frozen.svg':
      'sensor-kangaroo-motionsensor-frozen.svg',
    'sensor-kangaroo-motionsensor.svg': 'sensor-kangaroo-motionsensor.svg',
    'sensor-kinetico-leaksensor-frozen.svg':
      'sensor-kinetico-leaksensor-frozen.svg',
    'sensor-kinetico-leaksensor.svg': 'sensor-kinetico-leaksensor.svg',
    'sensor-leakalert-wifiwatersensor-frozen.svg':
      'sensor-leakalert-wifiwatersensor-frozen.svg',
    'sensor-leakalert-wifiwatersensor.svg':
      'sensor-leakalert-wifiwatersensor.svg',
    'sensor-leakbot-watersensor-frozen.svg':
      'sensor-leakbot-watersensor-frozen.svg',
    'sensor-leakbot-watersensor.svg': 'sensor-leakbot-watersensor.svg',
    'sensor-leaksmart-leakdetector-frozen.svg':
      'sensor-leaksmart-leakdetector-frozen.svg',
    'sensor-leaksmart-leakdetector.svg': 'sensor-leaksmart-leakdetector.svg',
    'sensor-leeo-frozen.svg': 'sensor-leeo-frozen.svg',
    'sensor-leeo-smartalert-frozen.svg': 'sensor-leeo-smartalert-frozen.svg',
    'sensor-leeo-smartalert.svg': 'sensor-leeo-smartalert.svg',
    'sensor-leeo.svg': 'sensor-leeo.svg',
    'sensor-levelsense-sumppump-frozen.svg':
      'sensor-levelsense-sumppump-frozen.svg',
    'sensor-levelsense-sumppump.svg': 'sensor-levelsense-sumppump.svg',
    'sensor-myfox-homealarm-frozen.svg': 'sensor-myfox-homealarm-frozen.svg',
    'sensor-myfox-homealarm.svg': 'sensor-myfox-homealarm.svg',
    'sensor-noiseaware-noisemonitor-frozen.svg':
      'sensor-noiseaware-noisemonitor-frozen.svg',
    'sensor-noiseaware-noisemonitor.svg': 'sensor-noiseaware-noisemonitor.svg',
    'sensor-purpleair-sensor-frozen.svg': 'sensor-purpleair-sensor-frozen.svg',
    'sensor-purpleair-sensor.svg': 'sensor-purpleair-sensor.svg',
    'sensor-roost-frozen.svg': 'sensor-roost-frozen.svg',
    'sensor-roost.svg': 'sensor-roost.svg',
    'sensor-shelly-doorsensor-frozen.svg':
      'sensor-shelly-doorsensor-frozen.svg',
    'sensor-shelly-doorsensor.svg': 'sensor-shelly-doorsensor.svg',
    'sensor-shelly-doorwindow-frozen.svg':
      'sensor-shelly-doorwindow-frozen.svg',
    'sensor-shelly-doorwindow.svg': 'sensor-shelly-doorwindow.svg',
    'sensor-shelly-floodsensor-frozen.svg':
      'sensor-shelly-floodsensor-frozen.svg',
    'sensor-shelly-floodsensor.svg': 'sensor-shelly-floodsensor.svg',
    'sensor-shelly-humiditysensor-frozen.svg':
      'sensor-shelly-humiditysensor-frozen.svg',
    'sensor-shelly-humiditysensor.svg': 'sensor-shelly-humiditysensor.svg',
    'sensor-shelly-ledlightstrip-frozen.svg':
      'sensor-shelly-ledlightstrip-frozen.svg',
    'sensor-shelly-ledlightstrip.svg': 'sensor-shelly-ledlightstrip.svg',
    'sensor-ting-smartsensor-frozen.svg': 'sensor-ting-smartsensor-frozen.svg',
    'sensor-ting-smartsensor.svg': 'sensor-ting-smartsensor.svg',
    'sensor.svg': 'sensor.svg',
    'settopbox-a95x-f1-frozen.svg': 'settopbox-a95x-f1-frozen.svg',
    'settopbox-a95x-f1.svg': 'settopbox-a95x-f1.svg',
    'settopbox-a95x-r2-frozen.svg': 'settopbox-a95x-r2-frozen.svg',
    'settopbox-a95x-r2.svg': 'settopbox-a95x-r2.svg',
    'settopbox-a98x-settopbox-frozen.svg':
      'settopbox-a98x-settopbox-frozen.svg',
    'settopbox-a98x-settopbox.svg': 'settopbox-a98x-settopbox.svg',
    'settopbox-abox-tvbox-frozen.svg': 'settopbox-abox-tvbox-frozen.svg',
    'settopbox-abox-tvbox.svg': 'settopbox-abox-tvbox.svg',
    'settopbox-adb-settopbox-frozen.svg': 'settopbox-adb-settopbox-frozen.svg',
    'settopbox-adb-settopbox.svg': 'settopbox-adb-settopbox.svg',
    'settopbox-airtel-xstreamstick-frozen.svg':
      'settopbox-airtel-xstreamstick-frozen.svg',
    'settopbox-airtel-xstreamstick.svg': 'settopbox-airtel-xstreamstick.svg',
    'settopbox-airtv-3-frozen.svg': 'settopbox-airtv-3-frozen.svg',
    'settopbox-airtv-3.svg': 'settopbox-airtv-3.svg',
    'settopbox-amazon-firetv-frozen.svg': 'settopbox-amazon-firetv-frozen.svg',
    'settopbox-amazon-firetv.svg': 'settopbox-amazon-firetv.svg',
    'settopbox-amiko-viper-frozen.svg': 'settopbox-amiko-viper-frozen.svg',
    'settopbox-amiko-viper.svg': 'settopbox-amiko-viper.svg',
    'settopbox-amino-amigo-frozen.svg': 'settopbox-amino-amigo-frozen.svg',
    'settopbox-amino-amigo.svg': 'settopbox-amino-amigo.svg',
    'settopbox-amino-aminet-frozen.svg': 'settopbox-amino-aminet-frozen.svg',
    'settopbox-amino-aminet.svg': 'settopbox-amino-aminet.svg',
    'settopbox-apple-tv-frozen.svg': 'settopbox-apple-tv-frozen.svg',
    'settopbox-apple-tv.svg': 'settopbox-apple-tv.svg',
    'settopbox-arris-eossettopbox-frozen.svg':
      'settopbox-arris-eossettopbox-frozen.svg',
    'settopbox-arris-eossettopbox.svg': 'settopbox-arris-eossettopbox.svg',
    'settopbox-arris-settopbox-frozen.svg':
      'settopbox-arris-settopbox-frozen.svg',
    'settopbox-arris-settopbox.svg': 'settopbox-arris-settopbox.svg',
    'settopbox-arris-videoaccesspoint-frozen.svg':
      'settopbox-arris-videoaccesspoint-frozen.svg',
    'settopbox-arris-videoaccesspoint.svg':
      'settopbox-arris-videoaccesspoint.svg',
    'settopbox-att-tv-frozen.svg': 'settopbox-att-tv-frozen.svg',
    'settopbox-att-tv.svg': 'settopbox-att-tv.svg',
    'settopbox-att-uversedvr-frozen.svg': 'settopbox-att-uversedvr-frozen.svg',
    'settopbox-att-uversedvr.svg': 'settopbox-att-uversedvr.svg',
    'settopbox-avermedia-avertv-frozen.svg':
      'settopbox-avermedia-avertv-frozen.svg',
    'settopbox-avermedia-avertv.svg': 'settopbox-avermedia-avertv.svg',
    'settopbox-avov-tvln2r-frozen.svg': 'settopbox-avov-tvln2r-frozen.svg',
    'settopbox-avov-tvln2r.svg': 'settopbox-avov-tvln2r.svg',
    'settopbox-beelink-gt1-frozen.svg': 'settopbox-beelink-gt1-frozen.svg',
    'settopbox-beelink-gt1.svg': 'settopbox-beelink-gt1.svg',
    'settopbox-bell-dvr-frozen.svg': 'settopbox-bell-dvr-frozen.svg',
    'settopbox-bell-dvr.svg': 'settopbox-bell-dvr.svg',
    'settopbox-bell-wholehomepvr-1.svg': 'settopbox-bell-wholehomepvr-1.svg',
    'settopbox-bell-wholehomepvr-frozen-1.svg':
      'settopbox-bell-wholehomepvr-frozen-1.svg',
    'settopbox-bell-wholehomepvr-frozen.svg':
      'settopbox-bell-wholehomepvr-frozen.svg',
    'settopbox-bell-wholehomepvr.svg': 'settopbox-bell-wholehomepvr.svg',
    'settopbox-caavo-controlcenter-frozen.svg':
      'settopbox-caavo-controlcenter-frozen.svg',
    'settopbox-caavo-controlcenter.svg': 'settopbox-caavo-controlcenter.svg',
    'settopbox-cisco-settopbox-frozen.svg':
      'settopbox-cisco-settopbox-frozen.svg',
    'settopbox-cisco-settopbox.svg': 'settopbox-cisco-settopbox.svg',
    'settopbox-comcast-xfinity-frozen.svg':
      'settopbox-comcast-xfinity-frozen.svg',
    'settopbox-comcast-xfinity.svg': 'settopbox-comcast-xfinity.svg',
    'settopbox-comcast-xi-frozen.svg': 'settopbox-comcast-xi-frozen.svg',
    'settopbox-comcast-xi.svg': 'settopbox-comcast-xi.svg',
    'settopbox-cristor-atlas-frozen.svg': 'settopbox-cristor-atlas-frozen.svg',
    'settopbox-cristor-atlas.svg': 'settopbox-cristor-atlas.svg',
    'settopbox-denon-avreceiver-frozen.svg':
      'settopbox-denon-avreceiver-frozen.svg',
    'settopbox-denon-avreceiver.svg': 'settopbox-denon-avreceiver.svg',
    'settopbox-deutschetelekom-frozen.svg':
      'settopbox-deutschetelekom-frozen.svg',
    'settopbox-deutschetelekom.svg': 'settopbox-deutschetelekom.svg',
    'settopbox-directv-geniehddvr-frozen.svg':
      'settopbox-directv-geniehddvr-frozen.svg',
    'settopbox-directv-geniehddvr.svg': 'settopbox-directv-geniehddvr.svg',
    'settopbox-dish-frozen.svg': 'settopbox-dish-frozen.svg',
    'settopbox-dish-hopper-frozen.svg': 'settopbox-dish-hopper-frozen.svg',
    'settopbox-dish-hopper.svg': 'settopbox-dish-hopper.svg',
    'settopbox-dish-joey-frozen.svg': 'settopbox-dish-joey-frozen.svg',
    'settopbox-dish-joey.svg': 'settopbox-dish-joey.svg',
    'settopbox-dish-joeyreceiver-frozen.svg':
      'settopbox-dish-joeyreceiver-frozen.svg',
    'settopbox-dish-joeyreceiver.svg': 'settopbox-dish-joeyreceiver.svg',
    'settopbox-dish-wally-frozen.svg': 'settopbox-dish-wally-frozen.svg',
    'settopbox-dish-wally.svg': 'settopbox-dish-wally.svg',
    'settopbox-dish-wirelessjoeyreceiver-frozen.svg':
      'settopbox-dish-wirelessjoeyreceiver-frozen.svg',
    'settopbox-dish-wirelessjoeyreceiver.svg':
      'settopbox-dish-wirelessjoeyreceiver.svg',
    'settopbox-dish.svg': 'settopbox-dish.svg',
    'settopbox-dlink-boxeebox-frozen.svg':
      'settopbox-dlink-boxeebox-frozen.svg',
    'settopbox-dlink-boxeebox.svg': 'settopbox-dlink-boxeebox.svg',
    'settopbox-dreambox-dm800se-frozen.svg':
      'settopbox-dreambox-dm800se-frozen.svg',
    'settopbox-dreambox-dm800se.svg': 'settopbox-dreambox-dm800se.svg',
    'settopbox-facebook-portaltv-frozen.svg':
      'settopbox-facebook-portaltv-frozen.svg',
    'settopbox-facebook-portaltv.svg': 'settopbox-facebook-portaltv.svg',
    'settopbox-formuler-f3-frozen.svg': 'settopbox-formuler-f3-frozen.svg',
    'settopbox-formuler-f3.svg': 'settopbox-formuler-f3.svg',
    'settopbox-formuler-f4turbo-frozen.svg':
      'settopbox-formuler-f4turbo-frozen.svg',
    'settopbox-formuler-f4turbo.svg': 'settopbox-formuler-f4turbo.svg',
    'settopbox-formuler-iptv-frozen.svg': 'settopbox-formuler-iptv-frozen.svg',
    'settopbox-formuler-iptv.svg': 'settopbox-formuler-iptv.svg',
    'settopbox-formuler-z-frozen.svg': 'settopbox-formuler-z-frozen.svg',
    'settopbox-formuler-z.svg': 'settopbox-formuler-z.svg',
    'settopbox-formuler-z8-frozen.svg': 'settopbox-formuler-z8-frozen.svg',
    'settopbox-formuler-z8.svg': 'settopbox-formuler-z8.svg',
    'settopbox-formuler-zx5g-frozen.svg': 'settopbox-formuler-zx5g-frozen.svg',
    'settopbox-formuler-zx5g.svg': 'settopbox-formuler-zx5g.svg',
    'settopbox-foxtel-box-frozen.svg': 'settopbox-foxtel-box-frozen.svg',
    'settopbox-foxtel-box.svg': 'settopbox-foxtel-box.svg',
    'settopbox-foxtel-iq3-frozen.svg': 'settopbox-foxtel-iq3-frozen.svg',
    'settopbox-foxtel-iq3.svg': 'settopbox-foxtel-iq3.svg',
    'settopbox-foxtel-iq4-frozen.svg': 'settopbox-foxtel-iq4-frozen.svg',
    'settopbox-foxtel-iq4.svg': 'settopbox-foxtel-iq4.svg',
    'settopbox-foxtel-nowbox-frozen.svg': 'settopbox-foxtel-nowbox-frozen.svg',
    'settopbox-foxtel-nowbox.svg': 'settopbox-foxtel-nowbox.svg',
    'settopbox-frozen.svg': 'settopbox-frozen.svg',
    'settopbox-goobangdoo-abox-frozen.svg':
      'settopbox-goobangdoo-abox-frozen.svg',
    'settopbox-goobangdoo-abox.svg': 'settopbox-goobangdoo-abox.svg',
    'settopbox-google-chromecast-frozen.svg':
      'settopbox-google-chromecast-frozen.svg',
    'settopbox-google-chromecast.svg': 'settopbox-google-chromecast.svg',
    'settopbox-google-nexus-frozen.svg': 'settopbox-google-nexus-frozen.svg',
    'settopbox-google-nexus.svg': 'settopbox-google-nexus.svg',
    'settopbox-gtmedia-frozen.svg': 'settopbox-gtmedia-frozen.svg',
    'settopbox-gtmedia.svg': 'settopbox-gtmedia.svg',
    'settopbox-h96-max-frozen.svg': 'settopbox-h96-max-frozen.svg',
    'settopbox-h96-max.svg': 'settopbox-h96-max.svg',
    'settopbox-himedia-hi3798mv100-frozen.svg':
      'settopbox-himedia-hi3798mv100-frozen.svg',
    'settopbox-himedia-hi3798mv100.svg': 'settopbox-himedia-hi3798mv100.svg',
    'settopbox-hisilicon-frozen.svg': 'settopbox-hisilicon-frozen.svg',
    'settopbox-hisilicon-q10-frozen.svg': 'settopbox-hisilicon-q10-frozen.svg',
    'settopbox-hisilicon-q10.svg': 'settopbox-hisilicon-q10.svg',
    'settopbox-hisilicon.svg': 'settopbox-hisilicon.svg',
    'settopbox-hke360-tvbox-frozen.svg': 'settopbox-hke360-tvbox-frozen.svg',
    'settopbox-hke360-tvbox.svg': 'settopbox-hke360-tvbox.svg',
    'settopbox-humax-settopbox-frozen.svg':
      'settopbox-humax-settopbox-frozen.svg',
    'settopbox-humax-settopbox.svg': 'settopbox-humax-settopbox.svg',
    'settopbox-infomir-frozen.svg': 'settopbox-infomir-frozen.svg',
    'settopbox-infomir.svg': 'settopbox-infomir.svg',
    'settopbox-jcom-link-frozen.svg': 'settopbox-jcom-link-frozen.svg',
    'settopbox-jcom-link.svg': 'settopbox-jcom-link.svg',
    'settopbox-kaon-settopbox-frozen.svg':
      'settopbox-kaon-settopbox-frozen.svg',
    'settopbox-kaon-settopbox.svg': 'settopbox-kaon-settopbox.svg',
    'settopbox-logitech-squeezeboxreceiver-frozen.svg':
      'settopbox-logitech-squeezeboxreceiver-frozen.svg',
    'settopbox-logitech-squeezeboxreceiver.svg':
      'settopbox-logitech-squeezeboxreceiver.svg',
    'settopbox-magenta-tvbox-frozen.svg': 'settopbox-magenta-tvbox-frozen.svg',
    'settopbox-magenta-tvbox.svg': 'settopbox-magenta-tvbox.svg',
    'settopbox-magictv-mtv9600d-frozen.svg':
      'settopbox-magictv-mtv9600d-frozen.svg',
    'settopbox-magictv-mtv9600d.svg': 'settopbox-magictv-mtv9600d.svg',
    'settopbox-marantz-avreceiver-frozen.svg':
      'settopbox-marantz-avreceiver-frozen.svg',
    'settopbox-marantz-avreceiver.svg': 'settopbox-marantz-avreceiver.svg',
    'settopbox-mecool-m8s-frozen.svg': 'settopbox-mecool-m8s-frozen.svg',
    'settopbox-mecool-m8s.svg': 'settopbox-mecool-m8s.svg',
    'settopbox-mecool-m8spro-frozen.svg': 'settopbox-mecool-m8spro-frozen.svg',
    'settopbox-mecool-m8spro.svg': 'settopbox-mecool-m8spro.svg',
    'settopbox-minix-neo-frozen.svg': 'settopbox-minix-neo-frozen.svg',
    'settopbox-minix-neo.svg': 'settopbox-minix-neo.svg',
    'settopbox-mxq-pro-frozen.svg': 'settopbox-mxq-pro-frozen.svg',
    'settopbox-mxq-pro.svg': 'settopbox-mxq-pro.svg',
    'settopbox-mytv-superbox-frozen.svg': 'settopbox-mytv-superbox-frozen.svg',
    'settopbox-mytv-superbox.svg': 'settopbox-mytv-superbox.svg',
    'settopbox-netgem-box-frozen.svg': 'settopbox-netgem-box-frozen.svg',
    'settopbox-netgem-box.svg': 'settopbox-netgem-box.svg',
    'settopbox-nowtv-rokusmartstick-frozen.svg':
      'settopbox-nowtv-rokusmartstick-frozen.svg',
    'settopbox-nowtv-rokusmartstick.svg': 'settopbox-nowtv-rokusmartstick.svg',
    'settopbox-nowtv-rokustb-frozen.svg': 'settopbox-nowtv-rokustb-frozen.svg',
    'settopbox-nowtv-rokustb.svg': 'settopbox-nowtv-rokustb.svg',
    'settopbox-nvidia-shieldtv-frozen.svg':
      'settopbox-nvidia-shieldtv-frozen.svg',
    'settopbox-nvidia-shieldtv.svg': 'settopbox-nvidia-shieldtv.svg',
    'settopbox-onkyo-avreceiver-frozen.svg':
      'settopbox-onkyo-avreceiver-frozen.svg',
    'settopbox-onkyo-avreceiver.svg': 'settopbox-onkyo-avreceiver.svg',
    'settopbox-optimum-alticeone-frozen.svg':
      'settopbox-optimum-alticeone-frozen.svg',
    'settopbox-optimum-alticeone.svg': 'settopbox-optimum-alticeone.svg',
    'settopbox-ott-box-frozen.svg': 'settopbox-ott-box-frozen.svg',
    'settopbox-ott-box.svg': 'settopbox-ott-box.svg',
    'settopbox-philips-huesyncbox-frozen.svg':
      'settopbox-philips-huesyncbox-frozen.svg',
    'settopbox-philips-huesyncbox.svg': 'settopbox-philips-huesyncbox.svg',
    'settopbox-pioneer-avreceiver-frozen.svg':
      'settopbox-pioneer-avreceiver-frozen.svg',
    'settopbox-pioneer-avreceiver.svg': 'settopbox-pioneer-avreceiver.svg',
    'settopbox-quickline-uhdbox-frozen.svg':
      'settopbox-quickline-uhdbox-frozen.svg',
    'settopbox-quickline-uhdbox.svg': 'settopbox-quickline-uhdbox.svg',
    'settopbox-realtek-atv-frozen.svg': 'settopbox-realtek-atv-frozen.svg',
    'settopbox-realtek-atv.svg': 'settopbox-realtek-atv.svg',
    'settopbox-roku-express+-frozen.svg': 'settopbox-roku-express+-frozen.svg',
    'settopbox-roku-express+.svg': 'settopbox-roku-express+.svg',
    'settopbox-roku-express-frozen.svg': 'settopbox-roku-express-frozen.svg',
    'settopbox-roku-express.svg': 'settopbox-roku-express.svg',
    'settopbox-roku-frozen.svg': 'settopbox-roku-frozen.svg',
    'settopbox-roku-hd-frozen.svg': 'settopbox-roku-hd-frozen.svg',
    'settopbox-roku-hd.svg': 'settopbox-roku-hd.svg',
    'settopbox-roku-lt-frozen.svg': 'settopbox-roku-lt-frozen.svg',
    'settopbox-roku-lt.svg': 'settopbox-roku-lt.svg',
    'settopbox-roku-player-frozen.svg': 'settopbox-roku-player-frozen.svg',
    'settopbox-roku-player.svg': 'settopbox-roku-player.svg',
    'settopbox-roku-premiere+-frozen.svg':
      'settopbox-roku-premiere+-frozen.svg',
    'settopbox-roku-premiere+.svg': 'settopbox-roku-premiere+.svg',
    'settopbox-roku-premiere-frozen.svg': 'settopbox-roku-premiere-frozen.svg',
    'settopbox-roku-premiere.svg': 'settopbox-roku-premiere.svg',
    'settopbox-roku-streamingstick+-frozen.svg':
      'settopbox-roku-streamingstick+-frozen.svg',
    'settopbox-roku-streamingstick+.svg': 'settopbox-roku-streamingstick+.svg',
    'settopbox-roku-streamingstick-frozen.svg':
      'settopbox-roku-streamingstick-frozen.svg',
    'settopbox-roku-streamingstick.svg': 'settopbox-roku-streamingstick.svg',
    'settopbox-roku-ultra-frozen.svg': 'settopbox-roku-ultra-frozen.svg',
    'settopbox-roku-ultra.svg': 'settopbox-roku-ultra.svg',
    'settopbox-roku.svg': 'settopbox-roku.svg',
    'settopbox-sagemcom-sunrise-frozen.svg':
      'settopbox-sagemcom-sunrise-frozen.svg',
    'settopbox-sagemcom-sunrise.svg': 'settopbox-sagemcom-sunrise.svg',
    'settopbox-samsung-frozen.svg': 'settopbox-samsung-frozen.svg',
    'settopbox-samsung.svg': 'settopbox-samsung.svg',
    'settopbox-sasktel-maxtvstream-frozen.svg':
      'settopbox-sasktel-maxtvstream-frozen.svg',
    'settopbox-sasktel-maxtvstream.svg': 'settopbox-sasktel-maxtvstream.svg',
    'settopbox-shadow-ghost-frozen.svg': 'settopbox-shadow-ghost-frozen.svg',
    'settopbox-shadow-ghost.svg': 'settopbox-shadow-ghost.svg',
    'settopbox-silicondust-hdhomerun-frozen.svg':
      'settopbox-silicondust-hdhomerun-frozen.svg',
    'settopbox-silicondust-hdhomerun.svg':
      'settopbox-silicondust-hdhomerun.svg',
    'settopbox-sky+-hdbox-frozen.svg': 'settopbox-sky+-hdbox-frozen.svg',
    'settopbox-sky+-hdbox.svg': 'settopbox-sky+-hdbox.svg',
    'settopbox-sky-hub-frozen.svg': 'settopbox-sky-hub-frozen.svg',
    'settopbox-sky-hub.svg': 'settopbox-sky-hub.svg',
    'settopbox-sky-qstb-frozen.svg': 'settopbox-sky-qstb-frozen.svg',
    'settopbox-sky-qstb.svg': 'settopbox-sky-qstb.svg',
    'settopbox-sling-airtvplayer-frozen.svg':
      'settopbox-sling-airtvplayer-frozen.svg',
    'settopbox-sling-airtvplayer.svg': 'settopbox-sling-airtvplayer.svg',
    'settopbox-slingbox-350-frozen.svg': 'settopbox-slingbox-350-frozen.svg',
    'settopbox-slingbox-350.svg': 'settopbox-slingbox-350.svg',
    'settopbox-slingbox-500-frozen.svg': 'settopbox-slingbox-500-frozen.svg',
    'settopbox-slingbox-500.svg': 'settopbox-slingbox-500.svg',
    'settopbox-slingbox-frozen.svg': 'settopbox-slingbox-frozen.svg',
    'settopbox-slingbox-m1-frozen.svg': 'settopbox-slingbox-m1-frozen.svg',
    'settopbox-slingbox-m1.svg': 'settopbox-slingbox-m1.svg',
    'settopbox-slingbox.svg': 'settopbox-slingbox.svg',
    'settopbox-swisscom-tvbox-frozen.svg':
      'settopbox-swisscom-tvbox-frozen.svg',
    'settopbox-swisscom-tvbox.svg': 'settopbox-swisscom-tvbox.svg',
    'settopbox-technicolor-frozen.svg': 'settopbox-technicolor-frozen.svg',
    'settopbox-technicolor-settopbox-frozen.svg':
      'settopbox-technicolor-settopbox-frozen.svg',
    'settopbox-technicolor-settopbox.svg':
      'settopbox-technicolor-settopbox.svg',
    'settopbox-technicolor.svg': 'settopbox-technicolor.svg',
    'settopbox-tivo-frozen.svg': 'settopbox-tivo-frozen.svg',
    'settopbox-tivo.svg': 'settopbox-tivo.svg',
    'settopbox-tx3-mini-frozen.svg': 'settopbox-tx3-mini-frozen.svg',
    'settopbox-tx3-mini.svg': 'settopbox-tx3-mini.svg',
    'settopbox-unblock-ubox4-frozen.svg': 'settopbox-unblock-ubox4-frozen.svg',
    'settopbox-unblock-ubox4.svg': 'settopbox-unblock-ubox4.svg',
    'settopbox-unblocktech-ubox6-frozen.svg':
      'settopbox-unblocktech-ubox6-frozen.svg',
    'settopbox-unblocktech-ubox6.svg': 'settopbox-unblocktech-ubox6.svg',
    'settopbox-upc-tvbox-frozen.svg': 'settopbox-upc-tvbox-frozen.svg',
    'settopbox-upc-tvbox.svg': 'settopbox-upc-tvbox.svg',
    'settopbox-valve-steamlink-frozen.svg':
      'settopbox-valve-steamlink-frozen.svg',
    'settopbox-valve-steamlink.svg': 'settopbox-valve-steamlink.svg',
    'settopbox-vestel-frozen.svg': 'settopbox-vestel-frozen.svg',
    'settopbox-vestel.svg': 'settopbox-vestel.svg',
    'settopbox-viark-combo-frozen.svg': 'settopbox-viark-combo-frozen.svg',
    'settopbox-viark-combo.svg': 'settopbox-viark-combo.svg',
    'settopbox-viark-lil-frozen.svg': 'settopbox-viark-lil-frozen.svg',
    'settopbox-viark-lil.svg': 'settopbox-viark-lil.svg',
    'settopbox-viark-sat-frozen.svg': 'settopbox-viark-sat-frozen.svg',
    'settopbox-viark-sat.svg': 'settopbox-viark-sat.svg',
    'settopbox-virgin-tvbox-frozen.svg': 'settopbox-virgin-tvbox-frozen.svg',
    'settopbox-virgin-tvbox.svg': 'settopbox-virgin-tvbox.svg',
    'settopbox-vodafone-frozen.svg': 'settopbox-vodafone-frozen.svg',
    'settopbox-vodafone-gigatv-frozen.svg':
      'settopbox-vodafone-gigatv-frozen.svg',
    'settopbox-vodafone-gigatv.svg': 'settopbox-vodafone-gigatv.svg',
    'settopbox-vodafone.svg': 'settopbox-vodafone.svg',
    'settopbox-voo-evasionbox-frozen.svg':
      'settopbox-voo-evasionbox-frozen.svg',
    'settopbox-voo-evasionbox.svg': 'settopbox-voo-evasionbox.svg',
    'settopbox-vuplus-solo-frozen.svg': 'settopbox-vuplus-solo-frozen.svg',
    'settopbox-vuplus-solo.svg': 'settopbox-vuplus-solo.svg',
    'settopbox-vuplus-zero-frozen.svg': 'settopbox-vuplus-zero-frozen.svg',
    'settopbox-vuplus-zero.svg': 'settopbox-vuplus-zero.svg',
    'settopbox-x92-tvbox-frozen.svg': 'settopbox-x92-tvbox-frozen.svg',
    'settopbox-x92-tvbox.svg': 'settopbox-x92-tvbox.svg',
    'settopbox-x96-q-frozen.svg': 'settopbox-x96-q-frozen.svg',
    'settopbox-x96-q.svg': 'settopbox-x96-q.svg',
    'settopbox-x96-tvbox-frozen.svg': 'settopbox-x96-tvbox-frozen.svg',
    'settopbox-x96-tvbox.svg': 'settopbox-x96-tvbox.svg',
    'settopbox-xiaomi-mibox-frozen.svg': 'settopbox-xiaomi-mibox-frozen.svg',
    'settopbox-xiaomi-mibox.svg': 'settopbox-xiaomi-mibox.svg',
    'settopbox-xiaomi-miboxtv-frozen.svg':
      'settopbox-xiaomi-miboxtv-frozen.svg',
    'settopbox-xiaomi-miboxtv.svg': 'settopbox-xiaomi-miboxtv.svg',
    'settopbox-yamaha-rxa830-frozen.svg': 'settopbox-yamaha-rxa830-frozen.svg',
    'settopbox-yamaha-rxa830.svg': 'settopbox-yamaha-rxa830.svg',
    'settopbox-yousee-frozen.svg': 'settopbox-yousee-frozen.svg',
    'settopbox-yousee.svg': 'settopbox-yousee.svg',
    'settopbox-zgemma-h2h-frozen.svg': 'settopbox-zgemma-h2h-frozen.svg',
    'settopbox-zgemma-h2h.svg': 'settopbox-zgemma-h2h.svg',
    'settopbox-zgemma-h9s-frozen.svg': 'settopbox-zgemma-h9s-frozen.svg',
    'settopbox-zgemma-h9s.svg': 'settopbox-zgemma-h9s.svg',
    'settopbox-zgemma-star-frozen.svg': 'settopbox-zgemma-star-frozen.svg',
    'settopbox-zgemma-star.svg': 'settopbox-zgemma-star.svg',
    'settopbox-ziggo-mediabox-frozen.svg':
      'settopbox-ziggo-mediabox-frozen.svg',
    'settopbox-ziggo-mediabox.svg': 'settopbox-ziggo-mediabox.svg',
    'settopbox.svg': 'settopbox.svg',
    'sleepsystem-1.svg': 'sleepsystem-1.svg',
    'sleepsystem-frozen-1.svg': 'sleepsystem-frozen-1.svg',
    'sleepsystem-frozen.svg': 'sleepsystem-frozen.svg',
    'sleepsystem.svg': 'sleepsystem.svg',
    'smartappliance-anovaculinary-frozen.svg':
      'smartappliance-anovaculinary-frozen.svg',
    'smartappliance-anovaculinary-precisioncooker-frozen.svg':
      'smartappliance-anovaculinary-precisioncooker-frozen.svg',
    'smartappliance-anovaculinary-precisioncooker.svg':
      'smartappliance-anovaculinary-precisioncooker.svg',
    'smartappliance-anovaculinary.svg': 'smartappliance-anovaculinary.svg',
    'smartappliance-frozen.svg': 'smartappliance-frozen.svg',
    'smartappliance-ge-frozen.svg': 'smartappliance-ge-frozen.svg',
    'smartappliance-ge.svg': 'smartappliance-ge.svg',
    'smartappliance.svg': 'smartappliance.svg',
    'smartbed-frozen.svg': 'smartbed-frozen.svg',
    'smartbed.svg': 'smartbed.svg',
    'smartdevice-amazon-dashbutton-frozen.svg':
      'smartdevice-amazon-dashbutton-frozen.svg',
    'smartdevice-amazon-dashbutton.svg': 'smartdevice-amazon-dashbutton.svg',
    'smartdevice-dyson-frozen.svg': 'smartdevice-dyson-frozen.svg',
    'smartdevice-dyson.svg': 'smartdevice-dyson.svg',
    'smartdevice-dyson2-frozen.svg': 'smartdevice-dyson2-frozen.svg',
    'smartdevice-dyson2.svg': 'smartdevice-dyson2.svg',
    'smartdevice-espressif-frozen.svg': 'smartdevice-espressif-frozen.svg',
    'smartdevice-espressif.svg': 'smartdevice-espressif.svg',
    'smartdevice-flir-frozen.svg': 'smartdevice-flir-frozen.svg',
    'smartdevice-flir.svg': 'smartdevice-flir.svg',
    'smartdevice-frozen.svg': 'smartdevice-frozen.svg',
    'smartdevice-funguypro-wateralarm-frozen.svg':
      'smartdevice-funguypro-wateralarm-frozen.svg',
    'smartdevice-funguypro-wateralarm.svg':
      'smartdevice-funguypro-wateralarm.svg',
    'smartdevice-idevices-frozen.svg': 'smartdevice-idevices-frozen.svg',
    'smartdevice-idevices.svg': 'smartdevice-idevices.svg',
    'smartdevice-ismart-smartsecurity-frozen.svg':
      'smartdevice-ismart-smartsecurity-frozen.svg',
    'smartdevice-ismart-smartsecurity.svg':
      'smartdevice-ismart-smartsecurity.svg',
    'smartdevice-konke-frozen.svg': 'smartdevice-konke-frozen.svg',
    'smartdevice-konke.svg': 'smartdevice-konke.svg',
    'smartdevice.svg': 'smartdevice.svg',
    'smartgrill-frozen.svg': 'smartgrill-frozen.svg',
    'smartgrill.svg': 'smartgrill.svg',
    'smartlaundry-frozen.svg': 'smartlaundry-frozen.svg',
    'smartlaundry.svg': 'smartlaundry.svg',
    'smartmonitor-ecowater-hydrolinkplus-frozen.svg':
      'smartmonitor-ecowater-hydrolinkplus-frozen.svg',
    'smartmonitor-ecowater-hydrolinkplus.svg':
      'smartmonitor-ecowater-hydrolinkplus.svg',
    'smartmonitor-emporia-vue-frozen.svg':
      'smartmonitor-emporia-vue-frozen.svg',
    'smartmonitor-emporia-vue.svg': 'smartmonitor-emporia-vue.svg',
    'smartmonitor-encored-enertalk-frozen.svg':
      'smartmonitor-encored-enertalk-frozen.svg',
    'smartmonitor-encored-enertalk.svg': 'smartmonitor-encored-enertalk.svg',
    'smartmonitor-energy-bridge2-frozen.svg':
      'smartmonitor-energy-bridge2-frozen.svg',
    'smartmonitor-energy-bridge2.svg': 'smartmonitor-energy-bridge2.svg',
    'smartmonitor-eyedro-electricitymonitor-frozen.svg':
      'smartmonitor-eyedro-electricitymonitor-frozen.svg',
    'smartmonitor-eyedro-electricitymonitor.svg':
      'smartmonitor-eyedro-electricitymonitor.svg',
    'smartmonitor-flume-smartwatermonitor-frozen.svg':
      'smartmonitor-flume-smartwatermonitor-frozen.svg',
    'smartmonitor-flume-smartwatermonitor.svg':
      'smartmonitor-flume-smartwatermonitor.svg',
    'smartmonitor-frozen.svg': 'smartmonitor-frozen.svg',
    'smartmonitor-guardian-valvecontroller-frozen.svg':
      'smartmonitor-guardian-valvecontroller-frozen.svg',
    'smartmonitor-guardian-valvecontroller.svg':
      'smartmonitor-guardian-valvecontroller.svg',
    'smartmonitor-ihome-frozen.svg': 'smartmonitor-ihome-frozen.svg',
    'smartmonitor-ihome.svg': 'smartmonitor-ihome.svg',
    'smartmonitor-lorex-homecenter-frozen.svg':
      'smartmonitor-lorex-homecenter-frozen.svg',
    'smartmonitor-lorex-homecenter.svg': 'smartmonitor-lorex-homecenter.svg',
    'smartmonitor-mystrom-wifibutton-frozen.svg':
      'smartmonitor-mystrom-wifibutton-frozen.svg',
    'smartmonitor-mystrom-wifibutton.svg':
      'smartmonitor-mystrom-wifibutton.svg',
    'smartmonitor-panasonic-aiseg-frozen.svg':
      'smartmonitor-panasonic-aiseg-frozen.svg',
    'smartmonitor-panasonic-aiseg.svg': 'smartmonitor-panasonic-aiseg.svg',
    'smartmonitor-phyn-watermonitor-frozen.svg':
      'smartmonitor-phyn-watermonitor-frozen.svg',
    'smartmonitor-phyn-watermonitor.svg': 'smartmonitor-phyn-watermonitor.svg',
    'smartmonitor-pumpspy-sumppump-frozen.svg':
      'smartmonitor-pumpspy-sumppump-frozen.svg',
    'smartmonitor-pumpspy-sumppump.svg': 'smartmonitor-pumpspy-sumppump.svg',
    'smartmonitor-qardio-1.svg': 'smartmonitor-qardio-1.svg',
    'smartmonitor-qardio-frozen-1.svg': 'smartmonitor-qardio-frozen-1.svg',
    'smartmonitor-qardio-frozen.svg': 'smartmonitor-qardio-frozen.svg',
    'smartmonitor-qardio.svg': 'smartmonitor-qardio.svg',
    'smartmonitor-roost-battery-frozen.svg':
      'smartmonitor-roost-battery-frozen.svg',
    'smartmonitor-roost-battery.svg': 'smartmonitor-roost-battery.svg',
    'smartmonitor-scram-smartdevice-frozen.svg':
      'smartmonitor-scram-smartdevice-frozen.svg',
    'smartmonitor-scram-smartdevice.svg': 'smartmonitor-scram-smartdevice.svg',
    'smartmonitor-sense-homeenergymonitor-frozen.svg':
      'smartmonitor-sense-homeenergymonitor-frozen.svg',
    'smartmonitor-sense-homeenergymonitor.svg':
      'smartmonitor-sense-homeenergymonitor.svg',
    'smartmonitor-shelly-3em-frozen.svg': 'smartmonitor-shelly-3em-frozen.svg',
    'smartmonitor-shelly-3em.svg': 'smartmonitor-shelly-3em.svg',
    'smartmonitor-tankutility-propanetankmonitor-frozen.svg':
      'smartmonitor-tankutility-propanetankmonitor-frozen.svg',
    'smartmonitor-tankutility-propanetankmonitor.svg':
      'smartmonitor-tankutility-propanetankmonitor.svg',
    'smartmonitor-victronenergy-colorcontrol-frozen.svg':
      'smartmonitor-victronenergy-colorcontrol-frozen.svg',
    'smartmonitor-victronenergy-colorcontrol.svg':
      'smartmonitor-victronenergy-colorcontrol.svg',
    'smartmonitor-wynd-halo-frozen.svg': 'smartmonitor-wynd-halo-frozen.svg',
    'smartmonitor-wynd-halo.svg': 'smartmonitor-wynd-halo.svg',
    'smartmonitor.svg': 'smartmonitor.svg',
    'smartphone-alcatel-1b-frozen.svg': 'smartphone-alcatel-1b-frozen.svg',
    'smartphone-alcatel-1b.svg': 'smartphone-alcatel-1b.svg',
    'smartphone-alcatel-1t-frozen.svg': 'smartphone-alcatel-1t-frozen.svg',
    'smartphone-alcatel-1t.svg': 'smartphone-alcatel-1t.svg',
    'smartphone-alcatel-1v-frozen.svg': 'smartphone-alcatel-1v-frozen.svg',
    'smartphone-alcatel-1v.svg': 'smartphone-alcatel-1v.svg',
    'smartphone-alcatel-1x-frozen.svg': 'smartphone-alcatel-1x-frozen.svg',
    'smartphone-alcatel-1x.svg': 'smartphone-alcatel-1x.svg',
    'smartphone-alcatel-1с-frozen.svg': 'smartphone-alcatel-1с-frozen.svg',
    'smartphone-alcatel-1с.svg': 'smartphone-alcatel-1с.svg',
    'smartphone-alcatel-5124j-frozen.svg':
      'smartphone-alcatel-5124j-frozen.svg',
    'smartphone-alcatel-5124j.svg': 'smartphone-alcatel-5124j.svg',
    'smartphone-alcatel-avalon-frozen.svg':
      'smartphone-alcatel-avalon-frozen.svg',
    'smartphone-alcatel-avalon.svg': 'smartphone-alcatel-avalon.svg',
    'smartphone-alcatel-go-frozen.svg': 'smartphone-alcatel-go-frozen.svg',
    'smartphone-alcatel-go.svg': 'smartphone-alcatel-go.svg',
    'smartphone-alcatel-onetouch-frozen.svg':
      'smartphone-alcatel-onetouch-frozen.svg',
    'smartphone-alcatel-onetouch.svg': 'smartphone-alcatel-onetouch.svg',
    'smartphone-alcatel-raven-frozen.svg':
      'smartphone-alcatel-raven-frozen.svg',
    'smartphone-alcatel-raven.svg': 'smartphone-alcatel-raven.svg',
    'smartphone-alcatel-tcl-frozen.svg': 'smartphone-alcatel-tcl-frozen.svg',
    'smartphone-alcatel-tcl.svg': 'smartphone-alcatel-tcl.svg',
    'smartphone-alcatel-x1-frozen.svg': 'smartphone-alcatel-x1-frozen.svg',
    'smartphone-alcatel-x1.svg': 'smartphone-alcatel-x1.svg',
    'smartphone-alcatel-zip-frozen.svg': 'smartphone-alcatel-zip-frozen.svg',
    'smartphone-alcatel-zip.svg': 'smartphone-alcatel-zip.svg',
    'smartphone-altice-s12-frozen.svg': 'smartphone-altice-s12-frozen.svg',
    'smartphone-altice-s12.svg': 'smartphone-altice-s12.svg',
    'smartphone-android-1.svg': 'smartphone-android-1.svg',
    'smartphone-android-frozen-1.svg': 'smartphone-android-frozen-1.svg',
    'smartphone-android-frozen.svg': 'smartphone-android-frozen.svg',
    'smartphone-android-l-frozen.svg': 'smartphone-android-l-frozen.svg',
    'smartphone-android-l.svg': 'smartphone-android-l.svg',
    'smartphone-android.svg': 'smartphone-android.svg',
    'smartphone-ans-ul40-frozen.svg': 'smartphone-ans-ul40-frozen.svg',
    'smartphone-ans-ul40.svg': 'smartphone-ans-ul40.svg',
    'smartphone-apple-iphone-frozen.svg': 'smartphone-apple-iphone-frozen.svg',
    'smartphone-apple-iphone-this-frozen.svg':
      'smartphone-apple-iphone-this-frozen.svg',
    'smartphone-apple-iphone-this.svg': 'smartphone-apple-iphone-this.svg',
    'smartphone-apple-iphone.svg': 'smartphone-apple-iphone.svg',
    'smartphone-aquos-r-frozen.svg': 'smartphone-aquos-r-frozen.svg',
    'smartphone-aquos-r.svg': 'smartphone-aquos-r.svg',
    'smartphone-aquos-r2-frozen.svg': 'smartphone-aquos-r2-frozen.svg',
    'smartphone-aquos-r2.svg': 'smartphone-aquos-r2.svg',
    'smartphone-aquos-r3-frozen.svg': 'smartphone-aquos-r3-frozen.svg',
    'smartphone-aquos-r3.svg': 'smartphone-aquos-r3.svg',
    'smartphone-aquos-r5g-frozen.svg': 'smartphone-aquos-r5g-frozen.svg',
    'smartphone-aquos-r5g.svg': 'smartphone-aquos-r5g.svg',
    'smartphone-aquos-rcompact-frozen.svg':
      'smartphone-aquos-rcompact-frozen.svg',
    'smartphone-aquos-rcompact.svg': 'smartphone-aquos-rcompact.svg',
    'smartphone-aquos-sense-frozen.svg': 'smartphone-aquos-sense-frozen.svg',
    'smartphone-aquos-sense.svg': 'smartphone-aquos-sense.svg',
    'smartphone-aquos-sense2-frozen.svg': 'smartphone-aquos-sense2-frozen.svg',
    'smartphone-aquos-sense2.svg': 'smartphone-aquos-sense2.svg',
    'smartphone-aquos-sense3-frozen.svg': 'smartphone-aquos-sense3-frozen.svg',
    'smartphone-aquos-sense3.svg': 'smartphone-aquos-sense3.svg',
    'smartphone-aquos-sense3basic-frozen.svg':
      'smartphone-aquos-sense3basic-frozen.svg',
    'smartphone-aquos-sense3basic.svg': 'smartphone-aquos-sense3basic.svg',
    'smartphone-aquos-sense3plus-frozen.svg':
      'smartphone-aquos-sense3plus-frozen.svg',
    'smartphone-aquos-sense3plus.svg': 'smartphone-aquos-sense3plus.svg',
    'smartphone-aquos-serie-frozen.svg': 'smartphone-aquos-serie-frozen.svg',
    'smartphone-aquos-serie.svg': 'smartphone-aquos-serie.svg',
    'smartphone-aquos-seriemini-frozen.svg':
      'smartphone-aquos-seriemini-frozen.svg',
    'smartphone-aquos-seriemini.svg': 'smartphone-aquos-seriemini.svg',
    'smartphone-asus-rogphone-frozen.svg':
      'smartphone-asus-rogphone-frozen.svg',
    'smartphone-asus-rogphone.svg': 'smartphone-asus-rogphone.svg',
    'smartphone-asus-smartphone-frozen.svg':
      'smartphone-asus-smartphone-frozen.svg',
    'smartphone-asus-smartphone.svg': 'smartphone-asus-smartphone.svg',
    'smartphone-asus-zenfone-frozen.svg': 'smartphone-asus-zenfone-frozen.svg',
    'smartphone-asus-zenfone.svg': 'smartphone-asus-zenfone.svg',
    'smartphone-att-axia-frozen.svg': 'smartphone-att-axia-frozen.svg',
    'smartphone-att-axia.svg': 'smartphone-att-axia.svg',
    'smartphone-att-calypso-frozen.svg': 'smartphone-att-calypso-frozen.svg',
    'smartphone-att-calypso.svg': 'smartphone-att-calypso.svg',
    'smartphone-att-fusion-frozen.svg': 'smartphone-att-fusion-frozen.svg',
    'smartphone-att-fusion.svg': 'smartphone-att-fusion.svg',
    'smartphone-att-maestro-frozen.svg': 'smartphone-att-maestro-frozen.svg',
    'smartphone-att-maestro.svg': 'smartphone-att-maestro.svg',
    'smartphone-att-radiant-frozen.svg': 'smartphone-att-radiant-frozen.svg',
    'smartphone-att-radiant.svg': 'smartphone-att-radiant.svg',
    'smartphone-blackberry-smartphone-frozen.svg':
      'smartphone-blackberry-smartphone-frozen.svg',
    'smartphone-blackberry-smartphone.svg':
      'smartphone-blackberry-smartphone.svg',
    'smartphone-blackview-a60-frozen.svg':
      'smartphone-blackview-a60-frozen.svg',
    'smartphone-blackview-a60.svg': 'smartphone-blackview-a60.svg',
    'smartphone-blackview-a80-frozen.svg':
      'smartphone-blackview-a80-frozen.svg',
    'smartphone-blackview-a80.svg': 'smartphone-blackview-a80.svg',
    'smartphone-blackview-bv5500-frozen.svg':
      'smartphone-blackview-bv5500-frozen.svg',
    'smartphone-blackview-bv5500.svg': 'smartphone-blackview-bv5500.svg',
    'smartphone-blackview-bv5900-frozen.svg':
      'smartphone-blackview-bv5900-frozen.svg',
    'smartphone-blackview-bv5900.svg': 'smartphone-blackview-bv5900.svg',
    'smartphone-blu-frozen.svg': 'smartphone-blu-frozen.svg',
    'smartphone-blu-life-frozen.svg': 'smartphone-blu-life-frozen.svg',
    'smartphone-blu-life.svg': 'smartphone-blu-life.svg',
    'smartphone-blu-r1-frozen.svg': 'smartphone-blu-r1-frozen.svg',
    'smartphone-blu-r1.svg': 'smartphone-blu-r1.svg',
    'smartphone-blu-view-frozen.svg': 'smartphone-blu-view-frozen.svg',
    'smartphone-blu-view.svg': 'smartphone-blu-view.svg',
    'smartphone-blu.svg': 'smartphone-blu.svg',
    'smartphone-cat-s52-frozen.svg': 'smartphone-cat-s52-frozen.svg',
    'smartphone-cat-s52.svg': 'smartphone-cat-s52.svg',
    'smartphone-chinamobile-a3-frozen.svg':
      'smartphone-chinamobile-a3-frozen.svg',
    'smartphone-chinamobile-a3.svg': 'smartphone-chinamobile-a3.svg',
    'smartphone-coolpad-cool-frozen.svg': 'smartphone-coolpad-cool-frozen.svg',
    'smartphone-coolpad-cool.svg': 'smartphone-coolpad-cool.svg',
    'smartphone-coolpad-legacy-frozen.svg':
      'smartphone-coolpad-legacy-frozen.svg',
    'smartphone-coolpad-legacy.svg': 'smartphone-coolpad-legacy.svg',
    'smartphone-coolpad-quattro-frozen.svg':
      'smartphone-coolpad-quattro-frozen.svg',
    'smartphone-coolpad-quattro.svg': 'smartphone-coolpad-quattro.svg',
    'smartphone-cricket-coolpad-frozen.svg':
      'smartphone-cricket-coolpad-frozen.svg',
    'smartphone-cricket-coolpad.svg': 'smartphone-cricket-coolpad.svg',
    'smartphone-cricket-vision-frozen.svg':
      'smartphone-cricket-vision-frozen.svg',
    'smartphone-cricket-vision.svg': 'smartphone-cricket-vision.svg',
    'smartphone-cricket-wave-frozen.svg': 'smartphone-cricket-wave-frozen.svg',
    'smartphone-cricket-wave.svg': 'smartphone-cricket-wave.svg',
    'smartphone-cubot-j5-frozen.svg': 'smartphone-cubot-j5-frozen.svg',
    'smartphone-cubot-j5.svg': 'smartphone-cubot-j5.svg',
    'smartphone-cubot-kingkong-frozen.svg':
      'smartphone-cubot-kingkong-frozen.svg',
    'smartphone-cubot-kingkong.svg': 'smartphone-cubot-kingkong.svg',
    'smartphone-cubot-note-frozen.svg': 'smartphone-cubot-note-frozen.svg',
    'smartphone-cubot-note.svg': 'smartphone-cubot-note.svg',
    'smartphone-cubot-p30-frozen.svg': 'smartphone-cubot-p30-frozen.svg',
    'smartphone-cubot-p30.svg': 'smartphone-cubot-p30.svg',
    'smartphone-cubot-r15pro-frozen.svg': 'smartphone-cubot-r15pro-frozen.svg',
    'smartphone-cubot-r15pro.svg': 'smartphone-cubot-r15pro.svg',
    'smartphone-cubot-x19-frozen.svg': 'smartphone-cubot-x19-frozen.svg',
    'smartphone-cubot-x19.svg': 'smartphone-cubot-x19.svg',
    'smartphone-cubot-x30-frozen.svg': 'smartphone-cubot-x30-frozen.svg',
    'smartphone-cubot-x30.svg': 'smartphone-cubot-x30.svg',
    'smartphone-digicel-dl3-frozen.svg': 'smartphone-digicel-dl3-frozen.svg',
    'smartphone-digicel-dl3.svg': 'smartphone-digicel-dl3.svg',
    'smartphone-digicel-dl501-frozen.svg':
      'smartphone-digicel-dl501-frozen.svg',
    'smartphone-digicel-dl501.svg': 'smartphone-digicel-dl501.svg',
    'smartphone-doogee-n20-frozen.svg': 'smartphone-doogee-n20-frozen.svg',
    'smartphone-doogee-n20.svg': 'smartphone-doogee-n20.svg',
    'smartphone-evercoss-a75b-frozen.svg':
      'smartphone-evercoss-a75b-frozen.svg',
    'smartphone-evercoss-a75b.svg': 'smartphone-evercoss-a75b.svg',
    'smartphone-foxxd-miro-frozen.svg': 'smartphone-foxxd-miro-frozen.svg',
    'smartphone-foxxd-miro.svg': 'smartphone-foxxd-miro.svg',
    'smartphone-freetel-raijin-frozen.svg':
      'smartphone-freetel-raijin-frozen.svg',
    'smartphone-freetel-raijin.svg': 'smartphone-freetel-raijin.svg',
    'smartphone-freetel-samurai-frozen.svg':
      'smartphone-freetel-samurai-frozen.svg',
    'smartphone-freetel-samurai.svg': 'smartphone-freetel-samurai.svg',
    'smartphone-frozen.svg': 'smartphone-frozen.svg',
    'smartphone-fujitsu-arrows-frozen.svg':
      'smartphone-fujitsu-arrows-frozen.svg',
    'smartphone-fujitsu-arrows.svg': 'smartphone-fujitsu-arrows.svg',
    'smartphone-fujitsu-easyphone-frozen.svg':
      'smartphone-fujitsu-easyphone-frozen.svg',
    'smartphone-fujitsu-easyphone.svg': 'smartphone-fujitsu-easyphone.svg',
    'smartphone-fujitsu-f04j-frozen.svg': 'smartphone-fujitsu-f04j-frozen.svg',
    'smartphone-fujitsu-f04j.svg': 'smartphone-fujitsu-f04j.svg',
    'smartphone-fujitsu-smartphone-frozen.svg':
      'smartphone-fujitsu-smartphone-frozen.svg',
    'smartphone-fujitsu-smartphone.svg': 'smartphone-fujitsu-smartphone.svg',
    'smartphone-google-nexus-frozen.svg': 'smartphone-google-nexus-frozen.svg',
    'smartphone-google-nexus.svg': 'smartphone-google-nexus.svg',
    'smartphone-google-pixel-frozen.svg': 'smartphone-google-pixel-frozen.svg',
    'smartphone-google-pixel.svg': 'smartphone-google-pixel.svg',
    'smartphone-honeywell-scanpal-frozen.svg':
      'smartphone-honeywell-scanpal-frozen.svg',
    'smartphone-honeywell-scanpal.svg': 'smartphone-honeywell-scanpal.svg',
    'smartphone-hotpepper-poblano-frozen.svg':
      'smartphone-hotpepper-poblano-frozen.svg',
    'smartphone-hotpepper-poblano.svg': 'smartphone-hotpepper-poblano.svg',
    'smartphone-htc-10-frozen.svg': 'smartphone-htc-10-frozen.svg',
    'smartphone-htc-10.svg': 'smartphone-htc-10.svg',
    'smartphone-htc-butterfly-frozen.svg':
      'smartphone-htc-butterfly-frozen.svg',
    'smartphone-htc-butterfly.svg': 'smartphone-htc-butterfly.svg',
    'smartphone-htc-j-frozen.svg': 'smartphone-htc-j-frozen.svg',
    'smartphone-htc-j.svg': 'smartphone-htc-j.svg',
    'smartphone-htc-one-frozen.svg': 'smartphone-htc-one-frozen.svg',
    'smartphone-htc-one.svg': 'smartphone-htc-one.svg',
    'smartphone-htc-u11-frozen.svg': 'smartphone-htc-u11-frozen.svg',
    'smartphone-htc-u11.svg': 'smartphone-htc-u11.svg',
    'smartphone-huawei-ascend-frozen.svg':
      'smartphone-huawei-ascend-frozen.svg',
    'smartphone-huawei-ascend.svg': 'smartphone-huawei-ascend.svg',
    'smartphone-huawei-ascendxt-frozen.svg':
      'smartphone-huawei-ascendxt-frozen.svg',
    'smartphone-huawei-ascendxt.svg': 'smartphone-huawei-ascendxt.svg',
    'smartphone-huawei-enjoy-frozen.svg': 'smartphone-huawei-enjoy-frozen.svg',
    'smartphone-huawei-enjoy.svg': 'smartphone-huawei-enjoy.svg',
    'smartphone-huawei-frozen.svg': 'smartphone-huawei-frozen.svg',
    'smartphone-huawei-h1621-frozen.svg': 'smartphone-huawei-h1621-frozen.svg',
    'smartphone-huawei-h1621.svg': 'smartphone-huawei-h1621.svg',
    'smartphone-huawei-honor-frozen.svg': 'smartphone-huawei-honor-frozen.svg',
    'smartphone-huawei-honor.svg': 'smartphone-huawei-honor.svg',
    'smartphone-huawei-maimang-frozen.svg':
      'smartphone-huawei-maimang-frozen.svg',
    'smartphone-huawei-maimang.svg': 'smartphone-huawei-maimang.svg',
    'smartphone-huawei-mate-frozen.svg': 'smartphone-huawei-mate-frozen.svg',
    'smartphone-huawei-mate.svg': 'smartphone-huawei-mate.svg',
    'smartphone-huawei-mate30-frozen.svg':
      'smartphone-huawei-mate30-frozen.svg',
    'smartphone-huawei-mate30.svg': 'smartphone-huawei-mate30.svg',
    'smartphone-huawei-mate40-frozen.svg':
      'smartphone-huawei-mate40-frozen.svg',
    'smartphone-huawei-mate40.svg': 'smartphone-huawei-mate40.svg',
    'smartphone-huawei-nova-frozen.svg': 'smartphone-huawei-nova-frozen.svg',
    'smartphone-huawei-nova.svg': 'smartphone-huawei-nova.svg',
    'smartphone-huawei-p10-frozen.svg': 'smartphone-huawei-p10-frozen.svg',
    'smartphone-huawei-p10.svg': 'smartphone-huawei-p10.svg',
    'smartphone-huawei-p20-frozen.svg': 'smartphone-huawei-p20-frozen.svg',
    'smartphone-huawei-p20.svg': 'smartphone-huawei-p20.svg',
    'smartphone-huawei-p30-frozen.svg': 'smartphone-huawei-p30-frozen.svg',
    'smartphone-huawei-p30.svg': 'smartphone-huawei-p30.svg',
    'smartphone-huawei-p8-1.svg': 'smartphone-huawei-p8-1.svg',
    'smartphone-huawei-p8-frozen-1.svg': 'smartphone-huawei-p8-frozen-1.svg',
    'smartphone-huawei-p8-frozen.svg': 'smartphone-huawei-p8-frozen.svg',
    'smartphone-huawei-p8.svg': 'smartphone-huawei-p8.svg',
    'smartphone-huawei-p9-frozen.svg': 'smartphone-huawei-p9-frozen.svg',
    'smartphone-huawei-p9.svg': 'smartphone-huawei-p9.svg',
    'smartphone-huawei-sensa-frozen.svg': 'smartphone-huawei-sensa-frozen.svg',
    'smartphone-huawei-sensa.svg': 'smartphone-huawei-sensa.svg',
    'smartphone-huawei-y6-frozen.svg': 'smartphone-huawei-y6-frozen.svg',
    'smartphone-huawei-y6.svg': 'smartphone-huawei-y6.svg',
    'smartphone-huawei-y9-frozen.svg': 'smartphone-huawei-y9-frozen.svg',
    'smartphone-huawei-y9.svg': 'smartphone-huawei-y9.svg',
    'smartphone-huawei.svg': 'smartphone-huawei.svg',
    'smartphone-kazuna-etalk-frozen.svg': 'smartphone-kazuna-etalk-frozen.svg',
    'smartphone-kazuna-etalk.svg': 'smartphone-kazuna-etalk.svg',
    'smartphone-kyocera-1.svg': 'smartphone-kyocera-1.svg',
    'smartphone-kyocera-androidone-frozen.svg':
      'smartphone-kyocera-androidone-frozen.svg',
    'smartphone-kyocera-androidone.svg': 'smartphone-kyocera-androidone.svg',
    'smartphone-kyocera-basio-frozen.svg':
      'smartphone-kyocera-basio-frozen.svg',
    'smartphone-kyocera-basio.svg': 'smartphone-kyocera-basio.svg',
    'smartphone-kyocera-basio3-frozen.svg':
      'smartphone-kyocera-basio3-frozen.svg',
    'smartphone-kyocera-basio3.svg': 'smartphone-kyocera-basio3.svg',
    'smartphone-kyocera-basio4-frozen.svg':
      'smartphone-kyocera-basio4-frozen.svg',
    'smartphone-kyocera-basio4.svg': 'smartphone-kyocera-basio4.svg',
    'smartphone-kyocera-cadence-frozen.svg':
      'smartphone-kyocera-cadence-frozen.svg',
    'smartphone-kyocera-cadence.svg': 'smartphone-kyocera-cadence.svg',
    'smartphone-kyocera-digno-frozen.svg':
      'smartphone-kyocera-digno-frozen.svg',
    'smartphone-kyocera-digno.svg': 'smartphone-kyocera-digno.svg',
    'smartphone-kyocera-dignorafre-frozen.svg':
      'smartphone-kyocera-dignorafre-frozen.svg',
    'smartphone-kyocera-dignorafre.svg': 'smartphone-kyocera-dignorafre.svg',
    'smartphone-kyocera-duraxv-1.svg': 'smartphone-kyocera-duraxv-1.svg',
    'smartphone-kyocera-duraxv-frozen-1.svg':
      'smartphone-kyocera-duraxv-frozen-1.svg',
    'smartphone-kyocera-duraxv-frozen.svg':
      'smartphone-kyocera-duraxv-frozen.svg',
    'smartphone-kyocera-duraxv.svg': 'smartphone-kyocera-duraxv.svg',
    'smartphone-kyocera-frozen-1.svg': 'smartphone-kyocera-frozen-1.svg',
    'smartphone-kyocera-frozen.svg': 'smartphone-kyocera-frozen.svg',
    'smartphone-kyocera-gratina-frozen.svg':
      'smartphone-kyocera-gratina-frozen.svg',
    'smartphone-kyocera-gratina.svg': 'smartphone-kyocera-gratina.svg',
    'smartphone-kyocera-gratina4g-frozen.svg':
      'smartphone-kyocera-gratina4g-frozen.svg',
    'smartphone-kyocera-gratina4g.svg': 'smartphone-kyocera-gratina4g.svg',
    'smartphone-kyocera-infobar-frozen.svg':
      'smartphone-kyocera-infobar-frozen.svg',
    'smartphone-kyocera-infobar.svg': 'smartphone-kyocera-infobar.svg',
    'smartphone-kyocera-kantan-frozen.svg':
      'smartphone-kyocera-kantan-frozen.svg',
    'smartphone-kyocera-kantan.svg': 'smartphone-kyocera-kantan.svg',
    'smartphone-kyocera-marvera-frozen.svg':
      'smartphone-kyocera-marvera-frozen.svg',
    'smartphone-kyocera-marvera.svg': 'smartphone-kyocera-marvera.svg',
    'smartphone-kyocera-miraie-frozen.svg':
      'smartphone-kyocera-miraie-frozen.svg',
    'smartphone-kyocera-miraie.svg': 'smartphone-kyocera-miraie.svg',
    'smartphone-kyocera-qua-frozen.svg': 'smartphone-kyocera-qua-frozen.svg',
    'smartphone-kyocera-qua.svg': 'smartphone-kyocera-qua.svg',
    'smartphone-kyocera-quaphone-frozen.svg':
      'smartphone-kyocera-quaphone-frozen.svg',
    'smartphone-kyocera-quaphone.svg': 'smartphone-kyocera-quaphone.svg',
    'smartphone-kyocera-rafre-frozen.svg':
      'smartphone-kyocera-rafre-frozen.svg',
    'smartphone-kyocera-rafre.svg': 'smartphone-kyocera-rafre.svg',
    'smartphone-kyocera-torque-frozen.svg':
      'smartphone-kyocera-torque-frozen.svg',
    'smartphone-kyocera-torque.svg': 'smartphone-kyocera-torque.svg',
    'smartphone-kyocera-urbano-frozen.svg':
      'smartphone-kyocera-urbano-frozen.svg',
    'smartphone-kyocera-urbano.svg': 'smartphone-kyocera-urbano.svg',
    'smartphone-kyocera.svg': 'smartphone-kyocera.svg',
    'smartphone-lenovo-701lv-1.svg': 'smartphone-lenovo-701lv-1.svg',
    'smartphone-lenovo-701lv-frozen-1.svg':
      'smartphone-lenovo-701lv-frozen-1.svg',
    'smartphone-lenovo-701lv-frozen.svg': 'smartphone-lenovo-701lv-frozen.svg',
    'smartphone-lenovo-701lv.svg': 'smartphone-lenovo-701lv.svg',
    'smartphone-letv-leeco-frozen.svg': 'smartphone-letv-leeco-frozen.svg',
    'smartphone-letv-leeco.svg': 'smartphone-letv-leeco.svg',
    'smartphone-lg-arena-frozen.svg': 'smartphone-lg-arena-frozen.svg',
    'smartphone-lg-arena.svg': 'smartphone-lg-arena.svg',
    'smartphone-lg-aristo-frozen.svg': 'smartphone-lg-aristo-frozen.svg',
    'smartphone-lg-aristo.svg': 'smartphone-lg-aristo.svg',
    'smartphone-lg-d160-frozen.svg': 'smartphone-lg-d160-frozen.svg',
    'smartphone-lg-d160.svg': 'smartphone-lg-d160.svg',
    'smartphone-lg-escape-frozen.svg': 'smartphone-lg-escape-frozen.svg',
    'smartphone-lg-escape.svg': 'smartphone-lg-escape.svg',
    'smartphone-lg-exalt-frozen.svg': 'smartphone-lg-exalt-frozen.svg',
    'smartphone-lg-exalt.svg': 'smartphone-lg-exalt.svg',
    'smartphone-lg-fortune-frozen.svg': 'smartphone-lg-fortune-frozen.svg',
    'smartphone-lg-fortune.svg': 'smartphone-lg-fortune.svg',
    'smartphone-lg-g6-frozen.svg': 'smartphone-lg-g6-frozen.svg',
    'smartphone-lg-g6.svg': 'smartphone-lg-g6.svg',
    'smartphone-lg-g7-frozen.svg': 'smartphone-lg-g7-frozen.svg',
    'smartphone-lg-g7.svg': 'smartphone-lg-g7.svg',
    'smartphone-lg-g8-frozen.svg': 'smartphone-lg-g8-frozen.svg',
    'smartphone-lg-g8.svg': 'smartphone-lg-g8.svg',
    'smartphone-lg-g8thinq-frozen.svg': 'smartphone-lg-g8thinq-frozen.svg',
    'smartphone-lg-g8thinq.svg': 'smartphone-lg-g8thinq.svg',
    'smartphone-lg-g8x-frozen.svg': 'smartphone-lg-g8x-frozen.svg',
    'smartphone-lg-g8x.svg': 'smartphone-lg-g8x.svg',
    'smartphone-lg-harmony-frozen.svg': 'smartphone-lg-harmony-frozen.svg',
    'smartphone-lg-harmony.svg': 'smartphone-lg-harmony.svg',
    'smartphone-lg-it-frozen.svg': 'smartphone-lg-it-frozen.svg',
    'smartphone-lg-it.svg': 'smartphone-lg-it.svg',
    'smartphone-lg-journey-frozen.svg': 'smartphone-lg-journey-frozen.svg',
    'smartphone-lg-journey.svg': 'smartphone-lg-journey.svg',
    'smartphone-lg-k10-frozen.svg': 'smartphone-lg-k10-frozen.svg',
    'smartphone-lg-k10.svg': 'smartphone-lg-k10.svg',
    'smartphone-lg-k22-1.svg': 'smartphone-lg-k22-1.svg',
    'smartphone-lg-k22-frozen-1.svg': 'smartphone-lg-k22-frozen-1.svg',
    'smartphone-lg-k22-frozen.svg': 'smartphone-lg-k22-frozen.svg',
    'smartphone-lg-k22.svg': 'smartphone-lg-k22.svg',
    'smartphone-lg-k30-frozen.svg': 'smartphone-lg-k30-frozen.svg',
    'smartphone-lg-k30.svg': 'smartphone-lg-k30.svg',
    'smartphone-lg-k31-frozen.svg': 'smartphone-lg-k31-frozen.svg',
    'smartphone-lg-k31.svg': 'smartphone-lg-k31.svg',
    'smartphone-lg-k40-frozen.svg': 'smartphone-lg-k40-frozen.svg',
    'smartphone-lg-k40.svg': 'smartphone-lg-k40.svg',
    'smartphone-lg-k40s-frozen.svg': 'smartphone-lg-k40s-frozen.svg',
    'smartphone-lg-k40s.svg': 'smartphone-lg-k40s.svg',
    'smartphone-lg-k41s-frozen.svg': 'smartphone-lg-k41s-frozen.svg',
    'smartphone-lg-k41s.svg': 'smartphone-lg-k41s.svg',
    'smartphone-lg-k42-frozen.svg': 'smartphone-lg-k42-frozen.svg',
    'smartphone-lg-k42.svg': 'smartphone-lg-k42.svg',
    'smartphone-lg-k50-frozen.svg': 'smartphone-lg-k50-frozen.svg',
    'smartphone-lg-k50.svg': 'smartphone-lg-k50.svg',
    'smartphone-lg-k50s-frozen.svg': 'smartphone-lg-k50s-frozen.svg',
    'smartphone-lg-k50s.svg': 'smartphone-lg-k50s.svg',
    'smartphone-lg-k51-frozen.svg': 'smartphone-lg-k51-frozen.svg',
    'smartphone-lg-k51.svg': 'smartphone-lg-k51.svg',
    'smartphone-lg-k61-frozen.svg': 'smartphone-lg-k61-frozen.svg',
    'smartphone-lg-k61.svg': 'smartphone-lg-k61.svg',
    'smartphone-lg-k8x-frozen.svg': 'smartphone-lg-k8x-frozen.svg',
    'smartphone-lg-k8x.svg': 'smartphone-lg-k8x.svg',
    'smartphone-lg-k92-frozen.svg': 'smartphone-lg-k92-frozen.svg',
    'smartphone-lg-k92.svg': 'smartphone-lg-k92.svg',
    'smartphone-lg-neon-frozen.svg': 'smartphone-lg-neon-frozen.svg',
    'smartphone-lg-neon.svg': 'smartphone-lg-neon.svg',
    'smartphone-lg-phoenix-frozen.svg': 'smartphone-lg-phoenix-frozen.svg',
    'smartphone-lg-phoenix.svg': 'smartphone-lg-phoenix.svg',
    'smartphone-lg-premier-frozen.svg': 'smartphone-lg-premier-frozen.svg',
    'smartphone-lg-premier.svg': 'smartphone-lg-premier.svg',
    'smartphone-lg-premierpro-frozen.svg':
      'smartphone-lg-premierpro-frozen.svg',
    'smartphone-lg-premierpro.svg': 'smartphone-lg-premierpro.svg',
    'smartphone-lg-prime-frozen.svg': 'smartphone-lg-prime-frozen.svg',
    'smartphone-lg-prime.svg': 'smartphone-lg-prime.svg',
    'smartphone-lg-q60-frozen.svg': 'smartphone-lg-q60-frozen.svg',
    'smartphone-lg-q60.svg': 'smartphone-lg-q60.svg',
    'smartphone-lg-q7-frozen.svg': 'smartphone-lg-q7-frozen.svg',
    'smartphone-lg-q7.svg': 'smartphone-lg-q7.svg',
    'smartphone-lg-q70-frozen.svg': 'smartphone-lg-q70-frozen.svg',
    'smartphone-lg-q70.svg': 'smartphone-lg-q70.svg',
    'smartphone-lg-qstylus-frozen.svg': 'smartphone-lg-qstylus-frozen.svg',
    'smartphone-lg-qstylus.svg': 'smartphone-lg-qstylus.svg',
    'smartphone-lg-reflect-frozen.svg': 'smartphone-lg-reflect-frozen.svg',
    'smartphone-lg-reflect.svg': 'smartphone-lg-reflect.svg',
    'smartphone-lg-risio-frozen.svg': 'smartphone-lg-risio-frozen.svg',
    'smartphone-lg-risio.svg': 'smartphone-lg-risio.svg',
    'smartphone-lg-style-frozen.svg': 'smartphone-lg-style-frozen.svg',
    'smartphone-lg-style.svg': 'smartphone-lg-style.svg',
    'smartphone-lg-style2-frozen.svg': 'smartphone-lg-style2-frozen.svg',
    'smartphone-lg-style2.svg': 'smartphone-lg-style2.svg',
    'smartphone-lg-stylo-frozen.svg': 'smartphone-lg-stylo-frozen.svg',
    'smartphone-lg-stylo.svg': 'smartphone-lg-stylo.svg',
    'smartphone-lg-tribute-frozen.svg': 'smartphone-lg-tribute-frozen.svg',
    'smartphone-lg-tribute.svg': 'smartphone-lg-tribute.svg',
    'smartphone-lg-v30-frozen.svg': 'smartphone-lg-v30-frozen.svg',
    'smartphone-lg-v30.svg': 'smartphone-lg-v30.svg',
    'smartphone-lg-v35-frozen.svg': 'smartphone-lg-v35-frozen.svg',
    'smartphone-lg-v35.svg': 'smartphone-lg-v35.svg',
    'smartphone-lg-v40-frozen.svg': 'smartphone-lg-v40-frozen.svg',
    'smartphone-lg-v40.svg': 'smartphone-lg-v40.svg',
    'smartphone-lg-v50-frozen.svg': 'smartphone-lg-v50-frozen.svg',
    'smartphone-lg-v50.svg': 'smartphone-lg-v50.svg',
    'smartphone-lg-v60-frozen.svg': 'smartphone-lg-v60-frozen.svg',
    'smartphone-lg-v60.svg': 'smartphone-lg-v60.svg',
    'smartphone-lg-velvet-1.svg': 'smartphone-lg-velvet-1.svg',
    'smartphone-lg-velvet-frozen-1.svg': 'smartphone-lg-velvet-frozen-1.svg',
    'smartphone-lg-velvet-frozen.svg': 'smartphone-lg-velvet-frozen.svg',
    'smartphone-lg-velvet.svg': 'smartphone-lg-velvet.svg',
    'smartphone-lg-x-frozen.svg': 'smartphone-lg-x-frozen.svg',
    'smartphone-lg-x.svg': 'smartphone-lg-x.svg',
    'smartphone-lg-xpression-frozen.svg': 'smartphone-lg-xpression-frozen.svg',
    'smartphone-lg-xpression.svg': 'smartphone-lg-xpression.svg',
    'smartphone-logic-x50a-frozen.svg': 'smartphone-logic-x50a-frozen.svg',
    'smartphone-logic-x50a.svg': 'smartphone-logic-x50a.svg',
    'smartphone-microsoft-surface-frozen.svg':
      'smartphone-microsoft-surface-frozen.svg',
    'smartphone-microsoft-surface.svg': 'smartphone-microsoft-surface.svg',
    'smartphone-microsoft-windowsphone-frozen.svg':
      'smartphone-microsoft-windowsphone-frozen.svg',
    'smartphone-microsoft-windowsphone.svg':
      'smartphone-microsoft-windowsphone.svg',
    'smartphone-motorola-frozen.svg': 'smartphone-motorola-frozen.svg',
    'smartphone-motorola-moto-frozen.svg':
      'smartphone-motorola-moto-frozen.svg',
    'smartphone-motorola-moto.svg': 'smartphone-motorola-moto.svg',
    'smartphone-motorola.svg': 'smartphone-motorola.svg',
    'smartphone-myphone-prime-frozen.svg':
      'smartphone-myphone-prime-frozen.svg',
    'smartphone-myphone-prime.svg': 'smartphone-myphone-prime.svg',
    'smartphone-nec-101n-frozen.svg': 'smartphone-nec-101n-frozen.svg',
    'smartphone-nec-101n.svg': 'smartphone-nec-101n.svg',
    'smartphone-nextbook-ares-frozen.svg':
      'smartphone-nextbook-ares-frozen.svg',
    'smartphone-nextbook-ares.svg': 'smartphone-nextbook-ares.svg',
    'smartphone-nixplay-seed-frozen.svg': 'smartphone-nixplay-seed-frozen.svg',
    'smartphone-nixplay-seed.svg': 'smartphone-nixplay-seed.svg',
    'smartphone-nokia-oneplus-frozen.svg':
      'smartphone-nokia-oneplus-frozen.svg',
    'smartphone-nokia-oneplus.svg': 'smartphone-nokia-oneplus.svg',
    'smartphone-nokia-phone-frozen.svg': 'smartphone-nokia-phone-frozen.svg',
    'smartphone-nokia-phone.svg': 'smartphone-nokia-phone.svg',
    'smartphone-nubia-redmagic-frozen.svg':
      'smartphone-nubia-redmagic-frozen.svg',
    'smartphone-nubia-redmagic.svg': 'smartphone-nubia-redmagic.svg',
    'smartphone-nuumobile-a6l-frozen.svg':
      'smartphone-nuumobile-a6l-frozen.svg',
    'smartphone-nuumobile-a6l.svg': 'smartphone-nuumobile-a6l.svg',
    'smartphone-oneplus-3-frozen.svg': 'smartphone-oneplus-3-frozen.svg',
    'smartphone-oneplus-3.svg': 'smartphone-oneplus-3.svg',
    'smartphone-oneplus-3t-frozen.svg': 'smartphone-oneplus-3t-frozen.svg',
    'smartphone-oneplus-3t.svg': 'smartphone-oneplus-3t.svg',
    'smartphone-oneplus-5-frozen.svg': 'smartphone-oneplus-5-frozen.svg',
    'smartphone-oneplus-5.svg': 'smartphone-oneplus-5.svg',
    'smartphone-oneplus-5t-frozen.svg': 'smartphone-oneplus-5t-frozen.svg',
    'smartphone-oneplus-5t.svg': 'smartphone-oneplus-5t.svg',
    'smartphone-oneplus-6-frozen.svg': 'smartphone-oneplus-6-frozen.svg',
    'smartphone-oneplus-6.svg': 'smartphone-oneplus-6.svg',
    'smartphone-oneplus-7-frozen.svg': 'smartphone-oneplus-7-frozen.svg',
    'smartphone-oneplus-7.svg': 'smartphone-oneplus-7.svg',
    'smartphone-oneplus-7pro-frozen.svg': 'smartphone-oneplus-7pro-frozen.svg',
    'smartphone-oneplus-7pro.svg': 'smartphone-oneplus-7pro.svg',
    'smartphone-oneplus-7t-frozen.svg': 'smartphone-oneplus-7t-frozen.svg',
    'smartphone-oneplus-7t.svg': 'smartphone-oneplus-7t.svg',
    'smartphone-oneplus-8pro-frozen.svg': 'smartphone-oneplus-8pro-frozen.svg',
    'smartphone-oneplus-8pro.svg': 'smartphone-oneplus-8pro.svg',
    'smartphone-oneplus-8t-frozen.svg': 'smartphone-oneplus-8t-frozen.svg',
    'smartphone-oneplus-8t.svg': 'smartphone-oneplus-8t.svg',
    'smartphone-oneplus-frozen.svg': 'smartphone-oneplus-frozen.svg',
    'smartphone-oneplus-nord-frozen.svg': 'smartphone-oneplus-nord-frozen.svg',
    'smartphone-oneplus-nord.svg': 'smartphone-oneplus-nord.svg',
    'smartphone-oneplus.svg': 'smartphone-oneplus.svg',
    'smartphone-oppo-a1k-frozen.svg': 'smartphone-oppo-a1k-frozen.svg',
    'smartphone-oppo-a1k.svg': 'smartphone-oppo-a1k.svg',
    'smartphone-oppo-a31u-frozen.svg': 'smartphone-oppo-a31u-frozen.svg',
    'smartphone-oppo-a31u.svg': 'smartphone-oppo-a31u.svg',
    'smartphone-oppo-a3s-frozen.svg': 'smartphone-oppo-a3s-frozen.svg',
    'smartphone-oppo-a3s.svg': 'smartphone-oppo-a3s.svg',
    'smartphone-oppo-a5s-frozen.svg': 'smartphone-oppo-a5s-frozen.svg',
    'smartphone-oppo-a5s.svg': 'smartphone-oppo-a5s.svg',
    'smartphone-oppo-a7x-frozen.svg': 'smartphone-oppo-a7x-frozen.svg',
    'smartphone-oppo-a7x.svg': 'smartphone-oppo-a7x.svg',
    'smartphone-oppo-a83-frozen.svg': 'smartphone-oppo-a83-frozen.svg',
    'smartphone-oppo-a83.svg': 'smartphone-oppo-a83.svg',
    'smartphone-oppo-ax7-frozen.svg': 'smartphone-oppo-ax7-frozen.svg',
    'smartphone-oppo-ax7.svg': 'smartphone-oppo-ax7.svg',
    'smartphone-oppo-f11-frozen.svg': 'smartphone-oppo-f11-frozen.svg',
    'smartphone-oppo-f11.svg': 'smartphone-oppo-f11.svg',
    'smartphone-oppo-f9-frozen.svg': 'smartphone-oppo-f9-frozen.svg',
    'smartphone-oppo-f9.svg': 'smartphone-oppo-f9.svg',
    'smartphone-oppo-find-frozen.svg': 'smartphone-oppo-find-frozen.svg',
    'smartphone-oppo-find.svg': 'smartphone-oppo-find.svg',
    'smartphone-oppo-r11-frozen.svg': 'smartphone-oppo-r11-frozen.svg',
    'smartphone-oppo-r11.svg': 'smartphone-oppo-r11.svg',
    'smartphone-oppo-r11s-frozen.svg': 'smartphone-oppo-r11s-frozen.svg',
    'smartphone-oppo-r11s.svg': 'smartphone-oppo-r11s.svg',
    'smartphone-oppo-r15-frozen.svg': 'smartphone-oppo-r15-frozen.svg',
    'smartphone-oppo-r15.svg': 'smartphone-oppo-r15.svg',
    'smartphone-oppo-r17-frozen.svg': 'smartphone-oppo-r17-frozen.svg',
    'smartphone-oppo-r17.svg': 'smartphone-oppo-r17.svg',
    'smartphone-oppo-reno-frozen.svg': 'smartphone-oppo-reno-frozen.svg',
    'smartphone-oppo-reno.svg': 'smartphone-oppo-reno.svg',
    'smartphone-oppo-reno2-frozen.svg': 'smartphone-oppo-reno2-frozen.svg',
    'smartphone-oppo-reno2.svg': 'smartphone-oppo-reno2.svg',
    'smartphone-oppo-reno4-frozen.svg': 'smartphone-oppo-reno4-frozen.svg',
    'smartphone-oppo-reno4.svg': 'smartphone-oppo-reno4.svg',
    'smartphone-oukitel-k10-frozen.svg': 'smartphone-oukitel-k10-frozen.svg',
    'smartphone-oukitel-k10.svg': 'smartphone-oukitel-k10.svg',
    'smartphone-oukitel-k7-frozen.svg': 'smartphone-oukitel-k7-frozen.svg',
    'smartphone-oukitel-k7.svg': 'smartphone-oukitel-k7.svg',
    'smartphone-oukitel-u23-frozen.svg': 'smartphone-oukitel-u23-frozen.svg',
    'smartphone-oukitel-u23.svg': 'smartphone-oukitel-u23.svg',
    'smartphone-oukitel-wp5-frozen.svg': 'smartphone-oukitel-wp5-frozen.svg',
    'smartphone-oukitel-wp5.svg': 'smartphone-oukitel-wp5.svg',
    'smartphone-oukitel-wp6-frozen.svg': 'smartphone-oukitel-wp6-frozen.svg',
    'smartphone-oukitel-wp6.svg': 'smartphone-oukitel-wp6.svg',
    'smartphone-p43-pro-frozen.svg': 'smartphone-p43-pro-frozen.svg',
    'smartphone-p43-pro.svg': 'smartphone-p43-pro.svg',
    'smartphone-palm-pvg100-frozen.svg': 'smartphone-palm-pvg100-frozen.svg',
    'smartphone-palm-pvg100.svg': 'smartphone-palm-pvg100.svg',
    'smartphone-qilive-q8s6in4g-frozen.svg':
      'smartphone-qilive-q8s6in4g-frozen.svg',
    'smartphone-qilive-q8s6in4g.svg': 'smartphone-qilive-q8s6in4g.svg',
    'smartphone-realme-2-frozen.svg': 'smartphone-realme-2-frozen.svg',
    'smartphone-realme-2.svg': 'smartphone-realme-2.svg',
    'smartphone-realme-3-frozen.svg': 'smartphone-realme-3-frozen.svg',
    'smartphone-realme-3.svg': 'smartphone-realme-3.svg',
    'smartphone-realme-5-frozen.svg': 'smartphone-realme-5-frozen.svg',
    'smartphone-realme-5.svg': 'smartphone-realme-5.svg',
    'smartphone-realme-5i-frozen.svg': 'smartphone-realme-5i-frozen.svg',
    'smartphone-realme-5i.svg': 'smartphone-realme-5i.svg',
    'smartphone-realme-6-frozen.svg': 'smartphone-realme-6-frozen.svg',
    'smartphone-realme-6.svg': 'smartphone-realme-6.svg',
    'smartphone-realme-c2-frozen.svg': 'smartphone-realme-c2-frozen.svg',
    'smartphone-realme-c2.svg': 'smartphone-realme-c2.svg',
    'smartphone-realme-x2-frozen.svg': 'smartphone-realme-x2-frozen.svg',
    'smartphone-realme-x2.svg': 'smartphone-realme-x2.svg',
    'smartphone-red-hydrogenone-frozen.svg':
      'smartphone-red-hydrogenone-frozen.svg',
    'smartphone-red-hydrogenone.svg': 'smartphone-red-hydrogenone.svg',
    'smartphone-samsung-galaxy-frozen.svg':
      'smartphone-samsung-galaxy-frozen.svg',
    'smartphone-samsung-galaxy.svg': 'smartphone-samsung-galaxy.svg',
    'smartphone-samsung-omnia-frozen.svg':
      'smartphone-samsung-omnia-frozen.svg',
    'smartphone-samsung-omnia.svg': 'smartphone-samsung-omnia.svg',
    'smartphone-samsung-sghi957-frozen.svg':
      'smartphone-samsung-sghi957-frozen.svg',
    'smartphone-samsung-sghi957.svg': 'smartphone-samsung-sghi957.svg',
    'smartphone-sharp-androidone-frozen.svg':
      'smartphone-sharp-androidone-frozen.svg',
    'smartphone-sharp-androidone.svg': 'smartphone-sharp-androidone.svg',
    'smartphone-sharp-aquos-frozen.svg': 'smartphone-sharp-aquos-frozen.svg',
    'smartphone-sharp-aquos.svg': 'smartphone-sharp-aquos.svg',
    'smartphone-sharp-basio2-frozen.svg': 'smartphone-sharp-basio2-frozen.svg',
    'smartphone-sharp-basio2.svg': 'smartphone-sharp-basio2.svg',
    'smartphone-sharp-simplesumaho-frozen.svg':
      'smartphone-sharp-simplesumaho-frozen.svg',
    'smartphone-sharp-simplesumaho.svg': 'smartphone-sharp-simplesumaho.svg',
    'smartphone-skydevices-elite-frozen.svg':
      'smartphone-skydevices-elite-frozen.svg',
    'smartphone-skydevices-elite.svg': 'smartphone-skydevices-elite.svg',
    'smartphone-sonim-xp8-frozen.svg': 'smartphone-sonim-xp8-frozen.svg',
    'smartphone-sonim-xp8.svg': 'smartphone-sonim-xp8.svg',
    'smartphone-sony-frozen.svg': 'smartphone-sony-frozen.svg',
    'smartphone-sony-xperia-frozen.svg': 'smartphone-sony-xperia-frozen.svg',
    'smartphone-sony-xperia.svg': 'smartphone-sony-xperia.svg',
    'smartphone-sony.svg': 'smartphone-sony.svg',
    'smartphone-sprint-slate-frozen.svg': 'smartphone-sprint-slate-frozen.svg',
    'smartphone-sprint-slate.svg': 'smartphone-sprint-slate.svg',
    'smartphone-this-frozen.svg': 'smartphone-this-frozen.svg',
    'smartphone-this.svg': 'smartphone-this.svg',
    'smartphone-tmobile-revvl-frozen.svg':
      'smartphone-tmobile-revvl-frozen.svg',
    'smartphone-tmobile-revvl.svg': 'smartphone-tmobile-revvl.svg',
    'smartphone-tmobile-tcl-frozen.svg': 'smartphone-tmobile-tcl-frozen.svg',
    'smartphone-tmobile-tcl.svg': 'smartphone-tmobile-tcl.svg',
    'smartphone-truconnect-flame-frozen.svg':
      'smartphone-truconnect-flame-frozen.svg',
    'smartphone-truconnect-flame.svg': 'smartphone-truconnect-flame.svg',
    'smartphone-ulefone-note9p-frozen.svg':
      'smartphone-ulefone-note9p-frozen.svg',
    'smartphone-ulefone-note9p.svg': 'smartphone-ulefone-note9p.svg',
    'smartphone-umidigi-a7-frozen.svg': 'smartphone-umidigi-a7-frozen.svg',
    'smartphone-umidigi-a7.svg': 'smartphone-umidigi-a7.svg',
    'smartphone-unimax-tracfone-frozen.svg':
      'smartphone-unimax-tracfone-frozen.svg',
    'smartphone-unimax-tracfone.svg': 'smartphone-unimax-tracfone.svg',
    'smartphone-unimax-umx-frozen.svg': 'smartphone-unimax-umx-frozen.svg',
    'smartphone-unimax-umx.svg': 'smartphone-unimax-umx.svg',
    'smartphone-vaio-phone-frozen.svg': 'smartphone-vaio-phone-frozen.svg',
    'smartphone-vaio-phone.svg': 'smartphone-vaio-phone.svg',
    'smartphone-verizon-orbicwonder-frozen.svg':
      'smartphone-verizon-orbicwonder-frozen.svg',
    'smartphone-verizon-orbicwonder.svg': 'smartphone-verizon-orbicwonder.svg',
    'smartphone-vinsmart-v341u-frozen.svg':
      'smartphone-vinsmart-v341u-frozen.svg',
    'smartphone-vinsmart-v341u.svg': 'smartphone-vinsmart-v341u.svg',
    'smartphone-vivo-iqoo-frozen.svg': 'smartphone-vivo-iqoo-frozen.svg',
    'smartphone-vivo-iqoo.svg': 'smartphone-vivo-iqoo.svg',
    'smartphone-vivo-phone-frozen.svg': 'smartphone-vivo-phone-frozen.svg',
    'smartphone-vivo-phone.svg': 'smartphone-vivo-phone.svg',
    'smartphone-vivo-y93s-frozen.svg': 'smartphone-vivo-y93s-frozen.svg',
    'smartphone-vivo-y93s.svg': 'smartphone-vivo-y93s.svg',
    'smartphone-wiko-life-frozen.svg': 'smartphone-wiko-life-frozen.svg',
    'smartphone-wiko-life.svg': 'smartphone-wiko-life.svg',
    'smartphone-wiko-smartphone-frozen.svg':
      'smartphone-wiko-smartphone-frozen.svg',
    'smartphone-wiko-smartphone.svg': 'smartphone-wiko-smartphone.svg',
    'smartphone-wiko-view2-frozen.svg': 'smartphone-wiko-view2-frozen.svg',
    'smartphone-wiko-view2.svg': 'smartphone-wiko-view2.svg',
    'smartphone-wiko-view3-frozen.svg': 'smartphone-wiko-view3-frozen.svg',
    'smartphone-wiko-view3.svg': 'smartphone-wiko-view3.svg',
    'smartphone-wiko-y80-frozen.svg': 'smartphone-wiko-y80-frozen.svg',
    'smartphone-wiko-y80.svg': 'smartphone-wiko-y80.svg',
    'smartphone-windowsphone-frozen.svg': 'smartphone-windowsphone-frozen.svg',
    'smartphone-windowsphone.svg': 'smartphone-windowsphone.svg',
    'smartphone-xiaomi-blackshark-frozen.svg':
      'smartphone-xiaomi-blackshark-frozen.svg',
    'smartphone-xiaomi-blackshark.svg': 'smartphone-xiaomi-blackshark.svg',
    'smartphone-xiaomi-mi-frozen.svg': 'smartphone-xiaomi-mi-frozen.svg',
    'smartphone-xiaomi-mi.svg': 'smartphone-xiaomi-mi.svg',
    'smartphone-xiaomi-mix-frozen.svg': 'smartphone-xiaomi-mix-frozen.svg',
    'smartphone-xiaomi-mix.svg': 'smartphone-xiaomi-mix.svg',
    'smartphone-xiaomi-poco-frozen.svg': 'smartphone-xiaomi-poco-frozen.svg',
    'smartphone-xiaomi-poco.svg': 'smartphone-xiaomi-poco.svg',
    'smartphone-xiaomi-pocophone-frozen.svg':
      'smartphone-xiaomi-pocophone-frozen.svg',
    'smartphone-xiaomi-pocophone.svg': 'smartphone-xiaomi-pocophone.svg',
    'smartphone-xiaomi-redmi-frozen.svg': 'smartphone-xiaomi-redmi-frozen.svg',
    'smartphone-xiaomi-redmi.svg': 'smartphone-xiaomi-redmi.svg',
    'smartphone-xiaomi-smartphone-frozen.svg':
      'smartphone-xiaomi-smartphone-frozen.svg',
    'smartphone-xiaomi-smartphone.svg': 'smartphone-xiaomi-smartphone.svg',
    'smartphone-zte-atrium-frozen.svg': 'smartphone-zte-atrium-frozen.svg',
    'smartphone-zte-atrium.svg': 'smartphone-zte-atrium.svg',
    'smartphone-zte-axon-frozen.svg': 'smartphone-zte-axon-frozen.svg',
    'smartphone-zte-axon.svg': 'smartphone-zte-axon.svg',
    'smartphone-zte-blade-frozen.svg': 'smartphone-zte-blade-frozen.svg',
    'smartphone-zte-blade.svg': 'smartphone-zte-blade.svg',
    'smartphone-zte-cymbal-frozen.svg': 'smartphone-zte-cymbal-frozen.svg',
    'smartphone-zte-cymbal.svg': 'smartphone-zte-cymbal.svg',
    'smartphone-zte-frozen.svg': 'smartphone-zte-frozen.svg',
    'smartphone-zte-grand-frozen.svg': 'smartphone-zte-grand-frozen.svg',
    'smartphone-zte-grand.svg': 'smartphone-zte-grand.svg',
    'smartphone-zte-grandx-frozen.svg': 'smartphone-zte-grandx-frozen.svg',
    'smartphone-zte-grandx.svg': 'smartphone-zte-grandx.svg',
    'smartphone-zte-lever-frozen.svg': 'smartphone-zte-lever-frozen.svg',
    'smartphone-zte-lever.svg': 'smartphone-zte-lever.svg',
    'smartphone-zte-libero-frozen.svg': 'smartphone-zte-libero-frozen.svg',
    'smartphone-zte-libero.svg': 'smartphone-zte-libero.svg',
    'smartphone-zte-mono-frozen.svg': 'smartphone-zte-mono-frozen.svg',
    'smartphone-zte-mono.svg': 'smartphone-zte-mono.svg',
    'smartphone-zte-qlink-frozen.svg': 'smartphone-zte-qlink-frozen.svg',
    'smartphone-zte-qlink.svg': 'smartphone-zte-qlink.svg',
    'smartphone-zte-quest-frozen.svg': 'smartphone-zte-quest-frozen.svg',
    'smartphone-zte-quest.svg': 'smartphone-zte-quest.svg',
    'smartphone-zte-savvy-frozen.svg': 'smartphone-zte-savvy-frozen.svg',
    'smartphone-zte-savvy.svg': 'smartphone-zte-savvy.svg',
    'smartphone-zte-visible-frozen.svg': 'smartphone-zte-visible-frozen.svg',
    'smartphone-zte-visible.svg': 'smartphone-zte-visible.svg',
    'smartphone-zte-warp-frozen.svg': 'smartphone-zte-warp-frozen.svg',
    'smartphone-zte-warp.svg': 'smartphone-zte-warp.svg',
    'smartphone-zte-zfive-frozen.svg': 'smartphone-zte-zfive-frozen.svg',
    'smartphone-zte-zfive.svg': 'smartphone-zte-zfive.svg',
    'smartphone-zte-zmax-frozen.svg': 'smartphone-zte-zmax-frozen.svg',
    'smartphone-zte-zmax.svg': 'smartphone-zte-zmax.svg',
    'smartphone-zte.svg': 'smartphone-zte.svg',
    'smartphone.svg': 'smartphone.svg',
    'smartremote-frozen.svg': 'smartremote-frozen.svg',
    'smartremote.svg': 'smartremote.svg',
    'smokealarm-frozen.svg': 'smokealarm-frozen.svg',
    'smokealarm-googlenest-protect-frozen.svg':
      'smokealarm-googlenest-protect-frozen.svg',
    'smokealarm-googlenest-protect.svg': 'smokealarm-googlenest-protect.svg',
    'smokealarm.svg': 'smokealarm.svg',
    'solar-enphase-envoy-frozen.svg': 'solar-enphase-envoy-frozen.svg',
    'solar-enphase-envoy.svg': 'solar-enphase-envoy.svg',
    'solar-frozen.svg': 'solar-frozen.svg',
    'solar.svg': 'solar.svg',
    'speaker-altice-amplify-frozen.svg': 'speaker-altice-amplify-frozen.svg',
    'speaker-altice-amplify.svg': 'speaker-altice-amplify.svg',
    'speaker-altice-frozen.svg': 'speaker-altice-frozen.svg',
    'speaker-altice.svg': 'speaker-altice.svg',
    'speaker-amazon-echo-frozen.svg': 'speaker-amazon-echo-frozen.svg',
    'speaker-amazon-echo.svg': 'speaker-amazon-echo.svg',
    'speaker-amazon-echodot-frozen.svg': 'speaker-amazon-echodot-frozen.svg',
    'speaker-amazon-echodot.svg': 'speaker-amazon-echodot.svg',
    'speaker-amazon-echosub-frozen.svg': 'speaker-amazon-echosub-frozen.svg',
    'speaker-amazon-echosub.svg': 'speaker-amazon-echosub.svg',
    'speaker-amazon-frozen.svg': 'speaker-amazon-frozen.svg',
    'speaker-amazon-tap-frozen.svg': 'speaker-amazon-tap-frozen.svg',
    'speaker-amazon-tap.svg': 'speaker-amazon-tap.svg',
    'speaker-amazon.svg': 'speaker-amazon.svg',
    'speaker-anker-frozen.svg': 'speaker-anker-frozen.svg',
    'speaker-anker-zolomojo-frozen.svg': 'speaker-anker-zolomojo-frozen.svg',
    'speaker-anker-zolomojo.svg': 'speaker-anker-zolomojo.svg',
    'speaker-anker.svg': 'speaker-anker.svg',
    'speaker-apple-frozen.svg': 'speaker-apple-frozen.svg',
    'speaker-apple-homepod-frozen.svg': 'speaker-apple-homepod-frozen.svg',
    'speaker-apple-homepod.svg': 'speaker-apple-homepod.svg',
    'speaker-apple.svg': 'speaker-apple.svg',
    'speaker-audiocast-frozen.svg': 'speaker-audiocast-frozen.svg',
    'speaker-audiocast-wifiaudio-frozen.svg':
      'speaker-audiocast-wifiaudio-frozen.svg',
    'speaker-audiocast-wifiaudio.svg': 'speaker-audiocast-wifiaudio.svg',
    'speaker-audiocast.svg': 'speaker-audiocast.svg',
    'speaker-audiopro-addon-frozen.svg': 'speaker-audiopro-addon-frozen.svg',
    'speaker-audiopro-addon.svg': 'speaker-audiopro-addon.svg',
    'speaker-audiopro-drumfire-frozen.svg':
      'speaker-audiopro-drumfire-frozen.svg',
    'speaker-audiopro-drumfire.svg': 'speaker-audiopro-drumfire.svg',
    'speaker-audiopro-frozen.svg': 'speaker-audiopro-frozen.svg',
    'speaker-audiopro.svg': 'speaker-audiopro.svg',
    'speaker-baidu-1.svg': 'speaker-baidu-1.svg',
    'speaker-baidu-frozen-1.svg': 'speaker-baidu-frozen-1.svg',
    'speaker-baidu-frozen.svg': 'speaker-baidu-frozen.svg',
    'speaker-baidu.svg': 'speaker-baidu.svg',
    'speaker-bangolufsen-beosound-frozen.svg':
      'speaker-bangolufsen-beosound-frozen.svg',
    'speaker-bangolufsen-beosound.svg': 'speaker-bangolufsen-beosound.svg',
    'speaker-bangolufson-1.svg': 'speaker-bangolufson-1.svg',
    'speaker-bangolufson-beolit-frozen.svg':
      'speaker-bangolufson-beolit-frozen.svg',
    'speaker-bangolufson-beolit.svg': 'speaker-bangolufson-beolit.svg',
    'speaker-bangolufson-beoplay-frozen.svg':
      'speaker-bangolufson-beoplay-frozen.svg',
    'speaker-bangolufson-beoplay.svg': 'speaker-bangolufson-beoplay.svg',
    'speaker-bangolufson-frozen-1.svg': 'speaker-bangolufson-frozen-1.svg',
    'speaker-bangolufson-frozen.svg': 'speaker-bangolufson-frozen.svg',
    'speaker-bangolufson.svg': 'speaker-bangolufson.svg',
    'speaker-belkin-frozen.svg': 'speaker-belkin-frozen.svg',
    'speaker-belkin-soundform-frozen.svg':
      'speaker-belkin-soundform-frozen.svg',
    'speaker-belkin-soundform.svg': 'speaker-belkin-soundform.svg',
    'speaker-belkin.svg': 'speaker-belkin.svg',
    'speaker-blackweb-frozen.svg': 'speaker-blackweb-frozen.svg',
    'speaker-blackweb-soundbar-frozen.svg':
      'speaker-blackweb-soundbar-frozen.svg',
    'speaker-blackweb-soundbar.svg': 'speaker-blackweb-soundbar.svg',
    'speaker-blackweb.svg': 'speaker-blackweb.svg',
    'speaker-bluesound-flexmediaplayer-frozen.svg':
      'speaker-bluesound-flexmediaplayer-frozen.svg',
    'speaker-bluesound-flexmediaplayer.svg':
      'speaker-bluesound-flexmediaplayer.svg',
    'speaker-bluesound-frozen.svg': 'speaker-bluesound-frozen.svg',
    'speaker-bluesound-pulsespeaker-frozen.svg':
      'speaker-bluesound-pulsespeaker-frozen.svg',
    'speaker-bluesound-pulsespeaker.svg': 'speaker-bluesound-pulsespeaker.svg',
    'speaker-bluesound-soundbar-frozen.svg':
      'speaker-bluesound-soundbar-frozen.svg',
    'speaker-bluesound-soundbar.svg': 'speaker-bluesound-soundbar.svg',
    'speaker-bluesound.svg': 'speaker-bluesound.svg',
    'speaker-bose-frozen.svg': 'speaker-bose-frozen.svg',
    'speaker-bose-homespeaker-frozen.svg':
      'speaker-bose-homespeaker-frozen.svg',
    'speaker-bose-homespeaker.svg': 'speaker-bose-homespeaker.svg',
    'speaker-bose-soundbar-frozen.svg': 'speaker-bose-soundbar-frozen.svg',
    'speaker-bose-soundbar.svg': 'speaker-bose-soundbar.svg',
    'speaker-bose-soundlink-frozen.svg': 'speaker-bose-soundlink-frozen.svg',
    'speaker-bose-soundlink.svg': 'speaker-bose-soundlink.svg',
    'speaker-bose-soundtouch-frozen.svg': 'speaker-bose-soundtouch-frozen.svg',
    'speaker-bose-soundtouch.svg': 'speaker-bose-soundtouch.svg',
    'speaker-bose-wavesoundtouch-frozen.svg':
      'speaker-bose-wavesoundtouch-frozen.svg',
    'speaker-bose-wavesoundtouch.svg': 'speaker-bose-wavesoundtouch.svg',
    'speaker-bose.svg': 'speaker-bose.svg',
    'speaker-bowerswilkins-a7-frozen.svg':
      'speaker-bowerswilkins-a7-frozen.svg',
    'speaker-bowerswilkins-a7.svg': 'speaker-bowerswilkins-a7.svg',
    'speaker-bowerswilkins-formation-frozen.svg':
      'speaker-bowerswilkins-formation-frozen.svg',
    'speaker-bowerswilkins-formation.svg':
      'speaker-bowerswilkins-formation.svg',
    'speaker-bowerswilkins-frozen.svg': 'speaker-bowerswilkins-frozen.svg',
    'speaker-bowerswilkins-zeppelin-frozen.svg':
      'speaker-bowerswilkins-zeppelin-frozen.svg',
    'speaker-bowerswilkins-zeppelin.svg': 'speaker-bowerswilkins-zeppelin.svg',
    'speaker-bowerswilkins.svg': 'speaker-bowerswilkins.svg',
    'speaker-cavalier-air-frozen.svg': 'speaker-cavalier-air-frozen.svg',
    'speaker-cavalier-air.svg': 'speaker-cavalier-air.svg',
    'speaker-cavalier-frozen.svg': 'speaker-cavalier-frozen.svg',
    'speaker-cavalier-maverick-frozen.svg':
      'speaker-cavalier-maverick-frozen.svg',
    'speaker-cavalier-maverick.svg': 'speaker-cavalier-maverick.svg',
    'speaker-cavalier.svg': 'speaker-cavalier.svg',
    'speaker-clova-frozen.svg': 'speaker-clova-frozen.svg',
    'speaker-clova-virtualassistant-frozen.svg':
      'speaker-clova-virtualassistant-frozen.svg',
    'speaker-clova-virtualassistant.svg': 'speaker-clova-virtualassistant.svg',
    'speaker-clova-wave-frozen.svg': 'speaker-clova-wave-frozen.svg',
    'speaker-clova-wave.svg': 'speaker-clova-wave.svg',
    'speaker-clova.svg': 'speaker-clova.svg',
    'speaker-control4-frozen.svg': 'speaker-control4-frozen.svg',
    'speaker-control4-speakerpoint-frozen.svg':
      'speaker-control4-speakerpoint-frozen.svg',
    'speaker-control4-speakerpoint.svg': 'speaker-control4-speakerpoint.svg',
    'speaker-control4-triad-frozen.svg': 'speaker-control4-triad-frozen.svg',
    'speaker-control4-triad.svg': 'speaker-control4-triad.svg',
    'speaker-control4.svg': 'speaker-control4.svg',
    'speaker-creative-frozen.svg': 'speaker-creative-frozen.svg',
    'speaker-creative-nova-frozen.svg': 'speaker-creative-nova-frozen.svg',
    'speaker-creative-nova.svg': 'speaker-creative-nova.svg',
    'speaker-creative-omni-frozen.svg': 'speaker-creative-omni-frozen.svg',
    'speaker-creative-omni.svg': 'speaker-creative-omni.svg',
    'speaker-creative.svg': 'speaker-creative.svg',
    'speaker-denon-frozen.svg': 'speaker-denon-frozen.svg',
    'speaker-denon-heos-frozen.svg': 'speaker-denon-heos-frozen.svg',
    'speaker-denon-heos.svg': 'speaker-denon-heos.svg',
    'speaker-denon-heos1-frozen.svg': 'speaker-denon-heos1-frozen.svg',
    'speaker-denon-heos1.svg': 'speaker-denon-heos1.svg',
    'speaker-denon-heosbar-frozen.svg': 'speaker-denon-heosbar-frozen.svg',
    'speaker-denon-heosbar.svg': 'speaker-denon-heosbar.svg',
    'speaker-denon-heoshomecinema-frozen.svg':
      'speaker-denon-heoshomecinema-frozen.svg',
    'speaker-denon-heoshomecinema.svg': 'speaker-denon-heoshomecinema.svg',
    'speaker-denon-heossubwoofer-frozen.svg':
      'speaker-denon-heossubwoofer-frozen.svg',
    'speaker-denon-heossubwoofer.svg': 'speaker-denon-heossubwoofer.svg',
    'speaker-denon-home-frozen.svg': 'speaker-denon-home-frozen.svg',
    'speaker-denon-home.svg': 'speaker-denon-home.svg',
    'speaker-denon.svg': 'speaker-denon.svg',
    'speaker-devialet-frozen.svg': 'speaker-devialet-frozen.svg',
    'speaker-devialet-goldphantom-frozen.svg':
      'speaker-devialet-goldphantom-frozen.svg',
    'speaker-devialet-goldphantom.svg': 'speaker-devialet-goldphantom.svg',
    'speaker-devialet-phantomreactor-frozen.svg':
      'speaker-devialet-phantomreactor-frozen.svg',
    'speaker-devialet-phantomreactor.svg':
      'speaker-devialet-phantomreactor.svg',
    'speaker-devialet.svg': 'speaker-devialet.svg',
    'speaker-echo-show-frozen.svg': 'speaker-echo-show-frozen.svg',
    'speaker-echo-show.svg': 'speaker-echo-show.svg',
    'speaker-echo-spot-frozen.svg': 'speaker-echo-spot-frozen.svg',
    'speaker-echo-spot.svg': 'speaker-echo-spot.svg',
    'speaker-eufy-frozen.svg': 'speaker-eufy-frozen.svg',
    'speaker-eufy-genie-frozen.svg': 'speaker-eufy-genie-frozen.svg',
    'speaker-eufy-genie.svg': 'speaker-eufy-genie.svg',
    'speaker-eufy.svg': 'speaker-eufy.svg',
    'speaker-fabriq-frozen.svg': 'speaker-fabriq-frozen.svg',
    'speaker-fabriq-wifispeaker-frozen.svg':
      'speaker-fabriq-wifispeaker-frozen.svg',
    'speaker-fabriq-wifispeaker.svg': 'speaker-fabriq-wifispeaker.svg',
    'speaker-fabriq.svg': 'speaker-fabriq.svg',
    'speaker-frozen.svg': 'speaker-frozen.svg',
    'speaker-ggmm-frozen.svg': 'speaker-ggmm-frozen.svg',
    'speaker-ggmm-smartspeaker-frozen.svg':
      'speaker-ggmm-smartspeaker-frozen.svg',
    'speaker-ggmm-smartspeaker.svg': 'speaker-ggmm-smartspeaker.svg',
    'speaker-ggmm.svg': 'speaker-ggmm.svg',
    'speaker-gohawk-frozen.svg': 'speaker-gohawk-frozen.svg',
    'speaker-gohawk-lit-frozen.svg': 'speaker-gohawk-lit-frozen.svg',
    'speaker-gohawk-lit.svg': 'speaker-gohawk-lit.svg',
    'speaker-gohawk.svg': 'speaker-gohawk.svg',
    'speaker-google-frozen.svg': 'speaker-google-frozen.svg',
    'speaker-google-home-frozen.svg': 'speaker-google-home-frozen.svg',
    'speaker-google-home.svg': 'speaker-google-home.svg',
    'speaker-google-nestaudio-frozen.svg':
      'speaker-google-nestaudio-frozen.svg',
    'speaker-google-nestaudio.svg': 'speaker-google-nestaudio.svg',
    'speaker-google.svg': 'speaker-google.svg',
    'speaker-harmankardon-allure-frozen.svg':
      'speaker-harmankardon-allure-frozen.svg',
    'speaker-harmankardon-allure.svg': 'speaker-harmankardon-allure.svg',
    'speaker-harmankardon-astra-frozen.svg':
      'speaker-harmankardon-astra-frozen.svg',
    'speaker-harmankardon-astra.svg': 'speaker-harmankardon-astra.svg',
    'speaker-harmankardon-aura-frozen.svg':
      'speaker-harmankardon-aura-frozen.svg',
    'speaker-harmankardon-aura.svg': 'speaker-harmankardon-aura.svg',
    'speaker-harmankardon-citation-frozen.svg':
      'speaker-harmankardon-citation-frozen.svg',
    'speaker-harmankardon-citation.svg': 'speaker-harmankardon-citation.svg',
    'speaker-harmankardon-enchant-frozen.svg':
      'speaker-harmankardon-enchant-frozen.svg',
    'speaker-harmankardon-enchant.svg': 'speaker-harmankardon-enchant.svg',
    'speaker-harmankardon-frozen.svg': 'speaker-harmankardon-frozen.svg',
    'speaker-harmankardon-invokespeaker-frozen.svg':
      'speaker-harmankardon-invokespeaker-frozen.svg',
    'speaker-harmankardon-invokespeaker.svg':
      'speaker-harmankardon-invokespeaker.svg',
    'speaker-harmankardon-omni-frozen.svg':
      'speaker-harmankardon-omni-frozen.svg',
    'speaker-harmankardon-omni.svg': 'speaker-harmankardon-omni.svg',
    'speaker-harmankardon.svg': 'speaker-harmankardon.svg',
    'speaker-ilive-frozen.svg': 'speaker-ilive-frozen.svg',
    'speaker-ilive-platinumconcierge-frozen.svg':
      'speaker-ilive-platinumconcierge-frozen.svg',
    'speaker-ilive-platinumconcierge.svg':
      'speaker-ilive-platinumconcierge.svg',
    'speaker-ilive-soundbar-frozen.svg': 'speaker-ilive-soundbar-frozen.svg',
    'speaker-ilive-soundbar.svg': 'speaker-ilive-soundbar.svg',
    'speaker-ilive-undercabinetspeaker-frozen.svg':
      'speaker-ilive-undercabinetspeaker-frozen.svg',
    'speaker-ilive-undercabinetspeaker.svg':
      'speaker-ilive-undercabinetspeaker.svg',
    'speaker-ilive-wirelessspeaker-frozen.svg':
      'speaker-ilive-wirelessspeaker-frozen.svg',
    'speaker-ilive-wirelessspeaker.svg': 'speaker-ilive-wirelessspeaker.svg',
    'speaker-ilive.svg': 'speaker-ilive.svg',
    'speaker-insignia-frozen.svg': 'speaker-insignia-frozen.svg',
    'speaker-insignia.svg': 'speaker-insignia.svg',
    'speaker-invoxia-frozen.svg': 'speaker-invoxia-frozen.svg',
    'speaker-invoxia-triby-frozen.svg': 'speaker-invoxia-triby-frozen.svg',
    'speaker-invoxia-triby.svg': 'speaker-invoxia-triby.svg',
    'speaker-invoxia.svg': 'speaker-invoxia.svg',
    'speaker-jbl-authentics-frozen.svg': 'speaker-jbl-authentics-frozen.svg',
    'speaker-jbl-authentics.svg': 'speaker-jbl-authentics.svg',
    'speaker-jbl-bar-frozen.svg': 'speaker-jbl-bar-frozen.svg',
    'speaker-jbl-bar.svg': 'speaker-jbl-bar.svg',
    'speaker-jbl-frozen.svg': 'speaker-jbl-frozen.svg',
    'speaker-jbl-playlist-frozen.svg': 'speaker-jbl-playlist-frozen.svg',
    'speaker-jbl-playlist.svg': 'speaker-jbl-playlist.svg',
    'speaker-jbl-soundfly-frozen.svg': 'speaker-jbl-soundfly-frozen.svg',
    'speaker-jbl-soundfly.svg': 'speaker-jbl-soundfly.svg',
    'speaker-jbl.svg': 'speaker-jbl.svg',
    'speaker-kef-frozen.svg': 'speaker-kef-frozen.svg',
    'speaker-kef-ls50-frozen.svg': 'speaker-kef-ls50-frozen.svg',
    'speaker-kef-ls50.svg': 'speaker-kef-ls50.svg',
    'speaker-kef-lsx-frozen.svg': 'speaker-kef-lsx-frozen.svg',
    'speaker-kef-lsx.svg': 'speaker-kef-lsx.svg',
    'speaker-kef.svg': 'speaker-kef.svg',
    'speaker-lg-frozen.svg': 'speaker-lg-frozen.svg',
    'speaker-lg-soundbar-frozen.svg': 'speaker-lg-soundbar-frozen.svg',
    'speaker-lg-soundbar.svg': 'speaker-lg-soundbar.svg',
    'speaker-lg.svg': 'speaker-lg.svg',
    'speaker-logitech-frozen.svg': 'speaker-logitech-frozen.svg',
    'speaker-logitech-squeezebox-frozen.svg':
      'speaker-logitech-squeezebox-frozen.svg',
    'speaker-logitech-squeezebox.svg': 'speaker-logitech-squeezebox.svg',
    'speaker-logitech-squeezeboxboom-frozen.svg':
      'speaker-logitech-squeezeboxboom-frozen.svg',
    'speaker-logitech-squeezeboxboom.svg':
      'speaker-logitech-squeezeboxboom.svg',
    'speaker-logitech-squeezeboxduet-frozen.svg':
      'speaker-logitech-squeezeboxduet-frozen.svg',
    'speaker-logitech-squeezeboxduet.svg':
      'speaker-logitech-squeezeboxduet.svg',
    'speaker-logitech-squeezeboxradio-frozen.svg':
      'speaker-logitech-squeezeboxradio-frozen.svg',
    'speaker-logitech-squeezeboxradio.svg':
      'speaker-logitech-squeezeboxradio.svg',
    'speaker-logitech-squeezeboxtouch-frozen.svg':
      'speaker-logitech-squeezeboxtouch-frozen.svg',
    'speaker-logitech-squeezeboxtouch.svg':
      'speaker-logitech-squeezeboxtouch.svg',
    'speaker-logitech-transporter-frozen.svg':
      'speaker-logitech-transporter-frozen.svg',
    'speaker-logitech-transporter.svg': 'speaker-logitech-transporter.svg',
    'speaker-logitech.svg': 'speaker-logitech.svg',
    'speaker-marantz-frozen.svg': 'speaker-marantz-frozen.svg',
    'speaker-marantz-pm7000n-frozen.svg': 'speaker-marantz-pm7000n-frozen.svg',
    'speaker-marantz-pm7000n.svg': 'speaker-marantz-pm7000n.svg',
    'speaker-marantz.svg': 'speaker-marantz.svg',
    'speaker-marshall-acton-frozen.svg': 'speaker-marshall-acton-frozen.svg',
    'speaker-marshall-acton.svg': 'speaker-marshall-acton.svg',
    'speaker-marshall-frozen.svg': 'speaker-marshall-frozen.svg',
    'speaker-marshall-stanmore-frozen.svg':
      'speaker-marshall-stanmore-frozen.svg',
    'speaker-marshall-stanmore.svg': 'speaker-marshall-stanmore.svg',
    'speaker-marshall-uxbridge-frozen.svg':
      'speaker-marshall-uxbridge-frozen.svg',
    'speaker-marshall-uxbridge.svg': 'speaker-marshall-uxbridge.svg',
    'speaker-marshall.svg': 'speaker-marshall.svg',
    'speaker-naim-frozen.svg': 'speaker-naim-frozen.svg',
    'speaker-naim-muso-frozen.svg': 'speaker-naim-muso-frozen.svg',
    'speaker-naim-muso.svg': 'speaker-naim-muso.svg',
    'speaker-naim-unitinova-frozen.svg': 'speaker-naim-unitinova-frozen.svg',
    'speaker-naim-unitinova.svg': 'speaker-naim-unitinova.svg',
    'speaker-naim.svg': 'speaker-naim.svg',
    'speaker-onn-frozen.svg': 'speaker-onn-frozen.svg',
    'speaker-onn-roku-frozen.svg': 'speaker-onn-roku-frozen.svg',
    'speaker-onn-roku.svg': 'speaker-onn-roku.svg',
    'speaker-onn.svg': 'speaker-onn.svg',
    'speaker-panasonic-frozen.svg': 'speaker-panasonic-frozen.svg',
    'speaker-panasonic-scga10-frozen.svg':
      'speaker-panasonic-scga10-frozen.svg',
    'speaker-panasonic-scga10.svg': 'speaker-panasonic-scga10.svg',
    'speaker-panasonic-schc20-frozen.svg':
      'speaker-panasonic-schc20-frozen.svg',
    'speaker-panasonic-schc20.svg': 'speaker-panasonic-schc20.svg',
    'speaker-panasonic-soundbar-frozen.svg':
      'speaker-panasonic-soundbar-frozen.svg',
    'speaker-panasonic-soundbar.svg': 'speaker-panasonic-soundbar.svg',
    'speaker-panasonic.svg': 'speaker-panasonic.svg',
    'speaker-polaroid-frozen.svg': 'speaker-polaroid-frozen.svg',
    'speaker-polaroid-pwf1002-frozen.svg':
      'speaker-polaroid-pwf1002-frozen.svg',
    'speaker-polaroid-pwf1002.svg': 'speaker-polaroid-pwf1002.svg',
    'speaker-polaroid.svg': 'speaker-polaroid.svg',
    'speaker-polkaudio-frozen.svg': 'speaker-polkaudio-frozen.svg',
    'speaker-polkaudio-magnifi-frozen.svg':
      'speaker-polkaudio-magnifi-frozen.svg',
    'speaker-polkaudio-magnifi.svg': 'speaker-polkaudio-magnifi.svg',
    'speaker-polkaudio-woodbourne-frozen.svg':
      'speaker-polkaudio-woodbourne-frozen.svg',
    'speaker-polkaudio-woodbourne.svg': 'speaker-polkaudio-woodbourne.svg',
    'speaker-polkaudio.svg': 'speaker-polkaudio.svg',
    'speaker-riva-festival-frozen.svg': 'speaker-riva-festival-frozen.svg',
    'speaker-riva-festival.svg': 'speaker-riva-festival.svg',
    'speaker-riva-frozen.svg': 'speaker-riva-frozen.svg',
    'speaker-riva.svg': 'speaker-riva.svg',
    'speaker-roberts-frozen.svg': 'speaker-roberts-frozen.svg',
    'speaker-roberts-stream-frozen.svg': 'speaker-roberts-stream-frozen.svg',
    'speaker-roberts-stream.svg': 'speaker-roberts-stream.svg',
    'speaker-roberts.svg': 'speaker-roberts.svg',
    'speaker-roku-frozen.svg': 'speaker-roku-frozen.svg',
    'speaker-roku-smart-frozen.svg': 'speaker-roku-smart-frozen.svg',
    'speaker-roku-smart.svg': 'speaker-roku-smart.svg',
    'speaker-roku-streambar-frozen.svg': 'speaker-roku-streambar-frozen.svg',
    'speaker-roku-streambar.svg': 'speaker-roku-streambar.svg',
    'speaker-roku.svg': 'speaker-roku.svg',
    'speaker-ruark-audio-frozen.svg': 'speaker-ruark-audio-frozen.svg',
    'speaker-ruark-audio.svg': 'speaker-ruark-audio.svg',
    'speaker-ruark-frozen.svg': 'speaker-ruark-frozen.svg',
    'speaker-ruark.svg': 'speaker-ruark.svg',
    'speaker-russound-frozen.svg': 'speaker-russound-frozen.svg',
    'speaker-russound-mca66-frozen.svg': 'speaker-russound-mca66-frozen.svg',
    'speaker-russound-mca66.svg': 'speaker-russound-mca66.svg',
    'speaker-russound-mca88x-frozen.svg': 'speaker-russound-mca88x-frozen.svg',
    'speaker-russound-mca88x.svg': 'speaker-russound-mca88x.svg',
    'speaker-russound.svg': 'speaker-russound.svg',
    'speaker-sagemcom-frozen.svg': 'speaker-sagemcom-frozen.svg',
    'speaker-sagemcom-soundbox-frozen.svg':
      'speaker-sagemcom-soundbox-frozen.svg',
    'speaker-sagemcom-soundbox.svg': 'speaker-sagemcom-soundbox.svg',
    'speaker-sagemcom.svg': 'speaker-sagemcom.svg',
    'speaker-samsung-audiodock-frozen.svg':
      'speaker-samsung-audiodock-frozen.svg',
    'speaker-samsung-audiodock.svg': 'speaker-samsung-audiodock.svg',
    'speaker-samsung-frozen.svg': 'speaker-samsung-frozen.svg',
    'speaker-samsung-soundbar-frozen.svg':
      'speaker-samsung-soundbar-frozen.svg',
    'speaker-samsung-soundbar.svg': 'speaker-samsung-soundbar.svg',
    'speaker-samsung-wirelessmultiroomspeaker-frozen.svg':
      'speaker-samsung-wirelessmultiroomspeaker-frozen.svg',
    'speaker-samsung-wirelessmultiroomspeaker.svg':
      'speaker-samsung-wirelessmultiroomspeaker.svg',
    'speaker-samsung.svg': 'speaker-samsung.svg',
    'speaker-sharperimage-frozen.svg': 'speaker-sharperimage-frozen.svg',
    'speaker-sharperimage-swf1002-frozen.svg':
      'speaker-sharperimage-swf1002-frozen.svg',
    'speaker-sharperimage-swf1002.svg': 'speaker-sharperimage-swf1002.svg',
    'speaker-sharperimage-towerspeaker-frozen.svg':
      'speaker-sharperimage-towerspeaker-frozen.svg',
    'speaker-sharperimage-towerspeaker.svg':
      'speaker-sharperimage-towerspeaker.svg',
    'speaker-sharperimage.svg': 'speaker-sharperimage.svg',
    'speaker-sonos-amp-frozen.svg': 'speaker-sonos-amp-frozen.svg',
    'speaker-sonos-amp.svg': 'speaker-sonos-amp.svg',
    'speaker-sonos-arc-frozen.svg': 'speaker-sonos-arc-frozen.svg',
    'speaker-sonos-arc.svg': 'speaker-sonos-arc.svg',
    'speaker-sonos-beam-frozen.svg': 'speaker-sonos-beam-frozen.svg',
    'speaker-sonos-beam.svg': 'speaker-sonos-beam.svg',
    'speaker-sonos-boost-frozen.svg': 'speaker-sonos-boost-frozen.svg',
    'speaker-sonos-boost.svg': 'speaker-sonos-boost.svg',
    'speaker-sonos-connect-frozen.svg': 'speaker-sonos-connect-frozen.svg',
    'speaker-sonos-connect.svg': 'speaker-sonos-connect.svg',
    'speaker-sonos-connectamp-frozen.svg':
      'speaker-sonos-connectamp-frozen.svg',
    'speaker-sonos-connectamp.svg': 'speaker-sonos-connectamp.svg',
    'speaker-sonos-dock-frozen.svg': 'speaker-sonos-dock-frozen.svg',
    'speaker-sonos-dock.svg': 'speaker-sonos-dock.svg',
    'speaker-sonos-frozen.svg': 'speaker-sonos-frozen.svg',
    'speaker-sonos-move-frozen.svg': 'speaker-sonos-move-frozen.svg',
    'speaker-sonos-move.svg': 'speaker-sonos-move.svg',
    'speaker-sonos-one-frozen.svg': 'speaker-sonos-one-frozen.svg',
    'speaker-sonos-one.svg': 'speaker-sonos-one.svg',
    'speaker-sonos-play1-frozen.svg': 'speaker-sonos-play1-frozen.svg',
    'speaker-sonos-play1.svg': 'speaker-sonos-play1.svg',
    'speaker-sonos-play3-frozen.svg': 'speaker-sonos-play3-frozen.svg',
    'speaker-sonos-play3.svg': 'speaker-sonos-play3.svg',
    'speaker-sonos-play5-frozen.svg': 'speaker-sonos-play5-frozen.svg',
    'speaker-sonos-play5.svg': 'speaker-sonos-play5.svg',
    'speaker-sonos-playbar-frozen.svg': 'speaker-sonos-playbar-frozen.svg',
    'speaker-sonos-playbar.svg': 'speaker-sonos-playbar.svg',
    'speaker-sonos-playbase-frozen.svg': 'speaker-sonos-playbase-frozen.svg',
    'speaker-sonos-playbase.svg': 'speaker-sonos-playbase.svg',
    'speaker-sonos-port-frozen.svg': 'speaker-sonos-port-frozen.svg',
    'speaker-sonos-port.svg': 'speaker-sonos-port.svg',
    'speaker-sonos-soundbar-frozen.svg': 'speaker-sonos-soundbar-frozen.svg',
    'speaker-sonos-soundbar.svg': 'speaker-sonos-soundbar.svg',
    'speaker-sonos-sub-frozen.svg': 'speaker-sonos-sub-frozen.svg',
    'speaker-sonos-sub.svg': 'speaker-sonos-sub.svg',
    'speaker-sonos-symfoniskwifispeaker-frozen.svg':
      'speaker-sonos-symfoniskwifispeaker-frozen.svg',
    'speaker-sonos-symfoniskwifispeaker.svg':
      'speaker-sonos-symfoniskwifispeaker.svg',
    'speaker-sonos-zoneplayer-frozen.svg':
      'speaker-sonos-zoneplayer-frozen.svg',
    'speaker-sonos-zoneplayer.svg': 'speaker-sonos-zoneplayer.svg',
    'speaker-sonos.svg': 'speaker-sonos.svg',
    'speaker-sony-frozen.svg': 'speaker-sony-frozen.svg',
    'speaker-sony-smartspeaker-frozen.svg':
      'speaker-sony-smartspeaker-frozen.svg',
    'speaker-sony-smartspeaker.svg': 'speaker-sony-smartspeaker.svg',
    'speaker-sony-soundbar-frozen.svg': 'speaker-sony-soundbar-frozen.svg',
    'speaker-sony-soundbar.svg': 'speaker-sony-soundbar.svg',
    'speaker-sony.svg': 'speaker-sony.svg',
    'speaker-tmall-frozen.svg': 'speaker-tmall-frozen.svg',
    'speaker-tmall.svg': 'speaker-tmall.svg',
    'speaker-tmalll-genie-frozen.svg': 'speaker-tmalll-genie-frozen.svg',
    'speaker-tmalll-genie.svg': 'speaker-tmalll-genie.svg',
    'speaker-ultimateears-frozen.svg': 'speaker-ultimateears-frozen.svg',
    'speaker-ultimateears-megablast-frozen.svg':
      'speaker-ultimateears-megablast-frozen.svg',
    'speaker-ultimateears-megablast.svg': 'speaker-ultimateears-megablast.svg',
    'speaker-ultimateears.svg': 'speaker-ultimateears.svg',
    'speaker-varo-cube-frozen.svg': 'speaker-varo-cube-frozen.svg',
    'speaker-varo-cube.svg': 'speaker-varo-cube.svg',
    'speaker-varo-frozen.svg': 'speaker-varo-frozen.svg',
    'speaker-varo-sidekick-frozen.svg': 'speaker-varo-sidekick-frozen.svg',
    'speaker-varo-sidekick.svg': 'speaker-varo-sidekick.svg',
    'speaker-varo.svg': 'speaker-varo.svg',
    'speaker-vizio-frozen.svg': 'speaker-vizio-frozen.svg',
    'speaker-vizio-soundbar-frozen.svg': 'speaker-vizio-soundbar-frozen.svg',
    'speaker-vizio-soundbar.svg': 'speaker-vizio-soundbar.svg',
    'speaker-vizio.svg': 'speaker-vizio.svg',
    'speaker-vodafone-atika-frozen.svg': 'speaker-vodafone-atika-frozen.svg',
    'speaker-vodafone-atika.svg': 'speaker-vodafone-atika.svg',
    'speaker-vodafone-frozen.svg': 'speaker-vodafone-frozen.svg',
    'speaker-vodafone.svg': 'speaker-vodafone.svg',
    'speaker-yamaha-frozen.svg': 'speaker-yamaha-frozen.svg',
    'speaker-yamaha-soundbar-frozen.svg': 'speaker-yamaha-soundbar-frozen.svg',
    'speaker-yamaha-soundbar.svg': 'speaker-yamaha-soundbar.svg',
    'speaker-yamaha.svg': 'speaker-yamaha.svg',
    'speaker-yoto-frozen.svg': 'speaker-yoto-frozen.svg',
    'speaker-yoto-player-frozen.svg': 'speaker-yoto-player-frozen.svg',
    'speaker-yoto-player.svg': 'speaker-yoto-player.svg',
    'speaker-yoto.svg': 'speaker-yoto.svg',
    'speaker-zeppelin-frozen.svg': 'speaker-zeppelin-frozen.svg',
    'speaker-zeppelin-wirelessspeaker-frozen.svg':
      'speaker-zeppelin-wirelessspeaker-frozen.svg',
    'speaker-zeppelin-wirelessspeaker.svg':
      'speaker-zeppelin-wirelessspeaker.svg',
    'speaker-zeppelin.svg': 'speaker-zeppelin.svg',
    'speaker-zolo-frozen.svg': 'speaker-zolo-frozen.svg',
    'speaker-zolo.svg': 'speaker-zolo.svg',
    'speaker.svg': 'speaker.svg',
    'sprinkler-blossom-frozen.svg': 'sprinkler-blossom-frozen.svg',
    'sprinkler-blossom.svg': 'sprinkler-blossom.svg',
    'sprinkler-frozen.svg': 'sprinkler-frozen.svg',
    'sprinkler-melnor-aquatimer-frozen.svg':
      'sprinkler-melnor-aquatimer-frozen.svg',
    'sprinkler-melnor-aquatimer.svg': 'sprinkler-melnor-aquatimer.svg',
    'sprinkler-melnor-raincloud-frozen.svg':
      'sprinkler-melnor-raincloud-frozen.svg',
    'sprinkler-melnor-raincloud.svg': 'sprinkler-melnor-raincloud.svg',
    'sprinkler-orbitirrigation-frozen.svg':
      'sprinkler-orbitirrigation-frozen.svg',
    'sprinkler-orbitirrigation.svg': 'sprinkler-orbitirrigation.svg',
    'sprinkler-rachio-frozen.svg': 'sprinkler-rachio-frozen.svg',
    'sprinkler-rachio.svg': 'sprinkler-rachio.svg',
    'sprinkler-rainmachine-frozen.svg': 'sprinkler-rainmachine-frozen.svg',
    'sprinkler-rainmachine.svg': 'sprinkler-rainmachine.svg',
    'sprinkler-scotts-grocontroller-frozen.svg':
      'sprinkler-scotts-grocontroller-frozen.svg',
    'sprinkler-scotts-grocontroller.svg': 'sprinkler-scotts-grocontroller.svg',
    'sprinkler-skydrop-sprinklercontroller-frozen.svg':
      'sprinkler-skydrop-sprinklercontroller-frozen.svg',
    'sprinkler-skydrop-sprinklercontroller.svg':
      'sprinkler-skydrop-sprinklercontroller.svg',
    'sprinkler.svg': 'sprinkler.svg',
    'storage-buffalo-linkstation-frozen.svg':
      'storage-buffalo-linkstation-frozen.svg',
    'storage-buffalo-linkstation.svg': 'storage-buffalo-linkstation.svg',
    'storage-frozen.svg': 'storage-frozen.svg',
    'storage.svg': 'storage.svg',
    'tablet-aila-sitplay-frozen.svg': 'tablet-aila-sitplay-frozen.svg',
    'tablet-aila-sitplay.svg': 'tablet-aila-sitplay.svg',
    'tablet-alcatel-1t-frozen.svg': 'tablet-alcatel-1t-frozen.svg',
    'tablet-alcatel-1t.svg': 'tablet-alcatel-1t.svg',
    'tablet-alcatel-joytab-frozen.svg': 'tablet-alcatel-joytab-frozen.svg',
    'tablet-alcatel-joytab.svg': 'tablet-alcatel-joytab.svg',
    'tablet-amazon-fire-frozen.svg': 'tablet-amazon-fire-frozen.svg',
    'tablet-amazon-fire.svg': 'tablet-amazon-fire.svg',
    'tablet-amazon-frozen.svg': 'tablet-amazon-frozen.svg',
    'tablet-amazon.svg': 'tablet-amazon.svg',
    'tablet-android-frozen.svg': 'tablet-android-frozen.svg',
    'tablet-android.svg': 'tablet-android.svg',
    'tablet-apple-ipad-frozen.svg': 'tablet-apple-ipad-frozen.svg',
    'tablet-apple-ipad-this-frozen.svg': 'tablet-apple-ipad-this-frozen.svg',
    'tablet-apple-ipad-this.svg': 'tablet-apple-ipad-this.svg',
    'tablet-apple-ipad.svg': 'tablet-apple-ipad.svg',
    'tablet-archos-oxygen-frozen.svg': 'tablet-archos-oxygen-frozen.svg',
    'tablet-archos-oxygen.svg': 'tablet-archos-oxygen.svg',
    'tablet-asus-memopad-frozen.svg': 'tablet-asus-memopad-frozen.svg',
    'tablet-asus-memopad.svg': 'tablet-asus-memopad.svg',
    'tablet-asus-zenpad-frozen.svg': 'tablet-asus-zenpad-frozen.svg',
    'tablet-asus-zenpad.svg': 'tablet-asus-zenpad.svg',
    'tablet-azpen-tablet-frozen.svg': 'tablet-azpen-tablet-frozen.svg',
    'tablet-azpen-tablet.svg': 'tablet-azpen-tablet.svg',
    'tablet-bdf-phablet-frozen.svg': 'tablet-bdf-phablet-frozen.svg',
    'tablet-bdf-phablet.svg': 'tablet-bdf-phablet.svg',
    'tablet-blackberry-playbook-frozen.svg':
      'tablet-blackberry-playbook-frozen.svg',
    'tablet-blackberry-playbook.svg': 'tablet-blackberry-playbook.svg',
    'tablet-bosch-ads-frozen.svg': 'tablet-bosch-ads-frozen.svg',
    'tablet-bosch-ads.svg': 'tablet-bosch-ads.svg',
    'tablet-chuwi-hi9-frozen.svg': 'tablet-chuwi-hi9-frozen.svg',
    'tablet-chuwi-hi9.svg': 'tablet-chuwi-hi9.svg',
    'tablet-control4-touchscreen-frozen.svg':
      'tablet-control4-touchscreen-frozen.svg',
    'tablet-control4-touchscreen.svg': 'tablet-control4-touchscreen.svg',
    'tablet-craig-cmp828-frozen.svg': 'tablet-craig-cmp828-frozen.svg',
    'tablet-craig-cmp828.svg': 'tablet-craig-cmp828.svg',
    'tablet-delphin-microtablet-frozen.svg':
      'tablet-delphin-microtablet-frozen.svg',
    'tablet-delphin-microtablet.svg': 'tablet-delphin-microtablet.svg',
    'tablet-digiland-tablet-frozen.svg': 'tablet-digiland-tablet-frozen.svg',
    'tablet-digiland-tablet.svg': 'tablet-digiland-tablet.svg',
    'tablet-ematic-pbsplaytimepad-frozen.svg':
      'tablet-ematic-pbsplaytimepad-frozen.svg',
    'tablet-ematic-pbsplaytimepad.svg': 'tablet-ematic-pbsplaytimepad.svg',
    'tablet-ematic-tablet-frozen.svg': 'tablet-ematic-tablet-frozen.svg',
    'tablet-ematic-tablet.svg': 'tablet-ematic-tablet.svg',
    'tablet-epad-tablet-frozen.svg': 'tablet-epad-tablet-frozen.svg',
    'tablet-epad-tablet.svg': 'tablet-epad-tablet.svg',
    'tablet-epik-highq-frozen.svg': 'tablet-epik-highq-frozen.svg',
    'tablet-epik-highq.svg': 'tablet-epik-highq.svg',
    'tablet-facebook-frozen.svg': 'tablet-facebook-frozen.svg',
    'tablet-facebook-portal+-frozen.svg': 'tablet-facebook-portal+-frozen.svg',
    'tablet-facebook-portal+.svg': 'tablet-facebook-portal+.svg',
    'tablet-facebook-portal-frozen.svg': 'tablet-facebook-portal-frozen.svg',
    'tablet-facebook-portal.svg': 'tablet-facebook-portal.svg',
    'tablet-facebook-portalmini-frozen.svg':
      'tablet-facebook-portalmini-frozen.svg',
    'tablet-facebook-portalmini.svg': 'tablet-facebook-portalmini.svg',
    'tablet-facebook.svg': 'tablet-facebook.svg',
    'tablet-frozen.svg': 'tablet-frozen.svg',
    'tablet-fujitsu-arrowstab-frozen.svg':
      'tablet-fujitsu-arrowstab-frozen.svg',
    'tablet-fujitsu-arrowstab.svg': 'tablet-fujitsu-arrowstab.svg',
    'tablet-google-frozen.svg': 'tablet-google-frozen.svg',
    'tablet-google-nexus-frozen.svg': 'tablet-google-nexus-frozen.svg',
    'tablet-google-nexus.svg': 'tablet-google-nexus.svg',
    'tablet-google-pixel-frozen.svg': 'tablet-google-pixel-frozen.svg',
    'tablet-google-pixel.svg': 'tablet-google-pixel.svg',
    'tablet-google.svg': 'tablet-google.svg',
    'tablet-googlenest-hub-frozen.svg': 'tablet-googlenest-hub-frozen.svg',
    'tablet-googlenest-hub.svg': 'tablet-googlenest-hub.svg',
    'tablet-hoozo-tablet-frozen.svg': 'tablet-hoozo-tablet-frozen.svg',
    'tablet-hoozo-tablet.svg': 'tablet-hoozo-tablet.svg',
    'tablet-huawei-dtab-frozen.svg': 'tablet-huawei-dtab-frozen.svg',
    'tablet-huawei-dtab.svg': 'tablet-huawei-dtab.svg',
    'tablet-huawei-dtabcompact-frozen.svg':
      'tablet-huawei-dtabcompact-frozen.svg',
    'tablet-huawei-dtabcompact.svg': 'tablet-huawei-dtabcompact.svg',
    'tablet-huawei-frozen.svg': 'tablet-huawei-frozen.svg',
    'tablet-huawei-mediapad-frozen.svg': 'tablet-huawei-mediapad-frozen.svg',
    'tablet-huawei-mediapad.svg': 'tablet-huawei-mediapad.svg',
    'tablet-huawei-quatab-frozen.svg': 'tablet-huawei-quatab-frozen.svg',
    'tablet-huawei-quatab.svg': 'tablet-huawei-quatab.svg',
    'tablet-huawei.svg': 'tablet-huawei.svg',
    'tablet-insignia-flextouchscreentabletlaptop-frozen.svg':
      'tablet-insignia-flextouchscreentabletlaptop-frozen.svg',
    'tablet-insignia-flextouchscreentabletlaptop.svg':
      'tablet-insignia-flextouchscreentabletlaptop.svg',
    'tablet-insignia-tablet-frozen.svg': 'tablet-insignia-tablet-frozen.svg',
    'tablet-insignia-tablet.svg': 'tablet-insignia-tablet.svg',
    'tablet-jty-tablet-frozen.svg': 'tablet-jty-tablet-frozen.svg',
    'tablet-jty-tablet.svg': 'tablet-jty-tablet.svg',
    'tablet-kte-tyd108h-frozen.svg': 'tablet-kte-tyd108h-frozen.svg',
    'tablet-kte-tyd108h.svg': 'tablet-kte-tyd108h.svg',
    'tablet-kurio-next-frozen.svg': 'tablet-kurio-next-frozen.svg',
    'tablet-kurio-next.svg': 'tablet-kurio-next.svg',
    'tablet-kyocera-quatab-frozen.svg': 'tablet-kyocera-quatab-frozen.svg',
    'tablet-kyocera-quatab.svg': 'tablet-kyocera-quatab.svg',
    'tablet-kyocera-szj-frozen.svg': 'tablet-kyocera-szj-frozen.svg',
    'tablet-kyocera-szj.svg': 'tablet-kyocera-szj.svg',
    'tablet-leapfrog-epic-frozen.svg': 'tablet-leapfrog-epic-frozen.svg',
    'tablet-leapfrog-epic.svg': 'tablet-leapfrog-epic.svg',
    'tablet-leapfrog-leappad-frozen.svg': 'tablet-leapfrog-leappad-frozen.svg',
    'tablet-leapfrog-leappad.svg': 'tablet-leapfrog-leappad.svg',
    'tablet-lenovo-everypad-frozen.svg': 'tablet-lenovo-everypad-frozen.svg',
    'tablet-lenovo-everypad.svg': 'tablet-lenovo-everypad.svg',
    'tablet-lenovo-tab3-frozen.svg': 'tablet-lenovo-tab3-frozen.svg',
    'tablet-lenovo-tab3.svg': 'tablet-lenovo-tab3.svg',
    'tablet-lenovo-tab4-frozen.svg': 'tablet-lenovo-tab4-frozen.svg',
    'tablet-lenovo-tab4.svg': 'tablet-lenovo-tab4.svg',
    'tablet-lenovo-tablet-frozen.svg': 'tablet-lenovo-tablet-frozen.svg',
    'tablet-lenovo-tablet.svg': 'tablet-lenovo-tablet.svg',
    'tablet-lenovo-thinkpad-frozen.svg': 'tablet-lenovo-thinkpad-frozen.svg',
    'tablet-lenovo-thinkpad.svg': 'tablet-lenovo-thinkpad.svg',
    'tablet-lg-gpad-frozen.svg': 'tablet-lg-gpad-frozen.svg',
    'tablet-lg-gpad.svg': 'tablet-lg-gpad.svg',
    'tablet-maitai-mt107-frozen.svg': 'tablet-maitai-mt107-frozen.svg',
    'tablet-maitai-mt107.svg': 'tablet-maitai-mt107.svg',
    'tablet-manjee-mj108-frozen.svg': 'tablet-manjee-mj108-frozen.svg',
    'tablet-manjee-mj108.svg': 'tablet-manjee-mj108.svg',
    'tablet-mediacom-smartpad-frozen.svg':
      'tablet-mediacom-smartpad-frozen.svg',
    'tablet-mediacom-smartpad.svg': 'tablet-mediacom-smartpad.svg',
    'tablet-mediatek-k960-frozen.svg': 'tablet-mediatek-k960-frozen.svg',
    'tablet-mediatek-k960.svg': 'tablet-mediatek-k960.svg',
    'tablet-mediatek-t906-frozen.svg': 'tablet-mediatek-t906-frozen.svg',
    'tablet-mediatek-t906.svg': 'tablet-mediatek-t906.svg',
    'tablet-mediatek-zh960-frozen.svg': 'tablet-mediatek-zh960-frozen.svg',
    'tablet-mediatek-zh960.svg': 'tablet-mediatek-zh960.svg',
    'tablet-medion-lifetab-frozen.svg': 'tablet-medion-lifetab-frozen.svg',
    'tablet-medion-lifetab.svg': 'tablet-medion-lifetab.svg',
    'tablet-microsoft-surface-frozen.svg':
      'tablet-microsoft-surface-frozen.svg',
    'tablet-microsoft-surface.svg': 'tablet-microsoft-surface.svg',
    'tablet-nabi-tablet-1.svg': 'tablet-nabi-tablet-1.svg',
    'tablet-nabi-tablet-frozen-1.svg': 'tablet-nabi-tablet-frozen-1.svg',
    'tablet-nabi-tablet-frozen.svg': 'tablet-nabi-tablet-frozen.svg',
    'tablet-nabi-tablet.svg': 'tablet-nabi-tablet.svg',
    'tablet-naxa-nid1050-frozen.svg': 'tablet-naxa-nid1050-frozen.svg',
    'tablet-naxa-nid1050.svg': 'tablet-naxa-nid1050.svg',
    'tablet-nec-lavietab-frozen.svg': 'tablet-nec-lavietab-frozen.svg',
    'tablet-nec-lavietab.svg': 'tablet-nec-lavietab.svg',
    'tablet-nextbook-ares-frozen.svg': 'tablet-nextbook-ares-frozen.svg',
    'tablet-nextbook-ares.svg': 'tablet-nextbook-ares.svg',
    'tablet-nextbook-frozen.svg': 'tablet-nextbook-frozen.svg',
    'tablet-nextbook.svg': 'tablet-nextbook.svg',
    'tablet-nordictrack-portal-frozen.svg':
      'tablet-nordictrack-portal-frozen.svg',
    'tablet-nordictrack-portal.svg': 'tablet-nordictrack-portal.svg',
    'tablet-onn-surftablet-frozen.svg': 'tablet-onn-surftablet-frozen.svg',
    'tablet-onn-surftablet.svg': 'tablet-onn-surftablet.svg',
    'tablet-onn-tablet-frozen.svg': 'tablet-onn-tablet-frozen.svg',
    'tablet-onn-tablet.svg': 'tablet-onn-tablet.svg',
    'tablet-pantech-frozen.svg': 'tablet-pantech-frozen.svg',
    'tablet-pantech.svg': 'tablet-pantech.svg',
    'tablet-pbskids-playtime-frozen.svg': 'tablet-pbskids-playtime-frozen.svg',
    'tablet-pbskids-playtime.svg': 'tablet-pbskids-playtime.svg',
    'tablet-phablet-frozen.svg': 'tablet-phablet-frozen.svg',
    'tablet-phablet-this-frozen.svg': 'tablet-phablet-this-frozen.svg',
    'tablet-phablet-this.svg': 'tablet-phablet-this.svg',
    'tablet-phablet.svg': 'tablet-phablet.svg',
    'tablet-pipo-tablet-frozen.svg': 'tablet-pipo-tablet-frozen.svg',
    'tablet-pipo-tablet.svg': 'tablet-pipo-tablet.svg',
    'tablet-qere-qr8-frozen.svg': 'tablet-qere-qr8-frozen.svg',
    'tablet-qere-qr8.svg': 'tablet-qere-qr8.svg',
    'tablet-qilive-tablet-frozen.svg': 'tablet-qilive-tablet-frozen.svg',
    'tablet-qilive-tablet.svg': 'tablet-qilive-tablet.svg',
    'tablet-rca-pro12-frozen.svg': 'tablet-rca-pro12-frozen.svg',
    'tablet-rca-pro12.svg': 'tablet-rca-pro12.svg',
    'tablet-rca-tablet-frozen.svg': 'tablet-rca-tablet-frozen.svg',
    'tablet-rca-tablet.svg': 'tablet-rca-tablet.svg',
    'tablet-remarkable-papertablet-frozen.svg':
      'tablet-remarkable-papertablet-frozen.svg',
    'tablet-remarkable-papertablet.svg': 'tablet-remarkable-papertablet.svg',
    'tablet-samsung-galaxy-frozen.svg': 'tablet-samsung-galaxy-frozen.svg',
    'tablet-samsung-galaxy.svg': 'tablet-samsung-galaxy.svg',
    'tablet-samsung-galaxybook-frozen.svg':
      'tablet-samsung-galaxybook-frozen.svg',
    'tablet-samsung-galaxybook.svg': 'tablet-samsung-galaxybook.svg',
    'tablet-samsung-galaxytab-frozen.svg':
      'tablet-samsung-galaxytab-frozen.svg',
    'tablet-samsung-galaxytab.svg': 'tablet-samsung-galaxytab.svg',
    'tablet-samsung-galaxyview-frozen.svg':
      'tablet-samsung-galaxyview-frozen.svg',
    'tablet-samsung-galaxyview.svg': 'tablet-samsung-galaxyview.svg',
    'tablet-samsung-gizmotablet-frozen.svg':
      'tablet-samsung-gizmotablet-frozen.svg',
    'tablet-samsung-gizmotablet.svg': 'tablet-samsung-gizmotablet.svg',
    'tablet-sanyo-benesse-frozen.svg': 'tablet-sanyo-benesse-frozen.svg',
    'tablet-sanyo-benesse.svg': 'tablet-sanyo-benesse.svg',
    'tablet-sharp-aquospad-frozen.svg': 'tablet-sharp-aquospad-frozen.svg',
    'tablet-sharp-aquospad.svg': 'tablet-sharp-aquospad.svg',
    'tablet-sharp-galapagos-frozen.svg': 'tablet-sharp-galapagos-frozen.svg',
    'tablet-sharp-galapagos.svg': 'tablet-sharp-galapagos.svg',
    'tablet-shenzhen-tablet-frozen.svg': 'tablet-shenzhen-tablet-frozen.svg',
    'tablet-shenzhen-tablet.svg': 'tablet-shenzhen-tablet.svg',
    'tablet-slate-8-frozen.svg': 'tablet-slate-8-frozen.svg',
    'tablet-slate-8.svg': 'tablet-slate-8.svg',
    'tablet-smartab-st1009-frozen.svg': 'tablet-smartab-st1009-frozen.svg',
    'tablet-smartab-st1009.svg': 'tablet-smartab-st1009.svg',
    'tablet-smartak-tab910-frozen.svg': 'tablet-smartak-tab910-frozen.svg',
    'tablet-smartak-tab910.svg': 'tablet-smartak-tab910.svg',
    'tablet-softwinerevb-tablet-frozen.svg':
      'tablet-softwinerevb-tablet-frozen.svg',
    'tablet-softwinerevb-tablet.svg': 'tablet-softwinerevb-tablet.svg',
    'tablet-sony-xperia-frozen.svg': 'tablet-sony-xperia-frozen.svg',
    'tablet-sony-xperia.svg': 'tablet-sony-xperia.svg',
    'tablet-sproutchannel-cubby-frozen.svg':
      'tablet-sproutchannel-cubby-frozen.svg',
    'tablet-sproutchannel-cubby.svg': 'tablet-sproutchannel-cubby.svg',
    'tablet-sylvania-sltdvd1023-frozen.svg':
      'tablet-sylvania-sltdvd1023-frozen.svg',
    'tablet-sylvania-sltdvd1023.svg': 'tablet-sylvania-sltdvd1023.svg',
    'tablet-tagital-t10k-frozen.svg': 'tablet-tagital-t10k-frozen.svg',
    'tablet-tagital-t10k.svg': 'tablet-tagital-t10k.svg',
    'tablet-this-frozen.svg': 'tablet-this-frozen.svg',
    'tablet-this.svg': 'tablet-this.svg',
    'tablet-trimble-t10-frozen.svg': 'tablet-trimble-t10-frozen.svg',
    'tablet-trimble-t10.svg': 'tablet-trimble-t10.svg',
    'tablet-verizon-ellipsis-frozen.svg': 'tablet-verizon-ellipsis-frozen.svg',
    'tablet-verizon-ellipsis.svg': 'tablet-verizon-ellipsis.svg',
    'tablet-verizon-gizmotab-frozen.svg': 'tablet-verizon-gizmotab-frozen.svg',
    'tablet-verizon-gizmotab.svg': 'tablet-verizon-gizmotab.svg',
    'tablet-visualland-prestige-frozen.svg':
      'tablet-visualland-prestige-frozen.svg',
    'tablet-visualland-prestige.svg': 'tablet-visualland-prestige.svg',
    'tablet-vizio-frozen.svg': 'tablet-vizio-frozen.svg',
    'tablet-vizio.svg': 'tablet-vizio.svg',
    'tablet-xiaomi-mipad-frozen.svg': 'tablet-xiaomi-mipad-frozen.svg',
    'tablet-xiaomi-mipad.svg': 'tablet-xiaomi-mipad.svg',
    'tablet-yuntab-k107-frozen.svg': 'tablet-yuntab-k107-frozen.svg',
    'tablet-yuntab-k107.svg': 'tablet-yuntab-k107.svg',
    'tablet-zonko-k106-frozen.svg': 'tablet-zonko-k106-frozen.svg',
    'tablet-zonko-k106.svg': 'tablet-zonko-k106.svg',
    'tablet-zte-grandx-frozen.svg': 'tablet-zte-grandx-frozen.svg',
    'tablet-zte-grandx.svg': 'tablet-zte-grandx.svg',
    'tablet-zte-trek-frozen.svg': 'tablet-zte-trek-frozen.svg',
    'tablet-zte-trek.svg': 'tablet-zte-trek.svg',
    'tablet-zte-zpad-frozen.svg': 'tablet-zte-zpad-frozen.svg',
    'tablet-zte-zpad.svg': 'tablet-zte-zpad.svg',
    'tablet.svg': 'tablet.svg',
    'thermostat-aprilaire-frozen.svg': 'thermostat-aprilaire-frozen.svg',
    'thermostat-aprilaire-thermostat-frozen.svg':
      'thermostat-aprilaire-thermostat-frozen.svg',
    'thermostat-aprilaire-thermostat.svg':
      'thermostat-aprilaire-thermostat.svg',
    'thermostat-aprilaire.svg': 'thermostat-aprilaire.svg',
    'thermostat-bosch-bcc100-frozen.svg': 'thermostat-bosch-bcc100-frozen.svg',
    'thermostat-bosch-bcc100.svg': 'thermostat-bosch-bcc100.svg',
    'thermostat-bosch-bcc50-frozen.svg': 'thermostat-bosch-bcc50-frozen.svg',
    'thermostat-bosch-bcc50.svg': 'thermostat-bosch-bcc50.svg',
    'thermostat-bticino-smarther-frozen.svg':
      'thermostat-bticino-smarther-frozen.svg',
    'thermostat-bticino-smarther.svg': 'thermostat-bticino-smarther.svg',
    'thermostat-danfosslink-frozen.svg': 'thermostat-danfosslink-frozen.svg',
    'thermostat-danfosslink.svg': 'thermostat-danfosslink.svg',
    'thermostat-ecobee-frozen.svg': 'thermostat-ecobee-frozen.svg',
    'thermostat-ecobee.svg': 'thermostat-ecobee.svg',
    'thermostat-eneco-toonthermostat-frozen.svg':
      'thermostat-eneco-toonthermostat-frozen.svg',
    'thermostat-eneco-toonthermostat.svg':
      'thermostat-eneco-toonthermostat.svg',
    'thermostat-engie-boxx-frozen.svg': 'thermostat-engie-boxx-frozen.svg',
    'thermostat-engie-boxx.svg': 'thermostat-engie-boxx.svg',
    'thermostat-flair-puck-frozen.svg': 'thermostat-flair-puck-frozen.svg',
    'thermostat-flair-puck.svg': 'thermostat-flair-puck.svg',
    'thermostat-frozen.svg': 'thermostat-frozen.svg',
    'thermostat-googlenest-frozen.svg': 'thermostat-googlenest-frozen.svg',
    'thermostat-googlenest.svg': 'thermostat-googlenest.svg',
    'thermostat-honeywell-frozen.svg': 'thermostat-honeywell-frozen.svg',
    'thermostat-honeywell.svg': 'thermostat-honeywell.svg',
    'thermostat-idevices-frozen.svg': 'thermostat-idevices-frozen.svg',
    'thermostat-idevices.svg': 'thermostat-idevices.svg',
    'thermostat-johnsoncontrols-glas-frozen.svg':
      'thermostat-johnsoncontrols-glas-frozen.svg',
    'thermostat-johnsoncontrols-glas.svg':
      'thermostat-johnsoncontrols-glas.svg',
    'thermostat-lux-konosmartthermostat-frozen.svg':
      'thermostat-lux-konosmartthermostat-frozen.svg',
    'thermostat-lux-konosmartthermostat.svg':
      'thermostat-lux-konosmartthermostat.svg',
    'thermostat-mysa-frozen.svg': 'thermostat-mysa-frozen.svg',
    'thermostat-mysa.svg': 'thermostat-mysa.svg',
    'thermostat-netatmo-smartthermostat-frozen.svg':
      'thermostat-netatmo-smartthermostat-frozen.svg',
    'thermostat-netatmo-smartthermostat.svg':
      'thermostat-netatmo-smartthermostat.svg',
    'thermostat-nvent-nuheat-frozen.svg': 'thermostat-nvent-nuheat-frozen.svg',
    'thermostat-nvent-nuheat.svg': 'thermostat-nvent-nuheat.svg',
    'thermostat-pro1-wifithermostat-frozen.svg':
      'thermostat-pro1-wifithermostat-frozen.svg',
    'thermostat-pro1-wifithermostat.svg': 'thermostat-pro1-wifithermostat.svg',
    'thermostat-radio-frozen.svg': 'thermostat-radio-frozen.svg',
    'thermostat-radio.svg': 'thermostat-radio.svg',
    'thermostat-sensi-frozen.svg': 'thermostat-sensi-frozen.svg',
    'thermostat-sensi.svg': 'thermostat-sensi.svg',
    'thermostat-trane-frozen.svg': 'thermostat-trane-frozen.svg',
    'thermostat-trane.svg': 'thermostat-trane.svg',
    'thermostat-wyze-thermostat-frozen.svg':
      'thermostat-wyze-thermostat-frozen.svg',
    'thermostat-wyze-thermostat.svg': 'thermostat-wyze-thermostat.svg',
    'thermostat.svg': 'thermostat.svg',
    'toy-frozen.svg': 'toy-frozen.svg',
    'toy.svg': 'toy.svg',
    'tv-akai-tv-frozen.svg': 'tv-akai-tv-frozen.svg',
    'tv-akai-tv.svg': 'tv-akai-tv.svg',
    'tv-aldinord-smarttv-frozen.svg': 'tv-aldinord-smarttv-frozen.svg',
    'tv-aldinord-smarttv.svg': 'tv-aldinord-smarttv.svg',
    'tv-amazon-fire-frozen.svg': 'tv-amazon-fire-frozen.svg',
    'tv-amazon-fire.svg': 'tv-amazon-fire.svg',
    'tv-amazon-firetv-frozen.svg': 'tv-amazon-firetv-frozen.svg',
    'tv-amazon-firetv.svg': 'tv-amazon-firetv.svg',
    'tv-argos-bushtv-frozen.svg': 'tv-argos-bushtv-frozen.svg',
    'tv-argos-bushtv.svg': 'tv-argos-bushtv.svg',
    'tv-atron-tv-frozen.svg': 'tv-atron-tv-frozen.svg',
    'tv-atron-tv.svg': 'tv-atron-tv.svg',
    'tv-bangolufsen-beoplay-frozen.svg': 'tv-bangolufsen-beoplay-frozen.svg',
    'tv-bangolufsen-beoplay.svg': 'tv-bangolufsen-beoplay.svg',
    'tv-bangolufsen-beovision-frozen.svg':
      'tv-bangolufsen-beovision-frozen.svg',
    'tv-bangolufsen-beovision.svg': 'tv-bangolufsen-beovision.svg',
    'tv-digihome-smarttv-frozen.svg': 'tv-digihome-smarttv-frozen.svg',
    'tv-digihome-smarttv.svg': 'tv-digihome-smarttv.svg',
    'tv-durabase-smarttv-frozen.svg': 'tv-durabase-smarttv-frozen.svg',
    'tv-durabase-smarttv.svg': 'tv-durabase-smarttv.svg',
    'tv-edenwood-smarttv-frozen.svg': 'tv-edenwood-smarttv-frozen.svg',
    'tv-edenwood-smarttv.svg': 'tv-edenwood-smarttv.svg',
    'tv-electronia-smarttv-frozen.svg': 'tv-electronia-smarttv-frozen.svg',
    'tv-electronia-smarttv.svg': 'tv-electronia-smarttv.svg',
    'tv-element-rokutv-frozen.svg': 'tv-element-rokutv-frozen.svg',
    'tv-element-rokutv.svg': 'tv-element-rokutv.svg',
    'tv-finlux-smarttvv-frozen.svg': 'tv-finlux-smarttvv-frozen.svg',
    'tv-finlux-smarttvv.svg': 'tv-finlux-smarttvv.svg',
    'tv-frozen.svg': 'tv-frozen.svg',
    'tv-fu-smarttv-frozen.svg': 'tv-fu-smarttv-frozen.svg',
    'tv-fu-smarttv.svg': 'tv-fu-smarttv.svg',
    'tv-funai-tv-frozen.svg': 'tv-funai-tv-frozen.svg',
    'tv-funai-tv.svg': 'tv-funai-tv.svg',
    'tv-grundig-firetv-frozen.svg': 'tv-grundig-firetv-frozen.svg',
    'tv-grundig-firetv.svg': 'tv-grundig-firetv.svg',
    'tv-grundig-vision6-frozen.svg': 'tv-grundig-vision6-frozen.svg',
    'tv-grundig-vision6.svg': 'tv-grundig-vision6.svg',
    'tv-grundig-vision7-frozen.svg': 'tv-grundig-vision7-frozen.svg',
    'tv-grundig-vision7.svg': 'tv-grundig-vision7.svg',
    'tv-haier-smarttv-frozen.svg': 'tv-haier-smarttv-frozen.svg',
    'tv-haier-smarttv.svg': 'tv-haier-smarttv.svg',
    'tv-hanseatic-smarttv-frozen.svg': 'tv-hanseatic-smarttv-frozen.svg',
    'tv-hanseatic-smarttv.svg': 'tv-hanseatic-smarttv.svg',
    'tv-highone-smarttv-frozen.svg': 'tv-highone-smarttv-frozen.svg',
    'tv-highone-smarttv.svg': 'tv-highone-smarttv.svg',
    'tv-hisense-4kuhdtv-frozen.svg': 'tv-hisense-4kuhdtv-frozen.svg',
    'tv-hisense-4kuhdtv.svg': 'tv-hisense-4kuhdtv.svg',
    'tv-hisense-frozen.svg': 'tv-hisense-frozen.svg',
    'tv-hisense-rokutv-frozen.svg': 'tv-hisense-rokutv-frozen.svg',
    'tv-hisense-rokutv.svg': 'tv-hisense-rokutv.svg',
    'tv-hisense.svg': 'tv-hisense.svg',
    'tv-hitachi-rokutv-frozen.svg': 'tv-hitachi-rokutv-frozen.svg',
    'tv-hitachi-rokutv.svg': 'tv-hitachi-rokutv.svg',
    'tv-hitachi-smarttv-frozen.svg': 'tv-hitachi-smarttv-frozen.svg',
    'tv-hitachi-smarttv.svg': 'tv-hitachi-smarttv.svg',
    'tv-hyundai-smarttv-frozen.svg': 'tv-hyundai-smarttv-frozen.svg',
    'tv-hyundai-smarttv.svg': 'tv-hyundai-smarttv.svg',
    'tv-insignia-rokutv-frozen.svg': 'tv-insignia-rokutv-frozen.svg',
    'tv-insignia-rokutv.svg': 'tv-insignia-rokutv.svg',
    'tv-jvc-firetv-frozen.svg': 'tv-jvc-firetv-frozen.svg',
    'tv-jvc-firetv.svg': 'tv-jvc-firetv.svg',
    'tv-jvc-smartdevice-frozen.svg': 'tv-jvc-smartdevice-frozen.svg',
    'tv-jvc-smartdevice.svg': 'tv-jvc-smartdevice.svg',
    'tv-jvc-smarttv-frozen.svg': 'tv-jvc-smarttv-frozen.svg',
    'tv-jvc-smarttv.svg': 'tv-jvc-smarttv.svg',
    'tv-kendo-smarttv-frozen.svg': 'tv-kendo-smarttv-frozen.svg',
    'tv-kendo-smarttv.svg': 'tv-kendo-smarttv.svg',
    'tv-kubo-smarttv-frozen.svg': 'tv-kubo-smarttv-frozen.svg',
    'tv-kubo-smarttv.svg': 'tv-kubo-smarttv.svg',
    'tv-kydos-smarttv-frozen.svg': 'tv-kydos-smarttv-frozen.svg',
    'tv-kydos-smarttv.svg': 'tv-kydos-smarttv.svg',
    'tv-leapfrog-leaptv-frozen.svg': 'tv-leapfrog-leaptv-frozen.svg',
    'tv-leapfrog-leaptv.svg': 'tv-leapfrog-leaptv.svg',
    'tv-leeco-letv-frozen.svg': 'tv-leeco-letv-frozen.svg',
    'tv-leeco-letv.svg': 'tv-leeco-letv.svg',
    'tv-lg-frozen.svg': 'tv-lg-frozen.svg',
    'tv-lg-rokutv-frozen.svg': 'tv-lg-rokutv-frozen.svg',
    'tv-lg-rokutv.svg': 'tv-lg-rokutv.svg',
    'tv-lg-smarttv-frozen.svg': 'tv-lg-smarttv-frozen.svg',
    'tv-lg-smarttv.svg': 'tv-lg-smarttv.svg',
    'tv-lg-webostv-frozen.svg': 'tv-lg-webostv-frozen.svg',
    'tv-lg-webostv.svg': 'tv-lg-webostv.svg',
    'tv-lg.svg': 'tv-lg.svg',
    'tv-linsar-smarttv-frozen.svg': 'tv-linsar-smarttv-frozen.svg',
    'tv-linsar-smarttv.svg': 'tv-linsar-smarttv.svg',
    'tv-logic-smarttv-frozen.svg': 'tv-logic-smarttv-frozen.svg',
    'tv-logic-smarttv.svg': 'tv-logic-smarttv.svg',
    'tv-luxor-smarttv-frozen.svg': 'tv-luxor-smarttv-frozen.svg',
    'tv-luxor-smarttv.svg': 'tv-luxor-smarttv.svg',
    'tv-m8s-androidtvbox-frozen.svg': 'tv-m8s-androidtvbox-frozen.svg',
    'tv-m8s-androidtvbox.svg': 'tv-m8s-androidtvbox.svg',
    'tv-magnavox-rokutv-frozen.svg': 'tv-magnavox-rokutv-frozen.svg',
    'tv-magnavox-rokutv.svg': 'tv-magnavox-rokutv.svg',
    'tv-medion-life-frozen.svg': 'tv-medion-life-frozen.svg',
    'tv-medion-life.svg': 'tv-medion-life.svg',
    'tv-medion-smarttv-frozen.svg': 'tv-medion-smarttv-frozen.svg',
    'tv-medion-smarttv.svg': 'tv-medion-smarttv.svg',
    'tv-mitchellbrown-smarttv-frozen.svg':
      'tv-mitchellbrown-smarttv-frozen.svg',
    'tv-mitchellbrown-smarttv.svg': 'tv-mitchellbrown-smarttv.svg',
    'tv-netgem-frozen.svg': 'tv-netgem-frozen.svg',
    'tv-netgem.svg': 'tv-netgem.svg',
    'tv-onida-firetv-frozen.svg': 'tv-onida-firetv-frozen.svg',
    'tv-onida-firetv.svg': 'tv-onida-firetv.svg',
    'tv-onn-rokutv-frozen.svg': 'tv-onn-rokutv-frozen.svg',
    'tv-onn-rokutv.svg': 'tv-onn-rokutv.svg',
    'tv-orima-smarttv-frozen.svg': 'tv-orima-smarttv-frozen.svg',
    'tv-orima-smarttv.svg': 'tv-orima-smarttv.svg',
    'tv-panasonic-portabletv-frozen.svg': 'tv-panasonic-portabletv-frozen.svg',
    'tv-panasonic-portabletv.svg': 'tv-panasonic-portabletv.svg',
    'tv-panasonic-tv-frozen.svg': 'tv-panasonic-tv-frozen.svg',
    'tv-panasonic-tv.svg': 'tv-panasonic-tv.svg',
    'tv-philips-rokutv-frozen.svg': 'tv-philips-rokutv-frozen.svg',
    'tv-philips-rokutv.svg': 'tv-philips-rokutv.svg',
    'tv-philips-smarttv-frozen.svg': 'tv-philips-smarttv-frozen.svg',
    'tv-philips-smarttv.svg': 'tv-philips-smarttv.svg',
    'tv-philips-tv-frozen.svg': 'tv-philips-tv-frozen.svg',
    'tv-philips-tv.svg': 'tv-philips-tv.svg',
    'tv-polaroid-ledtv-frozen.svg': 'tv-polaroid-ledtv-frozen.svg',
    'tv-polaroid-ledtv.svg': 'tv-polaroid-ledtv.svg',
    'tv-polaroid-smarttv-frozen.svg': 'tv-polaroid-smarttv-frozen.svg',
    'tv-polaroid-smarttv.svg': 'tv-polaroid-smarttv.svg',
    'tv-qilive-tv-frozen.svg': 'tv-qilive-tv-frozen.svg',
    'tv-qilive-tv.svg': 'tv-qilive-tv.svg',
    'tv-roku-tv-frozen.svg': 'tv-roku-tv-frozen.svg',
    'tv-roku-tv.svg': 'tv-roku-tv.svg',
    'tv-saba-smarttv-frozen.svg': 'tv-saba-smarttv-frozen.svg',
    'tv-saba-smarttv.svg': 'tv-saba-smarttv.svg',
    'tv-salora-ledtv-frozen.svg': 'tv-salora-ledtv-frozen.svg',
    'tv-salora-ledtv.svg': 'tv-salora-ledtv.svg',
    'tv-samsung-frozen.svg': 'tv-samsung-frozen.svg',
    'tv-samsung-q80-frozen.svg': 'tv-samsung-q80-frozen.svg',
    'tv-samsung-q80.svg': 'tv-samsung-q80.svg',
    'tv-samsung.svg': 'tv-samsung.svg',
    'tv-sanyo-rokutv-frozen.svg': 'tv-sanyo-rokutv-frozen.svg',
    'tv-sanyo-rokutv.svg': 'tv-sanyo-rokutv.svg',
    'tv-selecline-smarttv-frozen.svg': 'tv-selecline-smarttv-frozen.svg',
    'tv-selecline-smarttv.svg': 'tv-selecline-smarttv.svg',
    'tv-sharp-aquos-frozen.svg': 'tv-sharp-aquos-frozen.svg',
    'tv-sharp-aquos.svg': 'tv-sharp-aquos.svg',
    'tv-sharp-aquostv-frozen.svg': 'tv-sharp-aquostv-frozen.svg',
    'tv-sharp-aquostv.svg': 'tv-sharp-aquostv.svg',
    'tv-sharp-frozen.svg': 'tv-sharp-frozen.svg',
    'tv-sharp-rokutv-frozen.svg': 'tv-sharp-rokutv-frozen.svg',
    'tv-sharp-rokutv.svg': 'tv-sharp-rokutv.svg',
    'tv-sharp.svg': 'tv-sharp.svg',
    'tv-sony-bravia4ktv-frozen.svg': 'tv-sony-bravia4ktv-frozen.svg',
    'tv-sony-bravia4ktv.svg': 'tv-sony-bravia4ktv.svg',
    'tv-sony-braviatv-frozen.svg': 'tv-sony-braviatv-frozen.svg',
    'tv-sony-braviatv.svg': 'tv-sony-braviatv.svg',
    'tv-sony-frozen.svg': 'tv-sony-frozen.svg',
    'tv-sony.svg': 'tv-sony.svg',
    'tv-tcl-5series-frozen.svg': 'tv-tcl-5series-frozen.svg',
    'tv-tcl-5series.svg': 'tv-tcl-5series.svg',
    'tv-tcl-rokutv-frozen.svg': 'tv-tcl-rokutv-frozen.svg',
    'tv-tcl-rokutv.svg': 'tv-tcl-rokutv.svg',
    'tv-tcl-uniontv-frozen.svg': 'tv-tcl-uniontv-frozen.svg',
    'tv-tcl-uniontv.svg': 'tv-tcl-uniontv.svg',
    'tv-telefunken-smarttv-frozen.svg': 'tv-telefunken-smarttv-frozen.svg',
    'tv-telefunken-smarttv.svg': 'tv-telefunken-smarttv.svg',
    'tv-toshiba-4k-frozen.svg': 'tv-toshiba-4k-frozen.svg',
    'tv-toshiba-4k.svg': 'tv-toshiba-4k.svg',
    'tv-toshiba-chromecasttv-frozen.svg': 'tv-toshiba-chromecasttv-frozen.svg',
    'tv-toshiba-chromecasttv.svg': 'tv-toshiba-chromecasttv.svg',
    'tv-toshiba-firetv-frozen.svg': 'tv-toshiba-firetv-frozen.svg',
    'tv-toshiba-firetv.svg': 'tv-toshiba-firetv.svg',
    'tv-toshiba-regzalcdtv-frozen.svg': 'tv-toshiba-regzalcdtv-frozen.svg',
    'tv-toshiba-regzalcdtv.svg': 'tv-toshiba-regzalcdtv.svg',
    'tv-toshiba-smarttv-frozen.svg': 'tv-toshiba-smarttv-frozen.svg',
    'tv-toshiba-smarttv.svg': 'tv-toshiba-smarttv.svg',
    'tv-vizio-smartcast-frozen.svg': 'tv-vizio-smartcast-frozen.svg',
    'tv-vizio-smartcast.svg': 'tv-vizio-smartcast.svg',
    'tv-vizio-smarttv-frozen.svg': 'tv-vizio-smarttv-frozen.svg',
    'tv-vizio-smarttv.svg': 'tv-vizio-smarttv.svg',
    'tv-vox-smarttv-frozen.svg': 'tv-vox-smarttv-frozen.svg',
    'tv-vox-smarttv.svg': 'tv-vox-smarttv.svg',
    'tv-westwood-smarttv-frozen.svg': 'tv-westwood-smarttv-frozen.svg',
    'tv-westwood-smarttv.svg': 'tv-westwood-smarttv.svg',
    'tv-xiaomi-mitv-frozen.svg': 'tv-xiaomi-mitv-frozen.svg',
    'tv-xiaomi-mitv.svg': 'tv-xiaomi-mitv.svg',
    'tv.svg': 'tv.svg',
    'unknown-frozen.svg': 'unknown-frozen.svg',
    'unknown.svg': 'unknown.svg',
    'vacuum-1.svg': 'vacuum-1.svg',
    'vacuum-ecovacs-deebot-frozen.svg': 'vacuum-ecovacs-deebot-frozen.svg',
    'vacuum-ecovacs-deebot.svg': 'vacuum-ecovacs-deebot.svg',
    'vacuum-eufy-robovac-frozen.svg': 'vacuum-eufy-robovac-frozen.svg',
    'vacuum-eufy-robovac.svg': 'vacuum-eufy-robovac.svg',
    'vacuum-frozen-1.svg': 'vacuum-frozen-1.svg',
    'vacuum-frozen.svg': 'vacuum-frozen.svg',
    'vacuum-irobot-roomba-frozen.svg': 'vacuum-irobot-roomba-frozen.svg',
    'vacuum-irobot-roomba.svg': 'vacuum-irobot-roomba.svg',
    'vacuum.svg': 'vacuum.svg',
    'virtualreality-frozen.svg': 'virtualreality-frozen.svg',
    'virtualreality-microsoft-hololens-frozen.svg':
      'virtualreality-microsoft-hololens-frozen.svg',
    'virtualreality-microsoft-hololens.svg':
      'virtualreality-microsoft-hololens.svg',
    'virtualreality-oculus-frozen.svg': 'virtualreality-oculus-frozen.svg',
    'virtualreality-oculus-go-frozen.svg':
      'virtualreality-oculus-go-frozen.svg',
    'virtualreality-oculus-go.svg': 'virtualreality-oculus-go.svg',
    'virtualreality-oculus-quest-frozen.svg':
      'virtualreality-oculus-quest-frozen.svg',
    'virtualreality-oculus-quest.svg': 'virtualreality-oculus-quest.svg',
    'virtualreality-oculus.svg': 'virtualreality-oculus.svg',
    'virtualreality-varjo-vr1-frozen.svg':
      'virtualreality-varjo-vr1-frozen.svg',
    'virtualreality-varjo-vr1.svg': 'virtualreality-varjo-vr1.svg',
    'virtualreality.svg': 'virtualreality.svg',
    'visitormanagement-envoyvisitors-frozen.svg':
      'visitormanagement-envoyvisitors-frozen.svg',
    'visitormanagement-envoyvisitors.svg':
      'visitormanagement-envoyvisitors.svg',
    'visitormanagement-frozen.svg': 'visitormanagement-frozen.svg',
    'visitormanagement.svg': 'visitormanagement.svg',
    'voiceassistant-amazon-dashwand-frozen.svg':
      'voiceassistant-amazon-dashwand-frozen.svg',
    'voiceassistant-amazon-dashwand.svg': 'voiceassistant-amazon-dashwand.svg',
    'voiceassistant-amazon-echo-frozen.svg':
      'voiceassistant-amazon-echo-frozen.svg',
    'voiceassistant-amazon-echo.svg': 'voiceassistant-amazon-echo.svg',
    'voiceassistant-amazon-echoflex-frozen.svg':
      'voiceassistant-amazon-echoflex-frozen.svg',
    'voiceassistant-amazon-echoflex.svg': 'voiceassistant-amazon-echoflex.svg',
    'voiceassistant-amazon-echoinput-frozen.svg':
      'voiceassistant-amazon-echoinput-frozen.svg',
    'voiceassistant-amazon-echoinput.svg':
      'voiceassistant-amazon-echoinput.svg',
    'voiceassistant-amazon-echoplus-frozen.svg':
      'voiceassistant-amazon-echoplus-frozen.svg',
    'voiceassistant-amazon-echoplus.svg': 'voiceassistant-amazon-echoplus.svg',
    'voiceassistant-amazon-echoshow-frozen.svg':
      'voiceassistant-amazon-echoshow-frozen.svg',
    'voiceassistant-amazon-echoshow.svg': 'voiceassistant-amazon-echoshow.svg',
    'voiceassistant-amazon-echospot-frozen.svg':
      'voiceassistant-amazon-echospot-frozen.svg',
    'voiceassistant-amazon-echospot.svg': 'voiceassistant-amazon-echospot.svg',
    'voiceassistant-amazon-echostudio-frozen.svg':
      'voiceassistant-amazon-echostudio-frozen.svg',
    'voiceassistant-amazon-echostudio.svg':
      'voiceassistant-amazon-echostudio.svg',
    'voiceassistant-clova-desk-frozen.svg':
      'voiceassistant-clova-desk-frozen.svg',
    'voiceassistant-clova-desk.svg': 'voiceassistant-clova-desk.svg',
    'voiceassistant-frozen.svg': 'voiceassistant-frozen.svg',
    'voiceassistant-google-nest-frozen.svg':
      'voiceassistant-google-nest-frozen.svg',
    'voiceassistant-google-nest.svg': 'voiceassistant-google-nest.svg',
    'voiceassistant-jbl-linkspeaker-frozen.svg':
      'voiceassistant-jbl-linkspeaker-frozen.svg',
    'voiceassistant-jbl-linkspeaker.svg': 'voiceassistant-jbl-linkspeaker.svg',
    'voiceassistant-samsung-galaxyhome-frozen.svg':
      'voiceassistant-samsung-galaxyhome-frozen.svg',
    'voiceassistant-samsung-galaxyhome.svg':
      'voiceassistant-samsung-galaxyhome.svg',
    'voiceassistant.svg': 'voiceassistant.svg',
    'voipgateway-amazon-echoconnect-frozen.svg':
      'voipgateway-amazon-echoconnect-frozen.svg',
    'voipgateway-amazon-echoconnect.svg': 'voipgateway-amazon-echoconnect.svg',
    'voipgateway-audiocodes-mediapack-frozen.svg':
      'voipgateway-audiocodes-mediapack-frozen.svg',
    'voipgateway-audiocodes-mediapack.svg':
      'voipgateway-audiocodes-mediapack.svg',
    'voipgateway-avaya-ipphone-frozen.svg':
      'voipgateway-avaya-ipphone-frozen.svg',
    'voipgateway-avaya-ipphone.svg': 'voipgateway-avaya-ipphone.svg',
    'voipgateway-cisco-selsius-frozen.svg':
      'voipgateway-cisco-selsius-frozen.svg',
    'voipgateway-cisco-selsius.svg': 'voipgateway-cisco-selsius.svg',
    'voipgateway-cisco-sipuravoipgateway-frozen.svg':
      'voipgateway-cisco-sipuravoipgateway-frozen.svg',
    'voipgateway-cisco-sipuravoipgateway.svg':
      'voipgateway-cisco-sipuravoipgateway.svg',
    'voipgateway-cisco-spa122-frozen.svg':
      'voipgateway-cisco-spa122-frozen.svg',
    'voipgateway-cisco-spa122.svg': 'voipgateway-cisco-spa122.svg',
    'voipgateway-cisco-spa122ata-frozen.svg':
      'voipgateway-cisco-spa122ata-frozen.svg',
    'voipgateway-cisco-spa122ata.svg': 'voipgateway-cisco-spa122ata.svg',
    'voipgateway-frozen.svg': 'voipgateway-frozen.svg',
    'voipgateway-gigaset-dect-frozen.svg':
      'voipgateway-gigaset-dect-frozen.svg',
    'voipgateway-gigaset-dect.svg': 'voipgateway-gigaset-dect.svg',
    'voipgateway-google-fiber-frozen.svg':
      'voipgateway-google-fiber-frozen.svg',
    'voipgateway-google-fiber.svg': 'voipgateway-google-fiber.svg',
    'voipgateway-grandstream-analogtelephoneadapter-frozen.svg':
      'voipgateway-grandstream-analogtelephoneadapter-frozen.svg',
    'voipgateway-grandstream-analogtelephoneadapter.svg':
      'voipgateway-grandstream-analogtelephoneadapter.svg',
    'voipgateway-grandstream-voipphone-frozen.svg':
      'voipgateway-grandstream-voipphone-frozen.svg',
    'voipgateway-grandstream-voipphone.svg':
      'voipgateway-grandstream-voipphone.svg',
    'voipgateway-linksys-pap2t-frozen.svg':
      'voipgateway-linksys-pap2t-frozen.svg',
    'voipgateway-linksys-pap2t.svg': 'voipgateway-linksys-pap2t.svg',
    'voipgateway-magicjack-home-frozen.svg':
      'voipgateway-magicjack-home-frozen.svg',
    'voipgateway-magicjack-home.svg': 'voipgateway-magicjack-home.svg',
    'voipgateway-mediatrix-frozen.svg': 'voipgateway-mediatrix-frozen.svg',
    'voipgateway-mediatrix.svg': 'voipgateway-mediatrix.svg',
    'voipgateway-mitel-aastra-frozen.svg':
      'voipgateway-mitel-aastra-frozen.svg',
    'voipgateway-mitel-aastra.svg': 'voipgateway-mitel-aastra.svg',
    'voipgateway-mitel-sipphone-1.svg': 'voipgateway-mitel-sipphone-1.svg',
    'voipgateway-mitel-sipphone-frozen-1.svg':
      'voipgateway-mitel-sipphone-frozen-1.svg',
    'voipgateway-mitel-sipphone-frozen.svg':
      'voipgateway-mitel-sipphone-frozen.svg',
    'voipgateway-mitel-sipphone.svg': 'voipgateway-mitel-sipphone.svg',
    'voipgateway-nettalk-voipgateway-frozen.svg':
      'voipgateway-nettalk-voipgateway-frozen.svg',
    'voipgateway-nettalk-voipgateway.svg':
      'voipgateway-nettalk-voipgateway.svg',
    'voipgateway-obihai-obi202-frozen.svg':
      'voipgateway-obihai-obi202-frozen.svg',
    'voipgateway-obihai-obi202.svg': 'voipgateway-obihai-obi202.svg',
    'voipgateway-ooma-telo-frozen.svg': 'voipgateway-ooma-telo-frozen.svg',
    'voipgateway-ooma-telo.svg': 'voipgateway-ooma-telo.svg',
    'voipgateway-panasonic-kxtgp600-frozen.svg':
      'voipgateway-panasonic-kxtgp600-frozen.svg',
    'voipgateway-panasonic-kxtgp600.svg': 'voipgateway-panasonic-kxtgp600.svg',
    'voipgateway-panasonic-kxut123-frozen.svg':
      'voipgateway-panasonic-kxut123-frozen.svg',
    'voipgateway-panasonic-kxut123.svg': 'voipgateway-panasonic-kxut123.svg',
    'voipgateway-panasonic-phone-frozen.svg':
      'voipgateway-panasonic-phone-frozen.svg',
    'voipgateway-panasonic-phone.svg': 'voipgateway-panasonic-phone.svg',
    'voipgateway-polycom-obi200telephoneadapter-frozen.svg':
      'voipgateway-polycom-obi200telephoneadapter-frozen.svg',
    'voipgateway-polycom-obi200telephoneadapter.svg':
      'voipgateway-polycom-obi200telephoneadapter.svg',
    'voipgateway-polycom-telephoneadapter-frozen.svg':
      'voipgateway-polycom-telephoneadapter-frozen.svg',
    'voipgateway-polycom-telephoneadapter.svg':
      'voipgateway-polycom-telephoneadapter.svg',
    'voipgateway-polycom-voipphone-frozen.svg':
      'voipgateway-polycom-voipphone-frozen.svg',
    'voipgateway-polycom-voipphone.svg': 'voipgateway-polycom-voipphone.svg',
    'voipgateway-polycom-vvx-frozen.svg': 'voipgateway-polycom-vvx-frozen.svg',
    'voipgateway-polycom-vvx.svg': 'voipgateway-polycom-vvx.svg',
    'voipgateway-sangoma-ipphone-1.svg': 'voipgateway-sangoma-ipphone-1.svg',
    'voipgateway-sangoma-ipphone-frozen-1.svg':
      'voipgateway-sangoma-ipphone-frozen-1.svg',
    'voipgateway-sangoma-ipphone-frozen.svg':
      'voipgateway-sangoma-ipphone-frozen.svg',
    'voipgateway-sangoma-ipphone.svg': 'voipgateway-sangoma-ipphone.svg',
    'voipgateway-tmobile-linelink-frozen.svg':
      'voipgateway-tmobile-linelink-frozen.svg',
    'voipgateway-tmobile-linelink.svg': 'voipgateway-tmobile-linelink.svg',
    'voipgateway-vonage-telephoneadapter-frozen.svg':
      'voipgateway-vonage-telephoneadapter-frozen.svg',
    'voipgateway-vonage-telephoneadapter.svg':
      'voipgateway-vonage-telephoneadapter.svg',
    'voipgateway-vonage-vonage-frozen.svg':
      'voipgateway-vonage-vonage-frozen.svg',
    'voipgateway-vonage-vonage.svg': 'voipgateway-vonage-vonage.svg',
    'voipgateway-vtech-et635-frozen.svg': 'voipgateway-vtech-et635-frozen.svg',
    'voipgateway-vtech-et635.svg': 'voipgateway-vtech-et635.svg',
    'voipgateway-yealink-ipphone-frozen.svg':
      'voipgateway-yealink-ipphone-frozen.svg',
    'voipgateway-yealink-ipphone.svg': 'voipgateway-yealink-ipphone.svg',
    'voipgateway-yealink-videophone-frozen.svg':
      'voipgateway-yealink-videophone-frozen.svg',
    'voipgateway-yealink-videophone.svg': 'voipgateway-yealink-videophone.svg',
    'voipgateway-yealink-w52p-frozen.svg':
      'voipgateway-yealink-w52p-frozen.svg',
    'voipgateway-yealink-w52p.svg': 'voipgateway-yealink-w52p.svg',
    'voipgateway-yealink-w60b-frozen.svg':
      'voipgateway-yealink-w60b-frozen.svg',
    'voipgateway-yealink-w60b.svg': 'voipgateway-yealink-w60b.svg',
    'voipgateway.svg': 'voipgateway.svg',
    'washerdryer-bosch-dryer-frozen.svg': 'washerdryer-bosch-dryer-frozen.svg',
    'washerdryer-bosch-dryer.svg': 'washerdryer-bosch-dryer.svg',
    'washerdryer-frozen.svg': 'washerdryer-frozen.svg',
    'washerdryer-ge-dryer-frozen.svg': 'washerdryer-ge-dryer-frozen.svg',
    'washerdryer-ge-dryer.svg': 'washerdryer-ge-dryer.svg',
    'washerdryer-lg-dryer-frozen.svg': 'washerdryer-lg-dryer-frozen.svg',
    'washerdryer-lg-dryer.svg': 'washerdryer-lg-dryer.svg',
    'washerdryer-lg-washer-frozen.svg': 'washerdryer-lg-washer-frozen.svg',
    'washerdryer-lg-washer.svg': 'washerdryer-lg-washer.svg',
    'washerdryer-miele-washer-frozen.svg':
      'washerdryer-miele-washer-frozen.svg',
    'washerdryer-miele-washer.svg': 'washerdryer-miele-washer.svg',
    'washerdryer-samsung-dryer-frozen.svg':
      'washerdryer-samsung-dryer-frozen.svg',
    'washerdryer-samsung-dryer.svg': 'washerdryer-samsung-dryer.svg',
    'washerdryer-samsung-washer-frozen.svg':
      'washerdryer-samsung-washer-frozen.svg',
    'washerdryer-samsung-washer.svg': 'washerdryer-samsung-washer.svg',
    'washerdryer-siemens-heatpumpdryer-frozen.svg':
      'washerdryer-siemens-heatpumpdryer-frozen.svg',
    'washerdryer-siemens-heatpumpdryer.svg':
      'washerdryer-siemens-heatpumpdryer.svg',
    'washerdryer-siemens-washer-frozen.svg':
      'washerdryer-siemens-washer-frozen.svg',
    'washerdryer-siemens-washer.svg': 'washerdryer-siemens-washer.svg',
    'washerdryer-siemens-washerdryer-frozen.svg':
      'washerdryer-siemens-washerdryer-frozen.svg',
    'washerdryer-siemens-washerdryer.svg':
      'washerdryer-siemens-washerdryer.svg',
    'washerdryer-vzug-adorawash-frozen.svg':
      'washerdryer-vzug-adorawash-frozen.svg',
    'washerdryer-vzug-adorawash.svg': 'washerdryer-vzug-adorawash.svg',
    'washerdryer-vzug-dryer-frozen.svg': 'washerdryer-vzug-dryer-frozen.svg',
    'washerdryer-vzug-dryer.svg': 'washerdryer-vzug-dryer.svg',
    'washerdryer-vzug-washingmachine-frozen.svg':
      'washerdryer-vzug-washingmachine-frozen.svg',
    'washerdryer-vzug-washingmachine.svg':
      'washerdryer-vzug-washingmachine.svg',
    'washerdryer-whirlpool-dryer-frozen.svg':
      'washerdryer-whirlpool-dryer-frozen.svg',
    'washerdryer-whirlpool-dryer.svg': 'washerdryer-whirlpool-dryer.svg',
    'washerdryer-whirlpool-washer-frozen.svg':
      'washerdryer-whirlpool-washer-frozen.svg',
    'washerdryer-whirlpool-washer.svg': 'washerdryer-whirlpool-washer.svg',
    'washerdryer.svg': 'washerdryer.svg',
    'watch-amazfit-pace-frozen.svg': 'watch-amazfit-pace-frozen.svg',
    'watch-amazfit-pace.svg': 'watch-amazfit-pace.svg',
    'watch-amazfit-stratos-frozen.svg': 'watch-amazfit-stratos-frozen.svg',
    'watch-amazfit-stratos.svg': 'watch-amazfit-stratos.svg',
    'watch-amazfit-watch-frozen.svg': 'watch-amazfit-watch-frozen.svg',
    'watch-amazfit-watch.svg': 'watch-amazfit-watch.svg',
    'watch-apple-watch-frozen.svg': 'watch-apple-watch-frozen.svg',
    'watch-apple-watch.svg': 'watch-apple-watch.svg',
    'watch-doki-watch-frozen.svg': 'watch-doki-watch-frozen.svg',
    'watch-doki-watch.svg': 'watch-doki-watch.svg',
    'watch-fitbit-sense-frozen.svg': 'watch-fitbit-sense-frozen.svg',
    'watch-fitbit-sense.svg': 'watch-fitbit-sense.svg',
    'watch-frozen.svg': 'watch-frozen.svg',
    'watch-littlegenius-z5h-frozen.svg': 'watch-littlegenius-z5h-frozen.svg',
    'watch-littlegenius-z5h.svg': 'watch-littlegenius-z5h.svg',
    'watch-mobvoi-ticwatch-frozen.svg': 'watch-mobvoi-ticwatch-frozen.svg',
    'watch-mobvoi-ticwatch.svg': 'watch-mobvoi-ticwatch.svg',
    'watch-motorola-moto-frozen.svg': 'watch-motorola-moto-frozen.svg',
    'watch-motorola-moto.svg': 'watch-motorola-moto.svg',
    'watch-oppo-moto-frozen.svg': 'watch-oppo-moto-frozen.svg',
    'watch-oppo-watch.svg': 'watch-oppo-watch.svg',
    'watch-puma-smartwatch-frozen.svg': 'watch-puma-smartwatch-frozen.svg',
    'watch-puma-smartwatch.svg': 'watch-puma-smartwatch.svg',
    'watch-samsung-galaxy-frozen.svg': 'watch-samsung-galaxy-frozen.svg',
    'watch-samsung-galaxy.svg': 'watch-samsung-galaxy.svg',
    'watch-samsung-gear-frozen.svg': 'watch-samsung-gear-frozen.svg',
    'watch-samsung-gear.svg': 'watch-samsung-gear.svg',
    'watch-ticktalk-3-frozen.svg': 'watch-ticktalk-3-frozen.svg',
    'watch-ticktalk-3.svg': 'watch-ticktalk-3.svg',
    'watch-xplora-x4-frozen.svg': 'watch-xplora-x4-frozen.svg',
    'watch-xplora-x4.svg': 'watch-xplora-x4.svg',
    'watch.svg': 'watch.svg',
    'waterheater-1.svg': 'waterheater-1.svg',
    'waterheater-aquanta-waterheatercontroller-frozen.svg':
      'waterheater-aquanta-waterheatercontroller-frozen.svg',
    'waterheater-aquanta-waterheatercontroller.svg':
      'waterheater-aquanta-waterheatercontroller.svg',
    'waterheater-frozen-1.svg': 'waterheater-frozen-1.svg',
    'waterheater-frozen.svg': 'waterheater-frozen.svg',
    'waterheater-navien-navilink-frozen.svg':
      'waterheater-navien-navilink-frozen.svg',
    'waterheater-navien-navilink.svg': 'waterheater-navien-navilink.svg',
    'waterheater-nti-boiler-frozen.svg': 'waterheater-nti-boiler-frozen.svg',
    'waterheater-nti-boiler.svg': 'waterheater-nti-boiler.svg',
    'waterheater-rheem-econetwaterheater-frozen.svg':
      'waterheater-rheem-econetwaterheater-frozen.svg',
    'waterheater-rheem-econetwaterheater.svg':
      'waterheater-rheem-econetwaterheater.svg',
    'waterheater-rinnai-waterheater-frozen.svg':
      'waterheater-rinnai-waterheater-frozen.svg',
    'waterheater-rinnai-waterheater.svg': 'waterheater-rinnai-waterheater.svg',
    'waterheater.svg': 'waterheater.svg',
    'wearable-brightsign-glove-frozen.svg':
      'wearable-brightsign-glove-frozen.svg',
    'wearable-brightsign-glove.svg': 'wearable-brightsign-glove.svg',
    'wearable-fitbit-ace-frozen.svg': 'wearable-fitbit-ace-frozen.svg',
    'wearable-fitbit-ace.svg': 'wearable-fitbit-ace.svg',
    'wearable-fitbit-charge-frozen.svg': 'wearable-fitbit-charge-frozen.svg',
    'wearable-fitbit-charge.svg': 'wearable-fitbit-charge.svg',
    'wearable-fitbit-frozen.svg': 'wearable-fitbit-frozen.svg',
    'wearable-fitbit-inspire-frozen.svg': 'wearable-fitbit-inspire-frozen.svg',
    'wearable-fitbit-inspire.svg': 'wearable-fitbit-inspire.svg',
    'wearable-fitbit-ionic-frozen.svg': 'wearable-fitbit-ionic-frozen.svg',
    'wearable-fitbit-ionic.svg': 'wearable-fitbit-ionic.svg',
    'wearable-fitbit-versa-frozen.svg': 'wearable-fitbit-versa-frozen.svg',
    'wearable-fitbit-versa.svg': 'wearable-fitbit-versa.svg',
    'wearable-fitbit.svg': 'wearable-fitbit.svg',
    'wearable-fossil-q-frozen.svg': 'wearable-fossil-q-frozen.svg',
    'wearable-fossil-q.svg': 'wearable-fossil-q.svg',
    'wearable-fossil-sport-frozen.svg': 'wearable-fossil-sport-frozen.svg',
    'wearable-fossil-sport.svg': 'wearable-fossil-sport.svg',
    'wearable-frozen.svg': 'wearable-frozen.svg',
    'wearable-garmin-forerunner-frozen.svg':
      'wearable-garmin-forerunner-frozen.svg',
    'wearable-garmin-forerunner.svg': 'wearable-garmin-forerunner.svg',
    'wearable-garmin-frozen.svg': 'wearable-garmin-frozen.svg',
    'wearable-garmin-vivoactive-1.svg': 'wearable-garmin-vivoactive-1.svg',
    'wearable-garmin-vivoactive-frozen-1.svg':
      'wearable-garmin-vivoactive-frozen-1.svg',
    'wearable-garmin-vivoactive-frozen.svg':
      'wearable-garmin-vivoactive-frozen.svg',
    'wearable-garmin-vivoactive.svg': 'wearable-garmin-vivoactive.svg',
    'wearable-garmin-vivofit-frozen.svg': 'wearable-garmin-vivofit-frozen.svg',
    'wearable-garmin-vivofit.svg': 'wearable-garmin-vivofit.svg',
    'wearable-garmin-vivomove-frozen.svg':
      'wearable-garmin-vivomove-frozen.svg',
    'wearable-garmin-vivomove.svg': 'wearable-garmin-vivomove.svg',
    'wearable-garmin-vivosmart-frozen.svg':
      'wearable-garmin-vivosmart-frozen.svg',
    'wearable-garmin-vivosmart.svg': 'wearable-garmin-vivosmart.svg',
    'wearable-garmin-vivosport-frozen.svg':
      'wearable-garmin-vivosport-frozen.svg',
    'wearable-garmin-vivosport.svg': 'wearable-garmin-vivosport.svg',
    'wearable-garmin.svg': 'wearable-garmin.svg',
    'wearable-huawei-frozen.svg': 'wearable-huawei-frozen.svg',
    'wearable-huawei.svg': 'wearable-huawei.svg',
    'wearable-neebo-monitor-frozen.svg': 'wearable-neebo-monitor-frozen.svg',
    'wearable-neebo-monitor.svg': 'wearable-neebo-monitor.svg',
    'wearable-whistle-fit-frozen.svg': 'wearable-whistle-fit-frozen.svg',
    'wearable-whistle-fit.svg': 'wearable-whistle-fit.svg',
    'wearable.svg': 'wearable.svg',
    'weathermonitor-acurite-weatherstation-frozen.svg':
      'weathermonitor-acurite-weatherstation-frozen.svg',
    'weathermonitor-acurite-weatherstation.svg':
      'weathermonitor-acurite-weatherstation.svg',
    'weathermonitor-ambient-frozen.svg': 'weathermonitor-ambient-frozen.svg',
    'weathermonitor-ambient.svg': 'weathermonitor-ambient.svg',
    'weathermonitor-bloomsky-frozen.svg': 'weathermonitor-bloomsky-frozen.svg',
    'weathermonitor-bloomsky-storm-frozen.svg':
      'weathermonitor-bloomsky-storm-frozen.svg',
    'weathermonitor-bloomsky-storm.svg': 'weathermonitor-bloomsky-storm.svg',
    'weathermonitor-bloomsky-weatherstation-frozen.svg':
      'weathermonitor-bloomsky-weatherstation-frozen.svg',
    'weathermonitor-bloomsky-weatherstation.svg':
      'weathermonitor-bloomsky-weatherstation.svg',
    'weathermonitor-bloomsky.svg': 'weathermonitor-bloomsky.svg',
    'weathermonitor-davisinstruments-weatherlinklive-frozen.svg':
      'weathermonitor-davisinstruments-weatherlinklive-frozen.svg',
    'weathermonitor-davisinstruments-weatherlinklive.svg':
      'weathermonitor-davisinstruments-weatherlinklive.svg',
    'weathermonitor-frozen.svg': 'weathermonitor-frozen.svg',
    'weathermonitor-lacrosse-weatherstation-frozen.svg':
      'weathermonitor-lacrosse-weatherstation-frozen.svg',
    'weathermonitor-lacrosse-weatherstation.svg':
      'weathermonitor-lacrosse-weatherstation.svg',
    'weathermonitor-meteobridge-weatherstation-frozen.svg':
      'weathermonitor-meteobridge-weatherstation-frozen.svg',
    'weathermonitor-meteobridge-weatherstation.svg':
      'weathermonitor-meteobridge-weatherstation.svg',
    'weathermonitor-netatmo-personalweatherstation-frozen.svg':
      'weathermonitor-netatmo-personalweatherstation-frozen.svg',
    'weathermonitor-netatmo-personalweatherstation.svg':
      'weathermonitor-netatmo-personalweatherstation.svg',
    'weathermonitor-onelink-environmentmonitor-frozen.svg':
      'weathermonitor-onelink-environmentmonitor-frozen.svg',
    'weathermonitor-onelink-environmentmonitor.svg':
      'weathermonitor-onelink-environmentmonitor.svg',
    'weathermonitor-weatherflow-frozen.svg':
      'weathermonitor-weatherflow-frozen.svg',
    'weathermonitor-weatherflow.svg': 'weathermonitor-weatherflow.svg',
    'weathermonitor.svg': 'weathermonitor.svg',
    'wifitracker-bosch-nyon-frozen.svg': 'wifitracker-bosch-nyon-frozen.svg',
    'wifitracker-bosch-nyon.svg': 'wifitracker-bosch-nyon.svg',
    'wifitracker-frozen.svg': 'wifitracker-frozen.svg',
    'wifitracker-garmin-alpha-frozen.svg':
      'wifitracker-garmin-alpha-frozen.svg',
    'wifitracker-garmin-alpha.svg': 'wifitracker-garmin-alpha.svg',
    'wifitracker-garmin-dezl-frozen.svg': 'wifitracker-garmin-dezl-frozen.svg',
    'wifitracker-garmin-dezl.svg': 'wifitracker-garmin-dezl.svg',
    'wifitracker-garmin-edge-frozen.svg': 'wifitracker-garmin-edge-frozen.svg',
    'wifitracker-garmin-edge.svg': 'wifitracker-garmin-edge.svg',
    'wifitracker-garmin-gpsmap-frozen.svg':
      'wifitracker-garmin-gpsmap-frozen.svg',
    'wifitracker-garmin-gpsmap.svg': 'wifitracker-garmin-gpsmap.svg',
    'wifitracker-garmin-montana-frozen.svg':
      'wifitracker-garmin-montana-frozen.svg',
    'wifitracker-garmin-montana.svg': 'wifitracker-garmin-montana.svg',
    'wifitracker-garmin-overlander-frozen.svg':
      'wifitracker-garmin-overlander-frozen.svg',
    'wifitracker-garmin-overlander.svg': 'wifitracker-garmin-overlander.svg',
    'wifitracker-hammerhead-karoo-frozen.svg':
      'wifitracker-hammerhead-karoo-frozen.svg',
    'wifitracker-hammerhead-karoo.svg': 'wifitracker-hammerhead-karoo.svg',
    'wifitracker-jiobit-tracker-frozen.svg':
      'wifitracker-jiobit-tracker-frozen.svg',
    'wifitracker-jiobit-tracker.svg': 'wifitracker-jiobit-tracker.svg',
    'wifitracker-jiobit-wifitracker-frozen.svg':
      'wifitracker-jiobit-wifitracker-frozen.svg',
    'wifitracker-jiobit-wifitracker.svg': 'wifitracker-jiobit-wifitracker.svg',
    'wifitracker-pioneer-sgxca600-frozen.svg':
      'wifitracker-pioneer-sgxca600-frozen.svg',
    'wifitracker-pioneer-sgxca600.svg': 'wifitracker-pioneer-sgxca600.svg',
    'wifitracker-randmcnally-tnd-frozen.svg':
      'wifitracker-randmcnally-tnd-frozen.svg',
    'wifitracker-randmcnally-tnd.svg': 'wifitracker-randmcnally-tnd.svg',
    'wifitracker-samsung-connecttag-frozen.svg':
      'wifitracker-samsung-connecttag-frozen.svg',
    'wifitracker-samsung-connecttag.svg': 'wifitracker-samsung-connecttag.svg',
    'wifitracker-samsung-frozen.svg': 'wifitracker-samsung-frozen.svg',
    'wifitracker-samsung.svg': 'wifitracker-samsung.svg',
    'wifitracker-tomtom-gps-frozen.svg': 'wifitracker-tomtom-gps-frozen.svg',
    'wifitracker-tomtom-gps.svg': 'wifitracker-tomtom-gps.svg',
    'wifitracker-wahoo-elemnt-frozen.svg':
      'wifitracker-wahoo-elemnt-frozen.svg',
    'wifitracker-wahoo-elemnt.svg': 'wifitracker-wahoo-elemnt.svg',
    'wifitracker-whistle-go-frozen.svg': 'wifitracker-whistle-go-frozen.svg',
    'wifitracker-whistle-go.svg': 'wifitracker-whistle-go.svg',
    'wifitracker.svg': 'wifitracker.svg',
  },
  IotSmall: {
    'airconditioner-daikin-airconditioner.svg':
      'airconditioner-daikin-airconditioner.svg',
    'airconditioner-daikin.svg': 'airconditioner-daikin.svg',
    'airconditioner-fujitsu-ac.svg': 'airconditioner-fujitsu-ac.svg',
    'airconditioner-fujitsu.svg': 'airconditioner-fujitsu.svg',
    'airconditioner-haier.svg': 'airconditioner-haier.svg',
    'airconditioner-midea-airconditioner.svg':
      'airconditioner-midea-airconditioner.svg',
    'airconditioner-midea.svg': 'airconditioner-midea.svg',
    'airconditioner-mitsubishi-ac.svg': 'airconditioner-mitsubishi-ac.svg',
    'airconditioner-mitsubishi.svg': 'airconditioner-mitsubishi.svg',
    'airconditioner-samsung-ac.svg': 'airconditioner-samsung-ac.svg',
    'airconditioner-samsung.svg': 'airconditioner-samsung.svg',
    'airconditioner-sensibo-skysmartac.svg':
      'airconditioner-sensibo-skysmartac.svg',
    'airconditioner-sensibo-smartairconditioner.svg':
      'airconditioner-sensibo-smartairconditioner.svg',
    'airconditioner-sensibo.svg': 'airconditioner-sensibo.svg',
    'airconditioner-tado-smartac.svg': 'airconditioner-tado-smartac.svg',
    'airconditioner-tado-smartaccontrol.svg':
      'airconditioner-tado-smartaccontrol.svg',
    'airconditioner-tado.svg': 'airconditioner-tado.svg',
    'airconditioner-whirlpool.svg': 'airconditioner-whirlpool.svg',
    'airconditioner-xiaomi.svg': 'airconditioner-xiaomi.svg',
    'airconditioner.svg': 'airconditioner.svg',
    'airfryer.svg': 'airfryer.svg',
    'airpurifier-amway-atmosphereairpurifier.svg':
      'airpurifier-amway-atmosphereairpurifier.svg',
    'airpurifier-amway.svg': 'airpurifier-amway.svg',
    'airpurifier-awair-glow-2.svg': 'airpurifier-awair-glow-2.svg',
    'airpurifier-awair-glow.svg': 'airpurifier-awair-glow.svg',
    'airpurifier-awair.svg': 'airpurifier-awair.svg',
    'airpurifier-blueair-airpurifier.svg':
      'airpurifier-blueair-airpurifier.svg',
    'airpurifier-blueair.svg': 'airpurifier-blueair.svg',
    'airpurifier-brid-airpurifier.svg': 'airpurifier-brid-airpurifier.svg',
    'airpurifier-brid.svg': 'airpurifier-brid.svg',
    'airpurifier-broadlink-a1.svg': 'airpurifier-broadlink-a1.svg',
    'airpurifier-broadlink.svg': 'airpurifier-broadlink.svg',
    'airpurifier-coway-airmega.svg': 'airpurifier-coway-airmega.svg',
    'airpurifier-coway.svg': 'airpurifier-coway.svg',
    'airpurifier-dyson-purecool.svg': 'airpurifier-dyson-purecool.svg',
    'airpurifier-dyson-purecoolhepa.svg': 'airpurifier-dyson-purecoolhepa.svg',
    'airpurifier-dyson-purecoollink.svg': 'airpurifier-dyson-purecoollink.svg',
    'airpurifier-dyson-purehot+cool.svg': 'airpurifier-dyson-purehot+cool.svg',
    'airpurifier-dyson-purehot+coollink.svg':
      'airpurifier-dyson-purehot+coollink.svg',
    'airpurifier-dyson-purehumidify+cool.svg':
      'airpurifier-dyson-purehumidify+cool.svg',
    'airpurifier-dyson.svg': 'airpurifier-dyson.svg',
    'airpurifier-levoit-airpurifier.svg': 'airpurifier-levoit-airpurifier.svg',
    'airpurifier-levoit.svg': 'airpurifier-levoit.svg',
    'airpurifier-molekule.svg': 'airpurifier-molekule.svg',
    'airpurifier-philips-airpurifier.svg':
      'airpurifier-philips-airpurifier.svg',
    'airpurifier-philips.svg': 'airpurifier-philips.svg',
    'airpurifier-rabbitair-airpurifier.svg':
      'airpurifier-rabbitair-airpurifier.svg',
    'airpurifier-rabbitair.svg': 'airpurifier-rabbitair.svg',
    'airpurifier-samsung.svg': 'airpurifier-samsung.svg',
    'airpurifier-vocolinc-flowerbud.svg': 'airpurifier-vocolinc-flowerbud.svg',
    'airpurifier-vocolinc-pureflow.svg': 'airpurifier-vocolinc-pureflow.svg',
    'airpurifier-vocolinc.svg': 'airpurifier-vocolinc.svg',
    'airpurifier-winix.svg': 'airpurifier-winix.svg',
    'airpurifier-xiaomi.svg': 'airpurifier-xiaomi.svg',
    'airpurifier.svg': 'airpurifier.svg',
    'applianceorwearable-asix-ethernetadapter.svg':
      'applianceorwearable-asix-ethernetadapter.svg',
    'atgames-legendsultimate.svg': 'atgames-legendsultimate.svg',
    'babycamera-arlo.svg': 'babycamera-arlo.svg',
    'babycamera-cubo-ai.svg': 'babycamera-cubo-ai.svg',
    'babycamera-dophigo-camera.svg': 'babycamera-dophigo-camera.svg',
    'babycamera-lollipop-babymonitorsmartcamera.svg':
      'babycamera-lollipop-babymonitorsmartcamera.svg',
    'babycamera-miku-babymonitor.svg': 'babycamera-miku-babymonitor.svg',
    'babycamera-nanit.svg': 'babycamera-nanit.svg',
    'babycamera-nooie-ipc100.svg': 'babycamera-nooie-ipc100.svg',
    'babycamera-owlet-babymonitor.svg': 'babycamera-owlet-babymonitor.svg',
    'babycamera-philips-insight.svg': 'babycamera-philips-insight.svg',
    'babycamera-safety1st-babymonitor.svg':
      'babycamera-safety1st-babymonitor.svg',
    'babycamera-vava-babymonitor.svg': 'babycamera-vava-babymonitor.svg',
    'babycamera-victure-pc420.svg': 'babycamera-victure-pc420.svg',
    'babycamera-vtech-babymonitor.svg': 'babycamera-vtech-babymonitor.svg',
    'bitcoinminer-2.svg': 'bitcoinminer-2.svg',
    'bitcoinminer-antminer.svg': 'bitcoinminer-antminer.svg',
    'bitcoinminer-coinmine-one.svg': 'bitcoinminer-coinmine-one.svg',
    'bitcoinminer-halongmining-dragonmint.svg':
      'bitcoinminer-halongmining-dragonmint.svg',
    'bitcoinminer-innosilicon-miner.svg': 'bitcoinminer-innosilicon-miner.svg',
    'bitcoinminer.svg': 'bitcoinminer.svg',
    'blender.svg': 'blender.svg',
    'camera-2gig-hd100.svg': 'camera-2gig-hd100.svg',
    'camera-360-camera.svg': 'camera-360-camera.svg',
    'camera-360eyes-pro.svg': 'camera-360eyes-pro.svg',
    'camera-amazon-cloudcam.svg': 'camera-amazon-cloudcam.svg',
    'camera-amazon-echo-look.svg': 'camera-amazon-echo-look.svg',
    'camera-amazonring-floodlightcam.svg':
      'camera-amazonring-floodlightcam.svg',
    'camera-amazonring-indoorcam.svg': 'camera-amazonring-indoorcam.svg',
    'camera-amazonring-spotlightcam.svg': 'camera-amazonring-spotlightcam.svg',
    'camera-amazonring-stickupcam.svg': 'camera-amazonring-stickupcam.svg',
    'camera-amazonring.svg': 'camera-amazonring.svg',
    'camera-ambarella-securitycamera.svg':
      'camera-ambarella-securitycamera.svg',
    'camera-amcrest.svg': 'camera-amcrest.svg',
    'camera-arlo-essentialspotlightcamera.svg':
      'camera-arlo-essentialspotlightcamera.svg',
    'camera-arlo-floodlightcamera.svg': 'camera-arlo-floodlightcamera.svg',
    'camera-arlo.svg': 'camera-arlo.svg',
    'camera-axis-networkcamera.svg': 'camera-axis-networkcamera.svg',
    'camera-belkin-netcam.svg': 'camera-belkin-netcam.svg',
    'camera-binatone-homemonitor.svg': 'camera-binatone-homemonitor.svg',
    'camera-blink.svg': 'camera-blink.svg',
    'camera-bosch-autodome.svg': 'camera-bosch-autodome.svg',
    'camera-bosch-flexidome.svg': 'camera-bosch-flexidome.svg',
    'camera-canary.svg': 'camera-canary.svg',
    'camera-canon-eos.svg': 'camera-canon-eos.svg',
    'camera-canon-powershot.svg': 'camera-canon-powershot.svg',
    'camera-cocoon.svg': 'camera-cocoon.svg',
    'camera-comcast-xcam2.svg': 'camera-comcast-xcam2.svg',
    'camera-conico-securitycamera.svg': 'camera-conico-securitycamera.svg',
    'camera-dlink-miniwificamera.svg': 'camera-dlink-miniwificamera.svg',
    'camera-dlink-pantiltwificamera.svg': 'camera-dlink-pantiltwificamera.svg',
    'camera-dlink-vigilance.svg': 'camera-dlink-vigilance.svg',
    'camera-dlink-wificamera.svg': 'camera-dlink-wificamera.svg',
    'camera-dlink.svg': 'camera-dlink.svg',
    'camera-etiger-ipcamera.svg': 'camera-etiger-ipcamera.svg',
    'camera-eve-cam.svg': 'camera-eve-cam.svg',
    'camera-ezviz-c2c.svg': 'camera-ezviz-c2c.svg',
    'camera-ezviz-c6tc.svg': 'camera-ezviz-c6tc.svg',
    'camera-ezviz-camera.svg': 'camera-ezviz-camera.svg',
    'camera-ezviz-husky.svg': 'camera-ezviz-husky.svg',
    'camera-ezviz.svg': 'camera-ezviz.svg',
    'camera-faleemi.svg': 'camera-faleemi.svg',
    'camera-flir-networkvideorecorder.svg':
      'camera-flir-networkvideorecorder.svg',
    'camera-furbo-dogcamera.svg': 'camera-furbo-dogcamera.svg',
    'camera-geeni-smartwificamera.svg': 'camera-geeni-smartwificamera.svg',
    'camera-googlenest.svg': 'camera-googlenest.svg',
    'camera-gopro-camera.svg': 'camera-gopro-camera.svg',
    'camera-gopro.svg': 'camera-gopro.svg',
    'camera-gwsecurity-outdoorcamera.svg':
      'camera-gwsecurity-outdoorcamera.svg',
    'camera-hikvision-networkcamera.svg': 'camera-hikvision-networkcamera.svg',
    'camera-hive-view.svg': 'camera-hive-view.svg',
    'camera-honeywell-hqa.svg': 'camera-honeywell-hqa.svg',
    'camera-honeywell-lyricsecuritycamera.svg':
      'camera-honeywell-lyricsecuritycamera.svg',
    'camera-honeywelllyric-securitycamera.svg':
      'camera-honeywelllyric-securitycamera.svg',
    'camera-hugoai-securitycamera.svg': 'camera-hugoai-securitycamera.svg',
    'camera-iodata-tsns110w.svg': 'camera-iodata-tsns110w.svg',
    'camera-ismart-icamerakeep.svg': 'camera-ismart-icamerakeep.svg',
    'camera-johndeere-camera.svg': 'camera-johndeere-camera.svg',
    'camera-jsw-ipcamera.svg': 'camera-jsw-ipcamera.svg',
    'camera-kodak-pixpro.svg': 'camera-kodak-pixpro.svg',
    'camera-kodak-videomonitor.svg': 'camera-kodak-videomonitor.svg',
    'camera-kuna.svg': 'camera-kuna.svg',
    'camera-leica-q.svg': 'camera-leica-q.svg',
    'camera-litmor.svg': 'camera-litmor.svg',
    'camera-logitech-circle.svg': 'camera-logitech-circle.svg',
    'camera-lorex-ip.svg': 'camera-lorex-ip.svg',
    'camera-lorex-ipcamera.svg': 'camera-lorex-ipcamera.svg',
    'camera-lorex-wificamera.svg': 'camera-lorex-wificamera.svg',
    'camera-marshall-cv346.svg': 'camera-marshall-cv346.svg',
    'camera-mevo-camera.svg': 'camera-mevo-camera.svg',
    'camera-microseven-camera.svg': 'camera-microseven-camera.svg',
    'camera-momentum-axelsmartcamera.svg':
      'camera-momentum-axelsmartcamera.svg',
    'camera-momentum-cori.svg': 'camera-momentum-cori.svg',
    'camera-momentum-mocam.svg': 'camera-momentum-mocam.svg',
    'camera-momentum-robbi.svg': 'camera-momentum-robbi.svg',
    'camera-netatmo-presence.svg': 'camera-netatmo-presence.svg',
    'camera-netatmo.svg': 'camera-netatmo.svg',
    'camera-nightowl-securitycamera.svg': 'camera-nightowl-securitycamera.svg',
    'camera-nikon-z6.svg': 'camera-nikon-z6.svg',
    'camera-nucam-yieyespycamera.svg': 'camera-nucam-yieyespycamera.svg',
    'camera-olympus-stylus.svg': 'camera-olympus-stylus.svg',
    'camera-owlet.svg': 'camera-owlet.svg',
    'camera-panasonic-homehawk.svg': 'camera-panasonic-homehawk.svg',
    'camera-panasonic-lumix.svg': 'camera-panasonic-lumix.svg',
    'camera-petchatz-hd.svg': 'camera-petchatz-hd.svg',
    'camera-petcube-bites.svg': 'camera-petcube-bites.svg',
    'camera-petcube.svg': 'camera-petcube.svg',
    'camera-petzi-treatcam.svg': 'camera-petzi-treatcam.svg',
    'camera-piper-securitycamera.svg': 'camera-piper-securitycamera.svg',
    'camera-ptzoptics-camera.svg': 'camera-ptzoptics-camera.svg',
    'camera-reolink-c1pro.svg': 'camera-reolink-c1pro.svg',
    'camera-reolink-c2.svg': 'camera-reolink-c2.svg',
    'camera-reolink-camera.svg': 'camera-reolink-camera.svg',
    'camera-reolink-e1.svg': 'camera-reolink-e1.svg',
    'camera-reolink-rlc410w.svg': 'camera-reolink-rlc410w.svg',
    'camera-reolink-rlc511w.svg': 'camera-reolink-rlc511w.svg',
    'camera-rflink.svg': 'camera-rflink.svg',
    'camera-samsung-smartcam.svg': 'camera-samsung-smartcam.svg',
    'camera-samsung-smartthingscam.svg': 'camera-samsung-smartthingscam.svg',
    'camera-sercomm-ipcamera.svg': 'camera-sercomm-ipcamera.svg',
    'camera-shenzhen-ipcamera.svg': 'camera-shenzhen-ipcamera.svg',
    'camera-simplisafe-securitycamera.svg':
      'camera-simplisafe-securitycamera.svg',
    'camera-smarteye.svg': 'camera-smarteye.svg',
    'camera-somfy-indoorcamera.svg': 'camera-somfy-indoorcamera.svg',
    'camera-somfy-one.svg': 'camera-somfy-one.svg',
    'camera-somfy-outdoorcamera.svg': 'camera-somfy-outdoorcamera.svg',
    'camera-sony.svg': 'camera-sony.svg',
    'camera-sricam-ipcamera.svg': 'camera-sricam-ipcamera.svg',
    'camera-swann-pantilt.svg': 'camera-swann-pantilt.svg',
    'camera-swann-securitycamera.svg': 'camera-swann-securitycamera.svg',
    'camera-teckin-tc100.svg': 'camera-teckin-tc100.svg',
    'camera-tend-lynx.svg': 'camera-tend-lynx.svg',
    'camera-tendinsights-stuartcam.svg': 'camera-tendinsights-stuartcam.svg',
    'camera-toucan-outdoorcamera.svg': 'camera-toucan-outdoorcamera.svg',
    'camera-tplink-cloudcamera.svg': 'camera-tplink-cloudcamera.svg',
    'camera-tplink-hdpantiltcamera.svg': 'camera-tplink-hdpantiltcamera.svg',
    'camera-tplink-kasacam.svg': 'camera-tplink-kasacam.svg',
    'camera-tplink-kasaoutdoorcam.svg': 'camera-tplink-kasaoutdoorcam.svg',
    'camera-tplink-tapo.svg': 'camera-tplink-tapo.svg',
    'camera-ubiquiti-unifi.svg': 'camera-ubiquiti-unifi.svg',
    'camera-ulifecam-ipcamera.svg': 'camera-ulifecam-ipcamera.svg',
    'camera-veo-camera.svg': 'camera-veo-camera.svg',
    'camera-victure-ipcamera.svg': 'camera-victure-ipcamera.svg',
    'camera-vivint-outdoorcamera.svg': 'camera-vivint-outdoorcamera.svg',
    'camera-vivotek.svg': 'camera-vivotek.svg',
    'camera-wansview.svg': 'camera-wansview.svg',
    'camera-withings-home.svg': 'camera-withings-home.svg',
    'camera-wyze-cam.svg': 'camera-wyze-cam.svg',
    'camera-xfinity-homeindooroutdoorcamera.svg':
      'camera-xfinity-homeindooroutdoorcamera.svg',
    'camera-xiaomi-chuangmicamera.svg': 'camera-xiaomi-chuangmicamera.svg',
    'camera-xiaomi-mijiacamera.svg': 'camera-xiaomi-mijiacamera.svg',
    'camera-xiaomi.svg': 'camera-xiaomi.svg',
    'camera-yi.svg': 'camera-yi.svg',
    'camera-zmodo.svg': 'camera-zmodo.svg',
    'camera.svg': 'camera.svg',
    'camerascamcorders-eufy-camera.svg': 'camerascamcorders-eufy-camera.svg',
    'camerascamcorders-neos-smartcam.svg':
      'camerascamcorders-neos-smartcam.svg',
    'camerascamcorders-swann-ads450.svg': 'camerascamcorders-swann-ads450.svg',
    'car-bolt.svg': 'car-bolt.svg',
    'car-chevrolet.svg': 'car-chevrolet.svg',
    'car-ford-sync.svg': 'car-ford-sync.svg',
    'car-ford.svg': 'car-ford.svg',
    'car-tesla.svg': 'car-tesla.svg',
    'car.svg': 'car.svg',
    'clock-2.svg': 'clock-2.svg',
    'clock-geochron-atlas4k.svg': 'clock-geochron-atlas4k.svg',
    'clock-lametric-time.svg': 'clock-lametric-time.svg',
    'clock-lenovo-smartclock.svg': 'clock-lenovo-smartclock.svg',
    'clock-magnavox-digitalclock.svg': 'clock-magnavox-digitalclock.svg',
    'clock-puri-nixieclock.svg': 'clock-puri-nixieclock.svg',
    'clock-raspberrypi-hamclock.svg': 'clock-raspberrypi-hamclock.svg',
    'clock-sandman-doppler.svg': 'clock-sandman-doppler.svg',
    'clock-xiaomi-ai.svg': 'clock-xiaomi-ai.svg',
    'clock-xiaomi-mijiaclock.svg': 'clock-xiaomi-mijiaclock.svg',
    'clock.svg': 'clock.svg',
    'coffeemaker-2.svg': 'coffeemaker-2.svg',
    'coffeemaker-bosch-coffeemachine.svg':
      'coffeemaker-bosch-coffeemachine.svg',
    'coffeemaker-delonghi-coffeemaker.svg':
      'coffeemaker-delonghi-coffeemaker.svg',
    'coffeemaker-keurig.svg': 'coffeemaker-keurig.svg',
    'coffeemaker-siemens-coffeemachine.svg':
      'coffeemaker-siemens-coffeemachine.svg',
    'coffeemaker-thermador-coffeemachine.svg':
      'coffeemaker-thermador-coffeemachine.svg',
    'coffeemaker.svg': 'coffeemaker.svg',
    'computer-acer-aspire.svg': 'computer-acer-aspire.svg',
    'computer-acer-chromebook.svg': 'computer-acer-chromebook.svg',
    'computer-apple-imac.svg': 'computer-apple-imac.svg',
    'computer-apple-mac.svg': 'computer-apple-mac.svg',
    'computer-apple-macmini.svg': 'computer-apple-macmini.svg',
    'computer-apple-macpro.svg': 'computer-apple-macpro.svg',
    'computer-asus-chromebook.svg': 'computer-asus-chromebook.svg',
    'computer-asus-desktop.svg': 'computer-asus-desktop.svg',
    'computer-asus-tinkerboard.svg': 'computer-asus-tinkerboard.svg',
    'computer-asus-windows.svg': 'computer-asus-windows.svg',
    'computer-asus-zenbook.svg': 'computer-asus-zenbook.svg',
    'computer-asus.svg': 'computer-asus.svg',
    'computer-atrust-thinclient.svg': 'computer-atrust-thinclient.svg',
    'computer-compulab-airtop.svg': 'computer-compulab-airtop.svg',
    'computer-dell-alienware.svg': 'computer-dell-alienware.svg',
    'computer-dell-chromebook.svg': 'computer-dell-chromebook.svg',
    'computer-dell-dimension.svg': 'computer-dell-dimension.svg',
    'computer-dell-g5.svg': 'computer-dell-g5.svg',
    'computer-dell-windows-1.svg': 'computer-dell-windows-1.svg',
    'computer-dell-windows.svg': 'computer-dell-windows.svg',
    'computer-dell-xps.svg': 'computer-dell-xps.svg',
    'computer-desktop.svg': 'computer-desktop.svg',
    'computer-fujitsu-fmvda2a041.svg': 'computer-fujitsu-fmvda2a041.svg',
    'computer-gigabyte-brix.svg': 'computer-gigabyte-brix.svg',
    'computer-google chromebook.svg': 'computer-google chromebook.svg',
    'computer-google-chromebook.svg': 'computer-google-chromebook.svg',
    'computer-google-pixelbook.svg': 'computer-google-pixelbook.svg',
    'computer-hp-chromebook.svg': 'computer-hp-chromebook.svg',
    'computer-hp-eliteone.svg': 'computer-hp-eliteone.svg',
    'computer-hp-workstation.svg': 'computer-hp-workstation.svg',
    'computer-ibuypower-gamingcomputer.svg':
      'computer-ibuypower-gamingcomputer.svg',
    'computer-ibuypower-revolt2.svg': 'computer-ibuypower-revolt2.svg',
    'computer-igel-thinclient.svg': 'computer-igel-thinclient.svg',
    'computer-imsai-8080.svg': 'computer-imsai-8080.svg',
    'computer-intel-nuc.svg': 'computer-intel-nuc.svg',
    'computer-leadtek-pcoip.svg': 'computer-leadtek-pcoip.svg',
    'computer-lenovo-thinkcentre.svg': 'computer-lenovo-thinkcentre.svg',
    'computer-microsoft-surface.svg': 'computer-microsoft-surface.svg',
    'computer-optiplex.svg': 'computer-optiplex.svg',
    'computer-orangepi-pc.svg': 'computer-orangepi-pc.svg',
    'computer-pidp-computer.svg': 'computer-pidp-computer.svg',
    'computer-vizio.svg': 'computer-vizio.svg',
    'computer.svg': 'computer.svg',
    'cooker-bosch-cookit.svg': 'cooker-bosch-cookit.svg',
    'cooker-bosch-oven.svg': 'cooker-bosch-oven.svg',
    'cooker-bosch.svg': 'cooker-bosch.svg',
    'cooker-thermomix-tm6.svg': 'cooker-thermomix-tm6.svg',
    'cooker-thermomix.svg': 'cooker-thermomix.svg',
    'cooker.svg': 'cooker.svg',
    'cooktop-bosch-cooktop.svg': 'cooktop-bosch-cooktop.svg',
    'cooktop-siemens.svg': 'cooktop-siemens.svg',
    'cooktop-thermador-gasrangev.svg': 'cooktop-thermador-gasrangev.svg',
    'cooktop-thermador-hood.svg': 'cooktop-thermador-hood.svg',
    'cooktop-thermador.svg': 'cooktop-thermador.svg',
    'cooktop-vzug.svg': 'cooktop-vzug.svg',
    'cooktop.svg': 'cooktop.svg',
    'customgateway-cambium-epmp.svg': 'customgateway-cambium-epmp.svg',
    'customgateway-circle-home.svg': 'customgateway-circle-home.svg',
    'customgateway-cujo-securegateway.svg':
      'customgateway-cujo-securegateway.svg',
    'customgateway-intesis-gateway.svg': 'customgateway-intesis-gateway.svg',
    'customgateway-sharkrf-openspot.svg': 'customgateway-sharkrf-openspot.svg',
    'customgateway.svg': 'customgateway.svg',
    'diffuser-aera-smartdiffuser.svg': 'diffuser-aera-smartdiffuser.svg',
    'diffuser-pura-smartdiffuser.svg': 'diffuser-pura-smartdiffuser.svg',
    'diffuser.svg': 'diffuser.svg',
    'dishwasher-bosch-dishwasher.svg': 'dishwasher-bosch-dishwasher.svg',
    'dishwasher-bosch-shem78wh5n.svg': 'dishwasher-bosch-shem78wh5n.svg',
    'dishwasher-cove-dishwasher.svg': 'dishwasher-cove-dishwasher.svg',
    'dishwasher-ge.svg': 'dishwasher-ge.svg',
    'dishwasher-lg.svg': 'dishwasher-lg.svg',
    'dishwasher-samsung.svg': 'dishwasher-samsung.svg',
    'dishwasher-seimens.svg': 'dishwasher-seimens.svg',
    'dishwasher-thermador.svg': 'dishwasher-thermador.svg',
    'dishwasher-vzug.svg': 'dishwasher-vzug.svg',
    'dishwasher-whirlpool.svg': 'dishwasher-whirlpool.svg',
    'dishwasher.svg': 'dishwasher.svg',
    'doorbell-1.svg': 'doorbell-1.svg',
    'doorbell-2.svg': 'doorbell-2.svg',
    'doorbell-amazonring-chime.svg': 'doorbell-amazonring-chime.svg',
    'doorbell-amazonring-chimepro.svg': 'doorbell-amazonring-chimepro.svg',
    'doorbell-amazonring-elite.svg': 'doorbell-amazonring-elite.svg',
    'doorbell-amazonring-pro.svg': 'doorbell-amazonring-pro.svg',
    'doorbell-amazonring-videodoorbell.svg':
      'doorbell-amazonring-videodoorbell.svg',
    'doorbell-amazonring.svg': 'doorbell-amazonring.svg',
    'doorbell-amcrest-videodoorbell.svg': 'doorbell-amcrest-videodoorbell.svg',
    'doorbell-arlo-videodoorbell.svg': 'doorbell-arlo-videodoorbell.svg',
    'doorbell-august-view.svg': 'doorbell-august-view.svg',
    'doorbell-bticino-classe.svg': 'doorbell-bticino-classe.svg',
    'doorbell-control4-chime.svg': 'doorbell-control4-chime.svg',
    'doorbell-dling-smartvideodoorbell.svg':
      'doorbell-dling-smartvideodoorbell.svg',
    'doorbell-doorbird-videointercom.svg':
      'doorbell-doorbird-videointercom.svg',
    'doorbell-eufy-videodoorbell.svg': 'doorbell-eufy-videodoorbell.svg',
    'doorbell-kangaroo-doorbellcamera.svg':
      'doorbell-kangaroo-doorbellcamera.svg',
    'doorbell-nest.svg': 'doorbell-nest.svg',
    'doorbell-panasonic-videointercom.svg':
      'doorbell-panasonic-videointercom.svg',
    'doorbell-remo+.svg': 'doorbell-remo+.svg',
    'doorbell-ring-videodoorbell.svg': 'doorbell-ring-videodoorbell.svg',
    'doorbell-ring.svg': 'doorbell-ring.svg',
    'doorbell-skybell.svg': 'doorbell-skybell.svg',
    'doorbell-toucan-videodoorbell.svg': 'doorbell-toucan-videodoorbell.svg',
    'doorbell.svg': 'doorbell.svg',
    'doorlock-2.svg': 'doorlock-2.svg',
    'doorlock-august-smartlock.svg': 'doorlock-august-smartlock.svg',
    'doorlock-halo-smartlock.svg': 'doorlock-halo-smartlock.svg',
    'doorlock-kwikset-halo.svg': 'doorlock-kwikset-halo.svg',
    'doorlock-nuki-smartlock.svg': 'doorlock-nuki-smartlock.svg',
    'doorlock-schlage-smartdeadbolt.svg': 'doorlock-schlage-smartdeadbolt.svg',
    'doorlock-sesame-smartlock.svg': 'doorlock-sesame-smartlock.svg',
    'doorlock-ultraloq-deadbolt.svg': 'doorlock-ultraloq-deadbolt.svg',
    'doorlock-wyze-lock.svg': 'doorlock-wyze-lock.svg',
    'doorlock.svg': 'doorlock.svg',
    'ereader-amazon-kindle.svg': 'ereader-amazon-kindle.svg',
    'ereader-barnesandnoble-nook.svg': 'ereader-barnesandnoble-nook.svg',
    'ereader-ebook.svg': 'ereader-ebook.svg',
    'ereader-ereader-amazon-kindle.svg': 'ereader-ereader-amazon-kindle.svg',
    'ereader-kobo.svg': 'ereader-kobo.svg',
    'ereader-tolino.svg': 'ereader-tolino.svg',
    'evcharger-chargepoint.svg': 'evcharger-chargepoint.svg',
    'evcharger-easee-home.svg': 'evcharger-easee-home.svg',
    'evcharger-flo-home.svg': 'evcharger-flo-home.svg',
    'evcharger-goe-evcharger.svg': 'evcharger-goe-evcharger.svg',
    'evcharger-juicebox.svg': 'evcharger-juicebox.svg',
    'evcharger-openevse-evcharger.svg': 'evcharger-openevse-evcharger.svg',
    'evcharger-tesla-wallconnector.svg': 'evcharger-tesla-wallconnector.svg',
    'evcharger.svg': 'evcharger.svg',
    'fan-bigassfans-haiku.svg': 'fan-bigassfans-haiku.svg',
    'fan-bigassfans-haikufan.svg': 'fan-bigassfans-haikufan.svg',
    'fan-dyson-coolfan.svg': 'fan-dyson-coolfan.svg',
    'fan-dyson-purecool.svg': 'fan-dyson-purecool.svg',
    'fan-dyson-purecoolfan-1.svg': 'fan-dyson-purecoolfan-1.svg',
    'fan-dyson-purecoolfan.svg': 'fan-dyson-purecoolfan.svg',
    'fan-dyson-purehot+cool.svg': 'fan-dyson-purehot+cool.svg',
    'fan-dyson-purehot+coolfan.svg': 'fan-dyson-purehot+coolfan.svg',
    'fan-dyson.svg': 'fan-dyson.svg',
    'fan-haiku.svg': 'fan-haiku.svg',
    'fan-hunter-ceilingfan.svg': 'fan-hunter-ceilingfan.svg',
    'fan-xiaomi-smartmipedestalfan.svg': 'fan-xiaomi-smartmipedestalfan.svg',
    'fan.svg': 'fan.svg',
    'fitnessbike-myxfitness-bike.svg': 'fitnessbike-myxfitness-bike.svg',
    'fitnessbike-myxfitness.svg': 'fitnessbike-myxfitness.svg',
    'fitnessbike-nordictrack-bike.svg': 'fitnessbike-nordictrack-bike.svg',
    'fitnessbike-nordictrack.svg': 'fitnessbike-nordictrack.svg',
    'fitnessbike-peleton-bike.svg': 'fitnessbike-peleton-bike.svg',
    'fitnessbike-peleton.svg': 'fitnessbike-peleton.svg',
    'fitnessbike.svg': 'fitnessbike.svg',
    'fitnesstrainer-fightcamp-athomeboxing.svg':
      'fitnesstrainer-fightcamp-athomeboxing.svg',
    'fitnesstrainer-mirror-fitness.svg': 'fitnesstrainer-mirror-fitness.svg',
    'fitnesstrainer-nordictrack-trainer.svg':
      'fitnesstrainer-nordictrack-trainer.svg',
    'fitnesstrainer-nordictrack-treadmill.svg':
      'fitnesstrainer-nordictrack-treadmill.svg',
    'fitnesstrainer-nordictrack.svg': 'fitnesstrainer-nordictrack.svg',
    'fitnesstrainer-peloton-tread.svg': 'fitnesstrainer-peloton-tread.svg',
    'fitnesstrainer-proform-elliptical.svg':
      'fitnesstrainer-proform-elliptical.svg',
    'fitnesstrainer-proform-hiittrainer.svg':
      'fitnesstrainer-proform-hiittrainer.svg',
    'fitnesstrainer-proform-trainer.svg': 'fitnesstrainer-proform-trainer.svg',
    'fitnesstrainer-proform-treadmill.svg':
      'fitnesstrainer-proform-treadmill.svg',
    'fitnesstrainer-proform.svg': 'fitnesstrainer-proform.svg',
    'fitnesstrainer-tonal-trainer.svg': 'fitnesstrainer-tonal-trainer.svg',
    'fitnesstrainer-walkingpad-foldingtreadmill.svg':
      'fitnesstrainer-walkingpad-foldingtreadmill.svg',
    'fitnesstrainer.svg': 'fitnesstrainer.svg',
    'fridge-bosch-freezer.svg': 'fridge-bosch-freezer.svg',
    'fridge-bosch-refrigerator.svg': 'fridge-bosch-refrigerator.svg',
    'fridge-ge-refrigerator.svg': 'fridge-ge-refrigerator.svg',
    'fridge-lg-refrigerator.svg': 'fridge-lg-refrigerator.svg',
    'fridge-samsung-familyhub.svg': 'fridge-samsung-familyhub.svg',
    'fridge-siemens.svg': 'fridge-siemens.svg',
    'fridge-subzero-refrigerator.svg': 'fridge-subzero-refrigerator.svg',
    'fridge-thermador.svg': 'fridge-thermador.svg',
    'fridge-vzug-refrigerator.svg': 'fridge-vzug-refrigerator.svg',
    'fridge-whirlpool-refrigerator.svg': 'fridge-whirlpool-refrigerator.svg',
    'fridge.svg': 'fridge.svg',
    'gameconsole-atari-gameconsole.svg': 'gameconsole-atari-gameconsole.svg',
    'gameconsole-atari-vcs.svg': 'gameconsole-atari-vcs.svg',
    'gameconsole-atgames-ha2810.svg': 'gameconsole-atgames-ha2810.svg',
    'gameconsole-goldentee-live.svg': 'gameconsole-goldentee-live.svg',
    'gameconsole-google-stadia.svg': 'gameconsole-google-stadia.svg',
    'gameconsole-leapfrog-explorer.svg': 'gameconsole-leapfrog-explorer.svg',
    'gameconsole-microsoft.svg': 'gameconsole-microsoft.svg',
    'gameconsole-nintendo-3ds.svg': 'gameconsole-nintendo-3ds.svg',
    'gameconsole-nintendo-ds.svg': 'gameconsole-nintendo-ds.svg',
    'gameconsole-nintendo-wii.svg': 'gameconsole-nintendo-wii.svg',
    'gameconsole-odroid-goadvance.svg': 'gameconsole-odroid-goadvance.svg',
    'gameconsole-playbox-4one.svg': 'gameconsole-playbox-4one.svg',
    'gameconsole-raspberrypi-retropie.svg':
      'gameconsole-raspberrypi-retropie.svg',
    'gameconsole-sony-playstation.svg': 'gameconsole-sony-playstation.svg',
    'gameconsole.svg': 'gameconsole.svg',
    'garageopener-chamberlain-myq.svg': 'garageopener-chamberlain-myq.svg',
    'garageopener-chamberlain.svg': 'garageopener-chamberlain.svg',
    'garageopener-gogogate-garageopener.svg':
      'garageopener-gogogate-garageopener.svg',
    'garageopener-insignia-wifigaragedoorcontroller.svg':
      'garageopener-insignia-wifigaragedoorcontroller.svg',
    'garageopener-ismartgate.svg': 'garageopener-ismartgate.svg',
    'garageopener-meross-smartgarageopener.svg':
      'garageopener-meross-smartgarageopener.svg',
    'garageopener-momentum-niro.svg': 'garageopener-momentum-niro.svg',
    'garageopener-nexx-garage.svg': 'garageopener-nexx-garage.svg',
    'garageopener-remootio-garageopener.svg':
      'garageopener-remootio-garageopener.svg',
    'garageopener-skylink-garagedooropener.svg':
      'garageopener-skylink-garagedooropener.svg',
    'garageopener.svg': 'garageopener.svg',
    'homesecurity-abode.svg': 'homesecurity-abode.svg',
    'homesecurity-adt-securitypanel.svg': 'homesecurity-adt-securitypanel.svg',
    'homesecurity-alula-batconnect.svg': 'homesecurity-alula-batconnect.svg',
    'homesecurity-amcrest-dvr.svg': 'homesecurity-amcrest-dvr.svg',
    'homesecurity-amcrest-nvr.svg': 'homesecurity-amcrest-nvr.svg',
    'homesecurity-bosch-divar.svg': 'homesecurity-bosch-divar.svg',
    'homesecurity-brinks-homesecurity.svg':
      'homesecurity-brinks-homesecurity.svg',
    'homesecurity-defender-guard.svg': 'homesecurity-defender-guard.svg',
    'homesecurity-dsc-iotega.svg': 'homesecurity-dsc-iotega.svg',
    'homesecurity-eyezon-envizalink.svg': 'homesecurity-eyezon-envizalink.svg',
    'homesecurity-home8-homesecurity.svg':
      'homesecurity-home8-homesecurity.svg',
    'homesecurity-honeywell-alarmpanel.svg':
      'homesecurity-honeywell-alarmpanel.svg',
    'homesecurity-honeywell-tuxedotouchalarmpanel.svg':
      'homesecurity-honeywell-tuxedotouchalarmpanel.svg',
    'homesecurity-honeywell.svg': 'homesecurity-honeywell.svg',
    'homesecurity-honeywelllyric.svg': 'homesecurity-honeywelllyric.svg',
    'homesecurity-hub6-safe.svg': 'homesecurity-hub6-safe.svg',
    'homesecurity-ingrid-homesecurity.svg':
      'homesecurity-ingrid-homesecurity.svg',
    'homesecurity-interlogix-zerowire.svg':
      'homesecurity-interlogix-zerowire.svg',
    'homesecurity-ismart-smartsecurity.svg':
      'homesecurity-ismart-smartsecurity.svg',
    'homesecurity-konnected-nodemcuwifimodule.svg':
      'homesecurity-konnected-nodemcuwifimodule.svg',
    'homesecurity-konnectedsecurity.svg': 'homesecurity-konnectedsecurity.svg',
    'homesecurity-lorex-4kdvr.svg': 'homesecurity-lorex-4kdvr.svg',
    'homesecurity-lorex-4knvr.svg': 'homesecurity-lorex-4knvr.svg',
    'homesecurity-lorex-dv916.svg': 'homesecurity-lorex-dv916.svg',
    'homesecurity-lorex-dvr.svg': 'homesecurity-lorex-dvr.svg',
    'homesecurity-lorex-ipcamera.svg': 'homesecurity-lorex-ipcamera.svg',
    'homesecurity-lorex-lvh5100series.svg':
      'homesecurity-lorex-lvh5100series.svg',
    'homesecurity-lorex-nvr.svg': 'homesecurity-lorex-nvr.svg',
    'homesecurity-minut-point.svg': 'homesecurity-minut-point.svg',
    'homesecurity-nestguard.svg': 'homesecurity-nestguard.svg',
    'homesecurity-netatmo-welcome.svg': 'homesecurity-netatmo-welcome.svg',
    'homesecurity-qlosys-iqpanel.svg': 'homesecurity-qlosys-iqpanel.svg',
    'homesecurity-qlosys-iqpanel2.svg': 'homesecurity-qlosys-iqpanel2.svg',
    'homesecurity-ringalarmhomesecuritysystem.svg':
      'homesecurity-ringalarmhomesecuritysystem.svg',
    'homesecurity-somfyprotect.svg': 'homesecurity-somfyprotect.svg',
    'homesecurity-technicolor-tablet.svg':
      'homesecurity-technicolor-tablet.svg',
    'homesecurity-texe-homesecurity.svg': 'homesecurity-texe-homesecurity.svg',
    'homesecurity.svg': 'homesecurity.svg',
    'hub-adt-securityhub.svg': 'hub-adt-securityhub.svg',
    'hub-amazonring-bridge.svg': 'hub-amazonring-bridge.svg',
    'hub-aqara.svg': 'hub-aqara.svg',
    'hub-aquascape-smartcontrolhub.svg': 'hub-aquascape-smartcontrolhub.svg',
    'hub-arlo-basestation.svg': 'hub-arlo-basestation.svg',
    'hub-arlo-bridge.svg': 'hub-arlo-bridge.svg',
    'hub-august-connect.svg': 'hub-august-connect.svg',
    'hub-belkin-wemo.svg': 'hub-belkin-wemo.svg',
    'hub-blink-syncmodule.svg': 'hub-blink-syncmodule.svg',
    'hub-bond-bridge.svg': 'hub-bond-bridge.svg',
    'hub-brilliant-smarthomecontrolpanel.svg':
      'hub-brilliant-smarthomecontrolpanel.svg',
    'hub-broadlink-rm.svg': 'hub-broadlink-rm.svg',
    'hub-broadlink-rm2.svg': 'hub-broadlink-rm2.svg',
    'hub-broadlink-rm4mini.svg': 'hub-broadlink-rm4mini.svg',
    'hub-broadlink-rm4pro.svg': 'hub-broadlink-rm4pro.svg',
    'hub-broadlink-rmhome+.svg': 'hub-broadlink-rmhome+.svg',
    'hub-broadlink-rmmini3.svg': 'hub-broadlink-rmmini3.svg',
    'hub-broadlink-rmpro+.svg': 'hub-broadlink-rmpro+.svg',
    'hub-broadlink-rmpro.svg': 'hub-broadlink-rmpro.svg',
    'hub-bt-minihub.svg': 'hub-bt-minihub.svg',
    'hub-camect-home.svg': 'hub-camect-home.svg',
    'hub-cbyge-creachsmartbridge.svg': 'hub-cbyge-creachsmartbridge.svg',
    'hub-control4-ca1.svg': 'hub-control4-ca1.svg',
    'hub-control4-ea1.svg': 'hub-control4-ea1.svg',
    'hub-control4-ea3.svg': 'hub-control4-ea3.svg',
    'hub-control4-ea5.svg': 'hub-control4-ea5.svg',
    'hub-control4-hc800.svg': 'hub-control4-hc800.svg',
    'hub-control4-homecontroller.svg': 'hub-control4-homecontroller.svg',
    'hub-control4-ioextender.svg': 'hub-control4-ioextender.svg',
    'hub-develco-gateway.svg': 'hub-develco-gateway.svg',
    'hub-ezviz-w2d.svg': 'hub-ezviz-w2d.svg',
    'hub-heatmiser-neohub.svg': 'hub-heatmiser-neohub.svg',
    'hub-hive.svg': 'hub-hive.svg',
    'hub-homey.svg': 'hub-homey.svg',
    'hub-hoobs-hub.svg': 'hub-hoobs-hub.svg',
    'hub-hubitat-elevation.svg': 'hub-hubitat-elevation.svg',
    'hub-hunterdouglas-powerviewhub.svg': 'hub-hunterdouglas-powerviewhub.svg',
    'hub-ikea-tradfrigateway.svg': 'hub-ikea-tradfrigateway.svg',
    'hub-kangaroo-siren.svg': 'hub-kangaroo-siren.svg',
    'hub-leedarson-minihub.svg': 'hub-leedarson-minihub.svg',
    'hub-lightwaverf-linkplus.svg': 'hub-lightwaverf-linkplus.svg',
    'hub-lockly-securelink.svg': 'hub-lockly-securelink.svg',
    'hub-logitech-harmony.svg': 'hub-logitech-harmony.svg',
    'hub-logitech-pop.svg': 'hub-logitech-pop.svg',
    'hub-logitech-squeezeboxcontroller.svg':
      'hub-logitech-squeezeboxcontroller.svg',
    'hub-magichue.svg': 'hub-magichue.svg',
    'hub-meross-smarthub.svg': 'hub-meross-smarthub.svg',
    'hub-napco-ibridge.svg': 'hub-napco-ibridge.svg',
    'hub-nature-remo.svg': 'hub-nature-remo.svg',
    'hub-netatmo-relay.svg': 'hub-netatmo-relay.svg',
    'hub-notion-bridge.svg': 'hub-notion-bridge.svg',
    'hub-nuki-bridge.svg': 'hub-nuki-bridge.svg',
    'hub-overkiz-gateway.svg': 'hub-overkiz-gateway.svg',
    'hub-panasonic.svg': 'hub-panasonic.svg',
    'hub-philips-hue.svg': 'hub-philips-hue.svg',
    'hub-philips.svg': 'hub-philips.svg',
    'hub-qivicon-homebase.svg': 'hub-qivicon-homebase.svg',
    'hub-samsung-smartthings-hub-1.svg': 'hub-samsung-smartthings-hub-1.svg',
    'hub-samsung-smartthings-hub.svg': 'hub-samsung-smartthings-hub.svg',
    'hub-samsung-smartthingsconnecthome.svg':
      'hub-samsung-smartthingsconnecthome.svg',
    'hub-samsung-smartthingshub.svg': 'hub-samsung-smartthingshub.svg',
    'hub-sangoma-vega.svg': 'hub-sangoma-vega.svg',
    'hub-sonos-bridge.svg': 'hub-sonos-bridge.svg',
    'hub-tado-bridge.svg': 'hub-tado-bridge.svg',
    'hub-thermoworks-smokegateway.svg': 'hub-thermoworks-smokegateway.svg',
    'hub-tplink-omadacloudcontroller.svg':
      'hub-tplink-omadacloudcontroller.svg',
    'hub-urc-mxhomepro.svg': 'hub-urc-mxhomepro.svg',
    'hub-velux-active.svg': 'hub-velux-active.svg',
    'hub-vivintsmarthome.svg': 'hub-vivintsmarthome.svg',
    'hub-wink-relay.svg': 'hub-wink-relay.svg',
    'hub-winkhub.svg': 'hub-winkhub.svg',
    'hub-xiaomi-mijiagateway.svg': 'hub-xiaomi-mijiagateway.svg',
    'humidifier-dyson.svg': 'humidifier-dyson.svg',
    'humidifier-hisense-dehumidifier.svg':
      'humidifier-hisense-dehumidifier.svg',
    'humidifier-levoit-humidifier.svg': 'humidifier-levoit-humidifier.svg',
    'humidifier-meross-smarthumidifier.svg':
      'humidifier-meross-smarthumidifier.svg',
    'humidifier-vocolinc-mistflow.svg': 'humidifier-vocolinc-mistflow.svg',
    'humidifier.svg': 'humidifier.svg',
    'iotplatform.svg': 'iotplatform.svg',
    'laptop-acer-aspire.svg': 'laptop-acer-aspire.svg',
    'laptop-acer-chromebook.svg': 'laptop-acer-chromebook.svg',
    'laptop-acer-computer.svg': 'laptop-acer-computer.svg',
    'laptop-acer-predator.svg': 'laptop-acer-predator.svg',
    'laptop-acer-swift.svg': 'laptop-acer-swift.svg',
    'laptop-apple-macbook.svg': 'laptop-apple-macbook.svg',
    'laptop-asus-chromebook.svg': 'laptop-asus-chromebook.svg',
    'laptop-asus-rog.svg': 'laptop-asus-rog.svg',
    'laptop-asus-windows.svg': 'laptop-asus-windows.svg',
    'laptop-asus-x75a.svg': 'laptop-asus-x75a.svg',
    'laptop-asus-zenbook.svg': 'laptop-asus-zenbook.svg',
    'laptop-chromeos.svg': 'laptop-chromeos.svg',
    'laptop-computer.svg': 'laptop-computer.svg',
    'laptop-dell-alienware.svg': 'laptop-dell-alienware.svg',
    'laptop-dell-chromebook.svg': 'laptop-dell-chromebook.svg',
    'laptop-dell-inspiron.svg': 'laptop-dell-inspiron.svg',
    'laptop-dell-latitude.svg': 'laptop-dell-latitude.svg',
    'laptop-dell-windows.svg': 'laptop-dell-windows.svg',
    'laptop-dell-xps.svg': 'laptop-dell-xps.svg',
    'laptop-dell.svg': 'laptop-dell.svg',
    'laptop-ematic-laptop.svg': 'laptop-ematic-laptop.svg',
    'laptop-evoo-laptop.svg': 'laptop-evoo-laptop.svg',
    'laptop-gateway-ne71b.svg': 'laptop-gateway-ne71b.svg',
    'laptop-google chromebook.svg': 'laptop-google chromebook.svg',
    'laptop-google-chromebook.svg': 'laptop-google-chromebook.svg',
    'laptop-google-pixelbook.svg': 'laptop-google-pixelbook.svg',
    'laptop-hp-chromebook.svg': 'laptop-hp-chromebook.svg',
    'laptop-hp-computer.svg': 'laptop-hp-computer.svg',
    'laptop-hp-envy.svg': 'laptop-hp-envy.svg',
    'laptop-hp-notebook.svg': 'laptop-hp-notebook.svg',
    'laptop-hp.svg': 'laptop-hp.svg',
    'laptop.svg': 'laptop.svg',
    'lawnmower.svg': 'lawnmower.svg',
    'lightbulb-ai-hydra.svg': 'lightbulb-ai-hydra.svg',
    'lightbulb-ai-primehd.svg': 'lightbulb-ai-primehd.svg',
    'lightbulb-cololight-smartledlightpanel.svg':
      'lightbulb-cololight-smartledlightpanel.svg',
    'lightbulb-corsair-icue.svg': 'lightbulb-corsair-icue.svg',
    'lightbulb-dyson-lightcycle.svg': 'lightbulb-dyson-lightcycle.svg',
    'lightbulb-elgato-keylight.svg': 'lightbulb-elgato-keylight.svg',
    'lightbulb-etekcity-lightbulb.svg': 'lightbulb-etekcity-lightbulb.svg',
    'lightbulb-eve-lightstrip.svg': 'lightbulb-eve-lightstrip.svg',
    'lightbulb-everlights-lightbulb.svg': 'lightbulb-everlights-lightbulb.svg',
    'lightbulb-feit-lightbulb.svg': 'lightbulb-feit-lightbulb.svg',
    'lightbulb-friendship-lamp.svg': 'lightbulb-friendship-lamp.svg',
    'lightbulb-ge-lightbulb.svg': 'lightbulb-ge-lightbulb.svg',
    'lightbulb-greenwavereality-lightbulb.svg':
      'lightbulb-greenwavereality-lightbulb.svg',
    'lightbulb-juno-ai.svg': 'lightbulb-juno-ai.svg',
    'lightbulb-koogeek-lightbulb.svg': 'lightbulb-koogeek-lightbulb.svg',
    'lightbulb-koogeek-lightstrip.svg': 'lightbulb-koogeek-lightstrip.svg',
    'lightbulb-koogeek.svg': 'lightbulb-koogeek.svg',
    'lightbulb-lifx-1.svg': 'lightbulb-lifx-1.svg',
    'lightbulb-lifx-colorlightbulb.svg': 'lightbulb-lifx-colorlightbulb.svg',
    'lightbulb-lifx-downlight.svg': 'lightbulb-lifx-downlight.svg',
    'lightbulb-lifx-tile.svg': 'lightbulb-lifx-tile.svg',
    'lightbulb-lifx.svg': 'lightbulb-lifx.svg',
    'lightbulb-lohas-smartledbulb.svg': 'lightbulb-lohas-smartledbulb.svg',
    'lightbulb-magichue-light.svg': 'lightbulb-magichue-light.svg',
    'lightbulb-merkury-smartbulb.svg': 'lightbulb-merkury-smartbulb.svg',
    'lightbulb-meross-smartambientlight.svg':
      'lightbulb-meross-smartambientlight.svg',
    'lightbulb-meross-smartlightstrip.svg':
      'lightbulb-meross-smartlightstrip.svg',
    'lightbulb-meross-smartwifibulb.svg': 'lightbulb-meross-smartwifibulb.svg',
    'lightbulb-mirabellagenio-dimmable.svg':
      'lightbulb-mirabellagenio-dimmable.svg',
    'lightbulb-mystrom-wifibulb.svg': 'lightbulb-mystrom-wifibulb.svg',
    'lightbulb-mystrom-wifiledstrip.svg': 'lightbulb-mystrom-wifiledstrip.svg',
    'lightbulb-nanoleaf-canvas.svg': 'lightbulb-nanoleaf-canvas.svg',
    'lightbulb-nanoleaf-lightpanels.svg': 'lightbulb-nanoleaf-lightpanels.svg',
    'lightbulb-philips-hue.svg': 'lightbulb-philips-hue.svg',
    'lightbulb-philips-smartsleep.svg': 'lightbulb-philips-smartsleep.svg',
    'lightbulb-redsea-reefled.svg': 'lightbulb-redsea-reefled.svg',
    'lightbulb-samsung-smartthingsdesklamp.svg':
      'lightbulb-samsung-smartthingsdesklamp.svg',
    'lightbulb-sengled-smartwifiledbulb.svg':
      'lightbulb-sengled-smartwifiledbulb.svg',
    'lightbulb-sengled-wifiledlightstrip.svg':
      'lightbulb-sengled-wifiledlightstrip.svg',
    'lightbulb-shelly-bulb.svg': 'lightbulb-shelly-bulb.svg',
    'lightbulb-shelly-duo.svg': 'lightbulb-shelly-duo.svg',
    'lightbulb-shelly-vintage.svg': 'lightbulb-shelly-vintage.svg',
    'lightbulb-sony-multifunctionallight.svg':
      'lightbulb-sony-multifunctionallight.svg',
    'lightbulb-teckin-smartbulb.svg': 'lightbulb-teckin-smartbulb.svg',
    'lightbulb-tplink-kasawifiledbulb.svg':
      'lightbulb-tplink-kasawifiledbulb.svg',
    'lightbulb-tplink-tapo.svg': 'lightbulb-tplink-tapo.svg',
    'lightbulb-tplinkcasalightbulb.svg': 'lightbulb-tplinkcasalightbulb.svg',
    'lightbulb-tuya-smartledbulb.svg': 'lightbulb-tuya-smartledbulb.svg',
    'lightbulb-twinkly-ledlightstring.svg':
      'lightbulb-twinkly-ledlightstring.svg',
    'lightbulb-vocolinc-lightstrip.svg': 'lightbulb-vocolinc-lightstrip.svg',
    'lightbulb-vocolinc.svg': 'lightbulb-vocolinc.svg',
    'lightbulb-wiz-light.svg': 'lightbulb-wiz-light.svg',
    'lightbulb-wyze-bulb.svg': 'lightbulb-wyze-bulb.svg',
    'lightbulb-xiaomi-yeelightledbulb.svg':
      'lightbulb-xiaomi-yeelightledbulb.svg',
    'lightbulb-zengge-ledlight.svg': 'lightbulb-zengge-ledlight.svg',
    'lightbulb.svg': 'lightbulb.svg',
    'lightswitch-belkin-wemo.svg': 'lightswitch-belkin-wemo.svg',
    'lightswitch-control4-c4din8dime-1.svg':
      'lightswitch-control4-c4din8dime-1.svg',
    'lightswitch-control4-c4din8dime.svg':
      'lightswitch-control4-c4din8dime.svg',
    'lightswitch-crestron-switch.svg': 'lightswitch-crestron-switch.svg',
    'lightswitch-deako-smartlighting.svg':
      'lightswitch-deako-smartlighting.svg',
    'lightswitch-dingz-lightswitch.svg': 'lightswitch-dingz-lightswitch.svg',
    'lightswitch-ecobee-switch+.svg': 'lightswitch-ecobee-switch+.svg',
    'lightswitch-etekcity-dimmer.svg': 'lightswitch-etekcity-dimmer.svg',
    'lightswitch-feller-zeptrion.svg': 'lightswitch-feller-zeptrion.svg',
    'lightswitch-ge-dimmer.svg': 'lightswitch-ge-dimmer.svg',
    'lightswitch-ge-switch.svg': 'lightswitch-ge-switch.svg',
    'lightswitch-idevices-dimmerswitch.svg':
      'lightswitch-idevices-dimmerswitch.svg',
    'lightswitch-idevices-outdoorswitch.svg':
      'lightswitch-idevices-outdoorswitch.svg',
    'lightswitch-idevices-wallswitch.svg':
      'lightswitch-idevices-wallswitch.svg',
    'lightswitch-iotty-smartswitch.svg': 'lightswitch-iotty-smartswitch.svg',
    'lightswitch-koogeek-dimmer.svg': 'lightswitch-koogeek-dimmer.svg',
    'lightswitch-leviton-dimmer.svg': 'lightswitch-leviton-dimmer.svg',
    'lightswitch-leviton-switch.svg': 'lightswitch-leviton-switch.svg',
    'lightswitch-lutron-dimmer.svg': 'lightswitch-lutron-dimmer.svg',
    'lightswitch-lutrondimmerswitch.svg': 'lightswitch-lutrondimmerswitch.svg',
    'lightswitch-martinjerry-smartdimmerswitch.svg':
      'lightswitch-martinjerry-smartdimmerswitch.svg',
    'lightswitch-martinjerry-smartswitch.svg':
      'lightswitch-martinjerry-smartswitch.svg',
    'lightswitch-meross-dimmerswitch.svg':
      'lightswitch-meross-dimmerswitch.svg',
    'lightswitch-meross-smartwifiswitch.svg':
      'lightswitch-meross-smartwifiswitch.svg',
    'lightswitch-noonroomdirector.svg': 'lightswitch-noonroomdirector.svg',
    'lightswitch-orro-switch.svg': 'lightswitch-orro-switch.svg',
    'lightswitch-plum-lightpaddimmer.svg':
      'lightswitch-plum-lightpaddimmer.svg',
    'lightswitch-shelly-1.svg': 'lightswitch-shelly-1.svg',
    'lightswitch-shelly-1l.svg': 'lightswitch-shelly-1l.svg',
    'lightswitch-shelly-1pmrelayswitch-1.svg':
      'lightswitch-shelly-1pmrelayswitch-1.svg',
    'lightswitch-shelly-1pmrelayswitch.svg':
      'lightswitch-shelly-1pmrelayswitch.svg',
    'lightswitch-shelly-button.svg': 'lightswitch-shelly-button.svg',
    'lightswitch-shelly-dimmer.svg': 'lightswitch-shelly-dimmer.svg',
    'lightswitch-sonoffswitch.svg': 'lightswitch-sonoffswitch.svg',
    'lightswitch-tplink-kasawifilightswitch.svg':
      'lightswitch-tplink-kasawifilightswitch.svg',
    'lightswitch.svg': 'lightswitch.svg',
    'litterbox.svg': 'litterbox.svg',
    'mediaplayer-akai-mpc.svg': 'mediaplayer-akai-mpc.svg',
    'mediaplayer-amazon-echolink.svg': 'mediaplayer-amazon-echolink.svg',
    'mediaplayer-apple-appletv.svg': 'mediaplayer-apple-appletv.svg',
    'mediaplayer-apple-ipod.svg': 'mediaplayer-apple-ipod.svg',
    'mediaplayer-astellkern-ak70.svg': 'mediaplayer-astellkern-ak70.svg',
    'mediaplayer-bluesound-nodemediaplayer.svg':
      'mediaplayer-bluesound-nodemediaplayer.svg',
    'mediaplayer-bluesound-powernodemediaplayer.svg':
      'mediaplayer-bluesound-powernodemediaplayer.svg',
    'mediaplayer-bluesound-pulse.svg': 'mediaplayer-bluesound-pulse.svg',
    'mediaplayer-bluesound-vaultmediaplayer.svg':
      'mediaplayer-bluesound-vaultmediaplayer.svg',
    'mediaplayer-cambridgeaudio-cxnv2.svg':
      'mediaplayer-cambridgeaudio-cxnv2.svg',
    'mediaplayer-comoaudio.svg': 'mediaplayer-comoaudio.svg',
    'mediaplayer-control4-4zoneamplifier.svg':
      'mediaplayer-control4-4zoneamplifier.svg',
    'mediaplayer-control4-triad.svg': 'mediaplayer-control4-triad.svg',
    'mediaplayer-denon-ceol.svg': 'mediaplayer-denon-ceol.svg',
    'mediaplayer-denon-dj.svg': 'mediaplayer-denon-dj.svg',
    'mediaplayer-denon-dnp800ne.svg': 'mediaplayer-denon-dnp800ne.svg',
    'mediaplayer-denon-heosamp.svg': 'mediaplayer-denon-heosamp.svg',
    'mediaplayer-denon-heosdrive.svg': 'mediaplayer-denon-heosdrive.svg',
    'mediaplayer-denon-heoslink.svg': 'mediaplayer-denon-heoslink.svg',
    'mediaplayer-denon-heossuperlink.svg':
      'mediaplayer-denon-heossuperlink.svg',
    'mediaplayer-denondj-prime.svg': 'mediaplayer-denondj-prime.svg',
    'mediaplayer-dlink-cameravideorecorder.svg':
      'mediaplayer-dlink-cameravideorecorder.svg',
    'mediaplayer-dstv-explora.svg': 'mediaplayer-dstv-explora.svg',
    'mediaplayer-gracedigital-musicplayer.svg':
      'mediaplayer-gracedigital-musicplayer.svg',
    'mediaplayer-h96-max.svg': 'mediaplayer-h96-max.svg',
    'mediaplayer-hitachi-bluraydiscrecorder.svg':
      'mediaplayer-hitachi-bluraydiscrecorder.svg',
    'mediaplayer-idis-dvr.svg': 'mediaplayer-idis-dvr.svg',
    'mediaplayer-jooki-musicplayer.svg': 'mediaplayer-jooki-musicplayer.svg',
    'mediaplayer-kingbox-tvbox.svg': 'mediaplayer-kingbox-tvbox.svg',
    'mediaplayer-kodi.svg': 'mediaplayer-kodi.svg',
    'mediaplayer-logitech-squeezebox.svg':
      'mediaplayer-logitech-squeezebox.svg',
    'mediaplayer-magictv-mtv3300d.svg': 'mediaplayer-magictv-mtv3300d.svg',
    'mediaplayer-magictv-mtv8000d.svg': 'mediaplayer-magictv-mtv8000d.svg',
    'mediaplayer-marantz-mcr612.svg': 'mediaplayer-marantz-mcr612.svg',
    'mediaplayer-microjukebox.svg': 'mediaplayer-microjukebox.svg',
    'mediaplayer-mighty-musicplayer.svg': 'mediaplayer-mighty-musicplayer.svg',
    'mediaplayer-nad-t.svg': 'mediaplayer-nad-t.svg',
    'mediaplayer-nightowl-dvr.svg': 'mediaplayer-nightowl-dvr.svg',
    'mediaplayer-nuvo-homeaudiosystem.svg':
      'mediaplayer-nuvo-homeaudiosystem.svg',
    'mediaplayer-panasonic-blurayplayer.svg':
      'mediaplayer-panasonic-blurayplayer.svg',
    'mediaplayer-panasonic-blurayrecorder.svg':
      'mediaplayer-panasonic-blurayrecorder.svg',
    'mediaplayer-panasonic-diga.svg': 'mediaplayer-panasonic-diga.svg',
    'mediaplayer-panasonic-hddrecorder.svg':
      'mediaplayer-panasonic-hddrecorder.svg',
    'mediaplayer-philips-bdp7501.svg': 'mediaplayer-philips-bdp7501.svg',
    'mediaplayer-philips-tapr802.svg': 'mediaplayer-philips-tapr802.svg',
    'mediaplayer-pure-evoke.svg': 'mediaplayer-pure-evoke.svg',
    'mediaplayer-pure-sensia.svg': 'mediaplayer-pure-sensia.svg',
    'mediaplayer-russound-mbxamp.svg': 'mediaplayer-russound-mbxamp.svg',
    'mediaplayer-russound-xsource.svg': 'mediaplayer-russound-xsource.svg',
    'mediaplayer-samsung-blurayplayer.svg':
      'mediaplayer-samsung-blurayplayer.svg',
    'mediaplayer-scishion-v88.svg': 'mediaplayer-scishion-v88.svg',
    'mediaplayer-simaudio-mind.svg': 'mediaplayer-simaudio-mind.svg',
    'mediaplayer-siriusxm-soundstation.svg':
      'mediaplayer-siriusxm-soundstation.svg',
    'mediaplayer-sony-blurayplayer.svg': 'mediaplayer-sony-blurayplayer.svg',
    'mediaplayer-sony-blurayrecoder.svg': 'mediaplayer-sony-blurayrecoder.svg',
    'mediaplayer-sony-player.svg': 'mediaplayer-sony-player.svg',
    'mediaplayer-sony-walkman.svg': 'mediaplayer-sony-walkman.svg',
    'mediaplayer-teufel-radio3sixty.svg': 'mediaplayer-teufel-radio3sixty.svg',
    'mediaplayer-toshiba-regza.svg': 'mediaplayer-toshiba-regza.svg',
    'mediaplayer-volumio-musicplayer.svg':
      'mediaplayer-volumio-musicplayer.svg',
    'mediaplayer-wd-tvlivehubmediaplayer.svg':
      'mediaplayer-wd-tvlivehubmediaplayer.svg',
    'mediaplayer-wd-tvlivemediaplayer.svg':
      'mediaplayer-wd-tvlivemediaplayer.svg',
    'mediaplayer-wd-tvliveplusmediaplayer.svg':
      'mediaplayer-wd-tvliveplusmediaplayer.svg',
    'mediaplayer-wd-tvplaymediaplayer.svg':
      'mediaplayer-wd-tvplaymediaplayer.svg',
    'mediaplayer-wdtvmediaplayer.svg': 'mediaplayer-wdtvmediaplayer.svg',
    'mediaplayer-yamaha-avreceiver.svg': 'mediaplayer-yamaha-avreceiver.svg',
    'mediaplayer-yandex-station.svg': 'mediaplayer-yandex-station.svg',
    'mediaplayer.svg': 'mediaplayer.svg',
    'meshrouter-airties-air.svg': 'meshrouter-airties-air.svg',
    'meshrouter-bt-wholehome.svg': 'meshrouter-bt-wholehome.svg',
    'meshrouter-cambiumnetworks-router.svg':
      'meshrouter-cambiumnetworks-router.svg',
    'meshrouter-commscope.svg': 'meshrouter-commscope.svg',
    'meshrouter-dlink-covr.svg': 'meshrouter-dlink-covr.svg',
    'meshrouter-eero.svg': 'meshrouter-eero.svg',
    'meshrouter-elecom-meshrouter.svg': 'meshrouter-elecom-meshrouter.svg',
    'meshrouter-googlenest-wifi.svg': 'meshrouter-googlenest-wifi.svg',
    'meshrouter-helium-hotspot.svg': 'meshrouter-helium-hotspot.svg',
    'meshrouter-linksys-velop.svg': 'meshrouter-linksys-velop.svg',
    'meshrouter-netgear-orbipro.svg': 'meshrouter-netgear-orbipro.svg',
    'meshrouter-plume-pod.svg': 'meshrouter-plume-pod.svg',
    'meshrouter-plume-powerpod.svg': 'meshrouter-plume-powerpod.svg',
    'meshrouter-plume-superpod.svg': 'meshrouter-plume-superpod.svg',
    'meshrouter-plume.svg': 'meshrouter-plume.svg',
    'meshrouter-sky-qbooster.svg': 'meshrouter-sky-qbooster.svg',
    'meshrouter-tenda-nova.svg': 'meshrouter-tenda-nova.svg',
    'meshrouter.svg': 'meshrouter.svg',
    'microwave-siemens-microwave.svg': 'microwave-siemens-microwave.svg',
    'microwave-whirlpool.svg': 'microwave-whirlpool.svg',
    'microwave.svg': 'microwave.svg',
    'nasstorage-antsle-privatecloudserver-1.svg':
      'nasstorage-antsle-privatecloudserver-1.svg',
    'nasstorage-antsle-privatecloudserver.svg':
      'nasstorage-antsle-privatecloudserver.svg',
    'nasstorage-antsle.svg': 'nasstorage-antsle.svg',
    'nasstorage-asustor-as1002t.svg': 'nasstorage-asustor-as1002t.svg',
    'nasstorage-asustor-nas.svg': 'nasstorage-asustor-nas.svg',
    'nasstorage-buffalo-linkstation.svg': 'nasstorage-buffalo-linkstation.svg',
    'nasstorage-buffalo.svg': 'nasstorage-buffalo.svg',
    'nasstorage-cubbit-storagehub.svg': 'nasstorage-cubbit-storagehub.svg',
    'nasstorage-cubbit.svg': 'nasstorage-cubbit.svg',
    'nasstorage-dlink-sharecenter.svg': 'nasstorage-dlink-sharecenter.svg',
    'nasstorage-dlink.svg': 'nasstorage-dlink.svg',
    'nasstorage-echelon-smartserver.svg': 'nasstorage-echelon-smartserver.svg',
    'nasstorage-innuous-zenmini.svg': 'nasstorage-innuous-zenmini.svg',
    'nasstorage-innuous.svg': 'nasstorage-innuous.svg',
    'nasstorage-iodata-landisk.svg': 'nasstorage-iodata-landisk.svg',
    'nasstorage-iodata-pokedoracloud.svg':
      'nasstorage-iodata-pokedoracloud.svg',
    'nasstorage-iodata.svg': 'nasstorage-iodata.svg',
    'nasstorage-lacie-2big.svg': 'nasstorage-lacie-2big.svg',
    'nasstorage-lacie-5big.svg': 'nasstorage-lacie-5big.svg',
    'nasstorage-lacie-cloudbox.svg': 'nasstorage-lacie-cloudbox.svg',
    'nasstorage-lacie-fuel.svg': 'nasstorage-lacie-fuel.svg',
    'nasstorage-lacie-networkstorage.svg':
      'nasstorage-lacie-networkstorage.svg',
    'nasstorage-lacie.svg': 'nasstorage-lacie.svg',
    'nasstorage-lenovo.svg': 'nasstorage-lenovo.svg',
    'nasstorage-lg-nas.svg': 'nasstorage-lg-nas.svg',
    'nasstorage-lg.svg': 'nasstorage-lg.svg',
    'nasstorage-monument-photostorage.svg':
      'nasstorage-monument-photostorage.svg',
    'nasstorage-monument.svg': 'nasstorage-monument.svg',
    'nasstorage-netgear-nas.svg': 'nasstorage-netgear-nas.svg',
    'nasstorage-netgear-readynas.svg': 'nasstorage-netgear-readynas.svg',
    'nasstorage-netgear-stora.svg': 'nasstorage-netgear-stora.svg',
    'nasstorage-netgear.svg': 'nasstorage-netgear.svg',
    'nasstorage-omoidebako.svg': 'nasstorage-omoidebako.svg',
    'nasstorage-qnap-nas.svg': 'nasstorage-qnap-nas.svg',
    'nasstorage-qnap.svg': 'nasstorage-qnap.svg',
    'nasstorage-sandisk-ixpand.svg': 'nasstorage-sandisk-ixpand.svg',
    'nasstorage-sandisk.svg': 'nasstorage-sandisk.svg',
    'nasstorage-seagate-goflexhome.svg': 'nasstorage-seagate-goflexhome.svg',
    'nasstorage-seagate-nas.svg': 'nasstorage-seagate-nas.svg',
    'nasstorage-seagate.svg': 'nasstorage-seagate.svg',
    'nasstorage-synology-diskstationnas.svg':
      'nasstorage-synology-diskstationnas.svg',
    'nasstorage-synology.svg': 'nasstorage-synology.svg',
    'nasstorage-truenas-storage.svg': 'nasstorage-truenas-storage.svg',
    'nasstorage-truenas.svg': 'nasstorage-truenas.svg',
    'nasstorage-ubiquiti-unifi.svg': 'nasstorage-ubiquiti-unifi.svg',
    'nasstorage-ubiquiti.svg': 'nasstorage-ubiquiti.svg',
    'nasstorage-vivint-smartdrive.svg': 'nasstorage-vivint-smartdrive.svg',
    'nasstorage-vivint.svg': 'nasstorage-vivint.svg',
    'nasstorage-vivotek-videoserver.svg': 'nasstorage-vivotek-videoserver.svg',
    'nasstorage-westerndigital-mybooklive.svg':
      'nasstorage-westerndigital-mybooklive.svg',
    'nasstorage-westerndigital-mycloud.svg':
      'nasstorage-westerndigital-mycloud.svg',
    'nasstorage-westerndigital-mypassport.svg':
      'nasstorage-westerndigital-mypassport.svg',
    'nasstorage-westerndigital.svg': 'nasstorage-westerndigital.svg',
    'nasstorage-zyxel-mediaserver.svg': 'nasstorage-zyxel-mediaserver.svg',
    'nasstorage-zyxel.svg': 'nasstorage-zyxel.svg',
    'nasstorage.svg': 'nasstorage.svg',
    'networkcontroller-gardena-gateway.svg':
      'networkcontroller-gardena-gateway.svg',
    'networkcontroller-generac-mobilelink.svg':
      'networkcontroller-generac-mobilelink.svg',
    'networkcontroller-meross-remotecontrol.svg':
      'networkcontroller-meross-remotecontrol.svg',
    'networkcontroller-ubiquiti-unifi.svg':
      'networkcontroller-ubiquiti-unifi.svg',
    'networkcontroller-vmware-velocloud.svg':
      'networkcontroller-vmware-velocloud.svg',
    'networkcontroller-xyo-bridgex.svg': 'networkcontroller-xyo-bridgex.svg',
    'networkcontroller-zinwell-ethernetadapter.svg':
      'networkcontroller-zinwell-ethernetadapter.svg',
    'networkcontroller.svg': 'networkcontroller.svg',
    'oven-anovaculinary-precisionoven.svg':
      'oven-anovaculinary-precisionoven.svg',
    'oven-bosch-oven.svg': 'oven-bosch-oven.svg',
    'oven-brava.svg': 'oven-brava.svg',
    'oven-cosori-oven.svg': 'oven-cosori-oven.svg',
    'oven-ge.svg': 'oven-ge.svg',
    'oven-june.svg': 'oven-june.svg',
    'oven-lg-smartoven.svg': 'oven-lg-smartoven.svg',
    'oven-neff-n90.svg': 'oven-neff-n90.svg',
    'oven-samsung.svg': 'oven-samsung.svg',
    'oven-seimens.svg': 'oven-seimens.svg',
    'oven-siemens-compactoven.svg': 'oven-siemens-compactoven.svg',
    'oven-thermador.svg': 'oven-thermador.svg',
    'oven-vzug.svg': 'oven-vzug.svg',
    'oven-whirlpool.svg': 'oven-whirlpool.svg',
    'oven-wolf-oven.svg': 'oven-wolf-oven.svg',
    'oven.svg': 'oven.svg',
    'petaccessory-fi-collar.svg': 'petaccessory-fi-collar.svg',
    'petaccessory-petkit-cozy.svg': 'petaccessory-petkit-cozy.svg',
    'petaccessory-petkit-petfeeder.svg': 'petaccessory-petkit-petfeeder.svg',
    'petaccessory-petoneer-catfountain.svg':
      'petaccessory-petoneer-catfountain.svg',
    'petaccessory.svg': 'petaccessory.svg',
    'pictureframe-aluratek-photoframe.svg':
      'pictureframe-aluratek-photoframe.svg',
    'pictureframe-aura-frame.svg': 'pictureframe-aura-frame.svg',
    'pictureframe-canvia-smartartframe.svg':
      'pictureframe-canvia-smartartframe.svg',
    'pictureframe-meural-canvas.svg': 'pictureframe-meural-canvas.svg',
    'pictureframe-nixplay-seed.svg': 'pictureframe-nixplay-seed.svg',
    'pictureframe-nixplay-smartphotoframe.svg':
      'pictureframe-nixplay-smartphotoframe.svg',
    'pictureframe-photospring-digitalframe.svg':
      'pictureframe-photospring-digitalframe.svg',
    'pictureframe-pixstar-digitalphotoframe.svg':
      'pictureframe-pixstar-digitalphotoframe.svg',
    'pictureframe-simplysmarthome-photoshare.svg':
      'pictureframe-simplysmarthome-photoshare.svg',
    'pictureframe-skylight-frame.svg': 'pictureframe-skylight-frame.svg',
    'pictureframe.svg': 'pictureframe.svg',
    'plug-amazon-smartplug-1.svg': 'plug-amazon-smartplug-1.svg',
    'plug-amazon-smartplug.svg': 'plug-amazon-smartplug.svg',
    'plug-apc-wifipowerstrip.svg': 'plug-apc-wifipowerstrip.svg',
    'plug-belkin-wemo.svg': 'plug-belkin-wemo.svg',
    'plug-belkin-wemosmartplug.svg': 'plug-belkin-wemosmartplug.svg',
    'plug-broadlink-smartplug.svg': 'plug-broadlink-smartplug.svg',
    'plug-buddee-smartplug.svg': 'plug-buddee-smartplug.svg',
    'plug-cbyge-smartplug.svg': 'plug-cbyge-smartplug.svg',
    'plug-connectsense-smartinwalloutlet-1.svg':
      'plug-connectsense-smartinwalloutlet-1.svg',
    'plug-connectsense-smartinwalloutlet.svg':
      'plug-connectsense-smartinwalloutlet.svg',
    'plug-connectsense-smartoutlet.svg': 'plug-connectsense-smartoutlet.svg',
    'plug-currant-wifismartoutlet.svg': 'plug-currant-wifismartoutlet.svg',
    'plug-ecoplugs-smartplug.svg': 'plug-ecoplugs-smartplug.svg',
    'plug-etekcity-ihomesmartplug.svg': 'plug-etekcity-ihomesmartplug.svg',
    'plug-etekcity-voltsonsmartplug.svg': 'plug-etekcity-voltsonsmartplug.svg',
    'plug-geeni-spot.svg': 'plug-geeni-spot.svg',
    'plug-idevices-socket.svg': 'plug-idevices-socket.svg',
    'plug-idevices-switch.svg': 'plug-idevices-switch.svg',
    'plug-idevices-walloutlet.svg': 'plug-idevices-walloutlet.svg',
    'plug-ihome-smartplug.svg': 'plug-ihome-smartplug.svg',
    'plug-iris-smartplug.svg': 'plug-iris-smartplug.svg',
    'plug-konke-miniussmartplug.svg': 'plug-konke-miniussmartplug.svg',
    'plug-koogeek-outlet.svg': 'plug-koogeek-outlet.svg',
    'plug-koogeek-socket.svg': 'plug-koogeek-socket.svg',
    'plug-koogeek.svg': 'plug-koogeek.svg',
    'plug-meross-smartsurgeprotector.svg':
      'plug-meross-smartsurgeprotector.svg',
    'plug-meross-smartwifiplug.svg': 'plug-meross-smartwifiplug.svg',
    'plug-meross-smartwifiwallswitch.svg':
      'plug-meross-smartwifiwallswitch.svg',
    'plug-mobvoi-smartplug.svg': 'plug-mobvoi-smartplug.svg',
    'plug-mystrom-wifiswitch.svg': 'plug-mystrom-wifiswitch.svg',
    'plug-mytouchsmart-smartplug.svg': 'plug-mytouchsmart-smartplug.svg',
    'plug-nest-connect.svg': 'plug-nest-connect.svg',
    'plug-nexx-smartplug.svg': 'plug-nexx-smartplug.svg',
    'plug-opso-hkou1.svg': 'plug-opso-hkou1.svg',
    'plug-pumpspy-smartoutlet.svg': 'plug-pumpspy-smartoutlet.svg',
    'plug-samsung-smartthingsoutlet.svg': 'plug-samsung-smartthingsoutlet.svg',
    'plug-samsung-smartthingssmartplug.svg':
      'plug-samsung-smartthingssmartplug.svg',
    'plug-satechi-dualoutlet.svg': 'plug-satechi-dualoutlet.svg',
    'plug-shelly.svg': 'plug-shelly.svg',
    'plug-smartlife-smartplug.svg': 'plug-smartlife-smartplug.svg',
    'plug-snapav-wattbox.svg': 'plug-snapav-wattbox.svg',
    'plug-switchmate-powersmartplug.svg': 'plug-switchmate-powersmartplug.svg',
    'plug-teckin-smartplug.svg': 'plug-teckin-smartplug.svg',
    'plug-thinkeco-smartacplug.svg': 'plug-thinkeco-smartacplug.svg',
    'plug-tplink-kasa.svg': 'plug-tplink-kasa.svg',
    'plug-tplink-kasaoutlet.svg': 'plug-tplink-kasaoutlet.svg',
    'plug-tplink-kasawifiplug.svg': 'plug-tplink-kasawifiplug.svg',
    'plug-tplink-kasawifipowerstrip.svg': 'plug-tplink-kasawifipowerstrip.svg',
    'plug-tplink-powerline.svg': 'plug-tplink-powerline.svg',
    'plug-tplink-taposmartplug.svg': 'plug-tplink-taposmartplug.svg',
    'plug-tplink.svg': 'plug-tplink.svg',
    'plug-ubiquiti-unifi.svg': 'plug-ubiquiti-unifi.svg',
    'plug-vocolinc-powerstrip.svg': 'plug-vocolinc-powerstrip.svg',
    'plug-vocolinc-smartbar.svg': 'plug-vocolinc-smartbar.svg',
    'plug-vocolinc-smartoutlet.svg': 'plug-vocolinc-smartoutlet.svg',
    'plug-vocolinc-vp5.svg': 'plug-vocolinc-vp5.svg',
    'plug-wion-smartplug.svg': 'plug-wion-smartplug.svg',
    'plug-wyze-smartplug.svg': 'plug-wyze-smartplug.svg',
    'plug-xiaomi-mismartplug.svg': 'plug-xiaomi-mismartplug.svg',
    'plug.svg': 'plug.svg',
    'pod.svg': 'pod.svg',
    'pointofsale-clover-flex.svg': 'pointofsale-clover-flex.svg',
    'pointofsale-clover-mini.svg': 'pointofsale-clover-mini.svg',
    'pointofsale-clover-station.svg': 'pointofsale-clover-station.svg',
    'pointofsale-clover.svg': 'pointofsale-clover.svg',
    'pointofsale-ingenico-smartpos.svg': 'pointofsale-ingenico-smartpos.svg',
    'pointofsale-ingenico.svg': 'pointofsale-ingenico.svg',
    'pointofsale-pax-a80.svg': 'pointofsale-pax-a80.svg',
    'pointofsale-pax-a910.svg': 'pointofsale-pax-a910.svg',
    'pointofsale-pax-a920.svg': 'pointofsale-pax-a920.svg',
    'pointofsale-pax-a930.svg': 'pointofsale-pax-a930.svg',
    'pointofsale-pax-pos.svg': 'pointofsale-pax-pos.svg',
    'pointofsale-pax-s80.svg': 'pointofsale-pax-s80.svg',
    'pointofsale-pax.svg': 'pointofsale-pax.svg',
    'pointofsale-verifone-pos.svg': 'pointofsale-verifone-pos.svg',
    'pointofsale-verifone.svg': 'pointofsale-verifone.svg',
    'pointofsale.svg': 'pointofsale.svg',
    'poolsystem-1.svg': 'poolsystem-1.svg',
    'poolsystem-iaqualink.svg': 'poolsystem-iaqualink.svg',
    'poolsystem-pentair-intellichlor.svg':
      'poolsystem-pentair-intellichlor.svg',
    'poolsystem-pentair-poolcontrolsystem.svg':
      'poolsystem-pentair-poolcontrolsystem.svg',
    'poolsystem-waterguru-sense.svg': 'poolsystem-waterguru-sense.svg',
    'poolsystem.svg': 'poolsystem.svg',
    'printer-brother.svg': 'printer-brother.svg',
    'printer-canon-photoprinter.svg': 'printer-canon-photoprinter.svg',
    'printer-canon-pixma.svg': 'printer-canon-pixma.svg',
    'printer-canon.svg': 'printer-canon.svg',
    'printer-canon2.svg': 'printer-canon2.svg',
    'printer-creality-cr10s.svg': 'printer-creality-cr10s.svg',
    'printer-creality-ender.svg': 'printer-creality-ender.svg',
    'printer-dell.svg': 'printer-dell.svg',
    'printer-epson.svg': 'printer-epson.svg',
    'printer-glowforge-3dprinter.svg': 'printer-glowforge-3dprinter.svg',
    'printer-hp.svg': 'printer-hp.svg',
    'printer-kodak-verite.svg': 'printer-kodak-verite.svg',
    'printer-panasonic-fax.svg': 'printer-panasonic-fax.svg',
    'printer-pantum.svg': 'printer-pantum.svg',
    'printer-ricoh-sp.svg': 'printer-ricoh-sp.svg',
    'printer-ricoh.svg': 'printer-ricoh.svg',
    'printer-samsung.svg': 'printer-samsung.svg',
    'printer-toybox-3dprinter.svg': 'printer-toybox-3dprinter.svg',
    'printer-ultimaker-3dprinter.svg': 'printer-ultimaker-3dprinter.svg',
    'printer-xerox-phaser.svg': 'printer-xerox-phaser.svg',
    'printer-xerox.svg': 'printer-xerox.svg',
    'printer.svg': 'printer.svg',
    'projector-epson.svg': 'projector-epson.svg',
    'projector.svg': 'projector.svg',
    'rangeextender-arris-gca2002.svg': 'rangeextender-arris-gca2002.svg',
    'rangeextender-arris-gpe1003.svg': 'rangeextender-arris-gpe1003.svg',
    'rangeextender-arris-gpe2001.svg': 'rangeextender-arris-gpe2001.svg',
    'rangeextender-arris.svg': 'rangeextender-arris.svg',
    'rangeextender-asus-n300repeater.svg':
      'rangeextender-asus-n300repeater.svg',
    'rangeextender-dlink-wifiextender.svg':
      'rangeextender-dlink-wifiextender.svg',
    'rangeextender.svg': 'rangeextender.svg',
    'rangestove.svg': 'rangestove.svg',
    'rings-charger.svg': 'rings-charger.svg',
    'rings.svg': 'rings.svg',
    'robotvacuumcleaner-360-robotvacuumcleaner.svg':
      'robotvacuumcleaner-360-robotvacuumcleaner.svg',
    'robotvacuumcleaner-360.svg': 'robotvacuumcleaner-360.svg',
    'robotvacuumcleaner-bissell-robotvacuum.svg':
      'robotvacuumcleaner-bissell-robotvacuum.svg',
    'robotvacuumcleaner-bissell.svg': 'robotvacuumcleaner-bissell.svg',
    'robotvacuumcleaner-dyson-360.svg': 'robotvacuumcleaner-dyson-360.svg',
    'robotvacuumcleaner-dyson.svg': 'robotvacuumcleaner-dyson.svg',
    'robotvacuumcleaner-ecovacs-deebot.svg':
      'robotvacuumcleaner-ecovacs-deebot.svg',
    'robotvacuumcleaner-ecovacs.svg': 'robotvacuumcleaner-ecovacs.svg',
    'robotvacuumcleaner-electrolux-pure.svg':
      'robotvacuumcleaner-electrolux-pure.svg',
    'robotvacuumcleaner-electrolux.svg': 'robotvacuumcleaner-electrolux.svg',
    'robotvacuumcleaner-eufy-robovac.svg':
      'robotvacuumcleaner-eufy-robovac.svg',
    'robotvacuumcleaner-eufy.svg': 'robotvacuumcleaner-eufy.svg',
    'robotvacuumcleaner-irobot-roomba.svg':
      'robotvacuumcleaner-irobot-roomba.svg',
    'robotvacuumcleaner-irobot.svg': 'robotvacuumcleaner-irobot.svg',
    'robotvacuumcleaner-narwal-robotmop.svg':
      'robotvacuumcleaner-narwal-robotmop.svg',
    'robotvacuumcleaner-narwal.svg': 'robotvacuumcleaner-narwal.svg',
    'robotvacuumcleaner-neabot-robotvacuum.svg':
      'robotvacuumcleaner-neabot-robotvacuum.svg',
    'robotvacuumcleaner-neabot.svg': 'robotvacuumcleaner-neabot.svg',
    'robotvacuumcleaner-neato-botvac.svg':
      'robotvacuumcleaner-neato-botvac.svg',
    'robotvacuumcleaner-neato.svg': 'robotvacuumcleaner-neato.svg',
    'robotvacuumcleaner-plus360-s7.svg': 'robotvacuumcleaner-plus360-s7.svg',
    'robotvacuumcleaner-plus360.svg': 'robotvacuumcleaner-plus360.svg',
    'robotvacuumcleaner-samsung-powerbotvacuum.svg':
      'robotvacuumcleaner-samsung-powerbotvacuum.svg',
    'robotvacuumcleaner-samsung.svg': 'robotvacuumcleaner-samsung.svg',
    'robotvacuumcleaner-shark-robotvacuum.svg':
      'robotvacuumcleaner-shark-robotvacuum.svg',
    'robotvacuumcleaner-shark.svg': 'robotvacuumcleaner-shark.svg',
    'robotvacuumcleaner-viomi-robotvacuum.svg':
      'robotvacuumcleaner-viomi-robotvacuum.svg',
    'robotvacuumcleaner-viomi.svg': 'robotvacuumcleaner-viomi.svg',
    'robotvacuumcleaner-xiaomi-mi.svg': 'robotvacuumcleaner-xiaomi-mi.svg',
    'robotvacuumcleaner-xiaomi-roborock.svg':
      'robotvacuumcleaner-xiaomi-roborock.svg',
    'robotvacuumcleaner-xiaomi.svg': 'robotvacuumcleaner-xiaomi.svg',
    'robotvacuumcleaner.svg': 'robotvacuumcleaner.svg',
    'rotimatic.svg': 'rotimatic.svg',
    'router-1.svg': 'router-1.svg',
    'router-814v6.svg': 'router-814v6.svg',
    'router-actiontec-web6000q.svg': 'router-actiontec-web6000q.svg',
    'router-actiontec.svg': 'router-actiontec.svg',
    'router-adtran-netvanta.svg': 'router-adtran-netvanta.svg',
    'router-adtran-sdg814v6.svg': 'router-adtran-sdg814v6.svg',
    'router-airties.svg': 'router-airties.svg',
    'router-apple-airport.svg': 'router-apple-airport.svg',
    'router-araknis-accesspoint.svg': 'router-araknis-accesspoint.svg',
    'router-araknis-networkswitch.svg': 'router-araknis-networkswitch.svg',
    'router-araknis-router.svg': 'router-araknis-router.svg',
    'router-arris-gca2002.svg': 'router-arris-gca2002.svg',
    'router-arris-gpe1003.svg': 'router-arris-gpe1003.svg',
    'router-arris-router.svg': 'router-arris-router.svg',
    'router-aruba-ap.svg': 'router-aruba-ap.svg',
    'router-asus-wirelessrouter.svg': 'router-asus-wirelessrouter.svg',
    'router-asus.svg': 'router-asus.svg',
    'router-att-uverse.svg': 'router-att-uverse.svg',
    'router-avm-fritzbox.svg': 'router-avm-fritzbox.svg',
    'router-belkin.svg': 'router-belkin.svg',
    'router-bt-minihotspot.svg': 'router-bt-minihotspot.svg',
    'router-cambium-cnpilot.svg': 'router-cambium-cnpilot.svg',
    'router-cisco-encs.svg': 'router-cisco-encs.svg',
    'router-cisco-meraki.svg': 'router-cisco-meraki.svg',
    'router-cisco-rf-1.svg': 'router-cisco-rf-1.svg',
    'router-cisco-rf-2.svg': 'router-cisco-rf-2.svg',
    'router-cisco-rf.svg': 'router-cisco-rf.svg',
    'router-creston-cenioir104.svg': 'router-creston-cenioir104.svg',
    'router-crestron-cengwexer.svg': 'router-crestron-cengwexer.svg',
    'router-dlink-dirx1560.svg': 'router-dlink-dirx1560.svg',
    'router-dlink-wirelesshomecloudrouter.svg':
      'router-dlink-wirelesshomecloudrouter.svg',
    'router-dlink-wirelessngigabitrouter.svg':
      'router-dlink-wirelessngigabitrouter.svg',
    'router-dlink-wirelessnrouter.svg': 'router-dlink-wirelessnrouter.svg',
    'router-dlink-xtremenduo.svg': 'router-dlink-xtremenduo.svg',
    'router-dlink.svg': 'router-dlink.svg',
    'router-elecom-router.svg': 'router-elecom-router.svg',
    'router-fios-quantumgateway.svg': 'router-fios-quantumgateway.svg',
    'router-glinet-iotgateway.svg': 'router-glinet-iotgateway.svg',
    'router-glinet-meshwifi.svg': 'router-glinet-meshwifi.svg',
    'router-glinet-minirouter.svg': 'router-glinet-minirouter.svg',
    'router-glinet-travelrouter.svg': 'router-glinet-travelrouter.svg',
    'router-google-fiber.svg': 'router-google-fiber.svg',
    'router-hitron-gateway.svg': 'router-hitron-gateway.svg',
    'router-huawei.svg': 'router-huawei.svg',
    'router-iodata-airport.svg': 'router-iodata-airport.svg',
    'router-iodata-wifirouter.svg': 'router-iodata-wifirouter.svg',
    'router-legrand-gateway.svg': 'router-legrand-gateway.svg',
    'router-linksys-ethernetbridge.svg': 'router-linksys-ethernetbridge.svg',
    'router-linksys-mediaconnector.svg': 'router-linksys-mediaconnector.svg',
    'router-linksys-wifirouter.svg': 'router-linksys-wifirouter.svg',
    'router-linksys.svg': 'router-linksys.svg',
    'router-luma.svg': 'router-luma.svg',
    'router-luxul-xap.svg': 'router-luxul-xap.svg',
    'router-mikrotik-router.svg': 'router-mikrotik-router.svg',
    'router-mimosa-a5.svg': 'router-mimosa-a5.svg',
    'router-nec-aterm.svg': 'router-nec-aterm.svg',
    'router-netgear-ac1200.svg': 'router-netgear-ac1200.svg',
    'router-netgear-dm200.svg': 'router-netgear-dm200.svg',
    'router-netgear-ethernetswitch.svg': 'router-netgear-ethernetswitch.svg',
    'router-netgear-nighthawk.svg': 'router-netgear-nighthawk.svg',
    'router-netgear-nighthawkrouter.svg': 'router-netgear-nighthawkrouter.svg',
    'router-netgear-powerline.svg': 'router-netgear-powerline.svg',
    'router-netgear-r6250.svg': 'router-netgear-r6250.svg',
    'router-netgear-r6300.svg': 'router-netgear-r6300.svg',
    'router-netgear-rangemax.svg': 'router-netgear-rangemax.svg',
    'router-netgear-router.svg': 'router-netgear-router.svg',
    'router-netgear-wifirouter.svg': 'router-netgear-wifirouter.svg',
    'router-netgear-wirelessn.svg': 'router-netgear-wirelessn.svg',
    'router-netgear-wirelessrouter.svg': 'router-netgear-wirelessrouter.svg',
    'router-netgear-wnce3001.svg': 'router-netgear-wnce3001.svg',
    'router-netgear.svg': 'router-netgear.svg',
    'router-netgearnighthawk-progamingrouter.svg':
      'router-netgearnighthawk-progamingrouter.svg',
    'router-netgearnighthawk-smartwifirouter.svg':
      'router-netgearnighthawk-smartwifirouter.svg',
    'router-netgearnighthawk-wifirouter.svg':
      'router-netgearnighthawk-wifirouter.svg',
    'router-netgearorbi-satellite.svg': 'router-netgearorbi-satellite.svg',
    'router-netgearorbi-wallplug.svg': 'router-netgearorbi-wallplug.svg',
    'router-netgearorbi-wifisystem.svg': 'router-netgearorbi-wifisystem.svg',
    'router-netgearorbi.svg': 'router-netgearorbi.svg',
    'router-openwrt.svg': 'router-openwrt.svg',
    'router-pakedge-rk1.svg': 'router-pakedge-rk1.svg',
    'router-panasonic-accesspoint.svg': 'router-panasonic-accesspoint.svg',
    'router-peplink-max.svg': 'router-peplink-max.svg',
    'router-peplink-surfsoho.svg': 'router-peplink-surfsoho.svg',
    'router-planet-wirelessrouter.svg': 'router-planet-wirelessrouter.svg',
    'router-rockspace-router.svg': 'router-rockspace-router.svg',
    'router-ruckus-switch.svg': 'router-ruckus-switch.svg',
    'router-ruckus-unleashed.svg': 'router-ruckus-unleashed.svg',
    'router-samsung-connecthome.svg': 'router-samsung-connecthome.svg',
    'router-smartrg-sr400ac.svg': 'router-smartrg-sr400ac.svg',
    'router-synology.svg': 'router-synology.svg',
    'router-tplink-accesspoint.svg': 'router-tplink-accesspoint.svg',
    'router-tplink-ap.svg': 'router-tplink-ap.svg',
    'router-tplink-archer.svg': 'router-tplink-archer.svg',
    'router-tplink-deco.svg': 'router-tplink-deco.svg',
    'router-tplink-tlwa801nd.svg': 'router-tplink-tlwa801nd.svg',
    'router-tplink-tlwa901nd.svg': 'router-tplink-tlwa901nd.svg',
    'router-tplink-tlwr802n.svg': 'router-tplink-tlwr802n.svg',
    'router-tplink-tlwr841nd.svg': 'router-tplink-tlwr841nd.svg',
    'router-tplink-tlwr940n.svg': 'router-tplink-tlwr940n.svg',
    'router-tplink-touch.svg': 'router-tplink-touch.svg',
    'router-tplink.svg': 'router-tplink.svg',
    'router-trendnet-tew.svg': 'router-trendnet-tew.svg',
    'router-trendnet-wirelessrouter.svg': 'router-trendnet-wirelessrouter.svg',
    'router-ubiquiti-edgerouter.svg': 'router-ubiquiti-edgerouter.svg',
    'router-ubiquiti-nanostation.svg': 'router-ubiquiti-nanostation.svg',
    'router-ubiquiti-unifi.svg': 'router-ubiquiti-unifi.svg',
    'router-ubiquiti-usw.svg': 'router-ubiquiti-usw.svg',
    'router-verizon-fios.svg': 'router-verizon-fios.svg',
    'router-viasat-surfbeam.svg': 'router-viasat-surfbeam.svg',
    'router-wifi6.svg': 'router-wifi6.svg',
    'router-wodesys-rangeextender.svg': 'router-wodesys-rangeextender.svg',
    'router-xiaomi-aiot.svg': 'router-xiaomi-aiot.svg',
    'router-xiaomi-miwifi.svg': 'router-xiaomi-miwifi.svg',
    'router-zyxel-emg2926.svg': 'router-zyxel-emg2926.svg',
    'router-zyxel.svg': 'router-zyxel.svg',
    'router.svg': 'router.svg',
    'scale-fitbit-wifismartscale.svg': 'scale-fitbit-wifismartscale.svg',
    'scale-qardio-qardiobase2.svg': 'scale-qardio-qardiobase2.svg',
    'scale-shenzhen-smartscale.svg': 'scale-shenzhen-smartscale.svg',
    'scale-withings-body+.svg': 'scale-withings-body+.svg',
    'scale-withings-body.svg': 'scale-withings-body.svg',
    'scale-withings-bodycardio.svg': 'scale-withings-bodycardio.svg',
    'scale-withings.svg': 'scale-withings.svg',
    'scale-yolanda.svg': 'scale-yolanda.svg',
    'scale-yunmaimini-smartscale.svg': 'scale-yunmaimini-smartscale.svg',
    'scale.svg': 'scale.svg',
    'sciencekit-anki-vectorrobot.svg': 'sciencekit-anki-vectorrobot.svg',
    'sciencekit.svg': 'sciencekit.svg',
    'sensor-belkin-wemo.svg': 'sensor-belkin-wemo.svg',
    'sensor-connectedlifelabs-smartdry.svg':
      'sensor-connectedlifelabs-smartdry.svg',
    'sensor-dahua-aixvr.svg': 'sensor-dahua-aixvr.svg',
    'sensor-dlink-motionsensor.svg': 'sensor-dlink-motionsensor.svg',
    'sensor-dlink-mydlink.svg': 'sensor-dlink-mydlink.svg',
    'sensor-dlink-watersensor-1.svg': 'sensor-dlink-watersensor-1.svg',
    'sensor-dlink-watersensor.svg': 'sensor-dlink-watersensor.svg',
    'sensor-escort-max.svg': 'sensor-escort-max.svg',
    'sensor-flo-watermonitor.svg': 'sensor-flo-watermonitor.svg',
    'sensor-idealsciences-thermostat.svg':
      'sensor-idealsciences-thermostat.svg',
    'sensor-kangaroo-motionsensor.svg': 'sensor-kangaroo-motionsensor.svg',
    'sensor-kinetico-leaksensor.svg': 'sensor-kinetico-leaksensor.svg',
    'sensor-leakalert-wifiwatersensor.svg':
      'sensor-leakalert-wifiwatersensor.svg',
    'sensor-leakbot-watersensor.svg': 'sensor-leakbot-watersensor.svg',
    'sensor-leaksmart-leakdetector.svg': 'sensor-leaksmart-leakdetector.svg',
    'sensor-leeo-smartalert.svg': 'sensor-leeo-smartalert.svg',
    'sensor-leeo.svg': 'sensor-leeo.svg',
    'sensor-levelsense-sumppump.svg': 'sensor-levelsense-sumppump.svg',
    'sensor-myfox-homealarm.svg': 'sensor-myfox-homealarm.svg',
    'sensor-noiseaware-noisemonitor.svg': 'sensor-noiseaware-noisemonitor.svg',
    'sensor-purpleair-sensor.svg': 'sensor-purpleair-sensor.svg',
    'sensor-roost.svg': 'sensor-roost.svg',
    'sensor-shelly-doorsensor.svg': 'sensor-shelly-doorsensor.svg',
    'sensor-shelly-doorwindow.svg': 'sensor-shelly-doorwindow.svg',
    'sensor-shelly-floodsensor.svg': 'sensor-shelly-floodsensor.svg',
    'sensor-shelly-humiditysensor.svg': 'sensor-shelly-humiditysensor.svg',
    'sensor-shelly-ledlightstrip.svg': 'sensor-shelly-ledlightstrip.svg',
    'sensor-ting-smartsensor.svg': 'sensor-ting-smartsensor.svg',
    'sensor.svg': 'sensor.svg',
    'settopbox-a95x-f1.svg': 'settopbox-a95x-f1.svg',
    'settopbox-a95x-r2.svg': 'settopbox-a95x-r2.svg',
    'settopbox-a98x-settopbox.svg': 'settopbox-a98x-settopbox.svg',
    'settopbox-abox-tvbox.svg': 'settopbox-abox-tvbox.svg',
    'settopbox-adb-settopbox.svg': 'settopbox-adb-settopbox.svg',
    'settopbox-airtel-xstreamstick.svg': 'settopbox-airtel-xstreamstick.svg',
    'settopbox-airtv-3.svg': 'settopbox-airtv-3.svg',
    'settopbox-amazon-firetv.svg': 'settopbox-amazon-firetv.svg',
    'settopbox-amiko-viper.svg': 'settopbox-amiko-viper.svg',
    'settopbox-amino-amigo.svg': 'settopbox-amino-amigo.svg',
    'settopbox-amino-aminet.svg': 'settopbox-amino-aminet.svg',
    'settopbox-apple-tv.svg': 'settopbox-apple-tv.svg',
    'settopbox-arris-eossettopbox.svg': 'settopbox-arris-eossettopbox.svg',
    'settopbox-arris-settopbox.svg': 'settopbox-arris-settopbox.svg',
    'settopbox-arris-videoaccesspoint.svg':
      'settopbox-arris-videoaccesspoint.svg',
    'settopbox-att-tv.svg': 'settopbox-att-tv.svg',
    'settopbox-att-uversedvr.svg': 'settopbox-att-uversedvr.svg',
    'settopbox-avermedia-avertv.svg': 'settopbox-avermedia-avertv.svg',
    'settopbox-avov-tvln2r.svg': 'settopbox-avov-tvln2r.svg',
    'settopbox-beelink-gt1.svg': 'settopbox-beelink-gt1.svg',
    'settopbox-bell-dvr.svg': 'settopbox-bell-dvr.svg',
    'settopbox-bell-wholehomepvr-1.svg': 'settopbox-bell-wholehomepvr-1.svg',
    'settopbox-bell-wholehomepvr.svg': 'settopbox-bell-wholehomepvr.svg',
    'settopbox-caavo-controlcenter.svg': 'settopbox-caavo-controlcenter.svg',
    'settopbox-cisco-settopbox.svg': 'settopbox-cisco-settopbox.svg',
    'settopbox-comcast-xfinity.svg': 'settopbox-comcast-xfinity.svg',
    'settopbox-comcast-xi.svg': 'settopbox-comcast-xi.svg',
    'settopbox-cristor-atlas.svg': 'settopbox-cristor-atlas.svg',
    'settopbox-denon-avreceiver.svg': 'settopbox-denon-avreceiver.svg',
    'settopbox-deutschetelekom.svg': 'settopbox-deutschetelekom.svg',
    'settopbox-directv-geniehddvr.svg': 'settopbox-directv-geniehddvr.svg',
    'settopbox-dish-hopper.svg': 'settopbox-dish-hopper.svg',
    'settopbox-dish-joey.svg': 'settopbox-dish-joey.svg',
    'settopbox-dish-joeyreceiver.svg': 'settopbox-dish-joeyreceiver.svg',
    'settopbox-dish-wally.svg': 'settopbox-dish-wally.svg',
    'settopbox-dish-wirelessjoeyreceiver.svg':
      'settopbox-dish-wirelessjoeyreceiver.svg',
    'settopbox-dish.svg': 'settopbox-dish.svg',
    'settopbox-dlink-boxeebox.svg': 'settopbox-dlink-boxeebox.svg',
    'settopbox-dreambox-dm800se.svg': 'settopbox-dreambox-dm800se.svg',
    'settopbox-facebook-portaltv.svg': 'settopbox-facebook-portaltv.svg',
    'settopbox-formuler-f3.svg': 'settopbox-formuler-f3.svg',
    'settopbox-formuler-f4turbo.svg': 'settopbox-formuler-f4turbo.svg',
    'settopbox-formuler-iptv.svg': 'settopbox-formuler-iptv.svg',
    'settopbox-formuler-z.svg': 'settopbox-formuler-z.svg',
    'settopbox-formuler-z8.svg': 'settopbox-formuler-z8.svg',
    'settopbox-formuler-zx5g.svg': 'settopbox-formuler-zx5g.svg',
    'settopbox-foxtel-box.svg': 'settopbox-foxtel-box.svg',
    'settopbox-foxtel-iq3.svg': 'settopbox-foxtel-iq3.svg',
    'settopbox-foxtel-iq4.svg': 'settopbox-foxtel-iq4.svg',
    'settopbox-foxtel-nowbox.svg': 'settopbox-foxtel-nowbox.svg',
    'settopbox-goobangdoo-abox.svg': 'settopbox-goobangdoo-abox.svg',
    'settopbox-google-chromecast.svg': 'settopbox-google-chromecast.svg',
    'settopbox-google-nexus.svg': 'settopbox-google-nexus.svg',
    'settopbox-gtmedia.svg': 'settopbox-gtmedia.svg',
    'settopbox-h96-max.svg': 'settopbox-h96-max.svg',
    'settopbox-himedia-hi3798mv100.svg': 'settopbox-himedia-hi3798mv100.svg',
    'settopbox-hisilicon-q10.svg': 'settopbox-hisilicon-q10.svg',
    'settopbox-hisilicon.svg': 'settopbox-hisilicon.svg',
    'settopbox-hke360-tvbox.svg': 'settopbox-hke360-tvbox.svg',
    'settopbox-humax-settopbox.svg': 'settopbox-humax-settopbox.svg',
    'settopbox-infomir.svg': 'settopbox-infomir.svg',
    'settopbox-jcom-link.svg': 'settopbox-jcom-link.svg',
    'settopbox-kaon-settopbox.svg': 'settopbox-kaon-settopbox.svg',
    'settopbox-logitech-squeezeboxreceiver.svg':
      'settopbox-logitech-squeezeboxreceiver.svg',
    'settopbox-magenta-tvbox.svg': 'settopbox-magenta-tvbox.svg',
    'settopbox-magictv-mtv9600d.svg': 'settopbox-magictv-mtv9600d.svg',
    'settopbox-marantz-avreceiver.svg': 'settopbox-marantz-avreceiver.svg',
    'settopbox-mecool-m8s.svg': 'settopbox-mecool-m8s.svg',
    'settopbox-mecool-m8spro.svg': 'settopbox-mecool-m8spro.svg',
    'settopbox-minix-neo.svg': 'settopbox-minix-neo.svg',
    'settopbox-mxq-pro.svg': 'settopbox-mxq-pro.svg',
    'settopbox-mytv-superbox.svg': 'settopbox-mytv-superbox.svg',
    'settopbox-netgem-box.svg': 'settopbox-netgem-box.svg',
    'settopbox-nowtv-rokusmartstick.svg': 'settopbox-nowtv-rokusmartstick.svg',
    'settopbox-nowtv-rokustb.svg': 'settopbox-nowtv-rokustb.svg',
    'settopbox-nvidia-shieldtv.svg': 'settopbox-nvidia-shieldtv.svg',
    'settopbox-onkyo-avreceiver.svg': 'settopbox-onkyo-avreceiver.svg',
    'settopbox-optimum-alticeone.svg': 'settopbox-optimum-alticeone.svg',
    'settopbox-ott-box.svg': 'settopbox-ott-box.svg',
    'settopbox-philips-huesyncbox.svg': 'settopbox-philips-huesyncbox.svg',
    'settopbox-pioneer-avreceiver.svg': 'settopbox-pioneer-avreceiver.svg',
    'settopbox-quickline-uhdbox.svg': 'settopbox-quickline-uhdbox.svg',
    'settopbox-realtek-atv.svg': 'settopbox-realtek-atv.svg',
    'settopbox-roku-express+.svg': 'settopbox-roku-express+.svg',
    'settopbox-roku-express.svg': 'settopbox-roku-express.svg',
    'settopbox-roku-hd.svg': 'settopbox-roku-hd.svg',
    'settopbox-roku-lt.svg': 'settopbox-roku-lt.svg',
    'settopbox-roku-player.svg': 'settopbox-roku-player.svg',
    'settopbox-roku-premiere+.svg': 'settopbox-roku-premiere+.svg',
    'settopbox-roku-premiere.svg': 'settopbox-roku-premiere.svg',
    'settopbox-roku-streamingstick+.svg': 'settopbox-roku-streamingstick+.svg',
    'settopbox-roku-streamingstick.svg': 'settopbox-roku-streamingstick.svg',
    'settopbox-roku-ultra.svg': 'settopbox-roku-ultra.svg',
    'settopbox-roku.svg': 'settopbox-roku.svg',
    'settopbox-sagemcom-sunrise.svg': 'settopbox-sagemcom-sunrise.svg',
    'settopbox-samsung.svg': 'settopbox-samsung.svg',
    'settopbox-sasktel-maxtvstream.svg': 'settopbox-sasktel-maxtvstream.svg',
    'settopbox-shadow-ghost.svg': 'settopbox-shadow-ghost.svg',
    'settopbox-silicondust-hdhomerun.svg':
      'settopbox-silicondust-hdhomerun.svg',
    'settopbox-sky+-hdbox.svg': 'settopbox-sky+-hdbox.svg',
    'settopbox-sky-hub.svg': 'settopbox-sky-hub.svg',
    'settopbox-sky-qstb.svg': 'settopbox-sky-qstb.svg',
    'settopbox-sling-airtvplayer.svg': 'settopbox-sling-airtvplayer.svg',
    'settopbox-slingbox-350.svg': 'settopbox-slingbox-350.svg',
    'settopbox-slingbox-500.svg': 'settopbox-slingbox-500.svg',
    'settopbox-slingbox-m1.svg': 'settopbox-slingbox-m1.svg',
    'settopbox-slingbox.svg': 'settopbox-slingbox.svg',
    'settopbox-swisscom-tvbox.svg': 'settopbox-swisscom-tvbox.svg',
    'settopbox-technicolor-settopbox.svg':
      'settopbox-technicolor-settopbox.svg',
    'settopbox-technicolor.svg': 'settopbox-technicolor.svg',
    'settopbox-tivo.svg': 'settopbox-tivo.svg',
    'settopbox-tx3-mini.svg': 'settopbox-tx3-mini.svg',
    'settopbox-unblock-ubox4.svg': 'settopbox-unblock-ubox4.svg',
    'settopbox-unblocktech-ubox6.svg': 'settopbox-unblocktech-ubox6.svg',
    'settopbox-upc-tvbox.svg': 'settopbox-upc-tvbox.svg',
    'settopbox-valve-steamlink.svg': 'settopbox-valve-steamlink.svg',
    'settopbox-vestel.svg': 'settopbox-vestel.svg',
    'settopbox-viark-combo.svg': 'settopbox-viark-combo.svg',
    'settopbox-viark-lil.svg': 'settopbox-viark-lil.svg',
    'settopbox-viark-sat.svg': 'settopbox-viark-sat.svg',
    'settopbox-virgin-tvbox.svg': 'settopbox-virgin-tvbox.svg',
    'settopbox-vodafone-gigatv.svg': 'settopbox-vodafone-gigatv.svg',
    'settopbox-vodafone.svg': 'settopbox-vodafone.svg',
    'settopbox-voo-evasionbox.svg': 'settopbox-voo-evasionbox.svg',
    'settopbox-vuplus-solo.svg': 'settopbox-vuplus-solo.svg',
    'settopbox-vuplus-zero.svg': 'settopbox-vuplus-zero.svg',
    'settopbox-x92-tvbox.svg': 'settopbox-x92-tvbox.svg',
    'settopbox-x96-q.svg': 'settopbox-x96-q.svg',
    'settopbox-x96-tvbox.svg': 'settopbox-x96-tvbox.svg',
    'settopbox-xiaomi-mibox.svg': 'settopbox-xiaomi-mibox.svg',
    'settopbox-xiaomi-miboxtv.svg': 'settopbox-xiaomi-miboxtv.svg',
    'settopbox-yamaha-rxa830.svg': 'settopbox-yamaha-rxa830.svg',
    'settopbox-yousee.svg': 'settopbox-yousee.svg',
    'settopbox-zgemma-h2h.svg': 'settopbox-zgemma-h2h.svg',
    'settopbox-zgemma-h9s.svg': 'settopbox-zgemma-h9s.svg',
    'settopbox-zgemma-star.svg': 'settopbox-zgemma-star.svg',
    'settopbox-ziggo-mediabox.svg': 'settopbox-ziggo-mediabox.svg',
    'settopbox.svg': 'settopbox.svg',
    'sleepsystem-1.svg': 'sleepsystem-1.svg',
    'sleepsystem.svg': 'sleepsystem.svg',
    'smartappliance-anovaculinary-precisioncooker.svg':
      'smartappliance-anovaculinary-precisioncooker.svg',
    'smartappliance-anovaculinary.svg': 'smartappliance-anovaculinary.svg',
    'smartappliance-ge.svg': 'smartappliance-ge.svg',
    'smartappliance.svg': 'smartappliance.svg',
    'smartbed.svg': 'smartbed.svg',
    'smartdevice-amazon-dashbutton.svg': 'smartdevice-amazon-dashbutton.svg',
    'smartdevice-dyson.svg': 'smartdevice-dyson.svg',
    'smartdevice-dyson2.svg': 'smartdevice-dyson2.svg',
    'smartdevice-espressif.svg': 'smartdevice-espressif.svg',
    'smartdevice-flir.svg': 'smartdevice-flir.svg',
    'smartdevice-funguypro-wateralarm.svg':
      'smartdevice-funguypro-wateralarm.svg',
    'smartdevice-idevices.svg': 'smartdevice-idevices.svg',
    'smartdevice-ismart-smartsecurity.svg':
      'smartdevice-ismart-smartsecurity.svg',
    'smartdevice-konke.svg': 'smartdevice-konke.svg',
    'smartdevice.svg': 'smartdevice.svg',
    'smartgrill.svg': 'smartgrill.svg',
    'smartlaundry.svg': 'smartlaundry.svg',
    'smartmonitor-ecowater-hydrolinkplus.svg':
      'smartmonitor-ecowater-hydrolinkplus.svg',
    'smartmonitor-emporia-vue.svg': 'smartmonitor-emporia-vue.svg',
    'smartmonitor-encored-enertalk.svg': 'smartmonitor-encored-enertalk.svg',
    'smartmonitor-energy-bridge2.svg': 'smartmonitor-energy-bridge2.svg',
    'smartmonitor-eyedro-electricitymonitor.svg':
      'smartmonitor-eyedro-electricitymonitor.svg',
    'smartmonitor-flume-smartwatermonitor.svg':
      'smartmonitor-flume-smartwatermonitor.svg',
    'smartmonitor-guardian-valvecontroller.svg':
      'smartmonitor-guardian-valvecontroller.svg',
    'smartmonitor-ihome.svg': 'smartmonitor-ihome.svg',
    'smartmonitor-lorex-homecenter.svg': 'smartmonitor-lorex-homecenter.svg',
    'smartmonitor-mystrom-wifibutton.svg':
      'smartmonitor-mystrom-wifibutton.svg',
    'smartmonitor-panasonic-aiseg.svg': 'smartmonitor-panasonic-aiseg.svg',
    'smartmonitor-phyn-watermonitor.svg': 'smartmonitor-phyn-watermonitor.svg',
    'smartmonitor-pumpspy-sumppump.svg': 'smartmonitor-pumpspy-sumppump.svg',
    'smartmonitor-qardio-1.svg': 'smartmonitor-qardio-1.svg',
    'smartmonitor-qardio.svg': 'smartmonitor-qardio.svg',
    'smartmonitor-roost-battery.svg': 'smartmonitor-roost-battery.svg',
    'smartmonitor-scram-smartdevice.svg': 'smartmonitor-scram-smartdevice.svg',
    'smartmonitor-sense-homeenergymonitor.svg':
      'smartmonitor-sense-homeenergymonitor.svg',
    'smartmonitor-shelly-3em.svg': 'smartmonitor-shelly-3em.svg',
    'smartmonitor-tankutility-propanetankmonitor.svg':
      'smartmonitor-tankutility-propanetankmonitor.svg',
    'smartmonitor-victronenergy-colorcontrol.svg':
      'smartmonitor-victronenergy-colorcontrol.svg',
    'smartmonitor-wynd-halo.svg': 'smartmonitor-wynd-halo.svg',
    'smartmonitor.svg': 'smartmonitor.svg',
    'smartphone-alcatel-1b.svg': 'smartphone-alcatel-1b.svg',
    'smartphone-alcatel-1t.svg': 'smartphone-alcatel-1t.svg',
    'smartphone-alcatel-1v.svg': 'smartphone-alcatel-1v.svg',
    'smartphone-alcatel-1x.svg': 'smartphone-alcatel-1x.svg',
    'smartphone-alcatel-1с.svg': 'smartphone-alcatel-1с.svg',
    'smartphone-alcatel-5124j.svg': 'smartphone-alcatel-5124j.svg',
    'smartphone-alcatel-avalon.svg': 'smartphone-alcatel-avalon.svg',
    'smartphone-alcatel-go.svg': 'smartphone-alcatel-go.svg',
    'smartphone-alcatel-onetouch.svg': 'smartphone-alcatel-onetouch.svg',
    'smartphone-alcatel-raven.svg': 'smartphone-alcatel-raven.svg',
    'smartphone-alcatel-tcl.svg': 'smartphone-alcatel-tcl.svg',
    'smartphone-alcatel-x1.svg': 'smartphone-alcatel-x1.svg',
    'smartphone-alcatel-zip.svg': 'smartphone-alcatel-zip.svg',
    'smartphone-altice-s12.svg': 'smartphone-altice-s12.svg',
    'smartphone-android-1.svg': 'smartphone-android-1.svg',
    'smartphone-android-l.svg': 'smartphone-android-l.svg',
    'smartphone-android.svg': 'smartphone-android.svg',
    'smartphone-ans-ul40.svg': 'smartphone-ans-ul40.svg',
    'smartphone-apple-iphone-this.svg': 'smartphone-apple-iphone-this.svg',
    'smartphone-apple-iphone.svg': 'smartphone-apple-iphone.svg',
    'smartphone-aquos-r.svg': 'smartphone-aquos-r.svg',
    'smartphone-aquos-r2.svg': 'smartphone-aquos-r2.svg',
    'smartphone-aquos-r3.svg': 'smartphone-aquos-r3.svg',
    'smartphone-aquos-r5g.svg': 'smartphone-aquos-r5g.svg',
    'smartphone-aquos-rcompact.svg': 'smartphone-aquos-rcompact.svg',
    'smartphone-aquos-sense.svg': 'smartphone-aquos-sense.svg',
    'smartphone-aquos-sense2.svg': 'smartphone-aquos-sense2.svg',
    'smartphone-aquos-sense3.svg': 'smartphone-aquos-sense3.svg',
    'smartphone-aquos-sense3basic.svg': 'smartphone-aquos-sense3basic.svg',
    'smartphone-aquos-sense3plus.svg': 'smartphone-aquos-sense3plus.svg',
    'smartphone-aquos-serie.svg': 'smartphone-aquos-serie.svg',
    'smartphone-aquos-seriemini.svg': 'smartphone-aquos-seriemini.svg',
    'smartphone-asus-rogphone.svg': 'smartphone-asus-rogphone.svg',
    'smartphone-asus-smartphone.svg': 'smartphone-asus-smartphone.svg',
    'smartphone-asus-zenfone.svg': 'smartphone-asus-zenfone.svg',
    'smartphone-att-axia.svg': 'smartphone-att-axia.svg',
    'smartphone-att-calypso.svg': 'smartphone-att-calypso.svg',
    'smartphone-att-fusion.svg': 'smartphone-att-fusion.svg',
    'smartphone-att-maestro.svg': 'smartphone-att-maestro.svg',
    'smartphone-att-radiant.svg': 'smartphone-att-radiant.svg',
    'smartphone-blackberry-smartphone.svg':
      'smartphone-blackberry-smartphone.svg',
    'smartphone-blackview-a60.svg': 'smartphone-blackview-a60.svg',
    'smartphone-blackview-a80.svg': 'smartphone-blackview-a80.svg',
    'smartphone-blackview-bv5500.svg': 'smartphone-blackview-bv5500.svg',
    'smartphone-blackview-bv5900.svg': 'smartphone-blackview-bv5900.svg',
    'smartphone-blu-life.svg': 'smartphone-blu-life.svg',
    'smartphone-blu-r1.svg': 'smartphone-blu-r1.svg',
    'smartphone-blu-view.svg': 'smartphone-blu-view.svg',
    'smartphone-blu.svg': 'smartphone-blu.svg',
    'smartphone-cat-s52.svg': 'smartphone-cat-s52.svg',
    'smartphone-chinamobile-a3.svg': 'smartphone-chinamobile-a3.svg',
    'smartphone-coolpad-cool.svg': 'smartphone-coolpad-cool.svg',
    'smartphone-coolpad-legacy.svg': 'smartphone-coolpad-legacy.svg',
    'smartphone-coolpad-quattro.svg': 'smartphone-coolpad-quattro.svg',
    'smartphone-cricket-coolpad.svg': 'smartphone-cricket-coolpad.svg',
    'smartphone-cricket-vision.svg': 'smartphone-cricket-vision.svg',
    'smartphone-cricket-wave.svg': 'smartphone-cricket-wave.svg',
    'smartphone-cubot-j5.svg': 'smartphone-cubot-j5.svg',
    'smartphone-cubot-kingkong.svg': 'smartphone-cubot-kingkong.svg',
    'smartphone-cubot-note.svg': 'smartphone-cubot-note.svg',
    'smartphone-cubot-p30.svg': 'smartphone-cubot-p30.svg',
    'smartphone-cubot-r15pro.svg': 'smartphone-cubot-r15pro.svg',
    'smartphone-cubot-x19.svg': 'smartphone-cubot-x19.svg',
    'smartphone-cubot-x30.svg': 'smartphone-cubot-x30.svg',
    'smartphone-digicel-dl3.svg': 'smartphone-digicel-dl3.svg',
    'smartphone-digicel-dl501.svg': 'smartphone-digicel-dl501.svg',
    'smartphone-doogee-n20.svg': 'smartphone-doogee-n20.svg',
    'smartphone-evercoss-a75b.svg': 'smartphone-evercoss-a75b.svg',
    'smartphone-foxxd-miro.svg': 'smartphone-foxxd-miro.svg',
    'smartphone-freetel-raijin.svg': 'smartphone-freetel-raijin.svg',
    'smartphone-freetel-samurai.svg': 'smartphone-freetel-samurai.svg',
    'smartphone-fujitsu-arrows.svg': 'smartphone-fujitsu-arrows.svg',
    'smartphone-fujitsu-easyphone.svg': 'smartphone-fujitsu-easyphone.svg',
    'smartphone-fujitsu-f04j.svg': 'smartphone-fujitsu-f04j.svg',
    'smartphone-fujitsu-smartphone.svg': 'smartphone-fujitsu-smartphone.svg',
    'smartphone-google-nexus.svg': 'smartphone-google-nexus.svg',
    'smartphone-google-pixel.svg': 'smartphone-google-pixel.svg',
    'smartphone-honeywell-scanpal.svg': 'smartphone-honeywell-scanpal.svg',
    'smartphone-hotpepper-poblano.svg': 'smartphone-hotpepper-poblano.svg',
    'smartphone-htc-10.svg': 'smartphone-htc-10.svg',
    'smartphone-htc-butterfly.svg': 'smartphone-htc-butterfly.svg',
    'smartphone-htc-j.svg': 'smartphone-htc-j.svg',
    'smartphone-htc-one.svg': 'smartphone-htc-one.svg',
    'smartphone-htc-u11.svg': 'smartphone-htc-u11.svg',
    'smartphone-huawei-ascend.svg': 'smartphone-huawei-ascend.svg',
    'smartphone-huawei-ascendxt.svg': 'smartphone-huawei-ascendxt.svg',
    'smartphone-huawei-enjoy.svg': 'smartphone-huawei-enjoy.svg',
    'smartphone-huawei-h1621.svg': 'smartphone-huawei-h1621.svg',
    'smartphone-huawei-honor.svg': 'smartphone-huawei-honor.svg',
    'smartphone-huawei-maimang.svg': 'smartphone-huawei-maimang.svg',
    'smartphone-huawei-mate.svg': 'smartphone-huawei-mate.svg',
    'smartphone-huawei-mate30.svg': 'smartphone-huawei-mate30.svg',
    'smartphone-huawei-mate40.svg': 'smartphone-huawei-mate40.svg',
    'smartphone-huawei-nova.svg': 'smartphone-huawei-nova.svg',
    'smartphone-huawei-p10.svg': 'smartphone-huawei-p10.svg',
    'smartphone-huawei-p20.svg': 'smartphone-huawei-p20.svg',
    'smartphone-huawei-p30.svg': 'smartphone-huawei-p30.svg',
    'smartphone-huawei-p8-1.svg': 'smartphone-huawei-p8-1.svg',
    'smartphone-huawei-p8.svg': 'smartphone-huawei-p8.svg',
    'smartphone-huawei-p9.svg': 'smartphone-huawei-p9.svg',
    'smartphone-huawei-sensa.svg': 'smartphone-huawei-sensa.svg',
    'smartphone-huawei-y6.svg': 'smartphone-huawei-y6.svg',
    'smartphone-huawei-y9.svg': 'smartphone-huawei-y9.svg',
    'smartphone-huawei.svg': 'smartphone-huawei.svg',
    'smartphone-kazuna-etalk.svg': 'smartphone-kazuna-etalk.svg',
    'smartphone-kyocera-1.svg': 'smartphone-kyocera-1.svg',
    'smartphone-kyocera-androidone.svg': 'smartphone-kyocera-androidone.svg',
    'smartphone-kyocera-basio.svg': 'smartphone-kyocera-basio.svg',
    'smartphone-kyocera-basio3.svg': 'smartphone-kyocera-basio3.svg',
    'smartphone-kyocera-basio4.svg': 'smartphone-kyocera-basio4.svg',
    'smartphone-kyocera-cadence.svg': 'smartphone-kyocera-cadence.svg',
    'smartphone-kyocera-digno.svg': 'smartphone-kyocera-digno.svg',
    'smartphone-kyocera-dignorafre.svg': 'smartphone-kyocera-dignorafre.svg',
    'smartphone-kyocera-duraxv-1.svg': 'smartphone-kyocera-duraxv-1.svg',
    'smartphone-kyocera-duraxv.svg': 'smartphone-kyocera-duraxv.svg',
    'smartphone-kyocera-gratina.svg': 'smartphone-kyocera-gratina.svg',
    'smartphone-kyocera-gratina4g.svg': 'smartphone-kyocera-gratina4g.svg',
    'smartphone-kyocera-infobar.svg': 'smartphone-kyocera-infobar.svg',
    'smartphone-kyocera-kantan.svg': 'smartphone-kyocera-kantan.svg',
    'smartphone-kyocera-marvera.svg': 'smartphone-kyocera-marvera.svg',
    'smartphone-kyocera-miraie.svg': 'smartphone-kyocera-miraie.svg',
    'smartphone-kyocera-qua.svg': 'smartphone-kyocera-qua.svg',
    'smartphone-kyocera-quaphone.svg': 'smartphone-kyocera-quaphone.svg',
    'smartphone-kyocera-rafre.svg': 'smartphone-kyocera-rafre.svg',
    'smartphone-kyocera-torque.svg': 'smartphone-kyocera-torque.svg',
    'smartphone-kyocera-urbano.svg': 'smartphone-kyocera-urbano.svg',
    'smartphone-kyocera.svg': 'smartphone-kyocera.svg',
    'smartphone-lenovo-701lv-1.svg': 'smartphone-lenovo-701lv-1.svg',
    'smartphone-lenovo-701lv.svg': 'smartphone-lenovo-701lv.svg',
    'smartphone-letv-leeco.svg': 'smartphone-letv-leeco.svg',
    'smartphone-lg-arena.svg': 'smartphone-lg-arena.svg',
    'smartphone-lg-aristo.svg': 'smartphone-lg-aristo.svg',
    'smartphone-lg-d160.svg': 'smartphone-lg-d160.svg',
    'smartphone-lg-escape.svg': 'smartphone-lg-escape.svg',
    'smartphone-lg-exalt.svg': 'smartphone-lg-exalt.svg',
    'smartphone-lg-fortune.svg': 'smartphone-lg-fortune.svg',
    'smartphone-lg-g6.svg': 'smartphone-lg-g6.svg',
    'smartphone-lg-g7.svg': 'smartphone-lg-g7.svg',
    'smartphone-lg-g8.svg': 'smartphone-lg-g8.svg',
    'smartphone-lg-g8thinq.svg': 'smartphone-lg-g8thinq.svg',
    'smartphone-lg-g8x.svg': 'smartphone-lg-g8x.svg',
    'smartphone-lg-harmony.svg': 'smartphone-lg-harmony.svg',
    'smartphone-lg-it.svg': 'smartphone-lg-it.svg',
    'smartphone-lg-journey.svg': 'smartphone-lg-journey.svg',
    'smartphone-lg-k10.svg': 'smartphone-lg-k10.svg',
    'smartphone-lg-k22-1.svg': 'smartphone-lg-k22-1.svg',
    'smartphone-lg-k22.svg': 'smartphone-lg-k22.svg',
    'smartphone-lg-k30.svg': 'smartphone-lg-k30.svg',
    'smartphone-lg-k31.svg': 'smartphone-lg-k31.svg',
    'smartphone-lg-k40.svg': 'smartphone-lg-k40.svg',
    'smartphone-lg-k40s.svg': 'smartphone-lg-k40s.svg',
    'smartphone-lg-k41s.svg': 'smartphone-lg-k41s.svg',
    'smartphone-lg-k42.svg': 'smartphone-lg-k42.svg',
    'smartphone-lg-k50.svg': 'smartphone-lg-k50.svg',
    'smartphone-lg-k50s.svg': 'smartphone-lg-k50s.svg',
    'smartphone-lg-k51.svg': 'smartphone-lg-k51.svg',
    'smartphone-lg-k61.svg': 'smartphone-lg-k61.svg',
    'smartphone-lg-k8x.svg': 'smartphone-lg-k8x.svg',
    'smartphone-lg-k92.svg': 'smartphone-lg-k92.svg',
    'smartphone-lg-neon.svg': 'smartphone-lg-neon.svg',
    'smartphone-lg-phoenix.svg': 'smartphone-lg-phoenix.svg',
    'smartphone-lg-premier.svg': 'smartphone-lg-premier.svg',
    'smartphone-lg-premierpro.svg': 'smartphone-lg-premierpro.svg',
    'smartphone-lg-prime.svg': 'smartphone-lg-prime.svg',
    'smartphone-lg-q60.svg': 'smartphone-lg-q60.svg',
    'smartphone-lg-q7.svg': 'smartphone-lg-q7.svg',
    'smartphone-lg-q70.svg': 'smartphone-lg-q70.svg',
    'smartphone-lg-qstylus.svg': 'smartphone-lg-qstylus.svg',
    'smartphone-lg-reflect.svg': 'smartphone-lg-reflect.svg',
    'smartphone-lg-risio.svg': 'smartphone-lg-risio.svg',
    'smartphone-lg-style.svg': 'smartphone-lg-style.svg',
    'smartphone-lg-style2.svg': 'smartphone-lg-style2.svg',
    'smartphone-lg-stylo.svg': 'smartphone-lg-stylo.svg',
    'smartphone-lg-tribute.svg': 'smartphone-lg-tribute.svg',
    'smartphone-lg-v30.svg': 'smartphone-lg-v30.svg',
    'smartphone-lg-v35.svg': 'smartphone-lg-v35.svg',
    'smartphone-lg-v40.svg': 'smartphone-lg-v40.svg',
    'smartphone-lg-v50.svg': 'smartphone-lg-v50.svg',
    'smartphone-lg-v60.svg': 'smartphone-lg-v60.svg',
    'smartphone-lg-velvet-1.svg': 'smartphone-lg-velvet-1.svg',
    'smartphone-lg-velvet.svg': 'smartphone-lg-velvet.svg',
    'smartphone-lg-x.svg': 'smartphone-lg-x.svg',
    'smartphone-lg-xpression.svg': 'smartphone-lg-xpression.svg',
    'smartphone-logic-x50a.svg': 'smartphone-logic-x50a.svg',
    'smartphone-microsoft-surface.svg': 'smartphone-microsoft-surface.svg',
    'smartphone-microsoft-windowsphone.svg':
      'smartphone-microsoft-windowsphone.svg',
    'smartphone-motorola-moto.svg': 'smartphone-motorola-moto.svg',
    'smartphone-motorola.svg': 'smartphone-motorola.svg',
    'smartphone-myphone-prime.svg': 'smartphone-myphone-prime.svg',
    'smartphone-nec-101n.svg': 'smartphone-nec-101n.svg',
    'smartphone-nextbook-ares.svg': 'smartphone-nextbook-ares.svg',
    'smartphone-nixplay-seed.svg': 'smartphone-nixplay-seed.svg',
    'smartphone-nokia-oneplus.svg': 'smartphone-nokia-oneplus.svg',
    'smartphone-nokia-phone.svg': 'smartphone-nokia-phone.svg',
    'smartphone-nubia-redmagic.svg': 'smartphone-nubia-redmagic.svg',
    'smartphone-nuumobile-a6l.svg': 'smartphone-nuumobile-a6l.svg',
    'smartphone-oneplus-3.svg': 'smartphone-oneplus-3.svg',
    'smartphone-oneplus-3t.svg': 'smartphone-oneplus-3t.svg',
    'smartphone-oneplus-5.svg': 'smartphone-oneplus-5.svg',
    'smartphone-oneplus-5t.svg': 'smartphone-oneplus-5t.svg',
    'smartphone-oneplus-6.svg': 'smartphone-oneplus-6.svg',
    'smartphone-oneplus-7.svg': 'smartphone-oneplus-7.svg',
    'smartphone-oneplus-7pro.svg': 'smartphone-oneplus-7pro.svg',
    'smartphone-oneplus-7t.svg': 'smartphone-oneplus-7t.svg',
    'smartphone-oneplus-8pro.svg': 'smartphone-oneplus-8pro.svg',
    'smartphone-oneplus-8t.svg': 'smartphone-oneplus-8t.svg',
    'smartphone-oneplus-nord.svg': 'smartphone-oneplus-nord.svg',
    'smartphone-oneplus.svg': 'smartphone-oneplus.svg',
    'smartphone-oppo-a1k.svg': 'smartphone-oppo-a1k.svg',
    'smartphone-oppo-a31u.svg': 'smartphone-oppo-a31u.svg',
    'smartphone-oppo-a3s.svg': 'smartphone-oppo-a3s.svg',
    'smartphone-oppo-a5s.svg': 'smartphone-oppo-a5s.svg',
    'smartphone-oppo-a7x.svg': 'smartphone-oppo-a7x.svg',
    'smartphone-oppo-a83.svg': 'smartphone-oppo-a83.svg',
    'smartphone-oppo-ax7.svg': 'smartphone-oppo-ax7.svg',
    'smartphone-oppo-f11.svg': 'smartphone-oppo-f11.svg',
    'smartphone-oppo-f9.svg': 'smartphone-oppo-f9.svg',
    'smartphone-oppo-find.svg': 'smartphone-oppo-find.svg',
    'smartphone-oppo-r11.svg': 'smartphone-oppo-r11.svg',
    'smartphone-oppo-r11s.svg': 'smartphone-oppo-r11s.svg',
    'smartphone-oppo-r15.svg': 'smartphone-oppo-r15.svg',
    'smartphone-oppo-r17.svg': 'smartphone-oppo-r17.svg',
    'smartphone-oppo-reno.svg': 'smartphone-oppo-reno.svg',
    'smartphone-oppo-reno2.svg': 'smartphone-oppo-reno2.svg',
    'smartphone-oppo-reno4.svg': 'smartphone-oppo-reno4.svg',
    'smartphone-oukitel-k10.svg': 'smartphone-oukitel-k10.svg',
    'smartphone-oukitel-k7.svg': 'smartphone-oukitel-k7.svg',
    'smartphone-oukitel-u23.svg': 'smartphone-oukitel-u23.svg',
    'smartphone-oukitel-wp5.svg': 'smartphone-oukitel-wp5.svg',
    'smartphone-oukitel-wp6.svg': 'smartphone-oukitel-wp6.svg',
    'smartphone-p43-pro.svg': 'smartphone-p43-pro.svg',
    'smartphone-palm-pvg100.svg': 'smartphone-palm-pvg100.svg',
    'smartphone-qilive-q8s6in4g.svg': 'smartphone-qilive-q8s6in4g.svg',
    'smartphone-realme-2.svg': 'smartphone-realme-2.svg',
    'smartphone-realme-3.svg': 'smartphone-realme-3.svg',
    'smartphone-realme-5.svg': 'smartphone-realme-5.svg',
    'smartphone-realme-5i.svg': 'smartphone-realme-5i.svg',
    'smartphone-realme-6.svg': 'smartphone-realme-6.svg',
    'smartphone-realme-c2.svg': 'smartphone-realme-c2.svg',
    'smartphone-realme-x2.svg': 'smartphone-realme-x2.svg',
    'smartphone-red-hydrogenone.svg': 'smartphone-red-hydrogenone.svg',
    'smartphone-samsung-galaxy.svg': 'smartphone-samsung-galaxy.svg',
    'smartphone-samsung-omnia.svg': 'smartphone-samsung-omnia.svg',
    'smartphone-samsung-sghi957.svg': 'smartphone-samsung-sghi957.svg',
    'smartphone-sharp-androidone.svg': 'smartphone-sharp-androidone.svg',
    'smartphone-sharp-aquos.svg': 'smartphone-sharp-aquos.svg',
    'smartphone-sharp-basio2.svg': 'smartphone-sharp-basio2.svg',
    'smartphone-sharp-simplesumaho.svg': 'smartphone-sharp-simplesumaho.svg',
    'smartphone-skydevices-elite.svg': 'smartphone-skydevices-elite.svg',
    'smartphone-sonim-xp8.svg': 'smartphone-sonim-xp8.svg',
    'smartphone-sony-xperia.svg': 'smartphone-sony-xperia.svg',
    'smartphone-sony.svg': 'smartphone-sony.svg',
    'smartphone-sprint-slate.svg': 'smartphone-sprint-slate.svg',
    'smartphone-this.svg': 'smartphone-this.svg',
    'smartphone-tmobile-revvl.svg': 'smartphone-tmobile-revvl.svg',
    'smartphone-tmobile-tcl.svg': 'smartphone-tmobile-tcl.svg',
    'smartphone-truconnect-flame.svg': 'smartphone-truconnect-flame.svg',
    'smartphone-ulefone-note9p.svg': 'smartphone-ulefone-note9p.svg',
    'smartphone-umidigi-a7.svg': 'smartphone-umidigi-a7.svg',
    'smartphone-unimax-tracfone.svg': 'smartphone-unimax-tracfone.svg',
    'smartphone-unimax-umx.svg': 'smartphone-unimax-umx.svg',
    'smartphone-vaio-phone.svg': 'smartphone-vaio-phone.svg',
    'smartphone-verizon-orbicwonder.svg': 'smartphone-verizon-orbicwonder.svg',
    'smartphone-vinsmart-v341u.svg': 'smartphone-vinsmart-v341u.svg',
    'smartphone-vivo-iqoo.svg': 'smartphone-vivo-iqoo.svg',
    'smartphone-vivo-phone.svg': 'smartphone-vivo-phone.svg',
    'smartphone-vivo-y93s.svg': 'smartphone-vivo-y93s.svg',
    'smartphone-wiko-life.svg': 'smartphone-wiko-life.svg',
    'smartphone-wiko-smartphone.svg': 'smartphone-wiko-smartphone.svg',
    'smartphone-wiko-view2.svg': 'smartphone-wiko-view2.svg',
    'smartphone-wiko-view3.svg': 'smartphone-wiko-view3.svg',
    'smartphone-wiko-y80.svg': 'smartphone-wiko-y80.svg',
    'smartphone-windowsphone.svg': 'smartphone-windowsphone.svg',
    'smartphone-xiaomi-blackshark.svg': 'smartphone-xiaomi-blackshark.svg',
    'smartphone-xiaomi-mi.svg': 'smartphone-xiaomi-mi.svg',
    'smartphone-xiaomi-mix.svg': 'smartphone-xiaomi-mix.svg',
    'smartphone-xiaomi-poco.svg': 'smartphone-xiaomi-poco.svg',
    'smartphone-xiaomi-pocophone.svg': 'smartphone-xiaomi-pocophone.svg',
    'smartphone-xiaomi-redmi.svg': 'smartphone-xiaomi-redmi.svg',
    'smartphone-xiaomi-smartphone.svg': 'smartphone-xiaomi-smartphone.svg',
    'smartphone-zte-atrium.svg': 'smartphone-zte-atrium.svg',
    'smartphone-zte-axon.svg': 'smartphone-zte-axon.svg',
    'smartphone-zte-blade.svg': 'smartphone-zte-blade.svg',
    'smartphone-zte-cymbal.svg': 'smartphone-zte-cymbal.svg',
    'smartphone-zte-grand.svg': 'smartphone-zte-grand.svg',
    'smartphone-zte-grandx.svg': 'smartphone-zte-grandx.svg',
    'smartphone-zte-lever.svg': 'smartphone-zte-lever.svg',
    'smartphone-zte-libero.svg': 'smartphone-zte-libero.svg',
    'smartphone-zte-mono.svg': 'smartphone-zte-mono.svg',
    'smartphone-zte-qlink.svg': 'smartphone-zte-qlink.svg',
    'smartphone-zte-quest.svg': 'smartphone-zte-quest.svg',
    'smartphone-zte-savvy.svg': 'smartphone-zte-savvy.svg',
    'smartphone-zte-visible.svg': 'smartphone-zte-visible.svg',
    'smartphone-zte-warp.svg': 'smartphone-zte-warp.svg',
    'smartphone-zte-zfive.svg': 'smartphone-zte-zfive.svg',
    'smartphone-zte-zmax.svg': 'smartphone-zte-zmax.svg',
    'smartphone-zte.svg': 'smartphone-zte.svg',
    'smartphone.svg': 'smartphone.svg',
    'smartremote.svg': 'smartremote.svg',
    'smokealarm-googlenest-protect.svg': 'smokealarm-googlenest-protect.svg',
    'smokealarm.svg': 'smokealarm.svg',
    'solar-enphase-envoy.svg': 'solar-enphase-envoy.svg',
    'solar.svg': 'solar.svg',
    'speaker-altice-amplify.svg': 'speaker-altice-amplify.svg',
    'speaker-altice.svg': 'speaker-altice.svg',
    'speaker-amazon-echo.svg': 'speaker-amazon-echo.svg',
    'speaker-amazon-echodot.svg': 'speaker-amazon-echodot.svg',
    'speaker-amazon-echosub.svg': 'speaker-amazon-echosub.svg',
    'speaker-amazon-tap.svg': 'speaker-amazon-tap.svg',
    'speaker-amazon.svg': 'speaker-amazon.svg',
    'speaker-anker-zolomojo.svg': 'speaker-anker-zolomojo.svg',
    'speaker-anker.svg': 'speaker-anker.svg',
    'speaker-apple-homepod.svg': 'speaker-apple-homepod.svg',
    'speaker-apple.svg': 'speaker-apple.svg',
    'speaker-audiocast-wifiaudio.svg': 'speaker-audiocast-wifiaudio.svg',
    'speaker-audiocast.svg': 'speaker-audiocast.svg',
    'speaker-audiopro-addon.svg': 'speaker-audiopro-addon.svg',
    'speaker-audiopro-drumfire.svg': 'speaker-audiopro-drumfire.svg',
    'speaker-audiopro.svg': 'speaker-audiopro.svg',
    'speaker-baidu-1.svg': 'speaker-baidu-1.svg',
    'speaker-baidu.svg': 'speaker-baidu.svg',
    'speaker-bangolufsen-beosound.svg': 'speaker-bangolufsen-beosound.svg',
    'speaker-bangolufson-1.svg': 'speaker-bangolufson-1.svg',
    'speaker-bangolufson-beolit.svg': 'speaker-bangolufson-beolit.svg',
    'speaker-bangolufson-beoplay.svg': 'speaker-bangolufson-beoplay.svg',
    'speaker-bangolufson.svg': 'speaker-bangolufson.svg',
    'speaker-belkin-soundform.svg': 'speaker-belkin-soundform.svg',
    'speaker-belkin.svg': 'speaker-belkin.svg',
    'speaker-blackweb-soundbar.svg': 'speaker-blackweb-soundbar.svg',
    'speaker-blackweb.svg': 'speaker-blackweb.svg',
    'speaker-bluesound-flexmediaplayer.svg':
      'speaker-bluesound-flexmediaplayer.svg',
    'speaker-bluesound-pulsespeaker.svg': 'speaker-bluesound-pulsespeaker.svg',
    'speaker-bluesound-soundbar.svg': 'speaker-bluesound-soundbar.svg',
    'speaker-bluesound.svg': 'speaker-bluesound.svg',
    'speaker-bose-homespeaker.svg': 'speaker-bose-homespeaker.svg',
    'speaker-bose-soundbar.svg': 'speaker-bose-soundbar.svg',
    'speaker-bose-soundlink.svg': 'speaker-bose-soundlink.svg',
    'speaker-bose-soundtouch.svg': 'speaker-bose-soundtouch.svg',
    'speaker-bose-wavesoundtouch.svg': 'speaker-bose-wavesoundtouch.svg',
    'speaker-bose.svg': 'speaker-bose.svg',
    'speaker-bowerswilkins-a7.svg': 'speaker-bowerswilkins-a7.svg',
    'speaker-bowerswilkins-formation.svg':
      'speaker-bowerswilkins-formation.svg',
    'speaker-bowerswilkins-zeppelin.svg': 'speaker-bowerswilkins-zeppelin.svg',
    'speaker-bowerswilkins.svg': 'speaker-bowerswilkins.svg',
    'speaker-cavalier-air.svg': 'speaker-cavalier-air.svg',
    'speaker-cavalier-maverick.svg': 'speaker-cavalier-maverick.svg',
    'speaker-cavalier.svg': 'speaker-cavalier.svg',
    'speaker-clova-virtualassistant.svg': 'speaker-clova-virtualassistant.svg',
    'speaker-clova-wave.svg': 'speaker-clova-wave.svg',
    'speaker-clova.svg': 'speaker-clova.svg',
    'speaker-control4-speakerpoint.svg': 'speaker-control4-speakerpoint.svg',
    'speaker-control4-triad.svg': 'speaker-control4-triad.svg',
    'speaker-control4.svg': 'speaker-control4.svg',
    'speaker-creative-nova.svg': 'speaker-creative-nova.svg',
    'speaker-creative-omni.svg': 'speaker-creative-omni.svg',
    'speaker-creative.svg': 'speaker-creative.svg',
    'speaker-denon-heos.svg': 'speaker-denon-heos.svg',
    'speaker-denon-heos1.svg': 'speaker-denon-heos1.svg',
    'speaker-denon-heosbar.svg': 'speaker-denon-heosbar.svg',
    'speaker-denon-heoshomecinema.svg': 'speaker-denon-heoshomecinema.svg',
    'speaker-denon-heossubwoofer.svg': 'speaker-denon-heossubwoofer.svg',
    'speaker-denon-home.svg': 'speaker-denon-home.svg',
    'speaker-denon.svg': 'speaker-denon.svg',
    'speaker-devialet-goldphantom.svg': 'speaker-devialet-goldphantom.svg',
    'speaker-devialet-phantomreactor.svg':
      'speaker-devialet-phantomreactor.svg',
    'speaker-devialet.svg': 'speaker-devialet.svg',
    'speaker-echo-show.svg': 'speaker-echo-show.svg',
    'speaker-echo-spot.svg': 'speaker-echo-spot.svg',
    'speaker-eufy-genie.svg': 'speaker-eufy-genie.svg',
    'speaker-eufy.svg': 'speaker-eufy.svg',
    'speaker-fabriq-wifispeaker.svg': 'speaker-fabriq-wifispeaker.svg',
    'speaker-fabriq.svg': 'speaker-fabriq.svg',
    'speaker-ggmm-smartspeaker.svg': 'speaker-ggmm-smartspeaker.svg',
    'speaker-ggmm.svg': 'speaker-ggmm.svg',
    'speaker-gohawk-lit.svg': 'speaker-gohawk-lit.svg',
    'speaker-gohawk.svg': 'speaker-gohawk.svg',
    'speaker-google-home.svg': 'speaker-google-home.svg',
    'speaker-google-nestaudio.svg': 'speaker-google-nestaudio.svg',
    'speaker-google.svg': 'speaker-google.svg',
    'speaker-harmankardon-allure.svg': 'speaker-harmankardon-allure.svg',
    'speaker-harmankardon-astra.svg': 'speaker-harmankardon-astra.svg',
    'speaker-harmankardon-aura.svg': 'speaker-harmankardon-aura.svg',
    'speaker-harmankardon-citation.svg': 'speaker-harmankardon-citation.svg',
    'speaker-harmankardon-enchant.svg': 'speaker-harmankardon-enchant.svg',
    'speaker-harmankardon-invokespeaker.svg':
      'speaker-harmankardon-invokespeaker.svg',
    'speaker-harmankardon-omni.svg': 'speaker-harmankardon-omni.svg',
    'speaker-harmankardon.svg': 'speaker-harmankardon.svg',
    'speaker-ilive-platinumconcierge.svg':
      'speaker-ilive-platinumconcierge.svg',
    'speaker-ilive-soundbar.svg': 'speaker-ilive-soundbar.svg',
    'speaker-ilive-undercabinetspeaker.svg':
      'speaker-ilive-undercabinetspeaker.svg',
    'speaker-ilive-wirelessspeaker.svg': 'speaker-ilive-wirelessspeaker.svg',
    'speaker-ilive.svg': 'speaker-ilive.svg',
    'speaker-insignia.svg': 'speaker-insignia.svg',
    'speaker-invoxia-triby.svg': 'speaker-invoxia-triby.svg',
    'speaker-invoxia.svg': 'speaker-invoxia.svg',
    'speaker-jbl-authentics.svg': 'speaker-jbl-authentics.svg',
    'speaker-jbl-bar.svg': 'speaker-jbl-bar.svg',
    'speaker-jbl-playlist.svg': 'speaker-jbl-playlist.svg',
    'speaker-jbl-soundfly.svg': 'speaker-jbl-soundfly.svg',
    'speaker-jbl.svg': 'speaker-jbl.svg',
    'speaker-kef-ls50.svg': 'speaker-kef-ls50.svg',
    'speaker-kef-lsx.svg': 'speaker-kef-lsx.svg',
    'speaker-kef.svg': 'speaker-kef.svg',
    'speaker-lg-soundbar.svg': 'speaker-lg-soundbar.svg',
    'speaker-lg.svg': 'speaker-lg.svg',
    'speaker-logitech-squeezebox.svg': 'speaker-logitech-squeezebox.svg',
    'speaker-logitech-squeezeboxboom.svg':
      'speaker-logitech-squeezeboxboom.svg',
    'speaker-logitech-squeezeboxduet.svg':
      'speaker-logitech-squeezeboxduet.svg',
    'speaker-logitech-squeezeboxradio.svg':
      'speaker-logitech-squeezeboxradio.svg',
    'speaker-logitech-squeezeboxtouch.svg':
      'speaker-logitech-squeezeboxtouch.svg',
    'speaker-logitech-transporter.svg': 'speaker-logitech-transporter.svg',
    'speaker-logitech.svg': 'speaker-logitech.svg',
    'speaker-marantz-pm7000n.svg': 'speaker-marantz-pm7000n.svg',
    'speaker-marantz.svg': 'speaker-marantz.svg',
    'speaker-marshall-acton.svg': 'speaker-marshall-acton.svg',
    'speaker-marshall-stanmore.svg': 'speaker-marshall-stanmore.svg',
    'speaker-marshall-uxbridge.svg': 'speaker-marshall-uxbridge.svg',
    'speaker-marshall.svg': 'speaker-marshall.svg',
    'speaker-naim-muso.svg': 'speaker-naim-muso.svg',
    'speaker-naim-unitinova.svg': 'speaker-naim-unitinova.svg',
    'speaker-naim.svg': 'speaker-naim.svg',
    'speaker-onn-roku.svg': 'speaker-onn-roku.svg',
    'speaker-onn.svg': 'speaker-onn.svg',
    'speaker-panasonic-scga10.svg': 'speaker-panasonic-scga10.svg',
    'speaker-panasonic-schc20.svg': 'speaker-panasonic-schc20.svg',
    'speaker-panasonic-soundbar.svg': 'speaker-panasonic-soundbar.svg',
    'speaker-panasonic.svg': 'speaker-panasonic.svg',
    'speaker-polaroid-pwf1002.svg': 'speaker-polaroid-pwf1002.svg',
    'speaker-polaroid.svg': 'speaker-polaroid.svg',
    'speaker-polkaudio-magnifi.svg': 'speaker-polkaudio-magnifi.svg',
    'speaker-polkaudio-woodbourne.svg': 'speaker-polkaudio-woodbourne.svg',
    'speaker-polkaudio.svg': 'speaker-polkaudio.svg',
    'speaker-riva-festival.svg': 'speaker-riva-festival.svg',
    'speaker-riva.svg': 'speaker-riva.svg',
    'speaker-roberts-stream.svg': 'speaker-roberts-stream.svg',
    'speaker-roberts.svg': 'speaker-roberts.svg',
    'speaker-roku-smart.svg': 'speaker-roku-smart.svg',
    'speaker-roku-streambar.svg': 'speaker-roku-streambar.svg',
    'speaker-roku.svg': 'speaker-roku.svg',
    'speaker-ruark-audio.svg': 'speaker-ruark-audio.svg',
    'speaker-ruark.svg': 'speaker-ruark.svg',
    'speaker-russound-mca66.svg': 'speaker-russound-mca66.svg',
    'speaker-russound-mca88x.svg': 'speaker-russound-mca88x.svg',
    'speaker-russound.svg': 'speaker-russound.svg',
    'speaker-sagemcom-soundbox.svg': 'speaker-sagemcom-soundbox.svg',
    'speaker-sagemcom.svg': 'speaker-sagemcom.svg',
    'speaker-samsung-audiodock.svg': 'speaker-samsung-audiodock.svg',
    'speaker-samsung-soundbar.svg': 'speaker-samsung-soundbar.svg',
    'speaker-samsung-wirelessmultiroomspeaker.svg':
      'speaker-samsung-wirelessmultiroomspeaker.svg',
    'speaker-samsung.svg': 'speaker-samsung.svg',
    'speaker-sharperimage-swf1002.svg': 'speaker-sharperimage-swf1002.svg',
    'speaker-sharperimage-towerspeaker.svg':
      'speaker-sharperimage-towerspeaker.svg',
    'speaker-sharperimage.svg': 'speaker-sharperimage.svg',
    'speaker-sonos-amp.svg': 'speaker-sonos-amp.svg',
    'speaker-sonos-arc.svg': 'speaker-sonos-arc.svg',
    'speaker-sonos-beam.svg': 'speaker-sonos-beam.svg',
    'speaker-sonos-boost.svg': 'speaker-sonos-boost.svg',
    'speaker-sonos-connect.svg': 'speaker-sonos-connect.svg',
    'speaker-sonos-connectamp.svg': 'speaker-sonos-connectamp.svg',
    'speaker-sonos-dock.svg': 'speaker-sonos-dock.svg',
    'speaker-sonos-move.svg': 'speaker-sonos-move.svg',
    'speaker-sonos-one.svg': 'speaker-sonos-one.svg',
    'speaker-sonos-play1.svg': 'speaker-sonos-play1.svg',
    'speaker-sonos-play3.svg': 'speaker-sonos-play3.svg',
    'speaker-sonos-play5.svg': 'speaker-sonos-play5.svg',
    'speaker-sonos-playbar.svg': 'speaker-sonos-playbar.svg',
    'speaker-sonos-playbase.svg': 'speaker-sonos-playbase.svg',
    'speaker-sonos-port.svg': 'speaker-sonos-port.svg',
    'speaker-sonos-soundbar.svg': 'speaker-sonos-soundbar.svg',
    'speaker-sonos-sub.svg': 'speaker-sonos-sub.svg',
    'speaker-sonos-symfoniskwifispeaker.svg':
      'speaker-sonos-symfoniskwifispeaker.svg',
    'speaker-sonos-zoneplayer.svg': 'speaker-sonos-zoneplayer.svg',
    'speaker-sonos.svg': 'speaker-sonos.svg',
    'speaker-sony-smartspeaker.svg': 'speaker-sony-smartspeaker.svg',
    'speaker-sony-soundbar.svg': 'speaker-sony-soundbar.svg',
    'speaker-sony.svg': 'speaker-sony.svg',
    'speaker-tmall.svg': 'speaker-tmall.svg',
    'speaker-tmalll-genie.svg': 'speaker-tmalll-genie.svg',
    'speaker-ultimateears-megablast.svg': 'speaker-ultimateears-megablast.svg',
    'speaker-ultimateears.svg': 'speaker-ultimateears.svg',
    'speaker-varo-cube.svg': 'speaker-varo-cube.svg',
    'speaker-varo-sidekick.svg': 'speaker-varo-sidekick.svg',
    'speaker-varo.svg': 'speaker-varo.svg',
    'speaker-vizio-soundbar.svg': 'speaker-vizio-soundbar.svg',
    'speaker-vizio.svg': 'speaker-vizio.svg',
    'speaker-vodafone-atika.svg': 'speaker-vodafone-atika.svg',
    'speaker-vodafone.svg': 'speaker-vodafone.svg',
    'speaker-yamaha-soundbar.svg': 'speaker-yamaha-soundbar.svg',
    'speaker-yamaha.svg': 'speaker-yamaha.svg',
    'speaker-yoto-player.svg': 'speaker-yoto-player.svg',
    'speaker-yoto.svg': 'speaker-yoto.svg',
    'speaker-zeppelin-wirelessspeaker.svg':
      'speaker-zeppelin-wirelessspeaker.svg',
    'speaker-zeppelin.svg': 'speaker-zeppelin.svg',
    'speaker-zolo.svg': 'speaker-zolo.svg',
    'speaker.svg': 'speaker.svg',
    'sprinkler-blossom.svg': 'sprinkler-blossom.svg',
    'sprinkler-melnor-aquatimer.svg': 'sprinkler-melnor-aquatimer.svg',
    'sprinkler-melnor-raincloud.svg': 'sprinkler-melnor-raincloud.svg',
    'sprinkler-orbitirrigation.svg': 'sprinkler-orbitirrigation.svg',
    'sprinkler-rachio.svg': 'sprinkler-rachio.svg',
    'sprinkler-rainmachine.svg': 'sprinkler-rainmachine.svg',
    'sprinkler-scotts-grocontroller.svg': 'sprinkler-scotts-grocontroller.svg',
    'sprinkler-skydrop-sprinklercontroller.svg':
      'sprinkler-skydrop-sprinklercontroller.svg',
    'sprinkler.svg': 'sprinkler.svg',
    'storage-buffalo-linkstation.svg': 'storage-buffalo-linkstation.svg',
    'storage.svg': 'storage.svg',
    'tablet-aila-sitplay.svg': 'tablet-aila-sitplay.svg',
    'tablet-alcatel-1t.svg': 'tablet-alcatel-1t.svg',
    'tablet-alcatel-joytab.svg': 'tablet-alcatel-joytab.svg',
    'tablet-amazon-fire.svg': 'tablet-amazon-fire.svg',
    'tablet-amazon.svg': 'tablet-amazon.svg',
    'tablet-android.svg': 'tablet-android.svg',
    'tablet-apple-ipad-this.svg': 'tablet-apple-ipad-this.svg',
    'tablet-apple-ipad.svg': 'tablet-apple-ipad.svg',
    'tablet-archos-oxygen.svg': 'tablet-archos-oxygen.svg',
    'tablet-asus-memopad.svg': 'tablet-asus-memopad.svg',
    'tablet-asus-zenpad.svg': 'tablet-asus-zenpad.svg',
    'tablet-azpen-tablet.svg': 'tablet-azpen-tablet.svg',
    'tablet-bdf-phablet.svg': 'tablet-bdf-phablet.svg',
    'tablet-blackberry-playbook.svg': 'tablet-blackberry-playbook.svg',
    'tablet-bosch-ads.svg': 'tablet-bosch-ads.svg',
    'tablet-chuwi-hi9.svg': 'tablet-chuwi-hi9.svg',
    'tablet-control4-touchscreen.svg': 'tablet-control4-touchscreen.svg',
    'tablet-craig-cmp828.svg': 'tablet-craig-cmp828.svg',
    'tablet-delphin-microtablet.svg': 'tablet-delphin-microtablet.svg',
    'tablet-digiland-tablet.svg': 'tablet-digiland-tablet.svg',
    'tablet-ematic-pbsplaytimepad.svg': 'tablet-ematic-pbsplaytimepad.svg',
    'tablet-ematic-tablet.svg': 'tablet-ematic-tablet.svg',
    'tablet-epad-tablet.svg': 'tablet-epad-tablet.svg',
    'tablet-epik-highq.svg': 'tablet-epik-highq.svg',
    'tablet-facebook-portal+.svg': 'tablet-facebook-portal+.svg',
    'tablet-facebook-portal.svg': 'tablet-facebook-portal.svg',
    'tablet-facebook-portalmini.svg': 'tablet-facebook-portalmini.svg',
    'tablet-facebook.svg': 'tablet-facebook.svg',
    'tablet-fujitsu-arrowstab.svg': 'tablet-fujitsu-arrowstab.svg',
    'tablet-google-nexus.svg': 'tablet-google-nexus.svg',
    'tablet-google-pixel.svg': 'tablet-google-pixel.svg',
    'tablet-google.svg': 'tablet-google.svg',
    'tablet-googlenest-hub.svg': 'tablet-googlenest-hub.svg',
    'tablet-hoozo-tablet.svg': 'tablet-hoozo-tablet.svg',
    'tablet-huawei-dtab.svg': 'tablet-huawei-dtab.svg',
    'tablet-huawei-dtabcompact.svg': 'tablet-huawei-dtabcompact.svg',
    'tablet-huawei-mediapad.svg': 'tablet-huawei-mediapad.svg',
    'tablet-huawei-quatab.svg': 'tablet-huawei-quatab.svg',
    'tablet-huawei.svg': 'tablet-huawei.svg',
    'tablet-insignia-flextouchscreentabletlaptop.svg':
      'tablet-insignia-flextouchscreentabletlaptop.svg',
    'tablet-insignia-tablet.svg': 'tablet-insignia-tablet.svg',
    'tablet-jty-tablet.svg': 'tablet-jty-tablet.svg',
    'tablet-kte-tyd108h.svg': 'tablet-kte-tyd108h.svg',
    'tablet-kurio-next.svg': 'tablet-kurio-next.svg',
    'tablet-kyocera-quatab.svg': 'tablet-kyocera-quatab.svg',
    'tablet-kyocera-szj.svg': 'tablet-kyocera-szj.svg',
    'tablet-leapfrog-epic.svg': 'tablet-leapfrog-epic.svg',
    'tablet-leapfrog-leappad.svg': 'tablet-leapfrog-leappad.svg',
    'tablet-lenovo-everypad.svg': 'tablet-lenovo-everypad.svg',
    'tablet-lenovo-tab3.svg': 'tablet-lenovo-tab3.svg',
    'tablet-lenovo-tab4.svg': 'tablet-lenovo-tab4.svg',
    'tablet-lenovo-tablet.svg': 'tablet-lenovo-tablet.svg',
    'tablet-lenovo-thinkpad.svg': 'tablet-lenovo-thinkpad.svg',
    'tablet-lg-gpad.svg': 'tablet-lg-gpad.svg',
    'tablet-maitai-mt107.svg': 'tablet-maitai-mt107.svg',
    'tablet-manjee-mj108.svg': 'tablet-manjee-mj108.svg',
    'tablet-mediacom-smartpad.svg': 'tablet-mediacom-smartpad.svg',
    'tablet-mediatek-k960.svg': 'tablet-mediatek-k960.svg',
    'tablet-mediatek-t906.svg': 'tablet-mediatek-t906.svg',
    'tablet-mediatek-zh960.svg': 'tablet-mediatek-zh960.svg',
    'tablet-medion-lifetab.svg': 'tablet-medion-lifetab.svg',
    'tablet-microsoft-surface.svg': 'tablet-microsoft-surface.svg',
    'tablet-nabi-tablet-1.svg': 'tablet-nabi-tablet-1.svg',
    'tablet-nabi-tablet.svg': 'tablet-nabi-tablet.svg',
    'tablet-naxa-nid1050.svg': 'tablet-naxa-nid1050.svg',
    'tablet-nec-lavietab.svg': 'tablet-nec-lavietab.svg',
    'tablet-nextbook-ares.svg': 'tablet-nextbook-ares.svg',
    'tablet-nextbook.svg': 'tablet-nextbook.svg',
    'tablet-nordictrack-portal.svg': 'tablet-nordictrack-portal.svg',
    'tablet-onn-surftablet.svg': 'tablet-onn-surftablet.svg',
    'tablet-onn-tablet.svg': 'tablet-onn-tablet.svg',
    'tablet-pantech.svg': 'tablet-pantech.svg',
    'tablet-pbskids-playtime.svg': 'tablet-pbskids-playtime.svg',
    'tablet-phablet-this.svg': 'tablet-phablet-this.svg',
    'tablet-phablet.svg': 'tablet-phablet.svg',
    'tablet-pipo-tablet.svg': 'tablet-pipo-tablet.svg',
    'tablet-qere-qr8.svg': 'tablet-qere-qr8.svg',
    'tablet-qilive-tablet.svg': 'tablet-qilive-tablet.svg',
    'tablet-rca-pro12.svg': 'tablet-rca-pro12.svg',
    'tablet-rca-tablet.svg': 'tablet-rca-tablet.svg',
    'tablet-remarkable-papertablet.svg': 'tablet-remarkable-papertablet.svg',
    'tablet-samsung-galaxy.svg': 'tablet-samsung-galaxy.svg',
    'tablet-samsung-galaxybook.svg': 'tablet-samsung-galaxybook.svg',
    'tablet-samsung-galaxytab.svg': 'tablet-samsung-galaxytab.svg',
    'tablet-samsung-galaxyview.svg': 'tablet-samsung-galaxyview.svg',
    'tablet-samsung-gizmotablet.svg': 'tablet-samsung-gizmotablet.svg',
    'tablet-sanyo-benesse.svg': 'tablet-sanyo-benesse.svg',
    'tablet-sharp-aquospad.svg': 'tablet-sharp-aquospad.svg',
    'tablet-sharp-galapagos.svg': 'tablet-sharp-galapagos.svg',
    'tablet-shenzhen-tablet.svg': 'tablet-shenzhen-tablet.svg',
    'tablet-slate-8.svg': 'tablet-slate-8.svg',
    'tablet-smartab-st1009.svg': 'tablet-smartab-st1009.svg',
    'tablet-smartak-tab910.svg': 'tablet-smartak-tab910.svg',
    'tablet-softwinerevb-tablet.svg': 'tablet-softwinerevb-tablet.svg',
    'tablet-sony-xperia.svg': 'tablet-sony-xperia.svg',
    'tablet-sproutchannel-cubby.svg': 'tablet-sproutchannel-cubby.svg',
    'tablet-sylvania-sltdvd1023.svg': 'tablet-sylvania-sltdvd1023.svg',
    'tablet-tagital-t10k.svg': 'tablet-tagital-t10k.svg',
    'tablet-this.svg': 'tablet-this.svg',
    'tablet-trimble-t10.svg': 'tablet-trimble-t10.svg',
    'tablet-verizon-ellipsis.svg': 'tablet-verizon-ellipsis.svg',
    'tablet-verizon-gizmotab.svg': 'tablet-verizon-gizmotab.svg',
    'tablet-visualland-prestige.svg': 'tablet-visualland-prestige.svg',
    'tablet-vizio.svg': 'tablet-vizio.svg',
    'tablet-xiaomi-mipad.svg': 'tablet-xiaomi-mipad.svg',
    'tablet-yuntab-k107.svg': 'tablet-yuntab-k107.svg',
    'tablet-zonko-k106.svg': 'tablet-zonko-k106.svg',
    'tablet-zte-grandx.svg': 'tablet-zte-grandx.svg',
    'tablet-zte-trek.svg': 'tablet-zte-trek.svg',
    'tablet-zte-zpad.svg': 'tablet-zte-zpad.svg',
    'tablet.svg': 'tablet.svg',
    'thermostat-aprilaire-thermostat.svg':
      'thermostat-aprilaire-thermostat.svg',
    'thermostat-aprilaire.svg': 'thermostat-aprilaire.svg',
    'thermostat-bosch-bcc100.svg': 'thermostat-bosch-bcc100.svg',
    'thermostat-bosch-bcc50.svg': 'thermostat-bosch-bcc50.svg',
    'thermostat-bticino-smarther.svg': 'thermostat-bticino-smarther.svg',
    'thermostat-danfosslink.svg': 'thermostat-danfosslink.svg',
    'thermostat-ecobee.svg': 'thermostat-ecobee.svg',
    'thermostat-eneco-toonthermostat.svg':
      'thermostat-eneco-toonthermostat.svg',
    'thermostat-engie-boxx.svg': 'thermostat-engie-boxx.svg',
    'thermostat-flair-puck.svg': 'thermostat-flair-puck.svg',
    'thermostat-googlenest.svg': 'thermostat-googlenest.svg',
    'thermostat-honeywell.svg': 'thermostat-honeywell.svg',
    'thermostat-idevices.svg': 'thermostat-idevices.svg',
    'thermostat-johnsoncontrols-glas.svg':
      'thermostat-johnsoncontrols-glas.svg',
    'thermostat-lux-konosmartthermostat.svg':
      'thermostat-lux-konosmartthermostat.svg',
    'thermostat-mysa.svg': 'thermostat-mysa.svg',
    'thermostat-netatmo-smartthermostat.svg':
      'thermostat-netatmo-smartthermostat.svg',
    'thermostat-nvent-nuheat.svg': 'thermostat-nvent-nuheat.svg',
    'thermostat-pro1-wifithermostat.svg': 'thermostat-pro1-wifithermostat.svg',
    'thermostat-radio.svg': 'thermostat-radio.svg',
    'thermostat-sensi.svg': 'thermostat-sensi.svg',
    'thermostat-trane.svg': 'thermostat-trane.svg',
    'thermostat-wyze-thermostat.svg': 'thermostat-wyze-thermostat.svg',
    'thermostat.svg': 'thermostat.svg',
    'toy.svg': 'toy.svg',
    'tv-akai-tv.svg': 'tv-akai-tv.svg',
    'tv-aldinord-smarttv.svg': 'tv-aldinord-smarttv.svg',
    'tv-amazon-fire.svg': 'tv-amazon-fire.svg',
    'tv-amazon-firetv.svg': 'tv-amazon-firetv.svg',
    'tv-argos-bushtv.svg': 'tv-argos-bushtv.svg',
    'tv-atron-tv.svg': 'tv-atron-tv.svg',
    'tv-bangolufsen-beoplay.svg': 'tv-bangolufsen-beoplay.svg',
    'tv-bangolufsen-beovision.svg': 'tv-bangolufsen-beovision.svg',
    'tv-digihome-smarttv.svg': 'tv-digihome-smarttv.svg',
    'tv-durabase-smarttv.svg': 'tv-durabase-smarttv.svg',
    'tv-edenwood-smarttv.svg': 'tv-edenwood-smarttv.svg',
    'tv-electronia-smarttv.svg': 'tv-electronia-smarttv.svg',
    'tv-element-rokutv.svg': 'tv-element-rokutv.svg',
    'tv-finlux-smarttvv.svg': 'tv-finlux-smarttvv.svg',
    'tv-fu-smarttv.svg': 'tv-fu-smarttv.svg',
    'tv-funai-tv.svg': 'tv-funai-tv.svg',
    'tv-grundig-firetv.svg': 'tv-grundig-firetv.svg',
    'tv-grundig-vision6.svg': 'tv-grundig-vision6.svg',
    'tv-grundig-vision7.svg': 'tv-grundig-vision7.svg',
    'tv-haier-smarttv.svg': 'tv-haier-smarttv.svg',
    'tv-hanseatic-smarttv.svg': 'tv-hanseatic-smarttv.svg',
    'tv-highone-smarttv.svg': 'tv-highone-smarttv.svg',
    'tv-hisense-4kuhdtv.svg': 'tv-hisense-4kuhdtv.svg',
    'tv-hisense-rokutv.svg': 'tv-hisense-rokutv.svg',
    'tv-hisense.svg': 'tv-hisense.svg',
    'tv-hitachi-rokutv.svg': 'tv-hitachi-rokutv.svg',
    'tv-hitachi-smarttv.svg': 'tv-hitachi-smarttv.svg',
    'tv-hyundai-smarttv.svg': 'tv-hyundai-smarttv.svg',
    'tv-insignia-rokutv.svg': 'tv-insignia-rokutv.svg',
    'tv-jvc-firetv.svg': 'tv-jvc-firetv.svg',
    'tv-jvc-smartdevice.svg': 'tv-jvc-smartdevice.svg',
    'tv-jvc-smarttv.svg': 'tv-jvc-smarttv.svg',
    'tv-kendo-smarttv.svg': 'tv-kendo-smarttv.svg',
    'tv-kubo-smarttv.svg': 'tv-kubo-smarttv.svg',
    'tv-kydos-smarttv.svg': 'tv-kydos-smarttv.svg',
    'tv-leapfrog-leaptv.svg': 'tv-leapfrog-leaptv.svg',
    'tv-leeco-letv.svg': 'tv-leeco-letv.svg',
    'tv-lg-rokutv.svg': 'tv-lg-rokutv.svg',
    'tv-lg-smarttv.svg': 'tv-lg-smarttv.svg',
    'tv-lg-webostv.svg': 'tv-lg-webostv.svg',
    'tv-lg.svg': 'tv-lg.svg',
    'tv-linsar-smarttv.svg': 'tv-linsar-smarttv.svg',
    'tv-logic-smarttv.svg': 'tv-logic-smarttv.svg',
    'tv-luxor-smarttv.svg': 'tv-luxor-smarttv.svg',
    'tv-m8s-androidtvbox.svg': 'tv-m8s-androidtvbox.svg',
    'tv-magnavox-rokutv.svg': 'tv-magnavox-rokutv.svg',
    'tv-medion-life.svg': 'tv-medion-life.svg',
    'tv-medion-smarttv.svg': 'tv-medion-smarttv.svg',
    'tv-mitchellbrown-smarttv.svg': 'tv-mitchellbrown-smarttv.svg',
    'tv-netgem.svg': 'tv-netgem.svg',
    'tv-onida-firetv.svg': 'tv-onida-firetv.svg',
    'tv-onn-rokutv.svg': 'tv-onn-rokutv.svg',
    'tv-orima-smarttv.svg': 'tv-orima-smarttv.svg',
    'tv-panasonic-portabletv.svg': 'tv-panasonic-portabletv.svg',
    'tv-panasonic-tv.svg': 'tv-panasonic-tv.svg',
    'tv-philips-rokutv.svg': 'tv-philips-rokutv.svg',
    'tv-philips-smarttv.svg': 'tv-philips-smarttv.svg',
    'tv-philips-tv.svg': 'tv-philips-tv.svg',
    'tv-polaroid-ledtv.svg': 'tv-polaroid-ledtv.svg',
    'tv-polaroid-smarttv.svg': 'tv-polaroid-smarttv.svg',
    'tv-qilive-tv.svg': 'tv-qilive-tv.svg',
    'tv-roku-tv.svg': 'tv-roku-tv.svg',
    'tv-saba-smarttv.svg': 'tv-saba-smarttv.svg',
    'tv-salora-ledtv.svg': 'tv-salora-ledtv.svg',
    'tv-samsung-q80.svg': 'tv-samsung-q80.svg',
    'tv-samsung.svg': 'tv-samsung.svg',
    'tv-sanyo-rokutv.svg': 'tv-sanyo-rokutv.svg',
    'tv-selecline-smarttv.svg': 'tv-selecline-smarttv.svg',
    'tv-sharp-aquos.svg': 'tv-sharp-aquos.svg',
    'tv-sharp-aquostv.svg': 'tv-sharp-aquostv.svg',
    'tv-sharp-rokutv.svg': 'tv-sharp-rokutv.svg',
    'tv-sharp.svg': 'tv-sharp.svg',
    'tv-sony-bravia4ktv.svg': 'tv-sony-bravia4ktv.svg',
    'tv-sony-braviatv.svg': 'tv-sony-braviatv.svg',
    'tv-sony.svg': 'tv-sony.svg',
    'tv-tcl-5series.svg': 'tv-tcl-5series.svg',
    'tv-tcl-rokutv.svg': 'tv-tcl-rokutv.svg',
    'tv-tcl-uniontv.svg': 'tv-tcl-uniontv.svg',
    'tv-telefunken-smarttv.svg': 'tv-telefunken-smarttv.svg',
    'tv-toshiba-4k.svg': 'tv-toshiba-4k.svg',
    'tv-toshiba-chromecasttv.svg': 'tv-toshiba-chromecasttv.svg',
    'tv-toshiba-firetv.svg': 'tv-toshiba-firetv.svg',
    'tv-toshiba-regzalcdtv.svg': 'tv-toshiba-regzalcdtv.svg',
    'tv-toshiba-smarttv.svg': 'tv-toshiba-smarttv.svg',
    'tv-vizio-smartcast.svg': 'tv-vizio-smartcast.svg',
    'tv-vizio-smarttv.svg': 'tv-vizio-smarttv.svg',
    'tv-vox-smarttv.svg': 'tv-vox-smarttv.svg',
    'tv-westwood-smarttv.svg': 'tv-westwood-smarttv.svg',
    'tv-xiaomi-mitv.svg': 'tv-xiaomi-mitv.svg',
    'tv.svg': 'tv.svg',
    'unknown.svg': 'unknown.svg',
    'vacuum-1.svg': 'vacuum-1.svg',
    'vacuum-ecovacs-deebot.svg': 'vacuum-ecovacs-deebot.svg',
    'vacuum-eufy-robovac.svg': 'vacuum-eufy-robovac.svg',
    'vacuum-irobot-roomba.svg': 'vacuum-irobot-roomba.svg',
    'vacuum.svg': 'vacuum.svg',
    'virtualreality-microsoft-hololens.svg':
      'virtualreality-microsoft-hololens.svg',
    'virtualreality-oculus-go.svg': 'virtualreality-oculus-go.svg',
    'virtualreality-oculus-quest.svg': 'virtualreality-oculus-quest.svg',
    'virtualreality-oculus.svg': 'virtualreality-oculus.svg',
    'virtualreality-varjo-vr1.svg': 'virtualreality-varjo-vr1.svg',
    'virtualreality.svg': 'virtualreality.svg',
    'visitormanagement-envoyvisitors.svg':
      'visitormanagement-envoyvisitors.svg',
    'visitormanagement.svg': 'visitormanagement.svg',
    'voiceassistant-amazon-dashwand.svg': 'voiceassistant-amazon-dashwand.svg',
    'voiceassistant-amazon-echo.svg': 'voiceassistant-amazon-echo.svg',
    'voiceassistant-amazon-echoflex.svg': 'voiceassistant-amazon-echoflex.svg',
    'voiceassistant-amazon-echoinput.svg':
      'voiceassistant-amazon-echoinput.svg',
    'voiceassistant-amazon-echoplus.svg': 'voiceassistant-amazon-echoplus.svg',
    'voiceassistant-amazon-echoshow.svg': 'voiceassistant-amazon-echoshow.svg',
    'voiceassistant-amazon-echospot.svg': 'voiceassistant-amazon-echospot.svg',
    'voiceassistant-amazon-echostudio.svg':
      'voiceassistant-amazon-echostudio.svg',
    'voiceassistant-clova-desk.svg': 'voiceassistant-clova-desk.svg',
    'voiceassistant-google-nest.svg': 'voiceassistant-google-nest.svg',
    'voiceassistant-jbl-linkspeaker.svg': 'voiceassistant-jbl-linkspeaker.svg',
    'voiceassistant-samsung-galaxyhome.svg':
      'voiceassistant-samsung-galaxyhome.svg',
    'voiceassistant.svg': 'voiceassistant.svg',
    'voipgateway-amazon-echoconnect.svg': 'voipgateway-amazon-echoconnect.svg',
    'voipgateway-audiocodes-mediapack.svg':
      'voipgateway-audiocodes-mediapack.svg',
    'voipgateway-avaya-ipphone.svg': 'voipgateway-avaya-ipphone.svg',
    'voipgateway-cisco-selsius.svg': 'voipgateway-cisco-selsius.svg',
    'voipgateway-cisco-sipuravoipgateway.svg':
      'voipgateway-cisco-sipuravoipgateway.svg',
    'voipgateway-cisco-spa122.svg': 'voipgateway-cisco-spa122.svg',
    'voipgateway-cisco-spa122ata.svg': 'voipgateway-cisco-spa122ata.svg',
    'voipgateway-gigaset-dect.svg': 'voipgateway-gigaset-dect.svg',
    'voipgateway-google-fiber.svg': 'voipgateway-google-fiber.svg',
    'voipgateway-grandstream-analogtelephoneadapter.svg':
      'voipgateway-grandstream-analogtelephoneadapter.svg',
    'voipgateway-grandstream-voipphone.svg':
      'voipgateway-grandstream-voipphone.svg',
    'voipgateway-linksys-pap2t.svg': 'voipgateway-linksys-pap2t.svg',
    'voipgateway-magicjack-home.svg': 'voipgateway-magicjack-home.svg',
    'voipgateway-mediatrix.svg': 'voipgateway-mediatrix.svg',
    'voipgateway-mitel-aastra.svg': 'voipgateway-mitel-aastra.svg',
    'voipgateway-mitel-sipphone-1.svg': 'voipgateway-mitel-sipphone-1.svg',
    'voipgateway-mitel-sipphone.svg': 'voipgateway-mitel-sipphone.svg',
    'voipgateway-nettalk-voipgateway.svg':
      'voipgateway-nettalk-voipgateway.svg',
    'voipgateway-obihai-obi202.svg': 'voipgateway-obihai-obi202.svg',
    'voipgateway-ooma-telo.svg': 'voipgateway-ooma-telo.svg',
    'voipgateway-panasonic-kxtgp600.svg': 'voipgateway-panasonic-kxtgp600.svg',
    'voipgateway-panasonic-kxut123.svg': 'voipgateway-panasonic-kxut123.svg',
    'voipgateway-panasonic-phone.svg': 'voipgateway-panasonic-phone.svg',
    'voipgateway-polycom-obi200telephoneadapter.svg':
      'voipgateway-polycom-obi200telephoneadapter.svg',
    'voipgateway-polycom-telephoneadapter.svg':
      'voipgateway-polycom-telephoneadapter.svg',
    'voipgateway-polycom-voipphone.svg': 'voipgateway-polycom-voipphone.svg',
    'voipgateway-polycom-vvx.svg': 'voipgateway-polycom-vvx.svg',
    'voipgateway-sangoma-ipphone-1.svg': 'voipgateway-sangoma-ipphone-1.svg',
    'voipgateway-sangoma-ipphone.svg': 'voipgateway-sangoma-ipphone.svg',
    'voipgateway-tmobile-linelink.svg': 'voipgateway-tmobile-linelink.svg',
    'voipgateway-vonage-telephoneadapter.svg':
      'voipgateway-vonage-telephoneadapter.svg',
    'voipgateway-vonage-vonage.svg': 'voipgateway-vonage-vonage.svg',
    'voipgateway-vtech-et635.svg': 'voipgateway-vtech-et635.svg',
    'voipgateway-yealink-ipphone.svg': 'voipgateway-yealink-ipphone.svg',
    'voipgateway-yealink-videophone.svg': 'voipgateway-yealink-videophone.svg',
    'voipgateway-yealink-w52p.svg': 'voipgateway-yealink-w52p.svg',
    'voipgateway-yealink-w60b.svg': 'voipgateway-yealink-w60b.svg',
    'voipgateway.svg': 'voipgateway.svg',
    'washerdryer-bosch-dryer.svg': 'washerdryer-bosch-dryer.svg',
    'washerdryer-ge-dryer.svg': 'washerdryer-ge-dryer.svg',
    'washerdryer-lg-dryer.svg': 'washerdryer-lg-dryer.svg',
    'washerdryer-lg-washer.svg': 'washerdryer-lg-washer.svg',
    'washerdryer-miele-washer.svg': 'washerdryer-miele-washer.svg',
    'washerdryer-samsung-dryer.svg': 'washerdryer-samsung-dryer.svg',
    'washerdryer-samsung-washer.svg': 'washerdryer-samsung-washer.svg',
    'washerdryer-siemens-heatpumpdryer.svg':
      'washerdryer-siemens-heatpumpdryer.svg',
    'washerdryer-siemens-washer.svg': 'washerdryer-siemens-washer.svg',
    'washerdryer-siemens-washerdryer.svg':
      'washerdryer-siemens-washerdryer.svg',
    'washerdryer-vzug-adorawash.svg': 'washerdryer-vzug-adorawash.svg',
    'washerdryer-vzug-dryer.svg': 'washerdryer-vzug-dryer.svg',
    'washerdryer-vzug-washingmachine.svg':
      'washerdryer-vzug-washingmachine.svg',
    'washerdryer-whirlpool-dryer.svg': 'washerdryer-whirlpool-dryer.svg',
    'washerdryer-whirlpool-washer.svg': 'washerdryer-whirlpool-washer.svg',
    'washerdryer.svg': 'washerdryer.svg',
    'watch-amazfit-pace.svg': 'watch-amazfit-pace.svg',
    'watch-amazfit-stratos.svg': 'watch-amazfit-stratos.svg',
    'watch-amazfit-watch.svg': 'watch-amazfit-watch.svg',
    'watch-apple-watch.svg': 'watch-apple-watch.svg',
    'watch-doki-watch.svg': 'watch-doki-watch.svg',
    'watch-fitbit-sense.svg': 'watch-fitbit-sense.svg',
    'watch-littlegenius-z5h.svg': 'watch-littlegenius-z5h.svg',
    'watch-mobvoi-ticwatch.svg': 'watch-mobvoi-ticwatch.svg',
    'watch-motorola-moto.svg': 'watch-motorola-moto.svg',
    'watch-oppo-watch.svg': 'watch-oppo-watch.svg',
    'watch-puma-smartwatch.svg': 'watch-puma-smartwatch.svg',
    'watch-samsung-galaxy.svg': 'watch-samsung-galaxy.svg',
    'watch-samsung-gear.svg': 'watch-samsung-gear.svg',
    'watch-ticktalk-3.svg': 'watch-ticktalk-3.svg',
    'watch-xplora-x4.svg': 'watch-xplora-x4.svg',
    'watch.svg': 'watch.svg',
    'waterheater-1.svg': 'waterheater-1.svg',
    'waterheater-aquanta-waterheatercontroller.svg':
      'waterheater-aquanta-waterheatercontroller.svg',
    'waterheater-navien-navilink.svg': 'waterheater-navien-navilink.svg',
    'waterheater-nti-boiler.svg': 'waterheater-nti-boiler.svg',
    'waterheater-rheem-econetwaterheater.svg':
      'waterheater-rheem-econetwaterheater.svg',
    'waterheater-rinnai-waterheater.svg': 'waterheater-rinnai-waterheater.svg',
    'waterheater.svg': 'waterheater.svg',
    'wearable-brightsign-glove.svg': 'wearable-brightsign-glove.svg',
    'wearable-fitbit-ace.svg': 'wearable-fitbit-ace.svg',
    'wearable-fitbit-charge.svg': 'wearable-fitbit-charge.svg',
    'wearable-fitbit-inspire.svg': 'wearable-fitbit-inspire.svg',
    'wearable-fitbit-ionic.svg': 'wearable-fitbit-ionic.svg',
    'wearable-fitbit-versa.svg': 'wearable-fitbit-versa.svg',
    'wearable-fitbit.svg': 'wearable-fitbit.svg',
    'wearable-fossil-q.svg': 'wearable-fossil-q.svg',
    'wearable-fossil-sport.svg': 'wearable-fossil-sport.svg',
    'wearable-garmin-forerunner.svg': 'wearable-garmin-forerunner.svg',
    'wearable-garmin-vivoactive-1.svg': 'wearable-garmin-vivoactive-1.svg',
    'wearable-garmin-vivoactive.svg': 'wearable-garmin-vivoactive.svg',
    'wearable-garmin-vivofit.svg': 'wearable-garmin-vivofit.svg',
    'wearable-garmin-vivomove.svg': 'wearable-garmin-vivomove.svg',
    'wearable-garmin-vivosmart.svg': 'wearable-garmin-vivosmart.svg',
    'wearable-garmin-vivosport.svg': 'wearable-garmin-vivosport.svg',
    'wearable-garmin.svg': 'wearable-garmin.svg',
    'wearable-huawei.svg': 'wearable-huawei.svg',
    'wearable-neebo-monitor.svg': 'wearable-neebo-monitor.svg',
    'wearable-whistle-fit.svg': 'wearable-whistle-fit.svg',
    'wearable.svg': 'wearable.svg',
    'weathermonitor-acurite-weatherstation.svg':
      'weathermonitor-acurite-weatherstation.svg',
    'weathermonitor-ambient.svg': 'weathermonitor-ambient.svg',
    'weathermonitor-bloomsky-storm.svg': 'weathermonitor-bloomsky-storm.svg',
    'weathermonitor-bloomsky-weatherstation.svg':
      'weathermonitor-bloomsky-weatherstation.svg',
    'weathermonitor-bloomsky.svg': 'weathermonitor-bloomsky.svg',
    'weathermonitor-davisinstruments-weatherlinklive.svg':
      'weathermonitor-davisinstruments-weatherlinklive.svg',
    'weathermonitor-lacrosse-weatherstation.svg':
      'weathermonitor-lacrosse-weatherstation.svg',
    'weathermonitor-meteobridge-weatherstation.svg':
      'weathermonitor-meteobridge-weatherstation.svg',
    'weathermonitor-netatmo-personalweatherstation.svg':
      'weathermonitor-netatmo-personalweatherstation.svg',
    'weathermonitor-onelink-environmentmonitor.svg':
      'weathermonitor-onelink-environmentmonitor.svg',
    'weathermonitor-weatherflow.svg': 'weathermonitor-weatherflow.svg',
    'weathermonitor.svg': 'weathermonitor.svg',
    'wifitracker-bosch-nyon.svg': 'wifitracker-bosch-nyon.svg',
    'wifitracker-garmin-alpha.svg': 'wifitracker-garmin-alpha.svg',
    'wifitracker-garmin-dezl.svg': 'wifitracker-garmin-dezl.svg',
    'wifitracker-garmin-edge.svg': 'wifitracker-garmin-edge.svg',
    'wifitracker-garmin-gpsmap.svg': 'wifitracker-garmin-gpsmap.svg',
    'wifitracker-garmin-montana.svg': 'wifitracker-garmin-montana.svg',
    'wifitracker-garmin-overlander.svg': 'wifitracker-garmin-overlander.svg',
    'wifitracker-hammerhead-karoo.svg': 'wifitracker-hammerhead-karoo.svg',
    'wifitracker-jiobit-tracker.svg': 'wifitracker-jiobit-tracker.svg',
    'wifitracker-jiobit-wifitracker.svg': 'wifitracker-jiobit-wifitracker.svg',
    'wifitracker-pioneer-sgxca600.svg': 'wifitracker-pioneer-sgxca600.svg',
    'wifitracker-randmcnally-tnd.svg': 'wifitracker-randmcnally-tnd.svg',
    'wifitracker-samsung-connecttag.svg': 'wifitracker-samsung-connecttag.svg',
    'wifitracker-samsung.svg': 'wifitracker-samsung.svg',
    'wifitracker-tomtom-gps.svg': 'wifitracker-tomtom-gps.svg',
    'wifitracker-wahoo-elemnt.svg': 'wifitracker-wahoo-elemnt.svg',
    'wifitracker-whistle-go.svg': 'wifitracker-whistle-go.svg',
    'wifitracker.svg': 'wifitracker.svg',
  },
};
