import React, { FunctionComponent, PropsWithChildren } from 'react';

import OnboardingHeader from 'UI/Components/Headers/Header onboarding';

import Card from 'UI/Elements/Card';

import styles from './style.module.css';

type OnboardingPageWrapperProps = {
  label: string;
  subLabel: string;
};

const OnboardingPageWrapper: FunctionComponent<
  PropsWithChildren<OnboardingPageWrapperProps>
> = ({ children, label, subLabel }) => (
  <div className={styles.onboardingWrapper} role="main">
    <Card className={styles.onboardingCard} noBottomPadding>
      <OnboardingHeader label={label} subLabel={subLabel} />
      <div className={styles.onboardingContent}>{children}</div>
    </Card>
  </div>
);

const OnboardingPageTopContent = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className={styles.topContent}>{children}</div>;

const OnboardingPageBottomContent = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className={styles.bottomContent}>{children}</div>;

export {
  OnboardingPageBottomContent,
  OnboardingPageTopContent,
  OnboardingPageWrapper,
};
