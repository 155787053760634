import React from 'react';
import { TooltipProps } from 'recharts';
import Tooltip from 'UI/Elements/Tooltip';
import { Body2 } from 'UI/Elements/Typography';
import colorStyles from 'Styles/colors.module.css';
import i18n from '../../../../../i18n';

const onlineActivityGraphTooltip = () => {
  return ({ label, payload }: TooltipProps<string, string>) => {
    const values = payload?.map((p) => p.value);
    const value = Math.round(
      values?.reduce((acc, v) => acc + Number(v), 0) ?? 0
    );
    const unit = i18n.t('common.min');
    return (
      <Tooltip>
        <Body2>
          {value} {unit}
        </Body2>
        <Body2 className={colorStyles.still400}>{label}</Body2>
      </Tooltip>
    );
  };
};

export default onlineActivityGraphTooltip;
