import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'UI/Elements/Card';

import useDefaultDailyDataUsage from 'State/hooks/useDefaultDailyDataUsage';
import useDefaultMonthlyDataUsage from 'State/hooks/useDefaultMonthlyDataUsage';
import DailyDataUsageGraphUI from '../Data usage';

type SecureDataUsageProps = {
  span: 1 | 2 | 3;
};

const SecureDataUsage: FunctionComponent<SecureDataUsageProps> = ({ span }) => {
  const { t } = useTranslation();
  const dailyData = useDefaultDailyDataUsage();
  const monthlyData = useDefaultMonthlyDataUsage();
  const isLoading =
    (dailyData.isLoading && !dailyData.data) ||
    (monthlyData.isLoading && !monthlyData.data);
  const errorMessage = dailyData.errorMessage || monthlyData.errorMessage;

  if (isLoading || errorMessage) {
    return (
      <Card
        isLoading={isLoading}
        errorMessage={errorMessage}
        header={{
          L2Props: { label: t('common.dataUsage') },
        }}
      />
    );
  }

  return (
    <DailyDataUsageGraphUI
      span={span === 1 ? undefined : 2}
      daily={dailyData.data}
      monthly={monthlyData.data}
    />
  );
};

export default SecureDataUsage;
