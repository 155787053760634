import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon, { Props as IconProps } from 'UI/Elements/Icon';

import styles from './style.module.css';

export type L1Props = {
  iconProps?: IconProps;
  childComponent?: JSX.Element;
};

const L1: FunctionComponent<L1Props> = ({ iconProps, childComponent }) => {
  if (childComponent) {
    return <>{childComponent}</>;
  }
  if (!iconProps?.name) {
    return null;
  }

  return <Icon {...iconProps} className={cn(styles.l1, iconProps.className)} />;
};

export default L1;
