import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ZoneDevice } from 'Consts/types';

import { MenuItemProps } from 'UI/Components/Menu';

import DevicesCard from '../Devices card';
import { View } from '../types';

import styles from './style.module.css';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import cn from 'classnames';

const BELL = 'bell';

type UnapprovedDevicesCardProps = {
  isLoading?: boolean;
  errorMessage?: string;
  columnSpan: 1 | 2 | 3;
  itemLimitForOneColumn?: number | null;
  devices?: ZoneDevice[];
  headerMenuItems: MenuItemProps[];
  menuItems: MenuItemProps[];
  infoTooltipLabel: string;
  onViewAllClick: (view: View) => void;
};

const UnapprovedDevicesCard: FunctionComponent<UnapprovedDevicesCardProps> = ({
  isLoading,
  errorMessage,
  columnSpan,
  itemLimitForOneColumn = null,
  devices = [],
  headerMenuItems,
  menuItems,
  infoTooltipLabel,
  onViewAllClick,
}) => {
  const { t } = useTranslation();
  const handleUnapprovedDevicesCardViewAllClick = useCallback(() => {
    onViewAllClick('unapproved');
  }, [onViewAllClick]);

  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);

  return (
    <DevicesCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      devices={devices}
      title={t('devices.unapprovedDevices')}
      columnSpan={columnSpan}
      itemLimitForOneColumn={itemLimitForOneColumn}
      headerMenuItems={headerMenuItems}
      menuItems={menuItems}
      className={cn(styles.warmCardBackground, {
        [styles.bellCardBackground]: isBell,
      })}
      footerClassName={cn(styles.warmCardFooterBackground, {
        [styles.bellCardFooterBackground]: isBell,
      })}
      onViewAllClick={handleUnapprovedDevicesCardViewAllClick}
      infoTooltipLabel={infoTooltipLabel}
    />
  );
};

export default UnapprovedDevicesCard;
