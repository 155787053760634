import cn from 'classnames';
import { ZoneDevice } from 'Consts/types';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import StandardListItem from 'UI/Components/Lists/List standard';
import Card from 'UI/Elements/Card';
import { makeTextExcerpt } from 'Utils/formatters/string';
import styles from '../style.module.css';

type PrimaryDeviceTileProps = {
  assignedDevices: string[] | null;
  primaryDevice: ZoneDevice[] | null;
  onClick: (ev: React.MouseEvent) => void;
  shouldDisable?: boolean;
};

const PrimaryDeviceTile: FunctionComponent<PrimaryDeviceTileProps> = ({
  assignedDevices,
  primaryDevice,
  onClick,
}) => {
  const { t } = useTranslation();

  const getButtonLabel = () => {
    if (!primaryDevice || primaryDevice.length === 0) {
      return t('homepage.assign');
    }
    return makeTextExcerpt(primaryDevice[0].name, 20);
  };

  const isPrimaryDeviceAssigned = primaryDevice && primaryDevice.length > 0;
  const areDevicesAssigned = assignedDevices && assignedDevices.length > 0;

  return (
    <Card
      className={cn(styles.primaryDeviceTileCard, {
        [styles.primaryDeviceNotAssigned]: !isPrimaryDeviceAssigned,
      })}
      noBottomPadding
    >
      <StandardListItem
        L2Props={{
          label: areDevicesAssigned
            ? t('homepage.primaryDevice')
            : t('homepage.devices'),

          className: cn(styles.deviceTitle, {
            [styles.deviceTitleNotAssigned]: !isPrimaryDeviceAssigned,
          }),
        }}
        RProps={{
          className: cn({
            [styles.deviceName]: isPrimaryDeviceAssigned,
            [styles.assign]: !isPrimaryDeviceAssigned,
          }),
          label: getButtonLabel(),
        }}
        onClick={onClick}
        className={cn(styles.listTile, {
          [styles.listTileNotAssigned]: !isPrimaryDeviceAssigned,
        })}
        ariaLabel={t('homepage.primaryDevice')}
      />
    </Card>
  );
};

export default PrimaryDeviceTile;
