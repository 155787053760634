import InputListItem from 'UI/Components/Lists/List input';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import Card from 'UI/Elements/Card';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import colorStyles from 'Styles/colors.module.css';
import styles from './style.module.css';
import typography from 'Styles/typography.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'State/store';
import cn from 'classnames';
import { useSidepanel } from 'Utils/hooks/useSidepanel';

const EmployeeLoginSidePanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const databaseCompanyName = useSelector(selectors.settings.employeeLogin).data
    ?.databaseData.companyName;
  const localCompanyName = useSelector(selectors.settings.employeeLogin).data
    ?.localData.companyName;
  const { closeSidepanel } = useSidepanel();
  const [error, setError] = useState('');
  const [localCompanyNameInvalid, setLocalCompanyNameInvalid] = useState(true);

  const updateLocalName = (name: string) => {
    if (name.length > 60) {
      setError(t('settings.errorMaxCompanyNameChars'));
    } else {
      setError('');
    }
    dispatch(
      actions.settings.employeeLogin.updateInput({
        updatedCompanyName: name,
      })
    );
  };

  const handlePublish = useCallback(() => {
    if (!localCompanyName || localCompanyName.length > 60) return;
    dispatch(
      actions.settings.employeeLogin.updateCompanyName({
        updatedCompanyName: localCompanyName,
      })
    );
    closeSidepanel();
  }, [dispatch, localCompanyName]);

  const isUpdatedName = useMemo(() => {
    return databaseCompanyName !== localCompanyName;
  }, [databaseCompanyName, localCompanyName]);

  useEffect(() => {
    setLocalCompanyNameInvalid(!localCompanyName?.length ? true : false);
  }, [localCompanyName]);

  return (
    <SimpleSidePanel
      className={styles.employeeLoginSidePanel}
      headerClassName={styles.employeeLoginSidePanelHeader}
      title={t('common.editor')}
      heading3M
    >
      <SidePanelContentWrapper
        className={styles.employeeLoginSidePanelContentWrapper}
      >
        <Card className={styles.businessNameCard}>
          <div className={`${typography.medium} ${typography.heading4}`}>
            {t('settings.businessName')}
          </div>
          <InputListItem
            className={cn(colorStyles.still400, styles.companyNameInput)}
            value={localCompanyName || ''}
            onChange={updateLocalName}
            noIcon
            hasError={!!error}
            paragraph={error}
          />
        </Card>

        <Button
          label={t('common.publish')}
          theme={BUTTON_THEMES.black}
          onClick={handlePublish}
          disabled={localCompanyNameInvalid || !!error}
          className={cn(styles.ctaBtnHidden, {
            [styles.ctaBtnEnabled]: isUpdatedName,
          })}
        />
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export default EmployeeLoginSidePanel;
