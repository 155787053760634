import cn from 'classnames';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { To, useNavigate } from 'react-router-dom';

import ScreenHeader, {
  ScreenHeaderBase,
} from 'UI/Components/Headers/Header screen web';

import { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';

import { Props as PillProps } from 'UI/Elements/Pill large';

import styles from './style.module.css';
import cx from 'classnames';
import R, { RProps } from 'UI/Elements/R';

type FullscreenPageHeaderProps = JSX.IntrinsicElements['div'] & {
  label: string;
  backRoute?: To | Number;
  RProps?: RProps;
};

type PageHeaderProps = {
  label: string;
  buttonPillProps?: PillProps;
  className?: string;
};

export const FullscreenPageHeader: FunctionComponent<
  PropsWithChildren<FullscreenPageHeaderProps>
> = ({ className, label, children, backRoute, RProps = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateBack = useCallback(
    () => navigate(backRoute as any),
    [backRoute, navigate]
  );

  return (
    <ScreenHeaderBase
      className={cn(styles.pageHeader, className)}
      LProps={{
        label,
        iconProps: backRoute
          ? {
              name: IconNames.ChevronLeft,
              onClick: navigateBack,
              tooltipLabel: t('common.back'),
              shape: IconShape.square,
            }
          : undefined,
      }}
    >
      {children}
      <R {...RProps} />
    </ScreenHeaderBase>
  );
};

const PageHeader: FunctionComponent<PageHeaderProps> = ({
  label,
  buttonPillProps,
  className,
}) => {
  const classes = cx(styles.pageHeader, {
    [className || '']: Boolean(className),
  });
  return (
    <ScreenHeader
      className={classes}
      LProps={{ label }}
      RProps={{ buttonPillProps }}
    />
  );
};

export default PageHeader;
