import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useGuestDailyOnlineTime = () =>
  useUpdatedData({
    selector: selectors.onlineTime.guest.daily,
    action: actions.onlineTime.guest.daily.fetchData(),
  });

export default useGuestDailyOnlineTime;
