import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useIspSpeedTest = () =>
  useUpdatedData({
    selector: selectors.speedTest,
    action: actions.speedTest.fetchData(),
  });

export default useIspSpeedTest;
