import { FunctionComponent } from 'react';
import React from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import PillSmall from 'UI/Elements/Pill small';
import AtWorkCardTimeLineBar from '../AtWorkTimeLineBar/AtWorkTimeLineBar';
import { Time, Date } from 'Consts/types';
export type TimeItem = {
  startTime: Date;
  endTime: Date;
};
type AtWorkDataTileProps = {
  className?: string;
  date: Date;
  variant: 'present' | 'past';
  startTime?: Time;
  endTime?: Time;
  time?: TimeItem[];
};

const AtWorkDataTile: FunctionComponent<AtWorkDataTileProps> = ({
  className,
  date,
  variant,
  startTime,
  endTime,
  time,
}) => {
  return (
    <div
      className={cn(styles.atWorkCard, {
        [styles.atWorkCardPast]: variant === 'past',
        [styles.atWorkCardPresent]: variant === 'present',
        className,
      })}
    >
      <PillSmall
        className={cn({
          [styles.pillSmallPast]: variant === 'past',
        })}
      >
        {date}
      </PillSmall>
      <div className={styles.timeLineContainer}>
        <AtWorkCardTimeLineBar variant={variant} time={time as TimeItem[]} />
      </div>
    </div>
  );
};
export default AtWorkDataTile;
