import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as api from 'Api/endpoints';
import { CLOUDS } from '../../Api/index';
import { CloudBackend } from 'Consts/types';

import * as actions from '../actions';
import * as selectors from '../selectors';
import type { AppDispatch } from '../store';
import { isDevDomain, isStoredCloudAllowed } from 'subDomainConfiguration';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfigurationFromDomain } from 'subDomainConfiguration';

const useAuth = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { data: token } = useSelector(selectors.auth.token);
  const cloud = useSelector(selectors.auth.cloud);
  const environment = getConfigurationFromDomain();

  const [readingLS, setReadingLS] = useState(true);
  const [attemptingRefresh, setAttemptingRefresh] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    const cloudLS =
      ((localStorage.getItem('cloud') as CloudBackend) &&
        isStoredCloudAllowed(localStorage.getItem('cloud') as CloudBackend) &&
        CLOUDS[localStorage.getItem('cloud') as CloudBackend]) ||
      (isDevDomain() ? CLOUDS.DOGFOOD : CLOUDS.GAMMA);
    dispatch(actions.auth.setCloud(cloudLS));

    if (!auth) {
      setReadingLS(false);
      return;
    }

    (async () => {
      try {
        const { customerId, token, refreshToken } = await JSON.parse(auth);
        if ((!customerId || !token) && !attemptingRefresh) {
          dispatch(actions.auth.logout());

          setReadingLS(false);

          return;
        }

        const { data, error } = await api.getCustomer({
          customerId,
          token,
          cloud,
        });

        if (error || !data) {
          if (error?.name === 'Unauthorized') {
            if (
              environment?.authenticationsMethodsPermitted.includes(
                'partnerSso'
              )
            ) {
              if (refreshToken) {
                const appId = environment?.ssoAppId;
                const partnerId = environment?.partnerId || '';
                const params = {
                  refreshToken,
                  ...(appId ? { appId: appId } : {}),
                };
                const response = await api.refreshAccessTokens({
                  cloud,
                  partnerId,
                  params,
                });
                if (response.data) {
                  const { data, error } = await api.getCustomer({
                    customerId,
                    token: response.data.sessionToken,
                    cloud,
                  });
                  if (error || !data) {
                    dispatch(actions.auth.logout());
                  } else {
                    dispatch(
                      actions.auth.saveJustAuthToLocalStorage(
                        response.data.sessionToken,
                        partnerId,
                        response.data.refreshToken
                      )
                    );
                    setAttemptingRefresh(false);
                    dispatch(actions.customer.setCustomerId(customerId));
                  }
                } else {
                  dispatch(actions.auth.logout());
                }
              } else {
                dispatch(actions.auth.logout());
              }
            }

            if (token?.startsWith('Bearer')) {
              setAttemptingRefresh(true);
              const newtoken = 'Bearer ' + (await getAccessTokenSilently());
              const { data, error } = await api.getCustomer({
                customerId,
                token: newtoken,
                cloud,
              });
              if (error || !data) {
                dispatch(actions.auth.logout());
              } else {
                dispatch(
                  actions.auth.saveJustAuthToLocalStorage(newtoken, customerId)
                );
                setAttemptingRefresh(false);
                dispatch(actions.customer.setCustomerId(customerId));
              }
            }
          } else {
            dispatch(actions.auth.logout());
          }
          setReadingLS(false);

          return;
        }
        setAttemptingRefresh(false);
        dispatch(actions.customer.setCustomerId(customerId));
        dispatch(actions.auth.set(token));

        setReadingLS(false);
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          console.error(e);
        }

        dispatch(actions.auth.logout());

        setReadingLS(false);
      }
    })();
  }, [cloud, dispatch]);

  const onAuthFailure = useCallback(
    (currentToken: string | null, customerIdPassed?: string) => {
      (async () => {
        if (currentToken?.startsWith('Bearer')) {
          setAttemptingRefresh(true);
          const newtoken = 'Bearer ' + (await getAccessTokenSilently());
          if (currentToken && newtoken !== currentToken) {
            setAttemptingRefresh(false);
            dispatch(
              actions.auth.saveJustAuthToLocalStorage(
                newtoken,
                customerIdPassed
              )
            );
          }
        } else {
          dispatch(actions.auth.logout());
        }
      })();
    },
    [token]
  );

  return { isLoading: readingLS, cloud, token, onAuthFailure };
};

export default useAuth;
