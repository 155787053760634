import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon, { IconName } from 'UI/Elements/Icon';
import { Body1, Body2 } from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors.module.css';

import { onEnterKeydown } from 'Utils/keyboardEvents';
import styles from './style.module.css';

type CenterListItemProps = {
  label?: string;
  smallLabel?: string;
  paragraph?: string | null;
  iconName?: IconName;
  className?: string;
  onClick?: React.MouseEventHandler;
};

const CenterListItem: FunctionComponent<CenterListItemProps> = ({
  className,
  smallLabel,
  label,
  paragraph,
  iconName,
  onClick,
}) => {
  const containerClasses = cn(styles.centerListItem, className, {
    [styles.interactive]: !!onClick,
  });

  const ariaLabel = label || smallLabel || paragraph || iconName;

  if ((label || iconName || paragraph) && !smallLabel) {
    return (
      <div
        className={containerClasses}
        onClick={onClick}
        role={onClick ? 'button' : ''}
        tabIndex={onClick ? 0 : undefined}
        aria-label={ariaLabel}
        onKeyDown={(e) => onEnterKeydown(e, onClick)}
      >
        <div className={styles.centerListItemFirstRow}>
          {iconName && <Icon name={iconName} />}
          <Body1 className={colorStyles.still800}>{label}</Body1>
        </div>
        {paragraph && (
          <Body2 className={colorStyles.still400}>{paragraph}</Body2>
        )}
      </div>
    );
  }

  if ((smallLabel || iconName || paragraph) && !label) {
    return (
      <div
        className={containerClasses}
        role={onClick ? 'button' : ''}
        tabIndex={onClick ? 0 : undefined}
        aria-label={ariaLabel}
        onKeyDown={(e) => onEnterKeydown(e, onClick)}
      >
        <div className={styles.centerListItemFirstRow}>
          {iconName && <Icon name={iconName} />}
          <Body2 className={colorStyles.still400}>{smallLabel}</Body2>
        </div>
        {paragraph && (
          <Body2 className={colorStyles.still400}>{paragraph}</Body2>
        )}
      </div>
    );
  }

  return null;
};

export default CenterListItem;
