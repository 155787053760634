import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useEmployeeLast12MonthsAppsOnlineTime = () =>
  useUpdatedData({
    selector: selectors.appsOnlineTime.employee.last12months,
    action: actions.appsOnlineTime.employee.last12months.fetchData(),
  });

export default useEmployeeLast12MonthsAppsOnlineTime;
