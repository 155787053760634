import {
  addHours,
  eachDayOfInterval,
  eachHourOfInterval,
  endOfDay,
  startOfDay,
  subDays,
} from 'date-fns';

import { PERIODS } from 'Consts/defintions';
import {
  Categories,
  TimeDailyStats,
  TimeHourlyStats,
  Person,
  DataDailyStats,
  DataHourlyStats,
  Period,
  HourlyStats,
  DailyStats,
  Maybe,
  Nullable,
} from 'Consts/types';

import {
  formatGraphDateLabel,
  formatHours,
  formatMonthDay,
  formatMinutesToHours,
  formatSecondsToHoursAndMinutes,
} from 'Utils/formatters/date';

import { bytesToUnit, displayBytes, getUnitFromMaxValue } from 'Utils/mbMath';

type EmployeeStatusTypes = 'home' | 'away' | 'noDevice' | 'rest';

type GroupedPersons = {
  [key in EmployeeStatusTypes]: Person[];
};

export const groupEmployeeList = (persons: Nullable<Person[]>) => {
  const groupInitialState: GroupedPersons = {
    home: [],
    noDevice: [],
    away: [],
    rest: [],
  };

  if (persons) {
    persons.reduce((acc, curr: Person) => {
      if (!curr.primaryDevice) {
        acc.noDevice.push(curr);
      } else if (curr.connectionState === 'connected') {
        acc.home.push(curr);
      } else if (curr.connectionState === 'disconnected') {
        acc.away.push(curr);
      } else {
        acc.rest.push(curr);
      }

      return acc;
    }, groupInitialState);
  }

  return groupInitialState;
};

export const generateCategoryUsageList = (
  info: Array<TimeHourlyStats | TimeDailyStats>
) => {
  const entries = info.reduce((acc, cur) => {
    cur.categories.forEach(({ category, onlineSeconds }) => {
      if (!acc[category]) {
        acc[category] = 0;
      }

      acc[category] += onlineSeconds;
    });

    return acc;
  }, {} as Record<Categories, number>);

  const objectEntries = Object.entries(entries) as [Categories, number][];

  return objectEntries.sort(([next, a], [cur, b]) => {
    if (next === 'other') {
      return 1;
    }

    if (cur === 'other') {
      return -1;
    }

    return b - a;
  });
};

export const mostUsedCategories = (entries: [Categories, number][]) => {
  return entries.reduce((acc, cur, idx) => {
    if (idx === 0) {
      acc.push({ name: cur[0], value: cur[1] });
    } else if (idx === 1) {
      acc.push({ name: cur[0], value: cur[1] });
    } else if (idx === 2) {
      acc.push({ name: 'other', value: cur[1] });
    } else {
      acc[2].value += cur[1];
    }

    return acc;
  }, [] as { name: Categories; value: number }[]);
};

/* ONLINE TIME */

const secondsToMinutes = (sec: number) => {
  return sec / 60;
};

export const getHourlyTimeGraph = (
  hourlyStats: TimeHourlyStats[],
  fields: Partial<Record<Categories, Maybe<number>>>
) => {
  const now = new Date();

  const interval: Interval = {
    start: startOfDay(now),
    end: addHours(endOfDay(now), 1),
  };

  return eachHourOfInterval(interval).map((hour) => {
    const stats = hourlyStats.find(({ localStartTime }) => {
      const dataDate = new Date(localStartTime);

      return (
        dataDate.getHours() === hour.getHours() &&
        dataDate.getDate() === hour.getDate()
      );
    });

    if (!stats) {
      return {
        name: formatGraphDateLabel(new Date(hour), PERIODS.day),
        values: fields,
      };
    }

    const values = stats.categories.reduce(
      (acc, { category, onlineSeconds }) => {
        if (acc[category] !== undefined) {
          acc[category] =
            (acc[category] || 0) + secondsToMinutes(onlineSeconds);
        } else {
          acc.other = (acc.other || 0) + secondsToMinutes(onlineSeconds);
        }

        return acc;
      },
      { ...fields }
    );

    return { name: formatHours(new Date(hour)), values };
  });
};

export const getDailyTimeGraph = (
  dailyStats: TimeDailyStats[],
  fields: Partial<Record<Categories, number>>,
  period: Period
) => {
  const now = new Date();

  let start = subDays(now, 6);

  if (period === PERIODS.month) {
    start = subDays(now, 30);
  }

  const interval: Interval = {
    start,
    end: now,
  };

  return eachDayOfInterval(interval).map((day) => {
    const stats = dailyStats.find(({ localStartDate }) => {
      return (
        new Date(localStartDate).setHours(0, 0, 0, 0) ===
        day.setHours(0, 0, 0, 0)
      );
    });

    if (!stats) {
      return {
        name: formatGraphDateLabel(new Date(day), period),
        values: fields,
      };
    }

    const values = stats.categories.reduce(
      (acc, { category, onlineSeconds }) => {
        if (acc[category] !== undefined) {
          acc[category] =
            (acc[category] || 0) + secondsToMinutes(onlineSeconds);
        } else {
          acc.other = (acc.other || 0) + secondsToMinutes(onlineSeconds);
        }

        return acc;
      },
      { ...fields }
    );

    return { name: formatMonthDay(new Date(day)), values };
  });
};

/* DATA USAGE */

export const generateUsageDataCategoryList = (
  data: Array<DataHourlyStats | DataDailyStats>
) => {
  const totalCategoryValues = data.reduce((acc, stats) => {
    stats.categories.forEach(({ category, rxBytes, txBytes }) => {
      if (!acc[category]) {
        acc[category] = 0;
      }

      acc[category] += rxBytes + txBytes;
    });

    return acc;
  }, {} as Record<Categories, number>);

  const categoriesArray = Object.entries(totalCategoryValues) as [
    Categories,
    number
  ][];

  return categoriesArray.sort(([next, a], [cur, b]) => {
    if (next === 'other') {
      return 1;
    }

    if (cur === 'other') {
      return -1;
    }

    return b - a;
  });
};

export const formatHourlyStats = <T>(hourlyStats: Maybe<HourlyStats<T>[]>) => {
  const now = new Date();

  const interval: Interval = {
    start: startOfDay(now),
    end: addHours(endOfDay(now), 1),
  };

  return eachHourOfInterval(interval).map((hour) => {
    const stats = hourlyStats?.find(({ localStartTime }) => {
      const dataDate = new Date(localStartTime);

      return (
        dataDate.getHours() === hour.getHours() &&
        dataDate.getDate() === hour.getDate()
      );
    });

    return {
      localStartTime: hour.toISOString(),
      categories: stats?.categories || [],
    };
  });
};

export const formatDailyStats = <T>(
  dailyStats: Maybe<DailyStats<T>[]>,
  period: Period
) => {
  const now = new Date();

  let start = subDays(now, 6);

  if (period === PERIODS.month) {
    start = subDays(now, 30);
  }

  const interval: Interval = {
    start,
    end: now,
  };

  return eachDayOfInterval(interval).map((day) => {
    const stats = dailyStats?.find(({ localStartDate }) => {
      return (
        new Date(localStartDate).setHours(0, 0, 0, 0) ===
        day.setHours(0, 0, 0, 0)
      );
    });
    return {
      localStartDate: day.toISOString(),
      categories: stats?.categories || [],
    };
  });
};

type GraphData = {
  name: string;
  values: Record<string, number>;
}[];

export const getHourlyDataGraph = (
  hourlyStats: DataHourlyStats[],
  fields: Partial<Record<Categories, Maybe<number>>>
): GraphData => {
  const now = new Date();

  const interval: Interval = {
    start: startOfDay(now),
    end: addHours(endOfDay(now), 1),
  };

  return eachHourOfInterval(interval).map((hour) => {
    const stats = hourlyStats.find(({ localStartTime }) => {
      const dataDate = new Date(localStartTime);

      return (
        dataDate.getHours() === hour.getHours() &&
        dataDate.getDate() === hour.getDate()
      );
    });

    if (!stats) {
      return {
        name: formatGraphDateLabel(new Date(hour), PERIODS.day),
        values: fields,
      };
    }

    const values = stats.categories.reduce(
      (acc, { category, rxBytes, txBytes }) => {
        if (acc[category] !== undefined) {
          acc[category] = (acc[category] || 0) + rxBytes + txBytes;
        } else {
          acc.other = (acc.other || 0) + rxBytes + txBytes;
        }

        return acc;
      },
      { ...fields }
    );

    return { name: formatHours(new Date(hour)), values };
  });
};

export const getDailyDataGraph = (
  dailyStats: DataDailyStats[],
  fields: Partial<Record<Categories, number>>,
  period: Period
): GraphData => {
  const now = new Date();

  let start = subDays(now, 6);

  if (period === PERIODS.month) {
    start = subDays(now, 30);
  }

  const interval: Interval = {
    start,
    end: now,
  };

  return eachDayOfInterval(interval).map((day) => {
    const stats = dailyStats.find(({ localStartDate }) => {
      return (
        new Date(localStartDate).setHours(0, 0, 0, 0) ===
        day.setHours(0, 0, 0, 0)
      );
    });

    if (!stats) {
      return {
        name: formatGraphDateLabel(new Date(day), period),
        values: fields,
      };
    }

    const values = stats.categories.reduce(
      (acc, { category, rxBytes, txBytes }) => {
        if (acc[category] !== undefined) {
          acc[category] = (acc[category] || 0) + rxBytes + txBytes;
        } else {
          acc.other = (acc.other || 0) + rxBytes + txBytes;
        }

        return acc;
      },
      { ...fields }
    );

    return { name: formatMonthDay(new Date(day)), values };
  });
};

export const getMaxUnitFromGraphData = (graphData: Maybe<GraphData>) => {
  const allValues = graphData?.reduce((all, data) => {
    return [...all, ...Object.values(data.values)];
  }, [] as number[]);

  return getUnitFromMaxValue(allValues ?? []);
};

export const getCategoryGraphData = (graphData: Maybe<GraphData>) => {
  const maxUnit = getMaxUnitFromGraphData(graphData);

  const data = graphData?.map(({ name, values }) => {
    const entries = Object.entries(values) as [
      keyof typeof values,
      Maybe<number>
    ][];

    return {
      name,
      values: entries.reduce((newValues, [key, value]) => {
        newValues[key] = bytesToUnit(value ?? 0, maxUnit);

        return newValues;
      }, {} as typeof values),
    };
  });

  return {
    data: data ?? [],
    unit: maxUnit,
  };
};

export const getDataAverageLabel = ({
  dailyAverage,
  monthlyAverage,
  dailyStats,
  period,
}: {
  dailyAverage: Maybe<number>;
  dailyStats: Maybe<DataDailyStats[]>;
  monthlyAverage: Maybe<number>;
  period: Period;
}) => {
  if (period === PERIODS.day) {
    const value = displayBytes(dailyAverage ?? 0);

    return { i18key: 'graph.avgPerHr', value };
  }

  if (period === PERIODS.week) {
    const usage =
      (dailyStats?.reduce((acc, day) => {
        return (
          acc +
          day.categories.reduce((acc, { rxBytes, txBytes }) => {
            return acc + rxBytes + txBytes;
          }, 0)
        );
      }, 0) ?? 0) / 7;

    return { i18key: 'graph.avgPerDay', value: displayBytes(usage) };
  }

  if (period === PERIODS.month) {
    const value = displayBytes(monthlyAverage ?? 0);

    return { i18key: 'graph.avgPerDay', value };
  }

  return { i18key: '', value: '' };
};

export const getTimeAverageLabel = ({
  dailyAverage,
  monthlyAverage,
  dailyStats,
  period,
}: {
  dailyAverage: Maybe<number>;
  dailyStats: Maybe<TimeDailyStats[]>;
  monthlyAverage: Maybe<number>;
  period: Period;
}) => {
  if (period === PERIODS.day) {
    const value = Math.round(dailyAverage ?? 0);
    // dailyAverage expected in minutes
    if (value === 0) {
      const secondsPerHour = Math.round((dailyAverage ?? 0) * 60);
      return { i18key: 'graph.avgSecPerHr', value: secondsPerHour };
    } else {
      return { i18key: 'graph.avgMinPerHr', value };
    }
  }

  if (period === PERIODS.week) {
    const totalSeconds =
      dailyStats?.reduce((acc, day) => {
        return (
          acc +
          day.categories.reduce((acc, { onlineSeconds }) => {
            return acc + onlineSeconds;
          }, 0)
        );
      }, 0) || 0;

    const value = formatSecondsToHoursAndMinutes(totalSeconds / 7);

    return { i18key: 'graph.avgPerDay', value };
  }

  if (period === PERIODS.month) {
    const value = formatMinutesToHours(monthlyAverage ?? 0);

    return { i18key: 'graph.avgPerDay', value };
  }

  return { i18key: '', value: '' };
};

export const roundNumber = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};
