import React, { FunctionComponent } from 'react';
import cn from 'classnames';

import Icon, { IconName, IconNames } from 'UI/Elements/Icon';
import PillSmall from 'UI/Elements/Pill small';
import { Body3, Body3Medium, FinePrint1 } from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import MediumDeviceIcon from 'UI/Elements/Icon/device';

type TopProps = {
  icon1Name?: IconName;
  icon2Name?: IconName;
  timer?: string;
  pillSmall?: string;
};

type MidProps = {
  iconName?: string;
};

type BottomProps = {
  iconName?: IconName;
  pillSmall?: string;
  pillSmallVariant?: 'good' | 'poor';
  title?: string;
  label?: string;
};

const Top: FunctionComponent<TopProps> = ({
  icon1Name,
  icon2Name,
  timer,
  pillSmall,
}) => {
  if (icon1Name && !icon2Name && !pillSmall) {
    return (
      <div className={cn(styles.top, styles.withIcons)}>
        <Icon name={icon1Name} />
      </div>
    );
  }

  if (icon1Name && icon2Name) {
    return (
      <div className={cn(styles.top, styles.withIcons)}>
        <Icon name={icon2Name} />
        <Icon name={icon1Name} />
      </div>
    );
  }

  if (icon1Name && pillSmall) {
    return (
      <div className={cn(styles.top, styles.withPill)}>
        <PillSmall>{pillSmall}</PillSmall>
        <Icon name={icon1Name} />
      </div>
    );
  }

  if (timer) {
    return (
      <div className={cn(styles.top, styles.withTimer)}>
        <Icon name={IconNames.X} className={styles.timerIcon} />
        <Body3 className={colorStyles.freeze500}>{timer}</Body3>
      </div>
    );
  }

  return null;
};

const Mid: FunctionComponent<MidProps> = ({ iconName }) => {
  if (iconName) {
    return (
      <div className={styles.mid}>
        <MediumDeviceIcon
          fileName={iconName || 'unknown.svg'}
          className={styles.deviceIcon}
        />
      </div>
    );
  }

  return null;
};

const Bottom: FunctionComponent<BottomProps> = ({
  iconName,
  pillSmall,
  pillSmallVariant,
  title,
  label,
}) => {
  const singleLine = !!pillSmall;

  if (iconName && title) {
    return (
      <div
        className={cn(
          styles.bottom,
          singleLine ? styles.singleLine : styles.twoLine
        )}
      >
        {pillSmall && (
          <div className={styles.item}>
            <PillSmall variant={pillSmallVariant}>{pillSmall}</PillSmall>
          </div>
        )}
        {title && (
          <div className={styles.item}>
            <FinePrint1 className={colorStyles.still400}>{title}</FinePrint1>
          </div>
        )}
        {iconName && (
          <div className={styles.item}>
            <Icon name={iconName} />
            {label && <Body3Medium>{label}</Body3Medium>}
          </div>
        )}
      </div>
    );
  }

  return null;
};

type DeviceTileProps = {
  className?: string;
  TProps?: TopProps;
  MProps?: MidProps;
  BProps?: BottomProps;
};

const DeviceTile: FunctionComponent<DeviceTileProps> = ({
  className,
  TProps = {},
  MProps = {},
  BProps = {},
}) => {
  return (
    <div className={cn(styles.deviceTile, className)}>
      <Top {...TProps} />
      <Mid {...MProps} />
      <Bottom {...BProps} />
    </div>
  );
};

export default DeviceTile;
