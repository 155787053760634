import React, { FunctionComponent, useCallback } from 'react';
import ListItem from 'UI/Components/Lists/List standard';
import styles from '../RestrictedViewPage/style.module.css';
import sharedStyles from '../Nav panel/shared.module.css';
import { IconNames } from 'UI/Elements/Icon';
import { useTranslation } from 'react-i18next';
import { getNavItemThemeFromEnvironment } from '../Nav panel/Components/Desktop';
import cn from 'classnames';
import { ROUTES } from 'Consts/routes';
import { useLocation } from 'react-router-dom';
import LocationsSelector from '../Nav panel/Components/Locations';
import useLocations from 'State/hooks/useLocations';
import { useSelector } from 'react-redux';
import * as selectors from 'State/selectors';

export const RESTRICTED_NAV_ITEMS = [
  {
    route: ROUTES.ssidConfiguration.pods,
    label: 'settings.pods',
  },
  {
    route: ROUTES.ssidConfiguration.support,
    label: 'settings.support',
  },
  {
    route: ROUTES.ssidConfiguration.account,
    label: 'settings.account',
  },
];

export type RestrictedNavPanelProps = {
  onHomeNavigate: () => void;
  settingsExpanded: boolean;
  onSettingsExpand: (expanded: boolean) => void;
  onNavigate: (route: string) => void;
  onSwitchLocation: (locationId: string) => void;
};

const RestrictedNavPanel: FunctionComponent<RestrictedNavPanelProps> = ({
  onHomeNavigate,
  settingsExpanded,
  onSettingsExpand,
  onNavigate,
  onSwitchLocation,
}) => {
  const { t } = useTranslation(['translation', 'csp']);
  const routerLocation = useLocation();
  let currentRoute = routerLocation.pathname;
  const { activeLocation } = useLocations();
  const locationsForMenu = useSelector(selectors.locations.mergedLocations);

  const handleSettingsItemClick = useCallback(
    (ev: React.FocusEvent | React.MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();

      const newValue = ev.type !== 'focus' ? !settingsExpanded : true;

      localStorage.setItem('settingsExpanded', newValue.toString());
      onSettingsExpand(newValue);
    },
    [currentRoute, onNavigate, settingsExpanded]
  );

  return (
    <div className={styles.restrictedNavPanelWrapper}>
      <div
        aria-label="settings-navigation"
        role="navigation"
        className={styles.navList}
      >
        <ListItem
          className={sharedStyles.navLink}
          L1Props={{ iconProps: { name: IconNames.Home } }}
          L2Props={{ label: t('common.home') }}
          onClick={onHomeNavigate}
          role="link"
          navItemTheme={getNavItemThemeFromEnvironment()}
          ariaLabel={t('common.home')}
        />
        <ListItem
          className={sharedStyles.navLink}
          L1Props={{ iconProps: { name: IconNames.Settings } }}
          L2Props={{ label: t('common.settings') }}
          RProps={{
            icon1Props: {
              name: IconNames.ChevronRight,
              className: cn(styles.zonesChevron, {
                [styles.expanded]: settingsExpanded,
              }),
            },
          }}
          onClick={handleSettingsItemClick}
          onFocus={handleSettingsItemClick}
          navItemTheme={getNavItemThemeFromEnvironment()}
          ariaLabel={t('common.settings')}
        />
        {settingsExpanded &&
          RESTRICTED_NAV_ITEMS.map((navItem) => (
            <ListItem
              key={`${navItem.label}-${navItem.route}`}
              className={cn(sharedStyles.navLink, {
                [sharedStyles.selected]: currentRoute === navItem.route,
              })}
              L2Props={{
                label: t(`${navItem.label}`),
                withLeftPadding: true,
              }}
              role="link"
              onClick={() => onNavigate(navItem.route)}
              navItemTheme={getNavItemThemeFromEnvironment()}
              ariaLabel={t(`${navItem.label}`)}
            />
          ))}
      </div>
      <LocationsSelector
        currentLocation={activeLocation.data}
        locations={locationsForMenu}
        onSwitchToLocation={onSwitchLocation}
      />
    </div>
  );
};

export default RestrictedNavPanel;
