import { ROUTES } from 'Consts/routes';
import PillWeb from 'UI/Elements/Pill web';
import React, { FunctionComponent, useState } from 'react';
import { RESTRICTED_NAV_ITEMS } from '../RestrictedNavPanel/RestrictedNavPanel';
import styles from '../Nav panel/Components/Mobile/style.module.css';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { NavButton } from '../Nav panel/Components/Mobile';

export type RestrictedMobileNavPanelProps = {
  currentRoute: string;
  onNavigate: (route: string) => void;
  onHomeNavigate: () => void;
};

const RestrictedMobileNavPanel: FunctionComponent<
  RestrictedMobileNavPanelProps
> = ({ currentRoute, onNavigate, onHomeNavigate }) => {
  const { t } = useTranslation(['translation', 'csp']);
  const [isSettingsRoute, setIsSettingsRoute] = useState(
    currentRoute !== ROUTES.ssidConfiguration.configuration
  );

  const handleSettingsClick = (route: string) => {
    onNavigate(route);
  };

  const handleNavigationClick = (navigationLocation: 'home' | 'settings') => {
    if (navigationLocation === 'home') {
      setIsSettingsRoute(false);
      onHomeNavigate();
    } else {
      onNavigate(ROUTES.ssidConfiguration.pods);
      setIsSettingsRoute(navigationLocation === 'settings');
    }
  };

  return (
    <div className={cn(styles.mobileNavPanel)} role="navigation">
      {isSettingsRoute && (
        <div
          className={cn(styles.settingsNavBar, styles.restrictedSettingsNavBar)}
        >
          {RESTRICTED_NAV_ITEMS.map((item) => (
            <PillWeb
              key={`${item.label}-${item.route}`}
              className={styles.zonePill}
              label={t(`${item.label}`)}
              onClick={() => handleSettingsClick(item.route)}
              isSelected={currentRoute === item.route}
            />
          ))}
        </div>
      )}
      <div className={styles.pagesNavBar}>
        <NavButton
          currentRoute={currentRoute}
          route={ROUTES.ssidConfiguration.configuration}
          onNavigate={() => handleNavigationClick('home')}
        />
        <NavButton
          currentRoute={currentRoute}
          route={ROUTES.ssidConfiguration.pods}
          onNavigate={() => handleNavigationClick('settings')}
        />
      </div>
    </div>
  );
};

export default RestrictedMobileNavPanel;
