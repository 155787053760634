import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  DataDailyStats,
  DataHourlyStats,
  Nullable,
  Period,
  SpeedDimension,
} from 'Consts/types';

import { PERIODS } from 'Consts/defintions';

import type { BarGraphData } from 'UI/Components/Graphs/types';
import BarGraph from 'UI/Components/Graphs/BarGraph';

import SegmentedControls from 'UI/Elements/SegmentedControls';
import { Card } from 'UI/Elements/Card';

import dataUsageGraphTooltip from '../Components/Data usage graph tooltip';

import { bytesToUnit, displayBytes, getUnitFromMaxValue } from 'Utils/mbMath';

import {
  ZoneFilterNames,
  ZONES,
  GRADIENT_COLORS,
  totalHourlyUsage,
  totalDailyUsage,
  addTotals,
} from './utils';

import styles from './style.module.css';

export type DataUsageBarGraphData = BarGraphData<'usage'>;

type DataUsageGraphData = {
  all: Nullable<DataUsageBarGraphData[]>;
  secure: Nullable<DataUsageBarGraphData[]>;
  employee: Nullable<DataUsageBarGraphData[]>;
  guest: Nullable<DataUsageBarGraphData[]>;
};

type DataUsageGraphProps = {
  isLoading?: boolean;
  errorMessage?: string;
  label?: string;
  data: DataUsageGraphData;
  interval: number;
  initialFilterValue?: ZoneFilterNames;
  onFilterChange?: (filter: ZoneFilterNames) => void;
};

export type DailyDataUsageGraphData = {
  secure: DataHourlyStats[];
  employee: DataHourlyStats[];
  guest: DataHourlyStats[];
};

type DailyDataUsageGraphZonesProps = {
  isLoading?: boolean;
  errorMessage?: string;
  label?: string;
  speedDimension: SpeedDimension;
  data: Nullable<DailyDataUsageGraphData>;
  initialZone?: ZoneFilterNames;
  onZoneChange?: (filter: ZoneFilterNames) => void;
};

export type MonthlyDataUsageGraphData = {
  secure: DataDailyStats[];
  employee: DataDailyStats[];
  guest: DataDailyStats[];
};

type MonthlyDataUsageGraphZonesProps = {
  isLoading?: boolean;
  errorMessage?: string;
  label?: string;
  speedDimension: SpeedDimension;
  period: Period;
  data: Nullable<MonthlyDataUsageGraphData>;
  initialZone?: ZoneFilterNames;
  onZoneChange?: (filter: ZoneFilterNames) => void;
};

const DataUsageGraph: FunctionComponent<DataUsageGraphProps> = ({
  isLoading,
  errorMessage,
  label = 'Data usage',
  data,
  interval,
  initialFilterValue = 'all',
  onFilterChange = () => null,
}) => {
  const { t } = useTranslation();

  const [activeFilter, setActiveFilter] =
    useState<ZoneFilterNames>(initialFilterValue);

  const handleClick = (i: number) => {
    onFilterChange(ZONES[i].key);
    setActiveFilter(ZONES[i].key);
  };

  const aggregateSecure = useMemo(() => {
    return data.secure?.reduce((t, item) => t + item.values.usage, 0) ?? 0;
  }, [data]);

  const aggregateEmployee = useMemo(() => {
    return data.employee?.reduce((t, item) => t + item.values.usage, 0) ?? 0;
  }, [data]);

  const aggregateGuest = useMemo(() => {
    return data.guest?.reduce((t, item) => t + item.values.usage, 0) ?? 0;
  }, [data]);

  const aggregateUsage = aggregateSecure + aggregateEmployee + aggregateGuest;

  const aggregates = {
    all: aggregateUsage,
    secure: aggregateSecure,
    employee: aggregateEmployee,
    guest: aggregateGuest,
  };

  const secondLabel = aggregates[activeFilter]
    ? displayBytes(aggregates[activeFilter])
    : t('common.noData');

  const unit = getUnitFromMaxValue(
    data[activeFilter]?.map(({ values }) => values.usage) ?? []
  );

  const normalizedData = data[activeFilter]?.map(({ name, values }) => {
    return { name, values: { usage: bytesToUnit(values.usage, unit) } };
  });

  return (
    <Card
      isLoading={isLoading}
      errorMessage={errorMessage}
      header={{
        L2Props: {
          label,
          secondLabel: isLoading ? '' : secondLabel,
        },
      }}
    >
      <SegmentedControls
        tabs={ZONES.map(({ key, i18labelKey }) => ({
          key,
          label: t(i18labelKey),
        }))}
        onClick={handleClick}
        className={styles.tabs}
        initialActiveIndex={ZONES.findIndex((p) => p.key === activeFilter)}
      />

      {normalizedData && (
        <BarGraph
          className={styles.barGraph}
          data={normalizedData}
          graphGradient={GRADIENT_COLORS}
          customTooltip={dataUsageGraphTooltip(unit)}
          interval={interval}
          ariaLabel={t('wlan.dataUsageChartAriaLabel')}
        />
      )}
    </Card>
  );
};

export const DailyDataUsageGraphZones: FunctionComponent<
  DailyDataUsageGraphZonesProps
> = ({
  isLoading,
  errorMessage,
  label,
  speedDimension,
  data,
  initialZone = 'all',
  onZoneChange = () => null,
}) => {
  const totalSecure = useMemo<DataUsageBarGraphData[] | null>(() => {
    return data && totalHourlyUsage(data?.secure, speedDimension, PERIODS.day);
  }, [data, speedDimension]);

  const totalEmployee = useMemo<DataUsageBarGraphData[] | null>(() => {
    return (
      data && totalHourlyUsage(data?.employee, speedDimension, PERIODS.day)
    );
  }, [data, speedDimension]);

  const totalGuest = useMemo<DataUsageBarGraphData[] | null>(() => {
    return data && totalHourlyUsage(data?.guest, speedDimension, PERIODS.day);
  }, [data, speedDimension]);

  const totalTotal = useMemo<DataUsageBarGraphData[] | null>(() => {
    return (
      totalSecure &&
      totalEmployee &&
      totalGuest &&
      addTotals(totalSecure, totalEmployee, totalGuest)
    );
  }, [totalEmployee, totalGuest, totalSecure]);

  const [activeFilter, setActiveFilter] =
    useState<ZoneFilterNames>(initialZone);

  const allData: DataUsageGraphData = {
    all: totalTotal,
    secure: totalSecure,
    employee: totalEmployee,
    guest: totalGuest,
  };

  return (
    <DataUsageGraph
      isLoading={isLoading}
      errorMessage={errorMessage}
      label={label}
      data={allData}
      interval={3}
      initialFilterValue={activeFilter}
      onFilterChange={(filter: ZoneFilterNames) => {
        onZoneChange(filter);
        setActiveFilter(filter);
      }}
    />
  );
};

export const MonthlyDataUsageGraphZones: FunctionComponent<
  MonthlyDataUsageGraphZonesProps
> = ({
  isLoading,
  errorMessage,
  label,
  speedDimension,
  period,
  data,
  initialZone,
  onZoneChange,
}) => {
  const totalSecure = useMemo<DataUsageBarGraphData[] | null>(() => {
    return data && totalDailyUsage(data?.secure, speedDimension, period);
  }, [data, period, speedDimension]);

  const [activeFilter, setActiveFilter] = useState<ZoneFilterNames>(
    initialZone || 'all'
  );

  const totalEmployee = useMemo<DataUsageBarGraphData[] | null>(() => {
    return data && totalDailyUsage(data?.employee, speedDimension, period);
  }, [data, period, speedDimension]);

  const totalGuest = useMemo<DataUsageBarGraphData[] | null>(() => {
    return data && totalDailyUsage(data?.guest, speedDimension, period);
  }, [data, period, speedDimension]);

  const totalTotal = useMemo<DataUsageBarGraphData[] | null>(() => {
    return (
      totalSecure &&
      totalEmployee &&
      totalGuest &&
      addTotals(totalSecure, totalEmployee, totalGuest)
    );
  }, [totalEmployee, totalGuest, totalSecure]);

  const allData: DataUsageGraphData = {
    all: totalTotal,
    secure: totalSecure,
    employee: totalEmployee,
    guest: totalGuest,
  };

  return (
    <DataUsageGraph
      isLoading={isLoading}
      errorMessage={errorMessage}
      label={label}
      data={allData}
      interval={period === PERIODS.week ? 0 : 4}
      initialFilterValue={activeFilter}
      onFilterChange={(filter: ZoneFilterNames) => {
        if (onZoneChange) {
          onZoneChange(filter);
        }
        setActiveFilter(filter);
      }}
    />
  );
};
