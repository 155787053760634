import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon, { IconName } from 'UI/Elements/Icon';

import styles from './style.module.css';
import { Nullable } from '../../../Consts/types';
import colors from 'Styles/colors';
import { Body1 } from '../Typography';

type AlertBarProps = {
  text?: Nullable<string>;
  className?: string;
  iconName?: IconName;
  backgroundColor?: string;
};

const AlertBar: FunctionComponent<AlertBarProps> = ({
  className,
  text,
  iconName,
  backgroundColor = colors.sore700,
}) => {
  const classes = cn(styles.alertBar, className);

  return (
    <div className={classes} style={{ backgroundColor: backgroundColor }}>
      {iconName && <Icon name={iconName} className={styles.icon} />}
      <span className={styles.text}>
        <Body1>{text}</Body1>
      </span>
    </div>
  );
};

export default AlertBar;
