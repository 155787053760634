import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useGuestDailyDataUsage = () =>
  useUpdatedData({
    selector: selectors.dataUsage.guest.daily,
    action: actions.dataUsage.guest.daily.fetchData(),
  });

export default useGuestDailyDataUsage;
