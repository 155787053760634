export enum MixPanelEvents {
  AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
  AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE',
  EMPLOYEE_CREATE_SUCCESS = 'EMPLOYEE_CREATE_SUCCESS',
  LOGOUT = 'LOGOUT',
  SCREEN = 'SCREEN',
  GUEST_DOWNLOAD_CSV_MESSAGE = 'GUEST_DOWNLOAD_CSV_MESSAGE',
  GUEST_DOWNLOAD_CSV_SUCCESS = 'GUEST_DOWNLOAD_CSV_SUCCESS',
  GUEST_DOWNLOAD_CSV_FAILURE = 'GUEST_DOWNLOAD_CSV_FAILURE',
  GUEST_DOWNLOAD_TURN_ON_MESSAGE = 'GUEST_DOWNLOAD_TURN_ON_MESSAGE',
  GUEST_DOWNLOAD_TURN_ON_SUCCESS = 'GUEST_DOWNLOAD_TURN_ON_SUCCESS',
  GUEST_DOWNLOAD_TURN_ON_FAILURE = 'GUEST_DOWNLOAD_TURN_ON_FAILURE',
  GUEST_DOWNLOAD_NO_EMAILS_MESSAGE = 'GUEST_DOWNLOAD_NO_EMAILS_MESSAGE',
  EMPLOYEE_LOGIN = 'EMPLOYEE_LOGIN',
  UNQUARANTINE_DEVICE = 'UNQUARANTINE_DEVICE',
  EMPLOYEE_LOGIN_ENABLEMENT = 'EMPLOYEE_LOGIN_ENABLEMENT',
  LOCATION_SUMMARY = 'LOCATION_SUMMARY',
  EMPLOYEE_REMOVE_SUCCESS = 'EMPLOYEE_REMOVE_SUCCESS',
  EMPLOYEE_UNBLOCK_SUCCESS = 'EMPLOYEE_UNBLOCK_SUCCESS',
  SSID_CONFIGURATION = 'SSID_CONFIGURATION',
}
