import useLocationAtWorkEvents from 'State/hooks/useLocationAtWorkEvents';
import AtWorkCard from 'UI/Reusable/AtWorkCard/AtWorkCard';
import React, { FunctionComponent } from 'react';

type EmployeeAtWorkProps = {
  personId: string;
};

const EmployeeAtWork: FunctionComponent<EmployeeAtWorkProps> = ({
  personId,
}) => {
  const locationAtWorkEvents = useLocationAtWorkEvents();

  const personAtWorkEvents = locationAtWorkEvents.data?.filter((event) => {
    return event.personId === personId;
  });

  return (
    <AtWorkCard
      atWorkEvents={personAtWorkEvents}
      isLoading={locationAtWorkEvents.isLoading}
      errorMessage={locationAtWorkEvents.errorMessage}
    />
  );
};

export default EmployeeAtWork;
