import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const usePersonDailyOnlineTime = (
  personId: string,
  primaryDevice: string | null | undefined
) =>
  useUpdatedData({
    selector: selectors.onlineTime.persons.daily,
    action: actions.onlineTime.persons.daily.fetchData(personId, primaryDevice),
    dataDependencyKey: personId,
  });

export default usePersonDailyOnlineTime;
