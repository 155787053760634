import { Mac } from 'Consts/types';
import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDeviceSecurityEvents = (mac: Mac) =>
  useUpdatedData({
    selector: selectors.deviceSecurityEvents,
    action: actions.deviceSecurityEvents.fetchData(mac),
    dataDependencyKey: mac,
  });

export default useDeviceSecurityEvents;
