import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useEmployeeSecurityEvents = (personId: string) =>
  useUpdatedData({
    selector: selectors.employeeSecurityEvents,
    action: actions.employeeSecurityEvents.fetchData(personId),
    dataDependencyKey: personId,
  });

export default useEmployeeSecurityEvents;
