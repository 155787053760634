import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const usePersonMonthlyAppsOnlineTime = (personId: string) =>
  useUpdatedData({
    selector: selectors.appsOnlineTime.persons.monthly,
    action: actions.appsOnlineTime.persons.monthly.fetchData(personId),
    dataDependencyKey: personId,
  });

export default usePersonMonthlyAppsOnlineTime;
