import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';

export const sentenceCase = (str: string) => upperFirst(lowerCase(str));

export const makeTextExcerpt = (
  text: string,
  length: number,
  suffix: string = '…'
) => (text && text.length > length ? text.substring(0, length) + suffix : text);
