import React, {
  useCallback,
  useEffect,
  useState,
  FunctionComponent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

import InputListItem from 'UI/Components/Lists/List input';

import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { IconNames } from 'UI/Elements/Icon';

import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';

import {
  OnboardingPageWrapper,
  OnboardingPageTopContent,
  OnboardingPageBottomContent,
} from '../Components/Onboarding page wrapper';

import onboardingStyles from '../style.module.css';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import {
  getConfigurationFromDomain,
  isDevDomain,
} from 'subDomainConfiguration';
import AlertModal from 'UI/Elements/Modals/Alerts';
import Alert from 'UI/Components/Alert';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';

const BellLogin: FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const authError = useSelector(selectors.auth.authError);
  const bellAuth = useSelector(selectors.auth.bellAuth);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectAccount, setSelectAccount] = useState(false);
  const [error, setError] = useState({ password: '', username: '' });
  const environment = getConfigurationFromDomain();
  const trackEvent = useTrackEvent();
  const namespace = useCspTranslationNamespace();

  const countOfAccounts =
    bellAuth.data && bellAuth.data?.sections?.internetAccounts?.length;

  const handleChangeUsername = useCallback(
    (value: string) => {
      const trimmedUsername = value.trim();
      setError((oldError) => ({ ...oldError, username: '' }));
      dispatch(actions.auth.clear());
      setUsername(trimmedUsername);
    },
    [dispatch]
  );

  const handleChangePassword = useCallback(
    (value: string) => {
      setError((oldError) => ({ ...oldError, password: '' }));
      dispatch(actions.auth.clear());
      setPassword(value);
    },
    [dispatch]
  );

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (authError) {
      const errorMessage = capitalize(authError);

      setError({ username: errorMessage, password: '' });
    }
  }, [authError]);

  useEffect(() => {
    if (
      bellAuth.data &&
      bellAuth.data?.sections?.internetAccounts?.length > 1
    ) {
      setSelectAccount(true);
    }
  }, [bellAuth]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.BellLoginPage,
      },
    });
  }, []);

  const selectBellAccount = useCallback(
    (index: number) => {
      if (bellAuth.data) {
        dispatch(
          actions.auth.loginWithExchangeToken({
            fonseLoginResponse: bellAuth.data,
            index,
          })
        );
        setSelectAccount(false);
      }
    },
    [dispatch, bellAuth]
  );

  const handleBellLogin = useCallback(() => {
    const cloud = isDevDomain()
      ? environment?.cloudDev
      : environment?.cloudProduction;

    if (!cloud) return;

    dispatch(actions.auth.setCloud(cloud));

    if (!username) {
      setError((prev) => ({
        ...prev,
        username: t('onboarding.errorEmptyField'),
      }));
      return;
    }

    if (!password) {
      setError((prev) => ({
        ...prev,
        password: t('onboarding.errorEmptyField'),
      }));
      return;
    }

    dispatch(actions.auth.bellLogin({ username, password }));
  }, [dispatch, username, password, error, t]);

  return (
    <OnboardingPageWrapper
      label={t('onboarding.signInLabel', {
        ns: namespace,
      })}
      subLabel={t('onboarding.signInSubLabel')}
    >
      <OnboardingPageTopContent>
        <InputListItem
          className={onboardingStyles.stretch}
          prefixIcon={IconNames.EmailIcon}
          smallLabel={t('onboarding.yourUsername')}
          paragraph={error.username ? error.username : ''}
          value={username}
          onChange={handleChangeUsername}
          onSubmit={handleBellLogin}
          hasError={Boolean(error.username)}
        />

        <InputListItem
          className={onboardingStyles.stretch}
          inputType="password"
          prefixIcon={IconNames.Key}
          smallLabel={t('onboarding.yourPassword')}
          value={password}
          onChange={handleChangePassword}
          onSubmit={handleBellLogin}
          paragraph={error.password ? error.password : ''}
          hasError={Boolean(error.password)}
        />
      </OnboardingPageTopContent>

      <OnboardingPageBottomContent>
        <Button
          label={t('onboarding.signInBell', {
            ns: namespace,
          })}
          onClick={handleBellLogin}
        />

        <Button
          theme={BUTTON_THEMES.noborder}
          label={t('common.back')}
          onClick={handleBack}
        />
      </OnboardingPageBottomContent>

      <AlertModal
        isOpen={selectAccount}
        onClose={() => setSelectAccount(false)}
      >
        <Alert
          topProps={{
            label: t('onboarding.alertModal.selectAccount.heading'),
          }}
          bottomProps={{
            button1Props: {
              label:
                bellAuth.data?.sections.internetAccounts[0].description || '1',
              onClick: () => selectBellAccount(0),
              theme: BUTTON_THEMES.white,
            },
            button2Props:
              countOfAccounts && countOfAccounts >= 2
                ? {
                    label:
                      bellAuth.data?.sections.internetAccounts[1].description ||
                      '2',
                    onClick: () => selectBellAccount(1),
                    theme: BUTTON_THEMES.white,
                  }
                : undefined,
            button3Props:
              countOfAccounts && countOfAccounts >= 3
                ? {
                    label:
                      bellAuth.data?.sections.internetAccounts[2].description ||
                      '3',
                    onClick: () => selectBellAccount(2),
                    theme: BUTTON_THEMES.white,
                  }
                : undefined,
          }}
        />
      </AlertModal>
    </OnboardingPageWrapper>
  );
};

export default BellLogin;
