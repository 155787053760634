import React, { FunctionComponent } from 'react';
import { t } from 'i18next';

import { GuestNetworkUsage, Nullable } from 'Consts/types';

import HorizontalTile from 'UI/Components/Tiles/Horizontal tile';

import { IconName, IconNames } from 'UI/Elements/Icon';
import { Card } from 'UI/Elements/Card';

import { displayBytes } from 'Utils/mbMath';

import { AnalyticsFieldsColors } from 'Consts/defintions';
import styles from './style.module.css';
import { useTranslation } from 'react-i18next';

export type ListItem = {
  id: number | string;
  name: string;
  activityInfo?: string;
  connectionInfo?: string;
  deviceIcon?: IconName;
};

export type Item = {
  id: string | number;
  i18subTitleKey: string;
  label: string;
  iconName: IconName;
  color: string;
};

type AnalyticsUIProps = {
  items: Item[];
  headerLabel: string;
};

type AnalyticsProps = {
  headerLabel: string;
  data: Nullable<GuestNetworkUsage>;
};

export const AnalyticsUI: FunctionComponent<AnalyticsUIProps> = ({
  items,
  headerLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Card header={{ L2Props: { label: headerLabel } }}>
      <div className={styles.subtitle}>{t('guestZone.subtitle')}</div>
      <div className={styles.analytics}>
        {items.map((item) => (
          <div
            className={styles.tileWrapper}
            style={{ background: item.color }}
            key={item.id}
          >
            <HorizontalTile
              className={styles.tile}
              L2Props={{
                className: styles.l2,
                subTitleOnTop: true,
                subTitle: t(item?.i18subTitleKey),
                bigLabel: item?.label,
              }}
              RProps={{ iconName: item.iconName }}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

type AnalyticsFields = Record<
  string,
  {
    id: number;
    i18subTitleKey: string;
    i18labelKey: string;
    iconName: IconName;
    color: string;
  }
>;

const analyticFields: AnalyticsFields = {
  avgSessionLength: {
    id: 1,
    i18subTitleKey: 'guestZone.avgSession',
    i18labelKey: 'common.min',
    iconName: IconNames.Clock,
    color: AnalyticsFieldsColors.avgSessionLength,
  },
  avgSessionTxRxBytes: {
    id: 2,
    i18subTitleKey: 'guestZone.avgData',
    i18labelKey: '',
    iconName: IconNames.CircleFirmware,
    color: AnalyticsFieldsColors.avgSessionTxRxBytes,
  },
  deviceCount: {
    id: 3,
    i18subTitleKey: 'guestZone.avgDevice',
    i18labelKey: '',
    iconName: IconNames.Devices,
    color: AnalyticsFieldsColors.deviceCount,
  },
  guestCount: {
    id: 4,
    i18subTitleKey: 'guestZone.avgGuest',
    i18labelKey: '',
    iconName: IconNames.PeopleTwo,
    color: AnalyticsFieldsColors.guestCount,
  },
};

const setAnalyticsList = (analytics: Record<string, string | number>) => {
  return Object.keys(analytics).map((elem) => {
    let label = analytics[elem];

    if (analytics[elem] !== 0 && elem === 'avgSessionTxRxBytes') {
      label = displayBytes(Number(analytics[elem]));
    }
    const isEmpty = (label: number | string) => {
      if (label === 0) {
        return '--';
      } else {
        return `${label} ${t(analyticFields[elem].i18labelKey)}`;
      }
    };
    return {
      ...analyticFields[elem],
      label: isEmpty(label),
    };
  });
};

export const Analytics: FunctionComponent<AnalyticsProps> = ({
  headerLabel,
  data,
}) => {
  if (!data) {
    // TODO add loader
    return <Card header={{ L2Props: { label: headerLabel } }} />;
  }

  const analytics = setAnalyticsList({ ...data?.sessionStats });

  return <AnalyticsUI items={analytics || []} headerLabel={headerLabel} />;
};
