import cn from 'classnames';
import { isZonesRoute } from 'Consts/routes';
import { Mac, Person, ZoneDevice } from 'Consts/types';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as actions from 'State/actions';
import useEmployees from 'State/hooks/useEmployees';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import { AppDispatch } from 'State/store';
import colorStyles from 'Styles/colors.module.css';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import Alert from 'UI/Components/Alert';
import DeviceListItem, { Paragraph } from 'UI/Components/Lists/List device';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import Card from 'UI/Elements/Card';
import Divider from 'UI/Elements/Divider';
import { IconNames } from 'UI/Elements/Icon';
import AlertModal from 'UI/Elements/Modals/Alerts';
import { Heading4 } from 'UI/Elements/Typography';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import styles from './style.module.css';

const BELL = 'bell';

type AssignDevicesSidePanelProps = {
  devices: ZoneDevice[] | null;
  employee: Person;
};

const AssignDevicesSidePanel: FunctionComponent<
  AssignDevicesSidePanelProps
> = ({ devices, employee }) => {
  const { t } = useTranslation();
  const { closeSidepanel } = useSidepanel();
  const dispatch = useDispatch<AppDispatch>();
  const environment = getConfigurationFromDomain();
  const networkAccessData = useNetworkAccess();
  const persons = useEmployees();
  const [unassignModalOpen, setUnAssignModalOpen] = useState<boolean>(false);
  const routerLocation = useLocation();
  const currentRoute = routerLocation.pathname;

  const [assignedDevices, setAssignedDevices] = useState<Mac[]>(
    devices
      ?.filter((device) => device.personId === employee.id)
      .map((device) => device.mac) || []
  );

  const handleDeviceToggle = useCallback((device: ZoneDevice) => {
    setAssignedDevices((current) =>
      current.includes(device.mac)
        ? current.filter((mac) => mac !== device.mac)
        : [...current, device.mac]
    );
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(
      actions.employee.updateEmployee(
        employee.id,
        employee.nickname,
        employee.imageId,
        assignedDevices
      )
    );

    if (!employee.primaryDevice) {
      dispatch(
        actions.employee.assignPrimaryDeviceToEmployee(
          employee.id,
          assignedDevices[0]
        )
      );
    }

    closeSidepanel();
  }, [dispatch, employee, assignedDevices, closeSidepanel]);

  const personNicknameLookup = useCallback(
    (personId: string) => {
      if (persons)
        return persons.data?.find((p) => personId === p.id)?.nickname;
    },
    [persons]
  );

  const deviceParagraphText = useCallback(
    (device: ZoneDevice): Paragraph[] => {
      if (device.networkAccessMode === 'blocked')
        return [
          {
            label: t('common.blocked'),
            color: colorStyles.sore600,
          },
        ];
      if (
        device.networkAccessMode === 'auto' &&
        ((networkAccessData?.data?.default?.purgatory &&
          device.networkId === 'default') ||
          (networkAccessData?.data?.employee?.purgatory &&
            device.networkId === 'employee'))
      )
        return [
          {
            label: t('common.unapproved'),
            color: colorStyles.warm700,
          },
        ];
      if (device.personId)
        return [
          {
            label: t('devices.assignDeviceZone.newPersonAssigned', {
              employee: personNicknameLookup(device.personId),
            }),
          },
        ];
      return [];
    },
    [networkAccessData, personNicknameLookup, t]
  );

  const checkListOnIcon =
    environment.id === BELL ? IconNames.CheckListOnBell : IconNames.CheckListOn;
  const checkListOffIcon =
    environment.id === BELL
      ? IconNames.CheckListOffBell
      : IconNames.CheckListOff;

  const sortedDevices = useMemo(
    () =>
      devices ? [...devices].sort((a, b) => a.name.localeCompare(b.name)) : [],
    [devices]
  );
  const numSortedDevices = sortedDevices.length;

  const employeeDevices = useMemo(() => {
    return devices?.filter(
      ({ personId }: ZoneDevice) => personId === employee.id
    );
  }, [devices, employee.id]);

  const handleNoAssignedDevicesModalShow = useCallback(() => {
    setUnAssignModalOpen(true);
  }, [employeeDevices]);

  const handleNoAssignedDevicesModalHide = useCallback(() => {
    setUnAssignModalOpen(false);
  }, []);

  const assignDeviceAcknowledge = () => {
    setUnAssignModalOpen(false);
    closeSidepanel();
  };

  const handleSidePanelClose = () => {
    !employeeDevices?.length
      ? handleNoAssignedDevicesModalShow()
      : closeSidepanel();
  };

  return (
    <SimpleSidePanel
      onClose={handleSidePanelClose}
      title={t('devices.assignDeviceZone.assignDevices')}
    >
      <SidePanelContentWrapper>
        <Heading4>{t('devices.assignDeviceZone.assignDevices')}</Heading4>
        <Card
          noBottomPadding
          emptyCardLabel={
            numSortedDevices === 0
              ? t('devices.assignDeviceZone.noDevices')
              : ''
          }
        >
          {sortedDevices.map((device: ZoneDevice, i) => (
            <React.Fragment key={`${device.mac}_${i}`}>
              {i !== 0 && <Divider />}
              <DeviceListItem
                L1Props={{
                  mediumDeviceIconProps: {
                    fileName: device.icon,
                    size: 'small',
                  },
                  className: styles.deviceWithNoPersonIcons,
                }}
                L2Props={{
                  label: device.nickname || device.name,
                  paragraphList: deviceParagraphText(device),
                }}
                RProps={{
                  iconProps: {
                    name: assignedDevices.includes(device.mac)
                      ? checkListOnIcon
                      : checkListOffIcon,
                    onClick: () => handleDeviceToggle(device),
                  },
                }}
                onClick={() => handleDeviceToggle(device)}
              />
            </React.Fragment>
          ))}
        </Card>
        <AlertModal
          isOpen={unassignModalOpen}
          onClose={handleNoAssignedDevicesModalHide}
        >
          <Alert
            topProps={{
              label: t('assignPrimeDevice.unAssignModal.label'),
              paragraph:
                t('assignPrimeDevice.unAssignModal.paragraph') ||
                'Without this you won’t be able to use all the diagnostic tools about your employee',
              className: styles.modalHeader,
            }}
            bottomProps={{
              button1Props: {
                label: t('devices.modal.assignDeviceModal.iUnderstand'),
                onClick: assignDeviceAcknowledge,
              },
              button2Props: {
                label: t('common.cancel'),
                onClick: handleNoAssignedDevicesModalHide,
                theme: BUTTON_THEMES.white,
              },
            }}
          />
        </AlertModal>
      </SidePanelContentWrapper>
      <div
        className={
          environment.id === BELL
            ? styles.doneBtnContainerBell
            : styles.doneBtnContainer
        }
      >
        <Button
          className={
            environment.id === BELL
              ? styles.doneBtnBell
              : cn(styles.doneBtn, {
                  [styles.zonesNavBarButton]: isZonesRoute(currentRoute),
                })
          }
          theme={BUTTON_THEMES.black}
          label={t('common.done')}
          onClick={() => handleSubmit()}
        />
      </div>
    </SimpleSidePanel>
  );
};

export default AssignDevicesSidePanel;
