import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import PillSmall from 'UI/Elements/Pill small';

import {
  Heading4Medium,
  Heading4,
  Body2,
  Body2Medium,
} from 'UI/Elements/Typography';
import L1, { L1Props } from 'UI/Elements/L1';
import R, { RProps } from 'UI/Elements/R';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import { Nullable } from '../../../../Consts/types';

export type L2Props = {
  label?: Nullable<string>;
  paragraph?: Nullable<string>;
  paragraphOnTop?: boolean;
  secondLabel?: Nullable<string | number>;
  secondParagraph?: Nullable<string>;
  smallLabel?: Nullable<string>;
  smallPillLabel?: Nullable<string>;
  className?: string;
};

export type CardHeaderProps = {
  className?: string;
  L1Props?: L1Props;
  L2Props?: L2Props;
  RProps?: RProps;
  onClick?: React.MouseEventHandler;
};

const L2: FunctionComponent<L2Props> = ({
  label,
  paragraph,
  paragraphOnTop,
  secondLabel,
  secondParagraph,
  smallLabel,
  smallPillLabel,
  className,
}) => {
  const containerClassName = cn(styles.l2, className);

  let paragraphComponent = null;

  if (paragraph) {
    paragraphComponent = (
      <Body2 className={colorStyles.still400}>{paragraph}</Body2>
    );
  }

  return (
    <div className={containerClassName}>
      {paragraphOnTop && paragraphComponent && (
        <div className={styles.l2row}>{paragraphComponent}</div>
      )}

      <div className={styles.l2row}>
        {label && (
          <Heading4Medium className={styles.l2label}>{label}</Heading4Medium>
        )}
        {secondLabel && (
          <Heading4 className={colorStyles.still400}>{secondLabel}</Heading4>
        )}
        {smallPillLabel && (
          <PillSmall className={styles.l2pillSmall}>{smallPillLabel}</PillSmall>
        )}
      </div>

      {!paragraphOnTop && paragraphComponent && (
        <div className={styles.l2row}>{paragraphComponent}</div>
      )}

      {smallLabel && (
        <div className={styles.l2row}>
          <Body2Medium className={styles.l2smallLabel}>
            {smallLabel}
          </Body2Medium>
        </div>
      )}

      {secondParagraph && (
        <div className={styles.l2row}>
          <Body2 className={colorStyles.still400}>{secondParagraph}</Body2>
        </div>
      )}
    </div>
  );
};

const CardHeader: FunctionComponent<CardHeaderProps> = ({
  className,
  L1Props = {},
  L2Props = {},
  RProps = {},
  onClick,
}) => {
  return (
    <div
      className={cn(
        styles.cardHeader,
        { [styles.interactive]: !!onClick },
        className
      )}
      onClick={onClick}
    >
      <L1 {...L1Props} />
      <L2 {...L2Props} />
      <R {...RProps} />
    </div>
  );
};

export default CardHeader;
