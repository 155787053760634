import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const usePersonMonthlyOnlineTime = (
  personId: string,
  primaryDevice: string | null | undefined
) =>
  useUpdatedData({
    selector: selectors.onlineTime.persons.monthly,
    action: actions.onlineTime.persons.monthly.fetchData(
      personId,
      primaryDevice
    ),
    dataDependencyKey: personId,
  });

export default usePersonMonthlyOnlineTime;
