import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Mac } from 'Consts/types';

import StandardListItem from 'UI/Components/Lists/List standard';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';

import styles from './style.module.css';
import { Heading2Medium } from 'UI/Elements/Typography';

type MacAndIpAddressSidePanelProps = {
  mac: Mac;
  ip?: string;
  deviceName?: string;
};

const MacAndIpAddressSidePanel: FunctionComponent<
  MacAndIpAddressSidePanelProps
> = ({ mac, ip, deviceName }) => {
  const { t } = useTranslation();

  return (
    <SimpleSidePanel title={t('homepage.macAndIp')}>
      <SidePanelContentWrapper className={styles.sidepanelContentWrapper}>
        <Heading2Medium>{deviceName}</Heading2Medium>
        <StandardListItem
          L2Props={{
            label: t('homepage.macAddress'),
          }}
          RProps={{ label: mac }}
          ariaLabel={t('homepage.macAddress')}
        />
        <StandardListItem
          L2Props={{
            label: t('homepage.ipAddress'),
          }}
          RProps={{ label: ip || '---' }}
          ariaLabel={t('homepage.ipAddress')}
        />
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export default MacAndIpAddressSidePanel;
