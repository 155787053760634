import React from 'react';
import { TooltipProps } from 'recharts';

import { DataUnits } from 'Consts/types';

import Tooltip from 'UI/Elements/Tooltip';
import { Body2 } from 'UI/Elements/Typography';

import { displayBytes } from 'Utils/mbMath';

import colorStyles from 'Styles/colors.module.css';

const dataUsageGraphTooltip = (unit: DataUnits) => {
  return ({ label, payload }: TooltipProps<string, string>) => {
    const values = payload?.map((p) => p.value);
    const value = values?.reduce((acc, v) => acc + Number(v), 0) ?? 0;

    return (
      <Tooltip>
        <Body2>{displayBytes(value, unit)}</Body2>
        <Body2 className={colorStyles.still400}>{label}</Body2>
      </Tooltip>
    );
  };
};

export default dataUsageGraphTooltip;
