import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useSecurityEvents = () =>
  useUpdatedData({
    selector: selectors.securityEvents,
    action: actions.securityEvents.fetchData(),
  });

export default useSecurityEvents;
