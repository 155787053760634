import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import cx from 'classnames';

import { omit } from 'lodash';
import { XOR } from '../../../Consts/types';
import TextLoader from '../../Elements/TextLoader/TextLoader';
import classes from './style.module.css';

export type PendingContentProps = {
  loading: boolean;
} & Partial<
  {
    loader: FunctionComponent;
    message: ReactNode | string;
    isError: boolean;
  } & XOR<{ hideContent: boolean }, { transparent: boolean }>
>;

export type LoadableErrorProps = JSX.IntrinsicElements['div'] & {
  message?: ReactNode | string;
};

const PendingContent: FunctionComponent<
  PropsWithChildren<PendingContentProps>
> = (props) => {
  const propsToPass = omit(
    props,
    'children',
    'classes',
    'loading',
    'isError',
    'hideContent',
    'loader',
    'transparent'
  );

  const styles = {
    root: cx(classes.pendingContent, {
      [classes.pendingContent]: props.loading,
      [classes.pendingContentHideContent]: props.hideContent,
      [classes.pendingContentCurtain]: !props.transparent,
    }),
    loader: classes.pendingContentLoader,
    error: classes.pendingContentError,
  };

  const message = props.message || 'Something went wrong';
  const loaderProps = {
    visible: props.loading,
    classes: (current: any) => ({
      ...current,
      root: `${current.root} ${styles.loader}`,
    }),
  };
  const Loader = props.loader || TextLoader;

  return (
    <div {...propsToPass} className={styles.root}>
      <Loader {...loaderProps} />
      {props.isError ? (
        <PendingContentError className={styles.error} message={message} />
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
};

export const PendingContentError: FunctionComponent<LoadableErrorProps> = ({
  message,
  ...props
}) => {
  return <div {...props}>{message}</div>;
};

export default PendingContent;
