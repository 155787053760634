import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'Consts/routes';

export default function useNavigateToPod() {
  const navigate = useNavigate();

  return (podSerialNumber: string) => {
    // TODO navigate to Pod page when implemented until then navigate to settings page
    navigate(ROUTES.settings.pods);
  };
}
