import { ZoneDevice } from 'Consts/types';
import OnlineTimeGraph from 'Pages/Zones/Components/Employees/Components/Graph';
import usePersonDailyOnlineTime from 'State/hooks/usePersonDailyOnlineTime';
import usePersonMonthlyOnlineTime from 'State/hooks/usePersonMonthlyOnlineTime';
import React, { FunctionComponent } from 'react';

type OnlineActivityGraphProps = {
  personId: string;
  primaryDevice: ZoneDevice | null;
};

const OnlineActivityGraph: FunctionComponent<OnlineActivityGraphProps> = ({
  personId,
  primaryDevice,
}) => {
  const daily = usePersonDailyOnlineTime(
    personId,
    primaryDevice && primaryDevice.name
  );
  const monthly = usePersonMonthlyOnlineTime(
    personId,
    primaryDevice && primaryDevice.name
  );
  const personDailyData = daily.data?.[personId];
  const personMonthlyData = monthly.data?.[personId];

  return (
    <OnlineTimeGraph daily={personDailyData} monthly={personMonthlyData} />
  );
};

export default OnlineActivityGraph;
