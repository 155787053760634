import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styles from '../style.module.css';
import { ReactComponent as CloseIcon } from 'Assets/Icons/Icon-24/X.svg';
import useIsMobile from 'Utils/hooks/useIsMobile';

export type BenefitTooltipProps = {
  children: React.ReactNode;
  targetRef: React.RefObject<HTMLLIElement | null>;
  showTooltip: boolean;
  ariaLabel: string;
  triggerLearnMoreBtnRef: React.RefObject<HTMLButtonElement> | null;
  onCloseClick: () => void;
};

const BenefitsTooltip: FunctionComponent<BenefitTooltipProps> = ({
  children,
  targetRef,
  showTooltip,
  ariaLabel,
  triggerLearnMoreBtnRef,
  onCloseClick,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<{ top: number; left: number }>({
    top: -9999,
    left: -9999,
  });
  const isMobile = useIsMobile();
  const [mouseInteraction, setMouseInteraction] = useState(false);

  // The 'Learn more' button has been opened via mouse interaction
  document.body.addEventListener('mouseover', function () {
    setMouseInteraction(true);
  });

  // The 'Learn more' button has been opened via Enter or spacebar keypress
  document.body.addEventListener('keydown', function (event) {
    if (event.key === 'Enter' || event.key === ' ') {
      setMouseInteraction(false);
    }
  });

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        triggerLearnMoreBtnRef &&
        triggerLearnMoreBtnRef.current &&
        !triggerLearnMoreBtnRef.current.contains(event.target as Node)
      ) {
        onCloseClick();
      }
    },
    [onCloseClick, triggerLearnMoreBtnRef]
  );

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      handleClickOutside(event);
    };
    document.addEventListener('mousedown', handleMouseDown);

    return () => document.removeEventListener('mousedown', handleMouseDown);
  }, [handleClickOutside]);

  useEffect(() => {
    if (showTooltip && targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      let leftPosition =
        targetRect.left + (targetRect.width - tooltipRect.width) / 2 + 20;

      if (isMobile && leftPosition < 0) {
        leftPosition = 8;
      } else if (leftPosition + tooltipRect.width > window.innerWidth) {
        leftPosition = window.innerWidth - tooltipRect.width - 8;
      }

      setStyle({
        top: targetRect.bottom + window.scrollY - 11,
        left: leftPosition + window.scrollX,
      });
    }
  }, [showTooltip, targetRef]);

  if (!showTooltip) return null;

  return createPortal(
    <div
      className={styles.benefitsTooltip}
      ref={tooltipRef}
      style={style}
      role="dialog"
      aria-label={ariaLabel}
    >
      <div>{children}</div>
      <button
        className={styles.benefitsTooltipClose}
        onClick={onCloseClick}
        autoFocus={!mouseInteraction}
        id="closeBtn"
      >
        <CloseIcon />
      </button>
    </div>,
    document.body
  );
};

export default BenefitsTooltip;
