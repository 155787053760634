import type { InternetSpeed, Period, SpeedDimension } from 'Consts/types';

import { formatGraphDateLabel } from 'Utils/formatters/date';

import type { BarGraphData } from '../../Components/Graphs/types';

type SpeedBarGraphData = BarGraphData<'speed'>;

export const chartToData = (
  chart: InternetSpeed[] | null,
  period: Period,
  speedDimension: SpeedDimension
): {
  data: SpeedBarGraphData[];
  minSpeed: number | null;
  maxSpeed: number | null;
} => {
  let minSpeed: number | null = null;
  let maxSpeed: number | null = null;

  const graphData: SpeedBarGraphData[] =
    chart?.reduce((data, entry) => {
      if (entry) {
        const speed = entry[speedDimension] ?? 0;

        if (speed && (!minSpeed || speed < minSpeed)) {
          minSpeed = speed;
        }

        if (speed && (!maxSpeed || speed > maxSpeed)) {
          maxSpeed = speed;
        }

        data.push({
          name: formatGraphDateLabel(new Date(entry.timestamp), period),
          values: { speed },
        });
      }

      return data;
    }, [] as SpeedBarGraphData[]) ?? [];

  return {
    data: graphData,
    minSpeed,
    maxSpeed,
  };
};
