import cn from 'classnames';
import type { BandwidthLimit } from 'Consts/types';
import {
  copyBlobToClipboard,
  createImageElement,
  getBlobFromImageElement,
} from 'copy-image-clipboard';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import Alert from 'UI/Components/Alert';
import SettingsSectionHeader from 'UI/Components/Headers/SettingsSectionHeader';
import StandardListItem from 'UI/Components/Lists/List standard';

import { BUTTON_THEMES } from 'UI/Elements/Button';
import Card from 'UI/Elements/Card';
import Divider from 'UI/Elements/Divider';
import { IconName, IconNames } from 'UI/Elements/Icon';
import AlertModal from 'UI/Elements/Modals/Alerts';
import { Body2, Body3 } from 'UI/Elements/Typography';

import { copyToClipboard, stringToDots } from 'Utils/string';

import * as actions from 'State/actions';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import useWifiSettings from 'State/hooks/useWifiSettings';
import * as selectors from 'State/selectors';
import type { AppDispatch } from 'State/store';

import { SETTINGS_LIST, WIFI_SETTINGS } from '../../../definitions';

import colorStyles from 'Styles/colors.module.css';

import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import useLocationCapabilities from 'State/hooks/useLocationCapabilities';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import EmployeeLoginDisableWarningModal from './Components/EmployeeLogin/EmployeeLoginDisableWarningModal';
import EmployeeLoginSetting from './Components/EmployeeLogin/EmployeeLoginSetting';
import styles from './style.module.css';

type NameSectionProps = {
  label: string;
  iconName: IconName;
  onEditName: (ev: React.MouseEvent) => void;
};

type PasswordSectionProps = {
  label: string;
  password: string;
  onEditPassword: (ev: React.MouseEvent) => void;
};

type QRCodeProps = {
  ssid: string;
  password?: string;
  encryption: 'blank' | 'WPA' | 'WEP';
  showCopyIcon?: boolean;
  showPrintIcon?: boolean;
};

type LimitAccessProps = {
  label: string;
  purgatory: boolean;
  onNetworkAccessChange: React.ChangeEventHandler;
};

type BandwidthLimitModalProps = {
  isOpen: boolean;
  bandwidthLimit: BandwidthLimit;
  onClose: () => void;
};

type GuestWifiConfigProps = {
  onEditName: (ev: React.MouseEvent) => void;
};

const NameSection: FunctionComponent<NameSectionProps> = ({
  label,
  iconName,
  onEditName,
}) => {
  const { t } = useTranslation();

  return (
    <Card noBottomPadding>
      <StandardListItem
        L1Props={{ iconProps: { name: iconName } }}
        L2Props={{ label }}
        RProps={{
          icon1Props: {
            name: IconNames.Edit,
            onClick: onEditName,
            tooltipLabel: t('common.edit'),
          },
        }}
        ariaLabel={label}
      />
    </Card>
  );
};

const PasswordSection: FunctionComponent<PasswordSectionProps> = ({
  label,
  password,
  onEditPassword,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);
  const toggleShow = useCallback(() => setShow((oldShow) => !oldShow), []);

  const handleCopyPassword = useCallback(async () => {
    const res = await copyToClipboard(password || '');

    if (res) {
      dispatch(
        actions.ui.miniBanner.notify({
          label: t('settings.copiedToClipboard'),
          state: 'set',
        })
      );
    }
  }, [dispatch, password, t]);

  const prevLabel = useRef('');

  useEffect(() => {
    if (label !== prevLabel.current) {
      setShow(false);

      prevLabel.current = label;
    }
  }, [label]);

  return (
    <div>
      <SettingsSectionHeader
        className={styles.password}
        LProps={{ smallLabel: label }}
        RProps={{
          label: t(show ? 'settings.hide' : 'settings.show'),
          onClick: toggleShow,
        }}
      />

      <Card noBottomPadding>
        <StandardListItem
          L1Props={{ iconProps: { name: IconNames.Key } }}
          L2Props={{ label: show ? password : stringToDots(password || '') }}
          RProps={{
            icon1Props: {
              name: IconNames.Copy,
              onClick: handleCopyPassword,
              tooltipLabel: t('common.copy'),
            },
            icon2Props: {
              name: IconNames.Edit,
              onClick: onEditPassword,
              tooltipLabel: t('common.edit'),
            },
          }}
          ariaLabel={label}
        />
      </Card>
    </div>
  );
};

const QRCodeCard: FunctionComponent<QRCodeProps> = ({
  ssid,
  password,
  encryption,
  showCopyIcon,
  showPrintIcon,
}) => {
  const { t } = useTranslation();
  const qrDivRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<SVGSVGElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const handlePrint = useReactToPrint({
    content: () => qrDivRef.current,
  });

  const constructQrCodeValueForNetwork = () => {
    return (
      'WIFI:S:' +
      ssid +
      (encryption !== 'blank' ? ';T:' + encryption + ';P:' + password : '') +
      ';;'
    );
  };

  const copyQrCode = () => {
    if (qrDivRef.current) {
      innerRef.current = qrDivRef.current.querySelector('svg');
      try {
        if (innerRef) {
          const svgData = new XMLSerializer().serializeToString(
            innerRef.current!
          );
          const img = new Image();
          img.src =
            'data:image/svg+xml;base64,' +
            window.btoa(decodeURIComponent(encodeURIComponent(svgData)));
          img.width = 200;
          img.height = 200;

          createImageElement(img.src).then((imageCreated) => {
            getBlobFromImageElement(img).then((blob: Blob) => {
              return copyBlobToClipboard(blob);
            });
          });

          dispatch(
            actions.ui.miniBanner.notify({
              label: t('settings.copiedToClipboard'),
              state: 'set',
            })
          );
        }
      } catch (error) {
        dispatch(
          actions.ui.miniBanner.notify({
            label: t('settings.copiedToClipboardFailed'),
            state: 'unset',
          })
        );
      }
    }
  };

  const qrForNetwork = (
    <div ref={qrDivRef} className={styles.qrCode}>
      <QRCode id="qrCode" value={constructQrCodeValueForNetwork()} size={64} />
    </div>
  );

  return (
    <>
      <Card noBottomPadding>
        <StandardListItem
          L1Props={{ childComponent: qrForNetwork }}
          L2Props={{ label: t('settings.connectViaQRCode') }}
          RProps={{
            icon1Props: showPrintIcon
              ? {
                  name: 'Printer',
                  onClick: handlePrint,
                  tooltipLabel: t('settings.print'),
                }
              : undefined,
            icon2Props: showCopyIcon
              ? {
                  name: 'Copy',
                  onClick: copyQrCode,
                  tooltipLabel: t('settings.copy'),
                }
              : undefined,
          }}
          ariaLabel={t('settings.connectViaQRCode')}
        />
      </Card>
      <Body3 className={cn(colorStyles.still400, styles.description)}>
        {t('settings.connectViaQRCodeDescription', { ssid })}
      </Body3>
    </>
  );
};

const LimitAccess: FunctionComponent<LimitAccessProps> = ({
  label,
  purgatory,
  onNetworkAccessChange,
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();

  const showModal = useCallback(() => setShowMoreInfo(true), []);
  const hideModal = useCallback(() => setShowMoreInfo(false), []);

  return (
    <>
      <Card noBottomPadding>
        <StandardListItem
          L1Props={{ iconProps: { name: IconNames.Hand } }}
          L2Props={{ label: t('settings.newDeviceLimitText') }}
          RProps={{
            toggleProps: {
              onChange: onNetworkAccessChange,
              checked: purgatory,
              ariaLabel: t('settings.newDeviceLimitText'),
            },
          }}
          ariaLabel={t('settings.newDeviceLimitText')}
        />
      </Card>

      <Body3 className={cn(colorStyles.still400, styles.description)}>
        {t('settings.manuallyApproveText', { label })}
        <span className={styles.link} onClick={showModal} tabIndex={0}>
          {' ' + t('settings.moreInfo')}
        </span>
      </Body3>

      <AlertModal isOpen={showMoreInfo} onClose={hideModal}>
        <Alert
          topProps={{
            label: t('settings.moreInfo'),
            paragraph:
              t('settings.waitingApprovalText', { ns: namespace }) || '',
            className: styles.modalBody,
          }}
          bottomProps={{
            button1Props: {
              label: t('common.ok'),
              onClick: hideModal,
            },
          }}
        />
      </AlertModal>
    </>
  );
};

// TODO add type for selectors? or maybe just send data
type ProtectedWifiConfigProps = {
  label: string;
  uniqueKey: 'secure' | 'employee';
  networkId: 'default' | 'employee';
} & {
  onEditName: (ev: React.MouseEvent) => void;
  onEditPassword: (ev: React.MouseEvent) => void;
  onEditEmployeeLogin?: (ev: React.MouseEvent) => void;
  onOpenBlockedEmployees?: (ev: React.MouseEvent) => void;
};

export const ProtectedWifiConfig: FunctionComponent<
  ProtectedWifiConfigProps
> = ({
  label,
  uniqueKey,
  networkId,
  onEditName,
  onEditPassword,
  onEditEmployeeLogin,
  onOpenBlockedEmployees,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const networkAccessData = useNetworkAccess();
  const allWifiData = useWifiSettings();

  const networkAccess = networkAccessData.data?.[networkId];
  const wifi = allWifiData.data?.[networkId];

  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();

  const { data: locationCapabilitiesData } = useLocationCapabilities();

  const employeeLoginLiabilityDisclaimer = useSelector(
    selectors.settings.employeeLogin
  ).data?.databaseData.liabilityDisclaimer;

  const handleNetworkAccessChange = useCallback(() => {
    dispatch(
      actions.settings.networkAccess.update({
        purgatory: !networkAccess?.purgatory,
        networkId,
      })
    );
  }, [dispatch, networkId, networkAccess?.purgatory]);

  const [showDisableWarningModal, setShowDisableWarningModal] = useState(false);

  const errorMessage =
    allWifiData.errorMessage || networkAccessData.errorMessage;
  const isLoading = allWifiData.isLoading || networkAccessData.isLoading;

  if (errorMessage || isLoading || !wifi || !networkAccess) {
    return (
      <Card
        noBottomPadding
        isLoading={isLoading}
        errorMessage={errorMessage}
        emptyCardLabel={t('common.noData')}
      />
    );
  }

  return (
    <div
      role="region"
      className={styles.wifiConfigSection}
      aria-labelledby={
        uniqueKey === SETTINGS_LIST.secure
          ? t('settings.secure')
          : t('settings.employee')
      }
    >
      <Body2 className={cn(colorStyles.still400, styles.description)}>
        {t('settings.wifiSetupText', { label })}
      </Body2>

      <NameSection
        label={wifi.ssid}
        iconName={WIFI_SETTINGS[uniqueKey].iconName}
        onEditName={(ev) => onEditName(ev)}
      />

      {wifi.encryptionKey && (
        <PasswordSection
          label={t('settings.passwordText', { label })}
          password={wifi.encryptionKey}
          onEditPassword={(ev) => onEditPassword(ev)}
        />
      )}
      {wifi.encryptionKey && (
        <QRCodeCard
          ssid={wifi.ssid}
          encryption={'WPA'}
          password={wifi.encryptionKey}
          showCopyIcon={true}
          showPrintIcon={false}
        />
      )}
      <LimitAccess
        label={wifi.ssid}
        purgatory={networkAccess.purgatory}
        onNetworkAccessChange={handleNetworkAccessChange}
      />

      {uniqueKey === 'employee' &&
        customerSupportConfigurations?.customerFeatureEnablement
          .employeeOnboardingCaptivePortalEnabled &&
        locationCapabilitiesData?.state.capabilities?.captivePortalV2 && (
          <EmployeeLoginSetting
            onEditEmployeeLogin={onEditEmployeeLogin}
            onDisableFeature={() => setShowDisableWarningModal(true)}
            onOpenBlockedEmployees={onOpenBlockedEmployees}
          />
        )}
      <EmployeeLoginDisableWarningModal
        isOpen={showDisableWarningModal}
        onClose={() => setShowDisableWarningModal(false)}
        liabilityText={employeeLoginLiabilityDisclaimer}
      />
    </div>
  );
};

const bandwidthLimitTypes = {
  percentage: '%',
  absolute: 'Mbps',
};
const defaultBandwidth: BandwidthLimit = {
  enabled: true,
  type: 'percentage',
  upload: 100,
  download: 100,
};

const BandwidthLimitModal: FunctionComponent<BandwidthLimitModalProps> = ({
  isOpen,
  bandwidthLimit,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [newBandwidthLimit, setNewBandwidthLimit] = useState(
    bandwidthLimit.download.toString()
  );

  const handleChange = useCallback((val: string) => {
    const toInt = parseInt(val);

    if ((toInt >= 0 && toInt <= 100) || val === '') {
      setNewBandwidthLimit(val);
    }
  }, []);

  const handleBandwidthLimitUpdate = useCallback(() => {
    onClose();

    dispatch(
      actions.settings.wifi.updateGuestsWifiData({
        bandwidthLimit: {
          ...bandwidthLimit,
          upload: Number(newBandwidthLimit),
          download: Number(newBandwidthLimit),
        },
      })
    );
  }, [bandwidthLimit, dispatch, newBandwidthLimit, onClose]);

  return (
    <AlertModal isOpen={isOpen} onClose={onClose}>
      <Alert
        topProps={{ label: t('settings.limitUsage') }}
        middleProps={{
          listInputProps: {
            value: newBandwidthLimit,
            onChange: handleChange,
            inputType: 'number',
            onSubmit: handleBandwidthLimitUpdate,
          },
        }}
        bottomProps={{
          button1Props: {
            label: t('common.save'),
            onClick: handleBandwidthLimitUpdate,
          },
          button2Props: {
            label: t('common.cancel'),
            onClick: onClose,
            theme: BUTTON_THEMES.white,
          },
        }}
      />
    </AlertModal>
  );
};

export const GuestWifiConfig: FunctionComponent<GuestWifiConfigProps> = ({
  onEditName,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const wifiData = useSelector(selectors.settings.wifi.guest);
  const captivePortalUrl = useSelector(
    selectors.settings.wifi.captivePortalUrl
  );
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();

  const [isEnabled, setEnabled] = useState(wifiData?.enable);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  let bandwidthLimit = wifiData?.bandwidthLimit ?? defaultBandwidth;

  const [isDownloadLimitEnabled, setDownloadLimitEnabled] = useState(false);
  const { data: locationCapabilitiesData } = useLocationCapabilities();

  const handleDownloadLimitToggle = useCallback(() => {
    dispatch(
      actions.settings.wifi.updateGuestsWifiData({
        bandwidthLimit: {
          ...bandwidthLimit,
          enabled: !isDownloadLimitEnabled,
        },
      })
    );
    setDownloadLimitEnabled((prevState) => !prevState);
  }, [dispatch, isDownloadLimitEnabled, bandwidthLimit]);

  const handleOpenModal = useCallback(() => setModalIsOpen(true), []);
  const handleCloseModal = useCallback(() => setModalIsOpen(false), []);

  const handleEnableChange = useCallback(() => {
    setEnabled(!isEnabled);
    dispatch(
      actions.settings.wifi.updateGuestsWifiData({ enable: !isEnabled })
    );
  }, [dispatch, isEnabled]);

  useEffect(() => {
    setEnabled(wifiData?.enable);
    setDownloadLimitEnabled(
      wifiData?.bandwidthLimit?.enabled ?? defaultBandwidth.enabled
    );
  }, [wifiData]);

  if (!wifiData) {
    return (
      <div className={styles.errorNoNetwork}>
        {t('settings.useMobileToSetupGuest', { ns: namespace })}
      </div>
    );
  }

  return (
    <div
      role="region"
      aria-labelledby={t('settings.guest')}
      className={styles.wifiConfigSection}
    >
      <Body2 className={cn(colorStyles.still400, styles.description)}>
        {t('settings.guestSetupText', { ns: namespace })}
      </Body2>
      <NameSection
        label={wifiData.ssid}
        iconName={WIFI_SETTINGS.guest.iconName}
        onEditName={(ev) => onEditName(ev)}
      />
      <Card noBottomPadding>
        <StandardListItem
          L2Props={{
            label: t('settings.guestWifiEnabled', { ns: namespace }) || '',
          }}
          RProps={{
            toggleProps: {
              onChange: handleEnableChange,
              checked: isEnabled,
              ariaLabel: t('settings.guestWifiEnabled'),
            },
          }}
          ariaLabel={t('settings.guestWifiEnabled')}
        />
      </Card>
      {locationCapabilitiesData?.state.capabilities?.bandwidthThrottling &&
        isEnabled && (
          <>
            <Card>
              <StandardListItem
                L2Props={{
                  label: t('settings.limitUsage', { ns: namespace }) || '',
                }}
                RProps={{
                  toggleProps: {
                    onChange: handleDownloadLimitToggle,
                    checked: isDownloadLimitEnabled,
                    ariaLabel: t('settings.limitUsage'),
                  },
                }}
                ariaLabel={t('settings.limitUsage')}
              />

              {isDownloadLimitEnabled && (
                <>
                  <Divider />
                  <StandardListItem
                    L2Props={{
                      label:
                        bandwidthLimit.download +
                        bandwidthLimitTypes[bandwidthLimit.type],
                    }}
                    RProps={{
                      icon1Props: {
                        name: IconNames.Edit,
                        onClick: handleOpenModal,
                        tooltipLabel: t('common.edit'),
                      },
                    }}
                    ariaLabel={
                      bandwidthLimit.download +
                      bandwidthLimitTypes[bandwidthLimit.type]
                    }
                  />
                </>
              )}
            </Card>
            <Body3 className={cn(colorStyles.still400, styles.description)}>
              {t('settings.guestLimitText', { ns: namespace })}
            </Body3>
          </>
        )}

      {isEnabled && (
        <>
          <QRCodeCard
            ssid={wifiData.ssid}
            showCopyIcon={true}
            showPrintIcon={true}
            encryption="blank"
          />
          <Card noBottomPadding>
            <StandardListItem
              onClick={() => window.open(captivePortalUrl, '_blank')}
              L1Props={{ iconProps: { name: IconNames.Globe } }}
              L2Props={{ label: t('settings.guestLoginPortal') }}
              RProps={{
                icon1Props: {
                  name: IconNames.ChevronRight,
                  className: colorStyles.still400,
                },
              }}
              ariaLabel={t('settings.guestLoginPortal')}
            />
          </Card>
        </>
      )}
      <BandwidthLimitModal
        isOpen={modalIsOpen}
        bandwidthLimit={bandwidthLimit}
        onClose={handleCloseModal}
      />
    </div>
  );
};
