import { ROUTES } from 'Consts/routes';
import useDefaultMonthlyAppsOnlineTime from 'State/hooks/useDefaultMonthlyAppsOnlineTime';

import { AppTimeProps, AppTimeUI } from 'UI/Reusable/AppTime';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

const AppTimeSecureZone: FunctionComponent<AppTimeProps> = ({
  className,
  maxItems,
}) => {
  const secureAppsTime = useDefaultMonthlyAppsOnlineTime();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <AppTimeUI
      className={className}
      maxItems={maxItems}
      isLoading={secureAppsTime.isLoading}
      errorMessage={secureAppsTime.errorMessage}
      applications={secureAppsTime.data?.dailyStats?.[0]?.apps ?? []}
      title={t('common.appTime') as string}
      viewAllHandler={() =>
        navigate(
          generatePath(ROUTES.appTimeInsightsByNetworkId, {
            network: 'default',
          })
        )
      }
    />
  );
};

export default AppTimeSecureZone;
