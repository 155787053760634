import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EMAIL_REGEX } from 'Consts/defintions';

import InputListItem from 'UI/Components/Lists/List input';

import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { IconNames } from 'UI/Elements/Icon';

import * as api from 'Api/endpoints';
import * as selectors from 'State/selectors';

import {
  OnboardingPageWrapper,
  OnboardingPageTopContent,
  OnboardingPageBottomContent,
} from '../Components/Onboarding page wrapper';
import ResetResendButton from '../Components/Reset password button';

import onboardingStyles from '../style.module.css';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';

const ResetPasswordPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cloud = useSelector(selectors.auth.cloud);

  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const trackEvent = useTrackEvent();

  const handleChange = useCallback((value: string) => {
    const trimmedEmail = value.trim();
    setError('');
    setEmail(trimmedEmail);
  }, []);

  const handleEmailSent = useCallback(() => {
    setEmailSent(true);
    setTimeout(() => {
      setEmailSent(false);
    }, 5000);
  }, []);

  const handleSend = useCallback(async () => {
    if (email && !EMAIL_REGEX.test(email)) {
      setError(() => t('onboarding.errorInvalidEmail'));
    } else if (!email) {
      setError(() => t('onboarding.errorEmptyField'));
    } else {
      const res = await api.resetPassword({
        email,
        deepLink: 'plumeSmallBusiness://login',
        cloud,
      });

      if (res.error) {
        setError(res.error.message);
      } else if (res) {
        handleEmailSent();
      }
    }
  }, [cloud, email, handleEmailSent, t]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.ResetPasswordPage,
      },
    });
  }, []);

  return (
    <OnboardingPageWrapper
      label={t('onboarding.resetPassword')}
      subLabel={t('onboarding.resetPasswordSubLabel')}
    >
      <OnboardingPageTopContent>
        <InputListItem
          className={onboardingStyles.stretch}
          prefixIcon={IconNames.EmailIcon}
          smallLabel={t('onboarding.yourEmail')}
          paragraph={error}
          value={email}
          onChange={handleChange}
          hasError={Boolean(error)}
          onSubmit={handleSend}
        />
      </OnboardingPageTopContent>

      <OnboardingPageBottomContent>
        <ResetResendButton
          label={t('onboarding.resetPassword')}
          emailSent={emailSent}
          onClick={handleSend}
        />
        <Button
          theme={BUTTON_THEMES.noborder}
          label={t('common.back')}
          onClick={handleBack}
        />
      </OnboardingPageBottomContent>
    </OnboardingPageWrapper>
  );
};

export default ResetPasswordPage;
