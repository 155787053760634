import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useTopology = () =>
  useUpdatedData({
    selector: selectors.topology,
    action: actions.topology.fetchData(),
  });

export default useTopology;
