import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Period, SpeedDimension } from 'Consts/types';
import { PERIODS } from 'Consts/defintions';

import {
  DailyDataUsageGraphZones,
  DailyDataUsageGraphData,
  MonthlyDataUsageGraphData,
  MonthlyDataUsageGraphZones,
} from 'UI/Reusable/Data usage graph/Zones';
import { ZoneFilterNames } from 'UI/Reusable/Data usage graph/Zones/utils';

type DataUsageProps = {
  period: Period;
  speedDimension: SpeedDimension;
  isLoading?: boolean;
  errorMessage?: string;
  dailyDataUsage: DailyDataUsageGraphData | null;
  monthlyDataUsage: MonthlyDataUsageGraphData | null;
};

const DataUsage: FunctionComponent<DataUsageProps> = ({
  period,
  isLoading,
  errorMessage,
  speedDimension,
  dailyDataUsage,
  monthlyDataUsage,
}) => {
  const { t } = useTranslation();
  const label = t('common.dataUsage');
  const [activeZoneFilter, setActiveZoneFilter] =
    useState<ZoneFilterNames>('all');

  if (period === PERIODS.day) {
    return (
      <DailyDataUsageGraphZones
        isLoading={isLoading}
        errorMessage={errorMessage}
        speedDimension={speedDimension}
        data={dailyDataUsage}
        label={label}
        initialZone={activeZoneFilter}
        onZoneChange={(filter: ZoneFilterNames) => {
          setActiveZoneFilter(filter);
        }}
      />
    );
  }

  return (
    <MonthlyDataUsageGraphZones
      isLoading={isLoading}
      errorMessage={errorMessage}
      period={period}
      speedDimension={speedDimension}
      data={monthlyDataUsage}
      label={label}
      initialZone={activeZoneFilter}
      onZoneChange={(filter: ZoneFilterNames) => {
        setActiveZoneFilter(filter);
      }}
    />
  );
};

export default DataUsage;
