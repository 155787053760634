import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'UI/Elements/Card';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import StandardListItem from 'UI/Components/Lists/List standard';
import Divider from 'UI/Elements/Divider';
import styles from './style.module.css';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { BusinessDayType, AmPmType, BusinessHoursAmPm } from './types';
import InputListItem from 'UI/Components/Lists/List input';
import { IconNames } from 'UI/Elements/Icon';
import { Body1Medium } from 'UI/Elements/Typography';
import BusinessInfoHoursSidepanel from './BusinessInfoHoursSidepanel';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import { OpenTriggerEventType } from 'Consts/types';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { useTrapFocus } from 'Utils/accessibility/useTrapFocus';

type OpenCloseHoursSidepanelProps = {
  day: BusinessDayType;
};

const OpenCloseHoursSidepanel: FunctionComponent<
  OpenCloseHoursSidepanelProps
> = ({ day }) => {
  const { t } = useTranslation();
  const { handleTriggerEvent } = useTrapFocus();
  const [openBusinessHours, setOpenBusinessHours] = useState('');
  const [closeBusinessHours, setCloseBusinessHours] = useState('');
  const [openHours, setOpenHours] = useState('');
  const [closeHours, setCloseHours] = useState('');
  const [openMinutes, setOpenMinutes] = useState('');
  const [closeMinutes, setCloseMinutes] = useState('');
  const [openHoursError, setOpenHoursError] = useState('');
  const [closeHoursError, setCloseHoursError] = useState('');
  const [openAmPm, setOpenAmPm] = useState<AmPmType>(BusinessHoursAmPm.AM);
  const [closeAmPm, setCloseAmPm] = useState<AmPmType>(BusinessHoursAmPm.AM);
  const dispatchReduxAction = useDispatch<AppDispatch>();
  const { setContent } = useSidepanel();
  console.log(openHours, openMinutes, closeHours, closeMinutes);

  useEffect(() => {
    const hours = openBusinessHours.split('/')[0];
    const minutes = openBusinessHours.split('/')[1];

    setOpenHours(hours);
    setOpenMinutes(minutes);
    if (Number(hours) >= 12) {
      setOpenAmPm(BusinessHoursAmPm.PM);
    }
  }, [openBusinessHours]);

  useEffect(() => {
    const hours = closeBusinessHours.split('/')[0];
    const minutes = closeBusinessHours.split('/')[1];

    setCloseHours(hours);
    setCloseMinutes(minutes);
    if (Number(hours) >= 12) {
      setCloseAmPm(BusinessHoursAmPm.PM);
    }
  }, [closeBusinessHours]);

  useEffect(() => {
    if (openBusinessHours !== '') {
      /^([01]?[0-9]|2[0-3])?(:([0-5][0-9])?)?$/.test(openBusinessHours)
        ? setOpenHoursError('')
        : setOpenHoursError(t('settings.businessInfo.hoursMinutesError'));
    }
    if (closeBusinessHours !== '') {
      /^([01]?[0-9]|2[0-3])?(:([0-5][0-9])?)?$/.test(closeBusinessHours)
        ? setCloseHoursError('')
        : setCloseHoursError(t('settings.businessInfo.hoursMinutesError'));
    }
  }, [openBusinessHours, closeBusinessHours]);

  const handleSidepanelCancel = (ev: React.MouseEvent) => {
    handleTriggerEvent(ev);

    dispatchReduxAction(
      actions.ui.page.setSidepanelOpenTriggerType(
        ev.type as OpenTriggerEventType
      )
    );
    setContent(<BusinessInfoHoursSidepanel />);
  };

  return (
    <SimpleSidePanel
      title={t(`settings.businessInfo.businessHoursDays.${day}`)}
      rightLabelProps={{
        label: t('common.cancel'),
        onClick: handleSidepanelCancel,
        className: styles.cancelBtn,
      }}
      hideCollapseIcon
    >
      {' '}
      <SidePanelContentWrapper>
        <div className={styles.openCloseCards}>
          <Body1Medium>{t('settings.businessInfo.timeOpen')}</Body1Medium>
          <Card className={styles.timeEditInputCard} noBottomPadding>
            <InputListItem
              smallLabel={t('settings.businessInfo.hoursMinutes')}
              value={openBusinessHours}
              onChange={(e) => setOpenBusinessHours(e)}
              noIcon
              paragraph={openHoursError}
              hasError={!!openHoursError}
            />
          </Card>
          <Card noBottomPadding>
            <StandardListItem
              ariaLabel={t('settings.businessInfo.am')}
              L2Props={{
                label: t('settings.businessInfo.am'),
                className: styles.amPmLabel,
              }}
              RProps={
                openAmPm === BusinessHoursAmPm.AM
                  ? {
                      icon1Props: {
                        ariaLabel: '',
                        name: IconNames.Check,
                      },
                    }
                  : undefined
              }
              onClick={() => setOpenAmPm(BusinessHoursAmPm.AM)}
            />
            <Divider />
            <StandardListItem
              ariaLabel={t('settings.businessInfo.pm')}
              L2Props={{
                label: t('settings.businessInfo.pm'),
                className: styles.amPmLabel,
              }}
              RProps={
                openAmPm === BusinessHoursAmPm.PM
                  ? {
                      icon1Props: {
                        ariaLabel: '',
                        name: IconNames.Check,
                      },
                    }
                  : undefined
              }
              onClick={() => setOpenAmPm(BusinessHoursAmPm.PM)}
            />
          </Card>
        </div>
        <div className={styles.openCloseCards}>
          <Body1Medium>{t('settings.businessInfo.timeClose')}</Body1Medium>
          <Card className={styles.timeEditInputCard} noBottomPadding>
            <InputListItem
              smallLabel={t('settings.businessInfo.hoursMinutes')}
              value={closeBusinessHours}
              onChange={(e) => setCloseBusinessHours(e)}
              noIcon
              paragraph={closeHoursError}
              hasError={!!closeHoursError}
            />
          </Card>
          <Card noBottomPadding>
            <StandardListItem
              ariaLabel={t('settings.businessInfo.am')}
              L2Props={{
                label: t('settings.businessInfo.am'),
                className: styles.amPmLabel,
              }}
              RProps={
                closeAmPm === BusinessHoursAmPm.AM
                  ? {
                      icon1Props: {
                        ariaLabel: '',
                        name: IconNames.Check,
                      },
                    }
                  : undefined
              }
              onClick={() => setCloseAmPm(BusinessHoursAmPm.AM)}
            />
            <Divider />
            <StandardListItem
              ariaLabel={t('settings.businessInfo.pm')}
              L2Props={{
                label: t('settings.businessInfo.pm'),
                className: styles.amPmLabel,
              }}
              RProps={
                closeAmPm === BusinessHoursAmPm.PM
                  ? {
                      icon1Props: {
                        ariaLabel: '',
                        name: IconNames.Check,
                      },
                    }
                  : undefined
              }
              onClick={() => setCloseAmPm(BusinessHoursAmPm.PM)}
            />
          </Card>
        </div>
        <Button
          className={styles.sidepanelSaveBtn}
          theme={BUTTON_THEMES.black}
          label={t('common.save')}
          onClick={() => console.log('save')}
        />
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};
export default OpenCloseHoursSidepanel;
