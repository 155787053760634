import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDefaultLast12MonthsAppsOnlineTime = () =>
  useUpdatedData({
    selector: selectors.appsOnlineTime.default.last12months,
    action: actions.appsOnlineTime.default.last12months.fetchData(),
  });

export default useDefaultLast12MonthsAppsOnlineTime;
