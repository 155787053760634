import Alert from 'UI/Components/Alert';
import { BUTTON_THEMES } from 'UI/Elements/Button';
import AlertModal from 'UI/Elements/Modals/Alerts';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import styles from './style.module.css';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';

type EmployeeLoginDisableWarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
  liabilityText: string | null | undefined;
};

const EmployeeLoginDisableWarningModal: FunctionComponent<
  EmployeeLoginDisableWarningModalProps
> = ({ isOpen, onClose, liabilityText }) => {
  const dispatch = useDispatch<AppDispatch>();
  const trackEvent = useTrackEvent();
  const handleAgree = () => {
    dispatch(
      actions.settings.employeeLogin.updateEmployeeLoginEnabled({
        enabled: false,
      })
    );

    trackEvent({
      eventName: MixPanelEvents.EMPLOYEE_LOGIN_ENABLEMENT,
      additionalContent: {
        ENABLE: false,
      },
    });

    onClose();
  };

  return (
    <AlertModal isOpen={isOpen} onClose={onClose}>
      <Alert
        className={styles.disableModal}
        topProps={{
          label: t('settings.employeeLoginConfigurationDisableWarning'),
        }}
        middleProps={{
          paragraph: liabilityText || '',
        }}
        bottomProps={{
          button1Props: {
            label: t('common.agree'),
            onClick: handleAgree,
          },
          button2Props: {
            label: t('common.cancel'),
            onClick: onClose,
            theme: BUTTON_THEMES.white,
          },
        }}
      />
    </AlertModal>
  );
};

export default EmployeeLoginDisableWarningModal;
