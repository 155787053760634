import { useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

import useConditionalUpdate from './utils/useConditionalUpdate';

const useLocationAtWorkEvents = () => {
  const atWorkEvents = useSelector(selectors.locationAtWorkEvents);
  useConditionalUpdate({
    data: atWorkEvents,
    dependencies: [],
    action: actions.locationAtWorkEvents.fetchData(),
  });

  return atWorkEvents;
};

export default useLocationAtWorkEvents;
