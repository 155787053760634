import { NetworkType } from 'Consts/types';
import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const useDeviceGroup = (networkId?: NetworkType) =>
  useUpdatedData({
    selector: selectors.zones.deviceGroups,
    action: actions.zones.deviceGroups.fetchData(networkId),
    dataDependencyKey: networkId,
  });

export default useDeviceGroup;
