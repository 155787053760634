import React, { useEffect, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from 'trackingAnalytics/types';
import useQuery from 'Utils/hooks/useQueryParams';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { ROUTES } from 'Consts/routes';
import { useTranslation } from 'react-i18next';

const PartnerAuthenticationCallbackPage: FunctionComponent = () => {
  const trackEvent = useTrackEvent();
  const environment = getConfigurationFromDomain();
  const query = useQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const authError = useSelector(selectors.auth.authError);
  const partnerId = environment?.partnerId;
  const appId = environment?.ssoAppId;

  useEffect(() => {
    //code & state should be in the query else redirect back
    if (
      environment?.authenticationsMethodsPermitted.includes('partnerSso') &&
      query.has('code') &&
      query.has('state')
    ) {
      // code and state are delivered - call API to get token
      dispatch(
        actions.auth.loginSSOCallback({
          code: query.get('code') || '',
          state: query?.get('state') || '',
          appId: appId,
          partnerId: partnerId || '',
        })
      );
    } else {
      // no params sending back to onboarding landing page with an error
      dispatch(
        actions.auth.error(
          query.get('error_description') ||
            query.get('error') ||
            'Authentication failed'
        )
      );
      navigate(ROUTES.onboarding.index);
    }
  }, [query]);

  useEffect(() => {
    if (authError) {
      navigate(ROUTES.onboarding.index);
    }
  }, [authError]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.PartnerAuthenticationCallback,
      },
    });
  }, []);

  return <>{t('common.loading')}</>;
};

export default PartnerAuthenticationCallbackPage;
