import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const usePersonLast12MonthsAppsOnlineTime = (personId: string) =>
  useUpdatedData({
    selector: selectors.appsOnlineTime.persons.last12months,
    action: actions.appsOnlineTime.persons.last12months.fetchData(personId),
    dataDependencyKey: personId,
  });

export default usePersonLast12MonthsAppsOnlineTime;
