import { useRef } from 'react';
import { FilteredListType } from 'UI/Components/AutoComplete/helpers/filteredList.helper';
import { ListSearchDropdownResultsItemType } from '../ListSearchDropdownResults';

export const useSearchDropdownListItemRefs = (
  itemsListForRefs: ListSearchDropdownResultsItemType[] | FilteredListType[]
): React.MutableRefObject<(HTMLDivElement | null)[]> => {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  if (itemRefs.current.length !== itemsListForRefs.length) {
    itemRefs.current = Array(itemsListForRefs.length).fill(null);
  }

  return itemRefs;
};
