import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import Icon, { IconName } from 'UI/Elements/Icon';
import PillSmall from 'UI/Elements/Pill small';
import L1, { L1Props } from 'UI/Elements/L1';
import {
  Body1Medium,
  Body1,
  Body2Medium,
  Body2,
  Heading2Medium,
  Heading3Medium,
} from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import { Nullable } from '../../../../Consts/types';

export type L2Props = {
  label?: Nullable<string>;
  labelBold?: boolean;
  subTitleBold?: boolean;
  subTitleOnTop?: boolean;
  bigLabel?: Nullable<string>;
  bigLabelBig?: boolean;
  subTitle?: Nullable<string>;
  className?: string;
};

export type RProps = {
  iconName?: IconName;
  smallPillLabel?: string;
};

type HorizontalTileProps = {
  className?: string;
  L1Props?: L1Props;
  L2Props?: L2Props;
  RProps?: RProps;
};

const L2: FunctionComponent<L2Props> = ({
  label,
  bigLabel,
  bigLabelBig,
  labelBold = true,
  subTitle,
  subTitleBold = false,
  subTitleOnTop = true,
  className,
}) => {
  if (!subTitle && !label && !bigLabel) {
    return null;
  }

  let subTitleComponent = null;

  if (subTitle) {
    if (subTitleBold) {
      subTitleComponent = (
        <Body2Medium className={colorStyles.still400}>{subTitle}</Body2Medium>
      );
    } else {
      subTitleComponent = (
        <Body2 className={colorStyles.still400}>{subTitle}</Body2>
      );
    }
  }

  let labelComponent = null;

  if (label && !bigLabel) {
    if (labelBold) {
      labelComponent = <Body1Medium>{label}</Body1Medium>;
    } else {
      labelComponent = <Body1>{label}</Body1>;
    }
  }

  let bigLabelComponent = null;

  if (bigLabel && !label) {
    if (bigLabelBig) {
      bigLabelComponent = <Heading2Medium>{bigLabel}</Heading2Medium>;
    } else {
      bigLabelComponent = <Heading3Medium>{bigLabel}</Heading3Medium>;
    }
  }

  return (
    <div className={cn(styles.l2, className)}>
      {subTitleOnTop && subTitle && subTitleComponent}
      {label && !bigLabel && labelComponent}
      {bigLabel && !label && bigLabelComponent}
      {!subTitleOnTop && subTitle && subTitleComponent}
    </div>
  );
};

const R: FunctionComponent<RProps> = ({ iconName, smallPillLabel }) => {
  if (iconName) {
    return (
      <div className={styles.r}>
        <Icon name={iconName} />
      </div>
    );
  }

  if (smallPillLabel) {
    return (
      <div className={styles.r}>
        <PillSmall>{smallPillLabel}</PillSmall>
      </div>
    );
  }

  return null;
};

const HorizontalTile: FunctionComponent<HorizontalTileProps> = ({
  className,
  L1Props = {},
  L2Props = {},
  RProps = {},
}) => {
  return (
    <div className={cn(styles.horizontalTile, className)}>
      <L1 {...L1Props} />
      <L2 {...L2Props} />
      <R {...RProps} />
    </div>
  );
};

export default HorizontalTile;
