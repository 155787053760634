import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import i18n from 'i18next';

import type { CustomerId, Customer } from 'Consts/types';

import * as api from 'Api/endpoints';

import { Data, updateData } from 'State/utils';
import type { RootState, Action } from 'State/store';

export type CustomerState = {
  customerId: CustomerId | null;
  data: Data<Customer>;
};

const initialState: CustomerState = {
  customerId: null,
  data: updateData(null),
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    set(state, action: PayloadAction<Customer>) {
      state.data = updateData(action.payload);
      state.customerId = action.payload.id;
    },
    setCustomerId(state, action: PayloadAction<CustomerId | null>) {
      state.customerId = action.payload;
    },
    isLoading(state, action: PayloadAction<boolean>) {
      state.data.isLoading = action.payload;
      state.data.errorMessage = '';
      state.data.lastAttempt = Date.now();
    },
    error(state, action: PayloadAction<string>) {
      state.data.isLoading = false;
      state.data.errorMessage = action.payload;
    },
  },
});

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const customerId = getState().customer.customerId;
    const customerData = getState().customer.data;
    const { data: token } = getState().auth.data;
    const cloud = getState().auth.cloud;

    if (customerData.isLoading || !customerId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getCustomer({ customerId, token, cloud });

    if (error || !data) {
      dispatch(actions.error(error?.message || 'Error fetching customer'));

      return;
    }

    dispatch(actions.set(data));
  };
};

export const selectors = {
  id: (state: RootState) => state.customer.customerId,
  data: (state: RootState) => state.customer.data,
};

export const actions = {
  fetchData,
  ...slice.actions,
};

export default slice.reducer;
