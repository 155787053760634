import * as actions from '../actions';
import * as selectors from '../selectors';

import useUpdatedData from './utils/useUpdatedData';

const usePersonDailyDataUsage = (personId: string) =>
  useUpdatedData({
    selector: selectors.dataUsage.person.daily,
    action: actions.dataUsage.person.daily.fetchData(personId),
    dataDependencyKey: personId,
  });

export default usePersonDailyDataUsage;
