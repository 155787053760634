import { ROUTES } from 'Consts/routes';
import useEmployeeSecurityEvents from 'State/hooks/useEmployeeSecurityEvents';
import ShieldGraphCard from 'UI/Reusable/Shield graph';
import { FilterNamePlusAll } from 'UI/Reusable/Shield graph/utils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type EmployeeShieldGraphProps = { personId: string };

const EmployeeShieldGraph: FunctionComponent<EmployeeShieldGraphProps> = ({
  personId,
}) => {
  const navigate = useNavigate();
  const {
    data: employeeSecurityEvents,
    errorMessage: errorMessageSecurityEvents,
  } = useEmployeeSecurityEvents(personId);
  const [activeFilter, setActiveFilter] = useState<FilterNamePlusAll>('all');

  const handleSelectFilter = (name: FilterNamePlusAll) => {
    setActiveFilter((prev) => {
      return prev === name ? 'all' : name;
    });
  };

  const handleShieldViewAllClick = useCallback(() => {
    navigate(ROUTES.shield);
  }, [navigate]);

  return (
    <ShieldGraphCard
      eventsData={employeeSecurityEvents || []}
      errorMessage={errorMessageSecurityEvents}
      activeTypeFilter={activeFilter}
      onTypeFilterClick={handleSelectFilter}
      onViewAllClick={handleShieldViewAllClick}
    />
  );
};

export default EmployeeShieldGraph;
