import { useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';

import useConditionalUpdate from './utils/useConditionalUpdate';
import { Mac } from 'Consts/types';

const useDevice = (mac: Mac) => {
  const device = useSelector(selectors.device);

  useConditionalUpdate({
    data: device,
    dependencies: [],
    action: actions.device.fetchData(mac),
    forceUpdate: device?.data?.mac !== mac,
  });

  return device;
};

export default useDevice;
