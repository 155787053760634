import React, { FunctionComponent, useEffect, useState } from 'react';

import { Period, SpeedDimension, SpeedTestResults } from 'Consts/types';

import SpeedTestGraph, {
  SpeedTestGraphCard,
} from 'UI/Reusable/Speed test graph';
import {
  SpeedTestProgress,
  SpeedTestStates,
  SPEED_TEST_STATES,
} from 'Pages/Homepage/Components/Wlan/Components/Internet speed';
import SpeedTestCompleteAlertModal from 'UI/Reusable/SpeedTestCompleteAlertModal/SpeedTestCompleteAlertModal';

type SpeedTestProps = {
  speedTestState: SpeedTestStates;
  speedTest: SpeedTestResults | null;
  isLoading?: boolean;
  errorMessage?: string;
  period: Period;
  onCheckSpeed: () => void;
  speedDimension: SpeedDimension;
};

const SpeedTest: FunctionComponent<SpeedTestProps> = ({
  speedTestState,
  speedTest,
  isLoading,
  errorMessage,
  period,
  onCheckSpeed,
  speedDimension,
}) => {
  const [speedTestCompleteModalOpen, setSpeedTestCompleteModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (speedTestState === SPEED_TEST_STATES.thirdCheck) {
      setSpeedTestCompleteModalOpen(true);
    }
  }, [speedTestState]);

  if (
    speedTestState === SPEED_TEST_STATES.results ||
    speedTestState === SPEED_TEST_STATES.loading
  ) {
    return (
      <>
        <SpeedTestCompleteAlertModal
          open={speedTestCompleteModalOpen}
          download={speedTest?.dashboard.mostRecent.download || 0}
          upload={speedTest?.dashboard.mostRecent.upload || 0}
        />
        <SpeedTestGraph
          isLoading={isLoading}
          errorMessage={errorMessage}
          chart={speedTest?.[period]?.chart ?? null}
          onCheckSpeedClick={onCheckSpeed}
          period={period}
          speedDimension={speedDimension}
        />
      </>
    );
  }

  return (
    <>
      <SpeedTestGraphCard>
        <SpeedTestProgress
          speedTestState={speedTestState}
          download={speedTest?.dashboard.mostRecent.download || 0}
          upload={speedTest?.dashboard.mostRecent.upload || 0}
        />
      </SpeedTestGraphCard>
    </>
  );
};

export default SpeedTest;
